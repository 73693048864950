// RealEstateAgents.js
import React from "react";
import Frame1RealEstateAgents from "./Images/Frame 1 RealEstateAgents.png";
import { ReactComponent as ChairIcon } from "./Images/Chair icon.svg";
import { ReactComponent as CleanIcon } from "./Images/Clean icon.svg";
import { ReactComponent as BrandIcon } from "./Images/Brand icon.svg";
import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import Frame2RealEstateAgents from "./Images/Frame 2 RealEstateAgents.jpeg";
import Frame3RealEstateAgents from "./Images/Frame 3 RealEstateAgents.png";
import Frame4RealEstateAgents from "./Images/Frame 4 RealEstateAgents.png";
import ArrowIcon from "./Icons/ArrowIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RealEstateAgentsPt() {
  return (
    <div className="body-real-estate">
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Acelere suas vendas imobiliárias
            </h1>
            <div className="section-first-text">
              Atraia compradores, agende mais visitas e venda mais rápido. A
              Pedra ajuda você a mostrar o potencial de cada imóvel com um
              clique.
            </div>
            <div className="section-first-button-container">
              <button className="button-small" onClick={redirectToPage}>
                Experimente Pedra <ArrowIcon />
              </button>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={Frame1RealEstateAgents}
              alt="Ferramenta para Corretores de Imóveis – Captura de Tela do Software"
            ></img>
          </div>
        </section>
      </section>

      <div style={{ borderTop: "1px solid #EEEEEE", height: "20px" }}></div>

      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignText: "left",
          }}
        >
          <div className="">
            Uma ferramenta de marketing imobiliário sem igual.
          </div>
          <div className="title-secondary">
            A Pedra torna simples para qualquer empresa imobiliária melhorar
            seus anúncios de imóveis.
          </div>
          <div className="title-secondary">
            Não é necessário conhecimento especial — apenas faça upload das suas
            imagens e comece!
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <RenovateIcon />
            <div className="feature-title">Renove com IA</div>
            <div className="feature-text">
              Gere renders com IA em um clique. Os compradores querem ver
              propostas de renovação antes de fazer uma compra. A Pedra oferece
              renders econômicos, rápidos e de alta qualidade para mostrar
              ideias de renovação ao seu cliente.
            </div>
          </div>
          <img
            src={FrameRenovateRealEstateAgents}
            alt="Captura de Tela do Software de Render Pedra – Recursos Imobiliários"
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="title-container">Simples mas poderoso</div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <ChairIcon />
            <div className="feature-title">Home staging</div>
            <div className="feature-text">
              Venda um sonho. Adicione móveis a ambientes vazios com um clique.
              Os clientes têm dificuldade em entender as limitações do espaço.
            </div>
          </div>
          <img
            src={Frame2RealEstateAgents}
            alt="Captura de Tela do Software de Render Pedra de Home Staging Virtual"
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <CleanIcon />
                <div className="feature-title">Mantenha limpo</div>
                <div className="feature-text">
                  Use nossas ferramentas de IA para remover elementos das
                  imagens.
                </div>
              </div>
              <img
                src={Frame4RealEstateAgents}
                alt="Captura de Tela do Software de Render Pedra de Remoção de Objetos"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BrandIcon />
                <div className="feature-title">Proteja sua marca</div>
                <div className="feature-text">
                  Use nossa área de trabalho para adicionar marcas d'água às
                  imagens.
                </div>
              </div>
              <img
                src={Frame3RealEstateAgents}
                alt="Captura de Tela do Software de Render Pedra de Adição de Marca D'água Imobiliária"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="quote-container">
        <div className="quote-block">
          <h2 className="quote">
            "Usar a Pedra para editar nossas imagens está nos ajudando a
            conseguir mais visitas!"
          </h2>
          <div className="quote-author-block">
            <img
              className="quote-author-image"
              src="/fincas-lluria.png"
              alt="Logo da Fincas Lluria para Pedra"
            />

            <div className="quote-author-block-name-title">
              <div className="quote-author-name">Max Vives-Fierro</div>
              <div className="quote-author-company">
                Fincas Llúria Imobiliária
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mid-break"> </div>

      <div className="centered-heading">
        <button className="button" onClick={redirectToPage}>
          Experimente Pedra
        </button>
      </div>
    </div>
  );
}

export default RealEstateAgentsPt;
