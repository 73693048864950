import React from "react";
import Article4Frame from "../Images/Article4Frame.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle4De() {
  return (
    <>
      <div className="section-one-column-left">
        <article
          className="title-container article"
          style={{ marginTop: "100px" }}
        >
          <h1 className="article-titleh1">
            Wie kann KI Immobilienmaklern beim Verkauf helfen?
          </h1>
          <div className="title-secondary gray">
            Eine Erklärung darüber, was KI ist und wie sie beim Verkauf von
            Immobilien helfen kann
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Gründer bei Pedra</div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
              }}
              src={Article4Frame}
              alt="Titelbild des Artikels darüber, wie KI Immobilienmaklern beim Verkauf helfen kann"
            />
          </div>
        </article>
      </div>

      <div className="section-one-column-left" style={{ marginTop: "0px" }}>
        <section className="step-container article">
          <div>
            <div className="article-text">
              <div className="article-text">
                In diesem Text möchte ich über KI sprechen und versuchen zu
                entmystifizieren, was sie ist und wie sie im Alltag eines
                Immobilienmaklers wirklich helfen kann.
              </div>
              <br />
              <div className="article-text">
                Dieser Artikel dreht sich um die folgende Frage:
              </div>
              <br />
              <div className="article-text" style={{ fontWeight: "600" }}>
                Wie können wir mit KI mehr verkaufen?
              </div>
              <br />
              <div className="article-text">
                Aber zuerst werden wir erklären, was KI ist:
              </div>
              <br />
              <h2 className="article-titleh2">Was ist KI</h2>
              <br />
              <div className="article-text">
                Ein Programm, das gelernt hat, Aufgaben ähnlich wie ein Mensch
                auszuführen. Aufgaben wie Schreiben, Sprechen, Konversationen
                führen, Denken, Vorstellen usw.
              </div>
              <br />
              <h2 className="article-titleh2">Wie können wir eine KI erstellen?</h2>
              <div className="article-text">
                Der erste Schritt ist das Training eines Modells mit Millionen von
                Datenpunkten.
              </div>
              <br />
              <div className="article-text">
                Der zweite Schritt besteht darin, dieses bereits trainierte
                KI-Modell in die Praxis umzusetzen. Es ist wie bei Rocky, der sehr
                hart trainiert hat – alles für den finalen Kampf.
              </div>
              <br />
              <h2 className="article-titleh2">
                Welche Modelle könnten wir erstellen, um Immobilienmaklern zu
                helfen?
              </h2>
              <br />
              <div className="article-text">
                Zum Beispiel könnten wir eine KI trainieren, die erkennt, welche
                Bilder in einer Anzeige die meisten Klicks generieren, wie zum
                Beispiel bei{" "}
                <a
                  className="article-link"
                  href="https://www.idealista.com"
                  rel="nofollow"
                >
                  Idealista
                </a>
                .
              </div>
              <br />
              <div className="article-text">
                Wir müssten im Grunde ein Modell trainieren und ihm Millionen von
                Bildern zuführen und dem Modell mitteilen, welche als
                Anzeigencover verwendet wurden. Sobald das Modell mit diesen
                Millionen von Datenpunkten trainiert wurde, hätten wir unser
                intelligentes und kleines Modell, das uns hilft, das beste Bild
                für unser Cover auszuwählen. Wir könnten die 20 Bilder einer
                unserer Immobilien teilen, und es würde uns sagen, welches am
                besten zum Verkauf beitragen könnte.
              </div>
              <br />
              <div className="article-text">
                Aber einen kleinen Roboter zu bekommen, der uns diese Hilfe
                gibt, ist weder billig, noch einfach, noch schnell, denn:
              </div>
              <br />
              <li className="article-text">
                Wir müssen mit Millionen von Datenpunkten trainieren
              </li>
              <li className="article-text">
                Wir würden teure technische Ressourcen benötigen, wie
                Fachkräfte und Computer
              </li>
              <li className="article-text">Wir würden viel Zeit benötigen</li>
              <br />
              Daher werde ich nicht darauf eingehen, wie man KI-Modelle
              erstellt, obwohl das ein sehr interessantes Thema wäre. Aber
              sicherlich kein Thema, das Sie kennen müssen, um KI einfach
              optimal zu nutzen. In diesem Artikel werde ich auf bereits
              existierende Modelle eingehen, die Ihnen beim Verkauf helfen
              können.
              <br />
              <h2 className="article-titleh2">
                Gibt es KI-Modelle, die ein Haus für mich verkaufen können?
              </h2>
              <div className="article-text">
                Nein.
                <br />
                <br />
                Es gibt keine KI-Modelle, die den Immobilienmakler ersetzen oder
                für Sie verkaufen werden. Denn KI konzentriert sich auf
                wiederholende Tätigkeiten und nicht auf menschliche und hochkomplexe
                Aktivitäten. Der Immobilienmakler benötigt zwischenmenschliche
                Beziehungen und einen Komplexitätsgrad, den die heutige
                Technologie nicht übernehmen kann.
              </div>
              <br />
              <h2 className="article-titleh2">
                Was kann KI also für den Immobilienmakler tun?
              </h2>
              <div className="article-text">
                KI kann Ihnen repetitive Arbeit abnehmen. Und so haben Sie mehr
                Zeit, sich auf den Verkauf und die Akquise zu konzentrieren.
              </div>
              <br />
              <div className="article-text">Also, wie kann KI uns helfen?</div>
              <br />
              <h2 className="article-titleh2">
                Bilder erstellen, die uns beim Verkauf helfen.
              </h2>
              <div className="article-text">
                Bilder sind eine der repetitivsten Aufgaben, mit denen
                Immobilienmakler zu tun haben. Sie sind praktisch bei allen
                Immobilientransaktionen präsent.
              </div>
              <br />
              <div className="article-text">
                In der Welt der Immobilienbilder gibt es sehr repetitive
                Aufgaben:
              </div>
              <br />
              <div className="article-text">
                Ein klares Beispiel ist, wenn wir das "Oma-Haus" zum Verkauf
                haben. In diesen Situationen kann es sehr schwierig sein, denn
                selbst wenn die Immobilie gut ist, können die Möbel und die
                Dekoration den Verkaufsprozess stark beeinträchtigen.
              </div>
              <br />
              <div className="article-text">KI löst dies.</div>
              <br />
              <div className="article-text">
                Mit einem Klick können wir{" "}
                <Link className="article-link" to="/real-estate-photography">
                  Räume mit KI leeren
                </Link>{" "}
                und diese Räume optimal aussehen lassen
              </div>
              <br />
              <div className="article-text">
                Eine weitere repetitive Aufgabe in der Immobilienwelt ist das
                Erstellen von Visualisierungen für Renovierungsvorschläge sehr
                alter Wohnungen. Wenn eine Immobilie wirklich renoviert werden
                muss, wäre es am besten, wenn wir direkt zeigen könnten, wie sie
                renoviert aussieht. Und deshalb bestellen Immobilienmakler
                Visualisierungen bei Architekten!
              </div>
              <br />
              <div className="article-text">
                Das Problem ist, dass Visualisierungen teuer sind und einige
                Tage oder Wochen brauchen, um von einem Profi erstellt zu
                werden.
              </div>
              <br />
              <div className="article-text">
                KI behebt diese Probleme. Mit KI können Sie{" "}
                <Link className="article-link" to="/render">
                  Renovierungsvorschläge in Sekunden generieren
                </Link>
                , wann Sie wollen, wo Sie wollen, wenn Sie es brauchen.
              </div>
              <br />
              <div className="article-text">
                KI wird auch dabei helfen, Räume mit Leben zu füllen und
                sicherzustellen, dass es wie ein Ort aussieht, den jemand ein
                Zuhause nennen kann. Wenn Immobilien leer sind, haben Besucher
                Schwierigkeiten zu verstehen, wie es aussieht. Käufer sind
                verwirrt und haben Schwierigkeiten zu verstehen, wie es
                wirklich aussehen könnte.
              </div>
              <br />
              <div className="article-text">
                Physisches Home Staging ist in den letzten Jahren sehr populär
                geworden. Es ist eine großartige Lösung, kann aber teuer,
                langsam und komplex in der Umsetzung sein
              </div>
              <br />
              <div className="article-text">
                Mit KI können Sie den Effekt eines physischen Home Stagings
                nicht replizieren, aber Sie können den gleichen Effekt
                replizieren, wenn diese Immobilie online gesehen wird. Und das
                ist wichtig, denn die Mehrheit der Verkaufsprozesse beginnt
                online. Menschen gehen online, und wenn sie einige Bilder sehen,
                die ihnen gefallen, könnten sie eher daran interessiert sein,
                einen Termin für eine Besichtigung zu vereinbaren.
              </div>
              <br />
              <div className="article-text">
                Und auch unser Zeitplan wird KI zu schätzen wissen. Wir wissen,
                wie wichtig das Wetter ist, wenn wir ein Fotoshooting einer
                Immobilie machen wollen. Wir alle wollen{" "}
                <Link className="article-link" to="/real-estate-photography">
                  die besten Immobilienfotos
                </Link>{" "}
                machen. Aber das ist nicht immer einfach. Die Koordination mit
                dem Fotografen, mit dem Wetter, mit dem Hausbesitzer usw. ist
                ein Durcheinander! KI schafft hier Abhilfe. Mit einem
                Tastendruck am Computer können Sie diese Wolken am Himmel
                beseitigen und sicherstellen, dass das Gras so grün wie nie
                aussieht.
              </div>
              <br />
              <h2 className="article-titleh2">
                Zusammenfassend gibt KI Ihnen Superkräfte
              </h2>
              <br />
              <div className="article-text">
                Sie wird Ihnen helfen, schneller zu verkaufen. Sie können
                Bilder erstellen, die als Lead-Magnete auf
                Immobilienmarktplätzen und in sozialen Medien fungieren.
              </div>
              <br />
              <div className="article-text">
                Sie werden die Qualität, Geschwindigkeit und Flexibilität Ihrer
                Dienstleistungen steigern. Gegenüber dem Kunden und dem
                Verkäufer können Sie mehr Erfahrungen und Hilfe bei ihren
                Operationen und Bedürfnissen anbieten.{" "}
                <Link className="article-link" to="/render">
                  Möchte der Kunde die Immobilie nach einer Renovierung sehen?
                </Link>{" "}
                Möchten Sie die Bilder so schnell wie möglich verbessern?
                Möchten Sie schneller veröffentlichen?
              </div>
              <br />
              <div className="article-text">
                Automatisieren und reduzieren Sie Kosten. Was früher viel Geld
                und Zeit kostete, können Sie jetzt einfach mit einem Computer
                oder Telefon machen. Danke, KI.
              </div>
              <br />
              <div className="article-text" style={{ color: "#878787" }}>
                Dieser Inhalt wurde ursprünglich bei einer Live-Veranstaltung
                von{" "}
                <a className="article-link" href="https://www.iadespana.es">
                  IAD España
                </a>{" "}
                am 18. Juni 2024 präsentiert
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hallo! Ich bin Felix Ingla, der Gründer von{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , einer innovativen Webanwendung für virtuelles Home Staging und
              Immobilienfotografie, die die Art und Weise verändert, wie
              Immobilien in der digitalen Welt präsentiert werden. Mein
              Engagement für die Entwicklung modernster Technologie und
              intuitiver Lösungen hat Pedra zu einem vertrauenswürdigen
              Werkzeug für Immobilienprofis gemacht, die ihre
              Immobilienangebote verbessern möchten.
            </p>
            <p>
              Wenn Sie sich vernetzen und über Immobilientechnologie
              diskutieren möchten, kontaktieren Sie mich gerne über{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . Sie können auch mehr{" "}
              <Link className="article-link" to="/about">
                über Pedra hier
              </Link>{" "}
              lesen.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle4De;
