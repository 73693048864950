import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToRemoveObjectFrame1 from "../Images/HowToRemoveObjectFrame1.jpg";
import HowToRemoveObjectFrame2 from "../Images/HowToRemoveObjectFrame2Es.jpg";
import HowToRemoveObjectFrame3 from "../Images/HowToRemoveObjectFrame3Es.jpg";

function HowToRemoveObjectDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So entfernen Sie Objekte aus Häusern und Immobilien
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zum Entfernen von Objekten aus Räumen, Häusern und anderen
            Immobilienfotos
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wofür werden Pedras "Objekt entfernen"-Tools verwendet?
            </h2>
            <p className="article-text">
              Mit Pedras Objekt-Entfernungs-Tools können Sie Gegenstände, Möbel
              und mehr aus Ihren Fotos entfernen.
              <p style={{ marginTop: "10px" }}>
                Dies ist nützlich, um aufgeräumte Räume zu zeigen, ungeeignete
                Objekte aus Immobilienanzeigen zu entfernen oder einfach
                unattraktive Möbel zu beseitigen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie funktioniert die Objektentfernung?
            </h2>
            <p className="article-text">
              Wählen Sie ein Bild aus, aus dem Sie Objekte entfernen möchten.
              Klicken Sie dann auf{" "}
              <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
              und Sie sehen die{" "}
              <span style={{ fontWeight: "500" }}>"Objekt entfernen"</span>{" "}
              Option.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRemoveObjectFrame1}
                alt="Wie man Objekte aus Häusern und Immobilien entfernt – Screenshot der Pedra-Software"
              />
            </p>
            <p className="article-text">
              Malen Sie dann über das Bild. Achten Sie darauf, dass Sie keine
              Teile auslassen. Wenn es mehrere Objekte oder Möbelstücke zu
              entfernen gibt, wählen Sie sie alle auf einmal aus.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame2}
              alt="Wie man Objekte aus Häusern und Immobilien entfernt – Screenshot der Pedra-Software"
            />
            <h2 className="article-subtitleh2">
              Beispiel einer Objektentfernung
            </h2>
            <p className="article-text">
              Hier ist ein Beispiel für die Verwendung von "Objekt entfernen" in
              Pedra. In einem Durchgang entfernen wir alle Möbel aus einem Raum,
              um ihn geräumiger erscheinen zu lassen.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToRemoveObjectFrame3}
              alt="Wie man Objekte aus Häusern und Immobilien entfernt – Screenshot der Pedra-Software"
            />
            <br />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps zum korrekten Entfernen von Objekten
                </h4>
                <p className="callout-p">
                  Befolgen Sie diese Tipps für beste Ergebnisse:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Stellen Sie sicher, dass Sie das zu entfernende Objekt
                        vollständig übermalen. Decken Sie auch die Schatten ab.{" "}
                      </span>
                      Andernfalls können die Ergebnisse mangelhaft sein.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Wenn es mehrere Objekte oder Gegenstände zu entfernen
                        gibt, malen Sie sie alle auf einmal an
                      </span>
                      . Wenn Sie nur ein Objekt anmalen, könnte Pedra es durch
                      ein anderes ersetzen.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Wenn die Objektentfernung nicht vollständig erfolgt ist,
                        wenden Sie "Objekt entfernen" erneut auf das
                        resultierende Bild an
                      </span>
                      . Alle Bilder in Pedra sind nachbearbeitbar, auch die, die
                      Sie innerhalb von Pedra generieren.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Bei präziser Malweise können Sie "Objekt entfernen" als
                        Alternative zu "Leerer Raum" verwenden
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRemoveObjectDe;
