import React from "react";
import ReleaseAug2024 from "./Images/ReleaseAug2024.png";
import ReleaseJul2024 from "./Images/ReleaseJul2024.png";
import ReleaseJun2024 from "./Images/ReleaseJun2024.png";
import ReleaseMay2024 from "./Images/ReleaseMay2024.png";
import ReleaseSep24 from "./Images/ReleaseSep2024.png";
import ReleaseOct24 from "./Images/ReleaseOct2024.png";
import { Link } from "react-router-dom";
import ReleaseNov24 from "./Images/ReleaseNov2024.png";
import ReleaseDec24 from "./Images/ReleaseDec2024.png";

function ReleasesDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Versionen</h1>
          <h2 className="title-secondary">
            Sehen Sie hier die neuesten Versionen von Pedra
            <br />
          </h2>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>DEZEMBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/grundrisse-und-gewerberaume"
              >
                Pedra: Grundriss-Renderings und Gewerberäume
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/de/releases/grundrisse-und-gewerberaume"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseDec24}
                alt="Pedra Versionen Monat Dezember 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>NOVEMBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/arbeitsplatze"
              >
                Pedra: Arbeitsbereiche für Virtual Staging Zusammenarbeit
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link to="/de/releases/arbeitsplatze" style={{ padding: "0px" }}>
              <img
                src={ReleaseNov24}
                alt="Pedra Versionen Monat November 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>OKTOBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/stein-verbesserungen-pools-und-erhaltung"
              >
                Pedra: Pool-Verbesserungen, Raumerhaltung und selektive
                Renovierungsfunktionen
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/de/releases/stein-verbesserungen-pools-und-erhaltung"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseOct24}
                alt="Pedra Versionen Monat Oktober 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>SEPTEMBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/renovieren-und-einrichten-mit-einem-klick"
              >
                Pedra: Räume mit einem Klick renovieren und einrichten,
                verbesserte Möblierung bei 'Auto'-Ergebnissen und bessere
                Raumerhaltung.
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/de/releases/renovieren-und-einrichten-mit-einem-klick"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseSep24}
                alt="Pedra Versionen Monat September 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>AUGUST 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/realistische-einrichtung"
              >
                Pedra: Realistisches Virtual Staging, Renovierung in Ihrem
                eigenen Stil und mehr
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/de/releases/realistische-einrichtung"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseAug2024}
                alt="Pedra Versionen Monat August 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JULI 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen"
              >
                Pedra: 3D-Objekte hinzufügen, Himmel mit einem Klick blau machen
                und Bilder automatisch verbessern oder aufhellen
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJul2024}
                alt="Pedra Versionen Monat Juli 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUNI 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/innenraume-mit-ki-vom-handy-renovieren"
              >
                Pedra: Innenräume vom Handy mit KI renovieren, Bildfußnoten
                bearbeiten und Wasserzeichen anpassen
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/de/releases/innenraume-mit-ki-vom-handy-renovieren"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJun2024}
                alt="Pedra Versionen Monat Juni 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>MAI 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/de/releases/raume-leeren-und-objekte-mit-ki-entfernen"
              >
                Pedra: Räume leeren und Objekte entfernen, Renovierungsstile und
                neue Benutzeroberfläche
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/de/releases/raume-leeren-und-objekte-mit-ki-entfernen"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseMay2024}
                alt="Pedra Versionen Monat Mai 24 – Dekoratives Banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleasesDe;
