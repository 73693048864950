import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageSalonFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingLivingRoomPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Criar Home Staging Virtual para Salas de Estar
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como criar home staging virtual para salas de estar
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-position">Fundador da Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Como posso criar home staging virtual para uma sala de estar?
            </h2>
            <p className="article-text">
              Com a Pedra, você pode criar home staging virtual para salas de
              estar em apenas alguns cliques.
              <p>
                Para começar, acesse o menu{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                selecione <span style={{ fontWeight: "500" }}>"Mobiliar"</span>.
                Escolha "Sala de Estar" como tipo de ambiente e em cerca de 2 ou
                3 minutos, você terá sua imagem de home staging virtual da sala
                pronta para baixar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Como criar home staging virtual para uma sala de estar – Exemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              Como criar home staging virtual para salas de estar?
            </h2>
            <p className="article-text">
              Para conseguir seu{" "}
              <Link className="article-link" to="/home-staging-living-room">
                home staging virtual para uma sala de estar
              </Link>
              , siga estes passos simples:
            </p>
            <ol>
              <li>
                Faça upload da imagem da sala que você deseja mobiliar
                virtualmente
              </li>
              <li>Selecione a imagem para começar a editar</li>
              <li>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                depois clique em{" "}
                <span style={{ fontWeight: "500" }}>"Mobiliar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu para criar home staging virtual para uma sala de estar"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Mobiliar"</span>{" "}
              você precisará seguir estes passos:
            </p>
            <ol>
              <li>
                Selecione a{" "}
                <span style={{ fontWeight: "500" }}>
                  posição dos elementos de staging
                </span>
                . Para salas de estar, recomendamos usar "Desenhar" para indicar
                áreas específicas onde colocar sofás, poltronas, mesas e
                elementos decorativos.
              </li>
              <li>
                Selecione{" "}
                <span style={{ fontWeight: "500" }}>"Sala de Estar"</span> como
                tipo de ambiente. Isso garantirá que a Pedra coloque itens
                apropriados como sofás, poltronas, mesas de centro e elementos
                decorativos.
              </li>
              <li>
                Selecione o <span style={{ fontWeight: "500" }}>estilo</span>.
                Para salas de estar, estilos como "Moderno", "Contemporâneo" ou
                "Aconchegante" geralmente funcionam bem.
              </li>
              <li>
                Escolha o{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imagens a gerar
                </span>
                . Recomendamos escolher 2 ou 3 imagens para ter diferentes
                opções de design de sala.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Dicas para melhor home staging virtual de uma sala de estar:
                <ul>
                  <li>
                    Certifique-se de que a fotografia mostre todo o layout da
                    sala claramente para uma mobília mais precisa
                  </li>
                  <li>
                    Capture quaisquer características únicas como janelas,
                    lareira ou detalhes arquitetônicos
                  </li>
                  <li>
                    Use a opção "Desenhar" para indicar áreas específicas para
                    móveis, iluminação ou elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemplo de como criar home staging virtual para uma sala de estar"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Por que fazer home staging virtual para uma sala de estar?
            </h2>
            <p className="article-text">
              A sala de estar é um dos espaços mais importantes em uma casa e
              pode ser um grande diferencial na venda. O home staging virtual
              para salas permite que você mostre todo o potencial do espaço,
              criando ambientes acolhedores e funcionais que ajudam os
              compradores a se imaginarem relaxando e convivendo no espaço.
            </p>
            <p className="article-text">
              Com um bom home staging virtual para uma sala de estar, você pode:
            </p>
            <ul>
              <li>
                Transformar salas vazias ou desatualizadas em espaços
                acolhedores e modernos
              </li>
              <li>Destacar a funcionalidade e o layout do ambiente</li>
              <li>
                Mostrar diferentes possibilidades de arranjo e organização do
                espaço
              </li>
              <li>
                Ajudar os compradores a imaginar um espaço de convivência e
                relaxamento
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemplos de home staging virtual para salas de estar
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de home staging virtual que podem ser
              aplicados a salas de estar:
            </p>
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingLivingRoomPt;
