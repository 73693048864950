import React from "react";
import Article5Frame from "../Images/Article5Frame.jpeg";
import Article5Frame1 from "../Images/Article5Frame1.jpeg";
import Article5Frame2 from "../Images/Article5Frame2.jpeg";
import Article5Frame3 from "../Images/Article5Frame3.jpeg";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle5Pt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Como Garantir que a Foto de Capa do Seu Anúncio Imobiliário
              Acelere a Venda
            </h1>
            <div className="title-secondary gray">
              Escolha e gere fotos de capa em seus anúncios imobiliários que
              chamem a atenção e ajudem você a vender
            </div>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fundador da Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador da Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article5Frame}
                alt="Capa do artigo sobre como a IA pode ajudar corretores imobiliários a vender"
              />
            </div>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Hoje em dia, as fotos são fundamentais para vender imóveis. As
                pessoas navegam por apartamentos através de portais imobiliários
                digitais em seus celulares, seja no trem a caminho do trabalho
                ou sentadas no sofá no final do dia.
              </div>
              <div style={{ marginTop: "10px" }}>
                As pessoas deslizam os dedos no celular, como no Instagram ou
                Twitter, procurando algo que chame sua atenção.
              </div>
              <div style={{ marginTop: "10px" }}>
                E existem muitas maneiras de chamar a atenção, mas uma das mais
                eficazes é através de fotos.
              </div>
              <div style={{ marginTop: "10px" }}>
                Como corretores imobiliários, devemos garantir que a primeira
                foto que eles vejam seja aquela que gera mais sensações
                positivas e atenção.
              </div>
              <div style={{ marginTop: "10px" }}>
                E assim, eles visitam o anúncio e podemos fechar a venda
                rapidamente.
              </div>
              <div style={{ marginTop: "10px" }}>
                Para chamar a atenção com a primeira foto, você deve seguir 3
                passos simples:
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 —</span>{" "}
                Identificar o tipo de pessoas que vamos atrair com esse imóvel
              </h2>
              <div style={{ marginTop: "10px" }}>
                É um apartamento para reformar? É uma vila espetacular? É um
                apartamento para alugar?
              </div>
              <div style={{ marginTop: "10px" }}>
                Dependendo do tipo de imóvel, diferentes tipos de pessoas
                estarão interessadas.
              </div>
              <div style={{ marginTop: "10px" }}>
                Precisamos identificar quais pessoas serão atraídas por esse
                apartamento para capturar sua atenção.
              </div>
              <div style={{ marginTop: "10px" }}>
                Por exemplo, se estamos vendendo uma vila em uma área
                residencial de Madrid, certamente famílias abastadas com filhos
                estarão interessadas. Se é um apartamento à venda no centro da
                cidade para ser reformado, atrairá investidores ou pessoas que
                procuram começar a viver como casal.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 —</span>{" "}
                Escolher a fotografia para essas pessoas
              </h2>
              <div style={{ marginTop: "10px" }}>
                Agora que sabemos quem estará interessado em nosso imóvel,
                precisamos encontrar as fotografias que mais os atraem.
              </div>
              <div style={{ marginTop: "10px" }}>
                Se estamos tentando vender uma vila perfeita para famílias,
                destaque as fotos que mostram os melhores aspectos da vila. Tem
                piscina e jardim? Coloque uma foto onde eles estejam claramente
                visíveis. Tem uma sala de estar espetacular perfeita para noites
                em família? Coloque essa como primeira foto.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 —</span>{" "}
                Melhorar e mostrar o potencial da fotografia
              </h2>
              <div style={{ marginTop: "10px" }}>
                Conseguir a fotografia não é o último passo; devemos garantir
                que ela mostre o máximo potencial para o espectador.
              </div>
              <div style={{ marginTop: "10px" }}>
                Lembre-se, as pessoas navegam pelos portais, e precisamos
                capturar atenção suficiente para que elas cliquem no anúncio.
              </div>
              <div style={{ marginTop: "10px" }}>
                Se temos a foto de nossa vila à venda para famílias, mas a
                tiramos quando a grama não estava boa e o tempo estava ruim,
                certifique-se de usar a Pedra para{" "}
                <Link to="/real-estate-photography" className="article-link">
                  deixar a grama verde e o céu
                </Link>{" "}
                limpo.
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame1}
                  alt="Antes e depois de um céu azul e grama verde com IA"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                Se estamos vendendo um apartamento para reformar, mostre a
                proposta de reforma na primeira imagem. Hoje em dia, você pode{" "}
                <Link to="/render" className="article-link">
                  reformar imóveis com IA{" "}
                </Link>
                (inteligência artificial) de forma rápida e fácil, sem sair do
                seu computador ou celular. Aproveite isso.
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame2}
                  alt="Antes e depois de uma reforma com IA"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                E se você tem um apartamento para alugar sem móveis, mostre como
                ele ficaria mobiliado. Isso dará uma visão mais atraente do
                apartamento, e os potenciais inquilinos ficarão mais ansiosos
                para ver seu anúncio. E o melhor é que você pode{" "}
                <Link to="/home-staging-virtual" className="article-link">
                  {" "}
                  mobiliar apartamentos digitalmente usando a IA da Pedra
                </Link>
                .
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article5Frame3}
                  alt="Antes e depois de mobiliário virtual com IA"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Olá! Eu sou Felix Ingla, o fundador da{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , uma aplicação web inovadora de home staging virtual e fotografia
              imobiliária que está transformando como os imóveis são
              apresentados no mundo digital. Minha dedicação em criar tecnologia
              de ponta e soluções intuitivas estabeleceu a Pedra como uma
              ferramenta confiável para profissionais imobiliários que buscam
              melhorar seus anúncios de imóveis.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle5Pt;
