import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderTerracePt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Como Criar Renders de Terraços</h1>

          <div className="title-secondary gray">
            Tutorial sobre como criar renders de terraços
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Por que usar as ferramentas de render da Pedra para terraços?
            </h2>
            <p className="article-text">
              As ferramentas de render da Pedra permitem visualizar uma
              transformação completa do terraço em segundos, ajudando potenciais
              compradores a ver o verdadeiro potencial do espaço externo.
              <p style={{ marginTop: "10px" }}>
                Renovações de terraço podem aumentar dramaticamente o valor de
                um imóvel, já que os espaços externos se tornaram cada vez mais
                importantes para os compradores. Poder mostrar o potencial de um
                terraço subutilizado pode fazer uma grande diferença na venda de
                imóveis.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com a Pedra, você não precisa mais de designers paisagistas
                especializados ou especialistas em renderização 3D para criar
                propostas convincentes de renovação de terraço.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como criar um render de terraço?
            </h2>
            <p className="article-text">
              Comece fazendo upload de fotos claras do terraço existente que
              você deseja transformar.
              <p style={{ marginTop: "10px" }}>
                Selecione suas imagens do terraço e clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>,
                depois escolha a opção{" "}
                <span style={{ fontWeight: "500" }}>"Renovação"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Como usar a ferramenta de renovação para criar renders de terraço – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovação Rápida de Terraço:{" "}
              </span>
              Gere um Render de Terraço Rapidamente
            </h2>
            <p style={{ marginTop: "10px" }}>
              A ferramenta{" "}
              <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
              transformará seu terraço em cerca de 25 segundos.
              <p>
                Para uma atualização sutil, escolha o estilo "Minimalista" com
                criatividade "Baixa". Isso manterá o layout básico enquanto
                moderniza o piso, móveis e elementos paisagísticos.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Como usar a ferramenta de renovação para criar renders de terraço – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personalize Seu Render de Renovação de Terraço
            </h2>
            <p className="article-text">
              A Pedra oferece extensas opções de personalização para renders de
              terraço.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Escolha entre vários estilos de terraço, controle quão
                  dramáticas devem ser as mudanças e decida se deseja preservar
                  elementos existentes como guarda-corpos ou características
                  estruturais.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Como usar a ferramenta de renovação para criar renders de terraço – Captura de tela do software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Escolha seu estilo de terraço
            </h3>
            <p className="article-text">
              Selecione entre 9 estilos diferentes de terraço, incluindo
              moderno, tropical, mediterrâneo e mais.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você também pode fazer upload de imagens de referência de
                  estilos de terraço que você ama.
                </span>{" "}
                Isso permite criar renders que correspondam a uma inspiração
                específica de design ou à preferência estética externa do seu
                cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Defina a intensidade da renovação
            </h3>
            <p className="article-text">
              Escolha entre níveis de criatividade Baixo, Médio, Alto e Muito
              Alto para sua transformação de terraço.
              <p style={{ marginTop: "10px" }}>
                Configurações Baixa e Média atualizarão piso, móveis e plantas
                mantendo o layout básico do terraço intacto.
              </p>
              <p style={{ marginTop: "10px" }}>
                Configurações Alta e Muito Alta podem reimaginar completamente o
                espaço com novos layouts, pérgolas e elementos arquitetônicos.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Preserve elementos-chave do terraço
            </h3>
            <p className="article-text">
              Escolha preservar elementos estruturais como guarda-corpos ou
              características existentes que você deseja manter. Isso garante
              que seu render mantenha elementos práticos enquanto atualiza o
              design geral.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Gere seu render de terraço
            </h2>
            <p>
              Após selecionar suas preferências, gere o render do terraço.
              Renders padrão levam cerca de 15 segundos, enquanto preservar
              elementos específicos pode levar um pouco mais.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Dicas para melhores renders de terraço
                </h4>
                <p className="callout-p">
                  Para obter os renders de terraço mais realistas e úteis:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Gere múltiplas versões
                      </span>
                      . Cada render é único, e você pode encontrar alguns que
                      integram melhor elementos externos ou maximizam o espaço
                      disponível.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use criatividade baixa para atualizações sutis em terraços
                      básicos.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Tire fotos durante o dia e capture todo o espaço
                      </span>
                      . Isso ajuda a IA a entender melhor o layout do terraço e
                      criar transformações mais precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Exemplos de render de terraço
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de renovações de terraço criadas com
              nossos renders:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Terraço antes do render"}
              altAfter={"Terraço depois do render"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Terraço antes do render"}
              altAfter={"Terraço depois do render"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Terraço antes do render"}
              altAfter={"Terraço depois do render"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Terraço antes do render"}
              altAfter={"Terraço depois do render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderTerracePt;
