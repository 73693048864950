import React from "react";
import { Link } from "react-router-dom";

function HelpPt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Bem-vindo ao Centro de Ajuda Pedra
            </h1>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Aprenda como transformar seus imóveis com nossas ferramentas de
                decoração virtual.
              </div>
              <div style={{ marginTop: "10px" }}>
                Se você é novo na{" "}
                <Link className="article-link" to="/">
                  Pedra
                </Link>{" "}
                ou quer dominar nossos recursos, temos guias completos para
                ajudá-lo a ter sucesso.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Começando com{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  Decoração Virtual
                </span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Nossas ferramentas de decoração virtual permitem transformar
                espaços vazios em ambientes lindamente mobiliados, criar
                visualizações de reformas e melhorar fotos de imóveis - tudo sem
                decoração física.
              </div>
              <div style={{ marginTop: "10px" }}>
                Comece com estes tutoriais essenciais:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-virtual-home-staging"
                    >
                      Como Criar Decoração Virtual de Casa
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-empty-rooms-virtually"
                    >
                      Como Esvaziar Ambientes Virtualmente
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-furnish-virtually"
                    >
                      Como Mobiliar Virtualmente
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-add-3d-objects"
                    >
                      Como Adicionar Objetos 3D
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-remove-object"
                    >
                      Como Remover Objetos
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Tutoriais por{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Ambiente</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Aprenda como decorar e criar renderizações para diferentes tipos
                de ambientes:
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Guias de Decoração Virtual:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-living-room-virtual-home-staging"
                    >
                      Guia de Decoração de Sala de Estar
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bedroom-virtual-home-staging"
                    >
                      Guia de Decoração de Quarto
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-kitchen-virtual-home-staging"
                    >
                      Guia de Decoração de Cozinha
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bathroom-virtual-home-staging"
                    >
                      Guia de Decoração de Banheiro
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-terrace-virtual-home-staging"
                    >
                      Guia de Decoração de Terraço
                    </Link>
                  </li>
                </ul>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Guias de Renderização:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-living-room-render"
                    >
                      Guia de Renderização de Sala de Estar
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bedroom-render"
                    >
                      Guia de Renderização de Quarto
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-kitchen-render"
                    >
                      Guia de Renderização de Cozinha
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bathroom-render"
                    >
                      Guia de Renderização de Banheiro
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-terrace-render"
                    >
                      Guia de Renderização de Terraço
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Recursos{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Avançados</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Leve sua decoração ao próximo nível com nossas ferramentas
                avançadas:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-house-exterior-render"
                    >
                      Criando Renderizações Externas
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-draw-floor-plan"
                    >
                      Desenhando Plantas Baixas
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-renovate-houses-virtually"
                    >
                      Guia de Reforma Virtual
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Precisa de{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Ajuda?</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Não encontrou o que procurava? Nossa equipe de suporte está aqui
                para ajudar. Entre em contato conosco em felix@pedra.so.
              </div>
              <br />
              <br />
              <div style={{ marginTop: "10px" }}>
                Pronto para começar a transformar seus anúncios de imóveis?
              </div>
              <div style={{ marginTop: "10px" }}>
                Escolha qualquer tutorial acima para começar, ou visite nossa{" "}
                <Link className="article-link" to="/pricing">
                  página de preços
                </Link>{" "}
                para saber mais sobre nossos planos.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpPt;
