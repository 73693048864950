import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import ChangeSize from "../Images/HowTo3DChangeSize.jpeg";
import Move from "../Images/HowTo3DMove.jpeg";
import Rotate from "../Images/HowTo3DRotate.jpeg";

function HowTo3DPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Usar a Ferramenta "Adicionar Objeto" para Criar um Home Staging
            Virtual
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre como adicionar objetos 3D a um espaço e criar um home
            staging virtual
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            {typeof window !== "undefined" && (
              <iframe
                src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                  height: "500px",
                }}
                frameBorder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                title="IA para vender imóveis"
              ></iframe>
            )}
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Para que serve a ferramenta "Adicionar Objeto"?
            </h2>
            <div className="article-text">
              Com a ferramenta "Adicionar Objeto", você pode colocar móveis,
              plantas, objetos e muito mais em sua imagem.
              <br />
              <br />
              O melhor desta ferramenta é que você pode escolher onde colocar os
              objetos, seu tamanho e orientação.
              <br />
              <br />
              Depois de posicionar os objetos, você pode clicar na opção gerar.
              Isso criará uma imagem altamente realista do seu layout.
              <br />
              <br />
            </div>
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Adicionar Objetos
            </h2>
            <div className="article-text">
              Clique no objeto que você deseja adicionar. Ao clicar, o objeto
              será automaticamente adicionado à imagem.
              <div style={{ marginTop: "10px" }}>
                Se você quiser remover os objetos que adicionou, use o botão
                "Excluir Objetos" para removê-los da imagem.
              </div>
            </div>
            <br />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 –</span>{" "}
              Editar Posição, Orientação e Tamanho dos Objetos
            </h2>
            <div className="article-text">
              Agora que você adicionou os objetos à imagem, você precisa
              colocá-los na posição, orientação e tamanho corretos.
              <div style={{ marginTop: "10px", fontWeight: "500" }}>
                {" "}
                Recomendamos que você coloque um objeto na posição, tamanho e
                orientação corretos antes de adicionar outro objeto à imagem.
              </div>
            </div>
            <br />
            <h3 className="article-subtitle">Alterar Posição dos Objetos</h3>
            <div className="article-text">
              Use as setas verde, amarela e vermelha para mover o objeto.
              Lembre-se que você está trabalhando em 3 dimensões, então pode
              mover o objeto pelo espaço.
              <div style={{ marginTop: "10px" }}>
                Você também pode clicar no retângulo amarelo no meio do objeto
                para arrastá-lo pelo espaço.
              </div>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={Move}
                alt="Alterar posição de objeto 3D para home staging virtual – Captura de tela do software Pedra"
              />
              <br />
            </div>
            <h3 className="article-subtitle">Girar Objetos</h3>
            <div className="article-text">
              Use as esferas verde, amarela e vermelha para girar o objeto.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={Rotate}
              alt="Girar objeto 3D para home staging virtual – Captura de tela do software Pedra"
            />
            <h3 className="article-subtitle">Alterar Tamanho dos Objetos</h3>

            <div className="article-text">
              Use as linhas verde, amarela e vermelha para alterar o tamanho do
              objeto.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={ChangeSize}
              alt="Alterar tamanho 3D para home staging virtual – Captura de tela do software Pedra"
            />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Gerar Imagem
            </h2>
            <div className="article-text">
              Depois de posicionar os objetos, clique em gerar imagem. Uma
              representação hiper-realista do espaço será criada.
            </div>
            <div style={{ marginTop: "10px" }}>
              Se você tiver alguma dúvida, entre em contato comigo por e-mail em
              felix@pedra.so
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowTo3DPt;
