import React from "react";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function AboutUsFr() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h2 className="article-subtitleh2">Bonjour,</h2>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Je suis Felix Ingla, le fondateur de Pedra.
              </div>
              <div style={{ marginTop: "10px" }}>
                Au printemps 2023, j'ai lancé{" "}
                <Link className="article-link" to="/fr">
                  Pedra
                </Link>
                , un outil en ligne pour transformer facilement vos biens
                immobiliers.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Pedra est né d'une{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>réalité</span> du
                secteur immobilier.
              </h2>
              <div style={{ marginTop: "10px" }}>
                Pour vendre un bien sur le marché – et le vendre rapidement –
                les agences immobilières ont besoin de bonnes photographies, de
                débarrasser les appartements, de placer de beaux meubles dans
                les pièces, et même de montrer des propositions de rénovation.
              </div>
              <div style={{ marginTop: "10px" }}>
                Tout cela nécessite un investissement en temps et en argent.
                Mais nous n'avons pas toujours ce temps et cet argent.
              </div>
              <div style={{ marginTop: "10px" }}>
                C'est pourquoi Pedra a été créé : une façon facile, rapide et
                économique d'améliorer la présentation d'un bien sur le marché,
                afin de pouvoir le vendre plus rapidement.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Nos clients sont{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  notre priorité
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                Chez{" "}
                <Link className="article-link" to="">
                  Pedra
                </Link>
                , nous voulons assurer la meilleure expérience lorsque vous
                utilisez notre produit. Si à tout moment vous avez des
                commentaires ou besoin d'aide, n'hésitez pas à m'écrire à
                felix@pedra.so.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Un outil{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  puissant mais simple
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                Avec Pedra, nous offrons aux agences immobilières{" "}
                <Link className="article-link" to="/fr/real-estate-photography">
                  l'accès à l'intelligence artificielle pour améliorer vos
                  photographies immobilières
                </Link>
                ,{" "}
                <Link className="article-link" to="/fr/render">
                  générer des propositions de rénovation
                </Link>
                , désencombrer les pièces et plus encore. Et surtout, nous
                l'offrons dans un produit en ligne facile à utiliser – nous
                voulons que vous fassiez des économies de coûts et de temps.
              </div>
              <br />
              <br />
              Nous sommes fiers de vous présenter Pedra.
              <br />
              Et nous espérons que vous êtes impatient de l'utiliser.
              <br />
              <br />
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "50%",
                    height: "64px",
                    width: "auto",
                  }}
                  src={FelixIngla}
                  alt="Felix Ingla"
                />
                <div
                  style={{
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "100%",
                  }}
                >
                  <div className="article-text" color="#000 !important">
                    Felix Ingla
                  </div>
                  <div className="article-text" style={{ color: "#878787" }}>
                    Fondateur de Pedra
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsFr;
