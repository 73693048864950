import React from "react";
import { Link } from "react-router-dom";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import Article16Frame from "../Images/Article16Frame.png";

function BlogArticle16De() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">Was sind Sunken Living Rooms</h1>
            <h2 className="title-secondary gray">
              Entdecken Sie, wie diese Wohnbereiche Ihr Zuhause verwandeln
              können
            </h2>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Gründer von Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Gründer von Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article16Frame}
                alt="Beispiel eines Sunken Living Rooms"
              />
            </div>
            <article className="article-text">
              <p>
                Sunken Living Rooms, auch bekannt als "Conversation Pits", sind
                Wohnbereiche, bei denen das Bodenniveau etwas niedriger ist als
                der Rest des Raums oder der umgebende Raum. Dieses
                architektonische Design zeichnet sich dadurch aus, dass es einen
                zentralen und gemütlichen Bereich schafft, der zu Gesprächen und
                Komfort einlädt und sich durch seine differenzierte Struktur
                auszeichnet. Sie sind typischerweise über einige Stufen
                zugänglich, was ein Gefühl von Tiefe und Exklusivität schafft.
              </p>
              <p>
                Das Konzept der Sunken Living Rooms gewann Mitte des 20.
                Jahrhunderts an Popularität, besonders in den 1950er und 1960er
                Jahren, inmitten des Aufstiegs des Mid-Century Modern Designs.
                Architekten dieser Zeit begannen, mit neuen räumlichen Ansätzen
                zu experimentieren, um flüssigere und entspanntere Umgebungen
                innerhalb des Hauses zu schaffen. Die Idee hinter dieser Art von
                Design war es, eine Komfortzone zu generieren, die gleichzeitig
                die Monotonie flacher und offener Böden durchbricht und den
                Bereich zu einem intimeren und dynamischeren Ort macht.
              </p>
              [Continue translation for the rest of the file...]
              <div className="section-one-column-left">
                <div className="step-container article">
                  <footer className="article-footer">
                    <img
                      style={{
                        border: "1px solid #F7F7F7",
                        borderRadius: "50%",
                        height: "50px",
                        width: "50px",
                      }}
                      src={FelixIngla}
                      alt="Gründer von Pedra"
                    />
                    <h3 className="article-footer-author-name">Felix Ingla</h3>
                    <p>
                      Hallo! Ich bin Felix Ingla, der Gründer von{" "}
                      <Link className="article-link" to="/">
                        Pedra
                      </Link>
                      , einer innovativen Webanwendung für virtuelles Home
                      Staging und Immobilienfotografie, die die Art und Weise
                      verändert, wie Immobilien in der digitalen Welt
                      präsentiert werden.
                    </p>
                    <p>
                      Wenn Sie sich vernetzen möchten und an Diskussionen über
                      Immobilientechnologie interessiert sind, kontaktieren Sie
                      mich bitte über{" "}
                      <a
                        className="article-link"
                        href="https://www.linkedin.com/in/felixingla/"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                      . Sie können auch mehr{" "}
                      <Link className="article-link" to="/about">
                        über Pedra hier
                      </Link>{" "}
                      lesen.
                    </p>
                  </footer>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogArticle16De;
