import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToFurnishFrame1 from "../Images/HowToFurnishFrame1.jpg";
import HowToFurnishFrame2 from "../Images/HowToFurnishFrame2.jpg";
import HowToFurnishFrame3 from "../Images/HowToFurnishFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";

function HowToFurnishPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como usar a ferramenta mobiliar para mobiliar virtualmente
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre como mobiliar virtualmente casas e ambientes
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Para que serve a ferramenta "Mobiliar" da Pedra?
            </h2>
            <p className="article-text">
              Com as ferramentas de mobiliamento da Pedra, você pode adicionar
              móveis aos ambientes do seu imóvel.
              <p style={{ marginTop: "10px" }}>
                Mostrar ambientes mobiliados pode ajudar a acelerar a venda,
                pois os compradores entenderão melhor o layout dos espaços
                quando virem as fotos nos principais portais imobiliários.
              </p>
              <p style={{ marginTop: "10px" }}>
                Além disso, os móveis adicionados pela Pedra parecem limpos,
                novos e organizados, o que gera emoções positivas ao visualizar
                as fotos.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como mobiliar virtualmente um ambiente com a Pedra?
            </h2>
            <p className="article-text">
              Depois de fazer upload das imagens que você deseja mobiliar,
              selecione uma delas.
              <p style={{ marginTop: "10px" }}>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>, e
                você verá a opção de{" "}
                <span style={{ fontWeight: "500" }}>"Mobiliar"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame1}
                alt="Como usar a ferramenta de mobiliamento para mobiliar virtualmente – Captura de tela do software Pedra"
              />
            </p>
            <p className="article-text">
              Abaixo, mostramos os passos a seguir para mobiliar um espaço com a
              Pedra.
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Seleção da área a ser mobiliada virtualmente
            </h3>
            <p className="article-text">
              O primeiro passo determina onde os móveis aparecerão. A seleção
              pode ser automática ou desenhada. Se for automática, a Pedra
              decidirá onde colocar os móveis.
              <p style={{ marginTop: "10px" }}>
                {" "}
                Se você precisar de mais precisão ao colocar os móveis,
                selecione <span style={{ fontWeight: "500" }}>"Desenhar"</span>.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com a opção{" "}
                <span style={{ fontWeight: "500" }}>"Desenhar"</span> você pode
                pintar a área onde os móveis aparecerão. Basta pintar uma área
                uniforme e grande onde você quer que os móveis apareçam.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame2}
                alt="Como usar a ferramenta de mobiliamento para mobiliar virtualmente – Captura de tela do software Pedra"
              />
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Tipo de ambiente a ser mobiliado
            </h3>
            <p className="article-text">
              Neste segundo passo, você precisa selecionar o tipo de ambiente.
              Se selecionar 'Quarto', serão colocados uma cama, um criado-mudo,
              um abajur, etc. Se selecionar 'Sala de Estar', serão colocados
              sofás, mesas de centro, uma TV, etc.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Estilo de mobiliamento virtual
            </h3>
            <p className="article-text">
              Você também pode mudar o tipo de estilo de 'Mobiliar'. Você pode
              escolher entre 'Minimalista', 'Mediterrâneo', 'Tradicional' e mais
              opções.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Número de imagens a gerar
            </h2>
            <p>
              Você também pode escolher o número de propostas a gerar. Cada
              mobiliamento é único, então recomendamos selecionar gerar 2 ou 3
              propostas. Assim, você pode escolher a proposta que mais lhe
              agrada.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToFurnishFrame3}
              alt="Como usar a ferramenta de mobiliamento para mobiliar virtualmente – Captura de tela do software Pedra"
            />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Como obter os melhores mobiliamentos virtuais
                </h4>
                <p className="callout-p">
                  Siga estas dicas para obter os melhores resultados:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Se você não obtiver bons resultados com a opção de
                        posicionamento 'Auto' dos móveis, tente 'Desenhar'.
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Para controlar a colocação dos móveis, escolha a opção
                        'Desenhar' no passo número 1
                      </span>
                      . Assim, você pode indicar onde os móveis aparecem e qual
                      espaço eles ocupam.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Se você escolher 'Desenhar', não pinte uma área muito
                        pequena para evitar móveis com dimensões muito pequenas.
                      </span>{" "}
                      Pinte uma área uniforme e grande.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Se você quiser controlar quais móveis adicionar, sua
                        orientação e seu tamanho, recomendamos o{" "}
                        <Link
                          className="article-link"
                          to="/help/how-to-add-3d-objects"
                        >
                          'Adicionar objeto'
                        </Link>
                      </span>
                      . Esta outra funcionalidade da Pedra permite que você
                      escolha os móveis a colocar, sua orientação e tamanho.
                      Você pode ver como funciona{" "}
                      <Link
                        className="article-link"
                        to="/help/how-to-add-3d-objects"
                      >
                        aqui
                      </Link>
                      .
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToFurnishPt;
