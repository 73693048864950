import React from "react";
import ReleaseJun2024Frame1 from "../Images/ReleaseJun2024Frame1.png";
import ReleaseJun2024Frame2 from "../Images/ReleaseJun2024Frame2.png";
import ReleaseJun2024Frame3 from "../Images/ReleaseJun2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJun24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">JUNI 2024</div>
          <h1 className="article-titleh1">
            Pedra: Räume von Ihrem Mobilgerät aus erneuern, Bildunterschriften
            bearbeiten und Wasserzeichen ändern
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Illustratives Titelbild über KI und die Immobilienbranche"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Jetzt können Sie Pedra von Ihrem Mobilgerät aus nutzen. Hier sind
              die Neuigkeiten und Fortschritte, die wir im Juni gemacht haben:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Pedra für Mobilgeräte</h2>
              Jetzt können Sie Pedra von Ihrem mobilen Browser aus nutzen.
              Ideal, um Kunden während Besichtigungen zu beeindrucken, einen{" "}
              <Link to="/render" className="article-link">
                schnellen Renovierungsvorschlag
              </Link>{" "}
              zu erstellen oder Fotos zu bearbeiten, wenn Sie nicht an Ihrem
              Computer sind.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame1}
                  alt="Räume mit KI vom Mobilgerät aus erneuern"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Wasserzeichen verschieben und Transparenz anpassen
              </h2>
              Jetzt können Sie die Transparenz von Wasserzeichen bearbeiten und
              sie beliebig auf Ihrem Bild platzieren. Schützen Sie Ihre Marke,
              ohne Ihre Fotos zu beeinträchtigen.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame2}
                  alt="Wasserzeichen verschieben und Transparenz bearbeiten"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">Bildunterschriften ändern</h2>
              Stellen Sie sicher, dass Betrachter Ihrer Infografiken wissen,
              dass sie nicht echt sind. Jetzt können Sie den Text der
              Bildunterschriften und deren Größe bearbeiten.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJun2024Frame3}
                alt="Bildunterschrift eines KI-Fotos ändern"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>

            <div>
              <li>
                Verbesserte Qualität der eingefügten Möbel bei "Möblieren"
              </li>
              <li>Option zum "Möblieren" eines Eingangsbereichs</li>
              <li>
                Neues Design der Homepage: Sehen Sie das erste Bild jedes
                Projekts, ohne es öffnen zu müssen
              </li>
              <li>
                Wir haben ein 2-minütiges Tutorial hinzugefügt, damit Sie
                wissen, wie Sie Pedra nutzen können. Sie können es sich{" "}
                <a
                  className="article-link"
                  href="https://www.youtube.com/watch?v=z6O8EX2fJPI"
                  rel="nofollow"
                >
                  hier
                </a>{" "}
                ansehen
              </li>{" "}
              <li>
                Option zur Auswahl von Feinsteinzeug beim "Boden bearbeiten"
              </li>
              <li>Verschiedene Fehlerbehebungen</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJun24De;
