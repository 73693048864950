import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeSpace from "./Images/curtain-effect-hero/BeforeSpace.png";
import AfterSpace from "./Images/curtain-effect-hero/AfterSpace.png";
import BeforeSpaceExample1 from "./Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "./Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "./Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "./Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "./Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "./Images/curtain-effect/AfterSpaceStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderLocalDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Retail",
    beforeImage: BeforeSpace,
    afterImage: AfterSpace,
  });

  const handleExample = (example) => {
    if (example === "Retail") {
      setExample({
        name: "Retail",
        beforeImage: BeforeSpace,
        afterImage: AfterSpace,
      });
    } else if (example === "Office") {
      setExample({
        name: "Office",
        beforeImage: BeforeSpaceExample1,
        afterImage: AfterSpaceExample1,
      });
    } else if (example === "Restaurant") {
      setExample({
        name: "Restaurant",
        beforeImage: BeforeSpaceExample2,
        afterImage: AfterSpaceExample2,
      });
    } else {
      setExample({
        name: "Supermarket",
        beforeImage: BeforeSpaceExample3,
        afterImage: AfterSpaceExample3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was sind automatische Gewerbeimmobilien-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatische Gewerbeimmobilien-Renderings sind KI-gestützte Visualisierungen, die Fotos von Gewerbeimmobilien in professionell gerenderte Bilder umwandeln. Diese Technologie ermöglicht es Ihnen, fotorealistische Visualisierungen von Gewerberäumen mit verschiedenen Stilen, Oberflächen und Layouts zu erstellen, ohne dass eine manuelle 3D-Modellierung oder komplexe Software erforderlich ist.",
        },
      },
      {
        "@type": "Question",
        name: "Was sind die Vorteile von automatischen Gewerbeimmobilien-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Automatische Gewerbeimmobilien-Renderings bieten mehrere Vorteile, darunter: sofortige Transformation von Gewerberäumen, die Möglichkeit, schnell mehrere Designoptionen zu visualisieren, hochwertige fotorealistische Ergebnisse, erhebliche Zeit- und Kosteneinsparungen im Vergleich zum traditionellen Rendering und die Flexibilität, mit verschiedenen Layouts und Konfigurationen zu experimentieren.",
        },
      },
      {
        "@type": "Question",
        name: "Wie funktioniert der automatische Rendering-Prozess für Gewerbeimmobilien?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der Prozess beinhaltet das Hochladen von Fotos des aktuellen Gewerberaums auf unsere Plattform, die Auswahl der gewünschten Transformationen (wie das Ändern von Oberflächen, das Hinzufügen von Elementen oder das Modifizieren von Layouts) und den Erhalt fotorealistischer Renderings, die den transformierten Raum innerhalb von Minuten zeigen. Unsere KI-Technologie übernimmt automatisch alle komplexen Rendering-Arbeiten.",
        },
      },
      {
        "@type": "Question",
        name: "Wie viel kosten automatische Gewerbeimmobilien-Renderings?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Unser automatischer Rendering-Service für Gewerbeimmobilien ist für 29 € plus Mehrwertsteuer pro Monat verfügbar. Dieser Plan ermöglicht es Ihnen, bis zu 100 Renderings pro Monat zu generieren, ohne dass eine langfristige Verpflichtung erforderlich ist.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "Example1",
    beforeImage: BeforeSpaceExample1,
    afterImage: AfterSpaceExample1,
  });

  const stagingExamples = [
    {
      name: "Example1",
      beforeImage: BeforeSpaceExample1,
      afterImage: AfterSpaceExample1,
    },
    {
      name: "Example2",
      beforeImage: BeforeSpaceExample2,
      afterImage: AfterSpaceExample2,
    },
    {
      name: "Example3",
      beforeImage: BeforeSpaceExample3,
      afterImage: AfterSpaceExample3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Gewerbeimmobilien-Renderings</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Erstellen Sie fotorealistische Gewerbeimmobilien-Renderings
                  automatisch
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Sofort:</strong> Renderings in Sekunden generieren
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Keine technischen Kenntnisse
                    erforderlich
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Realistisch:</strong> Fotorealistische Ergebnisse
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Support:</strong> Experten-Unterstützung verfügbar
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 für 100 Renderings
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Direktor für Fotografie bei aProperties"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO bei Actívox"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO bei Gilart Consultors"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Über 100 Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Vor dem Gewerbeimmobilien-Rendering"
                  altAfter="Nach dem Gewerbeimmobilien-Rendering"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Retail" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Retail")}
                    >
                      Einzelhandelsraum
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Office" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Office")}
                    >
                      Bürodesign
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Restaurant" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Restaurant")}
                    >
                      Restaurant-Layout
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Showroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Showroom")}
                    >
                      Supermarkt-Design
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          VON ÜBER 5.000 PROFIS VERTRAUT
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Erstellen Sie professionelle Gewerbeimmobilien-Renderings{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatisch
          </span>{" "}
          in Sekunden.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Räume sofort transformieren</h3>
                <div className="feature-text">
                  Fotorealistische Renderings mit einem Klick generieren
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Mehrere Designoptionen</h3>
                <div className="feature-text">
                  Verschiedene Layouts und Konfigurationen einfach visualisieren
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perfekte Perspektiven</h3>
                <div className="feature-text">
                  Kamerawinkel automatisch korrigieren und optimieren
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Automatische Beleuchtung</h3>
                <div className="feature-text">
                  Professionelle Lichtanpassungen in jedem Rendering
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Materialien ändern</h3>
                <div className="feature-text">
                  Oberflächen und Ausführungen automatisch aktualisieren
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Sauberes Szenen-Setup</h3>
                <div className="feature-text">
                  Unerwünschte Objekte automatisch entfernen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Beispiele unserer automatischen Gewerbeimmobilien-Renderings.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Vor dem Gewerbeimmobilien-Rendering"
            altAfter="Nach dem Gewerbeimmobilien-Rendering"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Wie funktionieren automatische Gewerbeimmobilien-Renderings?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Laden Sie Ihr Gewerbeimmobilien-Foto hoch</h2>
            <div className="value-prop-text">
              Laden Sie ein beliebiges Foto Ihrer Gewerbeimmobilie auf die
              Pedra-Plattform hoch.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Gewerbeimmobilien-Fotos für automatisches Rendering in Pedra hochlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>Wählen Sie Ihre Rendering-Optionen</h2>
            <div className="value-prop-text">
              Wählen Sie aus, wie Sie Ihren Raum transformieren möchten.
              <p>
                Ändern Sie Materialien, aktualisieren Sie die Beleuchtung,
                modifizieren Sie das Layout, fügen Sie Elemente hinzu und mehr.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Wie man Optionen für automatisches Gewerbeimmobilien-Rendering auswählt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>Erhalten Sie Ihre fotorealistischen Renderings</h2>
            <div className="value-prop-text">
              Erhalten Sie Ihre professionell gerenderten
              Gewerbeimmobilien-Bilder in Sekunden.
              <p>
                Laden Sie Ihre Renderings sofort herunter und verwenden Sie sie.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Wie man automatische Gewerbeimmobilien-Renderings herunterlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Professionelle Gewerbeimmobilien-Renderings für €29 pro Monat. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Keine Vertragsbindung erforderlich.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Enthält</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 Renderings
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Räume sofort transformieren
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Materialien und Oberflächen ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Neue Elemente hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Automatische Szenenbereinigung
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Hochauflösende Ausgabe
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Mehrere Layout-Optionen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Professionelle Beleuchtung
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Projekte
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Teilen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Benutzerdefinierte Wasserzeichen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Prioritäts-Support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Jederzeit kündbar.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Zusätzliche Renderings bei Bedarf für je €0,20 verfügbar.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Fragen zu automatischen Gewerbeimmobilien-Renderings
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Was sind automatische Gewerbeimmobilien-Renderings?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Automatische Gewerbeimmobilien-Renderings nutzen
                fortschrittliche KI-Technologie, um normale
                Gewerbeimmobilien-Fotos in professionelle, fotorealistische
                Visualisierungen zu verwandeln. Diese Technologie ermöglicht es
                Ihnen, hochwertige Renderings ohne manuelle 3D-Modellierung oder
                komplexe Rendering-Software zu erstellen.
                <br />
                <br />
                Das System verarbeitet automatisch Beleuchtung, Materialien,
                Perspektiven und andere technische Aspekte, um sofort
                professionelle Ergebnisse zu liefern.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was sind die Vorteile von automatischen
                Gewerbeimmobilien-Renderings?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Automatische Gewerbeimmobilien-Renderings bieten zahlreiche
                Vorteile:
                <br />
                <br />
                1. Geschwindigkeit: Erstellen Sie Renderings in Sekunden, nicht
                in Stunden oder Tagen
                <br />
                2. Kostengünstig: Keine teure 3D-Modellierungssoftware
                erforderlich
                <br />
                3. Einfache Bedienung: Keine technischen Kenntnisse erforderlich
                <br />
                4. Mehrere Optionen: Testen Sie schnell verschiedene Layouts
                <br />
                5. Fotorealistische Ergebnisse: Professionelle Qualität
                <br />
                6. Flexibilität: Sofortige Änderungen möglich
                <br />
                7. Konsistenz: Zuverlässige Ergebnisse jedes Mal
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Was kann ich mit Pedras Gewerbeimmobilien-Renderings machen?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Pedras automatisches Rendering-System für Gewerbeimmobilien
                bietet vielfältige Möglichkeiten:
                <br />
                <br />
                1. Renderings erstellen: Erstellen Sie sofort fotorealistische
                Visualisierungen aus jedem Gewerbeimmobilien-Foto.
                <br />
                2. Beleuchtungsoptimierung: Automatische Verbesserung von Licht
                und Schatten für professionelle Ergebnisse.
                <br />
                3. Materialänderungen: Ändern Sie Oberflächen, Wände, Böden und
                andere Materialien mit einem Klick.
                <br />
                4. Elemente hinzufügen: Fügen Sie automatisch neue
                Einrichtungsgegenstände, Möbel und Dekorationselemente hinzu.
                <br />
                5. Aufräumen der Szene: Entfernen Sie automatisch unerwünschte
                Objekte.
                <br />
                6. Layout-Visualisierung: Testen Sie schnell verschiedene
                Raumkonfigurationen.
                <br />
                <br />
                Alle Transformationen werden automatisch von unserer KI
                durchgeführt und liefern professionelle Ergebnisse in Sekunden.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderLocalDe;
