import React from "react";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function AboutUsPt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h2 className="article-subtitleh2">Olá,</h2>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Eu sou Felix Ingla, o fundador da Pedra.
              </div>
              <div style={{ marginTop: "10px" }}>
                Na primavera de 2023, eu criei a{" "}
                <Link className="article-link" to="">
                  Pedra
                </Link>
                , uma ferramenta online para transformar seus imóveis
                facilmente.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                A Pedra nasceu de{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  uma realidade
                </span>{" "}
                do setor imobiliário.
              </h2>
              <div style={{ marginTop: "10px" }}>
                Para vender um imóvel no mercado – e vendê-lo rapidamente – as
                imobiliárias precisam de boas fotografias, limpar os
                apartamentos, colocar móveis bonitos nos ambientes e até mesmo
                mostrar propostas de reforma.
              </div>
              <div style={{ marginTop: "10px" }}>
                Tudo isso requer um investimento de tempo e dinheiro. Mas nem
                sempre temos esse tempo e dinheiro.
              </div>
              <div style={{ marginTop: "10px" }}>
                É por isso que a Pedra foi criada: uma maneira fácil, rápida e
                econômica de melhorar como apresentamos um imóvel ao mercado,
                para que possamos vendê-lo mais rapidamente.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Nossos clientes são{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  nossa prioridade
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                Na{" "}
                <Link className="article-link" to="">
                  Pedra
                </Link>
                , queremos garantir a melhor experiência quando você usa nosso
                produto. Se em algum momento você tiver feedback ou precisar de
                ajuda, por favor me escreva em felix@pedra.so.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Uma ferramenta{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  poderosa e ao mesmo tempo simples
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                Com a Pedra, fornecemos às imobiliárias{" "}
                <Link className="article-link" to="/real-estate-photography">
                  acesso à inteligência artificial para melhorar suas
                  fotografias imobiliárias
                </Link>
                ,{" "}
                <Link className="article-link" to="/render">
                  gerar propostas de reforma
                </Link>
                , limpar ambientes e muito mais. E acima de tudo, oferecemos
                isso em um produto online fácil de usar – queremos que você
                economize tanto em custos quanto em tempo.
              </div>
              <br />
              <br />
              Temos orgulho em apresentar a Pedra para você.
              <br />
              E esperamos que você esteja animado para usá-la.
              <br />
              <br />
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "50%",
                    height: "64px",
                    width: "auto",
                  }}
                  src={FelixIngla}
                  alt="Felix Ingla"
                />
                <div
                  style={{
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "100%",
                  }}
                >
                  <div className="article-text" color="#000 !important">
                    Felix Ingla
                  </div>
                  <div className="article-text" style={{ color: "#878787" }}>
                    Fundador da Pedra
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPt;
