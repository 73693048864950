import React from "react";
import Article7Frame from "../Images/Article7Frame.jpg";
import Article7Frame1 from "../Images/Article7Frame1.jpg";
import Article7Frame2 from "../Images/Article7Frame2.jpg";
import Article7Frame3 from "../Images/Article7Frame3.jpg";
import Article7Frame4 from "../Images/Article7Frame4.jpg";
import Article7Frame5 from "../Images/Article7Frame5.jpg";

import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle7Pt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Exemplos de Home Staging: Antes e Depois
            </h1>
            <h2 className="title-secondary gray">
              Está procurando exemplos de home staging antes e depois? Para te
              dar uma ideia do poder desta técnica, aqui estão alguns exemplos
              de home staging para você.
            </h2>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fundador da Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador da Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article7Frame}
                alt="Exemplo de Home Staging – Antes e Depois – Capa Ilustrativa"
              />
            </div>
            <article className="article-text">
              <br />
              <p style={{ marginTop: "10px" }}>
                A seguir, vamos mostrar exemplos de home staging para um
                banheiro, cozinha, quarto e sala de estar. Vamos lá!
              </p>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Exemplo: Home Staging de uma Cozinha
              </h2>
              <p style={{ marginTop: "10px" }}>
                O primeiro exemplo é esta cozinha. Como você pode ver, a mudança
                é radical, mas a mesma arquitetura e layout dos móveis são
                mantidos.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame1}
                  alt="Exemplo de Home Staging: Cozinha Antes e Depois"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Como você pode observar no antes e depois do Home Staging, o
                "antes" estava desatualizado e desorganizado. O novo visual é
                muito mais simples. Foi feito virtualmente e inclui uma mudança
                no piso, pintura das paredes e renovação dos móveis. O conteúdo
                fora da cozinha também foi decorado para combinar com o resto da
                imagem.
              </p>
              <h2 className="article-subtitleh2">
                Exemplo: Home Staging de um Banheiro
              </h2>
              <p style={{ marginTop: "10px" }}>
                A próxima imagem é um exemplo de home staging para um banheiro.
              </p>
              <p style={{ marginTop: "10px" }}>
                Como você pode ver, a mudança é bastante radical. Passamos de um
                banheiro desatualizado e desesperadamente precisando de reforma
                para um banheiro moderno. O tamanho do espaço e o layout
                permanecem os mesmos.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame5}
                  alt="Exemplo de Home Staging: Banheiro Antes e Depois"
                />
              </div>
              <br />
              <h2 className="article-subtitleh2">Home Staging de um Quarto</h2>
              <p style={{ marginTop: "10px" }}>
                O próximo exemplo de home staging é um quarto. Como você pode
                ver, a mudança é mínima e não agressiva. O tamanho do espaço é
                mantido, e apenas uma cama e uma planta são adicionadas. Desta
                forma, o potencial comprador entende melhor o propósito deste
                espaço.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com esta decoração, você consegue imaginar sua vida no imóvel?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame3}
                  alt="Exemplo de Home Staging: Quarto Antes e Depois"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Home Staging de uma Sala de Estar
              </h2>
              <p style={{ marginTop: "10px" }}>
                O seguinte exemplo de Home Staging ilustra a capacidade de
                renovar uma sala de estar muito desatualizada.
              </p>
              <p style={{ marginTop: "10px" }}>
                Observe que o layout dos móveis na foto é o mesmo, mas
                absolutamente todos os móveis foram renovados, e um novo espaço
                foi adicionado.
              </p>
              <p style={{ marginTop: "10px" }}>
                Este exemplo de home staging também destaca a luz no espaço, que
                é um fator muito importante para os compradores ao considerar um
                imóvel.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame2}
                  alt="Exemplo de Home Staging: Sala de Estar Antes e Depois"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                A mudança na luz é justificada pela remoção das cortinas e pela
                mudança na cor das paredes. Esta mudança torna o espaço muito
                mais agradável para o potencial comprador.
              </p>

              <h2 className="article-subtitleh2">
                Exemplo de Home Staging para um Terraço
              </h2>
              <p style={{ marginTop: "10px" }}>
                Para concluir os exemplos de home staging, aqui está um exemplo
                de decoração para um terraço. Como você pode ver aqui, o terraço
                se torna muito mais habitável. Você não gostaria de tomar café
                da manhã neste terraço?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame4}
                  alt="Exemplo de Home Staging: Terraço Antes e Depois"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Parece incrível como um imóvel pode ser melhorado com um
                investimento mínimo. O orçamento pode ser tão pequeno quanto
                alguma tinta e muita limpeza se feito fisicamente, ou alguns
                cliques e menos de $50 se feito virtualmente.
              </p>
            </article>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Olá! Eu sou Felix Ingla, o fundador da{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , uma aplicação web inovadora de home staging virtual e fotografia
              imobiliária que está transformando como os imóveis são
              apresentados no mundo digital. Minha dedicação em criar tecnologia
              de ponta e soluções intuitivas estabeleceu a Pedra como uma
              ferramenta confiável para profissionais imobiliários que buscam
              melhorar seus anúncios de imóveis.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle7Pt;
