import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons (same imports as before)
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeSpace from "./Images/curtain-effect-hero/BeforeSpace.png";
import AfterSpace from "./Images/curtain-effect-hero/AfterSpace.png";
import BeforeSpaceExample1 from "./Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "./Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "./Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "./Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "./Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "./Images/curtain-effect/AfterSpaceStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingLocalFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Commerce",
    beforeImage: BeforeSpace,
    afterImage: AfterSpace,
  });

  const handleExample = (example) => {
    if (example === "Commerce") {
      setExample({
        name: "Commerce",
        beforeImage: BeforeSpace,
        afterImage: AfterSpace,
      });
    } else if (example === "Bureau") {
      setExample({
        name: "Bureau",
        beforeImage: BeforeSpaceExample1,
        afterImage: AfterSpaceExample1,
      });
    } else if (example === "Restaurant") {
      setExample({
        name: "Restaurant",
        beforeImage: BeforeSpaceExample2,
        afterImage: AfterSpaceExample2,
      });
    } else {
      setExample({
        name: "Supermarché",
        beforeImage: BeforeSpaceExample3,
        afterImage: AfterSpaceExample3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu'est-ce que le home staging local commercial ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging local commercial est une technique de valorisation immobilière qui utilise l'IA pour transformer les photos d'espaces commerciaux en visualisations professionnelles. Cette technologie vous permet de créer des mises en scène photoréalistes d'espaces commerciaux avec différents styles, finitions et agencements sans nécessiter de modélisation 3D manuelle ou de logiciel complexe.",
        },
      },
      {
        "@type": "Question",
        name: "Quels sont les avantages du home staging local commercial ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging local commercial offre plusieurs avantages, notamment : la transformation instantanée d'espaces commerciaux, la capacité de visualiser rapidement plusieurs options d'aménagement, des résultats photoréalistes de haute qualité, des économies significatives de temps et de coûts par rapport au home staging traditionnel, et la flexibilité d'expérimenter différentes configurations.",
        },
      },
      {
        "@type": "Question",
        name: "Comment fonctionne le processus de home staging local commercial ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le processus consiste à télécharger des photos de votre local commercial actuel sur notre plateforme, à sélectionner les transformations souhaitées (comme changer les finitions, ajouter des éléments ou modifier l'agenagement) et à recevoir des visualisations photoréalistes montrant l'espace transformé en quelques minutes. Notre technologie IA gère automatiquement tout le travail complexe de mise en scène.",
        },
      },
      {
        "@type": "Question",
        name: "Combien coûte le home staging local commercial ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Notre service de home staging local commercial est disponible pour 29€ HT par mois. Ce forfait vous permet de générer jusqu'à 100 transformations par mois, sans engagement à long terme.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "exempleLocal1",
    beforeImage: BeforeSpaceExample1,
    afterImage: AfterSpaceExample1,
  });

  const stagingExamples = [
    {
      name: "exempleLocal1",
      beforeImage: BeforeSpaceExample1,
      afterImage: AfterSpaceExample1,
    },
    {
      name: "exempleLocal2",
      beforeImage: BeforeSpaceExample2,
      afterImage: AfterSpaceExample2,
    },
    {
      name: "exempleLocal3",
      beforeImage: BeforeSpaceExample3,
      afterImage: AfterSpaceExample3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging Local Commercial</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Créez des mises en scène photoréalistes de locaux commerciaux
                  automatiquement
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantané :</strong> Générez des rendus en secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simple :</strong> Aucune compétence technique
                    requise
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Réaliste :</strong> Résultats photoréalistes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Support :</strong> Assistance d'experts disponible
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayer Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 rendus
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Avant le rendu du local commercial"
                  altAfter="Après le rendu du local commercial"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Commerce" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Commerce")}
                    >
                      Local Commercial
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bureau" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bureau")}
                    >
                      Bureau
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Restaurant" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Restaurant")}
                    >
                      Restaurant
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Boutique" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Boutique")}
                    >
                      Supermarché
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS FONT DÉJÀ CONFIANCE À PEDRA
          <CustomerLogos />
        </div>
      </div>
      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Créez des mises en scène professionnelles de locaux commerciaux{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatiquement
          </span>{" "}
          en quelques secondes.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Transformez les espaces instantanément
                </h3>
                <div className="feature-text">
                  Générez des rendus photoréalistes en un seul clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Multiples options d'aménagement
                </h3>
                <div className="feature-text">
                  Visualisez différents layouts et configurations facilement
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Perspectives parfaites</h3>
                <div className="feature-text">
                  Correction et optimisation automatique des angles de caméra
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Éclairage automatique</h3>
                <div className="feature-text">
                  Ajustements professionnels de l'éclairage pour chaque rendu
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Changement de matériaux</h3>
                <div className="feature-text">
                  Mettez à jour les surfaces et finitions automatiquement
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Nettoyage de scène</h3>
                <div className="feature-text">
                  Supprimez automatiquement les objets indésirables
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemples de nos transformations de home staging local commercial.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Avant le rendu du local commercial"
            altAfter="Après le rendu du local commercial"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Comment fonctionne le home staging local commercial ?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Téléchargez votre photo du local commercial</h2>
            <div className="value-prop-text">
              Téléchargez n'importe quelle photo de votre local commercial sur
              la plateforme Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des photos de locaux commerciaux pour le rendu automatique sur Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>Choisissez vos options de rendu</h2>
            <div className="value-prop-text">
              Sélectionnez comment vous souhaitez transformer votre espace.
              <p>
                Changez les matériaux, actualisez l'éclairage, modifiez
                l'agencement, ajoutez des éléments et plus encore.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Comment choisir les options pour le rendu automatique de locaux commerciaux – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Obtenez vos rendus photoréalistes</h2>
            <div className="value-prop-text">
              Recevez vos images de local commercial rendues professionnellement
              en quelques secondes.
              <p>Téléchargez et utilisez vos rendus immédiatement.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Comment télécharger les rendus automatiques de locaux commerciaux – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Rendus professionnels de locaux commerciaux pour 29€ par mois. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclut</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 rendus
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Transformez les espaces instantanément
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Changez les matériaux et finitions
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Ajoutez de nouveaux éléments
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Nettoyage automatique de scène
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Sortie haute résolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Options multiples d'agencement
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Éclairage professionnel
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Projets illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Filigranes personnalisés
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support prioritaire
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayer Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Rendus supplémentaires disponibles à 0,20€ l'unité si nécessaire.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions sur le home staging local commercial
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Qu'est-ce que le home staging local commercial ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Le home staging local commercial utilise une technologie d'IA
                avancée pour transformer des photos ordinaires d'espaces
                commerciaux en mises en scène professionnelles. Cette
                technologie vous permet de créer des visualisations de haute
                qualité sans avoir besoin de modélisation 3D manuelle ou de
                logiciel complexe.
                <br />
                <br />
                Le système gère automatiquement l'éclairage, les matériaux, les
                perspectives et autres aspects techniques pour produire des
                résultats professionnels instantanément.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les avantages du home staging local commercial ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Le home staging local commercial offre de nombreux avantages :
                <br />
                <br />
                1. Rapidité : Transformez vos espaces en secondes, pas en heures
                ou en jours
                <br />
                2. Rentable : Pas besoin de meubles ou d'accessoires physiques
                coûteux
                <br />
                3. Facile à utiliser : Aucune expertise en décoration requise
                <br />
                4. Options multiples : Testez différents styles rapidement
                <br />
                5. Résultats photoréalistes : Mise en scène de qualité
                professionnelle
                <br />
                6. Flexibilité : Effectuez des modifications instantanément
                <br />
                7. Cohérence : Résultats fiables à chaque utilisation
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Que puis-je faire avec le home staging local commercial de Pedra
                ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Le système de home staging local commercial de Pedra offre une
                large gamme de fonctionnalités :
                <br />
                <br />
                1. Mise en scène virtuelle : Créez des visualisations
                photoréalistes à partir de n'importe quelle photo d'espace
                commercial instantanément.
                <br />
                2. Optimisation de l'éclairage : Améliorez automatiquement
                l'éclairage et les ombres pour des résultats professionnels.
                <br />
                3. Changements de matériaux : Modifiez les surfaces, sols, murs
                et autres matériaux en un clic.
                <br />
                4. Ajout de mobilier : Insérez du nouveau mobilier et des
                éléments décoratifs automatiquement.
                <br />
                5. Nettoyage de scène : Supprimez automatiquement les objets
                indésirables et le désordre.
                <br />
                6. Visualisation d'agencements : Testez différentes
                configurations de l'espace rapidement.
                <br />
                <br />
                Toutes les transformations sont gérées automatiquement par notre
                IA, livrant des résultats professionnels en quelques secondes.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingLocalFr;
