import React from "react";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import OscarMelendre from "./Images/Oscar Melendre image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import MaxVivesFierro from "./Images/Max Vives-Fierro image.png";
import JordiVives from "./Images/Jordi Vives image.png";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function TestimonialsFr() {
  return (
    <>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <div className="header-stars">
              ★★★★★
              <div className="header-stars-text">
                {" "}
                "La Découverte du Siècle"
              </div>
            </div>
            <h1 className="section-first-title">Témoignages Pedra</h1>
            <div className="section-first-text">
              <h2 className="section-first-text-bold">
                Inspirez-vous des commentaires et avis des clients de Pedra.
              </h2>
              <h2 className="section-first-text-gray">
                Avis sur nos fonctionnalités de home staging virtuel et
                d'amélioration de photographies.
              </h2>
            </div>
          </div>
        </section>
      </section>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Directeur de la Photographie chez aProperties
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra fonctionne merveilleusement bien. Je pense que la meilleure
            fonctionnalité de l'outil est la rapidité avec laquelle il génère
            des images.
          </div>
          <div className="testimonial-author-container-date">
            3 juillet 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Agent Immobilier chez Huspy
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            🌈 🙌🏾 Je suis de retour{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              pour faire de la magie avec Pedra
            </a>
            … Maintenant on attend !
          </div>
          <div className="testimonial-author-container-date">
            1er juillet 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Conseillère Immobilière chez iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Conseillère Immobilière chez iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra est la découverte du siècle
          </div>
          <div className="testimonial-author-container-date">
            3 juillet 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, PDG d'Actívox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                PDG d'Actívox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra nous est très utile. Nous sommes très contents !!!!
            <div style={{ marginTop: "10px" }}>Ça devrait être plus cher.</div>
          </div>
          <div className="testimonial-author-container-date">28 mai 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Agent Immobilier chez iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Agent Immobilier chez iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Impressionnant.
            <div style={{ marginTop: "10px" }}>
              De plus, l'espace spécifique que je voulais dégager semblait plus
              compliqué car il était profond, mais il l'a fait brillamment.
            </div>
          </div>
          <div className="testimonial-author-container-date">26 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Directeur chez Lucas Fox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Directeur chez Lucas Fox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Quelques maisons ont été vendues grâce à Pedra.
            <div style={{ marginTop: "10px" }}>
              J'ai mis des photos de couverture de comment la terrasse pourrait
              avoir l'air meublée et beaucoup de gens ont commencé à cliquer sur
              l'annonce
            </div>
          </div>
          <div className="testimonial-author-container-date">7 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, PDG de Gilart Consultors"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                PDG de Gilart Consultors
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Félicitations, car ça s'est beaucoup amélioré !!!
          </div>
          <div className="testimonial-author-container-date">2 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Agent immobilier
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Avec Pedra, j'ai réussi à{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              vendre une propriété de 500k€
            </a>{" "}
            en moins d'une journée. L'appartement que j'avais à vendre était un
            appartement À RÉNOVER et avec Pedra, j'ai obtenu des rendus qui ont
            permis de visualiser le potentiel des espaces après rénovation mais
            sans rénovation !
          </div>
          <div className="testimonial-author-container-date">2 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MaxVivesFierro}
              alt="Max Vives-Fierro, Consultant Immobilier chez Fincas Lluria"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Max Vives-Fierro
              </div>
              <div className="testimonial-author-container-second-title">
                Consultant Immobilier chez Fincas Lluria
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Avec Pedra, nous avons réussi à commencer à susciter de l'intérêt
            pour une propriété à rénover qui ne recevait pas d'appels. Nous
            avons créé des rendus de comment la propriété pourrait avoir l'air
            rénovée et avons commencé à avoir des visites !
          </div>
          <div className="testimonial-author-container-date">2 mars 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Service des Ventes chez Inmobiliaria Rico"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Service des Ventes chez Inmobiliaria Rico
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Je suis satisfaite de l'application Pedra.
          </div>
          <div className="testimonial-author-container-date">24 avril 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={JordiVives}
              alt="Jordi Vives, PDG de Vives Homes"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Jordi Vives
              </div>
              <div className="testimonial-author-container-second-title">
                PDG de Vives Homes
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Vous avez pensé à tout ! Pedra a tout ce dont nous avons besoin !
          </div>
          <div className="testimonial-author-container-date">15 avril 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Conseillère Immobilière chez Monapart"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Conseillère Immobilière chez Monapart
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Je suis très satisfaite des résultats
            <div style={{ marginTop: "10px" }}>
              De plus, ils améliorent le produit en fonction des retours que
              j'ai donnés !
            </div>
          </div>
          <div className="testimonial-author-container-date">4 juin 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Encuentro Immobilier, Boadilla del Monte"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Encuentro Immobilier, Boadilla del Monte
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">C'est un bon outil.</div>
          <div className="testimonial-author-container-date">6 mai 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={OscarMelendre}
              alt="Oscar Melendre, Marketing chez I-VEO"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Oscar Melendre
              </div>
              <div className="testimonial-author-container-second-title">
                Marketing chez I-VEO
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            L'IA fonctionne assez bien par rapport à d'autres outils d'IA. Elle
            interprète très bien les espaces !
          </div>
          <div className="testimonial-author-container-date">11 mars 2024</div>
        </div>
      </div>

      <div
        className="section-first-button-container"
        style={{ marginTop: "100px" }}
      >
        <button className="button-top-of-page" onClick={redirectToPage}>
          Essayez Pedra <ArrowIcon />
        </button>
      </div>
    </>
  );
}

export default TestimonialsFr;
