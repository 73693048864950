import React from "react";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import OscarMelendre from "./Images/Oscar Melendre image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import MaxVivesFierro from "./Images/Max Vives-Fierro image.png";
import JordiVives from "./Images/Jordi Vives image.png";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function TestimonialsDe() {
  return (
    <>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <div className="header-stars">
              ★★★★★
              <div className="header-stars-text">
                {" "}
                "Die Entdeckung des Jahrhunderts"
              </div>
            </div>
            <h1 className="section-first-title">Pedra Bewertungen</h1>
            <div className="section-first-text">
              <h2 className="section-first-text-bold">
                Lassen Sie sich von den Kommentaren und Bewertungen der
                Pedra-Kunden inspirieren.
              </h2>
              <h2 className="section-first-text-gray">
                Bewertungen zu unseren virtuellen Home Staging- und
                Fotoverbesserungsfunktionen.
              </h2>
            </div>
          </div>
        </section>
      </section>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Direktor für Fotografie bei aProperties"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Direktor für Fotografie bei aProperties
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra läuft wunderbar. Ich denke, das beste Feature des Tools ist,
            wie schnell es Bilder generiert.
          </div>
          <div className="testimonial-author-container-date">3. Juli 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienmakler bei Huspy
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            🌈 🙌🏾 Ich bin zurück{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              um Magie mit Pedra zu machen
            </a>
            … Jetzt heißt es warten!
          </div>
          <div className="testimonial-author-container-date">1. Juli 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Immobilienberaterin bei iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienberaterin bei iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra ist die Entdeckung des Jahrhunderts
          </div>
          <div className="testimonial-author-container-date">3. Juli 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, Geschäftsführer bei Actívox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                Geschäftsführer bei Actívox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra ist sehr nützlich für uns. Wir sind sehr zufrieden!!!!
            <div style={{ marginTop: "10px" }}>Es sollte teurer sein.</div>
          </div>
          <div className="testimonial-author-container-date">28. Mai 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Immobilienmakler bei iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienmakler bei iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Beeindruckend.
            <div style={{ marginTop: "10px" }}>
              Außerdem schien der spezifische Bereich, den ich freimachen
              wollte, komplizierter, weil er tief war, aber es hat es brillant
              gemacht.
            </div>
          </div>
          <div className="testimonial-author-container-date">26. Juni 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager bei Lucas Fox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager bei Lucas Fox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Ein paar Häuser wurden dank Pedra verkauft.
            <div style={{ marginTop: "10px" }}>
              Ich habe Titelfotos platziert, wie die Terrasse möbliert aussehen
              könnte, und viele Leute begannen, auf die Anzeige zu klicken
            </div>
          </div>
          <div className="testimonial-author-container-date">7. Juni 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, Geschäftsführerin bei Gilart Consultors"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                Geschäftsführerin bei Gilart Consultors
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Herzlichen Glückwunsch, denn es hat sich sehr verbessert!!!
          </div>
          <div className="testimonial-author-container-date">2. Juni 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienmaklerin
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Mit Pedra ist es mir gelungen,{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              eine Immobilie im Wert von 500.000 €
            </a>{" "}
            in weniger als einem Tag zu verkaufen. Die Wohnung, die ich zum
            Verkauf hatte, war eine ZU RENOVIERENDE Wohnung und mit Pedra bekam
            ich Renderings, die es ermöglichten, das Potenzial der Räume nach
            der Renovierung zu visualisieren, aber ohne Renovierung!
          </div>
          <div className="testimonial-author-container-date">2. Juni 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MaxVivesFierro}
              alt="Max Vives-Fierro, Immobilienberater bei Fincas Lluria"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Max Vives-Fierro
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienberater bei Fincas Lluria
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Mit Pedra ist es uns gelungen, Interesse an einer zu renovierenden
            Immobilie zu wecken, die keine Anrufe erhielt. Wir erstellten
            Renderings, wie die Immobilie renoviert aussehen könnte, und
            begannen Besichtigungen zu bekommen!
          </div>
          <div className="testimonial-author-container-date">2. März 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Verkaufsabteilung bei Inmobiliaria Rico"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Verkaufsabteilung bei Inmobiliaria Rico
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Ich bin zufrieden mit der Pedra-Anwendung.
          </div>
          <div className="testimonial-author-container-date">
            24. April 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={JordiVives}
              alt="Jordi Vives, Geschäftsführer von Vives Homes"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Jordi Vives
              </div>
              <div className="testimonial-author-container-second-title">
                Geschäftsführer von Vives Homes
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Ihr habt an alles gedacht! Pedra hat alles, was wir brauchen!
          </div>
          <div className="testimonial-author-container-date">
            15. April 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Immobilienberaterin bei Monapart"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienberaterin bei Monapart
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Ich bin sehr zufrieden mit den Ergebnissen
            <div style={{ marginTop: "10px" }}>
              Außerdem verbessern sie das Produkt basierend auf meinem Feedback!
            </div>
          </div>
          <div className="testimonial-author-container-date">4. Juni 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Encuentro Immobilien, Boadilla del Monte"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Encuentro Immobilien, Boadilla del Monte
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">Es ist ein gutes Tool.</div>
          <div className="testimonial-author-container-date">6. Mai 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={OscarMelendre}
              alt="Oscar Melendre, Marketing bei I-VEO"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Oscar Melendre
              </div>
              <div className="testimonial-author-container-second-title">
                Marketing bei I-VEO
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Die KI funktioniert im Vergleich zu anderen KI-Tools sehr gut. Sie
            interpretiert Räume sehr gut!
          </div>
          <div className="testimonial-author-container-date">11. März 2024</div>
        </div>
      </div>

      <div
        className="section-first-button-container"
        style={{ marginTop: "100px" }}
      >
        <button className="button-top-of-page" onClick={redirectToPage}>
          Pedra ausprobieren <ArrowIcon />
        </button>
      </div>
    </>
  );
}

export default TestimonialsDe;
