import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToEmptyRoomFrame1 from "../Images/HowToEmptyRoomFrame1.jpg";
import HowToEmptyRoomFrame2 from "../Images/HowToEmptyRoomFrame2.jpg";
import Example1Before from "../Images/curtain-effect-empty-room/Example1Before.jpeg";
import Example1After from "../Images/curtain-effect-empty-room/Example1After.jpeg";
import Example2Before from "../Images/curtain-effect-empty-room/Example2Before.jpeg";
import Example2After from "../Images/curtain-effect-empty-room/Example2After.jpeg";
import Example3Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example3After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import Example4Before from "../Images/curtain-effect-empty-room/Example4Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example4After.jpeg";
import CurtainEffect from "../CurtainEffect";

function HowToEmptyRoomPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Esvaziar Ambientes Virtualmente
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como esvaziar ambientes virtualmente
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Como posso esvaziar ambientes virtualmente?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                A Pedra oferece uma opção que permite esvaziar ambientes em
                apenas 1 clique.
              </p>
              <p style={{ marginTop: "10px" }}>
                Para esvaziar um ambiente, acesse o menu{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                clique em{" "}
                <span style={{ fontWeight: "500" }}>"Esvaziar ambiente"</span>.
                Em 2 ou 3 minutos, você terá uma imagem do seu ambiente vazio,
                pronta para baixar.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame1}
                alt="Como esvaziar ambientes em casas e imóveis – Captura de tela do software Pedra"
              />
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Como posso obter melhores resultados ao esvaziar um ambiente?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Se a função{" "}
                <span style={{ fontWeight: "500" }}>"Esvaziar ambiente"</span>{" "}
                deixou objetos ou partes não esvaziadas, ou adicionou janelas ou
                portas extras, você pode usar a ferramenta{" "}
                <span style={{ fontWeight: "500" }}>"Excluir objeto"</span> na
                imagem esvaziada para terminar de remover tudo o que você não
                quer da foto.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame2}
                alt="Como esvaziar ambientes em partes – Captura de tela do software Pedra"
              />
              <p>
                E se você quiser maior qualidade na fotografia, clique na opção{" "}
                <span style={{ fontWeight: "500" }}>"Converter para HD"</span>{" "}
                quando terminar de excluir os objetos.
              </p>
              <p style={{ marginTop: "10px" }}>
                Para esvaziar, acesse o menu{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                clique em{" "}
                <span style={{ fontWeight: "500" }}>"Esvaziar ambiente"</span>.
                Em 2 ou 3 minutos, você terá seu ambiente vazio, pronto para
                baixar.
              </p>
              <aside className="callout" style={{ marginTop: "10px" }}>
                <div>
                  <InfoIcon />
                </div>
                <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                  Se você não conseguir esvaziar as imagens corretamente, pode
                  enviar um e-mail para{" "}
                  <span style={{ fontWeight: "500" }}>felix@pedra.so</span> e
                  nós as devolveremos esvaziadas em 24-48 horas. Você deve estar
                  inscrito na Pedra para acessar este serviço.
                </h4>
              </aside>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Por que devo esvaziar um imóvel virtualmente?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Mostrar ambientes sem móveis pode ser fundamental ao vender um
                imóvel.
              </p>
              <p style={{ marginTop: "10px" }}>
                Por um lado, o vendedor se sentirá mais confortável sabendo que
                não estamos mostrando todos os seus pertences pessoais online.
                Imagine se todos pudessem ver seu quarto online – ningu��m gosta
                disso.
              </p>
              <p style={{ marginTop: "10px" }}>
                Por outro lado, o comprador ficará mais atraído por uma casa
                despersonalizada e poderá realmente ver o potencial do imóvel,
                ou imaginar melhor sua futura casa.
              </p>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Exemplos de esvaziamento virtual de ambientes
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de ambientes esvaziados virtualmente
              com a Pedra:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de esvaziar o ambiente virtualmente"}
              altAfter={"Depois de esvaziar o ambiente virtualmente"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de esvaziar o ambiente virtualmente"}
              altAfter={"Depois de esvaziar o ambiente virtualmente"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de esvaziar o ambiente virtualmente"}
              altAfter={"Depois de esvaziar o ambiente virtualmente"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de esvaziar o ambiente virtualmente"}
              altAfter={"Depois de esvaziar o ambiente virtualmente"}
            />

            <br />
            {/* 
            <h2 className="article-subtitleh2">
              Tutorial em vídeo: Como esvaziar um ambiente virtualmente
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    maxWidth: "900px",
                    height: "500px",
                  }}
                  frameBorder="0"
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  allowFullScreen
                  title="IA para vender imóveis"
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToEmptyRoomPt;
