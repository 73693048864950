import React from "react";
import ReleaseJun2024Frame1 from "../Images/ReleaseJun2024Frame1.png";
import ReleaseJun2024Frame2 from "../Images/ReleaseJun2024Frame2.png";
import ReleaseJun2024Frame3 from "../Images/ReleaseJun2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJun24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">JUNHO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Renove espaços pelo celular, edite legendas e altere marcas
            d'água
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Agora você pode usar a Pedra pelo celular. Aqui está este
              lançamento e o progresso que fizemos neste junho:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Pedra para celular</h2>
              Agora você pode usar a Pedra pelo navegador do celular. Ideal para
              impressionar clientes durante visitas, criar uma{" "}
              <Link to="/render" className="article-link">
                proposta rápida de reforma
              </Link>{" "}
              ou editar fotos quando estiver longe do computador.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame1}
                  alt="Renovar espaços com IA pelo celular"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Mova a marca d'água e edite sua transparência
              </h2>
              Agora você pode editar o nível de transparência das marcas d'água,
              e também pode colocá-las onde quiser na sua imagem. Proteja sua
              marca sem obstruir suas fotos.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJun2024Frame2}
                  alt="Mover e editar transparência da marca d'água"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">Altere o texto da legenda</h2>
              Garanta que as pessoas que veem seus infográficos saibam que eles
              não são reais. Agora você pode editar o texto da legenda e seu
              tamanho.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJun2024Frame3}
                alt="Alterar a legenda de uma fotografia com IA"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>

            <div>
              <li>Aumento na qualidade dos móveis inseridos em "Mobiliar"</li>
              <li>Opção para "Mobiliar" uma entrada</li>
              <li>
                Novo design da página inicial: veja qual é a primeira imagem
                dentro de cada projeto sem precisar abri-lo
              </li>
              <li>
                Adicionamos um tutorial de 2 minutos para você saber como usar a
                Pedra, e você pode assistir{" "}
                <a
                  className="article-link"
                  href="https://www.youtube.com/watch?v=z6O8EX2fJPI"
                  rel="nofollow"
                >
                  aqui
                </a>{" "}
              </li>{" "}
              <li>Opção de escolher porcelanato ao "Editar piso"</li>
              <li>Várias correções</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJun24Pt;
