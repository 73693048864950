import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const urlMappings = {
  en: {
    "/es": "/",
    "/fr": "/",
    "/es/realestate": "/realestate",
    "/fr/realestate": "/realestate",
    "/es/real-estate-photography": "/real-estate-photography",
    "/fr/real-estate-photography": "/real-estate-photography",
    "/es/floorplan": "/floorplan",
    "/fr/floorplan": "/floorplan",
    "/es/interiordesign": "/interiordesign",
    "/fr/interiordesign": "/interiordesign",
    "/es/render": "/render",
    "/fr/render": "/render",
    "/es/api-documentation": "/api-documentation",
    "/fr/api-documentation": "/api-documentation",
    "/es/blog/como-vender-casa": "/",
    "/es/blog/home-staging-ejemplos": "/blog/home-staging-examples",
    "/fr/blog/exemples-de-home-staging": "/blog/home-staging-examples",
    "/es/pricing": "/pricing",
    "/fr/pricing": "/pricing",
    "/es/blog/como-utilizar-ia-para-vender-propiedades":
      "/blog/how-to-sell-properties-with-ai",
    "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes":
      "/blog/how-to-sell-properties-with-ai",
    "/es/blog": "/blog",
    "/fr/blog": "/blog",
    "/es/blog/renovar-casa": "/",
    "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia":
      "/blog/sell-more-properties-with-ai",
    "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia":
      "/blog/sell-more-properties-with-ai",
    "/es/releases": "/releases",
    "/fr/releases": "/releases",
    "/es/releases/renueva-espacios-desde-el-movil-con-ia":
      "/releases/renovate-interiors-with-ai-from-phone",
    "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone":
      "/releases/renovate-interiors-with-ai-from-phone",
    "/es/home-staging-virtual": "/home-staging-virtual",
    "/fr/home-staging-virtual": "/home-staging-virtual",
    "/es/legal": "/legal",
    "/fr/legal": "/legal",
    "/es/privacy": "/privacy",
    "/fr/privacy": "/privacy",
    "/es/mls": "/",
    "/es/about": "/about",
    "/fr/about": "/about",
    "/es/help/como-anadir-objetos-3d": "/help/how-to-add-3d-objects",
    "/fr/help/comment-ajouter-des-objets-3d": "/help/how-to-add-3d-objects",
    "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta":
      "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
    "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere":
      "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
    "/es/releases/vacia-habitacion-y-quita-objetos-con-ia":
      "/releases/empty-rooms-and-remove-objects-with-ai",
    "/fr/releases/vide-chambres-et-enlever-objets-avec-ia":
      "/releases/empty-rooms-and-remove-objects-with-ai",
    "/es/reviews-and-testimonials": "/reviews-and-testimonials",
    "/fr/reviews-and-testimonials": "/reviews-and-testimonials",
    "/es/releases/anade-objetos-3d-y-cielo-azul":
      "/releases/add-3d-objects-and-make-sky-blue",
    "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu":
      "/releases/add-3d-objects-and-make-sky-blue",
    "/es/help/como-renovar-casas-virtualmente":
      "/help/how-to-renovate-houses-virtually",
    "/fr/help/comment-renover-des-maisons-en-virtuel":
      "/help/how-to-renovate-houses-virtually",
    "/es/help/como-amueblar-virtualmente": "/help/how-to-furnish-virtually",
    "/fr/help/comment-meubler-en-virtuel": "/help/how-to-furnish-virtually",
    "/es/help/como-borrar-objeto": "/help/how-to-remove-object",
    "/fr/help/comment-supprimer-un-objet": "/help/how-to-remove-object",
    "/es/blog/espacios-multifuncionales": "/blog/multifunctional-spaces",
    "/fr/blog/espaces-multifonctionnels": "/blog/multifunctional-spaces",
    "/es/blog/aumenta-valor-vivienda": "/blog/increase-home-value",
    "/fr/blog/augmentez-valeur-maison": "/blog/increase-home-value",
    "/es/blog/marketing-inmobiliario": "/blog/real-estate-marketing",
    "/fr/blog/marketing-immobilier": "/blog/real-estate-marketing",
    "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis":
      "/free-tools/generate-real-estate-listing-description-free",
    "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit":
      "/free-tools/generate-real-estate-listing-description-free",
    "/es/blog/tendencias-mercado-inmobiliario":
      "/blog/real-estate-market-trends",
    "/fr/blog/tendances-marche-immobilier": "/blog/real-estate-market-trends",
    "/es/releases/amueblado-realista": "/releases/realistic-furnishing",
    "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres":
      "/releases/realistic-furnishing",
    "/es/blog/publicidad-inmobiliaria": "/blog/real-estate-advertising",
    "/fr/blog/publicite-immobiliere": "/blog/real-estate-advertising",
    "/es/help/como-vaciar-habitacion-virtualmente":
      "/help/how-to-empty-rooms-virtually",
    "/fr/help/comment-vider-pieces-virtuellement":
      "/help/how-to-empty-rooms-virtually",
    "/es/blog/pricing-inmobiliario": "/blog/real-estate-pricing",
    "/fr/blog/pricing-immobilier": "/blog/real-estate-pricing",
    "/es/blog/mentor-inmobiliario": "/blog/real-estate-mentor",
    "/fr/blog/mentor-immobilier": "/blog/real-estate-mentor",
    "/es/blog/como-personalizar-habitacion": "/blog/how-to-personalize-room",
    "/fr/blog/comment-personnaliser-chambre": "/blog/how-to-personalize-room",
    "/es/help/como-crear-home-staging-virtual":
      "/help/how-to-create-virtual-home-staging",
    "/fr/help/comment-creer-home-staging-virtuel":
      "/help/how-to-create-virtual-home-staging",
    "/es/blog/salas-hundidas": "/blog/sunken-living-rooms",
    "/fr/blog/salons-en-contrebas": "/blog/sunken-living-rooms",
    "/es/blog/domotizar-casa": "/blog/home-automation",
    "/fr/blog/domotiser-maison": "/blog/home-automation",
    "/es/blog/propiedad-no-se-vende": "/blog/property-that-wont-sell",
    "/fr/blog/propriete-qui-ne-se-vend-pas": "/blog/property-that-wont-sell",
    "/fr/versions/renovez-et-meublez-en-un-clic":
      "/releases/renovate-and-furnish-in-one-click",
    "/es/lanzamientos/renueva-y-amuebla-en-un-clic":
      "/releases/renovate-and-furnish-in-one-click",
    "/es/blog/flipping": "/blog/flipping",
    "/fr/blog/flipping": "/blog/flipping",
    "/fr/blog/modernizar-casa": "/blog/modernize-home",
    "/es/blog/moderniser-maison": "/blog/modernize-home",
    "/es/blog/diseñador-ambientes": "/blog/environmental-designer",
    "/fr/blog/designer-environnement": "/blog/environmental-designer",
    "/es/home-staging-cocina": "/home-staging-kitchen",
    "/fr/home-staging-cuisine": "/home-staging-kitchen",
    "/fr/home-staging-salon": "/home-staging-living-room",
    "/es/home-staging-salon": "/home-staging-living-room",
    "/es/blog/short-sale": "/blog/short-sale",
    "/fr/blog/short-sale": "/blog/short-sale",
    "/fr/blog/erreurs-vente-maison": "/blog/mistakes-when-selling-house",
    "/es/blog/errores-al-vender-casa": "/blog/mistakes-when-selling-house",
    "/es/home-staging-terraza": "/home-staging-terrace",
    "/fr/home-staging-terrasse": "/home-staging-terrace",
    "/es/home-staging-dormitorio": "/home-staging-bedroom",
    "/fr/home-staging-chambre": "/home-staging-bedroom",
    "/fr/home-staging-salle-de-bain": "/home-staging-bathroom",
    "/es/home-staging-bano": "/home-staging-bathroom",
    "/es/blog/bienes-raices": "/blog/real-estate",
    "/fr/blog/immobilier": "/blog/real-estate",
    "/fr/help/comment-creer-home-staging-virtuel-terrasse":
      "/help/how-to-create-terrace-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-terraza":
      "/help/how-to-create-terrace-virtual-home-staging",
    "/es/blog/frases-inmobiliarias": "/blog/real-estate-phrases",
    "/fr/blog/phrases-immobilieres": "/blog/real-estate-phrases",
    "/fr/help/comment-creer-home-staging-virtuel-chambre":
      "/help/how-to-create-bedroom-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-dormitorio":
      "/help/how-to-create-bedroom-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-salon":
      "/help/how-to-create-living-room-virtual-home-staging",
    "/fr/help/comment-creer-home-staging-virtuel-salon":
      "/help/how-to-create-living-room-virtual-home-staging",
    "/fr/blog/fiducie-immobiliere": "/blog/real-estate-trust",
    "/es/blog/fideicomiso-inmobiliario": "/blog/real-estate-trust",
    "/fr/outils-gratuits/convertisseur-format-image":
      "/free-tools/free-image-format-converter",
    "/es/herramientas-gratis/convertidor-formato-imagen-gratuito":
      "/free-tools/free-image-format-converter",
    "/fr/blog/terrains-constructibles": "/blog/buildable-land",
    "/es/blog/terrenos-urbanizables": "/blog/buildable-land",
    "/fr/help/comment-creer-home-staging-virtuel-cuisine":
      "/help/how-to-create-kitchen-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-cocina":
      "/help/how-to-create-kitchen-virtual-home-staging",
    "/es/help/como-crear-home-staging-virtual-bano":
      "/help/how-to-create-bathroom-virtual-home-staging",
    "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain":
      "/help/how-to-create-bathroom-virtual-home-staging",
    "/es/releases/mejoras-pedra-piscinas-y-preservacion":
      "/releases/pedra-improvements-pools-and-preservation",
    "/fr/releases/ameliorations-pedra-piscines-et-preservation":
      "/releases/pedra-improvements-pools-and-preservation",
    "/fr/rendus-de-renovation": "/renders-for-renovations",
    "/es/renders-para-reformas": "/renders-for-renovations",
    "/es/blog/como-vender-casa-a-reformar":
      "/blog/how-to-sell-a-house-to-renovate",
    "/fr/blog/comment-vendre-une-maison-a-renover":
      "/blog/how-to-sell-a-house-to-renovate",
    "/es/render-cocina": "/render-kitchen",
    "/fr/rendu-cuisine": "/render-kitchen",
    "/es/render-dormitorio": "/render-bedroom",
    "/fr/rendu-chambre": "/render-bedroom",
    "/fr/rendu-salle-de-bain": "/render-bathroom",
    "/es/render-bano": "/render-bathroom",
    "/es/render-salon": "/render-living-room",
    "/fr/rendu-salon": "/render-living-room",
    "/fr/rendu-terrasse": "/render-terrace",
    "/es/render-terraza": "/render-terrace",
    "/es/render-casa": "/render-house",
    "/fr/rendu-maison": "/render-house",
    "/fr/help/comment-creer-rendu-cuisine":
      "/help/how-to-create-kitchen-render",
    "/es/help/como-crear-render-cocina": "/help/how-to-create-kitchen-render",
    "/es/help/como-crear-render-bano": "/help/how-to-create-bathroom-render",
    "/fr/help/comment-creer-rendu-salle-de-bain":
      "/help/how-to-create-bathroom-render",
    "/es/help/como-crear-render-sala-estar":
      "/help/how-to-create-living-room-render",
    "/fr/help/comment-creer-rendu-salon":
      "/help/how-to-create-living-room-render",
    "/fr/help/comment-creer-rendu-chambre":
      "/help/how-to-create-bedroom-render",
    "/es/help/como-crear-render-dormitorio":
      "/help/how-to-create-bedroom-render",
    "/es/help/como-crear-render-terraza": "/help/how-to-create-terrace-render",
    "/fr/help/comment-creer-rendu-terrasse":
      "/help/how-to-create-terrace-render",
    "/fr/help/comment-dessiner-plan": "/help/how-to-draw-floor-plan",
    "/es/help/como-dibujar-plano": "/help/how-to-draw-floor-plan",
    "/fr/help/comment-creer-rendu-exterieur-maison":
      "/help/how-to-create-house-exterior-render",
    "/es/help/como-crear-render-exterior-casa":
      "/help/how-to-create-house-exterior-render",
    "/es/releases/espacios-de-trabajo": "/releases/workspaces",
    "/fr/releases/espaces-de-travail": "/releases/workspaces",
    "/es/blog/home-staging-tradicional-vs-home-staging-virtual":
      "/blog/traditional-home-staging-vs-virtual-home-staging",
    "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel":
      "/blog/traditional-home-staging-vs-virtual-home-staging",
    "/fr/render-depuis-plan": "/render-from-floor-plan",
    "/es/render-desde-plano": "/render-from-floor-plan",
    "/fr/help/rendu-plan-etage-avec-ia": "/help/render-floor-plan-with-ai",
    "/es/help/renderizar-plano-de-planta-con-ia":
      "/help/render-floor-plan-with-ai",
    "/fr/blog/rentabilite-home-staging-virtuel": "/blog/roi-virtual-staging",
    "/es/blog/rentabilidad-home-staging-virtual": "/blog/roi-virtual-staging",
    "/fr/rendu-local": "/render-commercial",
    "/es/render-local": "/render-commercial",
    "/fr/home-staging-local": "/home-staging-commercial",
    "/es/home-staging-local": "/home-staging-commercial",
    "/es/help/como-crear-render-local-comercial":
      "/help/how-to-create-commercial-space-render",
    "/fr/help/comment-creer-rendu-local-commercial":
      "/help/how-to-create-commercial-space-render",
    "/fr/releases/plans-et-espaces-commerciaux":
      "/releases/floorplans-and-commercial-spaces",
    "/es/releases/planos-y-espacios-comerciales":
      "/releases/floorplans-and-commercial-spaces",
    "/pt/about": "/about",
    "/pt/api-documentation": "/api-documentation",
    "/pt/blog": "/blog",
    "/pt/blog/como-vender-imoveis-com-ia":
      "/blog/how-to-sell-properties-with-ai",
    "/pt/blog/marketing-imobiliario": "/blog/real-estate-marketing",
    "/pt/blog/tendencias-mercado-imobiliario":
      "/blog/real-estate-market-trends",
    "/pt/blog/publicidade-imobiliaria": "/blog/real-estate-advertising",
    "/pt/blog/precificacao-imobiliaria": "/blog/real-estate-pricing",
    "/pt/blog/mentor-imobiliario": "/blog/real-estate-mentor",
    "/pt/blog/como-personalizar-ambientes": "/blog/how-to-personalize-room",
    "/pt/blog/salas-rebaixadas": "/blog/sunken-living-rooms",
    "/pt/blog/automacao-residencial": "/blog/home-automation",
    "/pt/blog/imovel-que-nao-vende": "/blog/property-that-wont-sell",
    "/pt/blog/flipping": "/blog/flipping",
    "/pt/blog/modernizar-casa": "/blog/modernize-home",
    "/pt/blog/designer-ambiental": "/blog/environmental-designer",
    "/pt/blog/venda-rapida": "/blog/short-sale",
    "/pt/blog/erros-ao-vender-casa": "/blog/mistakes-when-selling-house",
    "/pt/blog/imoveis": "/blog/real-estate",
    "/pt/blog/frases-imobiliarias": "/blog/real-estate-phrases",
    "/pt/blog/confianca-imobiliaria": "/blog/real-estate-trust",
    "/pt/blog/terreno-construivel": "/blog/buildable-land",
    "/pt/blog/como-vender-casa-para-reforma":
      "/blog/how-to-sell-a-house-to-renovate",
    "/pt/blog/home-staging-tradicional-vs-virtual":
      "/blog/traditional-home-staging-vs-virtual-home-staging",
    "/pt/blog/roi-home-staging-virtual": "/blog/roi-virtual-staging",
    "/pt/blog/vender-mais-imoveis-com-ia": "/blog/sell-more-properties-with-ai",
    "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio":
      "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
    "/pt/blog/como-fazer-ceu-azul-foto-imobiliaria":
      "/blog/how-to-make-sky-blue-real-estate-photo",
    "/pt/blog/exemplos-home-staging": "/blog/home-staging-examples",
    "/pt/blog/espacos-multifuncionais": "/blog/multifunctional-spaces",
    "/pt/blog/aumentar-valor-imovel": "/blog/increase-home-value",
    "/pt/planta-baixa": "/floorplan",
    "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis":
      "/free-tools/generate-real-estate-listing-description-free",
    "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis":
      "/free-tools/free-image-format-converter",
    "/pt/ajuda": "/help",
    "/pt": "/",
    "/pt/home-staging-virtual": "/home-staging-virtual",
    "/pt/home-staging-banheiro": "/home-staging-bathroom",
    "/pt/home-staging-quarto": "/home-staging-bedroom",
    "/pt/home-staging-cozinha": "/home-staging-kitchen",
    "/pt/home-staging-sala": "/home-staging-living-room",
    "/pt/home-staging-local": "/home-staging-commercial",
    "/pt/home-staging-terraco": "/home-staging-terrace",
    "/pt/ajuda/como-adicionar-objetos-3d": "/help/how-to-add-3d-objects",
    "/pt/ajuda/como-desenhar-planta-baixa": "/help/how-to-draw-floor-plan",
    "/pt/ajuda/como-esvaziar-ambientes-virtualmente":
      "/help/how-to-empty-rooms-virtually",
    "/pt/ajuda/como-mobiliar-virtualmente": "/help/how-to-furnish-virtually",
    "/pt/ajuda/como-remover-objetos": "/help/how-to-remove-object",
    "/pt/ajuda/como-criar-render-banheiro":
      "/help/how-to-create-bathroom-render",
    "/pt/ajuda/como-criar-render-quarto": "/help/how-to-create-bedroom-render",
    "/pt/ajuda/renderizar-planta-baixa-com-ia":
      "/help/render-floor-plan-with-ai",
    "/pt/ajuda/como-criar-render-externa-casa":
      "/help/how-to-create-house-exterior-render",
    "/pt/ajuda/como-criar-render-cozinha": "/help/how-to-create-kitchen-render",
    "/pt/ajuda/como-criar-render-sala":
      "/help/how-to-create-living-room-render",
    "/pt/ajuda/como-criar-render-local-comercial":
      "/help/how-to-create-commercial-space-render",
    "/pt/ajuda/como-criar-render-terraco": "/help/how-to-create-terrace-render",
    "/pt/ajuda/como-renovar-casas-virtualmente":
      "/help/how-to-renovate-houses-virtually",
    "/pt/ajuda/como-criar-home-staging-virtual":
      "/help/how-to-create-virtual-home-staging",
    "/pt/ajuda/como-criar-home-staging-virtual-banheiro":
      "/help/how-to-create-bathroom-virtual-home-staging",
    "/pt/ajuda/como-criar-home-staging-virtual-quarto":
      "/help/how-to-create-bedroom-virtual-home-staging",
    "/pt/ajuda/como-criar-home-staging-virtual-cozinha":
      "/help/how-to-create-kitchen-virtual-home-staging",
    "/pt/ajuda/como-criar-home-staging-virtual-sala":
      "/help/how-to-create-living-room-virtual-home-staging",
    "/pt/ajuda/como-criar-home-staging-virtual-terraco":
      "/help/how-to-create-terrace-virtual-home-staging",
    "/pt/interiordesign": "/interiordesign",
    "/pt/legal": "/legal",
    "/de/about": "/about",
    "/de/api-documentation": "/api-documentation",
    "/de/blog": "/blog",
    "/de/blog/wie-man-immobilien-mit-ki-verkauft":
      "/blog/how-to-sell-properties-with-ai",
    "/de/blog/immobilien-marketing": "/blog/real-estate-marketing",
    "/de/blog/immobilienmarkt-trends": "/blog/real-estate-market-trends",
    "/de/blog/immobilien-werbung": "/blog/real-estate-advertising",
    "/de/blog/immobilien-preisgestaltung": "/blog/real-estate-pricing",
    "/de/blog/immobilien-mentor": "/blog/real-estate-mentor",
    "/de/blog/wie-man-raume-personalisiert": "/blog/how-to-personalize-room",
    "/de/blog/versenktes-wohnzimmer": "/blog/sunken-living-rooms",
    "/de/blog/hausautomatisierung": "/blog/home-automation",
    "/de/blog/immobilie-die-sich-nicht-verkauft":
      "/blog/property-that-wont-sell",
    "/de/blog/flipping": "/blog/flipping",
    "/de/blog/haus-modernisieren": "/blog/modernize-home",
    "/de/blog/umweltdesigner": "/blog/environmental-designer",
    "/de/blog/schnellverkauf": "/blog/short-sale",
    "/de/blog/fehler-beim-hausverkauf": "/blog/mistakes-when-selling-house",
    "/de/blog/immobilien": "/blog/real-estate",
    "/de/blog/immobilien-phrasen": "/blog/real-estate-phrases",
    "/de/blog/immobilien-vertrauen": "/blog/real-estate-trust",
    "/de/blog/bauland": "/blog/buildable-land",
    "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft":
      "/blog/how-to-sell-a-house-to-renovate",
    "/de/blog/traditionelles-vs-virtuelles-home-staging":
      "/blog/traditional-home-staging-vs-virtual-home-staging",
    "/de/blog/roi-virtuelles-home-staging": "/blog/roi-virtual-staging",
    "/de/blog/mehr-immobilien-mit-ki-verkaufen":
      "/blog/sell-more-properties-with-ai",
    "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt":
      "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
    "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht":
      "/blog/how-to-make-sky-blue-real-estate-photo",
    "/de/blog/home-staging-beispiele": "/blog/home-staging-examples",
    "/de/blog/multifunktionale-raume": "/blog/multifunctional-spaces",
    "/de/blog/hauswert-steigern": "/blog/increase-home-value",
    "/de/grundriss": "/floorplan",
    "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen":
      "/free-tools/generate-real-estate-listing-description-free",
    "/de/kostenlose-tools/bildformat-konverter-kostenlos":
      "/free-tools/free-image-format-converter",
    "/de/hilfe": "/help",
    "/de": "/",
    "/de/virtuelles-home-staging": "/home-staging-virtual",
    "/de/home-staging-badezimmer": "/home-staging-bathroom",
    "/de/home-staging-schlafzimmer": "/home-staging-bedroom",
    "/de/home-staging-kuche": "/home-staging-kitchen",
    "/de/home-staging-wohnzimmer": "/home-staging-living-room",
    "/de/home-staging-gewerbe": "/home-staging-commercial",
    "/de/home-staging-terrasse": "/home-staging-terrace",
    "/de/hilfe/wie-man-3d-objekte-hinzufugt": "/help/how-to-add-3d-objects",
    "/de/hilfe/wie-man-grundriss-zeichnet": "/help/how-to-draw-floor-plan",
    "/de/hilfe/wie-man-raume-virtuell-leert":
      "/help/how-to-empty-rooms-virtually",
    "/de/hilfe/wie-man-virtuell-einrichtet": "/help/how-to-furnish-virtually",
    "/de/hilfe/wie-man-objekte-entfernt": "/help/how-to-remove-object",
    "/de/hilfe/wie-man-badezimmer-rendering-erstellt":
      "/help/how-to-create-bathroom-render",
    "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt":
      "/help/how-to-create-bedroom-render",
    "/de/hilfe/grundriss-mit-ki-rendern": "/help/render-floor-plan-with-ai",
    "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt":
      "/help/how-to-create-house-exterior-render",
    "/de/hilfe/wie-man-kuchen-rendering-erstellt":
      "/help/how-to-create-kitchen-render",
    "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt":
      "/help/how-to-create-living-room-render",
    "/de/hilfe/wie-man-gewerberaum-rendering-erstellt":
      "/help/how-to-create-commercial-space-render",
    "/de/hilfe/wie-man-terrassen-rendering-erstellt":
      "/help/how-to-create-terrace-render",
    "/de/hilfe/wie-man-hauser-virtuell-renoviert":
      "/help/how-to-renovate-houses-virtually",
    "/de/hilfe/wie-man-virtuelles-home-staging-erstellt":
      "/help/how-to-create-virtual-home-staging",
    "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt":
      "/help/how-to-create-bathroom-virtual-home-staging",
    "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt":
      "/help/how-to-create-bedroom-virtual-home-staging",
    "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt":
      "/help/how-to-create-kitchen-virtual-home-staging",
    "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt":
      "/help/how-to-create-living-room-virtual-home-staging",
    "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt":
      "/help/how-to-create-terrace-virtual-home-staging",
    "/de/innenarchitektur": "/interiordesign",
    "/de/rechtliches": "/legal",
    "/de/preise": "/pricing",
    "/de/datenschutz": "/privacy",
    "/de/immobilien": "/realestate",
    "/de/immobilienfotografie": "/real-estate-photography",
    "/de/releases/realistische-einrichtung": "/releases/realistic-furnishing",
    "/de/releases/grundrisse-und-gewerberaume":
      "/releases/floorplans-and-commercial-spaces",
    "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen":
      "/releases/add-3d-objects-and-make-sky-blue",
    "/de/releases/innenraume-mit-ki-vom-handy-renovieren":
      "/releases/renovate-interiors-with-ai-from-phone",
    "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen":
      "/releases/empty-rooms-and-remove-objects-with-ai",
    "/de/releases/arbeitsplatze": "/releases/workspaces",
    "/de/releases/stein-verbesserungen-pools-und-erhaltung":
      "/releases/pedra-improvements-pools-and-preservation",
    "/de/releases": "/releases",
    "/de/releases/renovieren-und-einrichten-mit-einem-klick":
      "/releases/renovate-and-furnish-in-one-click",
    "/de/render": "/render",
    "/de/badezimmer-rendering": "/render-bathroom",
    "/de/schlafzimmer-rendering": "/render-bedroom",
    "/de/rendering-vom-grundriss": "/render-from-floor-plan",
    "/de/haus-rendering": "/render-house",
    "/de/kuchen-rendering": "/render-kitchen",
    "/de/wohnzimmer-rendering": "/render-living-room",
    "/de/gewerbe-rendering": "/render-commercial",
    "/de/terrassen-rendering": "/render-terrace",
    "/de/renderings-fur-renovierungen": "/renders-for-renovations",
    "/de/reviews-and-testimonials": "/reviews-and-testimonials",

    "/es/help/como-crear-efecto-cortinilla-home-staging-virtual":
      "/help/how-to-create-virtual-home-staging-curtain-effect",
    "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel":
      "/help/how-to-create-virtual-home-staging-curtain-effect",
    "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual":
      "/help/how-to-create-virtual-home-staging-curtain-effect",
    "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt":
      "/help/how-to-create-virtual-home-staging-curtain-effect",

    "/es/blog/consejos-para-fotografia-inmobiliaria":
      "/blog/real-estate-photography-tips",
    "/fr/blog/conseils-pour-la-photographie-immobiliere":
      "/blog/real-estate-photography-tips",
    "/de/blog/tipps-fur-immobilienfotografie":
      "/blog/real-estate-photography-tips",
    "/pt/blog/dicas-de-fotografia-imobiliaria":
      "/blog/real-estate-photography-tips",

    "/es/blog/optimizacion-de-anuncios-inmobiliarios":
      "/blog/property-listing-optimization",
    "/fr/blog/optimisation-des-annonces-immobilieres":
      "/blog/property-listing-optimization",
    "/de/blog/optimierung-von-immobilienanzeigen":
      "/blog/property-listing-optimization",
    "/pt/blog/otimizacao-de-anuncios-imobiliarios":
      "/blog/property-listing-optimization",
  },
  es: {
    "/": "/es",
    "/fr": "/es",
    "/realestate": "/es/realestate",
    "/fr/realestate": "/es/realestate",
    "/real-estate-photography": "/es/real-estate-photography",
    "/fr/real-estate-photography": "/es/real-estate-photography",
    "/floorplan": "/es/floorplan",
    "/fr/floorplan": "/es/floorplan",
    "/interiordesign": "/es/interiordesign",
    "/fr/interiordesign": "/es/interiordesign",
    "/render": "/es/render",
    "/fr/render": "/es/render",
    "/api-documentation": "/es/api-documentation",
    "/fr/api-documentation": "/es/api-documentation",
    "/es/mls": "/es",
    "/es/blog/como-vender-casa": "/es/blog/como-vender-casa",
    "/blog/home-staging-examples": "/es/blog/home-staging-ejemplos",
    "/fr/blog/exemples-de-home-staging": "/es/blog/home-staging-ejemplos",
    "/pricing": "/es/pricing",
    "/fr/pricing": "/es/pricing",
    "/es/blog/renovar-casa": "/es/blog/renovar-casa",
    "/blog/how-to-sell-properties-with-ai":
      "/es/blog/como-utilizar-ia-para-vender-propiedades",
    "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes":
      "/es/blog/como-utilizar-ia-para-vender-propiedades",
    "/blog": "/es/blog",
    "/fr/blog": "/es/blog",
    "/blog/sell-more-properties-with-ai":
      "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
    "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia":
      "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
    "/releases": "/es/releases",
    "/fr/releases": "/es/releases",
    "/releases/renovate-interiors-with-ai-from-phone":
      "/es/releases/renueva-espacios-desde-el-movil-con-ia",
    "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone":
      "/es/releases/renueva-espacios-desde-el-movil-con-ia",
    "/home-staging-virtual": "/es/home-staging-virtual",
    "/fr/home-staging-virtual": "/es/home-staging-virtual",
    "/legal": "/es/legal",
    "/fr/legal": "/es/legal",
    "/privacy": "/es/privacy",
    "/fr/privacy": "/es/privacy",
    "/about": "/es/about",
    "/fr/about": "/es/about",
    "/help/how-to-add-3d-objects": "/es/help/como-anadir-objetos-3d",
    "/fr/help/comment-ajouter-des-objets-3d": "/es/help/como-anadir-objetos-3d",
    "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo":
      "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",
    "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere":
      "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",
    "/releases/empty-rooms-and-remove-objects-with-ai":
      "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
    "/fr/releases/vide-chambres-et-enlever-objets-avec-ia":
      "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
    "/reviews-and-testimonials": "/es/reviews-and-testimonials",
    "/fr/reviews-and-testimonials": "/es/reviews-and-testimonials",
    "/releases/add-3d-objects-and-make-sky-blue":
      "/es/releases/anade-objetos-3d-y-cielo-azul",
    "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu":
      "/es/releases/anade-objetos-3d-y-cielo-azul",
    "/help/how-to-renovate-houses-virtually":
      "/es/help/como-renovar-casas-virtualmente",
    "/fr/help/comment-renover-des-maisons-en-virtuel":
      "/es/help/como-renovar-casas-virtualmente",
    "/help/how-to-furnish-virtually": "/es/help/como-amueblar-virtualmente",
    "/fr/help/comment-meubler-en-virtuel":
      "/es/help/como-amueblar-virtualmente",
    "/help/how-to-remove-object": "/es/help/como-borrar-objeto",
    "/fr/help/comment-supprimer-un-objet": "/es/help/como-borrar-objeto",
    "/blog/multifunctional-spaces": "/es/blog/espacios-multifuncionales",
    "/fr/blog/espaces-multifonctionnels": "/es/blog/espacios-multifuncionales",
    "/blog/increase-home-value": "/es/blog/aumenta-valor-vivienda",
    "/fr/blog/augmentez-valeur-maison": "/es/blog/aumenta-valor-vivienda",
    "/blog/real-estate-marketing": "/es/blog/marketing-inmobiliario",
    "/fr/blog/marketing-immobilier": "/es/blog/marketing-inmobiliario",
    "/free-tools/generate-real-estate-listing-description-free":
      "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
    "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit":
      "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
    "/blog/real-estate-market-trends":
      "/es/blog/tendencias-mercado-inmobiliario",
    "/fr/blog/tendances-marche-immobilier":
      "/es/blog/tendencias-mercado-inmobiliario",
    "/releases/realistic-furnishing": "/es/releases/amueblado-realista",
    "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres":
      "/es/releases/amueblado-realista",
    "/blog/real-estate-advertising": "/es/blog/publicidad-inmobiliaria",
    "/fr/blog/publicite-immobiliere": "/es/blog/publicidad-inmobiliaria",
    "/help/how-to-empty-rooms-virtually":
      "/es/help/como-vaciar-habitacion-virtualmente",
    "/fr/help/comment-vider-pieces-virtuellement":
      "/es/help/como-vaciar-habitacion-virtualmente",
    "/blog/real-estate-pricing": "/es/blog/pricing-inmobiliario",
    "/fr/blog/pricing-immobilier": "/es/blog/pricing-inmobiliario",
    "/blog/real-estate-mentor": "/es/blog/mentor-inmobiliario",
    "/fr/blog/mentor-immobilier": "/es/blog/mentor-inmobiliario",
    "/blog/how-to-personalize-room": "/es/blog/como-personalizar-habitacion",
    "/fr/blog/comment-personnaliser-chambre":
      "/es/blog/como-personalizar-habitacion",
    "/help/how-to-create-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual",
    "/fr/help/comment-creer-home-staging-virtuel":
      "/es/help/como-crear-home-staging-virtual",
    "/blog/sunken-living-rooms": "/es/blog/salas-hundidas",
    "/fr/blog/salons-en-contrebas": "/es/blog/salas-hundidas",
    "/blog/home-automation": "/es/blog/domotizar-casa",
    "/fr/blog/domotiser-maison": "/es/blog/domotizar-casa",
    "/blog/property-that-wont-sell": "/es/blog/propiedad-no-se-vende",
    "/fr/blog/propriete-qui-ne-se-vend-pas": "/es/blog/propiedad-no-se-vende",
    "/fr/versions/renovez-et-meublez-en-un-clic":
      "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
    "/releases/renovate-and-furnish-in-one-click":
      "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
    "/blog/flipping": "/es/blog/flipping",
    "/fr/blog/flipping": "/es/blog/flipping",
    "/fr/blog/moderniser-maison": "/es/blog/modernizar-casa",
    "/blog/modernize-home": "/es/blog/modernizar-casa",
    "/blog/environmental-designer": "/es/blog/diseñador-ambientes",
    "/fr/blog/designer-environnement": "/es/blog/diseñador-ambientes",
    "/fr/home-staging-cuisine": "/es/home-staging-cocina",
    "/home-staging-kitchen": "/es/home-staging-cocina",
    "/fr/home-staging-salon": "/es/home-staging-salon",
    "/home-staging-living-room": "/es/home-staging-salon",
    "/es/blog/short-sale": "/blog/short-sale",
    "/fr/blog/short-sale": "/blog/short-sale",
    "/fr/blog/erreurs-vente-maison": "/es/blog/errores-al-vender-casa",
    "/blog/mistakes-when-selling-house": "/es/blog/errores-al-vender-casa",
    "/home-staging-terrace": "/es/home-staging-terraza",
    "/fr/home-staging-terrasse": "/es/home-staging-terraza",
    "/fr/home-staging-chambre": "/es/home-staging-dormitorio",
    "/home-staging-bedroom": "/es/home-staging-dormitorio",
    "/home-staging-bathroom": "/es/home-staging-bano",
    "/fr/home-staging-salle-de-bain": "/es/home-staging-bano",
    "/blog/real-estate": "/es/blog/bienes-raices",
    "/fr/blog/immobilier": "/es/blog/bienes-raices",
    "/help/how-to-create-terrace-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-terraza",
    "/fr/help/comment-creer-home-staging-virtuel-terrasse":
      "/es/help/como-crear-home-staging-virtual-terraza",
    "/blog/real-estate-phrases": "/es/blog/frases-inmobiliarias",
    "/fr/blog/phrases-immobilieres": "/es/blog/frases-inmobiliarias",
    "/fr/help/comment-creer-home-staging-virtuel-chambre":
      "/es/help/como-crear-home-staging-virtual-dormitorio",
    "/help/how-to-create-bedroom-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-dormitorio",
    "/help/how-to-create-living-room-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-salon",
    "/fr/help/comment-creer-home-staging-virtuel-salon":
      "/es/help/como-crear-home-staging-virtual-salon",
    "/fr/blog/fiducie-immobiliere": "/es/blog/fideicomiso-inmobiliario",
    "/blog/real-estate-trust": "/es/blog/fideicomiso-inmobiliario",
    "/fr/outils-gratuits/convertisseur-format-image":
      "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
    "/free-tools/free-image-format-converter":
      "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
    "/fr/blog/terrains-constructibles": "/es/blog/terrenos-urbanizables",
    "/blog/buildable-land": "/es/blog/terrenos-urbanizables",
    "/fr/help/comment-creer-home-staging-virtuel-cuisine":
      "/es/help/como-crear-home-staging-virtual-cocina",
    "/help/how-to-create-kitchen-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-cocina",
    "/help/how-to-create-bathroom-virtual-home-staging":
      "/es/help/como-crear-home-staging-virtual-bano",
    "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain":
      "/es/help/como-crear-home-staging-virtual-bano",
    "/releases/pedra-improvements-pools-and-preservation":
      "/es/releases/mejoras-pedra-piscinas-y-preservacion",
    "/fr/releases/ameliorations-pedra-piscines-et-preservation":
      "/es/releases/mejoras-pedra-piscinas-y-preservacion",
    "/fr/rendus-de-renovation": "/es/renders-para-reformas",
    "/renders-for-renovations": "/es/renders-para-reformas",
    "/blog/how-to-sell-a-house-to-renovate":
      "/es/blog/como-vender-casa-a-reformar",
    "/fr/blog/comment-vendre-une-maison-a-renover":
      "/es/blog/como-vender-casa-a-reformar",
    "/render-kitchen": "/es/render-cocina",
    "/fr/rendu-cuisine": "/es/render-cocina",
    "/render-bedroom": "/es/render-dormitorio",
    "/fr/rendu-chambre": "/es/render-dormitorio",
    "/fr/rendu-salle-de-bain": "/es/render-bano",
    "/render-bathroom": "/es/render-bano",
    "/render-living-room": "/es/render-salon",
    "/fr/rendu-salon": "/es/render-salon",
    "/fr/rendu-terrasse": "/es/render-terraza",
    "/render-terrace": "/es/render-terraza",
    "/render-house": "/es/render-casa",
    "/fr/rendu-maison": "/es/render-casa",
    "/fr/help/comment-creer-rendu-cuisine": "/es/help/como-crear-render-cocina",
    "/help/how-to-create-kitchen-render": "/es/help/como-crear-render-cocina",
    "/help/how-to-create-bathroom-render": "/es/help/como-crear-render-bano",
    "/fr/help/comment-creer-rendu-salle-de-bain":
      "/es/help/como-crear-render-bano",
    "/help/how-to-create-living-room-render":
      "/es/help/como-crear-render-sala-estar",
    "/fr/help/comment-creer-rendu-salon":
      "/es/help/como-crear-render-sala-estar",
    "/fr/help/comment-creer-rendu-chambre":
      "/es/help/como-crear-render-dormitorio",
    "/help/how-to-create-bedroom-render":
      "/es/help/como-crear-render-dormitorio",
    "/help/how-to-create-terrace-render": "/es/help/como-crear-render-terraza",
    "/fr/help/comment-creer-rendu-terrasse":
      "/es/help/como-crear-render-terraza",
    "/fr/help/comment-dessiner-plan": "/es/help/como-dibujar-plano",
    "/help/how-to-draw-floor-plan": "/es/help/como-dibujar-plano",
    "/help/how-to-create-house-exterior-render":
      "/es/help/como-crear-render-exterior-casa",
    "/fr/help/comment-creer-rendu-exterieur-maison":
      "/es/help/como-crear-render-exterior-casa",
    "/fr/releases/espaces-de-travail": "/es/releases/espacios-de-trabajo",
    "/releases/workspaces": "/es/releases/espacios-de-trabajo",
    "/blog/traditional-home-staging-vs-virtual-home-staging":
      "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
    "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel":
      "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
    "/fr/render-depuis-plan": "/es/render-desde-plano",
    "/render-from-floor-plan": "/es/render-desde-plano",
    "/help/render-floor-plan-with-ai":
      "/es/help/renderizar-plano-de-planta-con-ia",
    "/fr/help/rendu-plan-etage-avec-ia":
      "/es/help/renderizar-plano-de-planta-con-ia",
    "/fr/blog/rentabilite-home-staging-virtuel":
      "/es/blog/rentabilidad-home-staging-virtual",
    "/blog/roi-virtual-staging": "/es/blog/rentabilidad-home-staging-virtual",
    "/fr/rendu-local": "/es/render-local",
    "/render-commercial": "/es/render-local",
    "/fr/home-staging-local": "/es/home-staging-local",
    "/home-staging-commercial": "/es/home-staging-local",
    "/fr/help/comment-creer-rendu-local-commercial":
      "/es/help/como-crear-render-local-comercial",
    "/help/how-to-create-commercial-space-render":
      "/es/help/como-crear-render-local-comercial",
    "/fr/releases/plans-et-espaces-commerciaux":
      "/es/releases/planos-y-espacios-comerciales",
    "/releases/floorplans-and-commercial-spaces":
      "/es/releases/planos-y-espacios-comerciales",
    "/pt/about": "/es/about",
    "/pt/api-documentation": "/es/api-documentation",
    "/pt/blog": "/es/blog",
    "/pt/blog/como-vender-imoveis-com-ia":
      "/es/blog/como-utilizar-ia-para-vender-propiedades",
    "/pt/blog/marketing-imobiliario": "/es/blog/marketing-inmobiliario",
    "/pt/blog/tendencias-mercado-imobiliario":
      "/es/blog/tendencias-mercado-inmobiliario",
    "/pt/blog/publicidade-imobiliaria": "/es/blog/publicidad-inmobiliaria",
    "/pt/blog/precificacao-imobiliaria": "/es/blog/pricing-inmobiliario",
    "/pt/blog/mentor-imobiliario": "/es/blog/mentor-inmobiliario",
    "/pt/blog/como-personalizar-ambientes":
      "/es/blog/como-personalizar-habitacion",
    "/pt/blog/salas-rebaixadas": "/es/blog/salas-hundidas",
    "/pt/blog/automacao-residencial": "/es/blog/domotizar-casa",
    "/pt/blog/imovel-que-nao-vende": "/es/blog/propiedad-no-se-vende",
    "/pt/blog/flipping": "/es/blog/flipping",
    "/pt/blog/modernizar-casa": "/es/blog/modernizar-casa",
    "/pt/blog/designer-ambiental": "/es/blog/diseñador-ambientes",

    "/pt/blog/erros-ao-vender-casa": "/es/blog/errores-al-vender-casa",
    "/pt/blog/imoveis": "/es/blog/bienes-raices",
    "/pt/blog/frases-imobiliarias": "/es/blog/frases-inmobiliarias",
    "/pt/blog/confianca-imobiliaria": "/es/blog/fideicomiso-inmobiliario",
    "/pt/blog/terreno-construivel": "/es/blog/terrenos-urbanizables",
    "/pt/blog/como-vender-casa-para-reforma":
      "/es/blog/como-vender-casa-a-reformar",
    "/pt/blog/home-staging-tradicional-vs-virtual":
      "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
    "/pt/blog/roi-home-staging-virtual":
      "/es/blog/rentabilidad-home-staging-virtual",
    "/pt/blog/vender-mais-imoveis-com-ia":
      "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
    "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio":
      "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",

    "/pt/blog/exemplos-home-staging": "/es/blog/home-staging-ejemplos",
    "/pt/blog/espacos-multifuncionais": "/es/blog/espacios-multifuncionales",
    "/pt/blog/aumentar-valor-imovel": "/es/blog/aumenta-valor-vivienda",
    "/pt/planta-baixa": "/es/floorplan",
    "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis":
      "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
    "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis":
      "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",

    "/pt": "/es",
    "/pt/home-staging-virtual": "/es/home-staging-virtual",
    "/pt/home-staging-banheiro": "/es/home-staging-bano",
    "/pt/home-staging-quarto": "/es/home-staging-dormitorio",
    "/pt/home-staging-cozinha": "/es/home-staging-cocina",
    "/pt/home-staging-sala": "/es/home-staging-salon",
    "/pt/home-staging-local": "/es/home-staging-local",
    "/pt/home-staging-terraco": "/es/home-staging-terraza",
    "/pt/ajuda/como-adicionar-objetos-3d": "/es/help/como-anadir-objetos-3d",
    "/pt/ajuda/como-desenhar-planta-baixa": "/es/help/como-dibujar-plano",
    "/pt/ajuda/como-esvaziar-ambientes-virtualmente":
      "/es/help/como-vaciar-habitacion-virtualmente",
    "/pt/ajuda/como-mobiliar-virtualmente":
      "/es/help/como-amueblar-virtualmente",
    "/pt/ajuda/como-remover-objetos": "/es/help/como-borrar-objeto",
    "/pt/ajuda/como-criar-render-banheiro": "/es/help/como-crear-render-bano",
    "/pt/ajuda/como-criar-render-quarto":
      "/es/help/como-crear-render-dormitorio",
    "/pt/ajuda/renderizar-planta-baixa-com-ia":
      "/es/help/renderizar-plano-de-planta-con-ia",
    "/pt/ajuda/como-criar-render-externa-casa":
      "/es/help/como-crear-render-exterior-casa",
    "/pt/ajuda/como-criar-render-cozinha": "/es/help/como-crear-render-cocina",
    "/pt/ajuda/como-criar-render-sala": "/es/help/como-crear-render-sala-estar",
    "/pt/ajuda/como-criar-render-local-comercial":
      "/es/help/como-crear-render-local-comercial",
    "/pt/ajuda/como-criar-render-terraco": "/es/help/como-crear-render-terraza",
    "/pt/ajuda/como-renovar-casas-virtualmente":
      "/es/help/como-renovar-casas-virtualmente",
    "/pt/ajuda/como-criar-home-staging-virtual":
      "/es/help/como-crear-home-staging-virtual",
    "/pt/ajuda/como-criar-home-staging-virtual-banheiro":
      "/es/help/como-crear-home-staging-virtual-bano",
    "/pt/ajuda/como-criar-home-staging-virtual-quarto":
      "/es/help/como-crear-home-staging-virtual-dormitorio",
    "/pt/ajuda/como-criar-home-staging-virtual-cozinha":
      "/es/help/como-crear-home-staging-virtual-cocina",
    "/pt/ajuda/como-criar-home-staging-virtual-sala":
      "/es/help/como-crear-home-staging-virtual-salon",
    "/pt/ajuda/como-criar-home-staging-virtual-terraco":
      "/es/help/como-crear-home-staging-virtual-terraza",
    "/pt/interiordesign": "/es/interiordesign",
    "/pt/legal": "/es/legal",
    "/de/about": "/es/about",
    "/de/api-documentation": "/es/api-documentation",
    "/de/blog": "/es/blog",
    "/de/blog/wie-man-immobilien-mit-ki-verkauft":
      "/es/blog/como-utilizar-ia-para-vender-propiedades",
    "/de/blog/immobilien-marketing": "/es/blog/marketing-inmobiliario",
    "/de/blog/immobilienmarkt-trends":
      "/es/blog/tendencias-mercado-inmobiliario",
    "/de/blog/immobilien-werbung": "/es/blog/publicidad-inmobiliaria",
    "/de/blog/immobilien-preisgestaltung": "/es/blog/pricing-inmobiliario",
    "/de/blog/immobilien-mentor": "/es/blog/mentor-inmobiliario",
    "/de/blog/wie-man-raume-personalisiert":
      "/es/blog/como-personalizar-habitacion",
    "/de/blog/versenktes-wohnzimmer": "/es/blog/salas-hundidas",
    "/de/blog/hausautomatisierung": "/es/blog/domotizar-casa",
    "/de/blog/immobilie-die-sich-nicht-verkauft":
      "/es/blog/propiedad-no-se-vende",
    "/de/blog/flipping": "/es/blog/flipping",
    "/de/blog/haus-modernisieren": "/es/blog/modernizar-casa",
    "/de/blog/umweltdesigner": "/es/blog/diseñador-ambientes",
    "/de/blog/schnellverkauf": "/es/blog/short-sale",
    "/de/blog/fehler-beim-hausverkauf": "/es/blog/errores-al-vender-casa",
    "/de/blog/immobilien": "/es/blog/bienes-raices",
    "/de/blog/immobilien-phrasen": "/es/blog/frases-inmobiliarias",
    "/de/blog/immobilien-vertrauen": "/es/blog/fideicomiso-inmobiliario",
    "/de/blog/bauland": "/es/blog/terrenos-urbanizables",
    "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft":
      "/es/blog/como-vender-casa-a-reformar",
    "/de/blog/traditionelles-vs-virtuelles-home-staging":
      "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
    "/de/blog/roi-virtuelles-home-staging":
      "/es/blog/rentabilidad-home-staging-virtual",
    "/de/blog/mehr-immobilien-mit-ki-verkaufen":
      "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
    "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt":
      "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",
    "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht":
      "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles",
    "/de/blog/home-staging-beispiele": "/es/blog/home-staging-ejemplos",
    "/de/blog/multifunktionale-raume": "/es/blog/espacios-multifuncionales",
    "/de/blog/hauswert-steigern": "/es/blog/aumenta-valor-vivienda",
    "/de/grundriss": "/es/floorplan",
    "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen":
      "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
    "/de/kostenlose-tools/bildformat-konverter-kostenlos":
      "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
    "/de/hilfe": "/fr/help",
    "/de": "/es",
    "/de/virtuelles-home-staging": "/es/home-staging-virtual",
    "/de/home-staging-badezimmer": "/es/home-staging-bano",
    "/de/home-staging-schlafzimmer": "/es/home-staging-dormitorio",
    "/de/home-staging-kuche": "/es/home-staging-cocina",
    "/de/home-staging-wohnzimmer": "/es/home-staging-salon",
    "/de/home-staging-gewerbe": "/es/home-staging-local",
    "/de/home-staging-terrasse": "/es/home-staging-terraza",
    "/de/hilfe/wie-man-3d-objekte-hinzufugt": "/es/help/como-anadir-objetos-3d",
    "/de/hilfe/wie-man-grundriss-zeichnet": "/es/help/como-dibujar-plano",
    "/de/hilfe/wie-man-raume-virtuell-leert":
      "/es/help/como-vaciar-habitacion-virtualmente",
    "/de/hilfe/wie-man-virtuell-einrichtet":
      "/es/help/como-amueblar-virtualmente",
    "/de/hilfe/wie-man-objekte-entfernt": "/es/help/como-borrar-objeto",
    "/de/hilfe/wie-man-badezimmer-rendering-erstellt":
      "/es/help/como-crear-render-bano",
    "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt":
      "/es/help/como-crear-render-dormitorio",
    "/de/hilfe/grundriss-mit-ki-rendern":
      "/es/help/renderizar-plano-de-planta-con-ia",
    "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt":
      "/es/help/como-crear-render-exterior-casa",
    "/de/hilfe/wie-man-kuchen-rendering-erstellt":
      "/es/help/como-crear-render-cocina",
    "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt":
      "/es/help/como-crear-render-sala-estar",
    "/de/hilfe/wie-man-gewerberaum-rendering-erstellt":
      "/es/help/como-crear-render-local-comercial",
    "/de/hilfe/wie-man-terrassen-rendering-erstellt":
      "/es/help/como-crear-render-terraza",
    "/de/hilfe/wie-man-hauser-virtuell-renoviert":
      "/es/help/como-renovar-casas-virtualmente",
    "/de/hilfe/wie-man-virtuelles-home-staging-erstellt":
      "/es/help/como-crear-home-staging-virtual",
    "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt":
      "/es/help/como-crear-home-staging-virtual-bano",
    "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt":
      "/es/help/como-crear-home-staging-virtual-dormitorio",
    "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt":
      "/es/help/como-crear-home-staging-virtual-cocina",
    "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt":
      "/es/help/como-crear-home-staging-virtual-salon",
    "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt":
      "/es/help/como-crear-home-staging-virtual-terraza",
    "/de/innenarchitektur": "/es/interiordesign",
    "/de/rechtliches": "/es/legal",
    "/de/preise": "/es/pricing",
    "/de/datenschutz": "/es/privacy",
    "/de/immobilien": "/es/realestate",
    "/de/immobilienfotografie": "/es/real-estate-photography",
    "/de/releases/realistische-einrichtung": "/es/releases/amueblado-realista",
    "/de/releases/grundrisse-und-gewerberaume":
      "/es/releases/planos-y-espacios-comerciales",
    "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen":
      "/es/releases/anade-objetos-3d-y-cielo-azul",
    "/de/releases/innenraume-mit-ki-vom-handy-renovieren":
      "/es/releases/renueva-espacios-desde-el-movil-con-ia",
    "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen":
      "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
    "/de/releases/arbeitsplatze": "/es/releases/espacios-de-trabajo",
    "/de/releases/stein-verbesserungen-pools-und-erhaltung":
      "/es/releases/mejoras-pedra-piscinas-y-preservacion",
    "/de/releases": "/es/releases",
    "/de/releases/renovieren-und-einrichten-mit-einem-klick":
      "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
    "/de/render": "/es/render",
    "/de/badezimmer-rendering": "/es/render-bano",
    "/de/schlafzimmer-rendering": "/es/render-dormitorio",
    "/de/rendering-vom-grundriss": "/es/render-desde-plano",
    "/de/haus-rendering": "/es/render-casa",
    "/de/kuchen-rendering": "/es/render-cocina",
    "/de/wohnzimmer-rendering": "/es/render-salon",
    "/de/gewerbe-rendering": "/es/render-local",
    "/de/terrassen-rendering": "/es/render-terraza",
    "/de/renderings-fur-renovierungen": "/es/renders-para-reformas",
    "/de/reviews-and-testimonials": "/es/reviews-and-testimonials",

    "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel":
      "/es/help/como-crear-efecto-cortinilla-home-staging-virtual",
    "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual":
      "/es/help/como-crear-efecto-cortinilla-home-staging-virtual",
    "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt":
      "/es/help/como-crear-efecto-cortinilla-home-staging-virtual",
    "/help/how-to-create-virtual-home-staging-curtain-effect":
      "/es/help/como-crear-efecto-cortinilla-home-staging-virtual",

    "/blog/real-estate-photography-tips":
      "/es/blog/consejos-para-fotografia-inmobiliaria",
    "/fr/blog/conseils-pour-la-photographie-immobiliere":
      "/es/blog/consejos-para-fotografia-inmobiliaria",
    "/de/blog/tipps-fur-immobilienfotografie":
      "/es/blog/consejos-para-fotografia-inmobiliaria",
    "/pt/blog/dicas-de-fotografia-imobiliaria":
      "/es/blog/consejos-para-fotografia-inmobiliaria",

    "/blog/property-listing-optimization":
      "/es/blog/optimizacion-de-anuncios-inmobiliarios",
    "/fr/blog/optimisation-des-annonces-immobilieres":
      "/es/blog/optimizacion-de-anuncios-inmobiliarios",
    "/de/blog/optimierung-von-immobilienanzeigen":
      "/es/blog/optimizacion-de-anuncios-inmobiliarios",
    "/pt/blog/otimizacao-de-anuncios-imobiliarios":
      "/es/blog/optimizacion-de-anuncios-inmobiliarios",
  },
  fr: {
    "/": "/fr",
    "/es": "/fr",
    "/realestate": "/fr/realestate",
    "/es/realestate": "/fr/realestate",
    "/real-estate-photography": "/fr/real-estate-photography",
    "/es/real-estate-photography": "/fr/real-estate-photography",
    "/floorplan": "/fr/floorplan",
    "/es/floorplan": "/fr/floorplan",
    "/es/blog/como-vender-casa": "/fr",
    "/interiordesign": "/fr/interiordesign",
    "/es/interiordesign": "/fr/interiordesign",
    "/render": "/fr/render",
    "/es/render": "/fr/render",
    "/api-documentation": "/fr/api-documentation",
    "/es/api-documentation": "/fr/api-documentation",
    "/es/mls": "/fr",
    "/es/blog/renovar-casa": "/fr",
    "/blog/home-staging-examples": "/fr/blog/exemples-de-home-staging",
    "/es/blog/home-staging-ejemplos": "/fr/blog/exemples-de-home-staging",
    "/pricing": "/fr/pricing",
    "/es/pricing": "/fr/pricing",
    "/blog/how-to-sell-properties-with-ai":
      "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
    "/es/blog/como-utilizar-ia-para-vender-propiedades":
      "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
    "/blog": "/fr/blog",
    "/es/blog": "/fr/blog",
    "/blog/sell-more-properties-with-ai":
      "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
    "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia":
      "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
    "/releases": "/fr/releases",
    "/es/releases": "/fr/releases",
    "/releases/renovate-interiors-with-ai-from-phone":
      "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone",
    "/es/releases/renueva-espacios-desde-el-movil-con-ia":
      "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone",
    "/home-staging-virtual": "/fr/home-staging-virtual",
    "/es/home-staging-virtual": "/fr/home-staging-virtual",
    "/legal": "/fr/legal",
    "/es/legal": "/fr/legal",
    "/privacy": "/fr/privacy",
    "/es/privacy": "/fr/privacy",
    "/about": "/fr/about",
    "/es/about": "/fr/about",
    "/help/how-to-add-3d-objects": "/fr/help/comment-ajouter-des-objets-3d",
    "/es/help/como-anadir-objetos-3d": "/fr/help/comment-ajouter-des-objets-3d",
    "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo":
      "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",
    "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta":
      "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",
    "/releases/empty-rooms-and-remove-objects-with-ai":
      "/fr/releases/vide-chambres-et-enlever-objets-avec-ia",
    "/es/releases/vacia-habitacion-y-quita-objetos-con-ia":
      "/fr/releases/vide-chambres-et-enlever-objets-avec-ia",
    "/reviews-and-testimonials": "/fr/reviews-and-testimonials",
    "/es/reviews-and-testimonials": "/fr/reviews-and-testimonials",
    "/releases/add-3d-objects-and-make-sky-blue":
      "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu",
    "/es/releases/anade-objetos-3d-y-cielo-azul":
      "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu",
    "/help/how-to-renovate-houses-virtually":
      "/fr/help/comment-renover-des-maisons-en-virtuel",
    "/es/help/como-renovar-casas-virtualmente":
      "/fr/help/comment-renover-des-maisons-en-virtuel",
    "/help/how-to-furnish-virtually": "/fr/help/comment-meubler-en-virtuel",
    "/es/help/como-amueblar-virtualmente":
      "/fr/help/comment-meubler-en-virtuel",
    "/help/how-to-remove-object": "/fr/help/comment-supprimer-un-objet",
    "/es/help/como-borrar-objeto": "/fr/help/comment-supprimer-un-objet",
    "/blog/multifunctional-spaces": "/fr/blog/espaces-multifonctionnels",
    "/es/blog/espacios-multifuncionales": "/fr/blog/espaces-multifonctionnels",
    "/blog/increase-home-value": "/fr/blog/augmentez-valeur-maison",
    "/es/blog/aumenta-valor-vivienda": "/fr/blog/augmentez-valeur-maison",
    "/blog/real-estate-marketing": "/fr/blog/marketing-immobilier",
    "/es/blog/marketing-inmobiliario": "/fr/blog/marketing-immobilier",
    "/free-tools/generate-real-estate-listing-description-free":
      "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
    "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis":
      "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
    "/blog/real-estate-market-trends": "/fr/blog/tendances-marche-immobilier",
    "/es/blog/tendencias-mercado-inmobiliario":
      "/fr/blog/tendances-marche-immobilier",
    "/releases/realistic-furnishing":
      "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
    "/es/releases/amueblado-realista":
      "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
    "/blog/real-estate-advertising": "/fr/blog/publicite-immobiliere",
    "/es/blog/publicidad-inmobiliaria": "/fr/blog/publicite-immobiliere",
    "/help/how-to-empty-rooms-virtually":
      "/fr/help/comment-vider-pieces-virtuellement",
    "/es/help/como-vaciar-habitacion-virtualmente":
      "/fr/help/comment-vider-pieces-virtuellement",
    "/blog/real-estate-pricing": "/fr/blog/pricing-immobilier",
    "/es/blog/pricing-inmobiliario": "/fr/blog/pricing-immobilier",
    "/blog/real-estate-mentor": "/fr/blog/mentor-immobilier",
    "/es/blog/mentor-inmobiliario": "/fr/blog/mentor-immobilier",
    "/blog/how-to-personalize-room": "/fr/blog/comment-personnaliser-chambre",
    "/es/blog/como-personalizar-habitacion":
      "/fr/blog/comment-personnaliser-chambre",
    "/help/how-to-create-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel",
    "/es/help/como-crear-home-staging-virtual":
      "/fr/help/comment-creer-home-staging-virtuel",
    "/blog/sunken-living-rooms": "/fr/blog/salons-en-contrebas",
    "/es/blog/salas-hundidas": "/fr/blog/salons-en-contrebas",
    "/blog/home-automation": "/fr/blog/domotiser-maison",
    "/es/blog/domotizar-casa": "/fr/blog/domotiser-maison",
    "/blog/property-that-wont-sell": "/fr/blog/propriete-qui-ne-se-vend-pas",
    "/es/blog/propiedad-no-se-vende": "/fr/blog/propriete-qui-ne-se-vend-pas",
    "/es/lanzamientos/renueva-y-amuebla-en-un-clic":
      "/fr/versions/renovez-et-meublez-en-un-clic",
    "/releases/renovate-and-furnish-in-one-click":
      "/fr/versions/renovez-et-meublez-en-un-clic",
    "/blog/flipping": "/fr/blog/flipping",
    "/es/blog/flipping": "/fr/blog/flipping",
    "/es/blog/modernizar-casa": "/fr/blog/moderniser-maison",
    "/blog/modernize-home": "/fr/blog/moderniser-maison",
    "/es/blog/diseñador-ambientes": "/fr/blog/designer-environnement",
    "/blog/environmental-designer": "/fr/blog/designer-environnement",
    "/es/home-staging-cocina": "/fr/home-staging-cuisine",
    "/home-staging-kitchen": "/fr/home-staging-cuisine",
    "/es/home-staging-salon": "/fr/home-staging-salon",
    "/home-staging-living-room": "/fr/home-staging-salon",
    "/es/blog/short-sale": "/fr/blog/short-sale",
    "/blog/short-sale": "/fr/blog/short-sale",
    "/blog/mistakes-when-selling-house": "/fr/blog/erreurs-vente-maison",
    "/es/blog/errores-al-vender-casa": "/fr/blog/erreurs-vente-maison",
    "/home-staging-terrace": "/fr/home-staging-terrasse",
    "/es/home-staging-terraza": "/fr/home-staging-terrasse",
    "/es/home-staging-dormitorio": "/fr/home-staging-chambre",
    "/home-staging-bedroom": "/fr/home-staging-chambre",
    "/home-staging-bathroom": "/fr/home-staging-salle-de-bain",
    "/es/home-staging-bano": "/fr/home-staging-salle-de-bain",
    "/blog/real-estate": "/fr/blog/immobilier",
    "/es/blog/bienes-raices": "/fr/blog/immobilier",
    "/help/how-to-create-terrace-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-terrasse",
    "/es/help/como-crear-home-staging-virtual-terraza":
      "/fr/help/comment-creer-home-staging-virtuel-terrasse",
    "/blog/real-estate-phrases": "/fr/blog/phrases-immobilieres",
    "/es/blog/frases-inmobiliarias": "/fr/blog/phrases-immobilieres",
    "/es/help/como-crear-home-staging-virtual-dormitorio":
      "/fr/help/comment-creer-home-staging-virtuel-chambre",
    "/help/how-to-create-bedroom-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-chambre",
    "/help/how-to-create-living-room-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-salon",
    "/es/help/como-crear-home-staging-virtual-salon":
      "/fr/help/comment-creer-home-staging-virtuel-salon",
    "/es/blog/fideicomiso-inmobiliario": "/fr/blog/fiducie-immobiliere",
    "/blog/real-estate-trust": "/fr/blog/fiducie-immobiliere",
    "/es/herramientas-gratis/convertidor-formato-imagen-gratuito":
      "/fr/outils-gratuits/convertisseur-format-image",
    "/free-tools/free-image-format-converter":
      "/fr/outils-gratuits/convertisseur-format-image",
    "/es/blog/terrenos-urbanizables": "/fr/blog/terrains-constructibles",
    "/blog/buildable-land": "/fr/blog/terrains-constructibles",
    "/es/help/como-crear-home-staging-virtual-cocina":
      "/fr/help/comment-creer-home-staging-virtuel-cuisine",
    "/help/how-to-create-kitchen-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-cuisine",
    "/help/how-to-create-bathroom-virtual-home-staging":
      "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
    "/es/help/como-crear-home-staging-virtual-bano":
      "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
    "/releases/pedra-improvements-pools-and-preservation":
      "/fr/releases/ameliorations-pedra-piscines-et-preservation",
    "/es/releases/mejoras-pedra-piscinas-y-preservacion":
      "/fr/releases/ameliorations-pedra-piscines-et-preservation",
    "/es/renders-para-reformas": "/fr/rendus-de-renovation",
    "/renders-for-renovations": "/fr/rendus-de-renovation",
    "/es/blog/como-vender-casa-a-reformar":
      "/fr/blog/comment-vendre-une-maison-a-renover",
    "/blog/how-to-sell-a-house-to-renovate":
      "/fr/blog/comment-vendre-une-maison-a-renover",
    "/render-kitchen": "/fr/rendu-cuisine",
    "/es/render-cocina": "/fr/rendu-cuisine",
    "/render-bedroom": "/fr/rendu-chambre",
    "/es/render-dormitorio": "/fr/rendu-chambre",
    "/es/render-bano": "/fr/rendu-salle-de-bain",
    "/render-bathroom": "/fr/rendu-salle-de-bain",
    "/render-living-room": "/fr/rendu-salon",
    "/es/render-salon": "/fr/rendu-salon",
    "/es/render-terraza": "/fr/rendu-terrasse",
    "/render-terrace": "/fr/rendu-terrasse",
    "/render-house": "/fr/rendu-maison",
    "/es/render-casa": "/fr/rendu-maison",
    "/es/help/como-crear-render-cocina": "/fr/help/comment-creer-rendu-cuisine",
    "/help/how-to-create-kitchen-render":
      "/fr/help/comment-creer-rendu-cuisine",
    "/help/how-to-create-bathroom-render":
      "/fr/help/comment-creer-rendu-salle-de-bain",
    "/es/help/como-crear-render-bano":
      "/fr/help/comment-creer-rendu-salle-de-bain",
    "/help/how-to-create-living-room-render":
      "/fr/help/comment-creer-rendu-salon",
    "/es/help/como-crear-render-sala-estar":
      "/fr/help/comment-creer-rendu-salon",
    "/es/help/como-crear-render-dormitorio":
      "/fr/help/comment-creer-rendu-chambre",
    "/help/how-to-create-bedroom-render":
      "/fr/help/comment-creer-rendu-chambre",
    "/help/how-to-create-terrace-render":
      "/fr/help/comment-creer-rendu-terrasse",
    "/es/help/como-crear-render-terraza":
      "/fr/help/comment-creer-rendu-terrasse",
    "/es/help/como-dibujar-plano": "/fr/help/comment-dessiner-plan",
    "/help/how-to-draw-floor-plan": "/fr/help/comment-dessiner-plan",
    "/es/help/como-crear-render-exterior-casa":
      "/fr/help/comment-creer-rendu-exterieur-maison",
    "/help/how-to-create-house-exterior-render":
      "/fr/help/comment-creer-rendu-exterieur-maison",
    "/es/releases/espacios-de-trabajo": "/fr/releases/espaces-de-travail",
    "/releases/workspaces": "/fr/releases/espaces-de-travail",
    "/blog/traditional-home-staging-vs-virtual-home-staging":
      "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
    "/es/blog/home-staging-tradicional-vs-home-staging-virtual":
      "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
    "/es/render-desde-plano": "/fr/render-depuis-plan",
    "/render-from-floor-plan": "/fr/render-depuis-plan",
    "/help/render-floor-plan-with-ai": "/fr/help/rendu-plan-etage-avec-ia",
    "/es/help/renderizar-plano-de-planta-con-ia":
      "/fr/help/rendu-plan-etage-avec-ia",
    "/es/blog/rentabilidad-home-staging-virtual":
      "/fr/blog/rentabilite-home-staging-virtuel",
    "/blog/roi-virtual-staging": "/fr/blog/rentabilite-home-staging-virtuel",
    "/es/render-local": "/fr/rendu-local",
    "/render-commercial": "/fr/rendu-local",
    "/es/home-staging-local": "/fr/home-staging-local",
    "/home-staging-commercial": "/fr/home-staging-local",
    "/es/help/como-crear-render-local-comercial":
      "/fr/help/comment-creer-rendu-local-commercial",
    "/help/how-to-create-commercial-space-render":
      "/fr/help/comment-creer-rendu-local-commercial",
    "/es/releases/planos-y-espacios-comerciales":
      "/fr/releases/plans-et-espaces-commerciaux",
    "/releases/floorplans-and-commercial-spaces":
      "/fr/releases/plans-et-espaces-commerciaux",
    "/pt/about": "/fr/about",
    "/pt/api-documentation": "/fr/api-documentation",
    "/pt/blog": "/fr/blog",
    "/pt/blog/como-vender-imoveis-com-ia":
      "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
    "/pt/blog/marketing-imobiliario": "/fr/blog/marketing-immobilier",
    "/pt/blog/tendencias-mercado-imobiliario":
      "/fr/blog/tendances-marche-immobilier",
    "/pt/blog/publicidade-imobiliaria": "/fr/blog/publicite-immobiliere",
    "/pt/blog/precificacao-imobiliaria": "/fr/blog/pricing-immobilier",
    "/pt/blog/mentor-imobiliario": "/fr/blog/mentor-immobilier",
    "/pt/blog/como-personalizar-ambientes":
      "/fr/blog/comment-personnaliser-chambre",
    "/pt/blog/salas-rebaixadas": "/fr/blog/salons-en-contrebas",
    "/pt/blog/automacao-residencial": "/fr/blog/domotiser-maison",
    "/pt/blog/imovel-que-nao-vende": "/fr/blog/propriete-qui-ne-se-vend-pas",
    "/pt/blog/flipping": "/fr/blog/flipping",
    "/pt/blog/modernizar-casa": "/fr/blog/moderniser-maison",
    "/pt/blog/designer-ambiental": "/fr/blog/designer-environnement",
    "/pt/blog/venda-rapida": "/fr/blog/short-sale",
    "/pt/blog/erros-ao-vender-casa": "/fr/blog/erreurs-vente-maison",
    "/pt/blog/imoveis": "/fr/blog/immobilier",
    "/pt/blog/frases-imobiliarias": "/fr/blog/phrases-immobilieres",
    "/pt/blog/confianca-imobiliaria": "/fr/blog/fiducie-immobiliere",
    "/pt/blog/terreno-construivel": "/fr/blog/terrains-constructibles",
    "/pt/blog/como-vender-casa-para-reforma":
      "/fr/blog/comment-vendre-une-maison-a-renover",
    "/pt/blog/home-staging-tradicional-vs-virtual":
      "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
    "/pt/blog/roi-home-staging-virtual":
      "/fr/blog/rentabilite-home-staging-virtuel",
    "/pt/blog/vender-mais-imoveis-com-ia":
      "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
    "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio":
      "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",

    "/pt/blog/exemplos-home-staging": "/fr/blog/exemples-de-home-staging",
    "/pt/blog/espacos-multifuncionais": "/fr/blog/espaces-multifonctionnels",
    "/pt/blog/aumentar-valor-imovel": "/fr/blog/augmentez-valeur-maison",
    "/pt/planta-baixa": "/fr/floorplan",
    "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis":
      "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
    "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis":
      "/fr/outils-gratuits/convertisseur-format-image",

    "/pt": "/fr",
    "/pt/home-staging-virtual": "/fr/home-staging-virtual",
    "/pt/home-staging-banheiro": "/fr/home-staging-salle-de-bain",
    "/pt/home-staging-quarto": "/fr/home-staging-chambre",
    "/pt/home-staging-cozinha": "/fr/home-staging-cuisine",
    "/pt/home-staging-sala": "/fr/home-staging-salon",
    "/pt/home-staging-local": "/fr/home-staging-local",
    "/pt/home-staging-terraco": "/fr/home-staging-terrasse",
    "/pt/ajuda/como-adicionar-objetos-3d":
      "/fr/help/comment-ajouter-des-objets-3d",
    "/pt/ajuda/como-desenhar-planta-baixa": "/fr/help/comment-dessiner-plan",
    "/pt/ajuda/como-esvaziar-ambientes-virtualmente":
      "/fr/help/comment-vider-pieces-virtuellement",
    "/pt/ajuda/como-mobiliar-virtualmente":
      "/fr/help/comment-meubler-en-virtuel",
    "/pt/ajuda/como-remover-objetos": "/fr/help/comment-supprimer-un-objet",
    "/pt/ajuda/como-criar-render-banheiro":
      "/fr/help/comment-creer-rendu-salle-de-bain",
    "/pt/ajuda/como-criar-render-quarto":
      "/fr/help/comment-creer-rendu-chambre",
    "/pt/ajuda/renderizar-planta-baixa-com-ia":
      "/fr/help/rendu-plan-etage-avec-ia",
    "/pt/ajuda/como-criar-render-externa-casa":
      "/fr/help/comment-creer-rendu-exterieur-maison",
    "/pt/ajuda/como-criar-render-cozinha":
      "/fr/help/comment-creer-rendu-cuisine",
    "/pt/ajuda/como-criar-render-sala": "/fr/help/comment-creer-rendu-salon",
    "/pt/ajuda/como-criar-render-local-comercial":
      "/fr/help/comment-creer-rendu-local-commercial",
    "/pt/ajuda/como-criar-render-terraco":
      "/fr/help/comment-creer-rendu-terrasse",
    "/pt/ajuda/como-renovar-casas-virtualmente":
      "/fr/help/comment-renover-des-maisons-en-virtuel",
    "/pt/ajuda/como-criar-home-staging-virtual":
      "/fr/help/comment-creer-home-staging-virtuel",
    "/pt/ajuda/como-criar-home-staging-virtual-banheiro":
      "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
    "/pt/ajuda/como-criar-home-staging-virtual-quarto":
      "/fr/help/comment-creer-home-staging-virtuel-chambre",
    "/pt/ajuda/como-criar-home-staging-virtual-cozinha":
      "/fr/help/comment-creer-home-staging-virtuel-cuisine",
    "/pt/ajuda/como-criar-home-staging-virtual-sala":
      "/fr/help/comment-creer-home-staging-virtuel-salon",
    "/pt/ajuda/como-criar-home-staging-virtual-terraco":
      "/fr/help/comment-creer-home-staging-virtuel-terrasse",
    "/pt/interiordesign": "/fr/interiordesign",
    "/pt/legal": "/fr/legal",
    "/de/about": "/fr/about",
    "/de/api-documentation": "/fr/api-documentation",
    "/de/blog": "/fr/blog",
    "/de/blog/wie-man-immobilien-mit-ki-verkauft":
      "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
    "/de/blog/immobilien-marketing": "/fr/blog/marketing-immobilier",
    "/de/blog/immobilienmarkt-trends": "/fr/blog/tendances-marche-immobilier",
    "/de/blog/immobilien-werbung": "/fr/blog/publicite-immobiliere",
    "/de/blog/immobilien-preisgestaltung": "/fr/blog/pricing-immobilier",
    "/de/blog/immobilien-mentor": "/fr/blog/mentor-immobilier",
    "/de/blog/wie-man-raume-personalisiert":
      "/fr/blog/comment-personnaliser-chambre",
    "/de/blog/versenktes-wohnzimmer": "/fr/blog/salons-en-contrebas",
    "/de/blog/hausautomatisierung": "/fr/blog/domotiser-maison",
    "/de/blog/immobilie-die-sich-nicht-verkauft":
      "/fr/blog/propriete-qui-ne-se-vend-pas",
    "/de/blog/flipping": "/fr/blog/flipping",
    "/de/blog/haus-modernisieren": "/fr/blog/moderniser-maison",
    "/de/blog/umweltdesigner": "/fr/blog/designer-environnement",
    "/de/blog/schnellverkauf": "/fr/blog/short-sale",
    "/de/blog/fehler-beim-hausverkauf": "/fr/blog/erreurs-vente-maison",
    "/de/blog/immobilien": "/fr/blog/immobilier",
    "/de/blog/immobilien-phrasen": "/fr/blog/phrases-immobilieres",
    "/de/blog/immobilien-vertrauen": "/fr/blog/fiducie-immobiliere",
    "/de/blog/bauland": "/fr/blog/terrains-constructibles",
    "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft":
      "/fr/blog/comment-vendre-une-maison-a-renover",
    "/de/blog/traditionelles-vs-virtuelles-home-staging":
      "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
    "/de/blog/roi-virtuelles-home-staging":
      "/fr/blog/rentabilite-home-staging-virtuel",
    "/de/blog/mehr-immobilien-mit-ki-verkaufen":
      "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
    "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt":
      "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",
    "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht":
      "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres",
    "/de/blog/home-staging-beispiele": "/fr/blog/exemples-de-home-staging",
    "/de/blog/multifunktionale-raume": "/fr/blog/espaces-multifonctionnels",
    "/de/blog/hauswert-steigern": "/fr/blog/augmentez-valeur-maison",
    "/de/grundriss": "/fr/floorplan",
    "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen":
      "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
    "/de/kostenlose-tools/bildformat-konverter-kostenlos":
      "/fr/outils-gratuits/convertisseur-format-image",
    "/de/hilfe": "/es/help",
    "/de": "/fr",
    "/de/virtuelles-home-staging": "/fr/home-staging-virtual",
    "/de/home-staging-badezimmer": "/fr/home-staging-salle-de-bain",
    "/de/home-staging-schlafzimmer": "/fr/home-staging-chambre",
    "/de/home-staging-kuche": "/fr/home-staging-cuisine",
    "/de/home-staging-wohnzimmer": "/fr/home-staging-salon",
    "/de/home-staging-gewerbe": "/fr/home-staging-local",
    "/de/home-staging-terrasse": "/fr/home-staging-terrasse",
    "/de/hilfe/wie-man-3d-objekte-hinzufugt":
      "/fr/help/comment-ajouter-des-objets-3d",
    "/de/hilfe/wie-man-grundriss-zeichnet": "/fr/help/comment-dessiner-plan",
    "/de/hilfe/wie-man-raume-virtuell-leert":
      "/fr/help/comment-vider-pieces-virtuellement",
    "/de/hilfe/wie-man-virtuell-einrichtet":
      "/fr/help/comment-meubler-en-virtuel",
    "/de/hilfe/wie-man-objekte-entfernt": "/fr/help/comment-supprimer-un-objet",
    "/de/hilfe/wie-man-badezimmer-rendering-erstellt":
      "/fr/help/comment-creer-rendu-salle-de-bain",
    "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt":
      "/fr/help/comment-creer-rendu-chambre",
    "/de/hilfe/grundriss-mit-ki-rendern": "/fr/help/rendu-plan-etage-avec-ia",
    "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt":
      "/fr/help/comment-creer-rendu-exterieur-maison",
    "/de/hilfe/wie-man-kuchen-rendering-erstellt":
      "/fr/help/comment-creer-rendu-cuisine",
    "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt":
      "/fr/help/comment-creer-rendu-salon",
    "/de/hilfe/wie-man-gewerberaum-rendering-erstellt":
      "/fr/help/comment-creer-rendu-local-commercial",
    "/de/hilfe/wie-man-terrassen-rendering-erstellt":
      "/fr/help/comment-creer-rendu-terrasse",
    "/de/hilfe/wie-man-hauser-virtuell-renoviert":
      "/fr/help/comment-renover-des-maisons-en-virtuel",
    "/de/hilfe/wie-man-virtuelles-home-staging-erstellt":
      "/fr/help/comment-creer-home-staging-virtuel",
    "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt":
      "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
    "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt":
      "/fr/help/comment-creer-home-staging-virtuel-chambre",
    "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt":
      "/fr/help/comment-creer-home-staging-virtuel-cuisine",
    "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt":
      "/fr/help/comment-creer-home-staging-virtuel-salon",
    "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt":
      "/fr/help/comment-creer-home-staging-virtuel-terrasse",
    "/de/innenarchitektur": "/fr/interiordesign",
    "/de/rechtliches": "/fr/legal",
    "/de/preise": "/fr/pricing",
    "/de/datenschutz": "/fr/privacy",
    "/de/immobilien": "/fr/realestate",
    "/de/immobilienfotografie": "/fr/real-estate-photography",
    "/de/releases/realistische-einrichtung":
      "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
    "/de/releases/grundrisse-und-gewerberaume":
      "/fr/releases/plans-et-espaces-commerciaux",
    "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen":
      "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu",
    "/de/releases/innenraume-mit-ki-vom-handy-renovieren":
      "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone",
    "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen":
      "/fr/releases/vide-chambres-et-enlever-objets-avec-ia",
    "/de/releases/arbeitsplatze": "/fr/releases/espaces-de-travail",
    "/de/releases/stein-verbesserungen-pools-und-erhaltung":
      "/fr/releases/ameliorations-pedra-piscines-et-preservation",
    "/de/releases": "/fr/releases",
    "/de/releases/renovieren-und-einrichten-mit-einem-klick":
      "/fr/versions/renovez-et-meublez-en-un-clic",
    "/de/render": "/fr/render",
    "/de/badezimmer-rendering": "/fr/rendu-salle-de-bain",
    "/de/schlafzimmer-rendering": "/fr/rendu-chambre",
    "/de/rendering-vom-grundriss": "/fr/render-depuis-plan",
    "/de/haus-rendering": "/fr/rendu-maison",
    "/de/kuchen-rendering": "/fr/rendu-cuisine",
    "/de/wohnzimmer-rendering": "/fr/rendu-salon",
    "/de/gewerbe-rendering": "/fr/rendu-local",
    "/de/terrassen-rendering": "/fr/rendu-terrasse",
    "/de/renderings-fur-renovierungen": "/fr/rendus-de-renovation",
    "/de/reviews-and-testimonials": "/fr/reviews-and-testimonials",

    "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual":
      "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel",
    "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt":
      "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel",
    "/help/how-to-create-virtual-home-staging-curtain-effect":
      "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel",
    "/es/help/como-crear-efecto-cortinilla-home-staging-virtual":
      "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel",

    "/es/blog/consejos-para-fotografia-inmobiliaria":
      "/fr/blog/conseils-pour-la-photographie-immobiliere",
    "/blog/real-estate-photography-tips":
      "/fr/blog/conseils-pour-la-photographie-immobiliere",
    "/de/blog/tipps-fur-immobilienfotografie":
      "/fr/blog/conseils-pour-la-photographie-immobiliere",
    "/pt/blog/dicas-de-fotografia-imobiliaria":
      "/fr/blog/conseils-pour-la-photographie-immobiliere",

    "/blog/property-listing-optimization":
      "/fr/blog/optimisation-des-annonces-immobilieres",
    "/es/blog/optimizacion-de-anuncios-inmobiliarios":
      "/fr/blog/optimisation-des-annonces-immobilieres",
    "/de/blog/optimierung-von-immobilienanzeigen":
      "/fr/blog/optimisation-des-annonces-immobilieres",
    "/pt/blog/otimizacao-de-anuncios-imobiliarios":
      "/fr/blog/optimisation-des-annonces-immobilieres",
  },
  pt: {
    "/fr/about": "/pt/about",
    "/fr/api-documentation": "/pt/api-documentation",
    "/fr/blog": "/pt/blog",
    "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes":
      "/pt/blog/como-vender-imoveis-com-ia",
    "/fr/blog/marketing-immobilier": "/pt/blog/marketing-imobiliario",
    "/fr/blog/tendances-marche-immobilier":
      "/pt/blog/tendencias-mercado-imobiliario",
    "/fr/blog/publicite-immobiliere": "/pt/blog/publicidade-imobiliaria",
    "/fr/blog/pricing-immobilier": "/pt/blog/precificacao-imobiliaria",
    "/fr/blog/mentor-immobilier": "/pt/blog/mentor-imobiliario",
    "/fr/blog/comment-personnaliser-chambre":
      "/pt/blog/como-personalizar-ambientes",
    "/fr/blog/salons-en-contrebas": "/pt/blog/salas-rebaixadas",
    "/fr/blog/domotiser-maison": "/pt/blog/automacao-residencial",
    "/fr/blog/propriete-qui-ne-se-vend-pas": "/pt/blog/imovel-que-nao-vende",
    "/fr/blog/flipping": "/pt/blog/flipping",
    "/fr/blog/moderniser-maison": "/pt/blog/modernizar-casa",
    "/fr/blog/designer-environnement": "/pt/blog/designer-ambiental",
    "/fr/blog/short-sale": "/pt/blog/venda-rapida",
    "/fr/blog/erreurs-vente-maison": "/pt/blog/erros-ao-vender-casa",
    "/fr/blog/immobilier": "/pt/blog/imoveis",
    "/fr/blog/phrases-immobilieres": "/pt/blog/frases-imobiliarias",
    "/fr/blog/fiducie-immobiliere": "/pt/blog/confianca-imobiliaria",
    "/fr/blog/terrains-constructibles": "/pt/blog/terreno-construivel",
    "/fr/blog/comment-vendre-une-maison-a-renover":
      "/pt/blog/como-vender-casa-para-reforma",
    "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel":
      "/pt/blog/home-staging-tradicional-vs-virtual",
    "/fr/blog/rentabilite-home-staging-virtuel":
      "/pt/blog/roi-home-staging-virtual",
    "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia":
      "/pt/blog/vender-mais-imoveis-com-ia",
    "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere":
      "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio",
    "/fr/blog/exemples-de-home-staging": "/pt/blog/exemplos-home-staging",
    "/fr/blog/espaces-multifonctionnels": "/pt/blog/espacos-multifuncionais",
    "/fr/blog/augmentez-valeur-maison": "/pt/blog/aumentar-valor-imovel",
    "/fr/floorplan": "/pt/planta-baixa",
    "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit":
      "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis",
    "/fr/outils-gratuits/convertisseur-format-image":
      "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis",
    "/fr": "/pt",
    "/fr/home-staging-virtual": "/pt/home-staging-virtual",
    "/fr/home-staging-salle-de-bain": "/pt/home-staging-banheiro",
    "/fr/home-staging-chambre": "/pt/home-staging-quarto",
    "/fr/home-staging-cuisine": "/pt/home-staging-cozinha",
    "/fr/home-staging-salon": "/pt/home-staging-sala",
    "/fr/home-staging-local": "/pt/home-staging-local",
    "/fr/home-staging-terrasse": "/pt/home-staging-terraco",
    "/fr/help/comment-ajouter-des-objets-3d":
      "/pt/ajuda/como-adicionar-objetos-3d",
    "/fr/help/comment-dessiner-plan": "/pt/ajuda/como-desenhar-planta-baixa",
    "/fr/help/comment-vider-pieces-virtuellement":
      "/pt/ajuda/como-esvaziar-ambientes-virtualmente",
    "/fr/help/comment-meubler-en-virtuel":
      "/pt/ajuda/como-mobiliar-virtualmente",
    "/fr/help/comment-supprimer-un-objet": "/pt/ajuda/como-remover-objetos",
    "/fr/help/comment-creer-rendu-salle-de-bain":
      "/pt/ajuda/como-criar-render-banheiro",
    "/fr/help/comment-creer-rendu-chambre":
      "/pt/ajuda/como-criar-render-quarto",
    "/fr/help/rendu-plan-etage-avec-ia":
      "/pt/ajuda/renderizar-planta-baixa-com-ia",
    "/fr/help/comment-creer-rendu-exterieur-maison":
      "/pt/ajuda/como-criar-render-externa-casa",
    "/fr/help/comment-creer-rendu-cuisine":
      "/pt/ajuda/como-criar-render-cozinha",
    "/fr/help/comment-creer-rendu-salon": "/pt/ajuda/como-criar-render-sala",
    "/fr/help/comment-creer-rendu-local-commercial":
      "/pt/ajuda/como-criar-render-local-comercial",
    "/fr/help/comment-creer-rendu-terrasse":
      "/pt/ajuda/como-criar-render-terraco",
    "/fr/help/comment-renover-des-maisons-en-virtuel":
      "/pt/ajuda/como-renovar-casas-virtualmente",
    "/fr/help/comment-creer-home-staging-virtuel":
      "/pt/ajuda/como-criar-home-staging-virtual",
    "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain":
      "/pt/ajuda/como-criar-home-staging-virtual-banheiro",
    "/fr/help/comment-creer-home-staging-virtuel-chambre":
      "/pt/ajuda/como-criar-home-staging-virtual-quarto",
    "/fr/help/comment-creer-home-staging-virtuel-cuisine":
      "/pt/ajuda/como-criar-home-staging-virtual-cozinha",
    "/fr/help/comment-creer-home-staging-virtuel-salon":
      "/pt/ajuda/como-criar-home-staging-virtual-sala",
    "/fr/help/comment-creer-home-staging-virtuel-terrasse":
      "/pt/ajuda/como-criar-home-staging-virtual-terraco",
    "/fr/interiordesign": "/pt/interiordesign",
    "/fr/legal": "/pt/legal",
    "/fr/pricing": "/pt/precos",
    "/fr/privacy": "/pt/privacy",
    "/fr/realestate": "/pt/realestate",
    "/fr/real-estate-photography": "/pt/fotografia-imobiliaria",
    "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres":
      "/pt/lancamentos/mobiliario-realista",
    "/fr/releases/plans-et-espaces-commerciaux":
      "/pt/lancamentos/plantas-baixas-e-espacos-comerciais",
    "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu":
      "/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul",
    "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone":
      "/pt/lancamentos/renovar-interiores-com-ia-pelo-celular",
    "/fr/releases/vide-chambres-et-enlever-objets-avec-ia":
      "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia",
    "/fr/releases/espaces-de-travail": "/pt/lancamentos/espacos-trabalho",
    "/fr/releases/ameliorations-pedra-piscines-et-preservation":
      "/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao",
    "/fr/releases": "/pt/lancamentos",
    "/fr/versions/renovez-et-meublez-en-un-clic":
      "/pt/lancamentos/renovar-e-mobiliar-em-um-clique",
    "/fr/render": "/pt/render",
    "/fr/rendu-salle-de-bain": "/pt/renderizar-banheiro",
    "/fr/rendu-chambre": "/pt/renderizar-quarto",
    "/fr/render-depuis-plan": "/pt/render-desde-planta",
    "/fr/rendu-maison": "/pt/renderizar-casa",
    "/fr/rendu-cuisine": "/pt/renderizar-cozinha",
    "/fr/rendu-salon": "/pt/renderizar-sala",
    "/fr/rendu-local": "/pt/renderizacao-comercial",
    "/fr/rendu-terrasse": "/pt/renderizar-terraco",
    "/fr/rendus-de-renovation": "/pt/renderizacoes-para-reformas",
    "/fr/reviews-and-testimonials": "/pt/reviews-and-testimonials",
    "/es/about": "/pt/about",
    "/es/api-documentation": "/pt/api-documentation",
    "/es/blog": "/pt/blog",
    "/es/blog/como-utilizar-ia-para-vender-propiedades":
      "/pt/blog/como-vender-imoveis-com-ia",
    "/es/blog/marketing-inmobiliario": "/pt/blog/marketing-imobiliario",
    "/es/blog/tendencias-mercado-inmobiliario":
      "/pt/blog/tendencias-mercado-imobiliario",
    "/es/blog/publicidad-inmobiliaria": "/pt/blog/publicidade-imobiliaria",
    "/es/blog/pricing-inmobiliario": "/pt/blog/precificacao-imobiliaria",
    "/es/blog/mentor-inmobiliario": "/pt/blog/mentor-imobiliario",
    "/es/blog/como-personalizar-habitacion":
      "/pt/blog/como-personalizar-ambientes",
    "/es/blog/salas-hundidas": "/pt/blog/salas-rebaixadas",
    "/es/blog/domotizar-casa": "/pt/blog/automacao-residencial",
    "/es/blog/propiedad-no-se-vende": "/pt/blog/imovel-que-nao-vende",
    "/es/blog/flipping": "/pt/blog/flipping",
    "/es/blog/modernizar-casa": "/pt/blog/modernizar-casa",
    "/es/blog/diseñador-ambientes": "/pt/blog/designer-ambiental",

    "/es/blog/errores-al-vender-casa": "/pt/blog/erros-ao-vender-casa",
    "/es/blog/bienes-raices": "/pt/blog/imoveis",
    "/es/blog/frases-inmobiliarias": "/pt/blog/frases-imobiliarias",
    "/es/blog/fideicomiso-inmobiliario": "/pt/blog/confianca-imobiliaria",
    "/es/blog/terrenos-urbanizables": "/pt/blog/terreno-construivel",
    "/es/blog/como-vender-casa-a-reformar":
      "/pt/blog/como-vender-casa-para-reforma",
    "/es/blog/home-staging-tradicional-vs-home-staging-virtual":
      "/pt/blog/home-staging-tradicional-vs-virtual",
    "/es/blog/rentabilidad-home-staging-virtual":
      "/pt/blog/roi-home-staging-virtual",
    "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia":
      "/pt/blog/vender-mais-imoveis-com-ia",
    "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta":
      "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio",
    "/es/blog/home-staging-ejemplos": "/pt/blog/exemplos-home-staging",
    "/es/blog/espacios-multifuncionales": "/pt/blog/espacos-multifuncionais",
    "/es/blog/aumenta-valor-vivienda": "/pt/blog/aumentar-valor-imovel",
    "/es/floorplan": "/pt/planta-baixa",
    "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis":
      "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis",
    "/es/herramientas-gratis/convertidor-formato-imagen-gratuito":
      "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis",
    "/es": "/pt",
    "/es/home-staging-virtual": "/pt/home-staging-virtual",
    "/es/home-staging-bano": "/pt/home-staging-banheiro",
    "/es/home-staging-dormitorio": "/pt/home-staging-quarto",
    "/es/home-staging-cocina": "/pt/home-staging-cozinha",
    "/es/home-staging-salon": "/pt/home-staging-sala",
    "/es/home-staging-local": "/pt/home-staging-local",
    "/es/home-staging-terraza": "/pt/home-staging-terraco",
    "/es/help/como-anadir-objetos-3d": "/pt/ajuda/como-adicionar-objetos-3d",
    "/es/help/como-dibujar-plano": "/pt/ajuda/como-desenhar-planta-baixa",
    "/es/help/como-vaciar-habitacion-virtualmente":
      "/pt/ajuda/como-esvaziar-ambientes-virtualmente",
    "/es/help/como-amueblar-virtualmente":
      "/pt/ajuda/como-mobiliar-virtualmente",
    "/es/help/como-borrar-objeto": "/pt/ajuda/como-remover-objetos",
    "/es/help/como-crear-render-bano": "/pt/ajuda/como-criar-render-banheiro",
    "/es/help/como-crear-render-dormitorio":
      "/pt/ajuda/como-criar-render-quarto",
    "/es/help/renderizar-plano-de-planta-con-ia":
      "/pt/ajuda/renderizar-planta-baixa-com-ia",
    "/es/help/como-crear-render-exterior-casa":
      "/pt/ajuda/como-criar-render-externa-casa",
    "/es/help/como-crear-render-cocina": "/pt/ajuda/como-criar-render-cozinha",
    "/es/help/como-crear-render-sala-estar": "/pt/ajuda/como-criar-render-sala",
    "/es/help/como-crear-render-local-comercial":
      "/pt/ajuda/como-criar-render-local-comercial",
    "/es/help/como-crear-render-terraza": "/pt/ajuda/como-criar-render-terraco",
    "/es/help/como-renovar-casas-virtualmente":
      "/pt/ajuda/como-renovar-casas-virtualmente",
    "/es/help/como-crear-home-staging-virtual":
      "/pt/ajuda/como-criar-home-staging-virtual",
    "/es/help/como-crear-home-staging-virtual-bano":
      "/pt/ajuda/como-criar-home-staging-virtual-banheiro",
    "/es/help/como-crear-home-staging-virtual-dormitorio":
      "/pt/ajuda/como-criar-home-staging-virtual-quarto",
    "/es/help/como-crear-home-staging-virtual-cocina":
      "/pt/ajuda/como-criar-home-staging-virtual-cozinha",
    "/es/help/como-crear-home-staging-virtual-salon":
      "/pt/ajuda/como-criar-home-staging-virtual-sala",
    "/es/help/como-crear-home-staging-virtual-terraza":
      "/pt/ajuda/como-criar-home-staging-virtual-terraco",
    "/es/interiordesign": "/pt/interiordesign",
    "/es/legal": "/pt/legal",
    "": "",
    "/es/pricing": "/pt/precos",
    "/es/privacy": "/pt/privacy",
    "/es/realestate": "/pt/realestate",
    "/es/real-estate-photography": "/pt/fotografia-imobiliaria",
    "/es/releases/amueblado-realista": "/pt/lancamentos/mobiliario-realista",
    "/es/releases/planos-y-espacios-comerciales":
      "/pt/lancamentos/plantas-baixas-e-espacos-comerciais",
    "/es/releases/anade-objetos-3d-y-cielo-azul":
      "/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul",
    "/es/releases/renueva-espacios-desde-el-movil-con-ia":
      "/pt/lancamentos/renovar-interiores-com-ia-pelo-celular",
    "/es/releases/vacia-habitacion-y-quita-objetos-con-ia":
      "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia",
    "/es/releases/espacios-de-trabajo": "/pt/lancamentos/espacos-trabalho",
    "/es/releases/mejoras-pedra-piscinas-y-preservacion":
      "/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao",
    "/es/releases": "/pt/lancamentos",
    "/es/lanzamientos/renueva-y-amuebla-en-un-clic":
      "/pt/lancamentos/renovar-e-mobiliar-em-um-clique",
    "/es/render": "/pt/render",
    "/es/render-bano": "/pt/renderizar-banheiro",
    "/es/render-dormitorio": "/pt/renderizar-quarto",
    "/es/render-desde-plano": "/pt/render-desde-planta",
    "/es/render-casa": "/pt/renderizar-casa",
    "/es/render-cocina": "/pt/renderizar-cozinha",
    "/es/render-salon": "/pt/renderizar-sala",
    "/es/render-local": "/pt/renderizacao-comercial",
    "/es/render-terraza": "/pt/renderizar-terraco",
    "/es/renders-para-reformas": "/pt/renderizacoes-para-reformas",
    "/es/reviews-and-testimonials": "/pt/reviews-and-testimonials",
    "/about": "/pt/about",
    "/api-documentation": "/pt/api-documentation",
    "/blog": "/pt/blog",
    "/blog/how-to-sell-properties-with-ai":
      "/pt/blog/como-vender-imoveis-com-ia",
    "/blog/real-estate-marketing": "/pt/blog/marketing-imobiliario",
    "/blog/real-estate-market-trends":
      "/pt/blog/tendencias-mercado-imobiliario",
    "/blog/real-estate-advertising": "/pt/blog/publicidade-imobiliaria",
    "/blog/real-estate-pricing": "/pt/blog/precificacao-imobiliaria",
    "/blog/real-estate-mentor": "/pt/blog/mentor-imobiliario",
    "/blog/how-to-personalize-room": "/pt/blog/como-personalizar-ambientes",
    "/blog/sunken-living-rooms": "/pt/blog/salas-rebaixadas",
    "/blog/home-automation": "/pt/blog/automacao-residencial",
    "/blog/property-that-wont-sell": "/pt/blog/imovel-que-nao-vende",
    "/blog/flipping": "/pt/blog/flipping",
    "/blog/modernize-home": "/pt/blog/modernizar-casa",
    "/blog/environmental-designer": "/pt/blog/designer-ambiental",
    "/blog/short-sale": "/pt/blog/venda-rapida",
    "/blog/mistakes-when-selling-house": "/pt/blog/erros-ao-vender-casa",
    "/blog/real-estate": "/pt/blog/imoveis",
    "/blog/real-estate-phrases": "/pt/blog/frases-imobiliarias",
    "/blog/real-estate-trust": "/pt/blog/confianca-imobiliaria",
    "/blog/buildable-land": "/pt/blog/terreno-construivel",
    "/blog/how-to-sell-a-house-to-renovate":
      "/pt/blog/como-vender-casa-para-reforma",
    "/blog/traditional-home-staging-vs-virtual-home-staging":
      "/pt/blog/home-staging-tradicional-vs-virtual",
    "/blog/roi-virtual-staging": "/pt/blog/roi-home-staging-virtual",
    "/blog/sell-more-properties-with-ai": "/pt/blog/vender-mais-imoveis-com-ia",
    "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo":
      "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio",
    "/blog/how-to-make-sky-blue-real-estate-photo":
      "/pt/blog/como-fazer-ceu-azul-foto-imobiliaria",
    "/blog/home-staging-examples": "/pt/blog/exemplos-home-staging",
    "/blog/multifunctional-spaces": "/pt/blog/espacos-multifuncionais",
    "/blog/increase-home-value": "/pt/blog/aumentar-valor-imovel",
    "/floorplan": "/pt/planta-baixa",
    "/free-tools/generate-real-estate-listing-description-free":
      "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis",
    "/free-tools/free-image-format-converter":
      "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis",
    "/help": "/pt/ajuda",
    "/": "/pt",
    "/home-staging-virtual": "/pt/home-staging-virtual",
    "/home-staging-bathroom": "/pt/home-staging-banheiro",
    "/home-staging-bedroom": "/pt/home-staging-quarto",
    "/home-staging-kitchen": "/pt/home-staging-cozinha",
    "/home-staging-living-room": "/pt/home-staging-sala",
    "/home-staging-commercial": "/pt/home-staging-local",
    "/home-staging-terrace": "/pt/home-staging-terraco",
    "/help/how-to-add-3d-objects": "/pt/ajuda/como-adicionar-objetos-3d",
    "/help/how-to-draw-floor-plan": "/pt/ajuda/como-desenhar-planta-baixa",
    "/help/how-to-empty-rooms-virtually":
      "/pt/ajuda/como-esvaziar-ambientes-virtualmente",
    "/help/how-to-furnish-virtually": "/pt/ajuda/como-mobiliar-virtualmente",
    "/help/how-to-remove-object": "/pt/ajuda/como-remover-objetos",
    "/help/how-to-create-bathroom-render":
      "/pt/ajuda/como-criar-render-banheiro",
    "/help/how-to-create-bedroom-render": "/pt/ajuda/como-criar-render-quarto",
    "/help/render-floor-plan-with-ai":
      "/pt/ajuda/renderizar-planta-baixa-com-ia",
    "/help/how-to-create-house-exterior-render":
      "/pt/ajuda/como-criar-render-externa-casa",
    "/help/how-to-create-kitchen-render": "/pt/ajuda/como-criar-render-cozinha",
    "/help/how-to-create-living-room-render":
      "/pt/ajuda/como-criar-render-sala",
    "/help/how-to-create-commercial-space-render":
      "/pt/ajuda/como-criar-render-local-comercial",
    "/help/how-to-create-terrace-render": "/pt/ajuda/como-criar-render-terraco",
    "/help/how-to-renovate-houses-virtually":
      "/pt/ajuda/como-renovar-casas-virtualmente",
    "/help/how-to-create-virtual-home-staging":
      "/pt/ajuda/como-criar-home-staging-virtual",
    "/help/how-to-create-bathroom-virtual-home-staging":
      "/pt/ajuda/como-criar-home-staging-virtual-banheiro",
    "/help/how-to-create-bedroom-virtual-home-staging":
      "/pt/ajuda/como-criar-home-staging-virtual-quarto",
    "/help/how-to-create-kitchen-virtual-home-staging":
      "/pt/ajuda/como-criar-home-staging-virtual-cozinha",
    "/help/how-to-create-living-room-virtual-home-staging":
      "/pt/ajuda/como-criar-home-staging-virtual-sala",
    "/help/how-to-create-terrace-virtual-home-staging":
      "/pt/ajuda/como-criar-home-staging-virtual-terraco",
    "/interiordesign": "/pt/interiordesign",
    "/legal": "/pt/legal",
    "/pricing": "/pt/precos",
    "/privacy": "/pt/privacy",
    "/realestate": "/pt/realestate",
    "/real-estate-photography": "/pt/fotografia-imobiliaria",
    "/releases/realistic-furnishing": "/pt/lancamentos/mobiliario-realista",
    "/releases/floorplans-and-commercial-spaces":
      "/pt/lancamentos/plantas-baixas-e-espacos-comerciais",
    "/releases/add-3d-objects-and-make-sky-blue":
      "/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul",
    "/releases/renovate-interiors-with-ai-from-phone":
      "/pt/lancamentos/renovar-interiores-com-ia-pelo-celular",
    "/releases/empty-rooms-and-remove-objects-with-ai":
      "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia",
    "/releases/workspaces": "/pt/lancamentos/espacos-trabalho",
    "/releases/pedra-improvements-pools-and-preservation":
      "/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao",
    "/releases": "/pt/lancamentos",
    "/releases/renovate-and-furnish-in-one-click":
      "/pt/lancamentos/renovar-e-mobiliar-em-um-clique",
    "/render": "/pt/render",
    "/render-bathroom": "/pt/renderizar-banheiro",
    "/render-bedroom": "/pt/renderizar-quarto",
    "/render-from-floor-plan": "/pt/render-desde-planta",
    "/render-house": "/pt/renderizar-casa",
    "/render-kitchen": "/pt/renderizar-cozinha",
    "/render-living-room": "/pt/renderizar-sala",
    "/render-commercial": "/pt/renderizacao-comercial",
    "/render-terrace": "/pt/renderizar-terraco",
    "/renders-for-renovations": "/pt/renderizacoes-para-reformas",
    "/reviews-and-testimonials": "/pt/reviews-and-testimonials",
    "/de/about": "/pt/about",
    "/de/api-documentation": "/pt/api-documentation",
    "/de/blog": "/pt/blog",
    "/de/blog/wie-man-immobilien-mit-ki-verkauft":
      "/pt/blog/como-vender-imoveis-com-ia",
    "/de/blog/immobilien-marketing": "/pt/blog/marketing-imobiliario",
    "/de/blog/immobilienmarkt-trends":
      "/pt/blog/tendencias-mercado-imobiliario",
    "/de/blog/immobilien-werbung": "/pt/blog/publicidade-imobiliaria",
    "/de/blog/immobilien-preisgestaltung": "/pt/blog/precificacao-imobiliaria",
    "/de/blog/immobilien-mentor": "/pt/blog/mentor-imobiliario",
    "/de/blog/wie-man-raume-personalisiert":
      "/pt/blog/como-personalizar-ambientes",
    "/de/blog/versenktes-wohnzimmer": "/pt/blog/salas-rebaixadas",
    "/de/blog/hausautomatisierung": "/pt/blog/automacao-residencial",
    "/de/blog/immobilie-die-sich-nicht-verkauft":
      "/pt/blog/imovel-que-nao-vende",
    "/de/blog/flipping": "/pt/blog/flipping",
    "/de/blog/haus-modernisieren": "/pt/blog/modernizar-casa",
    "/de/blog/umweltdesigner": "/pt/blog/designer-ambiental",
    "/de/blog/schnellverkauf": "/pt/blog/venda-rapida",
    "/de/blog/fehler-beim-hausverkauf": "/pt/blog/erros-ao-vender-casa",
    "/de/blog/immobilien": "/pt/blog/imoveis",
    "/de/blog/immobilien-phrasen": "/pt/blog/frases-imobiliarias",
    "/de/blog/immobilien-vertrauen": "/pt/blog/confianca-imobiliaria",
    "/de/blog/bauland": "/pt/blog/terreno-construivel",
    "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft":
      "/pt/blog/como-vender-casa-para-reforma",
    "/de/blog/traditionelles-vs-virtuelles-home-staging":
      "/pt/blog/home-staging-tradicional-vs-virtual",
    "/de/blog/roi-virtuelles-home-staging": "/pt/blog/roi-home-staging-virtual",
    "/de/blog/mehr-immobilien-mit-ki-verkaufen":
      "/pt/blog/vender-mais-imoveis-com-ia",
    "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt":
      "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio",
    "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht":
      "/pt/blog/como-fazer-ceu-azul-foto-imobiliaria",
    "/de/blog/home-staging-beispiele": "/pt/blog/exemplos-home-staging",
    "/de/blog/multifunktionale-raume": "/pt/blog/espacos-multifuncionais",
    "/de/blog/hauswert-steigern": "/pt/blog/aumentar-valor-imovel",
    "/de/grundriss": "/pt/planta-baixa",
    "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen":
      "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis",
    "/de/kostenlose-tools/bildformat-konverter-kostenlos":
      "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis",
    "/de/hilfe": "/pt/ajuda",
    "/de": "/pt",
    "/de/virtuelles-home-staging": "/pt/home-staging-virtual",
    "/de/home-staging-badezimmer": "/pt/home-staging-banheiro",
    "/de/home-staging-schlafzimmer": "/pt/home-staging-quarto",
    "/de/home-staging-kuche": "/pt/home-staging-cozinha",
    "/de/home-staging-wohnzimmer": "/pt/home-staging-sala",
    "/de/home-staging-gewerbe": "/pt/home-staging-local",
    "/de/home-staging-terrasse": "/pt/home-staging-terraco",
    "/de/hilfe/wie-man-3d-objekte-hinzufugt":
      "/pt/ajuda/como-adicionar-objetos-3d",
    "/de/hilfe/wie-man-grundriss-zeichnet":
      "/pt/ajuda/como-desenhar-planta-baixa",
    "/de/hilfe/wie-man-raume-virtuell-leert":
      "/pt/ajuda/como-esvaziar-ambientes-virtualmente",
    "/de/hilfe/wie-man-virtuell-einrichtet":
      "/pt/ajuda/como-mobiliar-virtualmente",
    "/de/hilfe/wie-man-objekte-entfernt": "/pt/ajuda/como-remover-objetos",
    "/de/hilfe/wie-man-badezimmer-rendering-erstellt":
      "/pt/ajuda/como-criar-render-banheiro",
    "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt":
      "/pt/ajuda/como-criar-render-quarto",
    "/de/hilfe/grundriss-mit-ki-rendern":
      "/pt/ajuda/renderizar-planta-baixa-com-ia",
    "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt":
      "/pt/ajuda/como-criar-render-externa-casa",
    "/de/hilfe/wie-man-kuchen-rendering-erstellt":
      "/pt/ajuda/como-criar-render-cozinha",
    "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt":
      "/pt/ajuda/como-criar-render-sala",
    "/de/hilfe/wie-man-gewerberaum-rendering-erstellt":
      "/pt/ajuda/como-criar-render-local-comercial",
    "/de/hilfe/wie-man-terrassen-rendering-erstellt":
      "/pt/ajuda/como-criar-render-terraco",
    "/de/hilfe/wie-man-hauser-virtuell-renoviert":
      "/pt/ajuda/como-renovar-casas-virtualmente",
    "/de/hilfe/wie-man-virtuelles-home-staging-erstellt":
      "/pt/ajuda/como-criar-home-staging-virtual",
    "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt":
      "/pt/ajuda/como-criar-home-staging-virtual-banheiro",
    "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt":
      "/pt/ajuda/como-criar-home-staging-virtual-quarto",
    "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt":
      "/pt/ajuda/como-criar-home-staging-virtual-cozinha",
    "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt":
      "/pt/ajuda/como-criar-home-staging-virtual-sala",
    "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt":
      "/pt/ajuda/como-criar-home-staging-virtual-terraco",
    "/de/innenarchitektur": "/pt/interiordesign",
    "/de/rechtliches": "/pt/legal",
    "/de/preise": "/pt/precos",
    "/de/datenschutz": "/pt/privacy",
    "/de/immobilien": "/pt/realestate",
    "/de/immobilienfotografie": "/pt/fotografia-imobiliaria",
    "/de/releases/realistische-einrichtung":
      "/pt/lancamentos/mobiliario-realista",
    "/de/releases/grundrisse-und-gewerberaume":
      "/pt/lancamentos/plantas-baixas-e-espacos-comerciais",
    "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen":
      "/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul",
    "/de/releases/innenraume-mit-ki-vom-handy-renovieren":
      "/pt/lancamentos/renovar-interiores-com-ia-pelo-celular",
    "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen":
      "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia",
    "/de/releases/arbeitsplatze": "/pt/lancamentos/espacos-trabalho",
    "/de/releases/stein-verbesserungen-pools-und-erhaltung":
      "/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao",
    "/de/releases": "/pt/lancamentos",
    "/de/releases/renovieren-und-einrichten-mit-einem-klick":
      "/pt/lancamentos/renovar-e-mobiliar-em-um-clique",
    "/de/render": "/pt/render",
    "/de/badezimmer-rendering": "/pt/renderizar-banheiro",
    "/de/schlafzimmer-rendering": "/pt/renderizar-quarto",
    "/de/rendering-vom-grundriss": "/pt/render-desde-planta",
    "/de/haus-rendering": "/pt/renderizar-casa",
    "/de/kuchen-rendering": "/pt/renderizar-cozinha",
    "/de/wohnzimmer-rendering": "/pt/renderizar-sala",
    "/de/gewerbe-rendering": "/pt/renderizacao-comercial",
    "/de/terrassen-rendering": "/pt/renderizar-terraco",
    "/de/renderings-fur-renovierungen": "/pt/renderizacoes-para-reformas",
    "/de/reviews-and-testimonials": "/pt/reviews-and-testimonials",

    "/es/help/como-crear-efecto-cortinilla-home-staging-virtual":
      "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual",
    "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel":
      "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual",
    "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt":
      "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual",
    "/help/how-to-create-virtual-home-staging-curtain-effect":
      "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual",

    "/es/blog/consejos-para-fotografia-inmobiliaria":
      "/pt/blog/dicas-de-fotografia-imobiliaria",
    "/fr/blog/conseils-pour-la-photographie-immobiliere":
      "/pt/blog/dicas-de-fotografia-imobiliaria",
    "/de/blog/tipps-fur-immobilienfotografie":
      "/pt/blog/dicas-de-fotografia-imobiliaria",
    "/blog/real-estate-photography-tips":
      "/pt/blog/dicas-de-fotografia-imobiliaria",

    "/blog/property-listing-optimization":
      "/pt/blog/otimizacao-de-anuncios-imobiliarios",
    "/es/blog/optimizacion-de-anuncios-inmobiliarios":
      "/pt/blog/otimizacao-de-anuncios-imobiliarios",
    "/fr/blog/optimisation-des-annonces-immobilieres":
      "/pt/blog/otimizacao-de-anuncios-imobiliarios",
    "/de/blog/optimierung-von-immobilienanzeigen":
      "/pt/blog/otimizacao-de-anuncios-imobiliarios",
  },
  de: {
    "/about": "/de/about",
    "/api-documentation": "/de/api-documentation",
    "/blog": "/de/blog",
    "/blog/how-to-sell-properties-with-ai":
      "/de/blog/wie-man-immobilien-mit-ki-verkauft",
    "/blog/real-estate-marketing": "/de/blog/immobilien-marketing",
    "/blog/real-estate-market-trends": "/de/blog/immobilienmarkt-trends",
    "/blog/real-estate-advertising": "/de/blog/immobilien-werbung",
    "/blog/real-estate-pricing": "/de/blog/immobilien-preisgestaltung",
    "/blog/real-estate-mentor": "/de/blog/immobilien-mentor",
    "/blog/how-to-personalize-room": "/de/blog/wie-man-raume-personalisiert",
    "/blog/sunken-living-rooms": "/de/blog/versenktes-wohnzimmer",
    "/blog/home-automation": "/de/blog/hausautomatisierung",
    "/blog/property-that-wont-sell":
      "/de/blog/immobilie-die-sich-nicht-verkauft",
    "/blog/flipping": "/de/blog/flipping",
    "/blog/modernize-home": "/de/blog/haus-modernisieren",
    "/blog/environmental-designer": "/de/blog/umweltdesigner",
    "/blog/short-sale": "/de/blog/schnellverkauf",
    "/blog/mistakes-when-selling-house": "/de/blog/fehler-beim-hausverkauf",
    "/blog/real-estate": "/de/blog/immobilien",
    "/blog/real-estate-phrases": "/de/blog/immobilien-phrasen",
    "/blog/real-estate-trust": "/de/blog/immobilien-vertrauen",
    "/blog/buildable-land": "/de/blog/bauland",
    "/blog/how-to-sell-a-house-to-renovate":
      "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft",
    "/blog/traditional-home-staging-vs-virtual-home-staging":
      "/de/blog/traditionelles-vs-virtuelles-home-staging",
    "/blog/roi-virtual-staging": "/de/blog/roi-virtuelles-home-staging",
    "/blog/sell-more-properties-with-ai":
      "/de/blog/mehr-immobilien-mit-ki-verkaufen",
    "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo":
      "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt",
    "/blog/how-to-make-sky-blue-real-estate-photo":
      "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht",
    "/blog/home-staging-examples": "/de/blog/home-staging-beispiele",
    "/blog/multifunctional-spaces": "/de/blog/multifunktionale-raume",
    "/blog/increase-home-value": "/de/blog/hauswert-steigern",
    "/floorplan": "/de/grundriss",
    "/free-tools/generate-real-estate-listing-description-free":
      "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen",
    "/free-tools/free-image-format-converter":
      "/de/kostenlose-tools/bildformat-konverter-kostenlos",
    "/help": "/de/hilfe",
    "/": "/de",
    "/home-staging-virtual": "/de/virtuelles-home-staging",
    "/home-staging-bathroom": "/de/home-staging-badezimmer",
    "/home-staging-bedroom": "/de/home-staging-schlafzimmer",
    "/home-staging-kitchen": "/de/home-staging-kuche",
    "/home-staging-living-room": "/de/home-staging-wohnzimmer",
    "/home-staging-commercial": "/de/home-staging-gewerbe",
    "/home-staging-terrace": "/de/home-staging-terrasse",
    "/help/how-to-add-3d-objects": "/de/hilfe/wie-man-3d-objekte-hinzufugt",
    "/help/how-to-draw-floor-plan": "/de/hilfe/wie-man-grundriss-zeichnet",
    "/help/how-to-empty-rooms-virtually":
      "/de/hilfe/wie-man-raume-virtuell-leert",
    "/help/how-to-furnish-virtually": "/de/hilfe/wie-man-virtuell-einrichtet",
    "/help/how-to-remove-object": "/de/hilfe/wie-man-objekte-entfernt",
    "/help/how-to-create-bathroom-render":
      "/de/hilfe/wie-man-badezimmer-rendering-erstellt",
    "/help/how-to-create-bedroom-render":
      "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt",
    "/help/render-floor-plan-with-ai": "/de/hilfe/grundriss-mit-ki-rendern",
    "/help/how-to-create-house-exterior-render":
      "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt",
    "/help/how-to-create-kitchen-render":
      "/de/hilfe/wie-man-kuchen-rendering-erstellt",
    "/help/how-to-create-living-room-render":
      "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt",
    "/help/how-to-create-commercial-space-render":
      "/de/hilfe/wie-man-gewerberaum-rendering-erstellt",
    "/help/how-to-create-terrace-render":
      "/de/hilfe/wie-man-terrassen-rendering-erstellt",
    "/help/how-to-renovate-houses-virtually":
      "/de/hilfe/wie-man-hauser-virtuell-renoviert",
    "/help/how-to-create-virtual-home-staging":
      "/de/hilfe/wie-man-virtuelles-home-staging-erstellt",
    "/help/how-to-create-bathroom-virtual-home-staging":
      "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt",
    "/help/how-to-create-bedroom-virtual-home-staging":
      "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt",
    "/help/how-to-create-kitchen-virtual-home-staging":
      "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt",
    "/help/how-to-create-living-room-virtual-home-staging":
      "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt",
    "/help/how-to-create-terrace-virtual-home-staging":
      "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt",
    "/interiordesign": "/de/innenarchitektur",
    "/legal": "/de/rechtliches",
    "/pricing": "/de/preise",
    "/privacy": "/de/datenschutz",
    "/realestate": "/de/immobilien",
    "/real-estate-photography": "/de/immobilienfotografie",
    "/releases/realistic-furnishing": "/de/releases/realistische-einrichtung",
    "/releases/floorplans-and-commercial-spaces":
      "/de/releases/grundrisse-und-gewerberaume",
    "/releases/add-3d-objects-and-make-sky-blue":
      "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen",
    "/releases/renovate-interiors-with-ai-from-phone":
      "/de/releases/innenraume-mit-ki-vom-handy-renovieren",
    "/releases/empty-rooms-and-remove-objects-with-ai":
      "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen",
    "/releases/workspaces": "/de/releases/arbeitsplatze",
    "/releases/pedra-improvements-pools-and-preservation":
      "/de/releases/stein-verbesserungen-pools-und-erhaltung",
    "/releases": "/de/releases",
    "/releases/renovate-and-furnish-in-one-click":
      "/de/releases/renovieren-und-einrichten-mit-einem-klick",
    "/render": "/de/render",
    "/render-bathroom": "/de/badezimmer-rendering",
    "/render-bedroom": "/de/schlafzimmer-rendering",
    "/render-from-floor-plan": "/de/rendering-vom-grundriss",
    "/render-house": "/de/haus-rendering",
    "/render-kitchen": "/de/kuchen-rendering",
    "/render-living-room": "/de/wohnzimmer-rendering",
    "/render-commercial": "/de/gewerbe-rendering",
    "/render-terrace": "/de/terrassen-rendering",
    "/renders-for-renovations": "/de/renderings-fur-renovierungen",
    "/reviews-and-testimonials": "/de/reviews-and-testimonials",
    "/es/about": "/de/about",
    "/es/api-documentation": "/de/api-documentation",
    "/es/blog": "/de/blog",
    "/es/blog/como-utilizar-ia-para-vender-propiedades":
      "/de/blog/wie-man-immobilien-mit-ki-verkauft",
    "/es/blog/marketing-inmobiliario": "/de/blog/immobilien-marketing",
    "/es/blog/tendencias-mercado-inmobiliario":
      "/de/blog/immobilienmarkt-trends",
    "/es/blog/publicidad-inmobiliaria": "/de/blog/immobilien-werbung",
    "/es/blog/pricing-inmobiliario": "/de/blog/immobilien-preisgestaltung",
    "/es/blog/mentor-inmobiliario": "/de/blog/immobilien-mentor",
    "/es/blog/como-personalizar-habitacion":
      "/de/blog/wie-man-raume-personalisiert",
    "/es/blog/salas-hundidas": "/de/blog/versenktes-wohnzimmer",
    "/es/blog/domotizar-casa": "/de/blog/hausautomatisierung",
    "/es/blog/propiedad-no-se-vende":
      "/de/blog/immobilie-die-sich-nicht-verkauft",
    "/es/blog/flipping": "/de/blog/flipping",
    "/es/blog/modernizar-casa": "/de/blog/haus-modernisieren",
    "/es/blog/diseñador-ambientes": "/de/blog/umweltdesigner",
    "/es/blog/short-sale": "/de/blog/schnellverkauf",
    "/es/blog/errores-al-vender-casa": "/de/blog/fehler-beim-hausverkauf",
    "/es/blog/bienes-raices": "/de/blog/immobilien",
    "/es/blog/frases-inmobiliarias": "/de/blog/immobilien-phrasen",
    "/es/blog/fideicomiso-inmobiliario": "/de/blog/immobilien-vertrauen",
    "/es/blog/terrenos-urbanizables": "/de/blog/bauland",
    "/es/blog/como-vender-casa-a-reformar":
      "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft",
    "/es/blog/home-staging-tradicional-vs-home-staging-virtual":
      "/de/blog/traditionelles-vs-virtuelles-home-staging",
    "/es/blog/rentabilidad-home-staging-virtual":
      "/de/blog/roi-virtuelles-home-staging",
    "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia":
      "/de/blog/mehr-immobilien-mit-ki-verkaufen",
    "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta":
      "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt",
    "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles":
      "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht",
    "/es/blog/home-staging-ejemplos": "/de/blog/home-staging-beispiele",
    "/es/blog/espacios-multifuncionales": "/de/blog/multifunktionale-raume",
    "/es/blog/aumenta-valor-vivienda": "/de/blog/hauswert-steigern",
    "/es/floorplan": "/de/grundriss",
    "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis":
      "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen",
    "/es/herramientas-gratis/convertidor-formato-imagen-gratuito":
      "/de/kostenlose-tools/bildformat-konverter-kostenlos",
    "/fr/help": "/de/hilfe",
    "/es": "/de",
    "/es/home-staging-virtual": "/de/virtuelles-home-staging",
    "/es/home-staging-bano": "/de/home-staging-badezimmer",
    "/es/home-staging-dormitorio": "/de/home-staging-schlafzimmer",
    "/es/home-staging-cocina": "/de/home-staging-kuche",
    "/es/home-staging-salon": "/de/home-staging-wohnzimmer",
    "/es/home-staging-local": "/de/home-staging-gewerbe",
    "/es/home-staging-terraza": "/de/home-staging-terrasse",
    "/es/help/como-anadir-objetos-3d": "/de/hilfe/wie-man-3d-objekte-hinzufugt",
    "/es/help/como-dibujar-plano": "/de/hilfe/wie-man-grundriss-zeichnet",
    "/es/help/como-vaciar-habitacion-virtualmente":
      "/de/hilfe/wie-man-raume-virtuell-leert",
    "/es/help/como-amueblar-virtualmente":
      "/de/hilfe/wie-man-virtuell-einrichtet",
    "/es/help/como-borrar-objeto": "/de/hilfe/wie-man-objekte-entfernt",
    "/es/help/como-crear-render-bano":
      "/de/hilfe/wie-man-badezimmer-rendering-erstellt",
    "/es/help/como-crear-render-dormitorio":
      "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt",
    "/es/help/renderizar-plano-de-planta-con-ia":
      "/de/hilfe/grundriss-mit-ki-rendern",
    "/es/help/como-crear-render-exterior-casa":
      "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt",
    "/es/help/como-crear-render-cocina":
      "/de/hilfe/wie-man-kuchen-rendering-erstellt",
    "/es/help/como-crear-render-sala-estar":
      "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt",
    "/es/help/como-crear-render-local-comercial":
      "/de/hilfe/wie-man-gewerberaum-rendering-erstellt",
    "/es/help/como-crear-render-terraza":
      "/de/hilfe/wie-man-terrassen-rendering-erstellt",
    "/es/help/como-renovar-casas-virtualmente":
      "/de/hilfe/wie-man-hauser-virtuell-renoviert",
    "/es/help/como-crear-home-staging-virtual":
      "/de/hilfe/wie-man-virtuelles-home-staging-erstellt",
    "/es/help/como-crear-home-staging-virtual-bano":
      "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt",
    "/es/help/como-crear-home-staging-virtual-dormitorio":
      "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt",
    "/es/help/como-crear-home-staging-virtual-cocina":
      "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt",
    "/es/help/como-crear-home-staging-virtual-salon":
      "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt",
    "/es/help/como-crear-home-staging-virtual-terraza":
      "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt",
    "/es/interiordesign": "/de/innenarchitektur",
    "/es/legal": "/de/rechtliches",
    "/es/pricing": "/de/preise",
    "/es/privacy": "/de/datenschutz",
    "/es/realestate": "/de/immobilien",
    "/es/real-estate-photography": "/de/immobilienfotografie",
    "/es/releases/amueblado-realista": "/de/releases/realistische-einrichtung",
    "/es/releases/planos-y-espacios-comerciales":
      "/de/releases/grundrisse-und-gewerberaume",
    "/es/releases/anade-objetos-3d-y-cielo-azul":
      "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen",
    "/es/releases/renueva-espacios-desde-el-movil-con-ia":
      "/de/releases/innenraume-mit-ki-vom-handy-renovieren",
    "/es/releases/vacia-habitacion-y-quita-objetos-con-ia":
      "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen",
    "/es/releases/espacios-de-trabajo": "/de/releases/arbeitsplatze",
    "/es/releases/mejoras-pedra-piscinas-y-preservacion":
      "/de/releases/stein-verbesserungen-pools-und-erhaltung",
    "/es/releases": "/de/releases",
    "/es/lanzamientos/renueva-y-amuebla-en-un-clic":
      "/de/releases/renovieren-und-einrichten-mit-einem-klick",
    "/es/render": "/de/render",
    "/es/render-bano": "/de/badezimmer-rendering",
    "/es/render-dormitorio": "/de/schlafzimmer-rendering",
    "/es/render-desde-plano": "/de/rendering-vom-grundriss",
    "/es/render-casa": "/de/haus-rendering",
    "/es/render-cocina": "/de/kuchen-rendering",
    "/es/render-salon": "/de/wohnzimmer-rendering",
    "/es/render-local": "/de/gewerbe-rendering",
    "/es/render-terraza": "/de/terrassen-rendering",
    "/es/renders-para-reformas": "/de/renderings-fur-renovierungen",
    "/es/reviews-and-testimonials": "/de/reviews-and-testimonials",
    "/fr/about": "/de/about",
    "/fr/api-documentation": "/de/api-documentation",
    "/fr/blog": "/de/blog",
    "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes":
      "/de/blog/wie-man-immobilien-mit-ki-verkauft",
    "/fr/blog/marketing-immobilier": "/de/blog/immobilien-marketing",
    "/fr/blog/tendances-marche-immobilier": "/de/blog/immobilienmarkt-trends",
    "/fr/blog/publicite-immobiliere": "/de/blog/immobilien-werbung",
    "/fr/blog/pricing-immobilier": "/de/blog/immobilien-preisgestaltung",
    "/fr/blog/mentor-immobilier": "/de/blog/immobilien-mentor",
    "/fr/blog/comment-personnaliser-chambre":
      "/de/blog/wie-man-raume-personalisiert",
    "/fr/blog/salons-en-contrebas": "/de/blog/versenktes-wohnzimmer",
    "/fr/blog/domotiser-maison": "/de/blog/hausautomatisierung",
    "/fr/blog/propriete-qui-ne-se-vend-pas":
      "/de/blog/immobilie-die-sich-nicht-verkauft",
    "/fr/blog/flipping": "/de/blog/flipping",
    "/fr/blog/moderniser-maison": "/de/blog/haus-modernisieren",
    "/fr/blog/designer-environnement": "/de/blog/umweltdesigner",
    "/fr/blog/short-sale": "/de/blog/schnellverkauf",
    "/fr/blog/erreurs-vente-maison": "/de/blog/fehler-beim-hausverkauf",
    "/fr/blog/immobilier": "/de/blog/immobilien",
    "/fr/blog/phrases-immobilieres": "/de/blog/immobilien-phrasen",
    "/fr/blog/fiducie-immobiliere": "/de/blog/immobilien-vertrauen",
    "/fr/blog/terrains-constructibles": "/de/blog/bauland",
    "/fr/blog/comment-vendre-une-maison-a-renover":
      "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft",
    "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel":
      "/de/blog/traditionelles-vs-virtuelles-home-staging",
    "/fr/blog/rentabilite-home-staging-virtuel":
      "/de/blog/roi-virtuelles-home-staging",
    "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia":
      "/de/blog/mehr-immobilien-mit-ki-verkaufen",
    "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere":
      "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt",
    "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres":
      "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht",
    "/fr/blog/exemples-de-home-staging": "/de/blog/home-staging-beispiele",
    "/fr/blog/espaces-multifonctionnels": "/de/blog/multifunktionale-raume",
    "/fr/blog/augmentez-valeur-maison": "/de/blog/hauswert-steigern",
    "/fr/floorplan": "/de/grundriss",
    "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit":
      "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen",
    "/fr/outils-gratuits/convertisseur-format-image":
      "/de/kostenlose-tools/bildformat-konverter-kostenlos",
    "/es/help": "/de/hilfe",
    "/fr": "/de",
    "/fr/home-staging-virtual": "/de/virtuelles-home-staging",
    "/fr/home-staging-salle-de-bain": "/de/home-staging-badezimmer",
    "/fr/home-staging-chambre": "/de/home-staging-schlafzimmer",
    "/fr/home-staging-cuisine": "/de/home-staging-kuche",
    "/fr/home-staging-salon": "/de/home-staging-wohnzimmer",
    "/fr/home-staging-local": "/de/home-staging-gewerbe",
    "/fr/home-staging-terrasse": "/de/home-staging-terrasse",
    "/fr/help/comment-ajouter-des-objets-3d":
      "/de/hilfe/wie-man-3d-objekte-hinzufugt",
    "/fr/help/comment-dessiner-plan": "/de/hilfe/wie-man-grundriss-zeichnet",
    "/fr/help/comment-vider-pieces-virtuellement":
      "/de/hilfe/wie-man-raume-virtuell-leert",
    "/fr/help/comment-meubler-en-virtuel":
      "/de/hilfe/wie-man-virtuell-einrichtet",
    "/fr/help/comment-supprimer-un-objet": "/de/hilfe/wie-man-objekte-entfernt",
    "/fr/help/comment-creer-rendu-salle-de-bain":
      "/de/hilfe/wie-man-badezimmer-rendering-erstellt",
    "/fr/help/comment-creer-rendu-chambre":
      "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt",
    "/fr/help/rendu-plan-etage-avec-ia": "/de/hilfe/grundriss-mit-ki-rendern",
    "/fr/help/comment-creer-rendu-exterieur-maison":
      "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt",
    "/fr/help/comment-creer-rendu-cuisine":
      "/de/hilfe/wie-man-kuchen-rendering-erstellt",
    "/fr/help/comment-creer-rendu-salon":
      "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt",
    "/fr/help/comment-creer-rendu-local-commercial":
      "/de/hilfe/wie-man-gewerberaum-rendering-erstellt",
    "/fr/help/comment-creer-rendu-terrasse":
      "/de/hilfe/wie-man-terrassen-rendering-erstellt",
    "/fr/help/comment-renover-des-maisons-en-virtuel":
      "/de/hilfe/wie-man-hauser-virtuell-renoviert",
    "/fr/help/comment-creer-home-staging-virtuel":
      "/de/hilfe/wie-man-virtuelles-home-staging-erstellt",
    "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain":
      "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt",
    "/fr/help/comment-creer-home-staging-virtuel-chambre":
      "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt",
    "/fr/help/comment-creer-home-staging-virtuel-cuisine":
      "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt",
    "/fr/help/comment-creer-home-staging-virtuel-salon":
      "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt",
    "/fr/help/comment-creer-home-staging-virtuel-terrasse":
      "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt",
    "/fr/interiordesign": "/de/innenarchitektur",
    "/fr/legal": "/de/rechtliches",
    "/fr/pricing": "/de/preise",
    "/fr/privacy": "/de/datenschutz",
    "/fr/realestate": "/de/immobilien",
    "/fr/real-estate-photography": "/de/immobilienfotografie",
    "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres":
      "/de/releases/realistische-einrichtung",
    "/fr/releases/plans-et-espaces-commerciaux":
      "/de/releases/grundrisse-und-gewerberaume",
    "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu":
      "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen",
    "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone":
      "/de/releases/innenraume-mit-ki-vom-handy-renovieren",
    "/fr/releases/vide-chambres-et-enlever-objets-avec-ia":
      "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen",
    "/fr/releases/espaces-de-travail": "/de/releases/arbeitsplatze",
    "/fr/releases/ameliorations-pedra-piscines-et-preservation":
      "/de/releases/stein-verbesserungen-pools-und-erhaltung",
    "/fr/releases": "/de/releases",
    "/fr/versions/renovez-et-meublez-en-un-clic":
      "/de/releases/renovieren-und-einrichten-mit-einem-klick",
    "/fr/render": "/de/render",
    "/fr/rendu-salle-de-bain": "/de/badezimmer-rendering",
    "/fr/rendu-chambre": "/de/schlafzimmer-rendering",
    "/fr/render-depuis-plan": "/de/rendering-vom-grundriss",
    "/fr/rendu-maison": "/de/haus-rendering",
    "/fr/rendu-cuisine": "/de/kuchen-rendering",
    "/fr/rendu-salon": "/de/wohnzimmer-rendering",
    "/fr/rendu-local": "/de/gewerbe-rendering",
    "/fr/rendu-terrasse": "/de/terrassen-rendering",
    "/fr/rendus-de-renovation": "/de/renderings-fur-renovierungen",
    "/fr/reviews-and-testimonials": "/de/reviews-and-testimonials",
    "/pt/about": "/de/about",
    "/pt/api-documentation": "/de/api-documentation",
    "/pt/blog": "/de/blog",
    "/pt/blog/como-vender-imoveis-com-ia":
      "/de/blog/wie-man-immobilien-mit-ki-verkauft",
    "/pt/blog/marketing-imobiliario": "/de/blog/immobilien-marketing",
    "/pt/blog/tendencias-mercado-imobiliario":
      "/de/blog/immobilienmarkt-trends",
    "/pt/blog/publicidade-imobiliaria": "/de/blog/immobilien-werbung",
    "/pt/blog/precificacao-imobiliaria": "/de/blog/immobilien-preisgestaltung",
    "/pt/blog/mentor-imobiliario": "/de/blog/immobilien-mentor",
    "/pt/blog/como-personalizar-ambientes":
      "/de/blog/wie-man-raume-personalisiert",
    "/pt/blog/salas-rebaixadas": "/de/blog/versenktes-wohnzimmer",
    "/pt/blog/automacao-residencial": "/de/blog/hausautomatisierung",
    "/pt/blog/imovel-que-nao-vende":
      "/de/blog/immobilie-die-sich-nicht-verkauft",
    "/pt/blog/flipping": "/de/blog/flipping",
    "/pt/blog/modernizar-casa": "/de/blog/haus-modernisieren",
    "/pt/blog/designer-ambiental": "/de/blog/umweltdesigner",
    "/pt/blog/venda-rapida": "/de/blog/schnellverkauf",
    "/pt/blog/erros-ao-vender-casa": "/de/blog/fehler-beim-hausverkauf",
    "/pt/blog/imoveis": "/de/blog/immobilien",
    "/pt/blog/frases-imobiliarias": "/de/blog/immobilien-phrasen",
    "/pt/blog/confianca-imobiliaria": "/de/blog/immobilien-vertrauen",
    "/pt/blog/terreno-construivel": "/de/blog/bauland",
    "/pt/blog/como-vender-casa-para-reforma":
      "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft",
    "/pt/blog/home-staging-tradicional-vs-virtual":
      "/de/blog/traditionelles-vs-virtuelles-home-staging",
    "/pt/blog/roi-home-staging-virtual": "/de/blog/roi-virtuelles-home-staging",
    "/pt/blog/vender-mais-imoveis-com-ia":
      "/de/blog/mehr-immobilien-mit-ki-verkaufen",
    "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio":
      "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt",
    "/pt/blog/como-fazer-ceu-azul-foto-imobiliaria":
      "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht",
    "/pt/blog/exemplos-home-staging": "/de/blog/home-staging-beispiele",
    "/pt/blog/espacos-multifuncionais": "/de/blog/multifunktionale-raume",
    "/pt/blog/aumentar-valor-imovel": "/de/blog/hauswert-steigern",
    "/pt/planta-baixa": "/de/grundriss",
    "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis":
      "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen",
    "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis":
      "/de/kostenlose-tools/bildformat-konverter-kostenlos",
    "/pt/ajuda": "/de/hilfe",
    "/pt": "/de",
    "/pt/home-staging-virtual": "/de/virtuelles-home-staging",
    "/pt/home-staging-banheiro": "/de/home-staging-badezimmer",
    "/pt/home-staging-quarto": "/de/home-staging-schlafzimmer",
    "/pt/home-staging-cozinha": "/de/home-staging-kuche",
    "/pt/home-staging-sala": "/de/home-staging-wohnzimmer",
    "/pt/home-staging-local": "/de/home-staging-gewerbe",
    "/pt/home-staging-terraco": "/de/home-staging-terrasse",
    "/pt/ajuda/como-adicionar-objetos-3d":
      "/de/hilfe/wie-man-3d-objekte-hinzufugt",
    "/pt/ajuda/como-desenhar-planta-baixa":
      "/de/hilfe/wie-man-grundriss-zeichnet",
    "/pt/ajuda/como-esvaziar-ambientes-virtualmente":
      "/de/hilfe/wie-man-raume-virtuell-leert",
    "/pt/ajuda/como-mobiliar-virtualmente":
      "/de/hilfe/wie-man-virtuell-einrichtet",
    "/pt/ajuda/como-remover-objetos": "/de/hilfe/wie-man-objekte-entfernt",
    "/pt/ajuda/como-criar-render-banheiro":
      "/de/hilfe/wie-man-badezimmer-rendering-erstellt",
    "/pt/ajuda/como-criar-render-quarto":
      "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt",
    "/pt/ajuda/renderizar-planta-baixa-com-ia":
      "/de/hilfe/grundriss-mit-ki-rendern",
    "/pt/ajuda/como-criar-render-externa-casa":
      "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt",
    "/pt/ajuda/como-criar-render-cozinha":
      "/de/hilfe/wie-man-kuchen-rendering-erstellt",
    "/pt/ajuda/como-criar-render-sala":
      "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt",
    "/pt/ajuda/como-criar-render-local-comercial":
      "/de/hilfe/wie-man-gewerberaum-rendering-erstellt",
    "/pt/ajuda/como-criar-render-terraco":
      "/de/hilfe/wie-man-terrassen-rendering-erstellt",
    "/pt/ajuda/como-renovar-casas-virtualmente":
      "/de/hilfe/wie-man-hauser-virtuell-renoviert",
    "/pt/ajuda/como-criar-home-staging-virtual":
      "/de/hilfe/wie-man-virtuelles-home-staging-erstellt",
    "/pt/ajuda/como-criar-home-staging-virtual-banheiro":
      "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt",
    "/pt/ajuda/como-criar-home-staging-virtual-quarto":
      "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt",
    "/pt/ajuda/como-criar-home-staging-virtual-cozinha":
      "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt",
    "/pt/ajuda/como-criar-home-staging-virtual-sala":
      "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt",
    "/pt/ajuda/como-criar-home-staging-virtual-terraco":
      "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt",
    "/pt/interiordesign": "/de/innenarchitektur",
    "/pt/legal": "/de/rechtliches",
    "/pt/precos": "/de/preise",
    "/pt/privacy": "/de/datenschutz",
    "/pt/realestate": "/de/immobilien",
    "/pt/fotografia-imobiliaria": "/de/immobilienfotografie",
    "/pt/lancamentos/mobiliario-realista":
      "/de/releases/realistische-einrichtung",
    "/pt/lancamentos/plantas-baixas-e-espacos-comerciais":
      "/de/releases/grundrisse-und-gewerberaume",
    "/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul":
      "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen",
    "/pt/lancamentos/renovar-interiores-com-ia-pelo-celular":
      "/de/releases/innenraume-mit-ki-vom-handy-renovieren",
    "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia":
      "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen",
    "/pt/lancamentos/espacos-trabalho": "/de/releases/arbeitsplatze",
    "/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao":
      "/de/releases/stein-verbesserungen-pools-und-erhaltung",
    "/pt/lancamentos": "/de/releases",
    "/pt/lancamentos/renovar-e-mobiliar-em-um-clique":
      "/de/releases/renovieren-und-einrichten-mit-einem-klick",
    "/pt/render": "/de/render",
    "/pt/renderizar-banheiro": "/de/badezimmer-rendering",
    "/pt/renderizar-quarto": "/de/schlafzimmer-rendering",
    "/pt/render-desde-planta": "/de/rendering-vom-grundriss",
    "/pt/renderizar-casa": "/de/haus-rendering",
    "/pt/renderizar-cozinha": "/de/kuchen-rendering",
    "/pt/renderizar-sala": "/de/wohnzimmer-rendering",
    "/pt/renderizacao-comercial": "/de/gewerbe-rendering",
    "/pt/renderizar-terraco": "/de/terrassen-rendering",
    "/pt/renderizacoes-para-reformas": "/de/renderings-fur-renovierungen",
    "/pt/reviews-and-testimonials": "/de/reviews-and-testimonials",

    "/es/help/como-crear-efecto-cortinilla-home-staging-virtual":
      "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt",
    "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel":
      "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt",
    "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual":
      "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt",
    "/help/how-to-create-virtual-home-staging-curtain-effect":
      "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt",

    "/es/blog/consejos-para-fotografia-inmobiliaria":
      "/de/blog/tipps-fur-immobilienfotografie",
    "/fr/blog/conseils-pour-la-photographie-immobiliere":
      "/de/blog/tipps-fur-immobilienfotografie",
    "/pt/blog/dicas-de-fotografia-imobiliaria":
      "/de/blog/tipps-fur-immobilienfotografie",
    "/blog/real-estate-photography-tips":
      "/de/blog/tipps-fur-immobilienfotografie",

    "/blog/property-listing-optimization":
      "/de/blog/optimierung-von-immobilienanzeigen",
    "/es/blog/optimizacion-de-anuncios-inmobiliarios":
      "/de/blog/optimierung-von-immobilienanzeigen",
    "/fr/blog/optimisation-des-annonces-immobilieres":
      "/de/blog/optimierung-von-immobilienanzeigen",
    "/pt/blog/otimizacao-de-anuncios-imobiliarios":
      "/de/blog/optimierung-von-immobilienanzeigen",
  },
};

const LanguageSwitcher = ({ setLocale, setLocalePath }) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const getLanguageFromPath = (path) => {
    const langMatch = path.match(/^\/([a-z]{2})\/?$/);
    if (langMatch) return langMatch[1];
    const generalMatch = path.match(/^\/([a-z]{2})\//);
    return generalMatch ? generalMatch[1] : "en";
  };

  const getNewPath = (targetLang) => {
    const currentLang = getLanguageFromPath(currentPath);

    // Special handling for root language paths (/fr, /es)
    if (currentPath.match(/^\/[a-z]{2}\/?$/)) {
      if (targetLang === "en") {
        return "/";
      }
      return `/${targetLang}`;
    }

    if (currentLang === targetLang) return currentPath;

    if (urlMappings[targetLang][currentPath]) {
      return urlMappings[targetLang][currentPath];
    }

    if (currentPath.startsWith(`/${currentLang}/`)) {
      if (targetLang === "en") {
        return currentPath.replace(/^\/[a-z]{2}\//, "/");
      } else {
        return currentPath.replace(/^\/[a-z]{2}\//, `/${targetLang}/`);
      }
    }

    if (currentLang === "en" && !currentPath.startsWith("/en/")) {
      return targetLang === "en" ? currentPath : `/${targetLang}${currentPath}`;
    }

    let englishPath = currentPath;
    if (currentLang !== "en") {
      englishPath =
        Object.entries(urlMappings.en).find(
          ([key, value]) => value === currentPath
        )?.[0] || currentPath.replace(/^\/[a-z]{2}\//, "/");
    }

    if (targetLang !== "en") {
      return (
        urlMappings[targetLang][englishPath] || `/${targetLang}${englishPath}`
      );
    }

    return englishPath;
  };

  const handleLanguageChange = (lang) => {
    const newPath = getNewPath(lang);
    setLocale(lang);
    setLocalePath(lang === "en" ? "/" : `/${lang}`);
    window.location.href = newPath;
  };

  const getHreflang = (lang) => {
    switch (lang) {
      case "en":
        return "en-US";
      case "es":
        return "es-ES";
      case "pt":
        return "pt-PT";
      case "fr":
        return "fr-FR";
      case "de":
        return "de-DE";
      default:
        return lang;
    }
  };

  const pagesWithoutAlternates = [
    "/es/mls",
    "/es/blog/renovar-casa",
    "/es/blog/como-vender-casa",
  ];

  return (
    <div>
      {["en", "es", "fr", "pt", "de"].map((lang) => {
        const newPath = getNewPath(lang);
        const shouldRenderAlternate =
          !pagesWithoutAlternates.includes(currentPath);

        return (
          <Link
            key={lang}
            to={newPath}
            style={{ padding: "0" }}
            onClick={() => handleLanguageChange(lang)}
            hrefLang={shouldRenderAlternate ? getHreflang(lang) : undefined}
            rel={shouldRenderAlternate ? "alternate" : undefined}
          >
            <div className="product-header-submenu-page-container">
              <div className="product-header-title-subtitle-container">
                <div className="product-header-title">
                  {lang === "en"
                    ? "English"
                    : lang === "es"
                    ? "Español"
                    : lang === "pt"
                    ? "Português"
                    : lang === "de"
                    ? "Deutsch"
                    : "Français"}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default LanguageSwitcher;
