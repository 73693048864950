import React from "react";
import ReleaseAug2024 from "./Images/ReleaseAug2024.png";
import ReleaseJul2024 from "./Images/ReleaseJul2024.png";
import ReleaseJun2024 from "./Images/ReleaseJun2024.png";
import ReleaseMay2024 from "./Images/ReleaseMay2024.png";
import ReleaseSep24 from "./Images/ReleaseSep2024.png";
import ReleaseOct24 from "./Images/ReleaseOct2024.png";
import { Link } from "react-router-dom";
import ReleaseNov24 from "./Images/ReleaseNov2024.png";
import ReleaseDec24 from "./Images/ReleaseDec2024.png";

function Releases() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Releases</h1>
          <h2 className="title-secondary">
            Check Pedra's latest releases here
            <br />
          </h2>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>DECEMBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/floorplans-and-commercial-spaces"
              >
                Pedra: Floor plan renders and commercial spaces
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/floorplans-and-commercial-spaces"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseDec24}
                alt="Pedra releases month of December 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>NOVEMBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/releases/workspaces">
                Pedra: Workspaces for virtual staging collaboration
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link to="/releases/workspaces" style={{ padding: "0px" }}>
              <img
                src={ReleaseNov24}
                alt="Pedra releases month of November 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>OCTOBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/pedra-improvements-pools-and-preservation"
              >
                Pedra: Pool improvements, space preservation, and selective
                renovation features
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/pedra-improvements-pools-and-preservation"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseOct24}
                alt="Pedra releases month of October 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>SEPTEMBER 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/renovate-and-furnish-in-one-click"
              >
                Pedra: Renovate and furnish spaces in just one click, improved
                furnish on 'Auto' results and better space preservation.
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/renovate-and-furnish-in-one-click"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseSep24}
                alt="Pedra releases month of September 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>AUGUST 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/realistic-furnishing"
              >
                Pedra: Realistic virtual staging, renovate with your own style,
                and more
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/realistic-furnishing"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseAug2024}
                alt="Pedra releases month of August 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JULY 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/add-3d-objects-and-make-sky-blue"
              >
                Pedra: Add 3D objects, make sky blue in 1 click, and enhance or
                enlight images automatically
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/add-3d-objects-and-make-sky-blue"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJul2024}
                alt="Pedra releases month of July 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUNE 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/renovate-interiors-with-ai-from-phone"
              >
                Pedra: Renovate interiors from your phone with AI, edit image
                foot notes and edit watermarks
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/renovate-interiors-with-ai-from-phone"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJun2024}
                alt="Pedra releases month of June 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>MAY 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/empty-rooms-and-remove-objects-with-ai"
              >
                Pedra: Clear Rooms and Remove Items, Renovation Styles, and New
                Interface
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/empty-rooms-and-remove-objects-with-ai"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseMay2024}
                alt="Pedra releases month of May 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Releases;
