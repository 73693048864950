import React from "react";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import OscarMelendre from "./Images/Oscar Melendre image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import MaxVivesFierro from "./Images/Max Vives-Fierro image.png";
import JordiVives from "./Images/Jordi Vives image.png";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function TestimonialsPt() {
  return (
    <>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <div className="header-stars">
              ★★★★★
              <div className="header-stars-text"> "A Descoberta do Século"</div>
            </div>
            <h1 className="section-first-title">Depoimentos Pedra</h1>
            <div className="section-first-text">
              <h2 className="section-first-text-bold">
                Inspire-se com os comentários e avaliações dos clientes da
                Pedra.
              </h2>
              <h2 className="section-first-text-gray">
                Avaliações sobre nossos recursos de home staging virtual e
                aprimoramento de fotografia.
              </h2>
            </div>
          </div>
        </section>
      </section>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Director of Photography at aProperties"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Director of Photography at aProperties
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            A Pedra está indo maravilhosamente bem. Acho que o melhor recurso da
            ferramenta é a rapidez com que gera imagens.
          </div>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Real Estate Agent at Huspy"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent at Huspy
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            🌈 🙌🏾 Estou de volta{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              para fazer mágica com a Pedra
            </a>
            … Agora é só esperar!
          </div>
          <div className="testimonial-author-container-date">July 1, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Real Estate Advisor at iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            A Pedra é a descoberta do século
          </div>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, CEO at Actívox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                CEO at Actívox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            A Pedra está sendo muito útil para nós. Estamos muito felizes!!!!
            <div style={{ marginTop: "10px" }}>Deveria ser mais cara.</div>
          </div>
          <div className="testimonial-author-container-date">May 28, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Real Estate Agent at iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent at iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Impressionante.
            <div style={{ marginTop: "10px" }}>
              Além disso, o espaço específico que eu queria limpar parecia mais
              complicado por ser profundo, mas fez isso brilhantemente.
            </div>
          </div>
          <div className="testimonial-author-container-date">June 26, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager at Lucas Fox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager at Lucas Fox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Algumas casas foram vendidas graças à Pedra.
            <div style={{ marginTop: "10px" }}>
              Coloquei fotos de capa de como o terraço poderia ficar mobiliado e
              muitas pessoas começaram a clicar no anúncio
            </div>
          </div>
          <div className="testimonial-author-container-date">June 7, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, CEO at Gilart Consultors"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                CEO at Gilart Consultors
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Parabéns, porque melhorou muito!!!
          </div>
          <div className="testimonial-author-container-date">June 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Real estate agent
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Com a Pedra, consegui{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              vender um imóvel de €500k
            </a>{" "}
            em menos de um dia. O apartamento que eu tinha à venda era um
            apartamento PARA REFORMAR e com a Pedra, consegui renders que
            permitiram visualizar o potencial dos espaços após a reforma, mas
            sem reforma!
          </div>
          <div className="testimonial-author-container-date">June 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MaxVivesFierro}
              alt="Max Vives-Fierro, Real Estate Consultant at Fincas Lluria"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Max Vives-Fierro
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Consultant at Fincas Lluria
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Com a Pedra, conseguimos começar a gerar interesse em um imóvel para
            reformar que não estava recebendo ligações. Criamos renders de como
            o imóvel poderia ficar reformado e começamos a receber visitas!
          </div>
          <div className="testimonial-author-container-date">March 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Sales Department at Inmobiliaria Rico"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Sales Department at Inmobiliaria Rico
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Estou feliz com o aplicativo Pedra.
          </div>
          <div className="testimonial-author-container-date">
            April 24, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={JordiVives}
              alt="Jordi Vives, CEO of Vives Homes"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Jordi Vives
              </div>
              <div className="testimonial-author-container-second-title">
                CEO of Vives Homes
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Vocês pensaram em tudo! A Pedra tem tudo o que precisamos!
          </div>
          <div className="testimonial-author-container-date">
            April 15, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Real Estate Advisor at Monapart"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at Monapart
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Estou muito satisfeita com os resultados
            <div style={{ marginTop: "10px" }}>
              Além disso, eles melhoram o produto com base no feedback que dei!
            </div>
          </div>
          <div className="testimonial-author-container-date">June 4, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Encuentro Real Estate, Boadilla del Monte"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Encuentro Real Estate, Boadilla del Monte
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">É uma boa ferramenta.</div>
          <div className="testimonial-author-container-date">May 6, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={OscarMelendre}
              alt="Oscar Melendre, Marketing at I-VEO"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Oscar Melendre
              </div>
              <div className="testimonial-author-container-second-title">
                Marketing at I-VEO
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            A IA funciona muito bem em comparação com outras ferramentas de IA.
            Interpreta muito bem os espaços!
          </div>
          <div className="testimonial-author-container-date">
            March 11, 2024
          </div>
        </div>
      </div>

      <div
        className="section-first-button-container"
        style={{ marginTop: "100px" }}
      >
        <button className="button-top-of-page" onClick={redirectToPage}>
          Experimente a Pedra <ArrowIcon />
        </button>
      </div>
    </>
  );
}

export default TestimonialsPt;
