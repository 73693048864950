// Home.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import BlueChairIcon from "./Icons/BlueChairIcon";
import BlueEyeIcon from "./Icons/BlueEyeIcon";
import BlueRulerIcon from "./Icons/BlueRulerIcon";
import BlueSmileIcon from "./Icons/BlueSmileIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import FloorPlanHeader from "./Images/FloorPlanHeader.png";
import FloorPlanFeature1 from "./Images/FloorPlanFeature1.png";
import FloorPlanFeature2 from "./Images/FloorPlanFeature2.png";
import FloorPlanFeature3 from "./Images/FloorPlanFeature3.png";
import FloorPlanFeature4 from "./Images/FloorPlanFeature4.png";
import FloorPlanStep1 from "./Images/FloorPlanStep1.png";
import FloorPlanStep2 from "./Images/FloorPlanStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function FloorPlanDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Das einfachste Grundriss-Tool
            </h1>
            <div className="section-first-text">
              Einfach. Leistungsstark. Zeichnen Sie Grundrisse in Sekunden, um
              Ihre Immobilien besser zu positionieren.
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#57A4EB",
                  border: "1px solid #57A4EB",
                }}
              >
                Pedra testen <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Direktor für Fotografie bei aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO bei Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO bei Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  Über 5.000 Immobilienprofis nutzen bereits Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={FloorPlanHeader}
              alt="Tool zum Erstellen von Grundrissen für Immobilien – Software Screenshot"
            ></img>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MEHR ALS 5.000 IMMOBILIENPROFIS VERTRAUEN BEREITS AUF PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Zeichnen Sie einen Grundriss mit Pedra
        </h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueChairIcon />
                <h2 className="feature-title">
                  Möbel zum Grundriss hinzufügen
                </h2>
                <div className="feature-text">
                  Verwenden Sie Möbelschichten, um zu zeigen, was in einen Raum
                  passt und machen Sie es Ihrem Publikum leichter, Grundrisse zu
                  verstehen
                </div>
              </div>
              <img
                src={FloorPlanFeature1}
                alt="Möbel zum Grundriss hinzufügen – Software Screenshot"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueEyeIcon />
                <div className="feature-title">
                  Grundrissmaße mit 1 Klick anzeigen
                </div>
                <div className="feature-text">
                  Entscheiden Sie, ob Sie Maße in Ihren Grundrissen anzeigen
                  oder ausblenden möchten
                </div>
              </div>
              <img
                src={FloorPlanFeature2}
                alt="Grundrissmaße anzeigen – Software Screenshot"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueSmileIcon />
                <h2 className="feature-title">Einfaches Grundriss-Tool</h2>
                <div className="feature-text">
                  Pedras Grundriss-Tool ist das einfachste — einfach per Drag &
                  Drop
                </div>
              </div>
              <img
                src={FloorPlanFeature3}
                alt="Einfaches Grundriss-Tool – Software Screenshot"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueRulerIcon />
                <h2 className="feature-title">
                  Flexibler aber einfacher Grundriss
                </h2>
                <div className="feature-text">
                  Pedras Grundriss ist einfach und dennoch flexibel. Nutzen Sie
                  verschiedene Ebenentypen: Türen, Fenster, Möbel und bearbeiten
                  Sie deren Parameter
                </div>
              </div>
              <img
                src={FloorPlanFeature4}
                alt="Flexibles aber einfaches Grundriss-Tool – Software Screenshot"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="title-container">Wie zeichnet man einen Grundriss?</div>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep1}
            alt="Wie man einen Grundriss zeichnet – Diagramm"
          />
        </div>

        <div className="description">
          <h5>SCHRITT 1</h5>
          <h2>
            Erstellen Sie ein neues Projekt und wählen Sie "Grundriss zeichnen"
          </h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep2}
            alt="Wie man einen Grundriss bearbeitet – Diagramm"
          />
        </div>

        <div className="description">
          <h5>SCHRITT 2</h5>
          <h2>Klicken Sie auf Ebenen, um sie zum Grundriss hinzuzufügen</h2>
          <div className="value-prop-text">
            Um die Größe, Farbe oder Eigenschaften der Ebene zu bearbeiten,
            können Sie sie auswählen
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button
          className="button"
          onClick={redirectToPage}
          style={{ backgroundColor: "#57A4EB", border: "1px solid #57A4EB" }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0077D4";
            e.target.style.borderColor = "#0077D4";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#57A4EB";
            e.target.style.borderColor = "#57A4EB";
          }}
        >
          Pedra testen <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <div className="section-one-column-left">
        <div className="title-container">Fragen & Antworten</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">Was sind Grundrisse?</h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Grundrisse sind grafische Darstellungen, die eine Draufsicht
                eines Gebäudes, Hauses oder einer Struktur zeigen, als würde man
                von oben nach unten schauen. Diese Pläne werden hauptsächlich in
                der Architektur, Innenarchitektur, im Bauingenieurwesen und
                verwandten Bereichen verwendet, um die Anordnung und Aufteilung
                von Räumen innerhalb einer Struktur zu visualisieren.
                <br />
                <br />
                Grundrisse enthalten typischerweise Details wie Raummaße, die
                Position von Wänden, Türen, Fenstern, Möbeln und andere wichtige
                Elemente für die Planung und den Bau eines Gebäudes. Sie sind
                essentiell für die Kommunikation zwischen Designern,
                Architekten, Ingenieuren und Bauunternehmern, die an einem
                Bauprojekt beteiligt sind.
                <br />
                <br />
                Stellen Sie sich vor, Sie schauen von oben auf ein Gebäude
                herab. Grundrisse erfassen diese Perspektive und zeigen die
                Verteilung der Innenräume, Zimmer, Wände, Türen, Fenster und
                anderer struktureller Elemente. Im Wesentlichen bieten diese
                Pläne eine Draufsicht eines Gebäudes und ermöglichen ein klares
                Verständnis davon, wie Räume organisiert und genutzt werden.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Welche Arten von Grundrissen gibt es?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Es gibt verschiedene Arten von Grundrissen, jeder mit seinem
                  eigenen Zweck und Fokus:
                </div>
                <h4>Erdgeschoss-Grundrisse:</h4>Sie zeigen die Raumaufteilung im
                Erdgeschoss. Dies umfasst Bereiche wie Eingänge, Wohnzimmer,
                Küchen, Schlafzimmer und Garagen.
                <br />
                <br />
                <h4>Obergeschoss-Grundrisse:</h4>Sie stellen die oberen Etagen
                eines Gebäudes dar, wie das zweite Stockwerk, den Dachboden oder
                weitere Ebenen. Diese Pläne sind wichtig für das Verständnis der
                vertikalen Struktur eines Gebäudes.
                <br />
                <br />
                <h4>Möblierte Grundrisse:</h4>Sie beinhalten die Platzierung von
                Möbeln innerhalb der Räume. Diese Pläne helfen Innenarchitekten
                und Hausbesitzern, sich vorzustellen, wie der Raum möbliert
                aussehen wird.
                <br />
                <br />
                <h4>Elektro- und Sanitärpläne:</h4>Sie zeigen die Position von
                Elektro- und Sanitäranschlüssen im Gebäude. Sie sind wichtig für
                die korrekte Installation von Elektro- und Sanitärsystemen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Wie erstellt man Grundrisse?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Neues Projekt erstellen:</h4>Beginnen Sie mit der Erstellung
                eines Grundrisses im Bereich 'Grundriss zeichnen'.
                <br />
                <br />
                <h4>Ebenen hinzufügen:</h4>Fügen Sie alle benötigten Ebenen
                hinzu und bearbeiten Sie die Abmessungen, Farbe oder
                Eigenschaften der Ebene.
                <br />
                <br />
                <h4>Möblierung:</h4>Fügen Sie Möbel zum Grundriss hinzu, um den
                verfügbaren Platz und mögliche Einrichtungsoptionen visuell
                darzustellen.
                <br />
                <br />
                <h4>Grundriss online herunterladen:</h4>Vervollständigen Sie die
                letzten Details wie Türen, Fenster etc. und laden Sie Ihren
                Grundriss herunter.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Was kostet Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra kostet 29€ plus Mehrwertsteuer pro Monat.
                <br />
                <br />
                Es ist ein monatliches Abonnement, Sie können also kündigen,
                wenn Sie es nicht mehr benötigen.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloorPlanDe;
