import React from "react";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h2 className="article-subtitleh2">Hello,</h2>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                I am Felix Ingla, the founder of Pedra.
              </div>
              <div style={{ marginTop: "10px" }}>
                In the spring of 2023, I started{" "}
                <Link className="article-link" to="">
                  Pedra
                </Link>
                , an online tool to transform your real estate properties
                easily.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Pedra is born from{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>a reality</span> of
                the real estate sector.
              </h2>
              <div style={{ marginTop: "10px" }}>
                To sell a property on the market – and to sell it quickly – real
                estate agencies need good photographies, clear the apartments of
                clutter, place nice furniture in the rooms, and even show
                renovation proposals.
              </div>
              <div style={{ marginTop: "10px" }}>
                All this requires an investment of time and money. But we don't
                always have that time and money.
              </div>
              <div style={{ marginTop: "10px" }}>
                That's why Pedra was created: an easy, fast, and economical way
                to improve how we present a property to the market, so that we
                can sell it faster.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Our customers are{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>our priority</span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                At{" "}
                <Link className="article-link" to="">
                  Pedra
                </Link>
                , we want to ensure the best experience when you use our
                product. If at any time you have feedback, or need any help,
                please write to me at felix@pedra.so.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                A tool{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  powerful yet simple
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                With Pedra, we provide real estate agencies{" "}
                <Link className="article-link" to="/real-estate-photography">
                  access to artificial intelligence to improve your real estate
                  photography
                </Link>
                ,{" "}
                <Link className="article-link" to="/render">
                  generate renovation proposals
                </Link>
                , clear rooms and more. And above all, offer it in an easy to
                use online product – we want you to save on costs as well as
                time.
              </div>
              <br />
              <br />
              We are proud to present Pedra to you.
              <br />
              And we hope you are excited to use it.
              <br />
              <br />
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "50%",
                    height: "64px",
                    width: "auto",
                  }}
                  src={FelixIngla}
                  alt="Felix Ingla"
                />
                <div
                  style={{
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "100%",
                  }}
                >
                  <div className="article-text" color="#000 !important">
                    Felix Ingla
                  </div>
                  <div className="article-text" style={{ color: "#878787" }}>
                    Founder at Pedra
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
