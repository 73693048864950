import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeHouseStaging1 from "../Images/curtain-effect/BeforeHouseStaging1.png";
import AfterHouseStaging1 from "../Images/curtain-effect/AfterHouseStaging1.png";
import BeforeHouseStaging2 from "../Images/curtain-effect/BeforeHouseStaging2.png";
import AfterHouseStaging2 from "../Images/curtain-effect/AfterHouseStaging2.png";
import BeforeHouseStaging3 from "../Images/curtain-effect/BeforeHouseStaging3.png";
import AfterHouseStaging3 from "../Images/curtain-effect/AfterHouseStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderHouseDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie Haus-Außenansicht-Renderings
          </h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Haus-Außenansicht-Renderings
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Warum sollten Sie Pedras Rendering-Tools für Hausaußenansichten
              nutzen?
            </h2>
            <p className="article-text">
              Mit Pedras Rendering-Tools können Sie in wenigen Sekunden eine
              komplette Transformation der Hausaußenansicht visualisieren und
              potenziellen Käufern das wahre Potenzial der Immobilie zeigen.
              <p style={{ marginTop: "10px" }}>
                Außenrenovierungen sind oft der wirkungsvollste Teil von
                Hausverbesserungen, da sie den ersten Eindruck jeder Immobilie
                prägen. Die Möglichkeit, das Potenzial einer veralteten
                Außenansicht aufzuzeigen, kann beim Verkauf von gebrauchten
                Immobilien einen großen Unterschied machen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit Pedra benötigen Sie keine spezialisierten Architekten oder
                Rendering-Experten mehr, um überzeugende
                Außenrenovierungsvorschläge zu erstellen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich ein Haus-Außenansicht-Rendering?
            </h2>
            <p className="article-text">
              Laden Sie zunächst klare Fotos der bestehenden Hausaußenansicht
              hoch, die Sie transformieren möchten.
              <p style={{ marginTop: "10px" }}>
                Wählen Sie Ihre Außenansichtsbilder aus und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                dann wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>"Renovierung"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Anleitung zur Verwendung des Renovierungs-Tools für Haus-Außenansicht-Renderings – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Schnelle Außenrenovierung:{" "}
              </span>
              Erstellen Sie ein schnelles Haus-Außenansicht-Rendering
            </h2>
            <p style={{ marginTop: "10px" }}>
              Das <span style={{ fontWeight: "500" }}>"Renovierungs"</span>-Tool
              transformiert Ihre Hausaußenansicht in etwa 25 Sekunden.
              <p>
                Für eine subtile Aktualisierung wählen Sie den
                "Minimalistischen" Stil mit "Niedriger" Kreativität. Dies behält
                die grundlegende Struktur bei, während Oberflächen und
                Fassadenelemente modernisiert werden.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Anleitung zur Verwendung des Renovierungs-Tools für Haus-Außenansicht-Renderings – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Passen Sie Ihr Haus-Außenansicht-Renovierungs-Rendering an
            </h2>
            <p className="article-text">
              Pedra bietet umfangreiche Anpassungsoptionen für
              Außenansicht-Renderings.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Wählen Sie aus verschiedenen Architekturstilen, steuern Sie,
                  wie dramatisch die Änderungen sein sollen, und entscheiden
                  Sie, ob bestehende Elemente wie Fenster oder markante Merkmale
                  erhalten bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Anleitung zur Verwendung des Renovierungs-Tools für Haus-Außenansicht-Renderings – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Wählen Sie Ihren Architekturstil
            </h3>
            <p className="article-text">
              Wählen Sie aus 9 verschiedenen Architekturstilen, darunter modern,
              traditionell, mediterran und mehr.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Referenzbilder von Hausstilen hochladen, die
                  Ihnen gefallen.
                </span>{" "}
                Dies ermöglicht es Ihnen, Renderings zu erstellen, die
                bestimmten Design-Inspirationen oder dem bevorzugten Stil Ihres
                Kunden entsprechen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Legen Sie die Renovierungsintensität fest
            </h3>
            <p className="article-text">
              Wählen Sie zwischen niedriger, mittlerer, hoher und sehr hoher
              Kreativität für Ihre Außentransformation.
              <p style={{ marginTop: "10px" }}>
                Niedrige und mittlere Einstellungen aktualisieren Oberflächen
                und Fassadenelemente, während die grundlegende Struktur erhalten
                bleibt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hohe und sehr hohe Einstellungen können die Außenansicht mit
                neuen architektonischen Elementen, Dachlinien und strukturellen
                Änderungen komplett neu gestalten.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Bewahren Sie wichtige Außenelemente
            </h3>
            <p className="article-text">
              Wählen Sie die Option, Fenster zu erhalten, was besonders wichtig
              ist für Häuser mit markanten Fensterelementen oder spezifischen
              Beleuchtungsanforderungen. Dies stellt sicher, dass Ihr Rendering
              praktische Elemente beibehält, während die Ästhetik aktualisiert
              wird.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Generieren Sie Ihr Außenansicht-Rendering
            </h2>
            <p>
              Nachdem Sie Ihre Präferenzen ausgewählt haben, generieren Sie das
              Außenansicht-Rendering. Standardrenderings dauern etwa 15
              Sekunden, während das Erhalten spezifischer Elemente etwas länger
              dauern kann.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 5 – </span>
              Bewahren Sie wichtige Außenelemente
            </h3>
            <p className="article-text">
              Wählen Sie, welche spezifischen Teile des Bildes Sie nicht
              modifizieren möchten.
              <p style={{ marginTop: "10px" }}>
                Nach Auswahl der Option "Teile bewahren" können Sie direkt auf
                dem Bild zeichnen, um Bereiche zu markieren, die unverändert
                bleiben sollen. Dies ist besonders nützlich für:
                <ul>
                  <li>Nachbargebäude, die intakt bleiben sollen</li>
                  <li>Die Straße oder umgebende Landschaft</li>
                  <li>Markante architektonische Merkmale</li>
                  <li>
                    Fenster oder andere Elemente, die Sie beibehalten möchten
                  </li>
                </ul>
              </p>
              <p style={{ marginTop: "10px" }}>
                Zeichnen Sie einfach über die Bereiche, die Sie bewahren
                möchten, bevor Sie auf Generieren klicken. Dies stellt sicher,
                dass Ihr Rendering sich nur auf die beabsichtigten Teile der
                Außenansicht konzentriert, während der Kontext und die Umgebung
                erhalten bleiben.
              </p>
              <br />
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps für bessere Haus-Außenansicht-Renderings
                </h4>
                <p className="callout-p">
                  Um die realistischsten und nützlichsten
                  Außenansicht-Renderings zu erhalten:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generieren Sie mehrere Versionen
                      </span>
                      . Jedes Rendering ist einzigartig, und Sie könnten
                      feststellen, dass einige wichtige Merkmale wie
                      architektonische Details oder markante Elemente besser
                      erhalten bleiben.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Verwenden Sie niedrige Kreativität für subtile
                      Aktualisierungen veralteter Außenansichten.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Machen Sie Fotos an einem bewölkten Tag, um harte
                        Schatten zu minimieren, und erfassen Sie die gesamte
                        Fassade
                      </span>
                      . Dies hilft der KI, die Struktur besser zu verstehen und
                      genauere Transformationen zu erstellen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Beispiele für Haus-Außenansicht-Renderings
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für Außenrenovierungen, die mit
              unseren Renderings erstellt wurden:
            </p>
            <CurtainEffect
              beforeImage={BeforeHouseStaging1}
              afterImage={AfterHouseStaging1}
              altBefore={"Hausaußenansicht vor der Renovierung"}
              altAfter={"Hausaußenansicht nach der Renovierung"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging2}
              afterImage={AfterHouseStaging2}
              altBefore={"Hausaußenansicht vor der Renovierung"}
              altAfter={"Hausaußenansicht nach der Renovierung"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging3}
              afterImage={AfterHouseStaging3}
              altBefore={"Hausaußenansicht vor der Renovierung"}
              altAfter={"Hausaußenansicht nach der Renovierung"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderHouseDe;
