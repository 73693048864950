import React from "react";

function ApiDocsPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Documentação da API Pedra.so</h1>
          <h2 className="title-secondary gray">
            API para criar imagens de home staging virtual
          </h2>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <p>
              Bem-vindo à documentação oficial da API Pedra.so. Nossa API
              fornece recursos poderosos de processamento de imagem para várias
              tarefas de aprimoramento e design de ambientes.
            </p>
            <br />
            <h2 className="article-subtitleh2">URLs Base</h2>
            <p>A API pode ser acessada através da seguinte URL base:</p>
            <ul>
              <li>
                Produção: <code>https://app.pedra.so/api</code>
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Autenticação</h2>
            <p>
              Todas as chamadas da API requerem um parâmetro <code>apiKey</code>{" "}
              para autenticação. Envie um e-mail para felix@pedra.so para
              solicitar sua chave de API.
            </p>
            <br />
            <h2 className="article-subtitleh2">Parâmetros Comuns</h2>
            <p>Os seguintes parâmetros são comuns a todos os endpoints:</p>
            <ul>
              <li>
                <code>apiKey</code> (string, obrigatório): Sua chave de API
                única para autenticação.
              </li>
              <li>
                <code>imageUrl</code> (string, obrigatório): URL da imagem a ser
                processada.
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">Endpoints</h2>

            <h3 className="article-subtitle">Executar Aprimoramento</h3>
            <p>
              Aprimora a qualidade, cor, luz e resolução da imagem fornecida.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/enhance</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parâmetros:</strong> Parâmetros comuns (ver acima)
            </p>
            <p>
              <strong>Tempo de resposta esperado:</strong> Entre 25 segundos e 1
              minuto
            </p>

            <br />
            <h3 className="article-subtitle">Executar Ambiente Vazio</h3>
            <p>Remove móveis e objetos do ambiente na imagem fornecida.</p>
            <p>
              <strong>Endpoint:</strong> <code>/empty_room</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parâmetros:</strong> Parâmetros comuns (ver acima)
            </p>
            <p>
              <strong>Tempo de resposta esperado:</strong> Entre 2 e 3 minutos
            </p>
            <br />
            <h3 className="article-subtitle">Executar Mobiliamento</h3>
            <p>
              Mobilia (cria um home staging virtual) de um espaço de acordo com
              os parâmetros especificados.
            </p>
            <p>
              <strong>Endpoint:</strong> <code>/furnish</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parâmetros:</strong>
            </p>
            <ul>
              <li>Parâmetros comuns (ver acima)</li>
              <li>
                <code>roomType</code> (string, obrigatório): Tipo de ambiente a
                ser mobiliado.
              </li>
              <li>
                <code>style</code> (string, obrigatório): Estilo de design a ser
                aplicado.
              </li>
            </ul>
            <p>
              <strong>Tempo de resposta esperado:</strong> Entre 2 e 3 minutos
            </p>

            <h4>
              Valores possíveis para <code>roomType</code>:
            </h4>
            <ul>
              <li>"Quarto"</li>
              <li>"Sala de estar"</li>
              <li>"Cozinha"</li>
              <li>"Escritório"</li>
              <li>"Terraço"</li>
              <li>"Sala de jantar"</li>
              <li>"Cozinha + Sala de estar"</li>
              <li>"Sala de jantar + Sala de estar"</li>
              <li>"Entrada"</li>
            </ul>

            <h4>
              Valores possíveis para <code>style</code>:
            </h4>
            <ul>
              <li>"Tradicional"</li>
              <li>"Minimalista"</li>
              <li>"Escandinavo"</li>
              <li>"Meio do século"</li>
              <li>"Boêmio"</li>
              <li>"Industrial"</li>
              <li>"Mediterrâneo"</li>
              <li>"Moderno"</li>
              <li>"Pirineus"</li>
            </ul>
            <br />
            <h3 className="article-subtitle">Executar Renovação</h3>
            <p>Renova um ambiente de acordo com o estilo especificado.</p>
            <p>
              <strong>Endpoint:</strong> <code>/renovation</code>
            </p>
            <p>
              <strong>Método:</strong> POST
            </p>
            <p>
              <strong>Parâmetros:</strong>
            </p>
            <ul>
              <li>Parâmetros comuns (ver acima)</li>
              <li>
                <code>style</code> (string, obrigatório): Estilo de design a ser
                aplicado para renovação.
              </li>
              <li>
                <code>preserveWindows</code> (boolean, obrigatório): Se deve
                preservar as janelas existentes durante a renovação.
              </li>
            </ul>
            <p>
              <strong>Tempo de resposta esperado:</strong> Entre 25 segundos e 2
              minutos
            </p>
            <br />
            <h2 className="article-subtitleh2">Formato de Resposta</h2>
            <p>
              Todos os endpoints da API retornam respostas em JSON. Uma resposta
              bem-sucedida terá um código de status 200 e incluirá as
              informações da imagem processada.
            </p>
            <br />
            <h2 className="article-subtitleh2">Tratamento de Erros</h2>
            <p>
              Em caso de erro, a API retornará um código de status HTTP
              apropriado junto com uma resposta JSON contendo detalhes do erro.
            </p>
            <br />
            <h2 className="article-subtitleh2">Suporte</h2>
            <p>
              Para quaisquer dúvidas ou problemas relacionados à API, entre em
              contato com nossa equipe de suporte em felix@pedra.so.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApiDocsPt;
