import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageBathroomFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingKitchenPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Criar Home Staging Virtual para Cozinhas
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como criar home staging virtual para cozinhas
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-position">Fundador da Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Como posso criar home staging virtual para uma cozinha?
            </h2>
            <p className="article-text">
              Com a Pedra, você pode criar home staging virtual para cozinhas em
              apenas alguns cliques.
              <p>
                Para começar, acesse o menu{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                selecione <span style={{ fontWeight: "500" }}>"Mobiliar"</span>.
                Escolha "Cozinha" como tipo de ambiente e em cerca de 2 ou 3
                minutos, você terá sua imagem de home staging virtual da cozinha
                pronta para baixar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Como criar home staging virtual para uma cozinha – Exemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              Como criar home staging virtual para cozinhas?
            </h2>
            <p className="article-text">
              Para conseguir seu{" "}
              <Link className="article-link" to="/home-staging-kitchen">
                home staging virtual para uma cozinha
              </Link>
              , siga estes passos simples:
            </p>
            <ol>
              <li>
                Faça upload da imagem da cozinha que você deseja mobiliar
                virtualmente
              </li>
              <li>Selecione a imagem para começar a editar</li>
              <li>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                depois clique em{" "}
                <span style={{ fontWeight: "500" }}>"Mobiliar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu para criar home staging virtual para uma cozinha"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Mobiliar"</span>{" "}
              você precisará seguir estes passos:
            </p>
            <ol>
              <li>
                Selecione a{" "}
                <span style={{ fontWeight: "500" }}>
                  posição dos elementos de staging
                </span>
                . Para cozinhas, recomendamos usar "Desenhar" para indicar áreas
                específicas onde colocar eletrodomésticos, utensílios e
                elementos decorativos.
              </li>
              <li>
                Selecione <span style={{ fontWeight: "500" }}>"Cozinha"</span>{" "}
                como tipo de ambiente. Isso garantirá que a Pedra coloque itens
                apropriados de cozinha como eletrodomésticos, utensílios e
                elementos decorativos.
              </li>
              <li>
                Selecione o <span style={{ fontWeight: "500" }}>estilo</span>.
                Para cozinhas, estilos como "Moderno", "Contemporâneo" ou
                "Industrial" geralmente funcionam bem.
              </li>
              <li>
                Escolha o{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imagens a gerar
                </span>
                . Recomendamos escolher 2 ou 3 imagens para ter diferentes
                opções de design de cozinha.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Dicas para melhor home staging virtual de uma cozinha:
                <ul>
                  <li>
                    Certifique-se de que a fotografia mostre todo o layout da
                    cozinha claramente para uma mobília mais precisa
                  </li>
                  <li>
                    Capture quaisquer características únicas como bancadas,
                    armários ou detalhes arquitetônicos
                  </li>
                  <li>
                    Use a opção "Desenhar" para indicar áreas específicas para
                    eletrodomésticos, utensílios ou elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemplo de como criar home staging virtual para uma cozinha"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Por que fazer home staging virtual para uma cozinha?
            </h2>
            <p className="article-text">
              A cozinha é um dos espaços mais importantes em uma casa e pode ser
              um grande diferencial na venda. O home staging virtual para
              cozinhas permite que você mostre todo o potencial do espaço,
              criando ambientes funcionais e acolhedores que ajudam os
              compradores a se imaginarem cozinhando e convivendo no espaço.
            </p>
            <p className="article-text">
              Com um bom home staging virtual para uma cozinha, você pode:
            </p>
            <ul>
              <li>
                Transformar cozinhas vazias ou desatualizadas em espaços
                modernos e funcionais
              </li>
              <li>Destacar a funcionalidade e o layout do ambiente</li>
              <li>
                Mostrar soluções de armazenamento e potencial de organização do
                espaço
              </li>
              <li>
                Ajudar os compradores a imaginar um espaço de convivência e
                preparação de refeições
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemplos de home staging virtual para cozinhas
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de home staging virtual que podem ser
              aplicados a cozinhas:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingKitchenPt;
