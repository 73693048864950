import React from "react";
import Article4Frame from "../Images/Article4Frame.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle4Pt() {
  return (
    <>
      <div className="section-one-column-left">
        <article
          className="title-container article"
          style={{ marginTop: "100px" }}
        >
          <h1 className="article-titleh1">
            Como a IA Pode Ajudar Corretores Imobiliários a Vender?
          </h1>
          <div className="title-secondary gray">
            Uma explicação sobre o que é IA e como ela pode ajudar na venda de
            imóveis
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
              }}
              src={Article4Frame}
              alt="Capa do artigo sobre como a IA pode ajudar corretores imobiliários a vender"
            />
          </div>
        </article>
      </div>

      <div className="section-one-column-left" style={{ marginTop: "0px" }}>
        <section className="step-container article">
          <div>
            <div className="article-text">
              <div className="article-text">
                Neste texto, quero compartilhar sobre IA e tentar desmistificar
                o que ela é, e como ela pode realmente ajudar no dia a dia de um
                corretor imobiliário.
              </div>
              <br />
              <div className="article-text">
                Este artigo girará em torno da seguinte pergunta:
              </div>
              <br />
              <div className="article-text" style={{ fontWeight: "600" }}>
                Como podemos vender mais com IA?
              </div>
              <br />
              <div className="article-text">
                Mas primeiro, vamos falar sobre o que é IA:
              </div>
              <br />
              <h2 className="article-titleh2">O que é IA</h2>
              <br />
              <div className="article-text">
                Um programa que aprendeu a realizar tarefas semelhantes a um
                humano. Tarefas como escrever, falar, ter conversas, pensar,
                imaginar, etc.
              </div>
              <br />
              <h2 className="article-titleh2">Como podemos criar uma IA?</h2>
              <div className="article-text">
                O primeiro passo é treinar um modelo com milhões de pontos de
                dados.
              </div>
              <br />
              <div className="article-text">
                O segundo passo é colocar em prática esse modelo de IA que já
                treinamos. É como quando o Rocky treinou muito - foi tudo para
                aquele combate final.
              </div>
              <br />
              <h2 className="article-titleh2">
                Que modelos poderíamos criar para ajudar corretores
                imobiliários?
              </h2>
              <br />
              <div className="article-text">
                Por exemplo, poderíamos treinar uma IA para identificar quais
                são as imagens que geram mais cliques em um anúncio, como por
                exemplo no{" "}
                <a
                  className="article-link"
                  href="https://www.idealista.com"
                  rel="nofollow"
                >
                  Idealista
                </a>
                .
              </div>
              <br />
              <div className="article-text">
                Basicamente, precisaríamos começar a treinar um modelo, e passar
                para ele milhões de imagens e dizer ao modelo quais foram
                colocadas como capa do anúncio. Uma vez que o modelo tenha sido
                treinado com esses milhões de pontos de dados, teríamos nosso
                modelo inteligente e pequeno para nos ajudar a escolher a melhor
                imagem para nossa capa. Poderíamos compartilhar as 20 imagens de
                uma de nossas propriedades, e ele nos diria qual seria a que
                poderia nos ajudar a vender mais.
              </div>
              <br />
              <div className="article-text">
                Mas obter um pequeno robô que nos dá essa ajuda não é barato,
                nem fácil, nem rápido, porque:
              </div>
              <br />
              <li className="article-text">
                Precisamos treinar com milhões de pontos de dados
              </li>
              <li className="article-text">
                Precisaríamos de recursos de engenharia caros, como
                profissionais e computadores
              </li>
              <li className="article-text">Precisaríamos de muito tempo</li>
              <br />
              Então não vou entrar em como criar modelos de IA, embora esse
              seria um tópico muito interessante. Mas com certeza, não é um
              tópico que você precisa conhecer para aproveitar ao máximo a IA
              facilmente. Neste artigo, vou falar sobre modelos que já existem e
              que podem ajudar você a vender mais.
              <br />
              <h2 className="article-titleh2">
                Existem modelos de IA que podem vender uma casa por mim?
              </h2>
              <div className="article-text">
                Não.
                <br />
                <br />
                Não existem modelos de IA que possam substituir o corretor
                imobiliário ou que vão vender por você. Porque a IA se concentra
                em atividades repetitivas e não em atividades humanas e de alta
                complexidade. O corretor imobiliário requer relações
                interpessoais e um grau de complexidade que a tecnologia de hoje
                não pode assumir.
              </div>
              <br />
              <h2 className="article-titleh2">
                Então o que a IA pode fazer pelo corretor imobiliário?
              </h2>
              <div className="article-text">
                A IA pode tirar de você o trabalho repetitivo. E assim você terá
                mais tempo para se concentrar em vender e captar.
              </div>
              <br />
              <div className="article-text">
                Então, como a IA pode nos ajudar?
              </div>
              <br />
              <h2 className="article-titleh2">
                Criar imagens que nos ajudem a vender.
              </h2>
              <div className="article-text">
                Imagens são uma das tarefas mais repetitivas que os corretores
                imobiliários precisam lidar. Elas estão virtualmente presentes
                em todas as transações imobiliárias.
              </div>
              <br />
              <div className="article-text">
                Dentro do mundo da imagem imobiliária, existem tarefas muito
                repetitivas:
              </div>
              <br />
              <div className="article-text">
                Um exemplo claro é quando temos a casa "da vovó" à venda. Nestas
                situações, pode ser muito difícil, porque mesmo que o imóvel
                seja bom, os móveis e a decoração podem prejudicar muito o
                processo de venda.
              </div>
              <br />
              <div className="article-text">A IA resolve isso.</div>
              <br />
              <div className="article-text">
                Em 1 clique podemos{" "}
                <Link className="article-link" to="/real-estate-photography">
                  esvaziar ambientes com IA
                </Link>{" "}
                e fazer com que esses ambientes fiquem melhores
              </div>
              <br />
              <div className="article-text">
                Outra tarefa repetitiva dentro do mundo imobiliário é conseguir
                renders mostrando propostas de reforma de apartamentos muito
                antigos. Se um imóvel realmente precisa de uma reforma, seria
                melhor se pudéssemos mostrar diretamente como ele ficaria
                reformado. E é por isso que os corretores pedem renders para
                arquitetos!
              </div>
              <br />
              <div className="article-text">
                O problema é que os renders são caros e precisam de alguns dias
                ou semanas para serem feitos por um profissional.
              </div>
              <br />
              <div className="article-text">
                A IA resolve esses problemas. Com IA, você pode{" "}
                <Link className="article-link" to="/render">
                  gerar propostas de reforma em segundos
                </Link>
                , quando quiser, onde quiser, quando precisar.
              </div>
              <br />
              <div className="article-text">
                A IA também ajudará a preencher espaços com vida, e garantir que
                pareça um lugar que alguém possa chamar de lar. Quando os
                imóveis estão vazios, os visitantes têm dificuldade para
                entender como fica. Os compradores ficam confusos e têm
                dificuldade para entender como poderia realmente ficar.
              </div>
              <br />
              <div className="article-text">
                O home staging físico real tornou-se muito popular nos últimos
                anos. É uma ótima solução, mas pode ser cara, lenta e complexa
                de implementar
              </div>
              <br />
              <div className="article-text">
                Com IA, você não pode replicar o efeito de um home staging
                físico, mas pode replicar o mesmo efeito de quando aquele imóvel
                é visto online. E isso é importante, porque a maioria dos
                processos de venda começa online. As pessoas vão online, e se
                virem algumas fotos que gostam, podem ficar mais interessadas em
                marcar uma visita.
              </div>
              <br />
              <div className="article-text">
                And our schedule will also appreciate AI. We know how key is the
                weather when we want to make a photoshoot of a property. We all
                want to{" "}
                <Link className="article-link" to="/real-estate-photography">
                  get the best real estate photographies
                </Link>{" "}
                possible. But that is not always easy. Coordinating with the
                photographer, with the weather, with the owner of the house,
                etc. it's a mess! AI puts a remedy to these things. With a
                stroke of a key in your computer, you can clear those skies of
                clouds, and ensure that the grass looks as green as ever.
              </div>
              <br />
              <h2 className="article-titleh2">
                In conclusion, AI gives you superpowers
              </h2>
              <br />
              <div className="article-text">
                It will help you sell faster. You can create images that act as
                lead magnets in real estate marketplaces and social media.
              </div>
              <br />
              <div className="article-text">
                You will augment the quality, speed and flexibility of your
                services. In fron of the customer and the seller, you can offer
                more experiences and help in their operations and needs.{" "}
                <Link className="article-link" to="/render">
                  Does the customer want to see the property after a renovation?
                </Link>{" "}
                Do you want to improve the images as fast possible? Do you want
                to publish faster?
              </div>
              <br />
              <div className="article-text">
                Automate and reduce costs. What before took lots of money and
                time, you now can do easily with a computer or phone. Thanks AI.
              </div>
              <br />
              <div className="article-text" style={{ color: "#878787" }}>
                This content was originally presented on a live event from{" "}
                <a className="article-link" href="https://www.iadespana.es">
                  IAD España
                </a>{" "}
                on the 18th of June 2024
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hello! I'm Felix Ingla, the founder of{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , an innovative virtual home staging and real estate photography
              web application that is transforming how properties are presented
              in the digital world. My dedication to creating cutting-edge
              technology and intuitive solution has established Pedra as a
              trusted tool for real estate professionals seeking to enhance
              their property listings.
            </p>
            <p>
              If you are eager to connect and interested in discussing real
              estate technology, please reach out via my{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . You can also read more{" "}
              <Link className="article-link" to="/about">
                about Pedra here
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle4Pt;
