import React from "react";
import HowToDrawFloorPlan1 from "../Images/HowToDrawFloorPlan1.png";
import HowToDrawFloorPlan2 from "../Images/HowToDrawFloorPlan2.png";
import InfoIcon from "../Icons/InfoIcon";

function HowToDrawFloorPlanDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">So erstellen Sie Grundrisse</h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Grundrissen ohne Architekturkenntnisse
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Was können Sie mit unserem Grundriss-Tool machen?
            </h2>
            <p className="article-text">
              Mit unserem Grundriss-Tool können Sie in wenigen Minuten
              professionelle Grundrisse erstellen, ohne architektonischen
              Hintergrund. Ob Sie Immobilienmakler, Innenarchitekt oder
              Immobilienbesitzer sind, Sie können ganz einfach präzise und
              optisch ansprechende Grundrisse erstellen.
              <p style={{ marginTop: "10px" }}>
                Grundrisse sind besonders nützlich für Immobilienanzeigen, da
                sie potenziellen Käufern helfen, den Aufbau und den Fluss des
                Raums vor einem Besuch zu verstehen.
              </p>
            </p>

            <h2 className="article-subtitleh2">
              So erstellen Sie einen Grundriss
            </h2>
            <p className="article-text">
              Beginnen Sie mit der Erstellung eines neuen Projekts in unserem
              Grundriss-Tool.
              <p style={{ marginTop: "10px" }}>
                Klicken Sie auf die{" "}
                <span style={{ fontWeight: "500" }}>"Neues Projekt"</span>{" "}
                Schaltfläche, um mit Ihrem Grundriss zu beginnen.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToDrawFloorPlan2}
                alt="So nutzen Sie das Grundriss-Tool – Screenshot der Pedra-Software"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Grundelemente: </span>
              Erstellen Sie Ihren Grundriss in Sekunden
            </h2>
            <p style={{ marginTop: "10px" }}>
              Die obere Werkzeugleiste enthält alle grundlegenden Elemente, die
              Sie benötigen: Räume, Türen, Fenster und Möbel. Klicken Sie
              einfach auf ein Element, um es Ihrer Leinwand hinzuzufügen.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToDrawFloorPlan1}
                alt="So nutzen Sie das Grundriss-Tool – Screenshot der Pedra-Software"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Anpassungsoptionen: </span>
              Modifizieren Sie Ihre Grundrisselemente
            </h2>
            <p className="article-text">
              Nach dem Platzieren können Sie die Elemente einfach nach Ihren
              Bedürfnissen anpassen.
              <p style={{ marginTop: "10px" }}>
                Klicken Sie auf ein Element, um auf dessen Eigenschaften
                zuzugreifen:
                <p style={{ marginTop: "10px" }}>
                  Bei Räumen können Sie Größe, Wandsichtbarkeit, Farbe und die
                  Anzeige des Raumnamens anpassen. Bei Möbeln, Türen und
                  Fenstern können Sie diese nach Bedarf drehen, in der Größe
                  ändern und neu positionieren.
                </p>
                <p style={{ marginTop: "10px" }}>
                  Um einen Raum umzubenennen, doppelklicken Sie einfach auf
                  seinen Namen und geben Sie den neuen ein.
                </p>
              </p>
            </p>

            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Tipps für bessere Grundrisse</h4>
                <p className="callout-p">
                  Für beste Ergebnisse mit Ihren Grundrissen:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Beginnen Sie mit den Räumen
                      </span>
                      . Erstellen Sie die Hauptstruktur, bevor Sie Innenwände
                      und Möbel hinzufügen.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Verwenden Sie Standardmaße
                      </span>
                      . Dies stellt sicher, dass Ihr Grundriss realistisch und
                      maßstabsgetreu ist.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Fügen Sie Möbel für eine bessere Visualisierung hinzu
                      </span>
                      . Dies hilft Betrachtern, den Raum besser zu verstehen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>

            <h2 className="article-subtitleh2">
              Video-Tutorial: Einen Grundriss erstellen
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/Urt4OaV--Wg?si=QJicp9L_1wh6HrjK"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <h2 className="article-subtitleh2">Tastaturkürzel</h2>
            <p className="article-text">
              Für effizienteres Arbeiten nutzen Sie diese Tastaturkürzel:
              <ul style={{ marginTop: "10px", marginLeft: "20px" }}>
                <li>Elemente drehen: Strg/Cmd + R</li>
                <li>Element löschen: Entf oder Rücktaste</li>
                <li>Element kopieren: Strg/Cmd + C</li>
                <li>Element einfügen: Strg/Cmd + V</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToDrawFloorPlanDe;
