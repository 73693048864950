import React from "react";

const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M20.9996 10.7302C20.9996 10.0504 20.9434 9.36699 20.8234 8.69824H11.2002V12.5491H16.711C16.4823 13.791 15.7475 14.8897 14.6716 15.5878V18.0865H17.9593C19.89 16.3448 20.9996 13.7727 20.9996 10.7302Z"
        fill="#4285F4"
      ></path>
      <path
        d="M11.2002 20.5008C13.9518 20.5008 16.2723 19.6152 17.963 18.0867L14.6753 15.588C13.7606 16.198 12.5797 16.5434 11.2039 16.5434C8.54225 16.5434 6.28545 14.7833 5.47571 12.417H2.08302V14.9928C3.81498 18.3696 7.34262 20.5008 11.2002 20.5008Z"
        fill="#34A853"
      ></path>
      <path
        d="M5.47228 12.4168C5.04492 11.1748 5.04492 9.82996 5.47228 8.58799V6.01221H2.08334C0.636293 8.83786 0.635973 12.1671 2.08302 14.9928L5.47228 12.4168Z"
        fill="#FBBC04"
      ></path>
      <path
        d="M11.2002 4.45805C12.6547 4.43601 14.0605 4.97247 15.1139 5.95723L18.0268 3.10218C16.1824 1.40459 13.7344 0.471278 11.2002 0.500674C7.34262 0.500674 3.8153 2.63172 2.08334 6.01221L5.47228 8.58799C6.27828 6.21797 8.5385 4.45805 11.2002 4.45805Z"
        fill="#EA4335"
      ></path>
    </svg>
  );
};

export default GoogleIcon;
