import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToEmptyRoomFrame1 from "../Images/HowToEmptyRoomFrame1.jpg";
import HowToEmptyRoomFrame2 from "../Images/HowToEmptyRoomFrame2.jpg";
import Example1Before from "../Images/curtain-effect-empty-room/Example1Before.jpeg";
import Example1After from "../Images/curtain-effect-empty-room/Example1After.jpeg";
import Example2Before from "../Images/curtain-effect-empty-room/Example2Before.jpeg";
import Example2After from "../Images/curtain-effect-empty-room/Example2After.jpeg";
import Example3Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example3After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import Example4Before from "../Images/curtain-effect-empty-room/Example4Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example4After.jpeg";
import CurtainEffect from "../CurtainEffect";

function HowToEmptyRoomDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">So leeren Sie Räume virtuell</h1>
          <h2 className="title-secondary gray">
            Tutorial zum virtuellen Leeren von Räumen
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich Räume virtuell leeren?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Pedra bietet eine Option, mit der Sie Räume mit nur einem Klick
                leeren können.
              </p>
              <p style={{ marginTop: "10px" }}>
                Um einen Raum zu leeren, öffnen Sie das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Leerer Raum"</span>. In 2
                bis 3 Minuten haben Sie ein Bild Ihres geleerten Raums, bereit
                zum Download.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame1}
                alt="So leeren Sie Räume in Häusern und Immobilien – Screenshot der Pedra-Software"
              />
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Wie kann ich bessere Ergebnisse beim Leeren eines Raums erzielen?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Wenn die{" "}
                <span style={{ fontWeight: "500" }}>"Leerer Raum"</span>{" "}
                Funktion Objekte oder Teile nicht vollständig entfernt hat oder
                zusätzliche Fenster oder Türen hinzugefügt hat, können Sie das{" "}
                <span style={{ fontWeight: "500" }}>"Objekt entfernen"</span>{" "}
                Tool auf dem geleerten Bild verwenden, um alles zu entfernen,
                was Sie nicht im Foto haben möchten.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToEmptyRoomFrame2}
                alt="So leeren Sie Räume in Teilen – Screenshot der Pedra-Software"
              />
              <p>
                Und wenn Sie eine höhere Qualität des Fotos wünschen, klicken
                Sie auf die{" "}
                <span style={{ fontWeight: "500" }}>"In HD umwandeln"</span>{" "}
                Option, wenn Sie mit dem Löschen der Objekte fertig sind.
              </p>
              <p style={{ marginTop: "10px" }}>
                Zum Leeren öffnen Sie das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Leerer Raum"</span>. In 2
                bis 3 Minuten haben Sie Ihren geleerten Raum, bereit zum
                Download.
              </p>
              <aside className="callout" style={{ marginTop: "10px" }}>
                <div>
                  <InfoIcon />
                </div>
                <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                  Wenn Sie die Bilder nicht korrekt leeren können, können Sie
                  eine E-Mail an{" "}
                  <span style={{ fontWeight: "500" }}>felix@pedra.so</span>{" "}
                  senden und wir senden sie Ihnen innerhalb von 24-48 Stunden
                  geleert zurück. Sie müssen Pedra abonniert haben, um diesen
                  Service nutzen zu können.
                </h4>
              </aside>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Warum sollte ich eine Immobilie virtuell leeren?
            </h2>
            <p className="article-text">
              <p style={{ marginTop: "10px" }}>
                Räume ohne Möbel zu zeigen kann beim Verkauf einer Immobilie
                entscheidend sein.
              </p>
              <p style={{ marginTop: "10px" }}>
                Einerseits fühlt sich der Verkäufer wohler, wenn nicht all seine
                persönlichen Gegenstände online zu sehen sind. Stellen Sie sich
                vor, jeder könnte Ihr Zimmer online sehen – das möchte niemand.
              </p>
              <p style={{ marginTop: "10px" }}>
                Andererseits wird der Käufer von einer unpersönlichen Immobilie
                mehr angezogen und kann das Potenzial der Immobilie besser
                erkennen oder sich sein zukünftiges Zuhause besser vorstellen.
              </p>
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Beispiele für virtuell geleerte Räume
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele von Räumen, die mit Pedra virtuell
              geleert wurden:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Vor dem virtuellen Leeren des Raums"}
              altAfter={"Nach dem virtuellen Leeren des Raums"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Vor dem virtuellen Leeren des Raums"}
              altAfter={"Nach dem virtuellen Leeren des Raums"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Vor dem virtuellen Leeren des Raums"}
              altAfter={"Nach dem virtuellen Leeren des Raums"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Vor dem virtuellen Leeren des Raums"}
              altAfter={"Nach dem virtuellen Leeren des Raums"}
            />

            <br />
            {/* 
            <h2 className="article-subtitleh2">
              Video zum virtuellen Leeren eines Raums
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    maxWidth: "900px",
                    height: "500px",
                  }}
                  frameBorder="0"
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  allowFullScreen
                  title="KI zum Verkauf von Immobilien"
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToEmptyRoomDe;
