import React from "react";

const WallChangePricingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.47826 11.6878V12.3478H10.2609V16.5217H7.13043V12.3478H7.91304V10.4644L14.4348 8.83392V6.60871H13.3913V8.17392H4V3.47827H13.3913V5.04349H16V10.0574L9.47826 11.6878Z"
        fill="#B15DE1"
      ></path>
    </svg>
  );
};

export default WallChangePricingIcon;
