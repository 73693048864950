import React from "react";

function LegalPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Aviso legal</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <h2 className="article-subtitleh2">Identificação do Titular:</h2>
            <div className="article-text">
              Nome da Empresa: Pedra Software S.L.
              <br />
              CIF: B16459992
              <br />
              Sede Social: Calle Vista Bella núm. 8, (08022), Barcelona,
              Catalunha, Espanha. Atividade: Desenvolvimento e fornecimento de
              software e outros serviços relacionados com tecnologias de
              informação e informática. CNAE: 6209 - Outros serviços
              relacionados com tecnologias de informação e informática.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Informações Gerais:</h2>
            <div className="article-text">
              Este site é propriedade da Pedra Software S.L. O uso deste site
              está sujeito às seguintes condições de uso. Solicitamos que as
              leia atentamente. O acesso a este site e o uso de seus conteúdos e
              serviços implica a aceitação total e sem reservas destas
              condições.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">
              Propriedade Intelectual e Industrial
            </h2>
            <div className="article-text">
              Nome da Empresa: Pedra Software S.L. Todos os conteúdos deste
              site, incluindo, sem limitação, textos, gráficos, imagens, seu
              design e os direitos de propriedade intelectual que possam
              corresponder a estes conteúdos, bem como todas as marcas, nomes
              comerciais ou qualquer outro sinal distintivo são propriedade da
              Pedra Software S.L., ficando reservados todos os direitos sobre os
              mesmos.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Condições de Uso</h2>
            <div className="article-text">
              O usuário se compromete a fazer uso adequado dos conteúdos e
              serviços que a Pedra Software S.L. oferece e não empregá-los para
              realizar atividades ilícitas ou que constituam delito e/ou que
              infrinjam a regulamentação sobre propriedade intelectual e
              industrial, ou qualquer outra norma do ordenamento jurídico
              aplicável.
            </div>
            <br />
            <h2 className="article-subtitleh2">Proteção de Dados</h2>
            <div className="article-text">
              Em cumprimento ao disposto no Regulamento (UE) 2016/679 do
              Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo
              à proteção das pessoas físicas no que diz respeito ao tratamento
              de dados pessoais e à livre circulação desses dados, todos os
              dados pessoais coletados durante o uso do nosso site serão
              tratados conforme nossa{" "}
              <a
                className="article-link"
                href="https://www.pedra.so/pt/privacy"
              >
                Política de Privacidade
              </a>
              , a qual todos os usuários devem aceitar antes de fornecer dados
              pessoais através deste site.
            </div>
            <br />
            <h2 className="article-subtitleh2">Contato</h2>
            <div className="article-text">
              Para qualquer pergunta ou proposta, entre em contato conosco
              através do seguinte e-mail:{" "}
              <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalPt;
