import React from "react";
import ReleaseSep2024Frame1 from "../Images/ReleaseSep2024Frame1.png";
import ReleaseSep2024Frame2 from "../Images/ReleaseSep2024Frame2.png";
import ReleaseSep2024Frame3 from "../Images/ReleaseSep2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseSep24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">SEPTEMBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: Renovierung und Möblierung mit einem Klick, verbesserte
            Ergebnisse und Raumerhaltung
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              In diesem September bringen wir Ihnen Verbesserungen, die die
              Nutzung von Pedra effizienter machen und es Ihnen ermöglichen, das
              Potenzial Ihrer Immobilien einfach zu präsentieren:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Renovieren und möblieren mit 1 Klick
              </h2>
              Früher waren für die Möblierung und Renovierung eines Raums zwei
              Schritte erforderlich. Jetzt ist das nicht mehr nötig – Sie können
              Renovierungsvorschläge generieren, die den Raum in einem Durchgang
              möblieren.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame1}
                  alt="Beispiel für Renovierung und Möblierung mit einem Klick"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Bessere Ergebnisse bei "Möblieren" im "Auto"-Modus
              </h2>
              Wir haben Pedras virtuelles Home Staging besser denn je gemacht.
              Wenn Sie jetzt auf <strong>"Möblieren"</strong> im{" "}
              <strong>"Auto"</strong>-Modus klicken, sehen Sie viel bessere
              Ergebnisse: dekorierte Wände, Hyperrealismus und korrekt
              positionierte Möbel.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame2}
                  alt="Beispiel für verbesserte Ergebnisse im Auto-Möblierungsmodus"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Verbesserte Raumerhaltung beim Leeren von Räumen
              </h2>
              Bei der Verwendung von <strong>"Raum leeren"</strong> werden Sie
              feststellen, dass die Architektur des Raums besser erhalten
              bleibt.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseSep2024Frame3}
                alt="Beispiel für verbesserte Raumerhaltung beim Leeren eines Raums"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>

            <div>
              <li>
                Bessere Ergebnisse bei <strong>"In HD umwandeln"</strong>
              </li>
              <li>
                Verbesserte Möbelpositionierung bei Verwendung von{" "}
                <strong>"Möblieren"</strong> im <strong>"Auto"</strong>-Modus
              </li>
              <li>Verbesserte Ergebnisse beim Hinzufügen von blauem Himmel</li>
              <li>
                Verbesserte Objektentfernung (Objekte werden korrekt entfernt
                bei gleichzeitiger Erhaltung der Bildqualität)
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseSep24De;
