import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseDec2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseDec2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseDec24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">DECEMBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: New features for new builds and commercial spaces
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              We're ending the year by bringing improvements to help Pedra
              assist you with more types of property sales :)
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Create renders from floor plans
              </h2>
              Would you like to convert your floor plans into realistic images
              instantly? Now with Pedra it's possible. Upload a floor plan image
              and generate images of all rooms in 1 click. Perfect for new
              builds.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Renders from floor plans"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Renovate and furnish commercial spaces
              </h2>
              Now you have the option to choose the type of space you want when
              renovating or furnishing your rooms: restaurant, bar, shop,
              supermarket, hair salon... Whatever you want!
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Commercial spaces"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>
            <div>
              <li>Better results when using "Furnish" to furnish rooms</li>
              <li>
                Option to hire professional virtual room furnishing service for
                €6 per image
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseDec24;
