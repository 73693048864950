import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeSpaceExample1 from "../Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "../Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "../Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "../Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "../Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "../Images/curtain-effect/AfterSpaceStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLocalDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie Renderings für Gewerbeimmobilien
          </h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Renderings für Gewerbeimmobilien
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Warum sollten Sie Pedras Rendering-Tools für Gewerbeimmobilien
              nutzen?
            </h2>
            <p className="article-text">
              Mit Pedras Rendering-Tools können Sie Transformationen von
              Gewerbeimmobilien in wenigen Sekunden visualisieren und
              potenziellen Kunden oder Investoren das wahre Potenzial der
              Räumlichkeiten zeigen.
              <p style={{ marginTop: "10px" }}>
                Renovierungen und Staging von Gewerbeimmobilien sind
                entscheidend, um Mieter oder Käufer anzuziehen. Die Möglichkeit,
                das Potenzial einer leeren oder veralteten Gewerbeimmobilie
                aufzuzeigen, kann einen erheblichen Unterschied beim schnelleren
                Abschluss von Geschäften machen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit Pedra benötigen Sie keine spezialisierten Innenarchitekten
                oder 3D-Rendering-Experten mehr, um überzeugende
                Visualisierungen von Gewerbeimmobilien zu erstellen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich ein Rendering einer Gewerbeimmobilie?
            </h2>
            <p className="article-text">
              Laden Sie zunächst klare Fotos der bestehenden Gewerbeimmobilie
              hoch, die Sie transformieren möchten.
              <p style={{ marginTop: "10px" }}>
                Wählen Sie Ihre Gewerbeimmobilienbilder aus und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                dann wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>"Renovieren"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="So nutzen Sie das Renovierungstool zur Erstellung von Gewerbeimmobilien-Renderings – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Schnelle Transformation von Gewerbeimmobilien:{" "}
              </span>
              Erstellen Sie ein schnelles Gewerbeimmobilien-Rendering
            </h2>
            <p style={{ marginTop: "10px" }}>
              Das <span style={{ fontWeight: "500" }}>"Renovieren"</span> Tool
              transformiert Ihre Gewerbeimmobilie in etwa 25 Sekunden.
              <p>
                Für eine subtile Aktualisierung wählen Sie den
                "Minimalistischen" Stil mit "Niedriger" Kreativität. Dies behält
                das grundlegende Layout bei, während Oberflächen und
                Einrichtungen modernisiert werden.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="So nutzen Sie das Renovierungstool zur Erstellung von Gewerbeimmobilien-Renderings – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Passen Sie Ihr Gewerbeimmobilien-Rendering an
            </h2>
            <p className="article-text">
              Pedra bietet umfangreiche Anpassungsoptionen für
              Gewerbeimmobilien-Renderings.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Wählen Sie aus verschiedenen Stilen, spezifizieren Sie Ihren
                  Raumtyp, steuern Sie, wie dramatisch die Änderungen sein
                  sollen, und entscheiden Sie, ob bestehende Elemente wie
                  Fenster oder strukturelle Merkmale erhalten bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="So nutzen Sie das Renovierungstool zur Erstellung von Gewerbeimmobilien-Renderings – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Spezifizieren Sie Ihren Gewerbeimmobilientyp
            </h3>
            <p className="article-text">
              Geben Sie im Raumtyp-Feld den spezifischen Typ der
              Gewerbeimmobilie ein, mit der Sie arbeiten (z.B. Restaurant, Bar,
              Cafeteria, Einzelhandelsgeschäft, Büroräume usw.).
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Referenzbilder ähnlicher Räume hochladen, die
                  Ihnen gefallen.
                </span>{" "}
                Dies ermöglicht es Ihnen, Renderings zu erstellen, die
                spezifischen Design-Inspirationen oder der bevorzugten Ästhetik
                Ihres Kunden entsprechen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Legen Sie die Renovierungsintensität fest
            </h3>
            <p className="article-text">
              Wählen Sie zwischen niedrigen, mittleren, hohen und sehr hohen
              Kreativitätsstufen für Ihre Gewerbeimmobilientransformation.
              <p style={{ marginTop: "10px" }}>
                Niedrige und mittlere Einstellungen aktualisieren Oberflächen
                und Einrichtungen, während das grundlegende Layout erhalten
                bleibt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hohe und sehr hohe Einstellungen können den Raum mit neuen
                Layouts, Möbelkonfigurationen und architektonischen Änderungen
                komplett neu gestalten.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Konfigurieren Sie die Erhaltungseinstellungen
            </h3>
            <p className="article-text">
              Wählen Sie, ob Fenster und strukturelle Elemente erhalten bleiben
              sollen, was besonders wichtig ist für Gewerbeimmobilien mit
              spezifischen architektonischen Merkmalen oder
              Beleuchtungsanforderungen. Dies stellt sicher, dass Ihr Rendering
              praktische Elemente beibehält, während die Ästhetik aktualisiert
              wird.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Generieren Sie Ihr Gewerbeimmobilien-Rendering
            </h2>
            <p>
              Nachdem Sie Ihre Präferenzen ausgewählt haben, generieren Sie das
              Gewerbeimmobilien-Rendering. Standard-Renderings dauern etwa 15
              Sekunden, während das Erhalten spezifischer Elemente etwas länger
              dauern kann.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps für bessere Gewerbeimmobilien-Renderings
                </h4>
                <p className="callout-p">
                  Um die realistischsten und nützlichsten
                  Gewerbeimmobilien-Renderings zu erhalten:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Stellen Sie sicher, dass "Möblieren" aktiviert ist
                      </span>{" "}
                      bevor Sie auf Bild generieren klicken. Dies stellt sicher,
                      dass Ihr Raum entsprechend seinem Typ richtig möbliert
                      wird.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Seien Sie spezifisch mit Ihrem Raumtyp
                      </span>
                      . Statt nur "Restaurant" spezifizieren Sie "modernes
                      italienisches Restaurant" oder "gemütliches Café" für
                      gezieltere Ergebnisse.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generieren Sie mehrere Versionen
                      </span>
                      . Jedes Rendering ist einzigartig, und Sie könnten
                      feststellen, dass einige wichtige Merkmale besser erhalten
                      oder Ihrer Vision besser entsprechen.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Entfernen Sie Unordnung und temporäre Gegenstände vor
                        dem Rendering
                      </span>
                      . Dies hilft der KI, den Raum besser zu verstehen und
                      genauere Transformationen zu erstellen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Beispiele für Gewerbeimmobilien-Renderings
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für
              Gewerbeimmobilien-Transformationen, die mit unseren Renderings
              erstellt wurden:
            </p>
            <CurtainEffect
              beforeImage={BeforeSpaceExample1}
              afterImage={AfterSpaceExample1}
              altBefore={"Gewerbeimmobilie vor dem Rendering"}
              altAfter={"Gewerbeimmobilie nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample2}
              afterImage={AfterSpaceExample2}
              altBefore={"Gewerbeimmobilie vor dem Rendering"}
              altAfter={"Gewerbeimmobilie nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample3}
              afterImage={AfterSpaceExample3}
              altBefore={"Gewerbeimmobilie vor dem Rendering"}
              altAfter={"Gewerbeimmobilie nach dem Rendering"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLocalDe;
