import React from "react";

function LegalDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Impressum</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <h2 className="article-subtitleh2">Unternehmensidentifikation:</h2>
            <div className="article-text">
              Firmenname: Pedra Software S.L.
              <br />
              Steuernummer: B16459992
              <br />
              Geschäftssitz: Calle Vista Bella No. 8, (08022), Barcelona,
              Katalonien, Spanien. Tätigkeit: Entwicklung und Bereitstellung von
              Software und anderen verwandten Dienstleistungen in der
              Informationstechnologie und Datenverarbeitung. NACE-Code: 6209 -
              Sonstige Dienstleistungen der Informationstechnologie und
              Datenverarbeitung.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Allgemeine Informationen:</h2>
            <div className="article-text">
              Diese Website gehört der Pedra Software S.L. Die Nutzung dieser
              Website unterliegt den folgenden Nutzungsbedingungen. Bitte lesen
              Sie diese sorgfältig durch. Der Zugriff auf diese Website und die
              Nutzung ihrer Inhalte und Dienste impliziert die vollständige und
              vorbehaltlose Annahme dieser Bedingungen.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">
              Geistiges und gewerbliches Eigentum
            </h2>
            <div className="article-text">
              Firmenname: Pedra Software S.L. Alle Inhalte dieser Website,
              einschließlich, aber nicht beschränkt auf Texte, Grafiken, Bilder,
              deren Design und die geistigen Eigentumsrechte, die solchen
              Inhalten entsprechen können, sowie alle Marken, Handelsnamen oder
              sonstigen Unterscheidungsmerkmale sind Eigentum von Pedra Software
              S.L., alle Rechte vorbehalten.
              <br />
            </div>
            <br />
            <h2 className="article-subtitleh2">Nutzungsbedingungen</h2>
            <div className="article-text">
              Der Benutzer verpflichtet sich, die von Pedra Software S.L.
              angebotenen Inhalte und Dienste angemessen zu nutzen und nicht für
              illegale Aktivitäten oder Aktivitäten zu verwenden, die eine
              Straftat darstellen und/oder gegen Vorschriften zum geistigen und
              gewerblichen Eigentum oder andere geltende Rechtsnormen verstoßen.
            </div>
            <br />
            <h2 className="article-subtitleh2">Datenschutz</h2>
            <div className="article-text">
              In Übereinstimmung mit der Verordnung (EU) 2016/679 des
              Europäischen Parlaments und des Rates vom 27. April 2016 zum
              Schutz natürlicher Personen bei der Verarbeitung personenbezogener
              Daten und zum freien Datenverkehr werden alle während der Nutzung
              unserer Website erhobenen personenbezogenen Daten gemäß unserer{" "}
              <a className="article-link" href="https://www.pedra.so/privacy">
                Datenschutzerklärung
              </a>{" "}
              behandelt, die alle Benutzer akzeptieren müssen, bevor sie
              personenbezogene Daten über diese Website bereitstellen.
            </div>
            <br />
            <h2 className="article-subtitleh2">Kontakt</h2>
            <div className="article-text">
              Bei Fragen oder Vorschlägen kontaktieren Sie uns unter folgender
              E-Mail-Adresse:{" "}
              <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalDe;
