import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageTerraceFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingTerraceDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie virtuelles Home Staging für Terrassen
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung von virtuellem Home Staging für
            Außenbereiche
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich virtuelles Home Staging für eine Terrasse erstellen?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie virtuelles Home Staging für Terrassen mit nur
              wenigen Klicks erstellen.
              <p>
                Öffnen Sie zunächst das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>. Wählen
                Sie "Terrasse" als Raumtyp aus, und in etwa 2 bis 3 Minuten
                haben Sie Ihr virtuelles Home Staging-Bild der Terrasse zum
                Download bereit.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="So erstellen Sie virtuelles Home Staging für eine Terrasse – Beispiele"
            />
            <br />
            <h2 className="article-subtitleh2">
              Wie erstellt man virtuelles Home Staging für Terrassen?
            </h2>
            <p className="article-text">
              Um Ihr{" "}
              <Link className="article-link" to="/home-staging-terrace">
                virtuelles Home Staging für eine Terrasse
              </Link>
              zu erstellen, folgen Sie diesen einfachen Schritten:
            </p>
            <ol>
              <li>
                Laden Sie das Bild der Terrasse hoch, die Sie virtuell möblieren
                möchten
              </li>
              <li>
                Wählen Sie das Bild aus, um mit der Bearbeitung zu beginnen
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menü zur Erstellung von virtuellem Home Staging für eine Terrasse"
            />
            <p className="article-text">
              Innerhalb von{" "}
              <span style={{ fontWeight: "500" }}>"Möblieren"</span> müssen Sie
              diese Schritte befolgen:
            </p>
            <ol>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>Position der Möbel</span>.
                Für Terrassen empfehlen wir "Zeichnen", um bestimmte Bereiche
                für Gartenmöbel zu markieren.
              </li>
              <li>
                Wählen Sie <span style={{ fontWeight: "500" }}>"Terrasse"</span>{" "}
                als Raumtyp. Dies stellt sicher, dass Pedra passende Außenmöbel
                wie Gartenstühle, Terrassentische, Sonnenschirme usw. platziert.
              </li>
              <li>
                Wählen Sie den <span style={{ fontWeight: "500" }}>Stil</span>.
                Für Terrassen funktionieren Stile wie "Mediterran", "Modern"
                oder "Rustikal" normalerweise gut.
              </li>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Anzahl der zu generierenden Bilder
                </span>
                . Wir empfehlen 2 oder 3 Bilder, um verschiedene
                Terrassengestaltungsoptionen zu haben.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für besseres virtuelles Home Staging einer Terrasse:
                <ul>
                  <li>
                    Stellen Sie sicher, dass das Foto die Grenzen der Terrasse
                    klar zeigt, für eine genauere Möblierung
                  </li>
                  <li>
                    Wenn die Terrasse eine Aussicht hat, versuchen Sie diese im
                    Foto einzufangen für ein attraktiveres Ergebnis
                  </li>
                  <li>
                    Nutzen Sie die "Zeichnen"-Option, um spezifische Bereiche
                    für Außenmöbel, Pflanzen oder Dekoelemente zu markieren
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Beispiel für die Erstellung von virtuellem Home Staging für eine Terrasse"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Warum virtuelles Home Staging für eine Terrasse?
            </h2>
            <p className="article-text">
              Eine gut präsentierte Terrasse kann ein großer Anziehungspunkt für
              potenzielle Käufer sein. Virtuelles Home Staging für Terrassen
              ermöglicht es Ihnen, das Potenzial dieser Außenbereiche zu zeigen,
              indem Sie gemütliche und funktionale Umgebungen schaffen, die den
              Käufern helfen, sich vorzustellen, wie sie den Außenbereich nutzen
              könnten.
            </p>
            <p className="article-text">
              Mit gutem virtuellem Home Staging für eine Terrasse können Sie:
            </p>
            <ul>
              <li>
                Das Potenzial leerer oder unattraktiver Außenbereiche zeigen
              </li>
              <li>Eine visuelle Erweiterung des Innenraums schaffen</li>
              <li>
                Besonderheiten wie Aussicht, Privatsphäre oder Vielseitigkeit
                des Raums hervorheben
              </li>
              <li>
                Käufern helfen, sich Momente der Entspannung oder Unterhaltung
                auf der Terrasse vorzustellen
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Beispiele für virtuelles Home Staging von Terrassen
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für virtuelles Home Staging, das auf
              Terrassen angewendet werden kann:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingTerraceDe;
