import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Keep all the existing image imports
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeKitchen from "./Images/curtain-effect/BeforeRenderFromFloorPlan1.png";
import AfterKitchen from "./Images/curtain-effect/AfterRenderFromFloorPlan1.png";
import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeRenderFromFloorPlan2.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterRenderFromFloorPlan2.png";
import BeforeKitchenStaging2 from "./Images/curtain-effect/BeforeRenderFromFloorPlan3.png";
import AfterKitchenStaging2 from "./Images/curtain-effect/AfterRenderFromFloorPlan3.png";
import BeforeKitchenStaging3 from "./Images/curtain-effect/BeforeRenderFromFloorPlan4.png";
import AfterKitchenStaging3 from "./Images/curtain-effect/AfterRenderFromFloorPlan4.png";
import VirtualStagingExample1 from "./Images/HowToFloorPlanRender1En.png";
import VirtualStagingExample2 from "./Images/HowToFloorPlanRender2En.png";
import VirtualStagingExample3 from "./Images/HowToFloorPlanRender3En.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderFromFloorPlanPt() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeKitchen,
    afterImage: AfterKitchen,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeKitchen,
        afterImage: AfterKitchen,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeKitchenStaging1,
        afterImage: AfterKitchenStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeKitchenStaging2,
        afterImage: AfterKitchenStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeKitchenStaging3,
        afterImage: AfterKitchenStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "O que é renderização de planta baixa?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "A renderização de planta baixa é um serviço baseado em IA que transforma plantas baixas 2D em visualizações fotorrealistas de ambientes. Esta tecnologia permite ver como cada cômodo da sua planta ficaria na vida real, completo com móveis, materiais e iluminação.",
        },
      },
      {
        "@type": "Question",
        name: "Quais são os benefícios da renderização automática de planta baixa?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "A renderização automática de planta baixa oferece vários benefícios: visualização rápida de espaços, a capacidade de testar diferentes estilos instantaneamente, resultados fotorrealistas de alta qualidade, economia significativa de tempo em comparação com a renderização tradicional e a flexibilidade para experimentar diferentes layouts e designs de cômodos.",
        },
      },
      {
        "@type": "Question",
        name: "Como funciona o processo de renderização de planta baixa?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "O processo é simples: carregue sua planta baixa em nossa plataforma, selecione seus estilos e opções de personalização preferidos para cada cômodo e receba visualizações fotorrealistas em minutos. Nossa tecnologia baseada em IA lida com toda a complexidade do trabalho de visualização automaticamente.",
        },
      },
      {
        "@type": "Question",
        name: "Quanto custa a renderização de planta baixa?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Nosso serviço de renderização de planta baixa está disponível por €29 mais IVA por mês. Este plano permite gerar até 100 renderizações por mês, sem compromisso de longo prazo necessário.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeKitchenStaging1,
      afterImage: AfterKitchenStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeKitchenStaging2,
      afterImage: AfterKitchenStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeKitchenStaging3,
      afterImage: AfterKitchenStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Renderização a partir de Planta</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Transforme suas plantas em visualizações realistas de
                  ambientes
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rápido:</strong> Resultados em 1 minuto
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simples:</strong> Apenas alguns cliques necessários
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Profissional:</strong> Renderizações de alta
                    qualidade
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Flexível:</strong> Qualquer estilo que desejar
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Experimente o Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por 100 renderizações
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Director of Photography at aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO at Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO at Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Mais de 100 depoimentos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Antes da renderização de planta baixa"
                  altAfter="Depois da renderização de planta baixa"
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Cozinha
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Quarto
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Sala de estar
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Banheiro
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          CONFIADO POR MAIS DE 5.000 PROFISSIONAIS
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Transforme plantas em visualizações realistas{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automaticamente
          </span>{" "}
          em minutos.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Renderização automática</h3>
                <div className="feature-text">
                  Gere visualizações a partir de plantas em poucos cliques
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Múltiplos estilos</h3>
                <div className="feature-text">
                  Escolha entre vários estilos de design de interiores
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Resultados rápidos</h3>
                <div className="feature-text">
                  Receba suas renderizações em apenas 1 minuto
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Ferramentas de edição IA</h3>
                <div className="feature-text">
                  Aprimore ainda mais suas renderizações com nossas ferramentas
                  de IA
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Qualquer formato</h3>
                <div className="feature-text">
                  Baixe suas imagens no formato de sua preferência
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Qualidade impressionante</h3>
                <div className="feature-text">
                  Imagens de qualidade profissional a partir de suas plantas
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemplos de nossas renderizações a partir de plantas.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore="Antes da renderização de planta baixa"
            altAfter="Depois da renderização de planta baixa"
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Como funciona a renderização a partir de plantas?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 1
            </h5>
            <h2>Envie sua planta</h2>
            <div className="value-prop-text">
              Envie sua planta para a plataforma Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Como carregar plantas baixas para renderização automática no Pedra – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 2
            </h5>
            <h2>Escolha suas opções de renderização</h2>
            <div className="value-prop-text">
              Selecione seu estilo preferido e opções de personalização.
              <p>Escolha o número de imagens que deseja e o estilo.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Como escolher opções para renderização de planta baixa – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 3
            </h5>
            <h2>Receba suas renderizações de ambientes</h2>
            <div className="value-prop-text">
              Receba renderizações fotorrealistas de cada ambiente da sua
              planta.
              <p>
                Baixe e use suas renderizações imediatamente ou continue
                editando-as.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Como baixar renderizações de planta baixa – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Renderizações profissionais de ambientes por €29 por mês. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sem compromisso necessário.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plano Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                por mês
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclui</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 gerações de imagens
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mês</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renderize imagens a partir de plantas
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Ambientes vazios
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Mobilie
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remova objetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumente a resolução
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Altere paredes e pisos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Melhore a fotografia
            </div>

            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente o Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancele a qualquer momento.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Renderizações adicionais disponíveis por €0,20 cada, se
              necessário.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Perguntas sobre renderização a partir de plantas
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                O que é renderização a partir de plantas?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                A renderização a partir de plantas é um serviço alimentado por
                IA que transforma plantas 2D em visualizações fotorrealistas de
                ambientes. Nossa tecnologia permite que você veja como cada
                ambiente da sua planta ficaria na vida real, completo com
                móveis, materiais e iluminação.
                <br />
                <br />O sistema gerencia automaticamente todos os aspectos da
                visualização para produzir resultados profissionais em apenas
                minutos.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais são os benefícios da renderização automática a partir de
                plantas?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                A renderização automática a partir de plantas oferece numerosas
                vantagens:
                <br />
                <br />
                1. Velocidade: Gere renderizações em apenas 1 minuto
                <br />
                2. Custo-benefício: Sem necessidade de modelagem 3D cara
                <br />
                3. Fácil de usar: Processo simples de envio e personalização
                <br />
                4. Múltiplos estilos: Teste diferentes designs rapidamente
                <br />
                5. Qualidade profissional: Saída em alta resolução
                <br />
                6. Flexibilidade: Edite e atualize renderizações facilmente
                <br />
                7. Solução completa: Gerencia todos os ambientes da sua planta
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                O que posso fazer com as renderizações de plantas da Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                O sistema de renderização de plantas da Pedra oferece recursos
                extensivos:
                <br />
                <br />
                1. Gere renderizações: Crie visualizações fotorrealistas a
                partir de qualquer planta
                <br />
                2. Seleção de estilo: Escolha entre vários estilos de design de
                interiores
                <br />
                3. Personalização de ambientes: Modifique materiais, móveis e
                iluminação
                <br />
                4. Opções de formato: Baixe no formato de arquivo de sua
                preferência
                <br />
                5. Edição com IA: Aprimore renderizações com ferramentas
                adicionais
                <br />
                6. Processamento em lote: Gerencie múltiplos ambientes
                eficientemente
                <br />
                <br />
                Todas as transformações são gerenciadas automaticamente por
                nossa IA, entregando resultados profissionais em minutos.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderFromFloorPlanPt;
