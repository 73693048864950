// Render.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import RedEyeIcon from "./Icons/RedEyeIcon";
import RedHammerIcon from "./Icons/RedHammerIcon";
import RedMultipleIcon from "./Icons/RedMultipleIcon";
import RedBrushIcon from "./Icons/RedBrushIcon";
import RedDownloadIcon from "./Icons/RedDownloadIcon";
import RedPlantIcon from "./Icons/RedPlantIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import posterImageVideo from "./Images/poster-images/poster-image-video.png";

//Images
import RenderFeature1 from "./Images/RenderFeature1.png";
import RenderFeature2 from "./Images/RenderFeature2.png";
import RenderFeature3 from "./Images/RenderFeature3.png";
import RenderFeature4 from "./Images/RenderFeature4.png";
import RenderFeature5 from "./Images/RenderFeature5.png";
import RenderFeature6 from "./Images/RenderFeature6.png";
import RenderStep1 from "./Images/RenderStep1.png";
import RenderStep2 from "./Images/RenderStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RenderPt() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">Renders com IA</h1>
            <div className="section-first-text">
              Use a Pedra para gerar renders profissionais de renovação com
              inteligência artificial. Basta fazer upload da sua imagem e
              selecionar uma opção de renovação
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#EB5757",
                  border: "1px solid #EB5757",
                }}
              >
                Experimente Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Diretor de Fotografia na aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Corretor de Imóveis na Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO da Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO da Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  Mais de 5.000 profissionais já usam a Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                  title="Render com IA"
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+demo+video+09+Jul+24+English.mp4"
                    type="video/mp4"
                  />
                  Seu navegador não suporta a tag de vídeo.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MAIS DE 5.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Renders lindos em 1 clique</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedHammerIcon />
                <h2 className="feature-title">
                  Crie renders a partir de uma imagem com IA
                </h2>
                <div className="feature-text">
                  Gere um render profissional a partir de uma única imagem
                </div>
              </div>
              <img
                src={RenderFeature1}
                alt="Crie Renders Incríveis com IA"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedMultipleIcon />
                <h2 className="feature-title">
                  Gere múltiplos renders de uma vez
                </h2>
                <div className="feature-text">
                  Você também pode gerar vários renders ao mesmo tempo
                  selecionando-os e escolhendo uma opção de renovação
                </div>
              </div>
              <img
                src={RenderFeature2}
                alt="Crie Vários Renders De Uma Vez – Captura de Tela do Software"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedEyeIcon />
                <h2 className="feature-title">Selecione estilos de render</h2>
                <div className="feature-text">
                  Escolha entre nossos vários estilos de renders: minimalista,
                  escandinavo, boêmio, etc.
                </div>
              </div>
              <img
                src={RenderFeature3}
                alt="Selecione Estilos de Render com Inteligência Artificial"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedDownloadIcon />
                <h2 className="feature-title">
                  Baixe render em qualquer formato
                </h2>
                <div className="feature-text">
                  Baixe renders em formatos PNG, JPG ou JPEG
                </div>
              </div>
              <img
                src={RenderFeature4}
                alt="Baixe Render Em Qualquer Formato – Captura de Tela do Software"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedPlantIcon />
                <h2 className="feature-title">
                  Adicione qualquer objeto com IA
                </h2>
                <div className="feature-text">
                  Adicione objetos específicos aos ambientes: plantas, mesas,
                  etc.
                </div>
              </div>
              <img
                src={RenderFeature5}
                alt="Adicione Qualquer Objeto – Captura de Tela do Software"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedBrushIcon />
                <h2 className="feature-title">Remova objetos com IA</h2>
                <div className="feature-text">
                  Remova partes de uma imagem antes ou depois do render
                </div>
              </div>
              <img
                src={RenderFeature6}
                alt="Remova Objetos – Captura de Tela do Software"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Como fazer um render de renovação com IA?
        </h2>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep1}
            alt="Como Fazer Upload De Um Arquivo – Diagrama"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>PASSO 1</h5>
          <h2>Crie um novo projeto e faça upload de uma ou mais imagens</h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep2}
            alt="Como Criar Render – Diagrama"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>PASSO 2</h5>
          <h2>Selecione uma das opções de renovação</h2>
          <div className="value-prop-text">
            As opções de renovação geralmente levam 15 segundos. Você pode gerar
            várias renovações ao mesmo tempo.
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button className="button" onClick={redirectToPage}>
          Experimente Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>

      <div className="section-one-column-left">
        <div className="title-container">Perguntas e respostas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">O que é um render com IA?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Um render com IA, no contexto de design e visualização
                arquitetônica, refere-se à aplicação de inteligência artificial
                (IA) para gerar imagens renderizadas de alta qualidade de
                espaços arquitetônicos. A inteligência artificial é usada para
                recriar espaços com base em imagens de entrada, permitindo
                resultados realistas e precisos.
                <br />
                <br />
                A IA permite resultados mais rápidos que os métodos tradicionais
                de renderização. Métodos antigos envolviam recriar manualmente
                modelos 3D dos ambientes e processar imagens para criar renders
                impressionantes. Com a IA isso agora é diferente. Podemos partir
                de imagens para criar novos visuais baseados na imagem original
                e em um novo estilo aplicado.
                <br />
                <br />A inteligência artificial na renderização de imagens
                imobiliárias não só economiza tempo, mas fornece uma maneira
                mais flexível de imaginar espaços para o corretor, os
                proprietários e os compradores. A tecnologia ajuda a visualizar
                rapidamente o potencial dos espaços de uma maneira nunca vista
                antes.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais são as vantagens de usar renders com IA?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <h4>Qualidade aprimorada:</h4>Renders com IA podem produzir
                resultados visualmente impressionantes com alto grau de
                realismo. Algoritmos de IA podem simular efeitos de iluminação,
                sombras e texturas com mais precisão que métodos tradicionais.
                <br />
                <br />
                <h4>Eficiência no tempo de renderização:</h4>A inteligência
                artificial pode acelerar o processo de renderização otimizando
                os cálculos necessários para gerar uma imagem. Isso pode reduzir
                significativamente os tempos de renderização, o que é
                especialmente benéfico em projetos com prazos apertados.
                <br />
                <br />
                <h4>Automação de tarefas repetitivas:</h4>A IA pode automatizar
                certas tarefas dentro do processo de renderização. Isso libera
                tempo para que artistas e designers se concentrem em aspectos
                criativos e conceituais.
                <br />
                <br />
                <h4>Personalização e adaptabilidade:</h4>Os algoritmos de IA
                podem aprender com padrões e preferências específicos,
                permitindo a personalização de renders de acordo com as
                necessidades do projeto ou preferências do cliente. Isso pode
                levar a resultados mais personalizados e satisfatórios.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Como funciona a renderização com IA?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Captura de dados e treinamento do modelo:</h4>
                <li>
                  O processo começa com a coleta de dados de entrada, que podem
                  incluir imagens de referência, modelos 3D, texturas e outros
                  elementos relacionados à cena.
                </li>
                <li>
                  Esses dados são usados para treinar um modelo de IA,
                  geralmente uma rede neural, que aprenderá a mapear as
                  características da entrada para a saída desejada, ou seja, a
                  imagem renderizada.
                </li>
                <br />
                <br />
                <h4>Processamento da cena:</h4>
                <li>
                  Uma vez que o modelo está treinado, ele é usado para processar
                  a cena a ser renderizada. Isso envolve gerar pixels para cada
                  ponto da imagem final.
                </li>
                <li>
                  Durante este processo, o modelo de IA pode levar em conta uma
                  variedade de fatores como iluminação, materiais, sombras,
                  texturas e a geometria da cena.
                </li>
                <br />
                <br />
                <h4>Geração da imagem renderizada:</h4>
                <li>
                  O modelo de IA gera a imagem renderizada pixel por pixel,
                  usando as informações aprendidas durante o treinamento para
                  tomar decisões sobre como renderizar cada parte da cena.
                </li>
                <li>
                  Isso pode incluir aplicar efeitos de iluminação global,
                  sombreamento, reflexos, refrações e outros efeitos visuais
                  para melhorar a qualidade e o realismo da imagem final.
                </li>
                <br />
                <br />
                <h4>Refinamento e pós-processamento:</h4>
                <li>
                  Uma vez que a imagem inicial é gerada, ela pode passar por um
                  processo de refinamento e pós-processamento para melhorar
                  ainda mais sua qualidade.
                </li>
                <li>
                  Isso pode incluir corrigir artefatos visuais, aplicar efeitos
                  de pós-processamento como desfoque ou brilho, e outras
                  técnicas para melhorar a estética geral da imagem.
                </li>
                <br />
                <br />
                <h4>Iteração e ajuste:</h4>
                <li>
                  Dependendo dos resultados obtidos, o processo de renderização
                  com IA pode envolver iterações adicionais, onde os parâmetros
                  do modelo são ajustados e os resultados são refinados até que
                  o nível desejado de qualidade e realismo seja alcançado.
                </li>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Quanto custa a Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                A Pedra custa 29€ mais impostos por mês.
                <br />
                <br />É uma assinatura mensal, portanto você pode cancelar sua
                assinatura se não precisar mais.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderPt;
