import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1En from "../Images/HowToHomeStageFrame1Es.jpg";
import HowToHomeStageFrame2En from "../Images/HowToHomeStageFrame2.jpg";
import HowToHomeStageFrame3En from "../Images/HowToHomeStageFrame3.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example1Before from "../Images/Example1BeforeHomeStaging.jpg";
import Example1After from "../Images/Example1AfterHomeStaging.jpg";
import Example2Before from "../Images/Example2BeforeHomeStaging.jpg";
import Example2After from "../Images/Example2AfterHomeStaging.jpg";
import Example3Before from "../Images/Example3BeforeHomeStaging.jpg";
import Example3After from "../Images/Example3AfterHomeStaging.jpg";
import Example4Before from "../Images/curtain-effect-hero/BeforeBedroomStaging.png";
import Example4After from "../Images/curtain-effect-hero/AfterBedroomStaging.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Como Criar Home Staging Virtual</h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como criar home staging virtual
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-position">Fundador da Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Como posso criar home staging virtual?
            </h2>
            <p className="article-text">
              Com a Pedra, você pode criar home staging virtual em apenas alguns
              cliques.
              <p>
                Para começar, acesse o menu{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                selecione <span style={{ fontWeight: "500" }}>"Mobiliar"</span>.
                Em cerca de 2 ou 3 minutos, você terá sua imagem de home staging
                virtual pronta para baixar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1En}
              alt="Como criar home staging virtual – Exemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              Como criar home staging virtual?
            </h2>
            <p className="article-text">
              Para conseguir seu{" "}
              <Link className="article-link" to="/home-staging">
                home staging virtual
              </Link>
              , siga estes passos simples:
            </p>
            <ol>
              <li>
                Faça upload da imagem do ambiente que você deseja mobiliar
                virtualmente
              </li>
              <li>Selecione a imagem para começar a editar</li>
              <li>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                depois clique em{" "}
                <span style={{ fontWeight: "500" }}>"Mobiliar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2En}
              alt="Menu para criar home staging virtual"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Mobiliar"</span>{" "}
              você precisará seguir estes passos:
            </p>
            <ol>
              <li>
                Selecione a{" "}
                <span style={{ fontWeight: "500" }}>
                  posição dos elementos de staging
                </span>
                . Recomendamos usar "Desenhar" para indicar áreas específicas
                onde colocar móveis e elementos decorativos.
              </li>
              <li>
                Selecione o{" "}
                <span style={{ fontWeight: "500" }}>tipo de ambiente</span>.
                Isso garantirá que a Pedra coloque itens apropriados para cada
                espaço, como sofás para salas de estar ou camas para quartos.
              </li>
              <li>
                Selecione o <span style={{ fontWeight: "500" }}>estilo</span>.
                Escolha entre opções como "Moderno", "Contemporâneo",
                "Aconchegante" e outros, dependendo do ambiente e do
                público-alvo.
              </li>
              <li>
                Escolha o{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imagens a gerar
                </span>
                . Recomendamos escolher 2 ou 3 imagens para ter diferentes
                opções de design.
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame3En}
              alt="Opções para criar home staging virtual"
            />

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Dicas para melhor home staging virtual:
                <ul>
                  <li>
                    Certifique-se de que a fotografia mostre todo o layout do
                    ambiente claramente para uma mobília mais precisa
                  </li>
                  <li>
                    Capture quaisquer características únicas como janelas,
                    portas ou detalhes arquitetônicos
                  </li>
                  <li>
                    Use a opção "Desenhar" para indicar áreas específicas para
                    móveis e elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemplo de como criar home staging virtual"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Por que fazer home staging virtual?
            </h2>
            <p className="article-text">
              O home staging virtual é uma ferramenta poderosa para mostrar o
              potencial de qualquer espaço. Permite que você transforme
              ambientes vazios ou desatualizados em espaços atraentes e
              funcionais que ajudam os compradores a visualizar o verdadeiro
              potencial do imóvel.
            </p>
            <p className="article-text">
              Com um bom home staging virtual, você pode:
            </p>
            <ul>
              <li>
                Transformar espaços vazios ou desatualizados em ambientes
                atraentes e modernos
              </li>
              <li>Destacar a funcionalidade e o layout de cada ambiente</li>
              <li>
                Mostrar diferentes possibilidades de uso e organização dos
                espaços
              </li>
              <li>
                Ajudar os compradores a imaginar como poderiam viver e
                aproveitar cada ambiente
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemplos de home staging virtual
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de home staging virtual que podem ser
              aplicados a diferentes ambientes:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingPt;
