import React from "react";
import HowToDrawFloorPlan1 from "../Images/HowToDrawFloorPlan1.png";
import HowToDrawFloorPlan2 from "../Images/HowToDrawFloorPlan2.png";
import InfoIcon from "../Icons/InfoIcon";

function HowToDrawFloorPlan() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Como Desenhar Plantas Baixas</h1>

          <div className="title-secondary gray">
            Tutorial sobre como criar plantas baixas sem ser arquiteto
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              O que você pode fazer com nossa ferramenta de plantas baixas?
            </h2>
            <p className="article-text">
              Nossa ferramenta de plantas baixas permite que você crie plantas
              profissionais em minutos, sem nenhuma formação em arquitetura.
              Seja você um corretor de imóveis, designer de interiores ou
              proprietário, você pode facilmente criar plantas baixas precisas e
              visualmente atraentes.
              <p style={{ marginTop: "10px" }}>
                Criar plantas baixas é especialmente útil para anúncios de
                imóveis, pois ajuda os potenciais compradores a entenderem o
                layout e o fluxo do espaço antes de visitá-lo.
              </p>
            </p>

            <h2 className="article-subtitleh2">Como criar uma planta baixa</h2>
            <p className="article-text">
              Comece criando um novo projeto em nossa ferramenta de plantas
              baixas.
              <p style={{ marginTop: "10px" }}>
                Clique no botão{" "}
                <span style={{ fontWeight: "500" }}>"Novo Projeto"</span> para
                começar sua planta baixa.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToDrawFloorPlan2}
                alt="Como usar a ferramenta de plantas baixas – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Elementos Básicos: </span>
              Crie sua planta baixa em segundos
            </h2>
            <p style={{ marginTop: "10px" }}>
              A barra de ferramentas superior contém todos os elementos básicos
              necessários: cômodos, portas, janelas e móveis. Simplesmente
              clique em qualquer elemento para adicioná-lo ao seu canvas.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToDrawFloorPlan1}
                alt="Como usar a ferramenta de plantas baixas – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Opções de personalização:{" "}
              </span>
              Modifique os elementos da sua planta baixa
            </h2>
            <p className="article-text">
              Depois de posicionar os elementos, você pode facilmente
              personalizá-los conforme suas necessidades.
              <p style={{ marginTop: "10px" }}>
                Clique em qualquer elemento para acessar suas propriedades:
                <p style={{ marginTop: "10px" }}>
                  Para cômodos, você pode ajustar o tamanho, ocultar paredes,
                  cor e mostrar/ocultar o nome do cômodo. Para móveis, portas e
                  janelas, você pode rotacionar, redimensionar e reposicioná-los
                  conforme necessário.
                </p>
                <p style={{ marginTop: "10px" }}>
                  Para renomear um cômodo, simplesmente clique duas vezes no
                  nome e digite o novo.
                </p>
              </p>
            </p>

            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Dicas para melhores plantas baixas
                </h4>
                <p className="callout-p">
                  Para obter os melhores resultados com suas plantas baixas:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Comece pelos cômodos
                      </span>
                      . Crie a estrutura principal antes de adicionar paredes
                      internas e móveis.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Use medidas padrão
                      </span>
                      . Isso garante que sua planta baixa seja realista e em
                      escala.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Adicione móveis para melhor visualização
                      </span>
                      . Isso ajuda os visualizadores a entenderem melhor o
                      espaço.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>

            <h2 className="article-subtitleh2">
              Tutorial em vídeo: Criando uma planta baixa
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/Urt4OaV--Wg?si=QJicp9L_1wh6HrjK"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <h2 className="article-subtitleh2">Atalhos do teclado</h2>
            <p className="article-text">
              Para trabalhar com mais eficiência, use estes atalhos do teclado:
              <ul style={{ marginTop: "10px", marginLeft: "20px" }}>
                <li>Rotacionar elementos: Ctrl/Cmd + R</li>
                <li>Deletar elemento: Delete ou Backspace</li>
                <li>Copiar elemento: Ctrl/Cmd + C</li>
                <li>Colar elemento: Ctrl/Cmd + V</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToDrawFloorPlan;
