import React from "react";

const RedMultipleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
    >
      <path
        d="M16.2891 7.80163C13.9331 7.80163 12.2891 9.44564 12.2891 11.8016V21.7977L11.6131 21.9657C10.1771 22.3257 9.06114 21.6577 8.70114 20.2177L5.98513 9.35364C5.62513 7.91763 6.29313 6.80163 7.73314 6.44163L14.7171 4.69363C16.1531 4.00063 17.2691 5.00163 17.6291 6.44163L17.9692 7.80163H16.2931H16.2891ZM23.4892 9.40164H16.2891C14.8091 9.40164 13.8891 10.3216 13.8891 11.8016V23.0017C13.8891 24.4817 14.8091 25.4017 16.2891 25.4017H23.4892C24.9692 25.4017 25.8892 24.4817 25.8892 23.0017V11.8016C25.8892 10.3216 24.9692 9.40164 23.4892 9.40164Z"
        fill="#EB5757"
      />
    </svg>
  );
};

export default RedMultipleIcon;
