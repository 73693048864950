import React from "react";
import ReleaseMay2024Frame1 from "../Images/ReleaseMay2024Frame1.png";
import ReleaseMay2024Frame2 from "../Images/ReleaseMay2024Frame2.png";
import ReleaseMay2024Frame3 from "../Images/ReleaseMay2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseMay24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">MAIO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Limpar Ambientes e Remover Itens, Estilos de Renovação e Nova
            Interface
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Descubra aqui as novidades da Pedra do último mês de maio:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Limpeza de Ambientes e Remoção de Objetos Aprimorada
              </h2>
              Aperfeiçoamos nosso algoritmo para limpar ambientes e remover
              itens, garantindo resultados muito mais realistas. Isso é ideal
              para mostrar imóveis livres de itens desnecessários, permitindo
              que seus clientes apreciem totalmente a estética do imóvel. Aqui
              está um exemplo de como um espaço pode ser transformado:
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame1}
                  alt="Limpar espaços com IA"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Estilos de Renovação: Novos e Aprimorados
              </h2>
              Agora você pode escolher novos{" "}
              <Link className="article-link" to="/render">
                estilos de renovação
              </Link>{" "}
              e notar mais mudanças. Assim, você pode oferecer mais propostas de
              renovação de alta qualidade para seus clientes em segundos.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame2}
                  alt="Estilos de Renovação com IA"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Nova Interface: Mais Simples e Intuitiva
              </h2>
              Transformamos completamente nossa aplicação, deixando para trás a
              complexidade do design anterior. Antes, as imagens se acumulavam,
              dificultando manter uma perspectiva clara do projeto e tornando o
              processo mais lento. Agora, apresentamos um design onde cada
              imagem é meticulosamente ordenada e rotulada, tornando a edição de
              fotos não apenas fácil, mas também intuitiva.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseMay2024Frame3}
                alt="Nova interface da Pedra"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>

            <div>
              <li>
                Transformamos completamente nossa aplicação, deixando para trás
                a complexidade do design anterior. Antes, as imagens se
                acumulavam, dificultando manter uma perspectiva clara do projeto
                e tornando o processo mais lento. Agora, apresentamos um design
                onde cada imagem é meticulosamente ordenada e rotulada, tornando
                a edição de fotos não apenas fácil, mas também intuitiva.
              </li>
              <li>Ajuste o nível de criatividade nas renovações</li>
              <li>Adicione marca d'água a todas as suas imagens de uma vez</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseMay24Pt;
