// Home.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import BlueChairIcon from "./Icons/BlueChairIcon";
import BlueEyeIcon from "./Icons/BlueEyeIcon";
import BlueRulerIcon from "./Icons/BlueRulerIcon";
import BlueSmileIcon from "./Icons/BlueSmileIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import FloorPlanHeader from "./Images/FloorPlanHeader.png";
import FloorPlanFeature1 from "./Images/FloorPlanFeature1.png";
import FloorPlanFeature2 from "./Images/FloorPlanFeature2.png";
import FloorPlanFeature3 from "./Images/FloorPlanFeature3.png";
import FloorPlanFeature4 from "./Images/FloorPlanFeature4.png";
import FloorPlanStep1 from "./Images/FloorPlanStep1.png";
import FloorPlanStep2 from "./Images/FloorPlanStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function FloorPlanEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">Dibuja planos de planta</h1>
            <div className="section-first-text">
              Simple. Potente. Dibuja planos en segundos para posicionar mejor
              tus propiedades.
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#57A4EB",
                  border: "1px solid #57A4EB",
                }}
              >
                Prueba Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5,000+ profesionales inmobiliarios ya usan Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={FloorPlanHeader}
              alt="Programa para hacer planos de planta"
            ></img>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 5,000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Dibuja planos con Pedra</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueChairIcon />
                <h2 className="feature-title">
                  Agrega muebles al plano de planta
                </h2>
                <div className="feature-text">
                  Utiliza capas de muebles para mostrar lo que cabe en un
                  espacio y facilitar la comprensión de los planos de planta por
                  parte de tu audiencia
                </div>
              </div>
              <img
                src={FloorPlanFeature1}
                alt="Agregar muebles al plano de planta"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueEyeIcon />
                <div className="feature-title">
                  Muestra las medidas del plano con 1 clic
                </div>
                <div className="feature-text">
                  Decide si mostrar u ocultar las medidas en tus planos de
                  planta
                </div>
              </div>
              <img
                src={FloorPlanFeature2}
                alt="Mostrar medidas del plano de planta"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueSmileIcon />
                <h2 className="feature-title">
                  Herramienta de plano de planta fácil
                </h2>
                <div className="feature-text">
                  La herramienta de plano de planta de Pedra es la más fácil:
                  solo arrastra y suelta
                </div>
              </div>
              <img
                src={FloorPlanFeature3}
                alt="Herramienta de plano de planta fácil"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueRulerIcon />
                <h2 className="feature-title">
                  Plano de planta flexible pero simple
                </h2>
                <div className="feature-text">
                  El plano de planta de Pedra es simple pero flexible. Disfruta
                  de muchos tipos de capas diferentes: puertas, ventanas,
                  muebles y edita sus parámetros
                </div>
              </div>
              <img
                src={FloorPlanFeature4}
                alt="Programa de planos flexible pero simple"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">¿Cómo dibujar un plano de planta?</h2>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep1}
            alt="Cómo dibujar un plano de planta - Diagrama"
          />
        </div>

        <div className="description">
          <h5>PASO 1</h5>
          <h2>Crea un nuevo proyecto y selecciona "Dibujar plano de planta"</h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep2}
            alt="Cómo editar un plano de planta - Diagrama"
          />
        </div>

        <div className="description">
          <h5>PASO 2</h5>
          <h2>Haz clic en las capas para agregarlas al plano</h2>
          <div className="value-prop-text">
            Para editar el tamaño, color o propiedades de la capa, puedes
            seleccionarlas. Abrirá una barra donde podrás editar sus
            propiedades.
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button
          className="button"
          onClick={redirectToPage}
          style={{ backgroundColor: "#57A4EB", border: "1px solid #57A4EB" }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0077D4"; // Darker background color on hover
            e.target.style.borderColor = "#0077D4"; // Darker border color on hover
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#57A4EB"; // Restore original background color on mouse out
            e.target.style.borderColor = "#57A4EB"; // Restore original border color on mouse out
          }}
        >
          Prueba Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <div className="section-one-column-left">
        <div className="title-container">Preguntas y respuestas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">
                ¿Qué son los planos de plantas?
              </h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Los planos de plantas son representaciones gráficas que muestran
                una vista superior de un edificio, casa o estructura, como si
                estuvieras mirando desde arriba hacia abajo. Estos planos son
                utilizados principalmente en arquitectura, diseño de interiores,
                ingeniería civil y otros campos relacionados para visualizar la
                distribución y disposición de los espacios dentro de una
                estructura.
                <br />
                <br />
                Los planos de plantas suelen incluir detalles como las
                dimensiones de las habitaciones, la ubicación de las paredes,
                puertas, ventanas, muebles y otros elementos importantes para la
                planificación y construcción de un edificio. Son esenciales para
                la comunicación entre los diseñadores, arquitectos, ingenieros y
                contratistas involucrados en un proyecto de construcción.
                <br />
                <br />
                Imagina que estás mirando hacia abajo desde el cielo sobre un
                edificio. Los planos de plantas capturan esta perspectiva,
                mostrando la distribución de los espacios internos, las
                habitaciones, las paredes, las puertas, las ventanas y otros
                elementos estructurales. Esencialmente, estos planos ofrecen una
                vista en planta de un edificio, permitiendo una comprensión
                clara de cómo se organizarán y utilizarán los espacios.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Qué tipos de planos de planta hay?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Existen varios tipos de planos de plantas, cada uno con su
                  propio propósito y enfoque:
                </div>
                <h4>Planos de Planta Baja:</h4>Muestran la distribución de los
                espacios en el nivel del suelo. Esto incluye áreas como
                vestíbulos, salas de estar, cocinas, dormitorios y garajes.
                <br />
                <br />
                <h4>Planos de Plantas Superiores:</h4> Representan los niveles
                superiores de un edificio, como el segundo piso, el ático o
                cualquier otro nivel adicional. Estos planos son fundamentales
                para comprender la disposición vertical de un edificio.
                <br />
                <br />
                <h4>Planos de Planta de Mobiliario:</h4>Incorporan la ubicación
                de los muebles dentro de los espacios. Estos planos ayudan a los
                diseñadores de interiores y propietarios a visualizar cómo se
                verá el espacio una vez que esté amueblado.
                <br />
                <br />
                <h4>Planos de Distribución Eléctrica y de Fontanería:</h4>
                Detallan la ubicación de los puntos de acceso eléctrico y de
                fontanería en el edificio. Son esenciales para garantizar una
                instalación adecuada de los sistemas eléctricos y de fontanería.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Cuál es el proceso de creación de planos?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Crea un nuevo proyecto:</h4>Comienza a crear un plano en la
                sección de "Dibujar plano de planta".
                <br />
                <br />
                <h4>Agrega capas:</h4>Añade todas las capas que necesites y
                edita las dimensiones, color o propiedades de la capa.
                <br />
                <br />
                <h4>Amueblalo:</h4>Agrega muebles al plano de planta para
                mostrar de forma visual el espacio disponible y cómo se podría
                amueblar.
                <br />
                <br />
                <h4>Descarga tu plano online:</h4>Termina los últimos detalles
                como puertas, ventanas, etc. y descarga tu plano.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">
                ¿Es gratis el programa para hacer planos?
              </h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Tras el periodo de prueba gratuita el precio es 29€ más IVA al
                mes.
                <br />
                <br />
                No tiene permanencia, así que puedes darte de baja cuando ya no
                lo necesites.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloorPlanEs;
