import React from "react";
import ReleaseSep2024Frame1 from "../Images/ReleaseSep2024Frame1.png";
import ReleaseSep2024Frame2 from "../Images/ReleaseSep2024Frame2.png";
import ReleaseSep2024Frame3 from "../Images/ReleaseSep2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseSep24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">SETEMBRO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Renovação e mobiliamento em um clique, resultados aprimorados
            e preservação de espaços
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Neste setembro, trazemos melhorias para tornar o uso da Pedra mais
              eficiente, permitindo que você mostre o potencial dos seus imóveis
              facilmente:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Renove e mobilie em 1 clique
              </h2>
              Anteriormente, mobiliar e renovar um espaço exigia duas etapas.
              Agora, isso não é mais necessário – você pode gerar propostas de
              renovação que mobiliam o espaço em uma única vez.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame1}
                  alt="Exemplo de renovação e mobiliamento em um clique"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Melhores resultados em "Mobiliar" com modo "Auto"
              </h2>
              Aprimoramos o home staging virtual da Pedra para ficar melhor do
              que nunca. Agora, quando você clica em <strong>"Mobiliar"</strong>{" "}
              no modo <strong>"Auto"</strong>, você verá resultados muito
              melhores: paredes decoradas, hiper-realismo e móveis posicionados
              corretamente.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame2}
                  alt="Exemplo de resultados aprimorados no modo Auto Mobiliar"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Melhor preservação do espaço ao esvaziar ambientes
              </h2>
              Ao usar <strong>"Esvaziar ambiente"</strong>, você notará que a
              arquitetura do espaço é melhor preservada.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseSep2024Frame3}
                alt="Exemplo de melhor preservação do espaço ao esvaziar um ambiente"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>

            <div>
              <li>
                Melhores resultados em <strong>"Converter para HD"</strong>
              </li>
              <li>
                Melhor posicionamento dos móveis ao usar{" "}
                <strong>"Mobiliar"</strong> no modo <strong>"Auto"</strong>
              </li>
              <li>Resultados aprimorados ao adicionar céu azul</li>
              <li>
                Remoção de objetos aprimorada (objetos são removidos
                corretamente mantendo a qualidade da imagem)
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseSep24Pt;
