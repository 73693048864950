// RealEstateAgents.js
import React from "react";
import Frame1RealEstateAgents from "./Images/Frame 1 RealEstateAgents.png";
import { ReactComponent as ChairIcon } from "./Images/Chair icon.svg";
import { ReactComponent as CleanIcon } from "./Images/Clean icon.svg";
import { ReactComponent as BrandIcon } from "./Images/Brand icon.svg";
import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import Frame2RealEstateAgents from "./Images/Frame 2 RealEstateAgents.jpeg";
import Frame3RealEstateAgents from "./Images/Frame 3 RealEstateAgents.png";
import Frame4RealEstateAgents from "./Images/Frame 4 RealEstateAgents.png";
import ArrowIcon from "./Icons/ArrowIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RealEstateAgents() {
  return (
    <div className="body-real-estate">
      <div>
        <section className="section-first-wrapper">
          <section className="section-first">
            <div className="section-first-text-container">
              <h1 className="section-first-title">
                Accelerate Real Estate sales
              </h1>
              <div className="section-first-text">
                Attract buyers, book more viewings, and sell faster. Pedra helps
                you showcase the potential of every property in one-click.
              </div>
              <div className="section-first-button-container">
                <button className="button-small" onClick={redirectToPage}>
                  Try Pedra <ArrowIcon />
                </button>
              </div>
            </div>
            <div className="header-picture">
              <img
                src={Frame1RealEstateAgents}
                alt="Tool For Real Estate Agents – Software Screenshot"
              ></img>
            </div>
          </section>
        </section>

        <div style={{ borderTop: "1px solid #EEEEEE", height: "20px" }}></div>

        <div className="section-one-column-left">
          <div
            className="title-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignText: "left",
            }}
          >
            <div className="">A Real Estate marketing tool like no other.</div>
            <div className="title-secondary">
              Pedra makes it simple for any Real Estate company to improve their
              property listings.
            </div>
            <div className="title-secondary">
              No special skills required — just upload your images and start!
            </div>
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <RenovateIcon />
              <div className="feature-title">Renovate with AI</div>
              <div className="feature-text">
                Generate AI renders in one click. Buyers want to see renovation
                proposals before making a purchase. Pedra offers cheap, fast and
                high quality renders to show your customer renovation ideas.
              </div>
            </div>
            <img
              src={FrameRenovateRealEstateAgents}
              alt="Pedra Render Software Screenshot – Real Estate Features"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="title-container">Simple but powerful</div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <ChairIcon />
              <div className="feature-title">Home staging</div>
              <div className="feature-text">
                Sell a dream. Add furniture to empty rooms with one click.
                Clients struggle to understand the space constrains.
              </div>
            </div>
            <img
              src={Frame2RealEstateAgents}
              alt="Pedra Render Software Screenshot of Virtual Home Staging"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-small">
                <div className="feature-text-container">
                  <CleanIcon />
                  <div className="feature-title">Keep it clean</div>
                  <div className="feature-text">
                    Use our AI tools to remove parts from images.
                  </div>
                </div>
                <img
                  src={Frame4RealEstateAgents}
                  alt="Pedra Render Software Screenshot of Object Removal"
                />
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-small">
                <div className="feature-text-container">
                  <BrandIcon />
                  <div className="feature-title">Protect your brand</div>
                  <div className="feature-text">
                    Use our canvas to add watermarks to images.
                  </div>
                </div>
                <img
                  src={Frame3RealEstateAgents}
                  alt="Pedra Render Software Screenshot of Real Estate Watermark Addition"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              “Using Pedra to edit our images is helping us get more visits!”
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src="/fincas-lluria.png"
                alt="Logo of Fincas Lluria for Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Max Vives-Fierro</div>
                <div className="quote-author-company">
                  Fincas Llúria Real Estate
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mid-break"> </div>

        <div className="centered-heading">
          <button className="button" onClick={redirectToPage}>
            Try Pedra
          </button>
        </div>
      </div>
    </div>
  );
}

export default RealEstateAgents;
