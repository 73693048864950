import React from "react";
import { Link } from "react-router-dom";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import Article21Frame1 from "../Images/Article21Frame1.png";
import Article21Frame2 from "../Images/Article21Frame2.png";
import Article21Frame3 from "../Images/Article21Frame3.png";
import Article21Frame4 from "../Images/Article21Frame4.png";

function BlogArticle21Pt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">O que é Design de Ambientes?</h1>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fundador da Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador da Pedra
                </div>
              </div>
            </div>
            <article className="article-text">
              <p>
                Design de ambientes é uma disciplina criativa e técnica que se
                concentra no planejamento e transformação de espaços, tanto
                interiores quanto exteriores, com o objetivo de torná-los
                funcionais, esteticamente agradáveis e adequados para seus
                usuários. Envolve o uso estratégico de elementos como móveis,
                iluminação, materiais e cores para criar ambientes que
                influenciem positivamente o bem-estar, a produtividade e o
                conforto das pessoas. Este campo abrange desde a decoração de
                interiores residenciais até a criação de grandes projetos
                comerciais e espaços públicos.
              </p>
              <p>
                Além da mera estética, o design de ambientes considera aspectos
                psicológicos, sociais e culturais, garantindo que os espaços
                tenham personalidade e propósito. Por exemplo, um ambiente de
                trabalho pode ser projetado para fomentar a colaboração e a
                criatividade, enquanto uma casa pode ser projetada para
                maximizar o conforto e a funcionalidade na vida diária.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article21Frame1}
                  alt="Design de ambiente de escritório"
                />
              </div>

              <h2 className="article-subtitleh2">
                A Importância do Designer de Ambientes
              </h2>
              <p>
                O designer de ambientes desempenha um papel essencial na
                transformação dos espaços. Este profissional não apenas tem a
                capacidade de embelezar um lugar, mas também de adaptá-lo para
                atender às necessidades específicas das pessoas que o utilizam.
                A partir de uma abordagem técnica e estética, o designer
                equilibra aspectos práticos com visuais, garantindo que o
                ambiente tenha um impacto positivo em seus usuários.
              </p>
              <p>
                Seu trabalho vai além de escolher cores ou móveis; eles devem
                considerar fatores como iluminação natural, acústica, circulação
                do espaço e uso eficiente dos materiais. Além disso, seu
                trabalho está intimamente ligado à sustentabilidade, pois são
                responsáveis por propor soluções ecológicas e duráveis que
                reduzam o impacto ambiental.
              </p>
              <p>
                Em um mundo onde o ambiente em que vivemos e trabalhamos
                influencia diretamente nossa qualidade de vida, o designer de
                ambientes é fundamental para criar espaços que melhorem o
                bem-estar, fomentem a produtividade e reflitam a identidade e
                cultura de quem os habita.
              </p>
              <p>
                Você pode modificar o design do ambiente por conta própria com
                nossa{" "}
                <Link to="/interiordesign" className="article-link">
                  ferramenta de design de interiores com IA
                </Link>
                .
              </p>

              <h2 className="article-subtitleh2">
                Como Fazer Design de Ambientes?
              </h2>
              <p>
                Design de ambientes é um processo criativo e técnico que combina
                arte e funcionalidade para transformar espaços, tornando-os mais
                atraentes, confortáveis e úteis. Criar um design de ambiente
                bem-sucedido envolve seguir um conjunto bem estruturado de
                etapas, desde a concepção inicial da ideia até a implementação
                final do projeto. Se você está interessado em projetar
                ambientes, aqui explicamos o passo a passo para realizar este
                processo:
              </p>

              <h3 className="article-subtitle">
                Definir os Objetivos do Projeto
              </h3>
              <p>
                O primeiro passo para um bom design de ambientes é entender
                claramente os objetivos do espaço a ser transformado. É crucial
                saber quem utilizará o espaço, quais são suas necessidades e
                desejos, e qual a principal função que o ambiente deve cumprir.
              </p>
              <h4 className="article-subtitle">Perguntas-chave:</h4>
              <ul>
                <li>
                  Qual é o propósito do espaço? É uma área residencial,
                  comercial ou recreativa?
                </li>
                <li>
                  Que tipo de atividades serão realizadas neste espaço?
                  (descanso, trabalho, entretenimento, etc.)
                </li>
                <li>
                  Quem o utilizará e quais são suas necessidades? (crianças,
                  adultos, grupos, pessoas com mobilidade reduzida, etc.)
                </li>
                <li>
                  Qual estilo ou atmosfera você deseja criar? (moderno, rústico,
                  minimalista, aconchegante, formal, etc.)
                </li>
              </ul>
              <p>
                Esta análise inicial é fundamental para tomar decisões mais
                informadas de design ao longo do projeto.
              </p>

              <h3 className="article-subtitle">Estudar o Espaço</h3>
              <p>
                O próximo passo é estudar o espaço disponível. Aqui é importante
                fazer uma análise minuciosa das características físicas da área
                em que você estará trabalhando, seja ela interior ou exterior.
              </p>
              <h4 className="article-subtitle">Fatores a considerar:</h4>
              <ul>
                <li>
                  Tamanho e dimensões: Faça medições precisas do espaço,
                  considerando altura, largura e profundidade. Isso ajudará a
                  planejar a disposição adequada de móveis e elementos.
                </li>
                <li>
                  Layout atual: Observe como o espaço está organizado
                  atualmente. Existem problemas de fluxo ou questões de uso da
                  área que precisam ser corrigidas?
                </li>
                <li>
                  Iluminação: A luz natural e artificial é um dos fatores mais
                  importantes no design de ambientes. Avalie as fontes de luz
                  natural, a direção de onde vêm e como afetam o espaço.
                  Determine também quais tipos de iluminação artificial serão
                  necessários.
                </li>
                <li>
                  Cores e materiais existentes: Quais cores predominam no espaço
                  e como afetam a atmosfera? Avalie os materiais atuais e decida
                  se devem ser mantidos, modificados ou substituídos.
                </li>
              </ul>
              <p>
                Esta análise permitirá identificar os pontos fortes e limitações
                do espaço, o que será essencial ao desenvolver sua proposta de
                design.
              </p>

              <h3 className="article-subtitle">
                Desenvolver um Conceito Criativo
              </h3>
              <p>
                Com as informações coletadas, o próximo passo é desenvolver um
                conceito criativo que reflita tanto a funcionalidade quanto a
                estética desejadas. Este conceito deve estar alinhado com os
                objetivos do cliente ou do espaço e guiará todas as decisões de
                design que você tomar.
              </p>
              <h4 className="article-subtitle">
                Como desenvolver um conceito?
              </h4>
              <ul>
                <li>
                  Inspire-se em referências visuais: Você pode criar um painel
                  de humor ou inspiração que inclua imagens, cores, texturas e
                  estilos que ajudem a definir a atmosfera geral do espaço.
                </li>
                <li>
                  Pense na experiência do usuário: Que sensações você quer que o
                  espaço evoque? Por exemplo, um espaço de trabalho deve ser
                  produtivo e organizado, enquanto uma área de descanso deve ser
                  relaxante e aconchegante.
                </li>
                <li>
                  Escolha um estilo de design: Definir um estilo claro
                  (minimalista, industrial, contemporâneo, vintage, etc.)
                  ajudará a guiar a escolha de materiais, cores e móveis. O
                  estilo deve ser consistente com as necessidades do usuário e o
                  contexto do espaço.
                </li>
              </ul>
              <p>
                Este conceito criativo será a espinha dorsal do projeto,
                servindo como referência à medida que você avança no design.
              </p>

              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article21Frame2}
                  alt="Design de ambiente criativo"
                />
              </div>

              <h3 className="article-subtitle">Elaborar um Plano de Design</h3>
              <p>
                Uma vez que você tenha um conceito sólido, o próximo passo é
                elaborar um plano detalhado de design. Isso envolve o
                planejamento específico de cada
              </p>
              <h4 className="article-subtitle">
                O que um plano de design inclui?
              </h4>
              <ul>
                <li>
                  Layout do espaço: Planeje como os diferentes elementos serão
                  organizados dentro do espaço. Defina os móveis que você
                  precisa e como eles serão posicionados para garantir um fluxo
                  adequado e uso eficiente da área.
                </li>
                <li>
                  Paleta de cores: Selecione uma gama de cores que seja
                  consistente com o conceito criativo. As cores têm um grande
                  impacto na atmosfera do espaço, então devem ser escolhidas
                  cuidadosamente para alcançar o efeito desejado.
                </li>
                <li>
                  Escolha de materiais: Escolha os materiais que serão usados
                  para pisos, paredes, móveis e elementos decorativos. Considere
                  fatores como durabilidade, manutenção e harmonia estética.
                </li>
                <li>
                  Iluminação: Planeje a iluminação do ambiente, considerando
                  tanto a luz natural quanto artificial. É importante escolher
                  luminárias que não apenas atendam a uma função prática, mas
                  também contribuam para o ambiente desejado.
                </li>
                <li>
                  Decoração e detalhes: Elementos decorativos, como arte,
                  plantas, tecidos e acessórios, são o que dará personalidade ao
                  espaço. Esses detalhes devem ser cuidadosamente selecionados
                  para complementar o design geral.
                </li>
              </ul>
              <p>
                Este plano deve ser muito detalhado para que seja facilmente
                executável durante a fase de implementação. Esta etapa é simples
                e não requer muita experiência; você pode desenvolver sua
                estratégia de design de ambiente usando{" "}
                <Link to="/interiordesign" className="article-link">
                  ferramentas de design de interiores com IA
                </Link>
                .
              </p>

              <h3 className="article-subtitle">
                Selecionar e Adquirir Materiais e Móveis
              </h3>
              <p>
                Uma vez que o plano de design esteja definido, é hora de
                selecionar e adquirir os materiais e móveis que serão usados no
                projeto. Esta etapa requer atenção ao orçamento, qualidade e
                prazos de entrega.
              </p>
              <h4 className="article-subtitle">
                Dicas para selecionar materiais:
              </h4>
              <ul>
                <li>
                  Qualidade e durabilidade: Certifique-se de escolher materiais
                  que não apenas sejam esteticamente agradáveis, mas também
                  resistentes e adequados para o uso pretendido do espaço.
                </li>
                <li>
                  Harmonia estética: Os materiais devem complementar o estilo
                  geral do design. Por exemplo, móveis de madeira e tecidos
                  naturais podem ser adequados para um design rústico, enquanto
                  aço e vidro seriam mais adequados para um ambiente moderno.
                </li>
                <li>
                  Orçamento: É essencial respeitar o orçamento definido no
                  projeto. Você pode encontrar alternativas mais econômicas sem
                  sacrificar qualidade ou design.
                </li>
              </ul>
              <p>
                A compra de materiais deve ser planejada cuidadosamente para
                garantir que tudo esteja disponível quando a execução começar.
              </p>

              <h3 className="article-subtitle">Execução do Design</h3>
              <p>
                Com todos os materiais e elementos prontos, é hora de passar
                para a fase de execução do design. Esta é onde o espaço
                realmente começa a se transformar. Nesta etapa, os móveis são
                instalados, acabamentos são aplicados e a decoração final é
                organizada.
              </p>
              <h4 className="article-subtitle">Passos-chave na execução:</h4>
              <ul>
                <li>
                  Supervisão do projeto: Se você está trabalhando com
                  contratistas ou fornecedores, é importante supervisionar o
                  processo para garantir que tudo seja executado de acordo com o
                  plano de design. Isso inclui a instalação de móveis,
                  iluminação, acabamentos, etc.
                </li>
                <li>
                  Montagem e decoração: Uma vez que os elementos principais
                  estejam em seu lugar, é hora de adicionar os detalhes
                  decorativos. Acessórios como cushions, arte, plantas e tapetes
                  são os toques finais que personalizam e completam o espaço.
                </li>
                <li>
                  Ajustes e ajustes: À medida que o design se forma, pequenas
                  modificações ou ajustes possam surgir. Seja mudar a
                  localização de um móvel ou ajustar a iluminação, é importante
                  ser flexível para garantir que o resultado final seja
                  perfeito.
                </li>
              </ul>
              <p>
                A execução pode levar tempo e exigir atenção constante aos
                detalhes, mas é a fase mais emocionante onde todo o trabalho
                anterior se torna realidade.
              </p>

              <h3 className="article-subtitle">
                Revisão Final e Entrega do Projeto
              </h3>
              <p>
                Finalmente, depois de executar, é importante fazer uma revisão
                final do espaço para garantir que tudo esteja em ordem e atenda
                aos objetivos estabelecidos no início. Neste ponto, uma
                caminhada é realizada com o cliente para avaliar se há aspectos
                que precisam ser ajustados.
              </p>
              <h4 className="article-subtitle">Considerações finais:</h4>
              <ul>
                <li>
                  Verificação de funcionalidade: Certifique-se de que o espaço é
                  funcional e confortável. Os móveis estão bem posicionados? A
                  iluminação é adequada?
                </li>
                <li>
                  Estética e harmonia: Verifique se o ambiente reflete o
                  ambiente desejado e se todos os elementos se integram bem no
                  todo.
                </li>
                <li>
                  Feedback do cliente: Escute o feedback do cliente e faça os
                  ajustes finais necessários para garantir que o espaço atenda
                  às suas expectativas.
                </li>
              </ul>
              <p>
                Uma vez que os ajustes sejam concluídos, o projeto está pronto
                para entrega, e o espaço é otimizado e projetado de acordo com
                os objetivos iniciais.
              </p>
              <p>
                Se você também quiser prepará-lo para venda, você pode aplicar{" "}
                <Link to="/home-staging-virtual" className="article-link">
                  home staging virtual
                </Link>{" "}
                para melhorar sua propriedade.
              </p>

              <h2 className="article-subtitleh2">
                Habilidades Chave de um Designer de Ambientes
              </h2>
              <p>
                O sucesso de um designer de ambientes depende de uma combinação
                única de habilidades criativas, técnicas e sociais. Essas
                competências permitem que eles abordem projetos de diferentes
                perspectivas, garantindo que os espaços projetados não apenas
                sejam atraentes, mas também funcionais e adaptados às
                necessidades dos usuários. Abaixo estão as habilidades chave que
                um designer de ambientes deve possuir:
              </p>

              <h3 className="article-subtitle">Criatividade e Inovação</h3>
              <p>
                Criatividade é o núcleo de todo designer de ambientes. Sua
                capacidade de imaginar soluções estéticas e funcionais permite
                que eles transformem espaços comuns em lugares únicos cheios de
                personalidade. Um designer criativo não se limita a seguir
                tendências; em vez disso, eles são capazes de gerar novas
                ideias, combinando estilos, materiais e conceitos para moldar
                espaços que resolvem problemas práticos e expressam uma visão
                original.
              </p>
              <p>
                A inovação também é vital nesta área, pois projetos de design
                frequentemente requerem soluções para problemas complexos. Seja
                otimizando o uso de pequenos espaços, aproveitando a luz natural
                ou integrando tecnologias modernas, o designer deve estar ciente
                de novas técnicas e abordagens que podem melhorar o resultado
                final.
              </p>

              <h3 className="article-subtitle">
                Conhecimento Técnico e Ferramentas Digitais
              </h3>
              <p>
                Embora a criatividade seja essencial, um designer de ambientes
                deve também possuir um profundo conhecimento técnico. Isso
                inclui entender aspectos estruturais, elétricos e acústicos de
                espaços, bem como estar familiarizado com materiais e suas
                propriedades. Um designer deve ser capaz de ler e interpretar
                plantas, trabalhar com construtores e fazer decisões informadas
                sobre o uso de cores, texturas e acabamentos que garantem a
                durabilidade e funcionalidade do design.
              </p>
              <p>
                É importante ter boas{" "}
                <Link to="/interiordesign" className="article-link">
                  ferramentas de design de interiores
                </Link>
                . Essas ferramentas não apenas facilitam a criação de plantas
                detalhadas, mas também permitem gerar representações realistas
                de ambientes antes de serem construídos.
              </p>

              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article21Frame3}
                  alt="Ferramentas do designer de ambientes"
                />
              </div>

              <h3 className="article-subtitle">
                Comunicação e Trabalho em Equipe
              </h3>
              <p>
                Design de ambientes é uma disciplina colaborativa que requer
                comunicação eficaz entre várias partes: clientes, construtores,
                arquitetos, engenheiros e outros designers. Um designer de
                ambientes bem-sucedido deve ser capaz de ouvir atentamente as
                necessidades e desejos dos clientes, traduzir essas expectativas
                em conceitos concretos e comunicar claramente sua visão para
                toda a equipe envolvida no projeto.
              </p>
              <p>
                O trabalho em equipe é fundamental, pois o designer raramente
                trabalha sozinho. Coordenar esforços com outros profissionais
                garante que o design não apenas seja esteticamente agradável,
                mas também estruturalmente sólido e funcional. Além disso, é
                importante para o designer lidar com críticas e feedback
                construtivamente, adaptando seu trabalho quando necessário para
                atender aos objetivos do projeto.
              </p>
              <p>
                Excelente comunicação também implica a capacidade de defender e
                argumentar decisões de design para clientes ou colegas,
                garantindo que as soluções propostas sejam entendidas e
                apreciadas. Empatia e adaptabilidade são chaves para gerenciar
                expectativas e resolver conflitos que possam surgir durante o
                processo criativo.
              </p>

              <h2 className="article-subtitleh2">
                Tipos de Design de Ambientes
              </h2>
              <p>
                Design de ambientes abrange várias áreas, desde espaços físicos
                interiores até exteriores. Cada tipo de design de ambientes tem
                seus desafios, princípios e abordagens criativas. Abaixo,
                exploramos os dois principais tipos de design de ambientes:
                interior e exterior, explicando em detalhes as características e
                particularidades de cada um.
              </p>
              <p>
                Para ambos os designs interior e exterior, você pode usar nossa{" "}
                <Link to="/interiordesign" className="article-link">
                  ferramenta de design com IA
                </Link>{" "}
                para modificar facilmente os espaços e personalizá-los à sua
                preferência.
              </p>

              <h3 className="article-subtitle">Design de Interiores</h3>
              <p>
                Design de interiores se concentra na transformação dos espaços
                interiores de edificações, sejam residenciais, comerciais ou
                institucionais. Este tipo de design busca combinar
                funcionalidade e estética, garantindo que os ambientes sejam
                confortáveis, práticos e visualmente atraentes. Dentro deste
                campo, aspectos fundamentais como distribuição de espaço,
                escolha de materiais, iluminação, móveis e elementos decorativos
                são considerados.
              </p>
              <h4 className="article-subtitle">Principais características:</h4>
              <ul>
                <li>
                  Funcionalidade e conforto: Um dos pilares do design de
                  interiores é otimizar espaços para uso diário. Os ambientes
                  devem ser confortáveis, práticos e respondem às necessidades
                  de quem os habita, seja uma casa, um escritório ou uma loja.
                </li>
                <li>
                  Estética e estilo: O design de interiores também desempenha um
                  papel crucial na estabelecimento do estilo e identidade visual
                  de um espaço. Isso inclui a seleção de cores, texturas, móveis
                  e acessórios que refletem um tema específico, seja moderno,
                  rústico, minimalista ou clássico.
                </li>
                <li>
                  Psicologia do espaço: Um bom design de interiores leva em
                  consideração como cores, disposição de móveis e iluminação
                  afetam a disposição e bem-estar das pessoas. Por exemplo, tons
                  quentes e materiais naturais podem gerar sensações de
                  relaxamento e conforto, enquanto um espaço muito saturado ou
                  mal distribuído pode causar desconforto.
                </li>
              </ul>
              <h4 className="article-subtitle">Exemplos de aplicação:</h4>
              <ul>
                <li>Casas: design de sala, cozinha, quarto, etc.</li>
                <li>
                  Escritórios: otimização de espaços colaborativos ou privados.
                </li>
                <li>
                  Espaços comerciais: lojas, restaurantes, hotéis que buscam
                  transmitir uma identidade e oferecer uma experiência única aos
                  usuários.
                </li>
              </ul>

              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article21Frame4}
                  alt="Design de ambiente interior"
                />
              </div>

              <h3 className="article-subtitle">Design de Exteriores</h3>
              <p>
                Design de exteriores, também conhecido como paisagismo, abrange
                o planejamento e criação de espaços abertos como jardins,
                parques, varandas ou quadras públicas. Este tipo de design se
                concentra na harmonia integrada de elementos naturais (plantas,
                água, pedras) com construções (pavimentos, mobiliário urbano,
                esculturas) para criar ambientes externos atraentes e
                funcionais.
              </p>
              <h4 className="article-subtitle">Principais características:</h4>
              <ul>
                <li>
                  Interação com o ambiente natural: Um bom design de exteriores
                  leva em consideração a vegetação local, condições climáticas e
                  topografia do terreno para criar espaços sustentáveis e
                  visualmente agradáveis. O uso de plantas nativas e
                  planejamento inteligente de água (irrigação eficiente) são
                  aspectos chave para respeitar o ambiente natural.
                </li>
                <li>
                  Funcionamento dos espaços: Como o design de interiores, o
                  design de exteriores deve atender a uma função. Jardins,
                  varandas e quadras devem ser espaços onde as pessoas podem
                  desfrutar de atividades ao ar livre, seja para lazer,
                  socialização ou relaxamento. Portanto, o planejamento de
                  caminhos, áreas de descanso e sombras é essencial.
                </li>
                <li>
                  Equilíbrio entre construído e natural: Um desafio comum no
                  design de exteriores é encontrar o equilíbrio entre natureza e
                  construções humanas. Elementos como fontes, caminhos e
                  estruturas devem ser integrados sem quebrar a harmonia visual
                  com o ambiente natural.
                </li>
              </ul>
              <h4 className="article-subtitle">Exemplos de aplicação:</h4>
              <ul>
                <li>
                  Jardins residenciais: áreas verdes projetadas para diversão
                  pessoal ou familiar.
                </li>
                <li>
                  Espaços públicos: parques e quadras que convidam à interação
                  social e lazer.
                </li>
                <li>
                  Paisagismo comercial: áreas externas de prédios corporativos
                  ou centros comerciais que buscam projetar uma imagem atraente.
                </li>
              </ul>

              <p>
                Se você está interessado em design de ambientes, recomendamos
                que tente nossa{" "}
                <Link to="/interiordesign" className="article-link">
                  ferramenta de design de interiores com IA
                </Link>
                .
              </p>
            </article>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Olá! Eu sou Felix Ingla, o fundador de{" "}
              <Link className="article-link" to="/en">
                Pedra
              </Link>
              , uma aplicação web inovadora para home staging virtual e
              fotografia imobiliária que está transformando a maneira como as
              propriedades são apresentadas no mundo digital.
            </p>
            <p>
              Se você gostaria de me conectar e está interessado em discutir
              tecnologia imobiliária, entre em contato comigo através do meu{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . Você também pode ler mais{" "}
              <Link className="article-link" to="/about">
                sobre Pedra aqui
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle21Pt;
