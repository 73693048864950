import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeSpaceExample1 from "../Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "../Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "../Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "../Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "../Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "../Images/curtain-effect/AfterSpaceStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLocalFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment créer des rendus d'espaces commerciaux
          </h1>

          <div className="title-secondary gray">
            Tutoriel sur la création de rendus d'espaces commerciaux
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Pourquoi utiliser les outils de rendu d'espaces commerciaux de
              Pedra ?
            </h2>
            <p className="article-text">
              Les outils de Pedra vous permettent de visualiser les
              transformations d'espaces commerciaux en quelques secondes, aidant
              les clients potentiels ou les investisseurs à voir le véritable
              potentiel de l'espace.
              <p style={{ marginTop: "10px" }}>
                Les rénovations et le home staging d'espaces commerciaux sont
                essentiels pour attirer les locataires ou les acheteurs. Pouvoir
                montrer le potentiel d'un espace commercial vide ou désuet peut
                faire une différence significative dans la rapidité de
                conclusion des transactions.
              </p>
              <p style={{ marginTop: "10px" }}>
                Avec Pedra, vous n'avez plus besoin de designers d'intérieur
                spécialisés ou d'experts en rendu 3D pour créer des
                visualisations commerciales convaincantes.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Comment créer un rendu d'espace commercial ?
            </h2>
            <p className="article-text">
              Commencez par télécharger des photos claires de l'espace
              commercial existant que vous souhaitez transformer.
              <p style={{ marginTop: "10px" }}>
                Sélectionnez vos images et cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, puis
                choisissez l'option{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Comment utiliser l'outil de rendu pour créer des rendus d'espaces commerciaux – Capture d'écran du logiciel Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Transformation rapide :{" "}
              </span>
              Générer un rendu d'espace commercial rapidement
            </h2>
            <p style={{ marginTop: "10px" }}>
              L'outil <span style={{ fontWeight: "500" }}>"Renovation"</span>{" "}
              transformera votre espace commercial en environ 25 secondes.
              <p>
                Pour une mise à jour subtile, choisissez le style "Minimaliste"
                avec une créativité "Faible". Cela maintiendra l'agencement de
                base tout en modernisant les finitions et les éléments.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Comment utiliser l'outil de rendu pour créer des rendus d'espaces commerciaux – Capture d'écran du logiciel Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personnaliser votre rendu d'espace commercial
            </h2>
            <p className="article-text">
              Pedra offre de nombreuses options de personnalisation pour les
              rendus d'espaces commerciaux.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Choisissez parmi différents styles, spécifiez le type
                  d'espace, contrôlez l'intensité des changements et décidez de
                  préserver ou non les éléments existants comme les fenêtres ou
                  les caractéristiques structurelles.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Comment utiliser l'outil de rendu pour créer des rendus d'espaces commerciaux – Capture d'écran du logiciel Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 1 – </span>
              Spécifier le type d'espace commercial
            </h3>
            <p className="article-text">
              Dans le champ type d'espace, saisissez le type spécifique d'espace
              commercial avec lequel vous travaillez (par exemple, restaurant,
              bar, cafétéria, magasin de détail, espace de bureau, etc.).
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Vous pouvez également télécharger des images de référence
                  d'espaces similaires que vous aimez.
                </span>{" "}
                Cela vous permet de créer des rendus qui correspondent à une
                inspiration spécifique ou à l'esthétique préférée de votre
                client.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 2 – </span>
              Intensité de la rénovation
            </h3>
            <p className="article-text">
              Choisissez entre les niveaux de créativité Faible, Moyen, Élevé et
              Très Élevé pour votre transformation d'espace commercial.
              <p style={{ marginTop: "10px" }}>
                Les niveaux Faible et Moyen mettront à jour les finitions et les
                éléments tout en conservant l'agencement de base.
              </p>
              <p style={{ marginTop: "10px" }}>
                Les niveaux Élevé et Très Élevé peuvent complètement réimaginer
                l'espace avec de nouvelles dispositions, configurations de
                mobilier et changements architecturaux.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 3 – </span>
              Configurer les paramètres de préservation
            </h3>
            <p className="article-text">
              Choisissez de préserver les fenêtres et les éléments structurels,
              ce qui est particulièrement important pour les espaces commerciaux
              avec des caractéristiques architecturales spécifiques ou des
              exigences d'éclairage. Cela garantit que votre rendu conserve les
              éléments pratiques tout en actualisant l'esthétique.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 4 – </span>
              Générer votre rendu d'espace commercial
            </h2>
            <p>
              Après avoir sélectionné vos préférences, générez le rendu de
              l'espace commercial. Les rendus standard prennent environ 15
              secondes, tandis que la préservation d'éléments spécifiques peut
              prendre un peu plus de temps.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Conseils pour de meilleurs rendus d'espaces commerciaux
                </h4>
                <p className="callout-p">
                  Pour obtenir les rendus d'espaces commerciaux les plus
                  réalistes et utiles :
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Assurez-vous que "Meubler" est activé
                      </span>{" "}
                      avant de cliquer sur générer l'image. Cela garantit que
                      votre espace sera correctement meublé selon son type.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Soyez précis avec le type d'espace
                      </span>
                      . Au lieu de simplement "restaurant", précisez "restaurant
                      italien moderne" ou "café décontracté" pour des résultats
                      plus ciblés.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Générez plusieurs versions
                      </span>
                      . Chaque rendu est unique, et vous pourriez en trouver
                      certains qui préservent mieux les caractéristiques
                      importantes ou correspondent mieux à votre vision.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Supprimez le désordre et les éléments temporaires avant
                        le rendu
                      </span>
                      . Cela aide l'IA à mieux comprendre l'espace et à créer
                      des transformations plus précises.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Exemples de rendus d'espaces commerciaux
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de transformations d'espaces commerciaux
              créées avec nos rendus :
            </p>
            <CurtainEffect
              beforeImage={BeforeSpaceExample1}
              afterImage={AfterSpaceExample1}
              altBefore={"Espace commercial avant le rendu"}
              altAfter={"Espace commercial après le rendu"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample2}
              afterImage={AfterSpaceExample2}
              altBefore={"Espace commercial avant le rendu"}
              altAfter={"Espace commercial après le rendu"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample3}
              afterImage={AfterSpaceExample3}
              altBefore={"Espace commercial avant le rendu"}
              altAfter={"Espace commercial après le rendu"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLocalFr;
