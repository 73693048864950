// 404Page.js
import React from "react";

const NotFoundPt = () => {
  return (
    <section className="section-first-wrapper">
      <div>
        <h1>404 - Página Não Encontrada</h1>
        <p>Desculpe, a página que você está procurando não existe.</p>
      </div>
    </section>
  );
};

export default NotFoundPt;
