import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import HowToCurtainEffectImage from "../Images/HowToCurtainEffectFrImage.jpg";

import CurtainEffect from "../CurtainEffect";

function HowToCurtainEffectFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment créer un effet rideau avant/après pour le Home Staging
            virtuel
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la création d'effets rideau avant/après pour le home
            staging virtuel
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Qu'est-ce qu'un effet rideau et pourquoi est-il utile ?
            </h2>
            <p className="article-text">
              Un effet rideau est une façon interactive de présenter les images
              avant et après du home staging virtuel. Il permet aux spectateurs
              de glisser entre la version originale et la version aménagée d'un
              espace, rendant facile l'appréciation de la transformation.
            </p>
            <p className="article-text">
              Cet effet est particulièrement précieux pour les professionnels de
              l'immobilier car il :
            </p>
            <ul>
              <li>
                Démontre l'impact du home staging virtuel de manière
                convaincante
              </li>
              <li>
                Aide les clients à comprendre le potentiel des espaces vides
              </li>
              <li>Crée du contenu engageant pour les supports marketing</li>
              <li>
                Instaure la confiance en montrant l'espace d'origine à côté des
                améliorations
              </li>
            </ul>

            <h2 className="article-subtitleh2">
              Comment créer un effet rideau avec Pedra
            </h2>
            <p className="article-text">
              Traditionnellement, créer un effet rideau nécessitait des
              connaissances techniques et des compétences en programmation.
              Cependant, avec Pedra, vous pouvez en créer un en un seul clic.
            </p>
            <p className="article-text">
              Une fois que vous avez créé votre image de home staging virtuel,
              il suffit de :
            </p>
            <ol>
              <li>Cliquer sur votre image virtuellement mise en scène</li>
              <li>
                Cliquer sur "Effet rideau" - cela générera automatiquement un
                lien partageable montrant votre comparaison avant/après avec un
                curseur interactif
              </li>
            </ol>

            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToCurtainEffectImage}
              alt="Comment créer un effet rideau - Capture d'écran du logiciel Pedra"
            />

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Conseils pour des effets rideau efficaces :
                <ul>
                  <li>
                    Utilisez des images de haute qualité pour une meilleure
                    présentation
                  </li>
                  <li>
                    Partagez le lien de l'effet rideau sur votre site web ou vos
                    réseaux sociaux pour mettre en valeur votre travail de home
                    staging virtuel
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">Exemples d'effets rideau</h2>
            <p className="article-text">
              Voici plusieurs exemples d'effets rideau montrant des
              transformations de home staging virtuel :
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Terrasse vide avant le home staging virtuel"}
              altAfter={"Terrasse meublée après le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Terrasse vide avant le home staging virtuel"}
              altAfter={"Terrasse meublée après le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Terrasse vide avant le home staging virtuel"}
              altAfter={"Terrasse meublée après le home staging virtuel"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Terrasse vide avant le home staging virtuel"}
              altAfter={"Terrasse meublée après le home staging virtuel"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToCurtainEffectFr;
