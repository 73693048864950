// LangManager.js
// This code:
//   -> Changes the lang attribute from index.html depending on the url of the page
//   -> Changes the canonical URL setting from index.html depending on the url of the page
//   -> Changes the meta description from index.html depending on the url of the page
//   -> Changes the meta title from index.html depending on the url of the page
// This helps with SEO, since pages can then know if the language is properly set for the page

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LangManager = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if the URL includes 'es'
    const newLang = location.pathname.includes("/es")
      ? "es"
      : location.pathname.includes("/fr")
      ? "fr"
      : location.pathname.includes("/pt")
      ? "pt"
      : location.pathname.includes("/de")
      ? "de"
      : "en";

    // Update the lang attribute of the HTML tag
    document.documentElement.lang = newLang;

    // Update the canonical setting based on the URL
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      const currentUrl = window.location.href;
      canonicalLink.href = currentUrl;
    }

    // Update the meta description based on the URL
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      const currentUrl = window.location.href;
      let newDescription = "";

      if (
        currentUrl.includes("so/floorplan") ||
        currentUrl.includes("fr/floorplan") ||
        currentUrl.includes("es/floorplan") ||
        currentUrl.includes("pt/planta-baixa") ||
        currentUrl.includes("de/grundriss")
      ) {
        newDescription =
          newLang === "es"
            ? "Dibuja planos de planta con la herramienta online para crear planos de Pedra, además te explicamos cómo crearlos de forma sencilla paso a paso."
            : newLang === "fr"
            ? "Dessinez des plans d'étage avec l'outil en ligne pour créer des plans avec Pedra, en plus nous vous expliquons comment les créer facilement étape par étape."
            : newLang === "pt"
            ? "Desenhe plantas baixas com a ferramenta online para criar plantas da Pedra, além disso explicamos como criá-las de forma simples passo a passo."
            : newLang === "de"
            ? "Erstellen Sie Grundrisse mit dem Online-Tool von Pedra. Wir erklären Ihnen Schritt für Schritt, wie Sie ganz einfach professionelle Grundrisse erstellen können."
            : "Create a floor plan in seconds. You can design floor plans with different layer types: doors, windows, furniture and edit their parameters.";
      } else if (
        [
          "/blog/property-listing-optimization",
          "/es/blog/optimizacion-de-anuncios-inmobiliarios",
          "/fr/blog/optimisation-des-annonces-immobilieres",
          "/de/blog/optimierung-von-immobilienanzeigen",
          "/pt/blog/otimizacao-de-anuncios-imobiliarios",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Apprenez des conseils essentiels pour optimiser vos annonces immobilières et attirer plus d'acheteurs."
          : location.pathname.includes("/es/")
          ? "Descubre estrategias clave para optimizar tus anuncios inmobiliarios y atraer más compradores."
          : location.pathname.includes("/de/")
          ? "Entdecken Sie wichtige Strategien zur Optimierung Ihrer Immobilienanzeigen und zur Gewinnung mehr Käufer."
          : location.pathname.includes("/pt/")
          ? "Descubra estratégias essenciais para otimizar seus anúncios imobiliários e atrair mais compradores."
          : "Learn essential tips to optimize your property listings and attract more buyers.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-chambre",
          "/help/how-to-create-bedroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-dormitorio",
          "/pt/ajuda/como-criar-home-staging-virtual-quarto",
          "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Guide complet pour créer un home staging virtuel de chambre. Apprenez à transformer vos espaces avec des techniques professionnelles."
          : newLang === "es"
          ? "Guía completa para crear home staging virtual de dormitorio. Aprende a transformar espacios con técnicas profesionales."
          : newLang === "pt"
          ? "Guia completo para criar home staging virtual de quarto. Aprenda a transformar espaços com técnicas profissionais."
          : newLang === "de"
          ? "Umfassender Leitfaden für virtuelles Schlafzimmer-Home-Staging. Lernen Sie professionelle Raumgestaltungstechniken."
          : "Complete guide to bedroom virtual home staging. Learn to transform spaces with professional techniques.";
      } else if (
        [
          "/fr/rendu-chambre",
          "/es/render-dormitorio",
          "/render-bedroom",
          "/pt/renderizar-quarto",
          "/de/schlafzimmer-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Créez des rendus de chambre réalistes avec notre outil d'IA. Transformez vos photos en visualisations professionnelles en quelques clics."
          : newLang === "es"
          ? "Crea renders de dormitorio realistas con nuestra herramienta de IA. Transforma tus fotos en visualizaciones profesionales en pocos clics."
          : newLang === "pt"
          ? "Crie renders de quarto realistas com nossa ferramenta de IA. Transforme suas fotos em visualizações profissionais em poucos cliques."
          : newLang === "de"
          ? "Erstellen Sie realistische Schlafzimmer-Renderings mit unserem KI-Tool. Verwandeln Sie Fotos in professionelle Visualisierungen."
          : "Create realistic bedroom renders with our AI tool. Transform your photos into professional visualizations in just a few clicks.";
      } else if (
        [
          "/es/blog/consejos-para-fotografia-inmobiliaria",
          "/fr/blog/conseils-pour-la-photographie-immobiliere",
          "/de/blog/tipps-fur-immobilienfotografie",
          "/pt/blog/dicas-de-fotografia-imobiliaria",
          "/blog/real-estate-photography-tips",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Apprenez des conseils essentiels pour réussir vos photographies immobilières. Améliorez vos compétences dès maintenant !"
          : location.pathname.includes("/es/")
          ? "Descubre consejos esenciales de fotografía inmobiliaria para destacar tus anuncios. ¡Mejora tus habilidades hoy!"
          : location.pathname.includes("/de/")
          ? "Entdecken Sie wichtige Tipps zur Immobilienfotografie für beeindruckende Immobilienanzeigen. Verbessern Sie noch heute Ihre Fähigkeiten!"
          : location.pathname.includes("/pt/")
          ? "Descubra dicas essenciais de fotografia imobiliária para criar anúncios impressionantes. Aprimore suas habilidades hoje!"
          : "Discover essential real estate photography tips for stunning property listings. Enhance your skills today!";
      } else if (
        [
          "/es/help/como-crear-efecto-cortinilla-home-staging-virtual",
          "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel",
          "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual",
          "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt",
          "/help/how-to-create-virtual-home-staging-curtain-effect",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment créer des effets avant/après interactifs pour le home staging virtuel avec un effet rideau. Ajoutez un curseur glissant pour comparer vos transformations."
          : newLang === "es"
          ? "Aprende a crear efectos interactivos antes/después para home staging virtual con una cortinilla. Añade un control deslizante para comparar transformaciones."
          : newLang === "pt"
          ? "Aprenda a criar efeitos interativos antes/depois para home staging virtual com um efeito cortina. Adicione um controle deslizante para comparar transformações."
          : newLang === "de"
          ? "Lernen Sie, wie Sie interaktive Vorher/Nachher-Effekte für virtuelles Home Staging mit einem Vorhangeffekt erstellen. Fügen Sie Schieberegler für Vergleiche hinzu."
          : "Learn how to create interactive before/after effects for virtual home staging with a curtain effect. Add a sliding control to showcase your staging transformations.";
      } else if (
        [
          "/es/releases/planos-y-espacios-comerciales",
          "/fr/releases/plans-et-espaces-commerciaux",
          "/pt/lancamentos/plantas-baixas-e-espacos-comerciais",
          "/de/releases/grundrisse-und-gewerberaume",
          "/releases/floorplans-and-commercial-spaces",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Ce décembre, découvrez la conversion instantanée de plans en rendus 3D et la visualisation d'espaces commerciaux."
          : newLang === "es"
          ? "Este diciembre, descubre la conversión instantánea de planos en renders 3D y la visualización de espacios comerciales."
          : newLang === "pt"
          ? "Neste dezembro, descubra a conversão instantânea de plantas baixas em renders 3D e a visualização de espaços comerciais."
          : newLang === "de"
          ? "Entdecken Sie diesen Dezember die sofortige Umwandlung von Grundrissen in 3D-Renderings und die Visualisierung von Gewerberäumen."
          : "This December, discover instant floor plan to 3D render conversion and commercial space visualization.";
      } else if (
        [
          "/blog/real-estate-advertising",
          "/es/blog/publicidad-inmobiliaria",
          "/fr/blog/publicite-immobiliere",
          "/pt/blog/publicidade-imobiliaria",
          "/de/blog/immobilien-werbung",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Aprende las mejores estrategias de publicidad inmobiliaria para atraer clientes y vender propiedades más rápido. Consejos y técnicas efectivas."
            : newLang === "fr"
            ? "Découvrez les meilleures stratégies publicitaires pour attirer des clients et vendre des biens plus rapidement. Conseils et techniques efficaces."
            : newLang === "pt"
            ? "Aprenda as melhores estratégias de publicidade imobiliária para atrair clientes e vender imóveis mais rápido. Dicas e técnicas eficazes."
            : newLang === "de"
            ? "Lernen Sie die besten Werbestrategien, um Kunden zu gewinnen und Immobilien schneller zu verkaufen. Effektive Tipps und Techniken."
            : "Learn the best real estate advertising strategies to attract clients and sell properties faster. Effective tips and techniques.";
      } else if (
        [
          "/es/help/como-crear-render-local-comercial",
          "/fr/help/comment-creer-rendu-local-commercial",
          "/pt/ajuda/como-criar-render-local-comercial",
          "/de/hilfe/wie-man-gewerberaum-rendering-erstellt",
          "/help/how-to-create-commercial-space-render",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Guide complet pour créer des rendus 3D professionnels de votre espace commercial. Découvrez les meilleures techniques et outils pour visualiser votre projet."
          : newLang === "es"
          ? "Guía completa para crear renders 3D profesionales de tu local comercial. Descubre las mejores técnicas y herramientas para visualizar tu proyecto."
          : newLang === "pt"
          ? "Guia completo para criar renders 3D profissionais do seu espaço comercial. Descubra as melhores técnicas e ferramentas para visualizar seu projeto."
          : newLang === "de"
          ? "Umfassender Leitfaden zur Erstellung professioneller 3D-Renderings Ihres Gewerberaums. Entdecken Sie die besten Techniken und Tools zur Visualisierung Ihres Projekts."
          : "Complete guide to creating professional 3D renders of your commercial space. Discover the best techniques and tools to visualize your project.";
      } else if (
        [
          "/fr/home-staging-local",
          "/es/home-staging-local",
          "/pt/home-staging-local",
          "/de/home-staging-gewerbe",
          "/home-staging-commercial",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de locales comerciales aumenta el atractivo de su propiedad. Ofrecemos servicios especializados para transformar su espacio comercial. Home staging comercial efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de locaux commerciaux augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre espace commercial. Home staging commercial efficace et abordable."
            : newLang === "pt"
            ? "O home staging de espaços comerciais aumenta o atrativo da sua propriedade. Oferecemos serviços especializados para transformar seu espaço comercial. Home staging comercial efetivo e acessível."
            : newLang === "de"
            ? "Home Staging für Gewerbeimmobilien steigert die Attraktivität Ihrer Immobilie. Wir bieten spezialisierte Dienstleistungen zur Transformation Ihres Gewerberaums. Effektives und erschwingliches gewerbliches Home Staging."
            : "Commercial property staging enhances your property's appeal. We offer specialized services to transform your commercial space. Effective and affordable commercial staging.";
      } else if (
        [
          "/es/render-local",
          "/fr/rendu-local",
          "/pt/renderizacao-comercial",
          "/de/gewerbe-rendering",
          "/render-commercial",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de locales comerciales automáticamente. Nuestro servicio genera visualizaciones profesionales de espacios comerciales en minutos. Renders de locales rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de locaux commerciaux automatiquement. Notre service génère des visualisations professionnelles d'espaces commerciaux en quelques minutes. Rendus de locaux rapides et abordables."
            : newLang === "pt"
            ? "Crie renders fotorrealistas de espaços comerciais automaticamente. Nosso serviço gera visualizações profissionais de espaços comerciais em minutos. Renders comerciais rápidos e acessíveis."
            : newLang === "de"
            ? "Erstellen Sie automatisch fotorealistische Renderings von Gewerberäumen. Unser Service generiert professionelle Visualisierungen von Gewerbeimmobilien in Minuten. Schnelle und kostengünstige Gewerberaum-Renderings."
            : "Create photorealistic commercial space renders automatically. Our service generates professional commercial property visualizations in minutes. Fast and affordable commercial renders.";
      } else if (
        [
          "/es/blog/rentabilidad-home-staging-virtual",
          "/fr/blog/rentabilite-home-staging-virtuel",
          "/pt/blog/roi-home-staging-virtual",
          "/de/blog/roi-virtuelles-home-staging",
          "/blog/roi-virtual-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Analysez la rentabilité du home staging virtuel dans l'immobilier avec des données réelles des principales agences du marché."
          : newLang === "es"
          ? "Descubre la rentabilidad del home staging virtual en el sector inmobiliario con datos reales de las principales agencias."
          : newLang === "pt"
          ? "Analise o retorno do investimento do home staging virtual no setor imobiliário com dados reais das principais agências."
          : newLang === "de"
          ? "Erfahren Sie mehr über die Rentabilität von virtuellem Home Staging in der Immobilienbranche mit realen Daten führender Agenturen."
          : "Learn about the ROI of virtual staging in real estate with concrete data and insights from top agencies across market segments.";
      } else if (
        [
          "/help/render-floor-plan-with-ai",
          "/fr/help/rendu-plan-etage-avec-ia",
          "/es/help/renderizar-plano-de-planta-con-ia",
          "/pt/ajuda/renderizar-planta-baixa-com-ia",
          "/de/hilfe/grundriss-mit-ki-rendern",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez vos plans en visualisations 3D photoréalistes avec l'IA. Guide pas à pas pour créer des rendus de qualité instantanément."
          : newLang === "es"
          ? "Transforma tus planos en imágenes fotorrealistas con IA. Tutorial paso a paso para crear renders de alta calidad al instante."
          : newLang === "pt"
          ? "Transforme suas plantas em visualizações 3D fotorrealistas com IA. Guia passo a passo para criar renders de qualidade instantaneamente."
          : newLang === "de"
          ? "Verwandeln Sie Ihre Grundrisse mit KI in fotorealistische 3D-Visualisierungen. Schritt-für-Schritt-Anleitung zur sofortigen Erstellung hochwertiger Renderings."
          : "Transform your floor plans into photorealistic 3D visuals with AI. Step-by-step guide to creating instant, high-quality room renders.";
      } else if (
        [
          "render-desde-plano",
          "render-depuis-plan",
          "render-desde-planta",
          "/de/rendering-vom-grundriss",
          "render-from-floor-plan",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez vos plans d'étage en images réalistes. Notre outil génère automatiquement des visuels pour chaque pièce de votre plan."
          : newLang === "es"
          ? "Convierte tus planos en imágenes realistas. Nuestra herramienta genera automáticamente visualizaciones para cada habitación de tu plano."
          : newLang === "pt"
          ? "Transforme suas plantas em imagens realistas. Nossa ferramenta gera automaticamente visualizações para cada ambiente da sua planta."
          : newLang === "de"
          ? "Verwandeln Sie Ihre Grundrisse in realistische Bilder. Unser Tool generiert automatisch Visualisierungen für jeden Raum Ihres Grundrisses."
          : "Transform your floor plans into realistic images. Our tool automatically generates visuals for every room in your floor plan.";
      } else if (
        [
          "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
          "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
          "/pt/blog/home-staging-tradicional-vs-virtual",
          "/de/blog/traditionelles-vs-virtuelles-home-staging",
          "/blog/traditional-home-staging-vs-virtual-home-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Comparez le home staging virtuel et traditionnel : coûts, avantages et inconvénients. Découvrez quelle méthode est la plus efficace pour valoriser votre bien immobilier."
          : newLang === "es"
          ? "Compara el home staging virtual y tradicional: costos, ventajas y desventajas. Descubre qué método es más efectivo para realzar el valor de tu propiedad."
          : newLang === "pt"
          ? "Compare o home staging virtual e tradicional: custos, vantagens e desvantagens. Descubra qual método é mais efetivo para valorizar seu imóvel."
          : newLang === "de"
          ? "Vergleichen Sie virtuelles und traditionelles Home Staging: Kosten, Vor- und Nachteile. Entdecken Sie, welche Methode effektiver ist, um den Wert Ihrer Immobilie zu steigern."
          : "Compare virtual and traditional home staging: costs, pros, and cons. Discover which method is more effective for enhancing your property's value.";
      } else if (
        [
          "/es/releases/espacios-de-trabajo",
          "/fr/releases/espaces-de-travail",
          "/pt/lancamentos/espacos-trabalho",
          "/de/releases/arbeitsplatze",
          "/releases/workspaces",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Ce mois de novembre, découvrez les nouveaux espaces de travail pour collaborer sur vos projets de home staging virtuel."
          : location.pathname.includes("/pt/") || newLang === "pt"
          ? "Neste novembro, descubra os novos espaços de trabalho para colaborar em seus projetos de home staging virtual."
          : newLang === "es"
          ? "Este noviembre, descubre los nuevos espacios de trabajo para colaborar en tus proyectos de home staging virtual."
          : newLang === "de"
          ? "Entdecken Sie diesen November die neuen Arbeitsbereiche für die Zusammenarbeit an Ihren virtuellen Home Staging Projekten."
          : "This November, discover the new workspaces for collaborating on your virtual staging projects.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-exterieur-maison",
          "/help/how-to-create-house-exterior-render",
          "/es/help/como-crear-render-exterior-casa",
          "/pt/ajuda/como-criar-render-externa-casa",
          "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu extérieur de maison avec notre guide simple. Transformez vos photos en visualisations réalistes et professionnelles."
          : newLang === "es"
          ? "Domina el render exterior de casa con nuestra guía fácil. Transforma tus fotos en visualizaciones realistas y profesionales."
          : newLang === "pt"
          ? "Domine a renderização externa de casas com nosso guia fácil. Transforme suas fotos em visualizações realistas e profissionais."
          : newLang === "de"
          ? "Beherrschen Sie das Rendering von Hausaußenansichten mit unserer einfachen Anleitung. Verwandeln Sie Ihre Fotos in realistische und professionelle Visualisierungen."
          : "Master house exterior rendering with our easy guide. Transform your photos into realistic and professional visualizations.";
      } else if (
        [
          "/fr/help/comment-dessiner-plan",
          "/help/how-to-draw-floor-plan",
          "/es/help/como-dibujar-plano",
          "/pt/ajuda/como-desenhar-planta-baixa",
          "/de/hilfe/wie-man-grundriss-zeichnet",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez la création de plans d'étage avec notre guide simple. Créez des plans professionnels sans être architecte."
          : newLang === "es"
          ? "Domina el dibujo de planos con nuestra guía fácil. Crea planos profesionales sin ser arquitecto."
          : newLang === "pt"
          ? "Domine a criação de plantas baixas com nosso guia fácil. Crie plantas profissionais sem ser arquiteto."
          : newLang === "de"
          ? "Beherrschen Sie das Zeichnen von Grundrissen mit unserer einfachen Anleitung. Erstellen Sie professionelle Grundrisse ohne Architekt zu sein."
          : "Master floor plan creation with our easy guide. Create professional floor plans without being an architect.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-terrasse",
          "/help/how-to-create-terrace-render",
          "/es/help/como-crear-render-terraza",
          "/pt/ajuda/como-criar-render-terraco",
          "/de/hilfe/wie-man-terrassen-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de terrasse avec notre guide simple. Transformez des espaces vides en terrasses fonctionnelles et élégantes."
          : newLang === "es"
          ? "Domina el render de terraza con nuestra guía fácil. Transforma espacios vacíos en terrazas funcionales y elegantes."
          : newLang === "pt"
          ? "Domine a renderização de terraços com nosso guia fácil. Transforme espaços vazios em terraços funcionais e elegantes."
          : newLang === "de"
          ? "Beherrschen Sie das Terrassen-Rendering mit unserer einfachen Anleitung. Verwandeln Sie leere Räume in funktionale und elegante Terrassen."
          : "Master terrace rendering with our easy guide. Transform empty spaces into functional and elegant terraces.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-chambre",
          "/help/how-to-create-bedroom-render",
          "/es/help/como-crear-render-dormitorio",
          "/pt/ajuda/como-criar-render-quarto",
          "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de chambre avec notre guide simple. Transformez des espaces vides en chambres fonctionnelles et élégantes."
          : newLang === "es"
          ? "Domina el render de dormitorio con nuestra guía fácil. Transforma espacios vacíos en dormitorios funcionales y elegantes."
          : newLang === "pt"
          ? "Domine a renderização de quartos com nosso guia fácil. Transforme espaços vazios em quartos funcionais e elegantes."
          : newLang === "de"
          ? "Beherrschen Sie das Schlafzimmer-Rendering mit unserer einfachen Anleitung. Verwandeln Sie leere Räume in funktionale und elegante Schlafzimmer."
          : "Master bedroom rendering with our easy guide. Transform empty spaces into functional and elegant bedrooms.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salon",
          "/help/how-to-create-living-room-render",
          "/es/help/como-crear-render-sala-estar",
          "/pt/ajuda/como-criar-render-sala",
          "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de salon avec notre guide simple. Transformez des espaces vides en salons fonctionnels et élégants."
          : newLang === "es"
          ? "Domina el render de sala de estar con nuestra guía fácil. Transforma espacios vacíos en salas funcionales y elegantes."
          : newLang === "pt"
          ? "Domine a renderização de salas com nosso guia fácil. Transforme espaços vazios em salas funcionais e elegantes."
          : newLang === "de"
          ? "Beherrschen Sie das Wohnzimmer-Rendering mit unserer einfachen Anleitung. Verwandeln Sie leere Räume in funktionale und elegante Wohnzimmer."
          : "Master living room rendering with our easy guide. Transform empty spaces into functional and elegant living rooms.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salle-de-bain",
          "/help/how-to-create-bathroom-render",
          "/es/help/como-crear-render-bano",
          "/pt/ajuda/como-criar-render-banheiro",
          "/de/hilfe/wie-man-badezimmer-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de salle de bain avec notre guide simple. Transformez des salles de bain vides en espaces fonctionnels et élégants."
          : newLang === "es"
          ? "Domina el render de baños con nuestra guía fácil. Transforma espacios vacíos en baños funcionales y elegantes."
          : newLang === "pt"
          ? "Domine a renderização de banheiros com nosso guia fácil. Transforme espaços vazios em banheiros funcionais e elegantes."
          : newLang === "de"
          ? "Beherrschen Sie das Badezimmer-Rendering mit unserer einfachen Anleitung. Verwandeln Sie leere Räume in funktionale und elegante Badezimmer."
          : "Master bathroom rendering with our easy guide. Transform empty spaces into functional and elegant bathrooms.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-cuisine",
          "/help/how-to-create-kitchen-render",
          "/es/help/como-crear-render-cocina",
          "/pt/ajuda/como-criar-render-cozinha",
          "/de/hilfe/wie-man-kuchen-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu 3D de cuisine avec notre guide simple. Transformez des cuisines vides en espaces fonctionnels et élégants."
          : newLang === "es"
          ? "Domina el render de cocinas con nuestra guía fácil. Transforma espacios vacíos en cocinas funcionales y elegantes."
          : newLang === "pt"
          ? "Domine a renderização de cozinhas com nosso guia fácil. Transforme espaços vazios em cozinhas funcionais e elegantes."
          : newLang === "de"
          ? "Beherrschen Sie das Küchen-Rendering mit unserer einfachen Anleitung. Verwandeln Sie leere Räume in funktionale und elegante Küchen."
          : "Master kitchen rendering with our easy guide. Transform empty spaces into functional and elegant kitchens.";
      } else if (
        [
          "/fr/rendu-maison",
          "/es/render-casa",
          "/pt/renderizar-casa",
          "/render-house",
          "/de/haus-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de casas automáticamente. Nuestro servicio genera visualizaciones profesionales de casas en minutos. Renders de casa rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de maisons automatiquement. Notre service génère des visualisations professionnelles de maisons en quelques minutes. Rendus de maison rapides et abordables."
            : newLang === "pt"
            ? "Crie renders fotorrealistas de casas automaticamente. Nosso serviço gera visualizações profissionais de casas em minutos. Renders de casa rápidos e acessíveis."
            : newLang === "de"
            ? "Erstellen Sie automatisch fotorealistische Haus-Renderings. Unser Service generiert professionelle Hausvisualisierungen in Minuten. Schnelle und kostengünstige Haus-Renderings."
            : "Create photorealistic house renders automatically. Our service generates professional house visualizations in minutes. Fast and affordable house renders.";
      } else if (
        [
          "/fr/rendu-salon",
          "/es/render-salon",
          "/pt/renderizar-sala",
          "/render-living-room",
          "/de/wohnzimmer-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de salones automáticamente. Nuestro servicio genera visualizaciones profesionales de salones en minutos. Renders de salón rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de salons automatiquement. Notre service génère des visualisations professionnelles de salons en quelques minutes. Rendus de salon rapides et abordables."
            : newLang === "pt"
            ? "Crie renders fotorrealistas de salas automaticamente. Nosso serviço gera visualizações profissionais de salas em minutos. Renders de sala rápidos e acessíveis."
            : newLang === "de"
            ? "Erstellen Sie automatisch fotorealistische Wohnzimmer-Renderings. Unser Service generiert professionelle Wohnzimmervisualisierungen in Minuten. Schnelle und kostengünstige Wohnzimmer-Renderings."
            : "Create photorealistic living room renders automatically. Our service generates professional living room visualizations in minutes. Fast and affordable living room renders.";
      } else if (
        [
          "/fr/rendu-terrasse",
          "/es/render-terraza",
          "/pt/renderizar-terraco",
          "/render-terrace",
          "/de/terrassen-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de terrazas automáticamente. Nuestro servicio genera visualizaciones profesionales de terrazas en minutos. Renders de terraza rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de terrasses automatiquement. Notre service génère des visualisations professionnelles de terrasses en quelques minutes. Rendus de terrasse rapides et abordables."
            : newLang === "pt"
            ? "Crie renders fotorrealistas de terraços automaticamente. Nosso serviço gera visualizações profissionais de terraços em minutos. Renders de terraço rápidos e acessíveis."
            : newLang === "de"
            ? "Erstellen Sie automatisch fotorealistische Terrassen-Renderings. Unser Service generiert professionelle Terrassenvisualisierungen in Minuten. Schnelle und kostengünstige Terrassen-Renderings."
            : "Create photorealistic terrace renders automatically. Our service generates professional terrace visualizations in minutes. Fast and affordable terrace renders.";
      } else if (
        [
          "/fr/rendu-salle-de-bain",
          "/es/render-bano",
          "/pt/renderizar-banheiro",
          "/render-bathroom",
          "/de/badezimmer-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de baños automáticamente. Nuestro servicio genera visualizaciones profesionales de baños en minutos. Renders de baño rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de salles de bain automatiquement. Notre service génère des visualisations professionnelles de salles de bain en quelques minutes. Rendus de salle de bain rapides et abordables."
            : newLang === "pt"
            ? "Crie renders fotorrealistas de banheiros automaticamente. Nosso serviço gera visualizações profissionais de banheiros em minutos. Renders de banheiro rápidos e acessíveis."
            : newLang === "de"
            ? "Erstellen Sie automatisch fotorealistische Badezimmer-Renderings. Unser Service generiert professionelle Badezimmervisualisierungen in Minuten. Schnelle und kostengünstige Badezimmer-Renderings."
            : "Create photorealistic bathroom renders automatically. Our service generates professional bathroom visualizations in minutes. Fast and affordable bathroom renders.";
      } else if (
        [
          "/es/blog/marketing-inmobiliario",
          "/fr/blog/marketing-immobilier",
          "/pt/blog/marketing-imobiliario",
          "/blog/real-estate-marketing",
          "/de/blog/immobilien-marketing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Le marketing immobilier est un ensemble de stratégies et de techniques conçues pour vendre des propriétés immobilières. Découvrez les types et comment les appliquer."
          : newLang === "es"
          ? "El marketing inmobiliario es un conjunto de estrategias y técnicas diseñadas para vender propiedades inmobiliarias, descubre los tipos y cómo aplicarlo."
          : newLang === "pt"
          ? "O marketing imobiliário é um conjunto de estratégias e técnicas projetadas para vender imóveis. Descubra os tipos e como aplicá-los."
          : newLang === "de"
          ? "Immobilienmarketing ist ein Set von Strategien und Techniken zum Verkauf von Immobilien. Entdecken Sie die verschiedenen Arten und deren Anwendung."
          : "Real estate marketing is a set of strategies and techniques designed to sell real estate properties. Discover the types and how to apply them.";
      } else if (
        [
          "/es/blog/mentor-inmobiliario",
          "/fr/blog/mentor-immobilier",
          "/pt/blog/mentor-imobiliario",
          "/blog/real-estate-mentor",
          "/de/blog/immobilien-mentor",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Les mentors immobiliers sont des experts du secteur et conseillent les personnes intéressées par le marché immobilier. Nous vous montrons comment choisir votre mentor."
          : newLang === "es"
          ? "Los mentores inmobiliarios son expertos en el sector y aconsejan a personas interesadas en el mercado inmobiliario, te enseñamos cómo elegir a tu mentor."
          : newLang === "pt"
          ? "Os mentores imobiliários são especialistas no setor e aconselham pessoas interessadas no mercado imobiliário. Nós te ensinamos como escolher seu mentor."
          : newLang === "de"
          ? "Immobilienmentoren sind Branchenexperten und beraten Menschen, die sich für den Immobilienmarkt interessieren. Wir zeigen Ihnen, wie Sie Ihren Mentor auswählen."
          : "Real estate mentors are experts in the field and provide advice to people interested in the real estate market. We guide you on how to choose your mentor.";
      } else if (
        [
          "/es/blog/como-vender-casa-a-reformar",
          "/fr/blog/comment-vendre-une-maison-a-renover",
          "/pt/blog/como-vender-casa-para-reforma",
          "/blog/how-to-sell-a-house-to-renovate",
          "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment vendre une maison à rénover avec succès. Guide pour mettre en valeur le potentiel de rénovation, fixer un prix attractif et attirer les acheteurs."
          : newLang === "es"
          ? "Descubre estrategias clave para vender una casa a reformar. Aprende a destacar su potencial, fijar el precio correcto y atraer compradores interesados en proyectos de renovación."
          : newLang === "pt"
          ? "Descubra estratégias-chave para vender uma casa para reforma. Aprenda a destacar seu potencial, definir o preço correto e atrair compradores interessados em projetos de renovação."
          : newLang === "de"
          ? "Entdecken Sie erfolgreiche Strategien zum Verkauf eines renovierungsbedürftigen Hauses. Lernen Sie, das Renovierungspotenzial hervorzuheben, den richtigen Preis festzulegen und interessierte Käufer anzuziehen."
          : "Learn effective strategies for selling a house that needs renovation. Tips on highlighting potential, setting the right price, and attracting buyers interested in renovation projects.";
      } else if (
        [
          "/es/renders-para-reformas",
          "/fr/rendus-de-renovation",
          "/pt/renderizacoes-para-reformas",
          "/renders-for-renovations",
          "/de/renderings-fur-renovierungen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez vos projets de rénovation avec nos rendus professionnels. Service automatisé pour architectes et décorateurs. Visualisation rapide et précise de vos rénovations."
          : newLang === "es"
          ? "Transforma tus proyectos de reforma con renders profesionales. Servicio automatizado para arquitectos y decoradores. Visualización rápida y precisa de tus reformas."
          : newLang === "pt"
          ? "Transforme seus projetos de reforma com renders profissionais. Serviço automatizado para arquitetos e decoradores. Visualização rápida e precisa de suas reformas."
          : newLang === "de"
          ? "Transformieren Sie Ihre Renovierungsprojekte mit professionellen Renderings. Automatisierter Service für Architekten und Dekorateure. Schnelle und präzise Visualisierung Ihrer Renovierungen."
          : "Transform your renovation projects with professional renders. Automated service for architects and decorators. Fast and accurate visualization of your renovations.";
      } else if (
        [
          "/es/home-staging-cocina",
          "/fr/home-staging-cuisine",
          "/pt/home-staging-cozinha",
          "/home-staging-kitchen",
          "/de/home-staging-kuche",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de cocina aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu cocina. Home staging de cocina efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de cuisine augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre cuisine. Home staging de cuisine efficace et abordable."
            : newLang === "pt"
            ? "O home staging de cozinha aumenta o atrativo do seu imóvel. Oferecemos serviços especializados para transformar sua cozinha. Home staging de cozinha efetivo e acessível."
            : newLang === "de"
            ? "Küchen-Home-Staging steigert die Attraktivität Ihrer Immobilie. Wir bieten spezialisierte Dienstleistungen zur Transformation Ihrer Küche. Effektives und erschwingliches Küchen-Home-Staging."
            : "Kitchen home staging enhances your property's appeal. We offer specialized services to transform your kitchen. Effective and affordable kitchen home staging.";
      } else if (
        [
          "/fr/rendu-cuisine",
          "/es/render-cocina",
          "/pt/renderizar-cozinha",
          "/render-kitchen",
          "/de/kuchen-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de cocinas automáticamente. Nuestro servicio genera visualizaciones profesionales de cocinas en minutos. Renders de cocina rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de cuisines automatiquement. Notre service génère des visualisations professionnelles de cuisines en quelques minutes. Rendus de cuisine rapides et abordables."
            : newLang === "pt"
            ? "Crie renders fotorrealistas de cozinhas automaticamente. Nosso serviço gera visualizações profissionais de cozinhas em minutos. Renders de cozinha rápidos e acessíveis."
            : newLang === "de"
            ? "Erstellen Sie automatisch fotorealistische Küchenrenderings. Unser Service generiert professionelle Küchenvisualisierungen in Minuten. Schnelle und kostengünstige Küchenrenderings."
            : "Create photorealistic kitchen renders automatically. Our service generates professional kitchen visualizations in minutes. Fast and affordable kitchen renders.";
      } else if (
        [
          "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
          "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
          "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis",
          "/free-tools/generate-real-estate-listing-description-free",
          "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Créez des annonces immobilières professionnelles instantanément avec notre générateur gratuit. Démarquez-vous et attirez plus d'acheteurs !"
          : newLang === "es"
          ? "Crea anuncios inmobiliarios profesionales al instante con nuestro generador gratuito. ¡Destácate en el mercado y atrae más compradores!"
          : newLang === "pt"
          ? "Crie anúncios imobiliários profissionais instantaneamente com nosso gerador gratuito. Destaque-se no mercado e atraia mais compradores!"
          : newLang === "de"
          ? "Erstellen Sie sofort professionelle Immobilienanzeigen mit unserem kostenlosen Generator. Heben Sie sich vom Markt ab und gewinnen Sie mehr Käufer!"
          : "Create professional real estate listings instantly with our free AI-powered description generator. Stand out in the market and attract more buyers!";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
          "/help/how-to-create-bathroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-bano",
          "/pt/ajuda/como-criar-home-staging-virtual-banheiro",
          "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de salle de bain avec notre guide simple. Transformez des salles de bain vides en espaces fonctionnels et accueillants."
          : newLang === "es"
          ? "Domina el home staging virtual de baños con nuestra guía fácil. Transforma baños vacíos en espacios funcionales y acogedores."
          : newLang === "pt"
          ? "Domine o home staging virtual de banheiros com nosso guia fácil. Transforme banheiros vazios em espaços funcionais e acolhedores."
          : newLang === "de"
          ? "Beherrschen Sie virtuelles Badezimmer-Home-Staging mit unserer einfachen Anleitung. Verwandeln Sie leere Badezimmer in funktionale, einladende Räume."
          : "Master bathroom virtual home staging with our easy guide. Transform empty bathrooms into functional, inviting spaces.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-cuisine",
          "/help/how-to-create-kitchen-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-cocina",
          "/pt/ajuda/como-criar-home-staging-virtual-cozinha",
          "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de cuisine avec notre guide simple. Transformez des cuisines vides en espaces fonctionnels et accueillants."
          : newLang === "es"
          ? "Domina el home staging virtual de cocinas con nuestra guía fácil. Transforma cocinas vacías en espacios funcionales y acogedores."
          : newLang === "pt"
          ? "Domine o home staging virtual de cozinhas com nosso guia fácil. Transforme cozinhas vazias em espaços funcionais e acolhedores."
          : newLang === "de"
          ? "Beherrschen Sie virtuelles Küchen-Home-Staging mit unserer einfachen Anleitung. Verwandeln Sie leere Küchen in funktionale, einladende Räume."
          : "Master kitchen virtual home staging with our easy guide. Transform empty kitchens into functional, inviting spaces.";
      } else if (
        [
          "/fr/releases/ameliorations-pedra-piscines-et-preservation",
          "/es/releases/mejoras-pedra-piscinas-y-preservacion",
          "/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao",
          "/releases/pedra-improvements-pools-and-preservation",
          "/de/releases/stein-verbesserungen-pools-und-erhaltung",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Ce mois d'octobre, découvrez les nouvelles fonctionnalités de Pedra pour améliorer vos piscines et préserver vos espaces avec plus de précision que jamais."
          : newLang === "es"
          ? "Este octubre, descubre las nuevas funcionalidades de Pedra para mejorar tus piscinas y preservar tus espacios con más precisión que nunca."
          : newLang === "pt"
          ? "Neste outubro, descubra as novas funcionalidades da Pedra para melhorar suas piscinas e preservar seus espaços com mais precisão do que nunca."
          : newLang === "de"
          ? "Entdecken Sie diesen Oktober die neuen Funktionen von Pedra zur Verbesserung Ihrer Pools und zur präziseren Erhaltung Ihrer Räume als je zuvor."
          : "This October, discover Pedra's new features to enhance your pools and preserve your spaces with more precision than ever.";
      } else if (
        [
          "/fr/help/comment-vider-pieces-virtuellement",
          "/help/how-to-empty-rooms-virtually",
          "es/help/como-vaciar-habitacion-virtualmente",
          "/pt/ajuda/como-esvaziar-ambientes-virtualmente",
          "/de/hilfe/wie-man-raume-virtuell-leert",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment vider virtuellement des pièces avec notre guide. Retirez meubles et objets d'une pièce facilement et de façon réaliste"
          : newLang === "es"
          ? "Aprende a vaciar habitaciones virtualmente de forma automática y fácil con nuestra guía. Vacía muebles y objetos de una habitación de manera sencilla."
          : newLang === "pt"
          ? "Aprenda a esvaziar ambientes virtualmente de forma automática e fácil com nosso guia. Remova móveis e objetos de um ambiente de maneira simples."
          : newLang === "de"
          ? "Lernen Sie mit unserer Anleitung, wie Sie Räume virtuell und automatisch leeren können. Entfernen Sie Möbel und Objekte einfach aus einem Raum."
          : "Learn how to empty rooms virtually in an automatic and easy way with our step-by-step guide. Empty furniture and objects from a room easily.";
      } else if (
        [
          "/fr/help/comment-meubler-en-virtuel",
          "/help/how-to-furnish-virtually",
          "/es/help/como-amueblar-virtualmente",
          "/pt/ajuda/como-mobiliar-virtualmente",
          "/de/hilfe/wie-man-virtuell-einrichtet",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Apprenez à meubler virtuellement des pièces et des propriétés. Ce tutoriel vous aidera à meubler comme un pro."
          : newLang === "es"
          ? "Descubre cómo usar la herramienta de amueblar para amueblar virtualmente tus propiedades. Tutorial paso a paso para un amueblado virtual profesional."
          : newLang === "pt"
          ? "Descubra como usar a ferramenta de mobília para mobiliar virtualmente suas propriedades. Tutorial passo a passo para uma mobília virtual profissional."
          : newLang === "de"
          ? "Lernen Sie, wie Sie Räume und Immobilien virtuell einrichten. Dieses Tutorial hilft Ihnen, professionell virtuell einzurichten."
          : "Learn to virtually furnish rooms and properties. This tutorial will help you virtually furnish like a pro.";
      } else if (currentUrl.includes("render")) {
        newDescription =
          newLang === "es"
            ? "Crea renders con IA y prueba el software gratis para generar de forma rápida renders 3d con ayuda de la inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez comment utiliser l'IA pour créer des rendus immobiliers époustouflants. Téléchargez vos images et choisissez parmi divers styles de rénovation."
            : newLang === "pt"
            ? "Crie renders com IA e experimente o software gratuito para gerar rapidamente renders 3D com ajuda da inteligência artificial."
            : newLang === "de"
            ? "Erstellen Sie KI-Renderings und testen Sie die Software kostenlos, um schnell 3D-Renderings mit Hilfe künstlicher Intelligenz zu generieren."
            : "Create AI renders and try the software for free to quickly generate 3D renders with the help of artificial intelligence.";
      } else if (
        [
          "/fr/help/comment-dessiner-plan",
          "/help/how-to-draw-floor-plan",
          "/es/help/como-dibujar-plano",
          "/pt/ajuda/como-desenhar-planta-baixa",
          "/de/hilfe/wie-man-grundriss-zeichnet",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez la création de plans d'étage avec notre guide simple. Créez des plans professionnels sans être architecte."
          : newLang === "es"
          ? "Domina el dibujo de planos con nuestra guía fácil. Crea planos profesionales sin ser arquitecto."
          : newLang === "pt"
          ? "Domine o desenho de plantas baixas com nosso guia fácil. Crie plantas profissionais sem ser arquiteto."
          : newLang === "de"
          ? "Beherrschen Sie die Grundrisserstellung mit unserer einfachen Anleitung. Erstellen Sie professionelle Grundrisse ohne Architekt zu sein."
          : "Master floor plan creation with our easy guide. Create professional floor plans without being an architect.";
      } else if (
        [
          "/fr/home-staging-chambre",
          "/es/home-staging-dormitorio",
          "/pt/home-staging-quarto",
          "/de/home-staging-schlafzimmer",
          "/home-staging-bedroom",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de dormitorio aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu dormitorio. Home staging de dormitorio efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de chambre augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre chambre. Home staging de chambre efficace et abordable."
            : newLang === "pt"
            ? "O home staging de quarto aumenta o atrativo do seu imóvel. Oferecemos serviços especializados para transformar seu quarto. Home staging de quarto efetivo e acessível."
            : newLang === "de"
            ? "Schlafzimmer-Home-Staging steigert die Attraktivität Ihrer Immobilie. Wir bieten spezialisierte Dienstleistungen zur Transformation Ihres Schlafzimmers. Effektives und erschwingliches Schlafzimmer-Home-Staging."
            : "Bedroom home staging enhances your property's appeal. We offer specialized services to transform your bedroom. Effective and affordable bedroom home staging.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salon",
          "/help/how-to-create-living-room-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-salon",
          "/pt/ajuda/como-criar-home-staging-virtual-sala",
          "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de salon avec notre guide simple. Transformez des espaces de vie vides en salons accueillants et bien aménagés."
          : newLang === "es"
          ? "Domina el home staging virtual de salones con nuestra guía fácil. Transforma espacios de estar vacíos en salones acogedores y bien amueblados."
          : newLang === "pt"
          ? "Domine o home staging virtual de salas com nosso guia fácil. Transforme espaços vazios em salas acolhedoras e bem mobiliadas."
          : newLang === "de"
          ? "Beherrschen Sie virtuelles Wohnzimmer-Home-Staging mit unserer einfachen Anleitung. Verwandeln Sie leere Wohnräume in einladende, gut eingerichtete Zimmer."
          : "Master living room virtual home staging with our easy guide. Transform empty living spaces into inviting, well-furnished rooms.";
      } else if (
        [
          "/fr/home-staging-salon",
          "/es/home-staging-salon",
          "/pt/home-staging-sala",
          "/de/home-staging-wohnzimmer",
          "/home-staging-living-room",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de salón aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu salón. Home staging de salón efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de salon augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre salon. Home staging de salon efficace et abordable."
            : newLang === "pt"
            ? "O home staging de sala aumenta o atrativo do seu imóvel. Oferecemos serviços especializados para transformar sua sala. Home staging de sala efetivo e acessível."
            : newLang === "de"
            ? "Wohnzimmer Home Staging steigert die Attraktivität Ihrer Immobilie. Wir bieten spezialisierte Dienstleistungen zur Transformation Ihres Wohnzimmers. Effektives und erschwingliches Wohnzimmer Home Staging."
            : "Living room home staging enhances your property's appeal. We offer specialized services to transform your living room. Effective and affordable living room home staging.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-terrasse",
          "/help/how-to-create-terrace-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-terraza",
          "/pt/ajuda/como-criar-home-staging-virtual-terraco",
          "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de terrasse avec notre guide simple. Transformez des espaces extérieurs vides en terrasses accueillantes et bien aménagées."
          : newLang === "es"
          ? "Domina el home staging virtual de terrazas con nuestra guía fácil. Transforma espacios exteriores vacíos en terrazas acogedoras y bien amuebladas."
          : newLang === "pt"
          ? "Domine o home staging virtual de terraços com nosso guia fácil. Transforme espaços externos vazios em terraços acolhedores e bem mobiliados."
          : newLang === "de"
          ? "Beherrschen Sie virtuelles Terrassen-Home-Staging mit unserer einfachen Anleitung. Verwandeln Sie leere Außenbereiche in einladende, gut möblierte Terrassen."
          : "Master terrace virtual home staging with our easy guide. Transform empty outdoor spaces into inviting, well-furnished terraces.";
      } else if (
        [
          "/es/blog/pricing-inmobiliario",
          "/fr/blog/pricing-immobilier",
          "/pt/blog/precificacao-imobiliaria",
          "/de/blog/immobilien-preisgestaltung",
          "/blog/real-estate-pricing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Le pricing immobilier consiste à fixer le prix optimal de votre bien immobilier et à maximiser sa valeur. Nous vous enseignons différentes méthodes de pricing immobilier."
          : newLang === "es"
          ? "El pricing inmobiliario consiste en fijar el precio óptimo de tu inmueble y maximizar el valor, te enseñamos diferentes métodos de pricing inmobiliario."
          : newLang === "pt"
          ? "A precificação imobiliária consiste em definir o preço ideal do seu imóvel e maximizar seu valor. Ensinamos diferentes métodos de precificação imobiliária."
          : newLang === "de"
          ? "Immobilien-Preisgestaltung bedeutet, den optimalen Preis für Ihre Immobilie festzulegen und ihren Wert zu maximieren. Wir zeigen Ihnen verschiedene Methoden der Immobilien-Preisgestaltung."
          : "Real estate pricing involves setting the optimal price for your property and maximizing its value. We teach you different methods of real estate pricing.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel",
          "/help/how-to-create-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual",
          "/pt/ajuda/como-criar-home-staging-virtual",
          "/de/hilfe/wie-man-virtuelles-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel avec notre guide simple. Transformez des espaces vides en pièces meublées attrayantes."
          : newLang === "es"
          ? "Domina el home staging virtual con nuestra guía fácil. Transforma espacios vacíos en habitaciones amuebladas y atractivas."
          : newLang === "pt"
          ? "Domine o home staging virtual com nosso guia fácil. Transforme espaços vazios em ambientes mobiliados e atraentes."
          : newLang === "de"
          ? "Beherrschen Sie virtuelles Home Staging mit unserer einfachen Anleitung. Verwandeln Sie leere Räume in attraktiv möblierte Zimmer."
          : "Master virtual home staging with our easy guide. Transform empty spaces into attractive, furnished rooms.";
      } else if (
        [
          "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
          "/fr/outils-gratuits/convertisseur-format-image",
          "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis",
          "/de/kostenlose-tools/bildformat-konverter-kostenlos",
          "/free-tools/free-image-format-converter",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Convertissez facilement vos images entre différents formats avec notre outil gratuit en ligne. Compatible PNG, JPG, WEBP, GIF et plus. Rapide, sécurisé et sans inscription."
          : newLang === "es"
          ? "Convierte imágenes entre formatos fácilmente con nuestra herramienta gratuita en línea. Compatible con PNG, JPG, WEBP, GIF y más. Rápido, seguro y sin registro."
          : newLang === "pt"
          ? "Converta imagens entre formatos facilmente com nossa ferramenta online gratuita. Compatível com PNG, JPG, WEBP, GIF e mais. Rápido, seguro e sem registro."
          : newLang === "de"
          ? "Konvertieren Sie Bilder einfach zwischen verschiedenen Formaten mit unserem kostenlosen Online-Tool. Unterstützt PNG, JPG, WEBP, GIF und mehr. Schnell, sicher und ohne Registrierung."
          : "Convert images between formats easily with our free online tool. Support for PNG, JPG, WEBP, GIF, and more. Fast, secure, and no registration required.";
      } else if (
        [
          "/fr/blog/augmentez-valeur-maison",
          "/es/blog/aumenta-valor-vivienda",
          "/pt/blog/aumentar-valor-imovel",
          "/de/blog/hauswert-steigern",
          "/blog/increase-home-value",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous vous montrons comment augmenter la valeur de votre maison rapidement et facilement avec plusieurs conseils et astuces pour améliorer votre maison avant de la vendre &#127969;"
          : newLang === "es"
          ? "Te enseñamos cómo aumentar el valor de la vivienda de forma rápida y sencilla con varios consejos y tips para mejorar tu hogar antes de venderlo &#127969;"
          : newLang === "pt"
          ? "Nós mostramos como aumentar o valor do seu imóvel de forma rápida e fácil com várias dicas e sugestões para melhorar sua casa antes de vendê-la &#127969;"
          : newLang === "de"
          ? "Wir zeigen Ihnen, wie Sie den Wert Ihres Hauses schnell und einfach steigern können, mit verschiedenen Tipps und Ratschlägen zur Verbesserung Ihres Hauses vor dem Verkauf &#127969;"
          : "We show you how to increase the value of your home quickly and easily with several tips and advice to improve your home before selling it &#127969;";
      } else if (
        [
          "/es/api-documentation",
          "/fr/api-documentation",
          "/pt/api-documentation",
          "/de/api-documentation",
          "/api-documentation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez l'API de mise en scène virtuelle Pedra.so. Transformez les espaces avec l'amélioration des pièces et la décoration par IA. Idéal pour l'immobilier."
          : newLang === "es"
          ? "Explora la API de home staging virtual de Pedra.so. Transforma espacios con mejora de habitaciones, eliminación de muebles y diseño por IA. Ideal para inmobiliarias."
          : newLang === "pt"
          ? "Explore a API de home staging virtual da Pedra.so. Transforme espaços com melhoria de ambientes, remoção de móveis e design por IA. Ideal para imobiliárias."
          : newLang === "de"
          ? "Entdecken Sie die virtuelle Home Staging API von Pedra.so. Transformieren Sie Räume mit KI-gestützter Raumverbesserung, Möbelentfernung und Innenarchitektur. Ideal für Immobilien-Apps."
          : "Discover Pedra.so's virtual home staging API. Transform spaces with AI-powered room enhancement, furniture removal, and interior design. Ideal for real estate apps.";
      } else if (
        [
          "/fr/interiordesign",
          "/es/interiordesign",
          "/pt/interiordesign",
          "/de/innenarchitektur",
          "/interiordesign",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Con nuestra herramienta de diseño de interiores con IA tendrás la capacidad de crear espacios personalizados, optimizados y estéticamente únicos en minutos."
            : newLang === "fr"
            ? "Notre outil de design d'intérieur alimenté par l'IA vous permet de créer des espaces personnalisés, optimisés et esthétiquement uniques en quelques minutes."
            : newLang === "pt"
            ? "Com nossa ferramenta de design de interiores com IA, você terá a capacidade de criar espaços personalizados, otimizados e esteticamente únicos em minutos."
            : newLang === "de"
            ? "Mit unserem KI-gestützten Innenarchitektur-Tool können Sie in wenigen Minuten personalisierte, optimierte und ästhetisch einzigartige Räume erstellen."
            : "With our AI-powered interior design tool, you'll have the ability to create customized, optimized and aesthetically unique spaces in minutes.";
      } else if (
        currentUrl.includes("real-estate-photography") ||
        currentUrl.includes("immobilienfotografie") ||
        currentUrl.includes("fotografia-imobiliaria")
      ) {
        newDescription =
          newLang === "es"
            ? "Mejora tus fotografías inmobiliarias con IA: arregla perspectivas, mejora color, luz y definición en segundos de manera automática. Quita objetos innecesarios."
            : newLang === "fr"
            ? "Utilisez l'IA pour perfectionner facilement vos photos immobilières. Que vous souhaitiez ajuster la lumière, corriger les perspectives ou enlever les objets inutiles, Pedra offre une solution rapide et efficace pour mettre en valeur vos propriétés"
            : newLang === "pt"
            ? "Melhore suas fotografias imobiliárias com IA: corrija perspectivas, melhore cor, luz e definição em segundos automaticamente. Remova objetos desnecessários."
            : newLang === "de"
            ? "Verbessern Sie Ihre Immobilienfotografie mit KI: Korrigieren Sie Perspektiven, optimieren Sie Farbe, Licht und Auflösung automatisch in Sekunden. Entfernen Sie unerwünschte Objekte."
            : "Improve your real estate photography with AI: fix perspectives, improve color, light and resolution in seconds automatically. Remove unwanted objects.";
      } else if (currentUrl.includes("realestate")) {
        newDescription =
          newLang === "es"
            ? "Usa Pedra para inmobiliarias para crear renders, home staging virtuales, planos de planta, mejora de imagen y más en segundos. Pedra te ayuda a vender inmuebles."
            : newLang === "fr"
            ? "Découvrez comment Pedra peut transformer vos photos immobilières. Avec des outils puissants pour la rénovation, le home staging, et la suppression d'objets, améliorez vos annonces facilement. Essayez Pedra dès maintenant pour attirer plus d'acheteurs et vendre plus rapidement."
            : newLang === "pt"
            ? "Use Pedra para imobiliárias para criar renders, home staging virtual, plantas baixas, melhoria de imagem e mais em segundos. Pedra ajuda você a vender imóveis."
            : newLang === "de"
            ? "Nutzen Sie Pedra für Immobilien, um in Sekunden Renderings, virtuelles Home Staging, Grundrisse, Bildverbesserungen und mehr zu erstellen. Pedra hilft Ihnen beim Verkauf von Immobilien."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (
        currentUrl.includes("home-staging-virtual") ||
        currentUrl.includes("virtuelles-home-staging")
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging acelera tus ventas inmobiliarias. Te proporcionamos una herramienta para realizar un home staging virtual. Home staging fácil y rápido."
            : newLang === "fr"
            ? "Le home staging accélère vos ventes immobilières. Nous vous fournissons un outil pour réaliser un home staging virtuel. Home staging facile et rapide."
            : newLang === "pt"
            ? "O home staging acelera suas vendas imobiliárias. Fornecemos uma ferramenta para realizar home staging virtual. Home staging fácil e rápido."
            : newLang === "de"
            ? "Home Staging beschleunigt Ihre Immobilienverkäufe. Wir bieten Ihnen ein Tool für virtuelles Home Staging. Einfaches und schnelles Home Staging."
            : "Home staging speeds up your real estate sales. We provide you with a tool to create a virtual home staging. Easy and fast home staging.";
      } else if (
        currentUrl.includes("privacy") ||
        currentUrl.includes("datenschutz")
      ) {
        newDescription =
          newLang === "es"
            ? "Politica de Privacidad de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Découvrez la politique de confidentialité de Pedra.so. Informez-vous sur la collecte, l'utilisation, et la protection de vos données personnelles lors de l'utilisation de notre application."
            : newLang === "pt"
            ? "Política de Privacidade da Pedra, aplicativo com IA para criar propostas de renovação, home staging virtual e outras infografias."
            : newLang === "de"
            ? "Datenschutzerklärung von Pedra, einer KI-Anwendung zur Erstellung von Renovierungsvorschlägen, virtuellem Home Staging und anderen Rendertypen."
            : "Pedra's privacy policy, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (currentUrl.includes("legal")) {
        newDescription =
          newLang === "es"
            ? "Aviso legal de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Consultez les mentions légales de Pedra.so. Découvrez les informations sur le propriétaire, les conditions d'utilisation, et la protection des données personnelles."
            : newLang === "pt" || location.pathname.includes("/pt/")
            ? "Aviso legal da Pedra, aplicativo com IA para criar propostas de renovação, home staging virtual e outras infografias."
            : newLang === "de"
            ? "Rechtliche Hinweise zu Pedra, einer KI-Anwendung zur Erstellung von Renovierungsvorschlägen, virtuellem Home Staging und anderen Rendertypen."
            : "Pedra's legal notice, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (currentUrl.includes("about")) {
        newDescription =
          newLang === "es"
            ? "Soy Felix Ingla, I empecé Pedra, una herramienta online para transformar tus propiedades de inmobiliarias de manera fácil con inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez l'histoire de Pedra et de son fondateur, Felix Ingla. Apprenez comment Pedra est né pour répondre aux besoins du marché immobilier en offrant une solution simple et efficace pour améliorer la présentation des biens. Explorez notre engagement envers la satisfaction client et comment notre outil peut vous aider à optimiser vos photos immobilières et plus encore."
            : newLang === "pt"
            ? "Sou Felix Ingla, e comecei a Pedra, uma ferramenta online para transformar seus imóveis de forma fácil com inteligência artificial."
            : newLang === "de"
            ? "Ich bin Felix Ingla und ich habe Pedra gegründet, ein Online-Tool zur einfachen Transformation Ihrer Immobilien mit künstlicher Intelligenz."
            : "I'm Felix Ingla, and I started Pedra, an online tool to transform your real estate properties easily with artificial intelligence.";
      } else if (
        [
          "/es/blog/tendencias-mercado-inmobiliario",
          "/fr/blog/tendances-marche-immobilier",
          "/pt/blog/tendencias-mercado-imobiliario",
          "/de/blog/immobilienmarkt-trends",
          "/blog/real-estate-market-trends",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous explorons l'évolution du marché immobilier et les tendances actuelles du marché immobilier digital."
          : newLang === "es"
          ? "Exploramos cómo ha evolucionado el mercado inmobiliario y cuales son las tendencias actuales del mercado digital inmobiliario."
          : newLang === "pt"
          ? "Exploramos como o mercado imobiliário evoluiu e quais são as tendências atuais do mercado digital imobiliário."
          : newLang === "de"
          ? "Wir untersuchen, wie sich der Immobilienmarkt entwickelt hat und welche aktuellen Trends es im digitalen Immobilienmarkt gibt."
          : "We explore how the real estate market has evolved and what the current trends in the digital real estate market are.";
      } else if (
        [
          "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
          "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
          "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis",
          "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen",
          "/free-tools/generate-real-estate-listing-description-free",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Créez des annonces immobilières professionnelles instantanément avec notre générateur gratuit. Démarquez-vous et attirez plus d'acheteurs !"
          : newLang === "es"
          ? "Crea anuncios inmobiliarios profesionales al instante con nuestro generador gratuito. ¡Destácate en el mercado y atrae más compradores!"
          : newLang === "pt"
          ? "Crie anúncios imobiliários profissionais instantaneamente com nosso gerador gratuito. Destaque-se no mercado e atraia mais compradores!"
          : newLang === "de"
          ? "Erstellen Sie professionelle Immobilienanzeigen sofort mit unserem kostenlosen Generator. Heben Sie sich vom Markt ab und gewinnen Sie mehr Käufer!"
          : "Create professional real estate listings instantly with our free AI-powered description generator. Stand out in the market and attract more buyers!";
      } else if (
        [
          "/es/blog/espacios-multifuncionales",
          "/fr/blog/espaces-multifonctionnels",
          "/pt/blog/espacos-multifuncionais",
          "/de/blog/multifunktionale-raume",
          "/blog/multifunctional-spaces",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Les espaces multifonctionnels ont pour objectif de maximiser l'utilisation de l'espace disponible, en utilisant une même zone pour différentes fonctions."
          : location.pathname.includes("/pt/") || newLang === "pt"
          ? "Os espaços multifuncionais têm o objetivo de aproveitar ao máximo o espaço disponível, usando uma mesma área para diferentes funções."
          : newLang === "es"
          ? "Los espacios multifuncionales tienen el objetivo de aprovechar al máximo el espacio posible, usando una misma zona para diferentes funciones."
          : newLang === "pt"
          ? "Os espaços multifuncionais têm o objetivo de aproveitar ao máximo o espaço disponível, usando uma mesma área para diferentes funções."
          : newLang === "de"
          ? "Multifunktionale Räume zielen darauf ab, die Nutzung des verfügbaren Raums zu maximieren, indem derselbe Bereich für verschiedene Funktionen genutzt wird."
          : "Multifunctional spaces aim to maximize the use of available space by using the same area for different functions.";
      } else if (
        [
          "/es/blog/como-personalizar-habitacion",
          "/fr/blog/comment-personnaliser-chambre",
          "/pt/blog/como-personalizar-ambientes",
          "/de/blog/wie-man-raume-personalisiert",
          "/blog/how-to-personalize-room",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous vous montrons comment personnaliser votre chambre, les étapes à suivre et des conseils pour personnaliser votre espace."
          : newLang === "es"
          ? "Te enseñamos cómo personalizar tu habitación, los pasos a seguir y consejos para personalizar tu espacio."
          : newLang === "pt"
          ? "Mostramos como personalizar seu ambiente, os passos a seguir e dicas para personalizar seu espaço."
          : newLang === "de"
          ? "Wir zeigen Ihnen, wie Sie Ihren Raum personalisieren können, welche Schritte zu befolgen sind und geben Tipps zur Gestaltung Ihres Raums."
          : "We show you how to personalize your room, the steps to follow, and tips for customizing your space.";
      } else if (currentUrl.includes("render")) {
        newDescription =
          newLang === "es"
            ? "Crea renders con IA y prueba el software gratis para generar de forma rápida renders 3d con ayuda de la inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez comment utiliser l'IA pour créer des rendus immobiliers époustouflants. Téléchargez vos images et choisissez parmi divers styles de rénovation."
            : newLang === "pt"
            ? "Crie renders com IA e experimente o software grátis para gerar rapidamente renders 3D com ajuda da inteligência artificial."
            : newLang === "de"
            ? "Erstellen Sie Renderings mit KI und testen Sie die Software kostenlos, um schnell 3D-Renderings mit Hilfe künstlicher Intelligenz zu generieren."
            : "Create AI renders and try the software for free to quickly generate 3D renders with the help of artificial intelligence.";
      } else if (
        [
          "/es/releases/renueva-y-amuebla-en-un-clic",
          "/fr/versions/renovez-et-meublez-en-un-clic",
          "/pt/lancamentos/renovar-e-mobiliar-em-um-clique",
          "/releases/renovate-and-furnish-in-one-click",
          "/de/releases/renovieren-und-einrichten-mit-einem-klick",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez votre espace sans effort avec Pedra. Notre fonction 'Auto' améliorée offre de meilleurs résultats tout en préservant l'intégrité spatiale. Découvrez une rénovation et un ameublement parfaits en un seul clic."
          : newLang === "es"
          ? "Transforma tu espacio sin esfuerzo con Pedra. Nuestra función 'Auto' mejorada ofrece mejores resultados preservando la integridad espacial. Experimenta una renovación y amueblado perfectos con un solo clic."
          : newLang === "pt"
          ? "Transforme seu espaço sem esforço com a Pedra. Nossa função 'Auto' aprimorada oferece melhores resultados preservando a integridade espacial. Experimente uma renovação e mobília perfeitas com apenas um clique."
          : newLang === "de"
          ? "Transformieren Sie Ihren Raum mühelos mit Pedra. Unsere verbesserte 'Auto'-Funktion bietet bessere Ergebnisse bei Erhaltung der räumlichen Integrität. Erleben Sie perfekte Renovierung und Einrichtung mit nur einem Klick."
          : "Transform your space effortlessly with Pedra. Our enhanced 'Auto' feature offers better results while preserving spatial integrity. Experience seamless renovation and furnishing with just a single click.";
      } else if (
        [
          "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
          "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
          "/pt/blog/vender-mais-imoveis-com-ia",
          "/blog/sell-more-properties-with-ai",
          "/de/blog/mehr-immobilien-mit-ki-verkaufen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment l'IA révolutionne l'immobilier avec des rendus réalistes, home staging virtuel et plus encore. Apprenez à tirer parti de ces innovations pour optimiser vos ventes."
          : newLang === "es"
          ? "Una explicación sobre qué es la IA y cómo puede ayudar a acelerar a la venta de inmuebles por menos coste."
          : newLang === "pt"
          ? "Uma explicação sobre o que é IA e como ela pode ajudar a acelerar a venda de imóveis por um custo menor."
          : newLang === "de"
          ? "Eine Erklärung darüber, was KI ist und wie sie beim Verkauf von Immobilien zu geringeren Kosten helfen kann."
          : "An explanation about what is AI and how it can help on the sale of real estate properties";
      } else if (
        [
          "/es/blog/como-utilizar-ia-para-vender-propiedades",
          "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
          "/pt/blog/como-vender-imoveis-com-ia",
          "/blog/how-to-sell-properties-with-ai",
          "/de/blog/wie-man-immobilien-mit-ki-verkauft",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment l'IA aide les agents immobiliers à vendre plus efficacement, de la sélection d'images à la création de rendus de rénovation. Lisez notre article pour en savoir plus!"
          : newLang === "es"
          ? "La IA en inmobiliarias usa la tecnologías para mejorar y optimizar diversos aspectos automatizando procesos del sector inmobiliario. ¡Te mostramos cómo usarlo!"
          : newLang === "pt"
          ? "A IA no mercado imobiliário usa tecnologia para melhorar e otimizar diversos aspectos, automatizando processos do setor imobiliário. Mostramos como usá-la!"
          : newLang === "de"
          ? "KI in der Immobilienbranche nutzt Technologie zur Verbesserung und Optimierung verschiedener Aspekte durch Automatisierung von Prozessen im Immobiliensektor."
          : "AI in real estate uses technology to enhance and optimize various aspects by automating processes within the real estate sector.";
      } else if (
        [
          "/releases/empty-rooms-and-remove-objects-with-ai",
          "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
          "/fr/releases/vider-pieces-et-supprimer-objets-avec-ia",
          "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia",
          "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          location.pathname.includes("/pt/") || newLang === "pt"
            ? "Esvazie ambientes e remova objetos com IA. Esvazie espaços em segundos em 1 clique."
            : newLang === "es"
            ? "Vacia habitación y quita objetos con IA. Vacia espacios en segundos en 1 click."
            : newLang === "fr"
            ? "Videz les pièces et supprimez des objets avec l'IA. Videz les espaces en quelques secondes en 1 clic."
            : newLang === "pt"
            ? "Esvazie ambientes e remova objetos com IA. Esvazie espaços em segundos com 1 clique."
            : newLang === "de"
            ? "Leeren Sie Räume und entfernen Sie Objekte mit KI. Leeren Sie Räume in Sekunden mit 1 Klick."
            : "Empty rooms and remove objects with AI. Empty rooms in seconds with 1 click";
      } else if (
        [
          "/releases/renovate-interiors-with-ai-from-phone",
          "/es/releases/renueva-espacios-desde-el-movil-con-ia",
          "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone",
          "/pt/lancamentos/renovar-interiores-com-ia-pelo-celular",
          "/de/releases/innenraume-mit-ki-vom-handy-renovieren",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Renueva habitaciones con IA desde el móvil en segundos y facilmente"
            : newLang === "fr"
            ? "Rénovez les intérieurs avec l'IA depuis votre téléphone en quelques secondes et facilement"
            : newLang === "pt"
            ? "Renove ambientes com IA pelo celular em segundos e facilmente"
            : newLang === "de"
            ? "Renovieren Sie Innenräume mit KI direkt vom Handy aus in Sekunden und ganz einfach"
            : "Renovate interiors and rooms with AI directly from your phone in seconds and easily";
      } else if (
        [
          "/help/how-to-add-3d-objects",
          "/es/help/como-anadir-objetos-3d",
          "/fr/help/comment-ajouter-des-objets-3d",
          "/pt/ajuda/como-adicionar-objetos-3d",
          "/de/hilfe/wie-man-3d-objekte-hinzufugt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Apprenez à utiliser l'outil 'Add Object' pour créer des mises en scène virtuelles avec Pedra. Ajoutez, positionnez et redimensionnez des objets 3D facilement."
          : newLang === "es"
          ? "Cómo añadir objetos y muebles 3D a una imagen y crear un home staging virtual hiperrealista."
          : newLang === "pt"
          ? "Como adicionar objetos e móveis 3D a uma imagem e criar um home staging virtual hiper-realista."
          : newLang === "de"
          ? "Wie man 3D-Objekte und Möbel zu einem Bild hinzufügt und ein hyperrealistisches virtuelles Home Staging erstellt."
          : "How to add 3D objects and furniture to a room and create a virtual home staging from an image.";
      } else if (
        [
          "/help/how-to-remove-object",
          "/es/help/como-borrar-objeto",
          "/fr/help/comment-supprimer-un-objet",
          "/pt/ajuda/como-remover-objetos",
          "/de/hilfe/wie-man-objekte-entfernt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Apprenez à éliminer les objets et meubles indésirables de vos photos immobilières avec Pedra. Tutoriel étape par étape pour améliorer la présentation de vos propriétés."
          : newLang === "es"
          ? "Aprende a eliminar objetos y muebles no deseados de tus fotos inmobiliarias con Pedra. Tutorial paso a paso para mejorar la presentación de propiedades."
          : newLang === "pt"
          ? "Aprenda a eliminar objetos e móveis indesejados de suas fotos imobiliárias com a Pedra. Tutorial passo a passo para melhorar a apresentação de imóveis."
          : newLang === "de"
          ? "Lernen Sie, wie Sie unerwünschte Objekte und Möbel aus Ihren Immobilienfotos mit Pedra entfernen. Schritt-für-Schritt-Anleitung zur Verbesserung der Immobilienpräsentation."
          : "Learn how to eliminate unwanted objects and furniture from your real estate photos with Pedra. Step-by-step tutorial to enhance property presentation.";
      } else if (
        [
          "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
          "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",
          "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",
          "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio",
          "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment choisir et optimiser la photo de couverture de votre annonce immobilière pour attirer l'attention des acheteurs et accélérer la vente. Utilisez des outils comme Pedra pour améliorer vos photos."
          : newLang === "es"
          ? "Escoge y genera fotos de portada en tus anuncios inmobiliarios que capten la atención y te ayuden a vender"
          : newLang === "pt"
          ? "Escolha e gere fotos de capa em seus anúncios imobiliários que chamem a atenção e ajudem você a vender"
          : newLang === "de"
          ? "Wählen und erstellen Sie Titelbilder für Ihre Immobilienanzeigen, die Aufmerksamkeit erregen und Ihnen beim Verkauf helfen"
          : "Choose and generate cover photos in your real estate listings that catch attention and help you sell";
      } else if (
        [
          "/blog/how-to-make-sky-blue-real-estate-photo",
          "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles",
          "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres",
          "/pt/blog/como-fazer-ceu-azul-foto-imobiliaria",
          "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment améliorer vos photos immobilières en rendant le ciel bleu grâce à Photoshop, à des services professionnels, ou à des applications spécialisées comme Pedra"
          : newLang === "es"
          ? "Como poner el cielo azul y despejado en las fotografías de tus inmuebles"
          : newLang === "pt"
          ? "Como deixar o céu azul e limpo nas fotografias dos seus imóveis"
          : newLang === "de"
          ? "Wie man den Himmel in Immobilienfotos blau und klar macht"
          : "How to make the sky in your real estate photos blue easily";
      } else if (
        [
          "/blog/mistakes-when-selling-house",
          "/es/blog/errores-al-vender-casa",
          "/fr/blog/erreurs-vente-maison",
          "/pt/blog/erros-ao-vender-casa",
          "/de/blog/fehler-beim-hausverkauf",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Erreurs courantes lors de la vente d'une maison et comment les éviter : conseils clés pour réussir une vente sans perdre d'argent ni de temps."
          : newLang === "es"
          ? "Errores comunes al vender una casa y cómo evitarlos: consejos clave para lograr una venta exitosa sin perder dinero ni tiempo."
          : newLang === "pt"
          ? "Erros comuns ao vender uma casa e como evitá-los: dicas importantes para conseguir uma venda bem-sucedida sem perder dinheiro nem tempo."
          : newLang === "de"
          ? "Häufige Fehler beim Hausverkauf und wie man sie vermeidet: Wichtige Tipps für einen erfolgreichen Verkauf ohne Zeit- und Geldverlust."
          : "Common mistakes when selling a house and how to avoid them: key tips for achieving a successful sale without losing money or time.";
      } else if (
        [
          "/blog/home-staging-examples",
          "/es/blog/home-staging-ejemplos",
          "/fr/blog/exemples-de-home-staging",
          "/pt/blog/exemplos-home-staging",
          "/de/blog/home-staging-beispiele",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez des exemples de home staging avant et après pour des cuisines, salles de bain, chambres, salons et terrasses. Apprenez comment transformer vos espaces avec l'IA et le home staging virtuel."
          : newLang === "es"
          ? "Ejemplos de home staging. Fotografías de home staging antes y después."
          : newLang === "pt"
          ? "Exemplos de home staging. Fotografias de home staging antes e depois."
          : newLang === "de"
          ? "Home Staging Beispiele. Vorher-Nachher-Fotos von Home Staging Projekten."
          : "Home staging examples. Photographies of home staging: before and after.";
      } else if (
        [
          "/blog/buildable-land",
          "/es/blog/terrenos-urbanizables",
          "/fr/blog/terrains-constructibles",
          "/pt/blog/terreno-construivel",
          "/de/blog/bauland",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous expliquons ce que sont les terrains constructibles, les différents types de terrains constructibles et comment les préparer pour la vente."
          : newLang === "es"
          ? "Te explicamos qué son los terrenos urbanizables, los tipos de terrenos urbanizables que hay y cómo prepararlos para la venta."
          : newLang === "pt"
          ? "Explicamos o que são terrenos construtíveis, os tipos de terrenos construtíveis existentes e como prepará-los para venda."
          : newLang === "de"
          ? "Wir erklären, was Bauland ist, welche verschiedenen Arten von Bauland es gibt und wie man es für den Verkauf vorbereitet."
          : "We explain what buildable land is, the different types of buildable land available, and how to prepare them for sale.";
      } else if (
        [
          "/blog/property-that-wont-sell",
          "/es/blog/propiedad-no-se-vende",
          "/fr/blog/propriete-qui-ne-se-vend-pas",
          "/pt/blog/imovel-que-nao-vende",
          "/de/blog/immobilie-die-sich-nicht-verkauft",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Une propriété qui ne se vend pas est une situation complexe. Voici ce que vous pouvez faire pour vous aider à vendre une propriété qui ne trouve pas preneur."
          : newLang === "es"
          ? "Una propiedad que no se vende es una situación complicada. Aquí te explciamos qué puedes hacer para ayudarte a vender una propiedad que no se vende."
          : newLang === "pt"
          ? "Um imóvel que não vende é uma situação complicada. Aqui explicamos o que você pode fazer para ajudar a vender um imóvel que não está vendendo."
          : newLang === "de"
          ? "Eine Immobilie, die sich nicht verkauft, ist eine komplizierte Situation. Hier erklären wir, was Sie tun können, um eine Immobilie zu verkaufen, die sich nicht verkauft."
          : "A property that won't sell is a complicated situation. Here we explain what you can do to help sell a property that isn't selling.";
      } else if (
        [
          "/blog/real-estate-phrases",
          "/es/blog/frases-inmobiliarias",
          "/fr/blog/phrases-immobilieres",
          "/pt/blog/frases-imobiliarias",
          "/de/blog/immobilien-phrasen",
        ].some((path) => currentUrl.includes(path))
      ) {
        console.log(newLang);

        newDescription = location.pathname.includes("/fr/")
          ? "Phrases Immobilières pour attirer les clients, mettre en valeur et décrire les propriétés. Nous vous apprenons à rédiger vos phrases et donnons des exemples des meilleures phrases immobilières."
          : newLang === "es"
          ? "Frases Inmobiliarias para atraer clientes, destacar y describir inmuebles. Te enseñamos cómo redactar tus frases y ejemplos de las mejores frases inmobiliarias."
          : location.pathname.includes("/pt/")
          ? "Frases imobiliárias para atrair clientes, destacar e descrever imóveis. Ensinamos como redigir suas frases e exemplos das melhores frases imobiliárias."
          : newLang === "de"
          ? "Immobilien-Phrasen, um Kunden anzuziehen, Immobilien hervorzuheben und zu beschreiben. Wir zeigen Ihnen, wie Sie Ihre Phrasen formulieren und geben Beispiele für die besten Immobilien-Phrasen."
          : "Real Estate Phrases to attract clients, highlight and describe properties. We teach you how to write your phrases and provide examples of the best real estate phrases.";
      } else if (
        [
          "/blog/modernize-home",
          "/es/blog/modernizar-casa",
          "/fr/blog/moderniser-maison",
          "/pt/blog/modernizar-casa",
          "/de/blog/haus-modernisieren",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment vous pouvez moderniser votre maison avec les meubles que vous avez, donnez une seconde vie à vos meubles anciens et modernisez votre intérieur avec ces idées."
          : newLang === "es"
          ? "Descubre cómo puedes modernizar tu casa con los muebles que tengas, dale una segunda vida a tus muebles antiguos y moderniza tu hogar con estas ideas."
          : newLang === "pt"
          ? "Descubra como você pode modernizar sua casa com os móveis que tem, dê uma segunda vida aos seus móveis antigos e modernize seu lar com estas ideias."
          : newLang === "de"
          ? "Entdecken Sie, wie Sie Ihr Haus mit vorhandenen Möbeln modernisieren können, geben Sie Ihren alten Möbeln ein zweites Leben und modernisieren Sie Ihr Zuhause mit diesen Ideen."
          : "Discover how you can modernize your home with the furniture you have, give a second life to your antique furniture and modernize your home with these ideas.";
      } else if (
        [
          "/reviews-and-testimonials",
          "/es/reviews-and-testimonials",
          "/fr/reviews-and-testimonials",
          "/pt/reviews-and-testimonials",
          "/de/reviews-and-testimonials",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Opiniones, testimonios y valoraciones sobre Pedra, herramienta de home staging virtual y mejora de fotografías"
            : newLang === "fr"
            ? "Lisez les témoignages de nos clients satisfaits sur Pedra. Découvrez comment notre outil de home staging virtuel et d'amélioration de photos a transformé leur travail."
            : newLang === "pt"
            ? "Opiniões, depoimentos e avaliações sobre a Pedra, ferramenta de home staging virtual e melhoria de fotografias"
            : newLang === "de"
            ? "Lesen Sie die Bewertungen und Erfahrungsberichte über Pedra, das Tool für virtuelles Home Staging und Fotoverbesserung"
            : "Opinions, testimonials and reviews about Pedra, virtual home staging and photography enhancement tool";
      } else if (
        [
          "/blog/home-automation",
          "/es/blog/domotizar-casa",
          "/fr/blog/domotiser-maison",
          "/pt/blog/automacao-residencial",
          "/de/blog/hausautomatisierung",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Domotiser votre maison est un processus très simple qui offre un grand confort au quotidien. Nous vous montrons comment la domotiser et quels produits acheter."
          : newLang === "es"
          ? "Domotizar tu casa es un proceso muy sencillo que permite una gran comodidad diaria, te enseñamos cómo domotizarla y qué productos comprar."
          : newLang === "pt"
          ? "Automatizar sua casa é um processo muito simples que proporciona grande conforto diário. Mostramos como automatizá-la e quais produtos comprar."
          : newLang === "de"
          ? "Die Hausautomatisierung ist ein sehr einfacher Prozess, der großen täglichen Komfort bietet. Wir zeigen Ihnen, wie Sie Ihr Haus automatisieren und welche Produkte Sie kaufen sollten."
          : "Automating your home is a very simple process that provides great daily convenience. We show you how to automate it and what products to buy.";
      } else if (
        [
          "/blog/flipping",
          "/es/blog/flipping",
          "/fr/blog/flipping",
          "/pt/blog/flipping",
          "/de/blog/flipping",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment réussir dans le flipping immobilier : de l'étude de marché à la vente, optimisez chaque étape pour maximiser vos profits."
          : newLang === "es"
          ? "Descubre cómo hacer flipping inmobiliario exitoso: desde la investigación de mercado hasta la venta, optimiza cada paso para maximizar tus ganancias."
          : newLang === "pt"
          ? "Descubra como fazer flipping imobiliário de sucesso: da pesquisa de mercado até a venda, otimize cada etapa para maximizar seus lucros."
          : newLang === "de"
          ? "Entdecken Sie, wie Sie erfolgreich Immobilien flippen: von der Marktforschung bis zum Verkauf, optimieren Sie jeden Schritt, um Ihre Gewinne zu maximieren."
          : "Discover how to successfully flip real estate: from market research to selling, optimize every step to maximize your profits.";
      } else if (
        [
          "/blog/environmental-designer",
          "/es/blog/diseñador-ambientes",
          "/fr/blog/designer-environnement",
          "/pt/blog/designer-ambiental",
          "/de/blog/umweltdesigner",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez le rôle du designer d'environnement, ses compétences clés, ses processus créatifs et les tendances actuelles dans la conception d'environnements uniques."
          : newLang === "es"
          ? "Descubre el papel del diseñador de ambientes, sus habilidades clave, procesos creativos y tendencias actuales en el diseño de ambientes únicos."
          : newLang === "pt"
          ? "Descubra o papel do designer ambiental, suas habilidades principais, processos criativos e tendências atuais no design de ambientes únicos."
          : newLang === "de"
          ? "Entdecken Sie die Rolle des Umweltdesigners, seine Schlüsselkompetenzen, kreativen Prozesse und aktuellen Trends im Design einzigartiger Umgebungen."
          : "Discover the role of the environmental designer, their key skills, creative processes, and current trends in unique environmental design.";
      } else if (
        [
          "/blog/sunken-living-rooms",
          "/es/blog/salas-hundidas",
          "/fr/blog/salons-en-contrebas",
          "/pt/blog/salas-rebaixadas",
          "/de/blog/versenktes-wohnzimmer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez le charme des salons en contrebas, des espaces architecturaux uniques qui allient design moderne et confort pour créer des ambiances chaleureuses et élégantes."
          : newLang === "es"
          ? "Descubre el encanto de las salas hundidas, espacios arquitectónicos únicos que combinan diseño moderno con comodidad, creando ambientes acogedores y elegantes."
          : newLang === "pt"
          ? "Descubra o charme das salas rebaixadas, espaços arquitetônicos únicos que combinam design moderno com conforto, criando ambientes acolhedores e elegantes."
          : newLang === "de"
          ? "Entdecken Sie den Charme von versenkten Wohnzimmern, einzigartigen architektonischen Räumen, die modernes Design mit Komfort verbinden und gemütliche und elegante Umgebungen schaffen."
          : "Discover the charm of sunken living rooms, unique architectural spaces that combine modern design with comfort, creating cozy and elegant environments.";
      } else if (
        [
          "/blog/real-estate-trust",
          "/es/blog/fideicomiso-inmobiliario",
          "/fr/blog/fiducie-immobiliere",
          "/pt/blog/confianca-imobiliaria",
          "/de/blog/immobilien-vertrauen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "La fiducie immobilière est un contrat juridique entre un constituant qui transfère la propriété de certains biens à un fiduciaire chargé de les gérer."
          : newLang === "es"
          ? "El fideicomiso inmobiliario es un contrato legal entre un fiduciante que transfiere la propiedad de ciertos bienes a un fiduciario que se encarga de administrarlo."
          : newLang === "pt"
          ? "A confiança imobiliária é um contrato legal entre um fideicomitente que transfere a propriedade de certos bens a um fiduciário responsável por administrá-los."
          : newLang === "de"
          ? "Ein Immobilientrust ist ein rechtlicher Vertrag zwischen einem Treugeber, der das Eigentum an bestimmten Vermögenswerten an einen Treuhänder überträgt, der für deren Verwaltung verantwortlich ist."
          : "A real estate trust is a legal contract between a trustor who transfers ownership of certain assets to a trustee who is responsible for managing them.";
      } else if (
        [
          "/blog/short-sale",
          "/es/blog/short-sale",
          "/fr/blog/short-sale",
          "/pt/blog/venda-rapida",
          "/de/blog/schnellverkauf",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez ce qu'est une vente à découvert, ses avantages et les exigences pour les vendeurs et les acheteurs. Exemples de ventes à découvert sur le marché immobilier."
          : newLang === "es"
          ? "Descubre qué es un short sale, sus beneficios y requisitos para vendedores y compradores. Ejemplos de short sale en el mercado inmobiliario."
          : newLang === "pt"
          ? "Descubra o que é uma venda rápida, seus benefícios e requisitos para vendedores e compradores. Exemplos de venda rápida no mercado imobiliário."
          : newLang === "de"
          ? "Erfahren Sie, was ein Schnellverkauf ist, seine Vorteile und Anforderungen für Verkäufer und Käufer. Beispiele für Schnellverkäufe auf dem Immobilienmarkt."
          : "Discover what a short sale is, its benefits and requirements for sellers and buyers. Examples of short sales in the real estate market.";
      } else if (
        [
          "/help/how-to-renovate-houses-virtually",
          "/es/help/como-renovar-casas-virtualmente",
          "/fr/help/comment-renover-des-maisons-en-virtuel",
          "/pt/ajuda/como-renovar-casas-virtualmente",
          "/de/hilfe/wie-man-hauser-virtuell-renoviert",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Comment rénover virtuellement une pièce. Créez des rénovations virtuelles d'une maison en quelques secondes"
          : newLang === "es"
          ? "Cómo renovar virtualmente una habitación. Crea renovaciones virtuales de una casa en segundos."
          : newLang === "pt"
          ? "Como renovar virtualmente um ambiente. Crie renovações virtuais de uma casa em segundos."
          : newLang === "de"
          ? "Wie man einen Raum virtuell renoviert. Erstellen Sie virtuelle Renovierungen eines Hauses in Sekunden."
          : "How to virtually renovate a room. Create a virtual renovation of a house in seconds.";
      } else if (currentUrl.includes("add-3d-objects-and-make-sky-blue")) {
        newDescription =
          "Add 3D objects and make sky blue to create realistic virtual home stagings easily in seconds.";
      } else if (
        [
          "/fr/home-staging-salle-de-bain",
          "/es/home-staging-bano",
          "/pt/home-staging-banheiro",
          "/home-staging-bathroom",
          "/de/home-staging-badezimmer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de baño aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu baño. Home staging de baño efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de salle de bain augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre salle de bain. Home staging de salle de bain efficace et abordable."
            : newLang === "pt"
            ? "O home staging de banheiro aumenta o atrativo do seu imóvel. Oferecemos serviços especializados para transformar seu banheiro. Home staging de banheiro efetivo e acessível."
            : newLang === "de"
            ? "Badezimmer-Home-Staging steigert die Attraktivität Ihrer Immobilie. Wir bieten spezialisierte Dienstleistungen zur Transformation Ihres Badezimmers. Effektives und erschwingliches Badezimmer-Home-Staging."
            : "Bathroom home staging enhances your property's appeal. We offer specialized services to transform your bathroom. Effective and affordable bathroom home staging.";
      } else if (
        [
          "/fr/home-staging-terrasse",
          "/es/home-staging-terraza",
          "/pt/home-staging-terraco",
          "/home-staging-terrace",
          "/de/home-staging-terrasse",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de terraza aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu terraza. Home staging de terraza efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de terrasse augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre terrasse. Home staging de terrasse efficace et abordable."
            : newLang === "pt"
            ? "O home staging de terraço aumenta o atrativo do seu imóvel. Oferecemos serviços especializados para transformar seu terraço. Home staging de terraço efetivo e acessível."
            : newLang === "de"
            ? "Terrassen-Home-Staging steigert die Attraktivität Ihrer Immobilie. Wir bieten spezialisierte Dienstleistungen zur Transformation Ihrer Terrasse. Effektives und erschwingliches Terrassen-Home-Staging."
            : "Terrace home staging enhances your property's appeal. We offer specialized services to transform your terrace. Effective and affordable terrace home staging.";
      } else if (
        [
          "/fr/home-staging-cuisine",
          "/es/home-staging-cocina",
          "/pt/home-staging-cozinha",
          "/home-staging-kitchen",
          "/de/home-staging-kuche",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de cocina aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu cocina. Home staging de cocina efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de cuisine augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre cuisine. Home staging de cuisine efficace et abordable."
            : newLang === "pt"
            ? "O home staging de cozinha aumenta o atrativo do seu imóvel. Oferecemos serviços especializados para transformar sua cozinha. Home staging de cozinha efetivo e acessível."
            : newLang === "de"
            ? "Küchen-Home-Staging steigert die Attraktivität Ihrer Immobilie. Wir bieten spezialisierte Dienstleistungen zur Transformation Ihrer Küche. Effektives und erschwingliches Küchen-Home-Staging."
            : "Kitchen home staging enhances your property's appeal. We offer specialized services to transform your kitchen. Effective and affordable kitchen home staging.";
      } else if (currentUrl.includes("anade-objetos-3d-y-cielo-azul")) {
        newDescription =
          "Añade objetos 3D y pon el cielo azul para crear home staging virtuales realista en segundos de manera fácil.";
      } else if (
        [
          "/releases/add-3d-objects-and-make-sky-blue",
          "/es/releases/anade-objetos-3d-y-cielo-azul",
          "/fr/releases/ajouter-objets-3d-et-ciel-bleu",
          "/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul",
          "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Ajoutez des objets 3D et rendez le ciel bleu pour créer facilement des mises en scène virtuelles réalistes en quelques secondes."
          : newLang === "es"
          ? "Añade objetos 3D y pon el cielo azul para crear home staging virtuales realista en segundos de manera fácil."
          : newLang === "pt"
          ? "Adicione objetos 3D e deixe o céu azul para criar home staging virtual realista em segundos de maneira fácil."
          : newLang === "de"
          ? "Fügen Sie 3D-Objekte hinzu und machen Sie den Himmel blau, um realistisches virtuelles Home Staging einfach in Sekunden zu erstellen."
          : "Add 3D objects and make sky blue to create realistic virtual home stagings easily in seconds.";
      } else if (
        [
          "/es/blog/flipping",
          "/fr/blog/flipping",
          "/blog/flipping",
          "/pt/blog/flipping",
          "/de/blog/flipping",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment réussir dans le flipping immobilier : de l'étude de marché à la vente, optimisez chaque étape pour maximiser vos profits."
          : newLang === "es"
          ? "Descubre cómo hacer flipping inmobiliario exitoso: desde la investigación de mercado hasta la venta, optimiza cada paso para maximizar tus ganancias."
          : newLang === "pt"
          ? "Descubra como fazer flipping imobiliário com sucesso: desde a pesquisa de mercado até a venda, otimize cada etapa para maximizar seus lucros."
          : newLang === "de"
          ? "Entdecken Sie erfolgreiches Immobilien-Flipping: von der Marktforschung bis zum Verkauf, optimieren Sie jeden Schritt zur Gewinnmaximierung."
          : "Discover how to successfully flip real estate: from market research to selling, optimize every step to maximize your profits.";
      } else if (currentUrl.includes("help/how-to-remove-object")) {
        newDescription =
          "Learn how to eliminate unwanted objects and furniture from your real estate photos with Pedra. Step-by-step tutorial to enhance property presentation.";
      } else if (
        [
          "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
          "/fr/versions/renovez-et-meublez-en-un-clic",
          "/releases/renovate-and-furnish-in-one-click",
          "/pt/lancamentos/renovar-e-mobiliar-em-um-clique",
          "/de/releases/renovieren-und-einrichten-mit-einem-klick",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez votre espace sans effort avec Pedra. Notre fonction 'Auto' améliorée offre de meilleurs résultats tout en préservant l'intégrité spatiale. Découvrez une rénovation et un ameublement parfaits en un seul clic."
          : newLang === "es"
          ? "Transforma tu espacio sin esfuerzo con Pedra. Nuestra función 'Auto' mejorada ofrece mejores resultados preservando la integridad espacial. Experimenta una renovación y amueblado perfectos con un solo clic."
          : newLang === "pt"
          ? "Transforme seu espaço sem esforço com a Pedra. Nossa função 'Auto' aprimorada oferece melhores resultados enquanto preserva a integridade espacial. Experimente uma renovação e mobília perfeitas com apenas um clique."
          : newLang === "de"
          ? "Transformieren Sie Ihren Raum mühelos mit Pedra. Unsere verbesserte 'Auto'-Funktion bietet bessere Ergebnisse bei Erhaltung der räumlichen Integrität. Erleben Sie perfekte Renovierung und Einrichtung mit nur einem Klick."
          : "Transform your space effortlessly with Pedra. Our enhanced 'Auto' feature offers better results while preserving spatial integrity. Experience seamless renovation and furnishing with just a single click.";
      } else if (currentUrl.includes("es/help/como-borrar-objeto")) {
        newDescription =
          "Aprende a eliminar objetos y muebles no deseados de tus fotos inmobiliarias con Pedra. Tutorial paso a paso para mejorar la presentación de propiedades.";
      } else if (
        [
          "/es/blog/salas-hundidas",
          "/fr/blog/salons-en-contrebas",
          "/blog/sunken-living-rooms",
          "/pt/blog/salas-rebaixadas",
          "/de/blog/versenktes-wohnzimmer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez le charme des salons en contrebas, des espaces architecturaux uniques qui allient design moderne et confort pour créer des ambiances chaleureuses et élégantes."
          : newLang === "es"
          ? "Descubre el encanto de las salas hundidas, espacios arquitectónicos únicos que combinan diseño moderno con comodidad, creando ambientes acogedores y elegantes."
          : newLang === "pt"
          ? "Descubra o charme das salas rebaixadas, espaços arquitetônicos únicos que combinam design moderno com conforto, criando ambientes acolhedores e elegantes."
          : newLang === "de"
          ? "Entdecken Sie den Charme von versenkten Wohnzimmern, einzigartigen architektonischen Räumen, die modernes Design mit Komfort verbinden und gemütliche und elegante Umgebungen schaffen."
          : "Discover the charm of sunken living rooms, unique architectural spaces that combine modern design with comfort, creating cozy and elegant environments.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel",
          "/help/how-to-create-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual",
          "/pt/ajuda/como-criar-home-staging-virtual",
          "/de/hilfe/wie-man-virtuelles-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel avec notre guide simple. Transformez des espaces vides en pièces meublées attrayantes."
          : newLang === "es"
          ? "Domina el home staging virtual con nuestra guía fácil. Transforma espacios vacíos en habitaciones amuebladas y atractivas."
          : newLang === "pt"
          ? "Domine o home staging virtual com o nosso guia fácil. Transforme espaços vazios em ambientes atraentes e mobiliados."
          : newLang === "de"
          ? "Meistern Sie virtuelles Home Staging mit unserem einfachen Leitfaden. Verwandeln Sie leere Räume in attraktiv möblierte Zimmer."
          : "Master virtual home staging with our easy guide. Transform empty spaces into attractive, furnished rooms.";
      } else if (
        [
          "/fr/blog/marketing-immobilier",
          "/blog/real-estate-marketing",
          "/es/blog/marketing-inmobiliario",
          "/pt/blog/marketing-imobiliario",
          "/de/blog/immobilien-marketing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Le marketing immobilier est un ensemble de stratégies et de techniques conçues pour vendre des propriétés immobilières. Découvrez les types et comment les appliquer."
          : newLang === "es"
          ? "El marketing inmobiliario es un conjunto de estrategias y técnicas diseñadas para vender propiedades inmobiliarias, descubre los tipos y cómo aplicarlo."
          : newLang === "pt"
          ? "O marketing imobiliário é um conjunto de estratégias e técnicas projetadas para vender propriedades imobiliárias. Descubra os tipos e como aplicá-los."
          : newLang === "de"
          ? "Immobilienmarketing ist ein Set von Strategien und Techniken, die für den Verkauf von Immobilien entwickelt wurden. Entdecken Sie die verschiedenen Arten und deren Anwendung."
          : "Real estate marketing is a set of strategies and techniques designed to sell real estate properties. Discover the types and how to apply them.";
      } else if (
        [
          "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
          "/releases/realistic-furnishing",
          "/es/releases/amueblado-realista",
          "/pt/lancamentos/mobiliario-realista",
          "/de/releases/realistische-einrichtung",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez les dernières améliorations IA de Pedra : ameublement ultra-réaliste, styles de rénovation personnalisés et préservation améliorée des fenêtres. Optimisez vos visuels !"
          : newLang === "es"
          ? "Explora las últimas mejoras de IA de Pedra: amueblado super realista, estilos de renovación personalizados y preservación mejorada de ventanas. ¡Optimiza tus visualizaciones!"
          : newLang === "pt"
          ? "Descubra as últimas melhorias de IA da Pedra: mobília super realista, estilos de renovação personalizados e preservação aprimorada das janelas. Otimize suas visualizações!"
          : newLang === "de"
          ? "Entdecken Sie Pedras neueste KI-Verbesserungen: super-realistische Einrichtung, individuelle Renovierungsstile und verbesserte Fenstererhaltung. Optimieren Sie Ihre Immobilienvisualisierungen jetzt!"
          : "Discover Pedra's latest AI improvements: super-realistic furnishing, custom renovation styles, and enhanced window preservation. Upgrade your property visualizations now!";
      } else if (
        [
          "/blog/real-estate",
          "/es/blog/bienes-raices",
          "/fr/blog/immobilier",
          "/pt/blog/imoveis",
          "/de/blog/immobilien",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Guide complet de l'immobilier: conseils et tendances"
          : newLang === "es"
          ? "Guía completa de bienes raíces: consejos y tendencias"
          : newLang === "pt"
          ? "Guia completo de imóveis: dicas e tendências"
          : newLang === "de"
          ? "Umfassender Immobilienführer: Tipps und Trends"
          : "Complete real estate guide: tips and market trends";
      } else if (
        [
          "/fr/blog/tendances-marche-immobilier",
          "/blog/real-estate-market-trends",
          "/es/blog/tendencias-mercado-inmobiliario",
          "/pt/blog/tendencias-mercado-imobiliario",
          "/de/blog/immobilienmarkt-trends",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous explorons l'évolution du marché immobilier et les tendances actuelles du marché immobilier digital."
          : newLang === "es"
          ? "Exploramos cómo ha evolucionado el mercado inmobiliario y cuales son las tendencias actuales del mercado digital inmobiliario."
          : newLang === "pt"
          ? "Exploramos como o mercado imobiliário evoluiu e quais são as tendências atuais do mercado imobiliário digital."
          : newLang === "de"
          ? "Wir untersuchen die Entwicklung des Immobilienmarktes und die aktuellen Trends im digitalen Immobilienmarkt."
          : "We explore how the real estate market has evolved and what the current trends in the digital real estate market are.";
      } else if (
        [
          "/fr/blog/augmentez-valeur-maison",
          "/blog/increase-home-value",
          "/es/blog/aumenta-valor-vivienda",
          "/pt/blog/aumentar-valor-imovel",
          "/de/blog/hauswert-steigern",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous vous montrons comment augmenter la valeur de votre maison rapidement et facilement avec plusieurs conseils et astuces pour améliorer votre maison avant de la vendre &#127969;"
          : newLang === "es"
          ? "Te enseñamos cómo aumentar el valor de la vivienda de forma rápida y sencilla con varios consejos y tips para mejorar tu hogar antes de venderlo &#127969;"
          : newLang === "pt"
          ? "Mostramos como aumentar o valor do seu imóvel de forma rápida e fácil com várias dicas e truques para melhorar sua casa antes de vendê-la &#127969;"
          : newLang === "de"
          ? "Wir zeigen Ihnen, wie Sie den Wert Ihres Hauses schnell und einfach steigern können, mit verschiedenen Tipps und Tricks zur Verbesserung Ihres Hauses vor dem Verkauf &#127969;"
          : "We show you how to increase the value of your home quickly and easily with several tips and advice to improve your home before selling it &#127969;";
      } else if (
        [
          "/fr/interiordesign",
          "/es/interiordesign",
          "/interiordesign",
          "/pt/interiordesign",
          "/de/innenarchitektur",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Con nuestra herramienta de diseño de interiores con IA tendrás la capacidad de crear espacios personalizados, optimizados y estéticamente únicos en minutos."
            : newLang === "fr"
            ? "Notre outil de design d'intérieur alimenté par l'IA vous permet de créer des espaces personnalisés, optimisés et esthétiquement uniques en quelques minutes."
            : newLang === "pt"
            ? "Com a nossa ferramenta de design de interiores alimentada por IA, você terá a capacidade de criar espaços personalizados, otimizados e esteticamente únicos em minutos."
            : newLang === "de"
            ? "Mit unserem KI-gestützten Innenarchitektur-Tool können Sie in wenigen Minuten personalisierte, optimierte und ästhetisch einzigartige Räume erstellen."
            : "With our AI-powered interior design tool, you'll have the ability to create customized, optimized and aesthetically unique spaces in minutes.";
      } else if (
        currentUrl.includes("real-estate-photography") ||
        currentUrl.includes("immobilienfotografie") ||
        currentUrl.includes("fotografia-imobiliaria")
      ) {
        newDescription =
          newLang === "es"
            ? "Mejora tus fotografías inmobiliarias con IA: arregla perspectivas, mejora color, luz y definición en segundos de manera automática. Quita objetos innecesarios."
            : newLang === "fr"
            ? "Utilisez l'IA pour perfectionner facilement vos photos immobilières. Que vous souhaitiez ajuster la lumière, corriger les perspectives ou enlever les objets inutiles, Pedra offre une solution rapide et efficace pour mettre en valeur vos propriétés"
            : newLang === "pt"
            ? "Melhore suas fotografias imobiliárias com IA: corrija perspectivas, melhore a cor, luz e resolução em segundos automaticamente. Remova objetos indesejados."
            : newLang === "de"
            ? "Verbessern Sie Ihre Immobilienfotografie mit KI: Korrigieren Sie Perspektiven, optimieren Sie Farbe, Licht und Auflösung automatisch in Sekunden. Entfernen Sie unerwünschte Objekte."
            : "Improve your real estate photography with AI: fix perspectives, improve color, light and resolution in seconds automatically. Remove unwanted objects.";
      } else if (currentUrl.includes("realestate")) {
        newDescription =
          newLang === "es"
            ? "Usa Pedra para inmobiliarias para crear renders, home staging virtuales, planos de planta, mejora de imagen y más en segundos. Pedra te ayuda a vender inmuebles."
            : newLang === "fr"
            ? "Découvrez comment Pedra peut transformer vos photos immobilières. Avec des outils puissants pour la rénovation, le home staging, et la suppression d'objets, améliorez vos annonces facilement. Essayez Pedra dès maintenant pour attirer plus d'acheteurs et vendre plus rapidement."
            : newLang === "pt"
            ? "Use o Pedra para imobiliárias para criar renders, home stagings virtuais, plantas baixas, melhorias de imagem e muito mais em segundos. O Pedra ajuda a vender imóveis."
            : newLang === "de"
            ? "Nutzen Sie Pedra für Immobilien, um in Sekunden Renderings, virtuelles Home Staging, Grundrisse, Bildverbesserungen und mehr zu erstellen. Pedra hilft Ihnen beim Verkauf von Immobilien."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("como-vender-casa")) {
        newDescription =
          newLang === "es"
            ? "Consejos y recomendaciones de cómo vender tu casa rápido, desde qué debes tener en cuenta antes de vender tu casa hasta cómo prepararla para venderla rápido."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("renovar-casa")) {
        newDescription =
          newLang === "es"
            ? "Renueva tu casa rápido, sin obras y con 6 ideas de cómo remodelar tu casa y qué necesitas para ello. Además de las herramientas que puedes usar para renovarla."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (
        currentUrl.includes("pricing") ||
        currentUrl.includes("preise") ||
        currentUrl.includes("precos")
      ) {
        newDescription =
          newLang === "es"
            ? "Usa Pedra para generar renders inmobiliarios gratis. Usa el Plan Pro por 29€ al mes. Cancela en cualquier momento."
            : newLang === "fr"
            ? "Découvrez notre forfait Pro à 29€/mois avec 100 générations d'images, rénovation, home staging et plus. Annulez à tout moment. Essayez Pedra maintenant!"
            : newLang === "pt"
            ? "Use o Pedra para gerar renders imobiliários grátis. Use o plano Pro por 29€/mês. Cancele a qualquer momento."
            : newLang === "de"
            ? "Testen Sie Pedra, um Renderings zu erstellen. Nutzen Sie den Pro-Plan für 29€ pro Monat. Jederzeit kündbar."
            : "Try Pedra to generate renders. Use the Pro Plan for €29 per month. Cancel anytime.";
      } else if (currentUrl.includes("render")) {
        newDescription =
          newLang === "es"
            ? "Crea renders con IA y prueba el software gratis para generar de forma rápida renders 3d con ayuda de la inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez comment utiliser l'IA pour créer des rendus immobiliers époustouflants. Téléchargez vos images et choisissez parmi divers styles de rénovation."
            : newLang === "pt"
            ? "Crie renders com IA e experimente o software gratuitamente para gerar rapidamente renders 3D com a ajuda da inteligência artificial."
            : newLang === "de"
            ? "Erstellen Sie KI-Renderings und testen Sie die Software kostenlos, um schnell 3D-Renderings mit Hilfe künstlicher Intelligenz zu generieren."
            : "Create AI renders and try the software for free to quickly generate 3D renders with the help of artificial intelligence.";
      } else if (
        currentUrl.includes("home-staging-virtual") ||
        currentUrl.includes("virtuelles-home-staging")
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging acelera tus ventas inmobiliarias. Te proporcionamos una herramienta para realizar un home staging virtual. Home staging fácil y rápido."
            : newLang === "fr"
            ? "Le home staging accélère vos ventes immobilières. Nous vous fournissons un outil pour réaliser un home staging virtuel. Home staging facile et rapide."
            : newLang === "pt"
            ? "O home staging acelera suas vendas imobiliárias. Oferecemos uma ferramenta para criar home staging virtual. Home staging fácil e rápido."
            : newLang === "de"
            ? "Home Staging beschleunigt Ihre Immobilienverkäufe. Wir bieten Ihnen ein Tool für virtuelles Home Staging. Einfaches und schnelles Home Staging."
            : "Home staging speeds up your real estate sales. We provide you with a tool to create a virtual home staging. Easy and fast home staging.";
      } else if (currentUrl.includes("es/mls")) {
        newDescription =
          "Proveedor oficial de IA de la MLS: propuestas de renovación con IA, home staging virtual, vaciado de habitaciones y más.";
      } else if (currentUrl.includes("privacy")) {
        newDescription =
          newLang === "es"
            ? "Política de Privacidad de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Découvrez la politique de confidentialité de Pedra.so. Informez-vous sur la collecte, l'utilisation, et la protection de vos données personnelles lors de l'utilisation de notre application."
            : newLang === "pt"
            ? "Política de Privacidade da Pedra, aplicativo com IA para criar propostas de renovação, home staging virtual e outros tipos de infográficos."
            : newLang === "de"
            ? "Pedras Datenschutzerklärung, eine KI-Anwendung zur Erstellung von Renovierungsvorschlägen, virtuellem Home Staging und anderen Rendertypen."
            : "Pedra's privacy policy, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (
        [
          "/fr/legal",
          "/es/legal",
          "/pt/legal",
          "/de/rechtliches",
          "/legal",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "Aviso legal de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Consultez les mentions légales de Pedra.so. Découvrez les informations sur le propriétaire, les conditions d'utilisation, et la protection des données personnelles."
            : newLang === "pt" || location.pathname.includes("/pt/")
            ? "Aviso legal da Pedra, aplicativo com IA para criar propostas de renovação, home staging virtual e outros tipos de infográficos."
            : newLang === "de"
            ? "Rechtliche Hinweise von Pedra, einer KI-Anwendung zur Erstellung von Renovierungsvorschlägen, virtuellem Home Staging und anderen Rendertypen."
            : "Pedra's legal notice, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newDescription =
          newLang === "es"
            ? "Opiniones, testimonios y valoraciones sobre Pedra, herramienta de home staging virtual y mejora de fotografías"
            : newLang === "fr"
            ? "Lisez les témoignages de nos clients satisfaits sur Pedra. Découvrez comment notre outil de home staging virtuel et d'amélioration de photos a transformé leur travail."
            : newLang === "pt"
            ? "Opiniões, depoimentos e avaliações sobre Pedra, ferramenta de home staging virtual e melhoria de fotografias"
            : newLang === "de"
            ? "Meinungen, Testimonials und Bewertungen über Pedra, Tool für virtuelles Home Staging und Fotoverbesserung"
            : "Opinions, testimonials and reviews about Pedra, virtual home staging and photography enhancement tool";
      } else if (currentUrl.includes("help/how-to-add-3d-objects")) {
        newDescription =
          "How to add 3D objects and furniture to a room and create a virtual home staging from an image.";
      } else if (currentUrl.includes("/es/help/como-anadir-objetos-3d")) {
        newDescription =
          "Cómo añadir objetos y muebles 3D a una imagen y crear un home staging virtual hiperrealista.";
      } else if (
        [
          "/fr/releases/vide-chambres-et-enlever-objets-avec-ia",
          "/releases/empty-rooms-and-remove-objects-with-ai",
          "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
          "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia",
          "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nouvelle fonctionnalité : Supprimez automatiquement les meubles et objets de vos photos avec notre outil d'IA. Découvrez notre dernière mise à jour de janvier 2024."
          : newLang === "es"
          ? "Nueva función: Elimina automáticamente muebles y objetos de tus fotos con nuestra herramienta de IA. Descubre nuestra última actualización de enero 2024."
          : newLang === "pt"
          ? "Nova funcionalidade: Remova automaticamente móveis e objetos de suas fotos com nossa ferramenta de IA. Descubra nossa última atualização de janeiro 2024."
          : newLang === "de"
          ? "Neue Funktion: Entfernen Sie automatisch Möbel und Objekte aus Ihren Fotos mit unserem KI-Tool. Entdecken Sie unser neuestes Update vom Januar 2024."
          : "New feature: Automatically remove furniture and objects from your photos with our AI tool. Discover our latest January 2024 update.";
      } else if (
        [
          "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu",
          "/releases/add-3d-objects-and-make-sky-blue",
          "/es/releases/anade-objetos-3d-y-cielo-azul",
          "/pt/releases/adicionar-objetos-3d-e-fazer-ceu-azul",
          "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment ajouter des objets 3D et un ciel bleu dans vos rendus. Dernières mises à jour de Pedra pour améliorer vos visualisations."
          : newLang === "es"
          ? "Aprende a añadir objetos 3D y cielo azul en tus renders. Últimas actualizaciones de Pedra para mejorar tus visualizaciones."
          : newLang === "pt"
          ? "Aprenda a adicionar objetos 3D e céu azul em seus renders. Últimas atualizações do Pedra para melhorar suas visualizações."
          : newLang === "de"
          ? "Lernen Sie, wie Sie 3D-Objekte und blauen Himmel zu Ihren Renderings hinzufügen. Neueste Pedra-Updates zur Verbesserung Ihrer Visualisierungen."
          : "Learn how to add 3D objects and blue sky to your renders. Latest Pedra updates to enhance your visualizations.";
      } else if (
        currentUrl.includes("releases") ||
        currentUrl.includes("lancamentos")
      ) {
        newDescription =
          newLang === "es"
            ? "Novedades en herramienta de home staging virtual y fotografía inmobiliaria"
            : newLang === "fr"
            ? "Découvrez les mises à jour récentes de Pedra pour mai, juin et juillet 2024 : nouvelles fonctionnalités, améliorations d'interface, et plus encore pour chaque mois."
            : newLang === "pt"
            ? "Novidades sobre ferramenta de home staging virtual e melhoria de fotografia imobiliária"
            : newLang === "de"
            ? "Neuigkeiten über virtuelles Home Staging Tool und Immobilienfotografie-Verbesserung"
            : "Releases on virtual home staging tool and real estate photography improvement";
      } else if (currentUrl.includes("blog")) {
        newDescription =
          newLang === "es"
            ? "Blog sobre Home Staging Virtual y Fotografía Real Estate, creado y mantenido por Pedra"
            : newLang === "fr"
            ? "Découvrez les articles récents de Pedra sur l'utilisation de l'IA dans l'immobilier. Apprenez à améliorer vos photos, accélérer les ventes, et plus encore."
            : newLang === "pt"
            ? "Blog sobre Home Staging Virtual e Fotografia Imobiliária, criado e mantido pela Pedra"
            : newLang === "de"
            ? "Blog über virtuelles Home Staging und Immobilienfotografie, erstellt und gepflegt von Pedra"
            : "Blog about Virtual Home Staging and Real Estate Photography, created and maintained by Pedra";
      } else if (
        ["/help", "/es/help", "/fr/help", "/pt/ajuda", "/de/hilfe"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Apprenez à mettre en valeur vos annonces immobilières virtuellement avec nos guides étape par étape, conseils et meilleures pratiques. Transformez les espaces vides en intérieurs meublés magnifiques."
          : newLang === "es"
          ? "Aprenda a realizar home staging virtual para sus anuncios inmobiliarios con nuestras guías paso a paso, consejos y mejores prácticas. Transforme espacios vacíos en hogares amueblados impresionantes."
          : newLang === "pt"
          ? "Aprenda a fazer home staging virtual para seus anúncios imobiliários com nossos guias passo a passo, dicas e melhores práticas. Transforme espaços vazios em lares mobiliados impressionantes."
          : newLang === "de"
          ? "Lernen Sie, wie Sie Ihre Immobilienanzeigen virtuell inszenieren können mit unseren Schritt-für-Schritt-Anleitungen, Tipps und Best Practices. Verwandeln Sie leere Räume in beeindruckend möblierte Wohnungen."
          : "Learn how to virtually stage your real estate listings with our step-by-step guides, tips, and best practices. Transform empty spaces into stunning, furnished homes instantly.";
      } else {
        newDescription =
          newLang === "es"
            ? "Transforma tus propiedades en segundos con IA. Amuebla, vacía y renueva espacios automáticamente. Home staging virtual desde 29€/mes sin permanencia. Resultados hiperrealistas en 1 click."
            : newLang === "fr"
            ? "Transformez vos propriétés en quelques secondes avec l'IA. Meublez, videz et rénovez les espaces automatiquement. Home staging virtuel à partir de 29€/mois sans engagement. Résultats hyperréalistes en 1 clic."
            : newLang === "pt"
            ? "Transforme suas propriedades em segundos com IA. Mobilie, esvazie e renove espaços automaticamente. Home staging virtual a partir de 29€/mês sem fidelização. Resultados hiper-realistas em 1 clique."
            : newLang === "de"
            ? "Transformieren Sie Ihre Immobilien in Sekunden mit KI. Möblieren, leeren und renovieren Sie Räume automatisch. Virtuelles Home Staging ab 29€/Monat ohne Bindung. Hyperrealistische Ergebnisse mit 1 Klick."
            : "Transform your properties in seconds with AI. Furnish, empty and renovate spaces automatically. Virtual home staging from €29/month with no commitment. Hyper-realistic results in 1 click.";
      }

      metaDescription.content = newDescription;
    }

    // Update the title based on the language
    const pageTitle = document.querySelector("title");
    if (pageTitle) {
      const currentUrl = window.location.href;
      let newTitle = "";

      if (
        [
          currentUrl.includes("so/floorplan") ||
            currentUrl.includes("fr/floorplan") ||
            currentUrl.includes("es/floorplan") ||
            currentUrl.includes("pt/planta-baixa") ||
            currentUrl.includes("de/grundriss"),
        ].some(Boolean)
      ) {
        newTitle =
          newLang === "es"
            ? "Dibuja planos de planta | Crea planos online"
            : newLang === "fr"
            ? "Outil de Création de Plans d'Étage Facile"
            : newLang === "pt"
            ? "Criador de Plantas Baixas | Ferramenta Fácil de Plantas Baixas"
            : newLang === "de"
            ? "Grundrisszeichner | Einfaches Grundriss-Tool"
            : "Floor Plan Creator | Easy Floor Plan Tool";
      } else if (
        [
          "/blog/property-listing-optimization",
          "/es/blog/optimizacion-de-anuncios-inmobiliarios",
          "/fr/blog/optimisation-des-annonces-immobilieres",
          "/de/blog/optimierung-von-immobilienanzeigen",
          "/pt/blog/otimizacao-de-anuncios-imobiliarios",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Optimisation des Annonces Immobilières: Stratégies Clés pour le Succès en 2025"
          : location.pathname.includes("/es/")
          ? "Optimización de Listados de Propiedades: Estrategias Clave para el Éxito en 2025"
          : location.pathname.includes("/de/")
          ? "Optimierung von Immobilienanzeigen: Schlüsselstrategien für den Erfolg im Jahr 2025"
          : location.pathname.includes("/pt/")
          ? "Otimização de Anúncios Imobiliários: Estratégias Essenciais para o Sucesso em 2025"
          : "Property Listing Optimization: Key Strategies for Success in 2025";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salle-de-bain",
          "/help/how-to-create-bathroom-render",
          "/es/help/como-crear-render-bano",
          "/pt/ajuda/como-criar-render-banheiro",
          "/de/hilfe/wie-man-badezimmer-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rendu de Salle de Bain | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía Render de Baño | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia Render de Banheiro | Tutorial Passo a Passo"
          : newLang === "de"
          ? "Badezimmer-Rendering Guide | Schritt-für-Schritt"
          : "Bathroom Rendering Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/fr/blog/tendances-marche-immobilier",
          "/blog/real-estate-market-trends",
          "/es/blog/tendencias-mercado-inmobiliario",
          "/pt/blog/tendencias-mercado-imobiliario",
          "/de/blog/immobilienmarkt-trends",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Tendances actuelles du marché immobilier"
          : newLang === "es"
          ? "Tendencias actuales del mercado inmobiliario"
          : newLang === "pt"
          ? "Tendências atuais do mercado imobiliário"
          : newLang === "de"
          ? "Aktuelle Immobilienmarkttrends"
          : "Current Real Estate Market Trends";
      } else if (
        [
          "/fr/blog/marketing-immobilier",
          "/blog/real-estate-marketing",
          "/es/blog/marketing-inmobiliario",
          "/pt/blog/marketing-imobiliario",
          "/de/blog/immobilien-marketing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Marketing Immobilier : Qu'est-ce que c'est et comment l'appliquer ?"
          : newLang === "es"
          ? "Marketing inmobiliario ¿Qué es y cómo aplicarlo?"
          : newLang === "pt"
          ? "Marketing Imobiliário: O que é e como aplicar?"
          : newLang === "de"
          ? "Immobilien-Marketing: Was ist das und wie wendet man es an?"
          : "Real Estate Marketing: What It Is and How to Apply It.";
      } else if (
        [
          "/fr/help/comment-dessiner-plan",
          "/help/how-to-draw-floor-plan",
          "/es/help/como-dibujar-plano",
          "/pt/ajuda/como-desenhar-planta-baixa",
          "/de/hilfe/wie-man-grundriss-zeichnet",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide de création de plans d'étage | Tutoriel simple"
          : newLang === "es"
          ? "Guía para dibujar planos | Tutorial paso a paso"
          : newLang === "pt"
          ? "Guia de plantas baixas | Tutorial passo a passo"
          : newLang === "de"
          ? "Grundriss zeichnen | Einfache Anleitung"
          : "Floor Plan Drawing Guide | Easy Step-by-Step Tutorial";
      } else if (
        [
          "/blog/modernize-home",
          "/es/blog/modernizar-casa",
          "/fr/blog/moderniser-maison",
          "/pt/blog/modernizar-casa",
          "/de/blog/haus-modernisieren",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment moderniser votre maison | Guide complet"
          : newLang === "es"
          ? "Cómo modernizar tu casa | Guía completa"
          : newLang === "pt"
          ? "Como modernizar sua casa | Guia completo"
          : newLang === "de"
          ? "Wie Sie Ihr Haus modernisieren | Kompletter Leitfaden"
          : "How to modernize your home | Complete guide";
      } else if (
        [
          "/es/releases/amueblado-realista",
          "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
          "/pt/lancamentos/mobiliario-realista",
          "/de/releases/realistische-einrichtung",
          "/releases/realistic-furnishing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Améliorations IA : Ameublement Ultra-Réaliste | Pedra"
          : newLang === "es"
          ? "Mejoras de IA: Amueblado Super Realista | Pedra"
          : newLang === "pt"
          ? "Melhorias de IA: Mobiliário Super Realista | Pedra"
          : newLang === "de"
          ? "KI-Verbesserungen: Super-Realistische Einrichtung | Pedra"
          : "AI Improvements: Super-Realistic Furnishing | Pedra";
      } else if (
        [
          "/es/blog/rentabilidad-home-staging-virtual",
          "/fr/blog/rentabilite-home-staging-virtuel",
          "/pt/blog/roi-home-staging-virtual",
          "/de/blog/roi-virtuelles-home-staging",
          "/blog/roi-virtual-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "ROI du Home Staging Virtuel | Données et Analyses"
          : newLang === "es"
          ? "ROI del Home Staging Virtual | Datos y Análisis"
          : newLang === "pt"
          ? "ROI do Home Staging Virtual | Dados e Análise"
          : newLang === "de"
          ? "ROI von virtuellem Home Staging | Daten & Analyse"
          : "ROI of Virtual Home Staging | Data & Analysis";
      } else if (
        [
          "/es/render-desde-plano",
          "/fr/render-depuis-plan",
          "/pt/render-desde-planta",
          "/de/rendering-vom-grundriss",
          "/render-from-floor-plan",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Rendu depuis plan | Générer des visuels en 1 clic | Pedra"
          : newLang === "es"
          ? "Render desde plano | Genera visuales en 1 clic | Pedra"
          : newLang === "pt"
          ? "Render da planta | Gere visuais em 1 clique | Pedra"
          : newLang === "de"
          ? "Rendering vom Grundriss | Visuals mit 1 Klick | Pedra"
          : "Render from Floor Plan | Generate Visuals in 1 Click | Pedra";
      } else if (
        [
          "/help/how-to-renovate-houses-virtually",
          "/es/help/como-renovar-casas-virtualmente",
          "/fr/help/comment-renover-des-maisons-en-virtuel",
          "/pt/ajuda/como-renovar-casas-virtualmente",
          "/de/hilfe/wie-man-hauser-virtuell-renoviert",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rénovation Virtuelle | Tutoriel Pas à Pas | Pedra"
          : newLang === "es"
          ? "Guía Renovación Virtual | Tutorial Paso a Paso | Pedra"
          : newLang === "pt"
          ? "Guia Renovação Virtual | Tutorial Passo a Passo | Pedra"
          : newLang === "de"
          ? "Virtuelle Renovierung | Schritt-für-Schritt Anleitung"
          : "Virtual Renovation Guide | Step by Step Tutorial | Pedra";
      } else if (
        [
          "/es/releases/espacios-de-trabajo",
          "/fr/releases/espaces-de-travail",
          "/pt/lancamentos/espacos-trabalho",
          "/de/releases/arbeitsplatze",
          "/releases/workspaces",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Nouveaux espaces de travail | Pedra"
          : location.pathname.includes("/pt/") || newLang === "pt"
          ? "Novos espaços de trabalho | Pedra"
          : newLang === "es"
          ? "Nuevos espacios de trabajo | Pedra"
          : newLang === "de"
          ? "Neue Arbeitsbereiche | Pedra"
          : "New workspaces | Pedra";
      } else if (
        [
          "/es/api-documentation",
          "/fr/api-documentation",
          "/api-documentation",
          "/pt/api-documentation",
          "/de/api-documentation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "API de Home Staging Virtuel | Documentation Pedra"
          : newLang === "es"
          ? "API de Home Staging Virtual | Documentación Pedra"
          : newLang === "pt"
          ? "API de Home Staging Virtual | Documentação Pedra"
          : newLang === "de"
          ? "Virtuelle Home Staging API | Pedra Dokumentation"
          : "Virtual Home Staging API | Pedra Documentation";
      } else if (
        [
          "/fr/help/comment-creer-rendu-chambre",
          "/help/how-to-create-bedroom-render",
          "/es/help/como-crear-render-dormitorio",
          "/pt/ajuda/como-criar-render-quarto",
          "/de/hilfe/wie-man-schlafzimmer-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rendu de Chambre | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía Render de Dormitorio | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia Render de Quarto | Tutorial Passo a Passo"
          : newLang === "de"
          ? "Schlafzimmer-Rendering Guide | Schritt-für-Schritt"
          : "Bedroom Rendering Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/fr/releases/ameliorations-pedra-piscinas-et-preservation",
          "/es/releases/mejoras-pedra-piscinas-y-preservacion",
          "/pt/lancamentos/melhorias-pedra-piscinas-e-preservacao",
          "/de/releases/stein-verbesserungen-pools-und-erhaltung",
          "/releases/pedra-improvements-pools-and-preservation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Améliorations Pedra: Piscines et Préservation"
          : newLang === "es"
          ? "Mejoras Pedra: Piscinas y Preservación"
          : newLang === "pt"
          ? "Melhorias Pedra: Piscinas e Preservação"
          : newLang === "de"
          ? "Pedra Verbesserungen: Pools und Erhaltung"
          : "Pedra Improvements: Pools and Preservation";
      } else if (
        [
          "/fr/versions/renovez-et-meublez-en-un-clic",
          "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
          "/pt/lancamentos/renovar-e-mobiliar-em-um-clique",
          "/releases/renovate-and-furnish-in-one-click",
          "/de/releases/renovieren-und-einrichten-mit-einem-klick",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Rénovation et Ameublement en Un Clic | Nouvelle Fonction Auto"
          : newLang === "es"
          ? "Renovación y Amueblado en Un Clic | Nueva Función Auto"
          : newLang === "pt"
          ? "Renovação e Mobília em Um Clique | Nova Função Auto"
          : newLang === "de"
          ? "Renovierung & Einrichtung per Klick | Neue Auto-Funktion"
          : "One-Click Renovation & Furnishing | New Auto Feature";
      } else if (
        [
          "/help/render-floor-plan-with-ai",
          "/fr/help/rendu-plan-etage-avec-ia",
          "/es/help/renderizar-plano-de-planta-con-ia",
          "/pt/ajuda/renderizar-planta-baixa-com-ia",
          "/de/hilfe/grundriss-mit-ki-rendern",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Rendu de Plan avec IA | Guide Pas à Pas"
          : newLang === "es"
          ? "Render de Planos con IA | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Render de Plantas com IA | Tutorial Passo a Passo"
          : newLang === "de"
          ? "KI-Grundriss-Rendering | Schritt-für-Schritt-Anleitung"
          : "AI Floor Plan Rendering | Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-rendu-terrasse",
          "/help/how-to-create-terrace-render",
          "/es/help/como-crear-render-terraza",
          "/pt/ajuda/como-criar-render-terraco",
          "/de/hilfe/wie-man-terrassen-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rendu de Terrasse | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía Render de Terraza | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia Render de Terraço | Tutorial Passo a Passo"
          : newLang === "de"
          ? "Terrassen-Rendering Guide | Schritt-für-Schritt"
          : "Terrace Rendering Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/fr/help/comment-creer-rendu-exterieur-maison",
          "/help/how-to-create-house-exterior-render",
          "/es/help/como-crear-render-exterior-casa",
          "/pt/ajuda/como-criar-render-externa-casa",
          "/de/hilfe/wie-man-haus-aussenansicht-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rendu Extérieur | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía Render Exterior | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia Render Externo | Tutorial Passo a Passo"
          : newLang === "de"
          ? "Außenansicht-Rendering Guide | Schritt-für-Schritt"
          : "House Exterior Rendering Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/fr/help/comment-creer-rendu-cuisine",
          "/help/how-to-create-kitchen-render",
          "/es/help/como-crear-render-cocina",
          "/pt/ajuda/como-criar-render-cozinha",
          "/de/hilfe/wie-man-kuchen-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rendu de Cuisine | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía Render de Cocina | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia Render de Cozinha | Tutorial Passo a Passo"
          : newLang === "de"
          ? "Küchen-Rendering Guide | Schritt-für-Schritt"
          : "Kitchen Rendering Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/es/blog/pricing-inmobiliario",
          "/fr/blog/pricing-immobilier",
          "/pt/blog/precificacao-imobiliaria",
          "/de/blog/immobilien-preisgestaltung",
          "/blog/real-estate-pricing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Pricing immobilier : Guide complet pour fixer les prix"
          : newLang === "es"
          ? "Pricing inmobiliario: Guía para fijar precios óptimos"
          : newLang === "pt"
          ? "Precificação imobiliária: Guia para definir preços"
          : newLang === "de"
          ? "Immobilien-Preisgestaltung: Leitfaden für faire Preise"
          : "Real Estate Pricing: Complete Guide to Setting Prices";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salon",
          "/help/how-to-create-living-room-render",
          "/es/help/como-crear-render-sala-estar",
          "/pt/ajuda/como-criar-render-sala",
          "/de/hilfe/wie-man-wohnzimmer-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rendu de Salon | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía Render de Sala | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia Render de Sala | Tutorial Passo a Passo"
          : newLang === "de"
          ? "Wohnzimmer-Rendering Guide | Schritt-für-Schritt"
          : "Living Room Rendering Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/es/help/como-crear-render-local-comercial",
          "/fr/help/comment-creer-rendu-local-commercial",
          "/pt/ajuda/como-criar-render-local-comercial",
          "/de/hilfe/wie-man-gewerberaum-rendering-erstellt",
          "/help/how-to-create-commercial-space-render",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide Rendu Local Commercial | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía Render Local Comercial | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia Render Comercial | Tutorial Passo a Passo"
          : newLang === "de"
          ? "Gewerberaum-Rendering Guide | Schritt-für-Schritt"
          : "Commercial Space Rendering Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/es/blog/mentor-inmobiliario",
          "/fr/blog/mentor-immobilier",
          "/blog/real-estate-mentor",
          "/pt/blog/mentor-imobiliario",
          "/de/blog/immobilien-mentor",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Mentor immobilier : Guide pour choisir votre expert"
          : newLang === "es"
          ? "Mentor inmobiliario: Guía para elegir tu experto"
          : newLang === "pt"
          ? "Mentor imobiliário: Guia para escolher seu expert"
          : newLang === "de"
          ? "Immobilien-Mentor: Leitfaden zur Mentorenwahl"
          : "Real Estate Mentor: Guide to Choosing Your Expert";
      } else if (
        [
          "/es/blog/como-personalizar-habitacion",
          "/fr/blog/comment-personnaliser-chambre",
          "/blog/how-to-personalize-room",
          "/pt/blog/como-personalizar-ambientes",
          "/de/blog/wie-man-raume-personalisiert",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment personnaliser votre chambre | Guide complet"
          : newLang === "es"
          ? "Cómo personalizar tu habitación | Guía completa"
          : newLang === "pt"
          ? "Como personalizar seu ambiente | Guia completo"
          : newLang === "de"
          ? "Wie Sie Ihren Raum personalisieren | Kompletter Guide"
          : "How to Personalize Your Room | Complete Guide";
      } else if (
        [
          "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
          "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
          "/free-tools/generate-real-estate-listing-description-free",
          "/pt/ferramentas-gratuitas/gerar-descricao-anuncio-gratis",
          "/de/kostenlose-tools/immobilienanzeige-beschreibung-kostenlos-erstellen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Générateur d'annonces immobilières gratuit | Créez en 1 clic"
          : newLang === "es"
          ? "Generador de anuncios inmobiliarios gratis | Crea en 1 clic"
          : newLang === "pt"
          ? "Gerador de anúncios imobiliários grátis | Crie em 1 clique"
          : newLang === "de"
          ? "Kostenloser Immobilienanzeigen-Generator | In 1 Klick"
          : "Free Real Estate Listing Generator | Create in 1 Click";
      } else if (
        [
          "/blog/sunken-living-rooms",
          "/es/blog/salas-hundidas",
          "/fr/blog/salons-en-contrebas",
          "/pt/blog/salas-rebaixadas",
          "/de/blog/versenktes-wohnzimmer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Salons en contrebas : Design moderne et confort élégant"
          : newLang === "es"
          ? "Salas hundidas: Diseño moderno y comodidad elegante"
          : newLang === "pt"
          ? "Salas rebaixadas: Design moderno e conforto elegante"
          : newLang === "de"
          ? "Versenktes Wohnzimmer: Modernes Design und Komfort"
          : "Sunken Living Rooms: Modern Design and Elegant Comfort";
      } else if (
        [
          "/blog/home-automation",
          "/es/blog/domotizar-casa",
          "/fr/blog/domotiser-maison",
          "/pt/blog/automacao-residencial",
          "/de/blog/hausautomatisierung",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Domotique : Guide complet pour automatiser votre maison"
          : newLang === "es"
          ? "Domótica: Guía completa para automatizar tu hogar"
          : newLang === "pt"
          ? "Automação Residencial: Guia completo para sua casa"
          : newLang === "de"
          ? "Hausautomatisierung: Kompletter Leitfaden für Ihr Haus"
          : "Home Automation: Complete Guide to Automate Your Home";
      } else if (
        [
          "/blog/property-that-wont-sell",
          "/es/blog/propiedad-no-se-vende",
          "/fr/blog/propriete-qui-ne-se-vend-pas",
          "/pt/blog/imovel-que-nao-vende",
          "/de/blog/immobilie-die-sich-nicht-verkauft",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Que faire quand une propriété ne se vend pas ? | Solutions"
          : newLang === "es"
          ? "¿Por qué no se vende mi propiedad? | Soluciones efectivas"
          : newLang === "pt"
          ? "Por que meu imóvel não vende? | Soluções que funcionam"
          : newLang === "de"
          ? "Warum verkauft sich meine Immobilie nicht? | Lösungen"
          : "Why isn't my property selling? | Solutions that work";
      } else if (
        [
          "/blog/flipping",
          "/es/blog/flipping",
          "/fr/blog/flipping",
          "/pt/blog/flipping",
          "/de/blog/flipping",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide complet du flipping immobilier : Réussir ses projets"
          : newLang === "es"
          ? "Flipping inmobiliario: Guía completa para tener éxito"
          : newLang === "pt"
          ? "Flipping imobiliário: Guia completo para ter sucesso"
          : newLang === "de"
          ? "Immobilien-Flipping: Kompletter Leitfaden zum Erfolg"
          : "Real Estate Flipping: Complete Guide to Success";
      } else if (
        [
          "/blog/short-sale",
          "/es/blog/short-sale",
          "/fr/blog/short-sale",
          "/pt/blog/venda-rapida",
          "/de/blog/schnellverkauf",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Vente à découvert : Guide complet du processus immobilier"
          : newLang === "es"
          ? "Short Sale: Guía completa del proceso inmobiliario"
          : newLang === "pt"
          ? "Venda Rápida: Guia completo do processo imobiliário"
          : newLang === "de"
          ? "Schnellverkauf: Kompletter Leitfaden Immobilienprozess"
          : "Short Sale: Complete Guide to the Real Estate Process";
      } else if (
        [
          "/blog/mistakes-when-selling-house",
          "/es/blog/errores-al-vender-casa",
          "/fr/blog/erreurs-vente-maison",
          "/pt/blog/erros-ao-vender-casa",
          "/de/blog/fehler-beim-hausverkauf",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Erreurs à éviter lors de la vente de votre maison"
          : newLang === "es"
          ? "Errores a evitar al vender tu casa"
          : newLang === "pt"
          ? "Erros a evitar ao vender sua casa"
          : newLang === "de"
          ? "Fehler beim Hausverkauf vermeiden"
          : "Common mistakes to avoid when selling your house";
      } else if (
        [
          "/es/blog/consejos-para-fotografia-inmobiliaria",
          "/fr/blog/conseils-pour-la-photographie-immobiliere",
          "/de/blog/tipps-fur-immobilienfotografie",
          "/pt/blog/dicas-de-fotografia-imobiliaria",
          "/blog/real-estate-photography-tips",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Réussir vos annonces immobilières : conseils essentiels pour la photographie immobilière"
          : location.pathname.includes("/es/")
          ? "Consejos esenciales de fotografía inmobiliaria para destacar anuncios de propiedades"
          : location.pathname.includes("/de/")
          ? "Erfolgreiche Immobilienanzeigen: Wesentliche Tipps zur Immobilienfotografie"
          : location.pathname.includes("/pt/")
          ? "Sucesso garantido: Dicas essenciais de fotografia imobiliária para anúncios impressionantes"
          : "Unlocking Success: Essential Real Estate Photography Tips for Stunning Property Listings";
      } else if (
        [
          "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
          "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
          "/pt/blog/home-staging-tradicional-vs-virtual",
          "/de/blog/traditionelles-vs-virtuelles-home-staging",
          "/blog/traditional-home-staging-vs-virtual-home-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Home Staging Virtuel vs Traditionnel : Comparaison Complète"
          : newLang === "es"
          ? "Home Staging Virtual vs Tradicional: Comparación Completa"
          : newLang === "pt"
          ? "Home Staging Virtual vs Tradicional: Comparação Completa"
          : newLang === "de"
          ? "Virtuelles vs Traditionelles Home Staging: Ein Vergleich"
          : "Virtual vs Traditional Home Staging: A Complete Comparison";
      } else if (
        [
          "/es/help/como-crear-efecto-cortinilla-home-staging-virtual",
          "/fr/help/comment-creer-effet-rideaux-home-staging-virtuel",
          "/pt/ajuda/como-criar-efeito-cortinas-home-staging-virtual",
          "/de/hilfe/wie-man-vorhang-effekt-home-staging-virtuell-erstellt",
          "/help/how-to-create-virtual-home-staging-curtain-effect",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Créer un effet avant/après avec un effet rideau pour le home staging virtuel"
          : newLang === "es"
          ? "Cómo crear efectos antes/después con cortinilla en home staging virtual"
          : newLang === "pt"
          ? "Como criar efeitos antes/depois com efeito cortina em home staging virtual"
          : newLang === "de"
          ? "Vorher/Nachher-Effekte mit einem Vorhangeffekt im virtuellen Home Staging erstellen"
          : "How to Create Before/After Effects with a Curtain Effect in Virtual Home Staging";
      } else if (
        [
          "/es/releases/planos-y-espacios-comerciales",
          "/fr/releases/plans-et-espaces-commerciaux",
          "/releases/floorplans-and-commercial-spaces",
          "/pt/lancamentos/plantas-baixas-e-espacos-comerciais",
          "/de/releases/grundrisse-und-gewerberaume",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Nouveauté : Rendus 3D de plans et espaces commerciaux"
          : newLang === "es"
          ? "Novedad: Renders de planos y espacios comerciales"
          : newLang === "pt"
          ? "Novidade: Renders de Plantas Baixas e Espaços Comerciais"
          : newLang === "de"
          ? "Neu: Grundriss-Renderings und Gewerberäume"
          : "New: Floor plan renders and commercial spaces";
      } else if (
        [
          "/fr/help/comment-vider-pieces-virtuellement",
          "/help/how-to-empty-rooms-virtually",
          "/es/help/como-vaciar-habitacion-virtualmente",
          "/pt/ajuda/como-esvaziar-comodos-virtualmente",
          "/de/hilfe/wie-man-raume-virtuell-leert",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment vider virtuellement des pièces | Guide étape par étape"
          : newLang === "es"
          ? "Cómo vaciar habitaciones virtualmente | Tutorial paso a paso"
          : newLang === "pt"
          ? "Como esvaziar cômodos virtualmente | Guia passo a passo"
          : newLang === "de"
          ? "Räume virtuell leeren | Schritt-für-Schritt-Anleitung"
          : "How to empty rooms virtually | Step-by-step guide";
      } else if (
        [
          "/es/help/como-crear-render-local-comercial",
          "/help/how-to-create-commercial-space-render",
          "/fr/help/comment-creer-rendu-local-commercial",
          "/pt/ajuda/como-criar-render-espaco-comercial",
          "/de/hilfe/wie-man-gewerberaum-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Créez des rendus professionnels pour votre espace commercial. Guide pas à pas!"
          : newLang === "es"
          ? "Aprende a crear renders profesionales de locales comerciales paso a paso aquí."
          : newLang === "pt"
          ? "Aprenda a criar renders profissionais de espaços comerciais passo a passo aqui."
          : newLang === "de"
          ? "Lernen Sie Schritt für Schritt professionelle Gewerberaum-Renderings zu erstellen. Hier starten!"
          : "Learn to create professional commercial space renders step by step. Start here!";
      } else if (
        [
          "/fr/home-staging-local",
          "/es/home-staging-local",
          "/home-staging-commercial",
          "/pt/home-staging-local",
          "/de/home-staging-gewerbe",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging para Locales Comerciales | Servicios Profesionales de Decoración"
            : newLang === "fr"
            ? "Home Staging pour Locaux Commerciaux | Services Professionnels de Décoration"
            : newLang === "pt"
            ? "Home Staging para Imóveis Comerciais | Serviços Profissionais de Decoração"
            : newLang === "de"
            ? "Home Staging für Gewerbeimmobilien | Professionelle Einrichtungsservices"
            : "Commercial Property Home Staging | Professional Staging Services";
      } else if (
        [
          "/blog/buildable-land",
          "/es/blog/terrenos-urbanizables",
          "/fr/blog/terrains-constructibles",
          "/pt/blog/terreno-construivel",
          "/de/blog/bauland",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Terrains constructibles: Types et préparation pour la vente"
          : newLang === "es"
          ? "Terrenos urbanizables: Tipos y preparación para la venta"
          : newLang === "pt"
          ? "Terrenos construtíveis: Tipos e preparação para venda"
          : newLang === "de"
          ? "Bauland: Arten und Vorbereitung für den Verkauf"
          : "Buildable Land: Types and How to Prepare for Sale";
      } else if (
        [
          "/es/render-local",
          "/fr/rendu-local",
          "/render-commercial",
          "/pt/renderizacao-comercial",
          "/de/gewerbe-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Local Comercial | Crear renders de locales automáticamente"
            : newLang === "fr"
            ? "Rendu de Local Commercial | Créer des rendus de locaux automatiquement"
            : newLang === "pt"
            ? "Render de Espaço Comercial | Criar renders de locais automaticamente"
            : newLang === "de"
            ? "Gewerberaum-Rendering | Automatisch Gewerberaum-Renderings erstellen"
            : "Commercial Space Render | Create commercial space renders automatically";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salle-de-bain",
          "/help/how-to-create-bathroom-render",
          "/es/help/como-crear-render-bano",
          "/pt/ajuda/como-criar-render-banho",
          "/de/hilfe/wie-man-badezimmer-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu de salle de bain : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render de baño: Guía paso a paso"
          : newLang === "pt"
          ? "Como criar um render de banheiro: Guia passo a passo"
          : newLang === "de"
          ? "Wie man ein Badezimmer-Rendering erstellt: Eine Schritt-für-Schritt-Anleitung"
          : "How to Create a Bathroom Render: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-rendu-cuisine",
          "/help/how-to-create-kitchen-render",
          "/es/help/como-crear-render-cocina",
          "/pt/ajuda/como-criar-render-cozinha",
          "/de/hilfe/wie-man-kuchen-rendering-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu de cuisine : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render de cocina: Guía paso a paso"
          : newLang === "pt"
          ? "Como criar um render de cozinha: Guia passo a passo"
          : newLang === "de"
          ? "Wie man ein Küchen-Rendering erstellt: Eine Schritt-für-Schritt-Anleitung"
          : "How to Create a Kitchen Render: A Step-by-Step Guide";
      } else if (
        [
          "/fr/rendu-maison",
          "/es/render-casa",
          "/render-house",
          "/pt/renderizar-casa",
          "/de/haus-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Casa | Crear renders de casa automáticamente"
            : newLang === "fr"
            ? "Rendu de Maison | Créer des rendus de maison automatiquement"
            : newLang === "pt"
            ? "Render de Casa | Criar renders de casa automaticamente"
            : newLang === "de"
            ? "Haus-Rendering | Automatisch Haus-Renderings erstellen"
            : "House Render | Create house renders automatically";
      } else if (
        [
          "/fr/rendu-terrasse",
          "/es/render-terraza",
          "/render-terrace",
          "/pt/renderizar-terraco",
          "/de/terrassen-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Terraza | Crear renders de terraza automáticamente"
            : newLang === "fr"
            ? "Rendu de Terrasse | Créer des rendus de terrasse automatiquement"
            : newLang === "pt"
            ? "Render de Terraço | Criar renders de terraço automaticamente"
            : newLang === "de"
            ? "Terrassen-Rendering | Automatisch Terrassen-Renderings erstellen"
            : "Terrace Render | Create terrace renders automatically";
      } else if (
        [
          "/blog/real-estate-advertising",
          "/es/blog/publicidad-inmobiliaria",
          "/fr/blog/publicite-immobiliere",
          "/pt/blog/publicidade-imobiliaria",
          "/de/blog/immobilien-werbung",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Publicidad Inmobiliaria | Cómo hacer publicidad inmobiliaria"
            : newLang === "fr"
            ? "Publicité Immobilière | Comment faire de la publicité immobilière"
            : newLang === "pt"
            ? "Publicidade Imobiliária | Como fazer publicidade imobiliária"
            : newLang === "de"
            ? "Immobilienwerbung | Wie man Immobilienwerbung macht"
            : "Real Estate Advertising | How to do real estate advertising";
      } else if (
        [
          "/fr/rendu-salon",
          "/es/render-salon",
          "/render-living-room",
          "/pt/renderizar-sala",
          "/de/wohnzimmer-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Salón | Crear renders de salón automáticamente"
            : newLang === "fr"
            ? "Rendu de Salon | Créer des rendus de salon automatiquement"
            : newLang === "pt"
            ? "Render de Sala | Criar renders de sala automaticamente"
            : newLang === "de"
            ? "Wohnzimmer-Rendering | Automatisch Wohnzimmer-Renderings erstellen"
            : "Living Room Render | Create living room renders automatically";
      } else if (
        [
          "/fr/rendu-salle-de-bain",
          "/es/render-bano",
          "/render-bathroom",
          "/pt/renderizar-banheiro",
          "/de/badezimmer-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Baño | Crear renders de baño automáticamente"
            : newLang === "fr"
            ? "Rendu de Salle de Bain | Créer des rendus de salle de bain automatiquement"
            : newLang === "pt"
            ? "Render de Banheiro | Criar renders de banheiro automaticamente"
            : newLang === "de"
            ? "Badezimmer-Rendering | Automatisch Badezimmer-Renderings erstellen"
            : "Bathroom Render | Create bathroom renders automatically";
      } else if (
        [
          "/fr/rendu-cuisine",
          "/es/render-cocina",
          "/render-kitchen",
          "/pt/renderizar-cozinha",
          "/de/kuchen-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Cocina | Crear renders de cocina automáticamente"
            : newLang === "fr"
            ? "Rendu de Cuisine | Créer des rendus de cuisine automatiquement"
            : newLang === "pt"
            ? "Render de Cozinha | Criar renders de cozinha automaticamente"
            : newLang === "de"
            ? "Küchen-Rendering | Automatisch Küchen-Renderings erstellen"
            : "Kitchen Render | Create kitchen renders automatically";
      } else if (
        [
          "/fr/rendu-chambre",
          "/es/render-dormitorio",
          "/render-bedroom",
          "/pt/renderizar-quarto",
          "/de/schlafzimmer-rendering",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Dormitorio | Crear renders de dormitorio automáticamente"
            : newLang === "fr"
            ? "Rendu de Chambre | Créer des rendus de chambre automatiquement"
            : newLang === "pt"
            ? "Render de Quarto | Criar renders de quarto automaticamente"
            : newLang === "de"
            ? "Schlafzimmer-Rendering | Automatisch Schlafzimmer-Renderings erstellen"
            : "Bedroom Render | Create bedroom renders automatically";
      } else if (
        [
          "/es/blog/como-vender-casa-a-reformar",
          "/fr/blog/comment-vendre-une-maison-a-renover",
          "/blog/how-to-sell-a-house-to-renovate",
          "/pt/blog/como-vender-casa-para-reformar",
          "/de/blog/wie-man-ein-renovierungsbedurftiges-haus-verkauft",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment Vendre une Maison à Rénover : Guide Pratique"
          : newLang === "es"
          ? "Cómo Vender una Casa a Reformar: Guía Completa"
          : newLang === "pt"
          ? "Como Vender uma Casa para Reformar: Guia Completo"
          : newLang === "de"
          ? "Wie man ein renovierungsbedürftiges Haus verkauft: Kompletter Leitfaden"
          : "How to Sell a House to Renovate: Complete Guide";
      } else if (
        [
          "/es/renders-para-reformas",
          "/renders-for-renovations",
          "/fr/rendus-de-renovation",
          "/pt/renderizacoes-para-reformas",
          "/de/renderings-fur-renovierungen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Rendus pour rénovations - Service rapide et automatique"
          : newLang === "es"
          ? "Renders para reformas - Servicio rápido y automático"
          : newLang === "pt"
          ? "Renders para Reformas - Serviço rápido e automático"
          : newLang === "de"
          ? "Renderings für Renovierungen - Schneller und automatischer Service"
          : "Renders for renovations - Fast and automatic service";
      } else if (
        [
          "/es/releases/mejoras-pedra-piscinas-y-preservacion",
          "/fr/releases/ameliorations-pedra-piscines-et-preservation",
          "/releases/pedra-improvements-pools-and-preservation",
          "/pt/releases/melhorias-pedra-piscinas-e-preservacao",
          "/de/releases/stein-verbesserungen-pools-und-erhaltung",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Améliorations Pedra : Piscines et préservation"
          : newLang === "es"
          ? "Mejoras Pedra: Piscinas y preservación de partes al renovar"
          : newLang === "pt"
          ? "Melhorias Pedra: Piscinas e preservação ao renovar"
          : newLang === "de"
          ? "Pedra Verbesserungen: Pools und Erhaltung bei Renovierungen"
          : "Améliorations de Pedra : Piscines et conservation des éléments dans les rénovations";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
          "/help/how-to-create-bathroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-bano",
          "/pt/ajuda/como-criar-home-staging-virtual-banheiro",
          "/de/hilfe/wie-man-virtuelles-badezimmer-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de salle de bain : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de baño: Guía paso a paso"
          : newLang === "pt"
          ? "Como criar um home staging virtual de banheiro: Guia passo a passo"
          : newLang === "de"
          ? "Wie man virtuelles Badezimmer-Home-Staging erstellt: Eine Schritt-für-Schritt-Anleitung"
          : "How to Create a Bathroom Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-cuisine",
          "/help/how-to-create-kitchen-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-cocina",
          "/pt/ajuda/como-criar-home-staging-virtual-cozinha",
          "/de/hilfe/wie-man-virtuelles-kuchen-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de cuisine : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de cocina: Guía paso a paso"
          : newLang === "pt"
          ? "Como criar um home staging virtual de cozinha: Guia passo a passo"
          : newLang === "de"
          ? "Wie man virtuelles Küchen-Home-Staging erstellt: Eine Schritt-für-Schritt-Anleitung"
          : "How to Create a Kitchen Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/es/blog/terrenos-urbanizables",
          "/fr/blog/terrains-constructibles",
          "/blog/buildable-land",
          "/pt/blog/terrenos-urbanizaveis",
          "/de/blog/bauland",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Terrains constructibles : Définition et types de terrains"
          : newLang === "es"
          ? "Terrenos urbanizables: Qué son y tipos de terrenos"
          : newLang === "pt"
          ? "Terrenos Urbanizáveis: O que são e tipos de terrenos"
          : newLang === "de"
          ? "Bauland: Definition und Arten von Grundstücken"
          : "Buildable Land: What It Is and Types of Land";
      } else if (
        [
          "/fr/outils-gratuits/convertisseur-format-image",
          "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
          "/free-tools/free-image-format-converter",
          "/pt/ferramentas-gratuitas/conversor-formato-imagem-gratis",
          "/de/kostenlose-tools/bildformat-konverter-kostenlos",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Convertisseur de Format d'Image Gratuit : Convertissez Entre Tous les Formats"
          : newLang === "es"
          ? "Convertidor Gratuito de Formatos de Imagen: Convierte Entre Cualquier Formato"
          : newLang === "pt"
          ? "Conversor Gratuito de Formato de Imagem: Converta Entre Qualquer Formato"
          : newLang === "de"
          ? "Kostenloser Bildformat-Konverter: Konvertieren Sie zwischen allen Bildformaten"
          : "Free Image Format Converter: Convert Between Any Image Format";
      } else if (
        [
          "/es/blog/fideicomiso-inmobiliario",
          "/fr/blog/fiducie-immobiliere",
          "/blog/real-estate-trust",
          "/pt/blog/confianca-imobiliaria",
          "/de/blog/immobilien-vertrauen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Qu'est-ce qu'une fiducie immobilière et quels sont les différents types?"
          : newLang === "es"
          ? "¿Qué es un fideicomiso inmobiliario y qué tipos de hay?"
          : newLang === "pt"
          ? "O que é um fideicomisso imobiliário e quais são os tipos?"
          : newLang === "de"
          ? "Was ist ein Immobilien-Trust und welche Arten gibt es?"
          : "What is a real estate trust and what types are there?";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salon",
          "/help/how-to-create-living-room-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-salon",
          "/pt/ajuda/como-criar-home-staging-virtual-sala",
          "/de/hilfe/wie-man-virtuelles-wohnzimmer-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de salon : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de salón: Guía paso a paso"
          : newLang === "pt"
          ? "Como criar um home staging virtual de sala: Guia passo a passo"
          : newLang === "de"
          ? "Wie man virtuelles Wohnzimmer-Home-Staging erstellt: Eine Schritt-für-Schritt-Anleitung"
          : "How to Create a Living Room Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-chambre",
          "/help/how-to-create-bedroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-dormitorio",
          "/pt/ajuda/como-criar-home-staging-virtual-quarto",
          "/de/hilfe/wie-man-virtuelles-schlafzimmer-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de chambre : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de dormitorio: Guía paso a paso"
          : newLang === "pt"
          ? "Como Criar um Home Staging Virtual de Quarto: Guia Passo a Passo"
          : newLang === "de"
          ? "Wie man virtuelles Schlafzimmer-Home-Staging erstellt: Eine Schritt-für-Schritt-Anleitung"
          : "How to Create a Bedroom Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/es/blog/frases-inmobiliarias",
          "/fr/blog/phrases-immobilieres",
          "/blog/real-estate-phrases",
          "/pt/blog/frases-imobiliarias",
          "/de/blog/immobilien-phrasen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Phrases Immobilières, Exemples et Conseils pour Attirer les Acheteurs"
          : newLang === "es"
          ? "Frases Inmobiliarias, ejemplos y consejos para captar compradores"
          : location.pathname.includes("/pt/")
          ? "Frases Imobiliárias, Exemplos e Dicas para Atrair Compradores"
          : newLang === "de"
          ? "Immobilien-Phrasen, Beispiele und Tipps zum Anlocken von Käufern"
          : "Real Estate Phrases, Examples and Tips to Attract Buyers";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-terrasse",
          "/help/how-to-create-terrace-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-terraza",
          "/pt/ajuda/como-criar-home-staging-virtual-terraco",
          "/de/hilfe/wie-man-virtuelles-terrassen-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de terrasse : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de terraza: Guía paso a paso"
          : newLang === "pt"
          ? "Como Criar um Home Staging Virtual de Terraço: Guia Passo a Passo"
          : newLang === "de"
          ? "Wie man virtuelles Terrassen-Home-Staging erstellt: Eine Schritt-für-Schritt-Anleitung"
          : "How to Create a Terrace Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/blog/bienes-raices",
          "/fr/blog/immobilier",
          "/blog/real-estate",
          "/pt/blog/imobiliario",
          "/de/blog/immobilien",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Immobilier : Conseils pour Acheter, Vendre et Investir"
          : newLang === "es"
          ? "Bienes Raíces: Consejos para Comprar, Vender e Invertir"
          : newLang === "pt"
          ? "Imobiliário: Dicas para Comprar, Vender e Investir"
          : newLang === "de"
          ? "Immobilien: Tipps zum Kaufen, Verkaufen und Investieren"
          : "Real Estate: Tips for Buying, Selling and Investing";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel",
          "/help/how-to-create-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual",
          "/pt/ajuda/como-criar-home-staging-virtual",
          "/de/hilfe/wie-man-virtuelles-home-staging-erstellt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide de Home Staging Virtuel | Tutoriel Pas à Pas"
          : newLang === "es"
          ? "Guía de Home Staging Virtual | Tutorial Paso a Paso"
          : newLang === "pt"
          ? "Guia de Home Staging Virtual | Tutorial Detalhado"
          : newLang === "de"
          ? "Virtuelles Home Staging Guide | Schritt-für-Schritt"
          : "Virtual Home Staging Guide | Step-by-Step Tutorial";
      } else if (
        [
          "/fr/home-staging-salle-de-bain",
          "/es/home-staging-bano",
          "/home-staging-bathroom",
          "/pt/home-staging-banheiro",
          "/de/home-staging-badezimmer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Baño | Transformar tu baño con home staging"
            : newLang === "fr"
            ? "Home Staging Salle de Bain | Transformer votre salle de bain avec le home staging"
            : newLang === "pt"
            ? "Home Staging de Banheiro | Transforme seu banheiro com home staging"
            : newLang === "de"
            ? "Badezimmer Home Staging | Transformieren Sie Ihr Bad mit Home Staging"
            : "Bathroom Home Staging | Transform your bathroom with home staging";
      } else if (
        [
          "/fr/home-staging-chambre",
          "/es/home-staging-dormitorio",
          "/home-staging-bedroom",
          "/pt/home-staging-quarto",
          "/de/home-staging-schlafzimmer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Dormitorio | Transformar tu dormitorio con home staging"
            : newLang === "fr"
            ? "Home Staging Chambre | Transformer votre chambre avec le home staging"
            : newLang === "pt"
            ? "Home Staging de Quarto | Transforme seu quarto com home staging"
            : newLang === "de"
            ? "Schlafzimmer Home Staging | Transformieren Sie Ihr Schlafzimmer mit Home Staging"
            : "Bedroom Home Staging | Transform your bedroom with home staging";
      } else if (
        [
          "/fr/home-staging-terrasse",
          "/es/home-staging-terraza",
          "/home-staging-terrace",
          "/pt/home-staging-terraco",
          "/de/home-staging-terrasse",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Terraza | Transformar tu terraza con home staging"
            : newLang === "fr"
            ? "Home Staging Terrasse | Transformer votre terrasse avec le home staging"
            : newLang === "pt"
            ? "Home Staging de Terraço | Transforme seu terraço com home staging"
            : newLang === "de"
            ? "Terrassen Home Staging | Transformieren Sie Ihre Terrasse mit Home Staging"
            : "Terrace Home Staging | Transform your terrace with home staging";
      } else if (
        [
          "/es/blog/errores-al-vender-casa",
          "/fr/blog/erreurs-vente-maison",
          "/blog/mistakes-when-selling-house",
          "/pt/blog/erros-venda-casa",
          "/de/blog/fehler-beim-hausverkauf",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Erreurs Courantes lors de la Vente d'une Maison et Comment les Éviter"
          : newLang === "es"
          ? "Errores Comunes al Vender una Casa y Cómo Evitarlos"
          : newLang === "pt"
          ? "Erros Comuns ao Vender uma Casa e Como Evitá-los"
          : newLang === "de"
          ? "Häufige Fehler beim Hausverkauf und wie man sie vermeidet"
          : "Common Mistakes When Selling a House and How to Avoid Them";
      } else if (
        [
          "/fr/home-staging-salon",
          "/es/home-staging-salon",
          "/home-staging-living-room",
          "/pt/home-staging-sala",
          "/de/home-staging-wohnzimmer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Salón | Transformar tu salón con home staging"
            : newLang === "fr"
            ? "Home Staging Salon | Transformer votre salon avec le home staging"
            : newLang === "pt"
            ? "Home Staging de Sala | Transforme sua sala com home staging"
            : newLang === "de"
            ? "Wohnzimmer Home Staging | Transformieren Sie Ihr Wohnzimmer mit Home Staging"
            : "Living Room Home Staging | Transform your living room with home staging";
      } else if (
        [
          "/es/blog/short-sale",
          "/fr/blog/short-sale",
          "/blog/short-sale",
          "/pt/blog/short-sale",
          "/de/blog/schnellverkauf",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide sur la vente à découvert : Définition et objectifs"
          : newLang === "es"
          ? "Guía sobre sobre Short Sale: Qué es y objetivos"
          : newLang === "pt"
          ? "Guia de Short Sale: O que é e seus objetivos"
          : newLang === "de"
          ? "Leitfaden zum Schnellverkauf: Was es ist und seine Ziele"
          : "Guide to Short Sale: What It Is and Its Objectives";
      } else if (
        [
          "/fr/home-staging-cuisine",
          "/es/home-staging-cocina",
          "/home-staging-kitchen",
          "/pt/home-staging-cozinha",
          "/de/home-staging-kuche",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Cocina | Transformar su cocina con home staging"
            : newLang === "fr"
            ? "Home Staging Cuisine | Transformer votre cuisine avec le home staging"
            : newLang === "pt"
            ? "Home Staging de Cozinha | Transforme sua cozinha com home staging"
            : newLang === "de"
            ? "Küchen Home Staging | Transformieren Sie Ihre Küche mit Home Staging"
            : "Kitchen Home Staging | Transform your kitchen with home staging";
      } else if (
        [
          "/es/blog/diseñador-ambientes",
          "/fr/blog/designer-environnement",
          "/blog/environmental-designer",
          "/pt/blog/designer-ambiental",
          "/de/blog/umweltdesigner",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Designer d'environnement : Guide sur le design d'environnement"
          : newLang === "es"
          ? "Diseñador de ambientes: Guía sobre el diseño de ambientes"
          : newLang === "pt"
          ? "Designer Ambiental: Guia de Design Ambiental"
          : newLang === "de"
          ? "Umweltdesigner: Leitfaden zum Umweltdesign"
          : "Environmental Designer: Guide to Environmental Design";
      } else if (
        [
          "/fr/blog/augmentez-valeur-maison",
          "/blog/increase-home-value",
          "/es/blog/aumenta-valor-vivienda",
          "/pt/blog/aumentar-valor-imovel",
          "/de/blog/hauswert-steigern",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Augmentez la valeur de la maison rapidement et facilement"
          : newLang === "es"
          ? "Aumenta el valor de la vivienda rápido y fácil"
          : newLang === "pt"
          ? "Aumente o valor da casa rápida e facilmente"
          : newLang === "de"
          ? "Steigern Sie den Hauswert schnell und einfach"
          : "Increase Home Value Quickly and Easily";
      } else if (
        [
          "/fr/blog/espaces-multifonctionnels",
          "/blog/multifunctional-spaces",
          "/es/blog/espacios-multifuncionales",
          "/pt/blog/espacos-multifuncionais",
          "/de/blog/multifunktionale-raume",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Espaces multifonctionnels: Qu'est-ce que c'est et comment optimiser l'espace?"
          : location.pathname.includes("/pt/") || newLang === "pt"
          ? "Espaços Multifuncionais: O que são e como otimizar o espaço?"
          : newLang === "es"
          ? "Espacios Multifuncionales ¿Qué es y cómo optimizar espacio?"
          : newLang === "de"
          ? "Multifunktionale Räume: Was sind sie und wie optimiert man den Raum?"
          : "Multifunctional Spaces: What Are They and How to Optimize Space?";
      } else if (
        [
          "/fr/help/comment-ajouter-des-objets-3d",
          "/help/how-to-add-3d-objects",
          "/es/help/como-anadir-objetos-3d",
          "/pt/ajuda/como-adicionar-objetos-3d",
          "/de/hilfe/wie-man-3d-objekte-hinzufugt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment Utiliser l'Outil 'Add Object' pour une Mise en Scène Virtuelle"
          : newLang === "es"
          ? "Añadir Objeto para Crear un Home Staging Virtual"
          : newLang === "pt"
          ? "Adicionar Objeto para Criar um Home Staging Virtual"
          : newLang === "de"
          ? "3D-Objekte hinzufügen für virtuelles Home Staging"
          : "Add Object to Create a Virtual Home Staging";
      } else if (
        currentUrl.includes("/fr/help/comment-renover-des-maisons-en-virtuel")
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer une rénovation virtuelle d'une maison"
          : newLang === "es"
          ? "Cómo renovar virtualmente una casa"
          : newLang === "pt"
          ? "Como renovar virtualmente uma casa"
          : newLang === "de"
          ? "Wie man ein Haus virtuell renoviert"
          : "How to virtually renovate a house";
      } else if (currentUrl.includes("/fr/help/comment-meubler-en-virtuel")) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide étape par étape pour meubler virtuellement des pièces avec l'outil de mobilier virtuel"
          : newLang === "es"
          ? "Guía paso a paso para amueblar virtualmente habitaciones con la herramienta de mobiliario virtual"
          : newLang === "pt"
          ? "Guia passo a passo para mobiliar virtualmente cômodos com a ferramenta de mobiliário virtual"
          : newLang === "de"
          ? "Schritt-für-Schritt-Anleitung zum virtuellen Einrichten von Räumen mit dem virtuellen Möbel-Tool"
          : "Step by step guide to virtually furnish rooms with the virtual furniture tool";
      } else if (
        [
          "/fr/help/comment-supprimer-un-objet",
          "/help/how-to-remove-object",
          "/es/help/como-borrar-objeto",
          "/pt/ajuda/como-remover-objeto",
          "/de/hilfe/wie-man-objekte-entfernt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment supprimer des objets des photos de propriété"
          : newLang === "es"
          ? "Cómo borrar objetos de fotos de casas y propiedades"
          : newLang === "pt"
          ? "Como remover objetos de fotos de casas e propriedades"
          : newLang === "de"
          ? "Wie man Objekte aus Immobilienfotos entfernt"
          : "How to remove objects from property photos";
      } else if (
        [
          "/fr/interiordesign",
          "/es/interiordesign",
          "/interiordesign",
          "/pt/interiordesign",
          "/de/innenarchitektur",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Diseño de interiores con IA"
            : newLang === "fr"
            ? "Design d'intérieur avec IA"
            : newLang === "pt"
            ? "Design de Interiores com IA"
            : newLang === "de"
            ? "KI-gestützte Innenarchitektur"
            : "AI Interior Design";
      } else if (
        currentUrl.includes("real-estate-photography") ||
        currentUrl.includes("fotografia-imobiliaria") ||
        currentUrl.includes("immobilienfotografie")
      ) {
        newTitle =
          newLang === "es"
            ? "Pedra | Mejora tus fotografías inmobiliarias con IA"
            : newLang === "fr"
            ? "Méliorez vos photos immobilières en un clic"
            : newLang === "pt"
            ? "Pedra | Melhore suas fotos imobiliárias com IA"
            : newLang === "de"
            ? "Pedra | Verbessern Sie Ihre Immobilienfotos mit KI"
            : "Pedra | Improve your real estate photos with AI";
      } else if (
        currentUrl.includes("realestate") ||
        currentUrl.includes("de/realestate")
      ) {
        newTitle =
          newLang === "es"
            ? "Pedra | Herramienta Renders Para Inmobiliarias"
            : newLang === "fr"
            ? "Optimisez vos Annonces Immobilières avec Pedra"
            : newLang === "pt"
            ? "Pedra | Ferramenta de Render Para Imobiliárias"
            : newLang === "de"
            ? "Pedra | Einfaches Render-Tool für Immobilien"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("como-vender-casa")) {
        newTitle =
          newLang === "es"
            ? "¿Cómo vender mi casa? | Consejos para vender rápido tu casa"
            : newLang === "pt"
            ? "Como vender minha casa? | Dicas para vender rápido"
            : newLang === "de"
            ? "Wie verkaufe ich mein Haus? | Tipps für einen schnellen Verkauf"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("renovar-casa")) {
        newTitle =
          newLang === "es"
            ? "¿Cómo renovar tu casa? | Pasos e ideas para renovar tu hogar"
            : newLang === "pt"
            ? "Como renovar minha casa? | Passos e ideias para renovar"
            : newLang === "de"
            ? "Wie renoviere ich mein Haus? | Schritte und Ideen zur Renovierung"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (
        currentUrl.includes("pricing") ||
        currentUrl.includes("preise") ||
        currentUrl.includes("precos")
      ) {
        newTitle =
          newLang === "es"
            ? "Pedra | Home Staging Virtual Precios"
            : newLang === "fr"
            ? "Pedra | Home Staging Virtual Tarifs: Forfait Pro à 29€/mois"
            : newLang === "pt"
            ? "Pedra | Home Staging Virtual Preços"
            : newLang === "de"
            ? "Pedra | Virtuelles Home Staging Preise"
            : "Pedra | Virtual Home Staging Pricing";
      } else if (currentUrl.includes("render")) {
        newTitle =
          newLang === "es"
            ? "Renders IA | Crea renders con IA "
            : newLang === "fr"
            ? "Rendus Immobiliers IA – Transformez Vos Images en Rendus Professionnels avec IA"
            : newLang === "pt"
            ? "Renders IA | Crie renders com IA"
            : newLang === "de"
            ? "KI-Renderings | Erstellen Sie Renderings mit KI"
            : "AI Renders | Create AI renders";
      } else if (
        [
          "/blog/how-to-make-sky-blue-real-estate-photo",
          "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles",
          "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres",
          "/pt/blog/como-fazer-ceu-azul-foto-imobiliaria",
          "/de/blog/wie-man-blauen-himmel-in-immobilienfotos-macht",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment rendre le ciel bleu dans vos photos immobilières"
          : newLang === "es"
          ? "Cómo Poner Cielo Azul En Fotos de Inmuebles"
          : newLang === "pt"
          ? "Como Deixar o Céu Azul em Fotos de Imóveis"
          : newLang === "de"
          ? "Wie man blauen Himmel in Immobilienfotos macht"
          : "How To Turn Sky Blue In Real Estate Photo";
      } else if (
        currentUrl.includes(
          "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
        )
      ) {
        newTitle = "IA dans l'immobilier : Applications et Avantages";
      } else if (
        [
          "/blog/how-to-sell-properties-with-ai",
          "/es/blog/como-utilizar-ia-para-vender-propiedades",
          "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes",
          "/pt/blog/como-vender-imoveis-com-ia",
          "/de/blog/wie-man-immobilien-mit-ki-verkauft",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment l'IA Révolutionne la Vente Immobilière"
          : newLang === "es"
          ? "¿Cómo usar la IA en Inmobiliarias? | IA para inmobiliarias"
          : newLang === "pt"
          ? "Como usar IA para Vender Propriedades | IA para Imobiliárias"
          : newLang === "de"
          ? "Wie man Immobilien mit KI verkauft | KI für Immobilien"
          : "What is AI and how is it used in Real Estate? | AI for Real Estate";
      } else if (
        [
          "/blog/home-staging-examples",
          "/es/blog/home-staging-ejemplos",
          "/fr/blog/exemples-de-home-staging",
          "/pt/blog/exemplos-home-staging",
          "/de/blog/home-staging-beispiele",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Exemples de Home Staging : Avant et Après"
          : newLang === "es"
          ? "Ejemplos de Home Staging: Antes y Después"
          : newLang === "pt"
          ? "Exemplos de Home Staging: Antes e Depois"
          : newLang === "de"
          ? "Home Staging Beispiele: Vorher und Nachher"
          : "Home Staging Examples: Before and After";
      } else if (
        [
          "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo",
          "/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta",
          "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere",
          "/pt/blog/como-acelerar-vendas-com-foto-capa-anuncio",
          "/de/blog/wie-man-verkaufe-mit-immobilienanzeigen-titelbild-beschleunigt",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment la photo de couverture peut accélérer la vente de votre propriété"
          : newLang === "es"
          ? "Cómo la foto de portada puede acelerar la venta de tu propiedad"
          : newLang === "pt"
          ? "Como a foto de capa pode acelerar a venda do seu imóvel"
          : newLang === "de"
          ? "Wie das Titelbild den Verkauf Ihrer Immobilie beschleunigen kann"
          : "How the cover photo can speed up your property sale";
      } else if (
        [
          "/help/how-to-furnish-virtually",
          "/es/help/como-amueblar-virtualmente",
          "/fr/help/comment-meubler-virtuellement",
          "/pt/ajuda/como-mobiliar-virtualmente",
          "/de/hilfe/wie-man-virtuell-einrichtet",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide étape par étape pour meubler virtuellement avec l'outil d'ameublement virtuel"
          : newLang === "es"
          ? "Tutorial de Amueblado Virtual: Guía Paso a Paso para Amueblar Virtualmente"
          : newLang === "pt"
          ? "Guia passo a passo para mobiliar virtualmente com a ferramenta de mobiliário virtual"
          : newLang === "de"
          ? "Schritt-für-Schritt-Anleitung zum virtuellen Einrichten mit dem virtuellen Einrichtungs-Tool"
          : "Step-by-Step Guide to Virtually Furnish Rooms with Virtual Furnishing Tool";
      } else if (
        currentUrl.includes("home-staging-virtual") ||
        currentUrl.includes("virtuelles-home-staging")
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging Virtual | Crear un home staging virtual"
            : newLang === "fr"
            ? "Home Staging Virtuel | Créer un home staging virtuel"
            : newLang === "pt"
            ? "Home Staging Virtual | Criar um home staging virtual"
            : newLang === "de"
            ? "Virtuelles Home Staging | Virtuelles Home Staging erstellen"
            : "Virtual Home Staging | Create a virtual home staging";
      } else if (
        [
          "/blog/sell-more-properties-with-ai",
          "/es/blog/vende-mas-propiedades-inmobiliarias-con-ia",
          "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia",
          "/pt/blog/vender-mais-imoveis-com-ia",
          "/de/blog/mehr-immobilien-mit-ki-verkaufen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "¿Cómo puede la IA ayudar a los agentes inmobiliarios a vender?"
            : newLang === "pt"
            ? "Como a IA pode ajudar os agentes imobiliários a vender?"
            : newLang === "de"
            ? "Wie kann KI Immobilienmaklern beim Verkauf helfen?"
            : "How can I help real estate agents sell?";
      } else if (
        currentUrl.includes("privacy") ||
        currentUrl.includes("datenschutz")
      ) {
        newTitle =
          newLang === "es"
            ? "Pedra | Política de Privacidad"
            : newLang === "fr"
            ? "Politique de Confidentialité - Pedra.so"
            : newLang === "pt"
            ? "Pedra | Política de Privacidade"
            : newLang === "de"
            ? "Pedra | Datenschutz"
            : "Pedra | Privacy Policy";
      } else if (
        [
          "/blog/real-estate",
          "/es/blog/bienes-raices",
          "/fr/blog/immobilier",
          "/pt/blog/imoveis",
          "/de/blog/immobilien",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Blog immobilier : conseils, investissement et tendances"
          : newLang === "es"
          ? "Blog inmobiliario: consejos, inversión y tendencias"
          : newLang === "pt"
          ? "Blog imobiliário: dicas, investimento e tendências"
          : newLang === "de"
          ? "Immobilien-Blog: Tipps, Investitionen und Trends"
          : "Real Estate Blog: Tips, Investment and Market Trends";
      } else if (
        [
          "/legal",
          "/es/legal",
          "/fr/legal",
          "/pt/legal",
          "/de/rechtliches",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Pedra | Aviso Legal"
            : newLang === "fr"
            ? "Mentions Légales - Pedra.so"
            : newLang === "pt"
            ? "Pedra | Aviso Legal"
            : newLang === "de"
            ? "Pedra | Rechtliches"
            : "Pedra | Legal Notice";
      } else if (
        [
          "/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone",
          "/releases/renovate-interiors-with-ai-from-phone",
          "/es/releases/renueva-espacios-desde-el-movil-con-ia",
          "/pt/lancamentos/renovar-interiores-com-ia-pelo-celular",
          "/de/releases/innenraume-mit-ki-vom-handy-renovieren",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Rénovation d'intérieur avec l'IA depuis votre téléphone | Juin 2024"
          : newLang === "es"
          ? "Renovación de interiores con IA desde tu móvil | Junio 2024"
          : newLang === "pt"
          ? "Renovação de interiores com IA pelo celular | Junho 2024"
          : newLang === "de"
          ? "Innenrenovierung mit KI vom Handy aus | Juni 2024"
          : "Interior renovation with AI from your phone | June 2024";
      } else if (
        [
          "/fr/releases/vide-chambres-et-enlever-objets-avec-ia",
          "/releases/empty-rooms-and-remove-objects-with-ai",
          "/es/releases/vacia-habitacion-y-quita-objetos-con-ia",
          "/pt/lancamentos/esvaziar-ambientes-e-remover-objetos-com-ia",
          "/de/releases/raume-leeren-und-objekte-mit-ki-entfernen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Supprimez les meubles avec l'IA | Mise à jour mai 2024"
          : location.pathname.includes("/pt/") || newLang === "pt"
          ? "Remova móveis com IA | Atualização maio 2024"
          : newLang === "es"
          ? "Elimina muebles con IA | Actualización mayo 2024"
          : newLang === "de"
          ? "Möbel mit KI entfernen | Update Mai 2024"
          : "Remove furniture with AI | May 2024 update";
      } else if (
        [
          "/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu",
          "/releases/add-3d-objects-and-make-sky-blue",
          "/es/releases/anade-objetos-3d-y-cielo-azul",
          "/pt/lancamentos/adicionar-objetos-3d-e-fazer-ceu-azul",
          "/de/releases/3d-objekte-hinzufugen-und-himmel-blau-machen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Ajout d'objets 3D et d'un ciel bleu | Mises à jour Pedra"
          : newLang === "es"
          ? "Añadir objetos 3D y cielo azul | Actualizaciones Pedra"
          : newLang === "pt"
          ? "Adicionar objetos 3D e céu azul | Atualizações Pedra"
          : newLang === "de"
          ? "3D-Objekte hinzufügen und Himmel blau machen | Pedra Updates"
          : "Adding 3D objects and blue sky | Pedra Updates";
      } else if (
        currentUrl.includes("releases") ||
        currentUrl.includes("lancamentos")
      ) {
        newTitle =
          newLang === "es"
            ? "Pedra | Novedades en herramienta de home staging virtual"
            : newLang === "fr"
            ? "Dernières Versions de Pedra"
            : newLang === "pt"
            ? "Pedra | Lançamentos da ferramenta de home staging virtual"
            : newLang === "de"
            ? "Pedra | Neuigkeiten zum virtuellen Home Staging Tool"
            : "Pedra | Releases on virtual home staging tool";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newTitle =
          newLang === "es"
            ? "Testimonios para Pedra | Opiniones y Valoraciones"
            : newLang === "fr"
            ? "Témoignages Pedra | Découvrez les Avis de Nos Clients"
            : newLang === "pt"
            ? "Testemunhos Pedra | Opiniões e Avaliações"
            : newLang === "de"
            ? "Bewertungen für Pedra | Rezensionen und Meinungen"
            : "Testimonials for Pedra | Reviews and Opinions";
      } else if (currentUrl.includes("blog")) {
        newTitle =
          newLang === "es"
            ? "Blog de Pedra | Virtual Home Staging y Fotografía Inmobiliaria"
            : newLang === "fr"
            ? "Blog de Pedra – Conseils et Astuces pour l'Immobilier avec l'IA"
            : newLang === "pt"
            ? "Blog Pedra | Home Staging Virtual e Fotografia Imobiliária"
            : newLang === "de"
            ? "Pedras Blog | Virtuelles Home Staging und Immobilienfotografie"
            : "Pedra's Blog | Home Staging Virtual and Real Estate Photography";
      } else if (
        ["/es/help", "/fr/help", "/help", "/pt/ajuda", "/de/hilfe"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Aide au Home Staging Virtuel & Tutoriels | Support Pedra"
          : newLang === "es"
          ? "Ayuda de Home Staging Virtual y Tutoriales | Soporte Pedra"
          : newLang === "pt" || location.pathname.includes("/pt/")
          ? "Ajuda de Home Staging Virtual e Tutoriais | Suporte Pedra"
          : newLang === "de"
          ? "Virtuelles Home Staging Hilfe & Tutorials | Pedra Support"
          : "Virtual Staging Help & Tutorials | Pedra Support";
      } else if (currentUrl.includes("about")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Sobre nosotros y nuestra misión en el sector inmobiliario"
            : newLang === "fr"
            ? "Pedra | À propos de nous et notre mission dans l'immobilier"
            : newLang === "pt"
            ? "Pedra | Sobre nós e nossa missão no setor imobiliário"
            : newLang === "de"
            ? "Pedra | Über uns und unsere Mission in der Immobilienbranche"
            : "Pedra | About us and our mission in real estate";
      } else {
        newTitle =
          newLang === "es"
            ? "Pedra | Home Staging Virtual con IA para Inmobiliarias"
            : newLang === "fr"
            ? "Pedra | Home Staging Virtuel avec IA pour l'Immobilier"
            : newLang === "pt"
            ? "Pedra | Home Staging Virtual com IA para Imobiliárias"
            : newLang === "de"
            ? "Pedra | Virtuelles Home Staging mit KI für Immobilien"
            : "Pedra | Virtual Home Staging with AI for Real Estate";
      }

      pageTitle.innerText = newTitle;
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default LangManager;
