import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

function HowToRenovateDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So nutzen Sie das Renovierungstool zur virtuellen Renovierung von
            Immobilien
          </h1>

          <div className="title-secondary gray">
            Tutorial zur virtuellen Renovierung von Immobilien
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wofür sind Pedras virtuelle Renovierungstools gedacht?
            </h2>
            <p className="article-text">
              Mit Pedras Renovierungstools können Sie in wenigen Sekunden eine
              potenzielle Renovierung einer Immobilie darstellen.
              <p style={{ marginTop: "10px" }}>
                Das Aufzeigen des Renovierungspotenzials ist ideal, um
                gebrauchte Immobilien zu verkaufen, die wahrscheinlich eine gute
                Investition des Käufers zur Verbesserung ihres Zustands
                benötigen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Dank Pedra müssen Sie keine Renderer oder Architekten
                beauftragen, um einen Renovierungsvorschlag für eine Immobilie
                zu erhalten.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich einen virtuellen Renovierungsvorschlag?
            </h2>
            <p className="article-text">
              Nachdem Sie die zu renovierenden Bilder hochgeladen haben, wählen
              Sie diese aus.
              <p style={{ marginTop: "10px" }}>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                und Sie sehen die{" "}
                <span style={{ fontWeight: "500" }}>"Renovieren"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="So nutzen Sie das Renovierungstool zur virtuellen Renovierung von Immobilien – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Renovierung: </span>
              Schnelle virtuelle Renovierung generieren
            </h2>
            <p style={{ marginTop: "10px" }}>
              Durch Klicken auf{" "}
              <span style={{ fontWeight: "500" }}>"Renovieren"</span> können Sie
              einen Renovierungsvorschlag für die ausgewählten Bilder
              generieren. Sie haben ihn in etwa 25 Sekunden.
              <p>
                Wenn Sie den Stil "Minimalistisch" und "Niedrige" Kreativität
                wählen, generiert die{" "}
                <span style={{ fontWeight: "500" }}>"Renovieren"</span> Option
                eine minimalistische Renovierung mit geringem Veränderungsgrad.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="So nutzen Sie das Renovierungstool zur virtuellen Renovierung von Immobilien – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Virtuelle Renovierung nach Ihren Wünschen generieren
            </h2>
            <p className="article-text">
              Pedra bietet eine Möglichkeit, die gewünschte Renovierung mit
              hohem Anpassungsgrad zu erhalten.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Sie können eigene Stile auswählen oder hochladen, den
                  Kreativitätsgrad für die Änderungen festlegen und sogar
                  bestimmen, ob bestimmte Teile des Originalbildes, wie die
                  Fenster, erhalten bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="So nutzen Sie das Renovierungstool zur virtuellen Renovierung von Immobilien – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Renovierungsstil
            </h3>
            <p className="article-text">
              Pedra bietet 9 grundlegende Stile für fortgeschrittene
              Renovierung: minimalistisch, traditionell, mediterran, etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Ihren eigenen Renovierungsstil hochladen.
                </span>{" "}
                Durch das Hochladen Ihres eigenen Renovierungsstils können Sie
                Renovierungsvorschläge basierend auf dem hochgeladenen Stil
                erstellen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Renovierungskreativität
            </h3>
            <p className="article-text">
              Sie können auch den Grad der fortgeschrittenen
              Renovierungskreativität zwischen Niedrig, Mittel, Hoch und Sehr
              Hoch wählen.
              <p style={{ marginTop: "10px" }}>
                Niedrig und Mittel werden die Architektur des Originalfotos gut
                bewahren.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hoch und Sehr Hoch sollen die Architektur des Raums aggressiv
                verändern, sodass das resultierende Foto dem Original nicht
                ähneln wird.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Fenster bei der Renovierung erhalten
            </h3>
            <p className="article-text">
              Sie haben auch die Möglichkeit, die Fenster bei einer
              fortgeschrittenen Renovierung zu erhalten. Dies ist wichtig, wenn
              Sie die Aussicht aus den Fenstern beibehalten möchten oder wenn
              sie exakt wie im Originalfoto sein sollen.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Renovierungsbild generieren
            </h2>
            <p>
              Sobald Sie Stil, Kreativitätsgrad und die Fenstererhaltung
              ausgewählt haben, können Sie die Bilder generieren. Es dauert etwa
              15 Sekunden pro Bild, es sei denn, Sie erhalten Fenster, was etwas
              länger dauert.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  So vermeiden Sie unerwünschte Änderungen an Fenstern und Türen
                </h4>
                <p className="callout-p">
                  Wenn Türen erscheinen, wo Fenster waren, oder wenn in den
                  generierten Bildern mehr Fenster als erwartet sind, empfehlen
                  wir folgende Maßnahmen:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Generieren Sie 2 oder 3 zusätzliche Renovierungen
                      </span>
                      . Jede Renovierung ist einzigartig, sodass einige die
                      Fenster und Türen besser erhalten als andere.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      {" "}
                      Halten Sie den Kreativitätsgrad niedrig.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Wenn das Originalfoto Spiegelungen oder Spiegel hat, die
                        wie Fenster aussehen, verwenden Sie das Tool, um sie aus
                        dem Bild zu entfernen
                      </span>
                      . Generieren Sie dann die Renovierung auf dem Bild ohne
                      Spiegelungen oder Spiegel.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenovateDe;
