import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import HowToCurtainEffectImage from "../Images/HowToCurtainEffectDeImage.jpg";

import CurtainEffect from "../CurtainEffect";
function HowToCurtainEffectDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Wie man Vorher/Nachher-Vorhang-Effekte für virtuelles Home Staging
            erstellt
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung von Vorher/Nachher-Vorhangeffekten für
            virtuelles Staging
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Was ist ein Vorhang-Effekt und warum ist er nützlich?
            </h2>
            <p className="article-text">
              Ein Vorhang-Effekt ist eine interaktive Möglichkeit, Vorher- und
              Nachher-Bilder des virtuellen Home Stagings zu präsentieren. Er
              ermöglicht es den Betrachtern, zwischen der ursprünglichen und der
              gestalteten Version eines Raums zu wechseln, wodurch die
              Transformation leicht zu erkennen ist.
            </p>
            <p className="article-text">
              Dieser Effekt ist besonders wertvoll für Immobilienprofis, weil
              er:
            </p>
            <ul>
              <li>
                Die Wirkung des virtuellen Stagings auf überzeugende Weise
                demonstriert
              </li>
              <li>Kunden hilft, das Potenzial leerer Räume zu verstehen</li>
              <li>Ansprechende Inhalte für Marketingmaterialien erstellt</li>
              <li>
                Vertrauen aufbaut, indem der ursprüngliche Raum neben den
                Verbesserungen gezeigt wird
              </li>
            </ul>

            <h2 className="article-subtitleh2">
              Wie man einen Vorhang-Effekt mit Pedra erstellt
            </h2>
            <p className="article-text">
              Traditionell erforderte die Erstellung eines Vorhang-Effekts
              technisches Wissen und Programmierkenntnisse. Mit Pedra können Sie
              jedoch mit nur einem Klick einen erstellen.
            </p>
            <p className="article-text">
              Sobald Sie Ihr virtuelles Staging-Bild erstellt haben, müssen Sie
              einfach:
            </p>
            <ol>
              <li>Auf Ihr virtuell gestaltetes Bild klicken</li>
              <li>
                Auf "Vorhang-Effekt" klicken - dadurch wird automatisch ein
                teilbarer Link generiert, der Ihren Vorher/Nachher-Vergleich mit
                einem interaktiven Schieberegler zeigt
              </li>
            </ol>

            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToCurtainEffectImage}
              alt="Wie man einen Vorhang-Effekt erstellt - Screenshot der Pedra-Software"
            />

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für effektive Vorhang-Effekte:
                <ul>
                  <li>
                    Verwenden Sie hochwertige Bilder für die beste Präsentation
                  </li>
                  <li>
                    Teilen Sie den Vorhang-Effekt-Link auf Ihrer Website oder in
                    sozialen Medien, um Ihre virtuelle Staging-Arbeit zu
                    präsentieren
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">
              Beispiele für Vorhang-Effekte
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für Vorhang-Effekte, die virtuelle
              Staging-Transformationen zeigen:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Leere Terrasse vor dem virtuellen Staging"}
              altAfter={"Möblierte Terrasse nach dem virtuellen Staging"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Leere Terrasse vor dem virtuellen Staging"}
              altAfter={"Möblierte Terrasse nach dem virtuellen Staging"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Leere Terrasse vor dem virtuellen Staging"}
              altAfter={"Möblierte Terrasse nach dem virtuellen Staging"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Leere Terrasse vor dem virtuellen Staging"}
              altAfter={"Möblierte Terrasse nach dem virtuellen Staging"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToCurtainEffectDe;
