import React from "react";
import ReleaseEsAug2024Es from "./Images/ReleaseAug2024Es.png";
import ReleaseJul2024Es from "./Images/ReleaseJul2024Es.png";
import ReleaseJun2024Es from "./Images/ReleaseJun2024Es.png";
import ReleaseMay2024Es from "./Images/ReleaseMay2024Es.png";
import ReleaseSep2024Es from "./Images/ReleaseSep2024Es.png";
import ReleaseOct24 from "./Images/ReleaseOct2024.png";
import ReleasesNov24Es from "./Images/ReleaseNov2024Es.png";
import { Link } from "react-router-dom";
import ReleaseDec24 from "./Images/ReleaseDec2024.png";

function ReleasesEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Lanzamientos</h1>
          <h2 className="title-secondary">
            Ve aquí los últimos lanzamientos de Pedra <br />
          </h2>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>DICIEMBRE 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/planos-y-espacios-comerciales"
              >
                Renders de planos y espacios comerciales
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/planos-y-espacios-comerciales"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseDec24}
                alt="Lanzamientos de Pedra del mes de diciembre 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>NOVIEMBRE 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/espacios-de-trabajo"
              >
                Espacios de trabajo para colaborar en{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/espacios-de-trabajo"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleasesNov24Es}
                alt="Lanzamientos de Pedra del mes de noviembre 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>OCTUBRE 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/mejoras-pedra-piscinas-y-preservacion"
              >
                Pedra: Mejoras en piscinas, preservación de espacios y
                renovación selectiva
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/mejoras-pedra-piscinas-y-preservacion"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseOct24}
                alt="Lanzamientos de Pedra del mes de octubre 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>SEPTIEMBRE 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/lanzamientos/renueva-y-amuebla-en-un-clic"
              >
                Pedra: renueva y amuebla espacios en un solo clic, mejores
                resultados en 'Auto' y preservación del espacio.
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/lanzamientos/renueva-y-amuebla-en-un-clic"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseSep2024Es}
                alt="Lanzamientos Pedra mes de Septiembre 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>AGOSTO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/amueblado-realista"
              >
                Pedra: Amueblado más realista, generar propuestas de renovación
                con tu propio estilo y más
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/amueblado-realista"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseEsAug2024Es}
                alt="Lanzamientos Pedra mes de Agosto 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JULIO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/anade-objetos-3d-y-cielo-azul"
              >
                Pedra: Añade objetos 3D, cielo azul en 1 click, y embellece o
                ilumna automaticamente
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/anade-objetos-3d-y-cielo-azul"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJul2024Es}
                alt="Lanzamientos Pedra mes de Julio 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUNIO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/renueva-espacios-desde-el-movil-con-ia"
              >
                Pedra: Renueva espacios desde el móvil, edita pies de foto y
                cambia las marcas de agua
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/renueva-espacios-desde-el-movil-con-ia"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJun2024Es}
                alt="Lanzamientos Pedra mes de Junio 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>MAYO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/vacia-habitacion-y-quita-objetos-con-ia"
              >
                Pedra: Vaciar Habitaciones y Quitar Objetos, Estilos de
                Renovación y Nueva Interfaz
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/vacia-habitacion-y-quita-objetos-con-ia"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseMay2024Es}
                alt="Lanzamientos Pedra mes de Junio 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleasesEs;
