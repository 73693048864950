import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageTerraceFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingTerracePt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Criar Home Staging Virtual para Terraços
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como criar home staging virtual para terraços
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-position">Fundador da Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Como posso criar home staging virtual para um terraço?
            </h2>
            <p className="article-text">
              Com a Pedra, você pode criar home staging virtual para terraços em
              apenas alguns cliques.
              <p>
                Para começar, acesse o menu{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                selecione <span style={{ fontWeight: "500" }}>"Mobiliar"</span>.
                Escolha "Terraço" como tipo de ambiente e em cerca de 2 ou 3
                minutos, você terá sua imagem de home staging virtual do terraço
                pronta para baixar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Como criar home staging virtual para um terraço – Exemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              Como criar home staging virtual para terraços?
            </h2>
            <p className="article-text">
              Para conseguir seu{" "}
              <Link className="article-link" to="/home-staging-terrace">
                home staging virtual para um terraço
              </Link>
              , siga estes passos simples:
            </p>
            <ol>
              <li>
                Faça upload da imagem do terraço que você deseja mobiliar
                virtualmente
              </li>
              <li>Selecione a imagem para começar a editar</li>
              <li>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span> e
                depois clique em{" "}
                <span style={{ fontWeight: "500" }}>"Mobiliar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menu para criar home staging virtual para um terraço"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Mobiliar"</span>{" "}
              você precisará seguir estes passos:
            </p>
            <ol>
              <li>
                Selecione a{" "}
                <span style={{ fontWeight: "500" }}>
                  posição dos elementos de staging
                </span>
                . Para terraços, recomendamos usar "Desenhar" para indicar áreas
                específicas onde colocar móveis de exterior, plantas e elementos
                decorativos.
              </li>
              <li>
                Selecione <span style={{ fontWeight: "500" }}>"Terraço"</span>{" "}
                como tipo de ambiente. Isso garantirá que a Pedra coloque itens
                apropriados para áreas externas como móveis de jardim, plantas e
                elementos decorativos.
              </li>
              <li>
                Selecione o <span style={{ fontWeight: "500" }}>estilo</span>.
                Para terraços, estilos como "Moderno", "Mediterrâneo" ou
                "Tropical" geralmente funcionam bem.
              </li>
              <li>
                Escolha o{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imagens a gerar
                </span>
                . Recomendamos escolher 2 ou 3 imagens para ter diferentes
                opções de design de terraço.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Dicas para melhor home staging virtual de um terraço:
                <ul>
                  <li>
                    Certifique-se de que a fotografia mostre todo o layout do
                    terraço claramente para uma mobília mais precisa
                  </li>
                  <li>
                    Capture quaisquer características únicas como vista,
                    guarda-corpos ou detalhes arquitetônicos
                  </li>
                  <li>
                    Use a opção "Desenhar" para indicar áreas específicas para
                    móveis, plantas ou elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Exemplo de como criar home staging virtual para um terraço"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Por que fazer home staging virtual para um terraço?
            </h2>
            <p className="article-text">
              O terraço é um dos espaços mais valorizados em uma casa e pode ser
              um grande diferencial na venda. O home staging virtual para
              terraços permite que você mostre todo o potencial do espaço,
              criando ambientes acolhedores e funcionais que ajudam os
              compradores a se imaginarem relaxando e aproveitando o espaço ao
              ar livre.
            </p>
            <p className="article-text">
              Com um bom home staging virtual para um terraço, você pode:
            </p>
            <ul>
              <li>
                Transformar terraços vazios ou subutilizados em espaços
                acolhedores e funcionais
              </li>
              <li>Destacar a funcionalidade e o layout do ambiente</li>
              <li>
                Mostrar diferentes possibilidades de uso e organização do espaço
                externo
              </li>
              <li>
                Ajudar os compradores a imaginar momentos de lazer e relaxamento
                ao ar livre
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Exemplos de home staging virtual para terraços
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de home staging virtual que podem ser
              aplicados a terraços:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de criar o home staging virtual"}
              altAfter={"Depois de criar o home staging virtual"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingTerracePt;
