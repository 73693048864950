import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import BathroomIcon from "./Icons/hero-icons/BathroomIcon.jsx";
import BedIcon from "./Icons/hero-icons/BedIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";

// Images
import Bouchra from "./Images/Bouchra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish2.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish2.png";
import BeforeBedroomStaging from "./Images/curtain-effect-hero/BeforeBedroomStaging.png";
import AfterBedroomStaging from "./Images/curtain-effect-hero/AfterBedroomStaging.png";
import BeforeTerraceStaging from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterTerraceStaging from "./Images/curtain-effect-hero/AfterTerraceStaging.png";
import BeforeBathroomStaging from "./Images/curtain-effect-hero/BeforeBathroomStaging.png";
import AfterBathroomStaging from "./Images/curtain-effect-hero/AfterBathroomStaging.png";
import BeforeStagingExample1 from "./Images/curtain-effect/BeforeStagingExample1.png";
import AfterStagingExample1 from "./Images/curtain-effect/AfterStagingExample1.png";
import BeforeStagingExample2 from "./Images/curtain-effect/BeforeStagingExample2.png";
import AfterStagingExample2 from "./Images/curtain-effect/AfterStagingExample2.png";
import BeforeStagingExample3 from "./Images/curtain-effect/BeforeStagingExample3.png";
import AfterStagingExample3 from "./Images/curtain-effect/AfterStagingExample3.png";
import BeforeStagingExample4 from "./Images/curtain-effect/BeforeStagingExample4.png";
import AfterStagingExample4 from "./Images/curtain-effect/AfterStagingExample4.png";
import BeforeStagingExample5 from "./Images/curtain-effect/BeforeStagingExample5.png";
import AfterStagingExample5 from "./Images/curtain-effect/AfterStagingExample5.png";
import BeforeStagingExample6 from "./Images/curtain-effect/BeforeStagingExample6.png";
import AfterStagingExample6 from "./Images/curtain-effect/AfterStagingExample6.png";
import BeforeStagingExample7 from "./Images/curtain-effect/BeforeStagingExample7.png";
import AfterStagingExample7 from "./Images/curtain-effect/AfterStagingExample7.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);

  // Images in the staging examples hero

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
    } else if (example === "Bedroom") {
      setExample({
        name: "Bedroom",
        beforeImage: BeforeBedroomStaging,
        afterImage: AfterBedroomStaging,
      });
    } else if (example === "Terrace") {
      setExample({
        name: "Terrace",
        beforeImage: BeforeTerraceStaging,
        afterImage: AfterTerraceStaging,
      });
    } else {
      setExample({
        name: "Bathroom",
        beforeImage: BeforeBathroomStaging,
        afterImage: AfterBathroomStaging,
      });
    }
  };

  // Images in the staging examples body

  const stagingExamples = [
    {
      name: "stagingExample1",
      image: BeforeStagingExample1,
    },
    {
      name: "stagingExample2",
      image: BeforeStagingExample2,
    },
    {
      name: "stagingExample3",
      image: BeforeStagingExample3,
    },
    {
      name: "stagingExample4",
      image: BeforeStagingExample4,
    },
    {
      name: "stagingExample5",
      image: BeforeStagingExample5,
    },
    {
      name: "stagingExample6",
      image: BeforeStagingExample6,
    },
    {
      name: "stagingExample7",
      image: BeforeStagingExample7,
    },
  ];

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeStagingExample1,
    afterImage: AfterStagingExample1,
  });

  const handleStagingExample = (example) => {
    if (example === "stagingExample1") {
      setStagingExample({
        name: "stagingExample1",
        beforeImage: BeforeStagingExample1,
        afterImage: AfterStagingExample1,
      });
    } else if (example === "stagingExample2") {
      setStagingExample({
        name: "stagingExample2",
        beforeImage: BeforeStagingExample2,
        afterImage: AfterStagingExample2,
      });
    } else if (example === "stagingExample3") {
      setStagingExample({
        name: "stagingExample3",
        beforeImage: BeforeStagingExample3,
        afterImage: AfterStagingExample3,
      });
    } else if (example === "stagingExample4") {
      setStagingExample({
        name: "stagingExample4",
        beforeImage: BeforeStagingExample4,
        afterImage: AfterStagingExample4,
      });
    } else if (example === "stagingExample5") {
      setStagingExample({
        name: "stagingExample5",
        beforeImage: BeforeStagingExample5,
        afterImage: AfterStagingExample5,
      });
    } else if (example === "stagingExample6") {
      setStagingExample({
        name: "stagingExample6",
        beforeImage: BeforeStagingExample6,
        afterImage: AfterStagingExample6,
      });
    } else if (example === "stagingExample7") {
      setStagingExample({
        name: "stagingExample7",
        beforeImage: BeforeStagingExample7,
        afterImage: AfterStagingExample7,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was ist virtuelles Home-Staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Virtuelles Home-Staging ist eine Immobilienmarketing-Technik, die digitale Werkzeuge nutzt, um eine Immobilie für potenzielle Käufer oder Mieter attraktiv zu präsentieren. Im Gegensatz zum traditionellen Home-Staging, das physische Änderungen an der Immobilie vornimmt, erfolgt virtuelles Home-Staging durch Bildbearbeitung und digitales Rendering, um das Erscheinungsbild der Räume zu verbessern.",
        },
      },
      {
        "@type": "Question",
        name: "Woraus besteht virtuelles Home-Staging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Virtuelles Home-Staging besteht aus der Bearbeitung von Immobilienbildern mit digitalen Werkzeugen. Dies umfasst: \n\n- Bildbearbeitung: Retuschieren und Verbessern von Fotos mit KI, Entfernen unerwünschter Objekte, Anpassen von Beleuchtung und Hinzufügen virtueller Möbel oder Dekoration. \n\n- Design-Rendering: Erstellen digitaler Darstellungen, die zeigen, wie die Immobilie nach Dekoration oder Renovierung aussehen könnte. \n\n- Zeit- und Kostenersparnis: Es ist schneller und wirtschaftlicher als traditionelles Home-Staging, da keine physischen Änderungen an der Immobilie erforderlich sind.",
        },
      },
      {
        "@type": "Question",
        name: "Welche Schritte sind im virtuellen Home-Staging-Prozess enthalten?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Der virtuelle Home-Staging-Prozess umfasst folgende Schritte: \n\n1. Projektfotos hochladen. \n\n2. Virtuelle Home-Staging-Bearbeitungsoptionen auswählen, wie das Leeren möblierter Räume oder Hinzufügen virtueller Möbel. \n\n3. Projekt herunterladen, sobald die Änderungen abgeschlossen sind.",
        },
      },
      {
        "@type": "Question",
        name: "Wie lange dauert es, ein virtuelles Home-Staging-Projekt abzuschließen?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Die Zeit für ein virtuelles Home-Staging-Projekt hängt von der Anzahl der Bilder und der erforderlichen Bearbeitungszeit ab. Das Hochladen von Dateien und Herunterladen von Projekten geht jedoch schnell, und die Ergebnisse werden sofort erzielt.",
        },
      },
      {
        "@type": "Question",
        name: "Kann virtuelles Home-Staging bei leeren Immobilien durchgeführt werden?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Ja! Virtuelles Home-Staging ist ideal für leere Immobilien. Sie können sie mit KI oder manuell möblieren und die gewünschten Möbel hinzufügen, was hilft, das Potenzial der Räume zu visualisieren und die Präsentation der Immobilie zu verbessern.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Virtuelles Home-Staging</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  Das beste virtuelle Home-Staging direkt zur Hand
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Schnell:</strong> Ergebnisse in Sekunden
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Von Anfang an intuitiv
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualität:</strong> Hyperrealistische Ergebnisse
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Hilfe:</strong> Menschliche Unterstützung bei Bedarf
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 für 100 Bilder
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Fotografie-Direktor bei aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO bei Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO bei Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Vor dem virtuellen Home-Staging"}
                  altAfter={"Nach dem virtuellen Home-Staging"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Wohnzimmer
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Bedroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bedroom")}
                    >
                      <BedIcon />
                      Schlafzimmer
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Bathroom" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Bathroom")}
                    >
                      <BathroomIcon />
                      Badezimmer
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Terrace" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Terrace")}
                    >
                      <PurpleEnhanceIcon />
                      Terrasse
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          ÜBER 5.000 IMMOBILIENPROFIS VERTRAUEN BEREITS PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Virtuelles Home-Staging zum{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            schnelleren Verkauf
          </span>{" "}
          jeder Art von Immobilie.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Virtuell möblieren mit 1 Klick
                </h3>
                <div className="feature-text">
                  Erstellen Sie automatisches Home-Staging mit 1 Klick
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Beschriftungen</h3>
                <div className="feature-text">
                  Kennzeichnen Sie Ihr Bild als virtuelles Home-Staging mit
                  Ihrem eigenen Text
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Fotos verbessern und korrigieren
                </h3>
                <div className="feature-text">
                  Verbessern Sie die Qualität und Perspektive von Fotos für
                  besseres virtuelles Home-Staging
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Eigene Möbel hinzufügen</h3>
                <div className="feature-text">
                  Fügen Sie Persönlichkeit hinzu, indem Sie Ihre eigenen Möbel
                  im virtuellen Home-Staging einsetzen
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Virtuell renovieren</h3>
                <div className="feature-text">
                  Erstellen Sie Renovierungsvorschläge für Ihre
                  Bestandsimmobilien
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Objekte löschen oder Räume leeren
                </h3>
                <div className="feature-text">
                  Entfernen Sie Objekte oder Wasserzeichen für ein saubereres
                  virtuelles Home-Staging
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Beispiele unseres virtuellen Home-Stagings.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Vor dem virtuellen Home-Staging"}
            altAfter={"Nach dem virtuellen Home-Staging"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            100+
          </span>{" "}
          Bewertungen unseres virtuellen Home-Stagings.
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Fotografie-Direktor bei aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Fotografie-Direktor bei aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra läuft großartig. Ich denke, das Beste am Tool ist{" "}
            <span className="text-highlight">
              die Geschwindigkeit, mit der es Bilder generiert.
            </span>
          </q>
          <div className="testimonial-author-container-date">3. Juli 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Immobilienberaterin bei iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienberaterin bei iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Pedra ist die Entdeckung des Jahrhunderts
            </span>
          </q>
          <div className="testimonial-author-container-date">3. Juli 2024</div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Immobilienmakler bei iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienmakler bei iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Beeindruckend.
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Außerdem{" "}
              <span className="text-highlight">
                schien der spezifische Bereich, den ich leeren wollte,
                komplizierter, weil er tief war, aber es wurde brillant gemacht.
              </span>
            </div>
          </q>
          <div className="testimonial-author-container-date">26. Juni 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager bei Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager bei Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Ein paar Häuser wurden dank Pedra verkauft.
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              Ich habe Vorschläge, wie die Terrasse möbliert aussehen könnte, im
              Titelbild platziert und viele Leute haben begonnen, auf die
              Anzeige zu klicken
            </div>
          </q>
          <div className="testimonial-author-container-date">7. Juni 2024</div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienmaklerin
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Mit Pedra konnte ich{" "}
              <a
                className="article-link"
                href="https://www.instagram.com/p/C4A6kCmIeMy/"
                rel="nofollow"
              >
                eine Immobilie für 500.000 €
              </a>{" "}
              in weniger als einem Tag verkaufen.{" "}
            </span>{" "}
            Die Wohnung, die ich zum Verkauf hatte, war eine
            RENOVIERUNGSBEDÜRFTIGE IMMOBILIE und mit Pedra bekam ich
            Visualisierungen, die es ermöglichten, das Potenzial der Räume nach
            der Renovierung zu sehen - aber ohne tatsächliche Renovierung!
          </q>
          <div className="testimonial-author-container-date">2. Juni 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Immobilienberaterin bei Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Immobilienberaterin bei Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            <span className="text-highlight">
              Ich bin sehr zufrieden mit den Ergebnissen
            </span>
            <div style={{ marginTop: "10px", display: "inline" }}>
              . Außerdem haben sie das Produkt entsprechend meinem geteilten
              Feedback verbessert!
            </div>
          </q>
          <div className="testimonial-author-container-date">4. Juni 2024</div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Wie funktioniert es?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Erstellen Sie ein Projekt und laden Sie ein Bild hoch</h2>
            <div className="value-prop-text">
              Laden Sie ein oder mehrere Bilder zu Pedra hoch.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Dateien hochlädt, um virtuelles Home Staging in Pedra zu erstellen – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>
              Wählen Sie eine Option, um Ihr virtuelles Home Staging zu
              erstellen
            </h2>
            <div className="value-prop-text">
              Laden Sie ein Bild hoch und klicken Sie auf die entsprechende
              Option, um Ihr virtuelles Home Staging zu erstellen.
              <p>
                Sie können Räume möblieren, spezifische Objekte hinzufügen, sie
                renovieren, Teile entfernen und vieles mehr!
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Wie man Optionen wählt, um virtuelles Home Staging zu erstellen – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>
              Warten Sie und laden Sie das virtuelle Home Staging herunter
            </h2>
            <div className="value-prop-text">
              Die Erstellung virtueller Home Stagings kann von 15 Sekunden bis
              zu einigen Minuten dauern.
              <p>
                Sobald sie erstellt sind, können Sie sie weiter bearbeiten oder
                herunterladen.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Wie man ein virtuelles Home Staging herunterlädt – Infografik"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Die virtuellen Home Stagings, die Sie für 29 € pro Monat benötigen.{" "}
          <br />{" "}
          <span
            style={{
              color: "rgb(248, 121, 42)",
              fontWeight: "bold",
            }}
          >
            Und ohne Bindung.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29 €</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">
              Beinhaltet
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 Bildgenerierungen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovieren und neu gestalten
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Räume leeren
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Möblieren
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Objekte entfernen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Auflösung erhöhen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Böden ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Fotografie verbessern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Virtuelle Videos generieren
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Grundrisse
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Projekt-Sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Wasserzeichen hinzufügen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzter Support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Jederzeit kündbar.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Kaufen Sie bei Bedarf weitere Bilder für 0,20 € pro Bild.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Fragen und Antworten</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Was ist Home Staging?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Home Staging ist eine Immobilienmarketing-Technik, bei der eine
                Immobilie (Haus, Wohnung etc.) für den Verkauf oder die
                Vermietung vorbereitet wird, um sie für potenzielle Käufer oder
                Mieter attraktiver zu machen. Dies wird durch eine strategische
                Präsentation und Dekoration des Raums erreicht, um die
                positivsten Eigenschaften hervorzuheben und Mängel zu
                minimieren.
                <br />
                <br />
                Der Home Staging-Prozess beinhaltet das Reinigen,
                Entpersonalisieren und Entrümpeln der Immobilie, damit sich
                potenzielle Käufer oder Mieter leicht vorstellen können, wie es
                wäre, in diesem Raum zu leben. Dazu kann auch das Umstellen von
                Möbeln, das Hinzufügen von Dekoelementen und in manchen Fällen
                kleinere Renovierungen oder Reparaturen gehören, um das
                Gesamterscheinungsbild der Immobilie zu verbessern.
                <br />
                <br />
                Das Ziel von Home Staging ist es, bei Besuchern einen positiven
                ersten Eindruck zu hinterlassen und die Chancen zu erhöhen, die
                Immobilie schnell und zu einem günstigen Preis zu verkaufen oder
                zu vermieten. Es ist eine zunehmend beliebte Strategie im
                Immobilienmarkt, da sie die visuelle und emotionale
                Anziehungskraft einer Immobilie steigern kann.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Woraus besteht virtuelles Home Staging?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Virtuelles Home Staging ist eine Variante des traditionellen
                  Home Stagings, die digitale Werkzeuge und Technologie nutzt,
                  um eine Immobilie potenziellen Käufern oder Mietern attraktiv
                  zu präsentieren. Anstatt physische Änderungen an der Immobilie
                  vorzunehmen, wie das Umstellen von Möbeln oder das Hinzufügen
                  von Dekoelementen, wird virtuelles Home Staging hauptsächlich
                  auf folgende Weise durchgeführt:
                </div>
                <h4>Bildbearbeitung:</h4>Mit Pedra können Sie Bilder mit KI
                bearbeiten, um Fotos der Immobilie zu retuschieren und zu
                verbessern. Dies kann das Entfernen unerwünschter Objekte, das
                Anpassen von Beleuchtung und Farbe und sogar das Hinzufügen
                virtueller Möbel oder Dekoration umfassen.
                <br />
                <br />
                <h4>Design-Rendering:</h4> Erstellen Sie digitale Darstellungen
                davon, wie die Immobilie nach der Dekoration oder Renovierung
                aussehen könnte. Dies kann Käufern helfen, das Potenzial des
                Raums zu visualisieren und Ideen für ihre eigene Dekoration zu
                inspirieren.
                <br />
                <br />
                <h4>Zeit- und Kostenersparnis:</h4>Virtuelles Home Staging ist
                schneller und wirtschaftlicher als traditionelles Home Staging,
                da keine physischen Möbel oder Accessoires gemietet oder
                physische Änderungen an der Immobilie vorgenommen werden müssen.
                Dies kann besonders bei leeren Immobilien oder in Situationen,
                in denen Zeit ein kritischer Faktor ist, von Vorteil sein.
                <br />
                <br />
                <h4>Flexibilität und Anpassung:</h4>Mit virtuellem Home Staging
                ist es möglich, schnell und ohne Verpflichtungen mit
                verschiedenen Dekorationsstilen und Raumkonfigurationen zu
                experimentieren. Dies ermöglicht es, die Präsentation der
                Immobilie entsprechend dem Zielmarkt und den Präferenzen
                potenzieller Käufer oder Mieter anzupassen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Tipps für virtuelles Home Staging
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Kennen Sie Ihre Zielgruppe:</h4>Das Verständnis der
                Bedürfnisse und Präferenzen Ihrer Zielgruppe wird Ihnen helfen,
                virtuelles Home Staging-Content zu erstellen, der bei ihnen
                Anklang findet. Berücksichtigen Sie, wer die potenziellen Käufer
                oder Mieter sind und passen Sie Ihren Ansatz entsprechend an.
                <br />
                <br />
                <h4>Seien Sie ehrlich und transparent:</h4>Stellen Sie sicher,
                dass der virtuelle Home Staging-Content das Erscheinungsbild und
                die Eigenschaften der Immobilie genau widerspiegelt. Vermeiden
                Sie übermäßige Bearbeitung oder Manipulation von Bildern, da
                dies unrealistische Erwartungen wecken kann.
                <br />
                <br />
                <h4>Mehrere Perspektiven:</h4>Erfassen Sie die Immobilie aus
                verschiedenen Blickwinkeln, um eine vollständige Ansicht zu
                bieten. Dies ermöglicht es Betrachtern, eine bessere Vorstellung
                von der Raumaufteilung und den einzigartigen Merkmalen der
                Immobilie zu bekommen.
                <br />
                <br />
                <h4>Stilistische Konsistenz bewahren:</h4>Verwenden Sie in allen
                Bildern und virtuellen Rundgängen einen kohärenten
                Dekorationsstil, um ein einheitliches visuelles Erlebnis zu
                schaffen. Dies hilft, das Interesse der Betrachter
                aufrechtzuerhalten und erleichtert die Visualisierung der
                Immobilie als Ganzes.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">
                Preis für virtuelles Home Staging
              </h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Der Preis für Pedra beträgt €29 plus Mehrwertsteuer pro Monat.
                <br />
                <br />
                Für diesen Preis können Sie bis zu 100 Bilder pro Monat
                generieren.
                <br />
                <br />
                Es gibt keine Bindung, Sie können also kündigen, wenn Sie es
                nicht mehr benötigen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                Welche Schritte sind im virtuellen Home Staging-Prozess
                enthalten?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                Der Home Staging-Prozess ist recht schnell und einfach. Hier
                sind die Schritte, die Sie befolgen müssen, um virtuelles Home
                Staging mit Pedra zu erstellen:
                <br />
                <br />
                1. <strong>Projektfotos hochladen:</strong> Bei der Erstellung
                eines Projekts können Sie ein oder mehrere Bilder zum Bearbeiten
                hochladen.
                <br />
                <br />
                2. <strong>Virtuelle Home Staging-Optionen:</strong> Sobald Sie
                die Fotos ausgewählt haben, finden Sie im Dropdown-Menü "Mit KI
                bearbeiten" alle Optionen, die Sie benötigen, vom Leeren von
                Räumen über das Hinzufügen von Möbeln bis hin zur Renovierung
                von Räumen und mehr.
                <br />
                <br />
                3. <strong>Projekt herunterladen:</strong> Sobald Sie alle
                Änderungen vorgenommen und die Bilder bearbeitet haben, müssen
                Sie nur noch die Bilder Ihres Home Stagings herunterladen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                Kann virtuelles Home Staging bei leeren Immobilien durchgeführt
                werden?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  Ja! Eine der Funktionen unseres virtuellen Home Staging-Tools
                  ist das Möblieren des Raums. Sie können die Option "Möblieren"
                  verwenden, um den Raum automatisch mit KI zu möblieren, oder
                  wenn Sie es vorziehen, können Sie mit der Option "Objekt
                  hinzufügen" manuell die gewünschten Möbel hinzufügen.
                </p>
                <p>
                  Mit beiden Optionen hilft Ihnen unser virtuelles Home
                  Staging-Tool dabei, das Potenzial Ihrer Räume zu
                  visualisieren, was die Entscheidungsfindung erleichtert und
                  die Präsentation der Immobilie verbessert.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingDe;
