import React from "react";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import OscarMelendre from "./Images/Oscar Melendre image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import MaxVivesFierro from "./Images/Max Vives-Fierro image.png";
import JordiVives from "./Images/Jordi Vives image.png";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function Testimonials() {
  return (
    <>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <div className="header-stars">
              ★★★★★
              <div className="header-stars-text">
                {" "}
                "The Discovery of the Century"
              </div>
            </div>
            <h1 className="section-first-title">Pedra Testimonials</h1>
            <div className="section-first-text">
              <h2 className="section-first-text-bold">
                Get inspired by the comments and reviews from Pedra's customers.
              </h2>
              <h2 className="section-first-text-gray">
                Reviews on our virtual home staging and photography enhancement
                features.
              </h2>
            </div>
          </div>
        </section>
      </section>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Director of Photography at aProperties"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Director of Photography at aProperties
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra is doing wonderfully. I think the best feature of the tool is
            how quickly it generates images.
          </div>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Real Estate Agent at Huspy"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent at Huspy
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            🌈 🙌🏾 I’m back{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              to make magic with Pedra
            </a>
            … Now we wait!
          </div>
          <div className="testimonial-author-container-date">July 1, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Real Estate Advisor at iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra is the discovery of the century
          </div>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, CEO at Actívox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                CEO at Actívox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra is being very useful for us. We are very happy!!!!
            <div style={{ marginTop: "10px" }}>
              It should be more expensive.
            </div>
          </div>
          <div className="testimonial-author-container-date">May 28, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Real Estate Agent at iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent at iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Impressive.
            <div style={{ marginTop: "10px" }}>
              Also, the specific space I wanted to clear seemed more complicated
              because it was deep, but it did it brilliantly.
            </div>
          </div>
          <div className="testimonial-author-container-date">June 26, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager at Lucas Fox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager at Lucas Fox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            A couple of houses have been sold thanks to Pedra.
            <div style={{ marginTop: "10px" }}>
              I placed cover photos of how the terrace could look furnished and
              a lot of people started clicking on the ad
            </div>
          </div>
          <div className="testimonial-author-container-date">June 7, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, CEO at Gilart Consultors"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                CEO at Gilart Consultors
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Congratulations, because it has improved a lot!!!
          </div>
          <div className="testimonial-author-container-date">June 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Real estate agent
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            With Pedra, I managed to{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              sell a €500k property
            </a>{" "}
            in less than a day. The apartment I had for sale was a TO BE
            RENOVATED apartment and with Pedra, I got renders that allowed
            visualizing the potential of the spaces after renovation but without
            renovation!
          </div>
          <div className="testimonial-author-container-date">June 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MaxVivesFierro}
              alt="Max Vives-Fierro, Real Estate Consultant at Fincas Lluria"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Max Vives-Fierro
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Consultant at Fincas Lluria
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            With Pedra, we managed to start generating interest in a property to
            be renovated that wasn’t receiving calls. We created renders of how
            the property could look renovated and started getting visits!
          </div>
          <div className="testimonial-author-container-date">March 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Sales Department at Inmobiliaria Rico"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Sales Department at Inmobiliaria Rico
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            I am happy with the Pedra application.
          </div>
          <div className="testimonial-author-container-date">
            April 24, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={JordiVives}
              alt="Jordi Vives, CEO of Vives Homes"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Jordi Vives
              </div>
              <div className="testimonial-author-container-second-title">
                CEO of Vives Homes
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            You have thought of everything! Pedra has everything we need!
          </div>
          <div className="testimonial-author-container-date">
            April 15, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Real Estate Advisor at Monapart"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at Monapart
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            I am very satisfied with the results
            <div style={{ marginTop: "10px" }}>
              Moreover, they improve the product based on the feedback I’ve
              given!
            </div>
          </div>
          <div className="testimonial-author-container-date">June 4, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Encuentro Real Estate, Boadilla del Monte"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Encuentro Real Estate, Boadilla del Monte
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">It is a good tool.</div>
          <div className="testimonial-author-container-date">May 6, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={OscarMelendre}
              alt="Oscar Melendre, Marketing at I-VEO"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Oscar Melendre
              </div>
              <div className="testimonial-author-container-second-title">
                Marketing at I-VEO
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            The AI works quite well compared to other AI tools. It interprets
            spaces very well!
          </div>
          <div className="testimonial-author-container-date">
            March 11, 2024
          </div>
        </div>
      </div>

      <div
        className="section-first-button-container"
        style={{ marginTop: "100px" }}
      >
        <button className="button-top-of-page" onClick={redirectToPage}>
          Try Pedra <ArrowIcon />
        </button>
      </div>
    </>
  );
}

export default Testimonials;
