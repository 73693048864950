import React from "react";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function AboutUsDe() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h2 className="article-subtitleh2">Hallo,</h2>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Ich bin Felix Ingla, der Gründer von Pedra.
              </div>
              <div style={{ marginTop: "10px" }}>
                Im Frühjahr 2023 habe ich{" "}
                <Link className="article-link" to="">
                  Pedra
                </Link>
                , ein Online-Tool zur einfachen Transformation Ihrer Immobilien,
                gegründet.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Pedra entsteht aus{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  einer Realität
                </span>{" "}
                der Immobilienbranche.
              </h2>
              <div style={{ marginTop: "10px" }}>
                Um eine Immobilie auf dem Markt zu verkaufen – und sie schnell
                zu verkaufen – benötigen Immobilienmakler gute Fotos, müssen die
                Wohnungen von Unordnung befreien, schöne Möbel in den Räumen
                platzieren und sogar Renovierungsvorschläge zeigen.
              </div>
              <div style={{ marginTop: "10px" }}>
                All das erfordert Zeit und Geld. Aber wir haben nicht immer
                diese Zeit und dieses Geld.
              </div>
              <div style={{ marginTop: "10px" }}>
                Deshalb wurde Pedra geschaffen: eine einfache, schnelle und
                wirtschaftliche Möglichkeit, die Präsentation einer Immobilie
                auf dem Markt zu verbessern, damit wir sie schneller verkaufen
                können.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Unsere Kunden sind{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  unsere Priorität
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                Bei{" "}
                <Link className="article-link" to="">
                  Pedra
                </Link>{" "}
                möchten wir Ihnen die beste Erfahrung bei der Nutzung unseres
                Produkts garantieren. Wenn Sie Feedback haben oder Hilfe
                benötigen, schreiben Sie mir bitte an felix@pedra.so.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Ein Tool{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  leistungsstark und dennoch einfach
                </span>
                .
              </h2>
              <div style={{ marginTop: "10px" }}>
                Mit Pedra bieten wir Immobilienmaklern{" "}
                <Link className="article-link" to="/real-estate-photography">
                  Zugang zu künstlicher Intelligenz zur Verbesserung Ihrer
                  Immobilienfotografie
                </Link>
                ,{" "}
                <Link className="article-link" to="/render">
                  zur Erstellung von Renovierungsvorschlägen
                </Link>
                , zum Aufräumen von Räumen und mehr. Und vor allem bieten wir es
                in einem einfach zu bedienenden Online-Produkt an – wir möchten,
                dass Sie sowohl bei den Kosten als auch bei der Zeit sparen.
              </div>
              <br />
              <br />
              Wir sind stolz darauf, Ihnen Pedra präsentieren zu können.
              <br />
              Und wir hoffen, dass Sie gespannt darauf sind, es zu nutzen.
              <br />
              <br />
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "50%",
                    height: "64px",
                    width: "auto",
                  }}
                  src={FelixIngla}
                  alt="Felix Ingla"
                />
                <div
                  style={{
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "100%",
                  }}
                >
                  <div className="article-text" color="#000 !important">
                    Felix Ingla
                  </div>
                  <div className="article-text" style={{ color: "#878787" }}>
                    Gründer von Pedra
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsDe;
