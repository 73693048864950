import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseNov2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseNov2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseNov24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">NOVEMBRO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Colaboração em equipe e reconhecimento do setor
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Neste novembro, trazemos atualizações para ajudar você a colaborar
              com sua equipe, e uma conquista que temos orgulho de compartilhar:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Convide membros para seu espaço de trabalho
              </h2>
              Agora você pode convidar outros membros para seu espaço de
              trabalho para que diferentes pessoas da sua imobiliária possam
              trabalhar na Pedra. Basta escrever o e-mail deles e convidá-los.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Recurso de convite para espaço de trabalho"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Prêmio de Inovação em IA Imobiliária 2024
              </h2>
              A Associação de Corretores de Imóveis nos concedeu o prêmio de
              inovação em IA para o setor imobiliário. Recebemos 56% dos votos
              de 600 corretores.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Prêmio de inovação"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>
            <div>
              <li>
                Opção para renovar e mobiliar para "Cozinha e sala de jantar" e
                "Cozinha e sala de estar"
              </li>
              <li>Esvaziamento de ambientes mais rápido</li>
              <li>
                Opção de contratar serviço profissional de esvaziamento de
                ambientes por €4 por imagem
              </li>
              <li>Otimização dos tamanhos das imagens baixadas</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseNov24Pt;
