import React from "react";
import Article7Frame from "../Images/Article7Frame.jpg";
import Article7Frame1 from "../Images/Article7Frame1.jpg";
import Article7Frame2 from "../Images/Article7Frame2.jpg";
import Article7Frame3 from "../Images/Article7Frame3.jpg";
import Article7Frame4 from "../Images/Article7Frame4.jpg";
import Article7Frame5 from "../Images/Article7Frame5.jpg";

import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function BlogArticle7De() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Home Staging Beispiele: Vorher und Nachher
            </h1>
            <h2 className="title-secondary gray">
              Suchen Sie nach Home Staging Vorher-Nachher-Beispielen? Um Ihnen
              eine Vorstellung von der Wirkung dieser Technik zu geben, hier
              einige Home Staging Beispiele für Sie.
            </h2>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Gründer von Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Gründer bei Pedra
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={Article7Frame}
                alt="Home Staging Beispiel – Vorher und Nachher – Illustratives Titelbild"
              />
            </div>
            <article className="article-text">
              <br />
              <p style={{ marginTop: "10px" }}>
                Als Nächstes zeigen wir Ihnen Beispiele für Home Staging eines
                Badezimmers, einer Küche, eines Schlafzimmers und eines
                Wohnzimmers. Los geht's!
              </p>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Beispiel: Home Staging einer Küche
              </h2>
              <p style={{ marginTop: "10px" }}>
                Das erste Beispiel ist diese Küche. Wie Sie sehen können, ist
                die Veränderung radikal, aber die gleiche Architektur und
                Möbelanordnung wird beibehalten.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame1}
                  alt="Home Staging Beispiel: Küche Vorher und Nachher"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Wie Sie im Vorher und Nachher des Home Stagings sehen können,
                war das "Vorher" veraltet und überladen. Der neue Look ist viel
                einfacher. Es wurde virtuell durchgeführt und beinhaltet eine
                Bodenänderung, Wandbemalung und Möbelrenovierung. Auch die
                Inhalte außerhalb der Küche wurden gestaltet, um zum Rest des
                Bildes zu passen.
              </p>
              <h2 className="article-subtitleh2">
                Beispiel: Home Staging eines Badezimmers
              </h2>
              <p style={{ marginTop: "10px" }}>
                Das nächste Bild ist ein Beispiel für Home Staging eines
                Badezimmers.
              </p>
              <p style={{ marginTop: "10px" }}>
                Wie Sie sehen können, ist die Veränderung ziemlich radikal. Wir
                gehen von einem veralteten Badezimmer, das dringend renoviert
                werden muss, zu einem modernen Badezimmer über. Die Größe des
                Raums und die Anordnung bleiben gleich.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame5}
                  alt="Home Staging Beispiel: Badezimmer Vorher und Nachher"
                />
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Home Staging eines Schlafzimmers
              </h2>
              <p style={{ marginTop: "10px" }}>
                Das nächste Beispiel für Home Staging ist ein Schlafzimmer. Wie
                Sie sehen können, ist die Veränderung minimal und nicht
                aggressiv. Die Raumgröße wird beibehalten und nur ein Bett und
                eine Pflanze werden hinzugefügt. Auf diese Weise versteht der
                potenzielle Käufer den Zweck dieses Raums besser.
              </p>
              <p style={{ marginTop: "10px" }}>
                Können Sie sich mit dieser Dekoration Ihr Leben in der Immobilie
                vorstellen?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame3}
                  alt="Home Staging Beispiel: Schlafzimmer Vorher und Nachher"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Home Staging eines Wohnzimmers
              </h2>
              <p style={{ marginTop: "10px" }}>
                Das folgende Home Staging Beispiel zeigt die Möglichkeit, ein
                sehr veraltetes Wohnzimmer zu erneuern.
              </p>
              <p style={{ marginTop: "10px" }}>
                Beachten Sie, dass die Möbelanordnung im Foto gleich ist, aber
                absolut alle Möbel wurden erneuert und ein neuer Raum wurde
                hinzugefügt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Dieses Home Staging Beispiel hebt auch das Licht im Raum hervor,
                was ein sehr wichtiger Faktor für Käufer bei der Betrachtung
                einer Immobilie ist.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame2}
                  alt="Home Staging Beispiel: Wohnzimmer Vorher und Nachher"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Die Veränderung des Lichts wird durch die Entfernung der
                Vorhänge und die Änderung der Wandfarbe gerechtfertigt. Diese
                Änderung macht den Raum für den potenziellen Käufer viel
                angenehmer.
              </p>

              <h2 className="article-subtitleh2">
                Beispiel für Home Staging einer Terrasse
              </h2>
              <p style={{ marginTop: "10px" }}>
                Zum Abschluss der Home Staging Beispiele hier ein Beispiel für
                das Staging einer Terrasse. Wie Sie hier sehen können, wird die
                Terrasse viel wohnlicher. Würden Sie nicht gerne auf dieser
                Terrasse frühstücken?
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article7Frame4}
                  alt="Home Staging Beispiel: Terrasse Vorher und Nachher"
                />
              </div>
              <p style={{ marginTop: "10px" }}>
                Es erscheint unglaublich, wie eine Immobilie mit minimaler
                Investition verbessert werden kann. Das Budget kann bei
                physischer Durchführung so gering wie etwas Farbe und viel
                Reinigung sein, oder bei virtueller Durchführung nur wenige
                Klicks und weniger als 50 €.
              </p>
            </article>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Gründer bei Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Hallo! Ich bin Felix Ingla, der Gründer von{" "}
              <Link className="article-link" to="">
                Pedra
              </Link>
              , einer innovativen virtuellen Home-Staging- und
              Immobilienfotografie-Webanwendung, die die Präsentation von
              Immobilien in der digitalen Welt verändert. Mein Engagement für
              die Entwicklung modernster Technologie und intuitiver Lösungen hat
              Pedra zu einem vertrauenswürdigen Werkzeug für Immobilienprofis
              gemacht, die ihre Immobilienangebote verbessern möchten.
            </p>
            <p>
              Wenn Sie sich vernetzen möchten und an Diskussionen über
              Immobilientechnologie interessiert sind, kontaktieren Sie mich
              bitte über{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . Sie können auch mehr{" "}
              <Link className="article-link" to="/about">
                über Pedra hier lesen
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle7De;
