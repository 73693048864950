import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import { Link } from "react-router-dom";
import CustomerLogos from "../CustomerLogos";
import ArrowIcon from "../Icons/ArrowIcon";

const FreeDescriptionGeneratorPt = () => {
  function redirectToPage() {
    if (window.location.href.includes("/es")) {
      window.open("https://app.pedra.so/es/register", "_blank");
    } else if (window.location.href.includes("/fr")) {
      window.open("https://app.pedra.so/fr/register", "_blank");
    } else if (window.location.href.includes("/pt")) {
      window.open("https://app.pedra.so/pt/register", "_blank");
    } else if (window.location.href.includes("/de")) {
      window.open("https://app.pedra.so/de/register", "_blank");
    } else {
      window.open("https://app.pedra.so/register", "_blank");
    }
  }

  const [formData, setFormData] = useState({
    propertyType: "",
    condition: "",
    numberOfBedrooms: "",
    location: "",
    locationVibe: "",
    area: "",
    otherWarnings: "",
    highlightedFeatures: "",
  });
  const [generatedOutput, setGeneratedOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "propertyType",
      "condition",
      "numberOfBedrooms",
      "area",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        errors[field] = "Este campo é obrigatório";
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generateListing = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setGeneratedOutput("");

    try {
      const response = await fetch(
        "/.netlify/functions/generate-ad-description-pt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Falha ao gerar anúncio");
      }

      setGeneratedOutput(data.output);
    } catch (err) {
      console.error("Error in generateListing:", err);
      setError(
        "Estamos com alto volume de tráfego – por favor, tente novamente em alguns minutos"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderField = (
    name,
    label,
    type = "text",
    rows = 1,
    placeholder = ""
  ) => (
    <div>
      <label htmlFor={name} className="free-tool-label">
        {label}:
      </label>
      {type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          rows={rows}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px", height: "100px" }}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px" }}
          placeholder={placeholder}
        />
      )}
      {fieldErrors[name] && (
        <div style={{ color: "red", fontSize: "0.8em" }}>
          {fieldErrors[name]}
        </div>
      )}
    </div>
  );

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            <span className="text-highlight">Grátis</span> Gerador de Anúncios
            Imobiliários
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% grátis</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% com IA</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Anúncios em 10 segundos</span>
            </p>
          </div>
        </div>
        <h2>Insira os detalhes do imóvel</h2>
        <form
          onSubmit={generateListing}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {renderField(
            "propertyType",
            "Tipo de Imóvel",
            "text",
            1,
            "Apartamento, Casa, Vila"
          )}
          {renderField(
            "condition",
            "Condição",
            "text",
            1,
            "Novo, Reformado, Precisa de reforma"
          )}
          {renderField(
            "numberOfBedrooms",
            "Número de Quartos",
            "text",
            1,
            "2, 3, 4+"
          )}
          {renderField(
            "location",
            "Localização (opcional)",
            "text",
            1,
            "Centro, Bairro Jardins"
          )}
          {renderField(
            "locationVibe",
            "Ambiente do Bairro (opcional)",
            "text",
            1,
            "Tranquilo, Movimentado, Familiar"
          )}
          {renderField("area", "Área", "text", 1, "80m², 100m², 150m²")}
          {renderField(
            "highlightedFeatures",
            "Características Destacadas",
            "textarea",
            3,
            "Terraço espaçoso, Cozinha equipada, Vista panorâmica"
          )}
          {renderField(
            "otherWarnings",
            "Outros Avisos (opcional)",
            "textarea",
            3,
            "Necessita pequenos reparos, Não permite animais"
          )}

          <button
            type="submit"
            disabled={isLoading}
            style={{ cursor: "pointer" }}
            className="free-tool-button"
          >
            {isLoading ? "Gerando..." : "Gerar Anúncio"}
          </button>
        </form>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>
            <strong>Erro:</strong> {error}
          </div>
        )}

        {generatedOutput && (
          <div className="step-container article">
            <article className="article-text free-tool-result-container">
              <div style={{ marginTop: "20px" }}>
                <h2 className="article-subtitleh2">
                  <span className="text-highlight">Anúncio Gerado:</span>
                </h2>
                <p>{generatedOutput}</p>
              </div>
            </article>
          </div>
        )}

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              O que é um gerador de anúncios imobiliários?
            </h2>
            <p>
              Um gerador de anúncios imobiliários é uma ferramenta que ajuda
              corretores e proprietários a criar descrições atraentes e
              profissionais para seus imóveis à venda ou aluguel. Esta
              ferramenta usa algoritmos avançados e inteligência artificial para
              gerar descrições persuasivas e detalhadas com base nas informações
              fornecidas sobre o imóvel.
            </p>

            <h2 className="article-subtitleh2">
              Como criar anúncios imobiliários
            </h2>
            <p>
              Se você deseja obter as descrições de imóveis mais eficazes e
              atraentes, use a Pedra. Nosso gerador de descrições usa um método
              único que combina inteligência artificial avançada com
              conhecimento do mercado imobiliário local.
            </p>
            <p>
              O procedimento para criar um anúncio imobiliário gratuitamente é o
              seguinte:
            </p>

            <h2 className="article-subtitleh2">
              Preencha os Detalhes Básicos do Imóvel
            </h2>
            <p>
              Tipo de Imóvel: Selecione o tipo de imóvel que está promovendo,
              como apartamento, casa ou vila. Isso ajuda a categorizar
              corretamente o anúncio e estabelecer as expectativas dos
              compradores desde o início.
            </p>
            <p>
              Condição do Imóvel: Indique se o imóvel é novo, reformado ou
              precisa de reforma. Esta informação é crucial pois estabelece o
              estado atual do imóvel e pode influenciar as decisões dos
              compradores.
            </p>

            <h2 className="article-subtitleh2">
              Especifique as Características Principais
            </h2>
            <p>
              Número de Quartos: Informe quantos quartos o imóvel possui. Este é
              um dos detalhes mais procurados pelos compradores e deve ser claro
              e preciso.
            </p>
            <p>
              Área: Forneça a área total do imóvel em metros quadrados.
              Certifique-se de ser preciso, pois isso ajuda os interessados a
              comparar facilmente diferentes imóveis.
            </p>

            <h2 className="article-subtitleh2">
              Adicione Informações Opcionais para Enriquecer o Anúncio
            </h2>
            <p>
              Localização: Embora opcional, adicionar detalhes sobre a
              localização, como "Centro" ou "Bairro Jardins", pode tornar seu
              anúncio mais relevante para quem procura em áreas específicas.
            </p>
            <p>
              Ambiente do Bairro: Descreva a atmosfera do bairro, por exemplo,
              "Tranquilo", "Movimentado" ou "Familiar". Isso ajuda os
              interessados a imaginar o estilo de vida que poderiam ter naquele
              imóvel.
            </p>

            <h2 className="article-subtitleh2">
              Destaque Pontos-Chave do Imóvel
            </h2>
            <p>
              Características Destacadas: Use este campo para ressaltar
              características especiais como "Terraço espaçoso", "Cozinha
              equipada" ou "Vista panorâmica". Estes detalhes podem ser
              decisivos para captar a atenção e diferenciar seu anúncio de
              outros similares.
            </p>

            <h2 className="article-subtitleh2">Inclua Avisos ou Restrições</h2>
            <p>
              Outros Avisos (opcional): Adicione quaisquer restrições
              importantes, como "Necessita pequenos reparos" ou "Não permite
              animais". Ser transparente com essas condições evitará
              mal-entendidos e atrairá interessados que estejam dispostos a
              aceitar essas condições.
            </p>

            <h2 className="article-subtitleh2">
              Vantagens do gerador de anúncios imobiliários
            </h2>
            <h3>Eficiência de tempo</h3>
            <p>
              Criar um anúncio imobiliário do zero pode ser um processo longo e
              detalhado, especialmente se você quer garantir que seja persuasivo
              e profissional. Com o gerador de anúncios, você só precisa inserir
              as informações principais sobre o imóvel e, em questão de
              segundos, terá uma descrição completa e bem estruturada. Isso
              permite que você economize um tempo valioso que pode ser dedicado
              a outras tarefas importantes, como atendimento ao cliente ou
              gestão de mais imóveis.
            </p>

            <h3>Qualidade profissional</h3>
            <p>
              Uma descrição mal escrita ou pouco atraente pode desestimular
              potenciais compradores ou locatários logo de início. O gerador
              utiliza linguagem profissional e convincente, garantindo que cada
              anúncio destaque os melhores aspectos do imóvel e capture a
              atenção do leitor. Além disso, a escrita se adapta às melhores
              práticas do mercado imobiliário, o que aumenta as chances de
              atrair interessados.
            </p>

            <h3>Facilidade de uso</h3>
            <p>
              O gerador de anúncios é projetado para ser intuitivo e fácil de
              usar, mesmo para quem não tem experiência prévia em redação ou
              marketing imobiliário. Você só precisa preencher alguns campos com
              informações básicas sobre o imóvel, como tipo, localização e
              características destacadas, e a ferramenta cuida do resto. Essa
              simplicidade permite que qualquer pessoa crie anúncios eficazes
              sem complicações.
            </p>

            <h3>Gratuito</h3>
            <p>
              Diferentemente de outras ferramentas de redação ou serviços que
              podem ter um custo associado, este gerador de anúncios é
              totalmente gratuito. Isso permite que usuários, sejam corretores
              independentes, pequenas imobiliárias ou grandes empresas do setor,
              tenham acesso a descrições de alta qualidade sem incorrer em
              custos adicionais.
            </p>

            <h2 className="article-subtitleh2">
              Melhores casos de uso do gerador de descrições
            </h2>
            <h3>Para corretores independentes</h3>
            <p>
              Corretores independentes frequentemente lidam com múltiplos
              imóveis e têm a responsabilidade de gerenciar todo o processo de
              venda ou aluguel, desde a captação do imóvel até a criação de
              anúncios atrativos. O gerador de descrições permite que eles
              economizem tempo e esforço na redação dos anúncios, garantindo que
              cada descrição seja profissional e persuasiva. Isso permite que se
              concentrem em outras tarefas críticas, como negociação com
              clientes ou organização de visitas, aumentando sua produtividade e
              eficácia no fechamento de negócios.
            </p>

            <h3>Para proprietários vendendo por conta própria</h3>
            <p>
              Proprietários que optam por vender ou alugar seus imóveis por
              conta própria (sem intermediários) frequentemente não têm a
              experiência em marketing imobiliário necessária para escrever
              descrições atraentes e eficazes. O gerador de descrições fornece a
              eles uma solução simples e gratuita para criar anúncios que
              destaquem os melhores aspectos de seu imóvel. Isso os ajuda a
              captar a atenção de potenciais compradores ou locatários,
              aumentando as chances de sucesso na venda ou locação, sem a
              necessidade de contratar um corretor ou redator profissional.
            </p>

            <h3>Para imobiliárias</h3>
            <p>
              Imobiliárias lidam com um grande volume de imóveis e precisam
              manter consistência no tom e na qualidade dos anúncios que
              publicam. O gerador de descrições permite que padronizem a criação
              de conteúdo, garantindo que todos os imóveis sejam apresentados de
              forma consistente e profissional. Além disso, reduz o tempo e os
              custos associados à redação manual, permitindo que as equipes de
              marketing se concentrem em estratégias mais amplas de divulgação.
              Ao oferecer descrições de alta qualidade de forma eficiente, as
              imobiliárias podem melhorar sua imagem de marca e atrair mais
              clientes.
            </p>

            <h2 className="article-subtitleh2">
              Para quem é destinado o gerador de anúncios imobiliários?
            </h2>
            <p>
              Embora qualquer pessoa possa usar o gerador de descrições da
              Pedra, estes são os usuários mais comuns:
            </p>
            <ul>
              <li>Corretores de imóveis</li>
              <li>Proprietários de imóveis</li>
              <li>Investidores imobiliários</li>
            </ul>
            <p>
              Descubra como nossa ferramenta pode transformar seus anúncios
              imobiliários hoje.
            </p>

            <h2 className="article-subtitleh2">
              Melhore a apresentação visual do seu anúncio imobiliário
            </h2>
            <p>
              Além de uma descrição convincente, a apresentação visual do seu
              imóvel é crucial para atrair potenciais compradores. Na Pedra,
              entendemos a importância de mostrar todo o potencial de cada
              imóvel. Por isso, oferecemos serviços adicionais para melhorar a
              apresentação dos seus anúncios:
            </p>
            <ul>
              <li>
                Fotografia imobiliária profissional: Nosso software irá melhorar
                suas imagens em 1 clique, para que fiquem com melhor resolução,
                luz e cor.
              </li>
              <li>
                Home staging virtual: Utilizamos tecnologia avançada para
                mostrar como o imóvel ficaria com diferentes estilos de
                decoração, ajudando os compradores a visualizar seu potencial.
              </li>
            </ul>
            <p>
              Estes serviços complementares, junto com nossas descrições geradas
              por IA, ajudarão você a criar anúncios imobiliários que se
              destacam no mercado competitivo atual. Entre em contato para
              descobrir como podemos ajudar você a apresentar seu imóvel da
              melhor forma possível.
            </p>

            <h2 className="article-subtitleh2">
              Crie seu anúncio pronto para divulgar em portais imobiliários
            </h2>
            <p>
              Com nosso gerador de descrições gratuito e a ferramenta de{" "}
              <Link to="/home-staging-virtual" className="article-link">
                home staging virtual
              </Link>{" "}
              para otimizar suas fotos imobiliárias, você poderá gerenciar todas
              as informações do seu imóvel de forma rápida e fácil. Essas
              ferramentas permitirão que você automatize tarefas complexas,
              reduzindo significativamente as horas de trabalho e melhorando a
              qualidade dos resultados, sem precisar dedicar muito tempo e
              esforço.
            </p>
            <p>
              Depois que você criar a descrição e otimizar suas fotos com a
              ajuda da Pedra, elas estarão prontas para serem publicadas em
              diferentes portais de anúncios para atrair futuros inquilinos.
            </p>
          </article>
        </div>
        <br />
        <div className="customer-logo-container">
          MAIS DE 5.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
        <div className="section-first-button-container">
          <button className="button-top-of-page" onClick={redirectToPage}>
            Experimente a Pedra <ArrowIcon />
          </button>
        </div>
      </section>
    </section>
  );
};

export default FreeDescriptionGeneratorPt;
