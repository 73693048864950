import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import { Link } from "react-router-dom";
import CustomerLogos from "../CustomerLogos";
import ArrowIcon from "../Icons/ArrowIcon";

const FreeDescriptionGeneratorFr = () => {
  function redirectToPage() {
    if (window.location.href.includes("/es")) {
      window.open("https://app.pedra.so/es/register", "_blank");
    } else if (window.location.href.includes("/fr")) {
      window.open("https://app.pedra.so/fr/register", "_blank");
    } else if (window.location.href.includes("/pt")) {
      window.open("https://app.pedra.so/pt/register", "_blank");
    } else if (window.location.href.includes("/de")) {
      window.open("https://app.pedra.so/de/register", "_blank");
    } else {
      window.open("https://app.pedra.so/register", "_blank");
    }
  }

  const [formData, setFormData] = useState({
    propertyType: "",
    condition: "",
    numberOfBedrooms: "",
    location: "",
    locationVibe: "",
    area: "",
    otherWarnings: "",
    highlightedFeatures: "",
  });
  const [generatedOutput, setGeneratedOutput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "propertyType",
      "condition",
      "numberOfBedrooms",
      "area",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        errors[field] = "Ce champ est obligatoire";
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generateListing = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setGeneratedOutput("");

    try {
      const response = await fetch(
        "/.netlify/functions/generate-ad-description-fr",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Échec de la génération de l'annonce");
      }

      setGeneratedOutput(data.output);
    } catch (err) {
      console.error("Erreur dans generateListing:", err);
      setError(
        "Nous connaissons un volume de trafic élevé - veuillez réessayer dans quelques minutes"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderField = (
    name,
    label,
    type = "text",
    rows = 1,
    placeholder = ""
  ) => (
    <div>
      <label htmlFor={name} className="free-tool-label">
        {label} :
      </label>
      {type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          rows={rows}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px", height: "100px" }}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          className="free-tool-input"
          style={{ width: "100%", marginTop: "5px" }}
          placeholder={placeholder}
        />
      )}
      {fieldErrors[name] && (
        <div style={{ color: "red", fontSize: "0.8em" }}>
          {fieldErrors[name]}
        </div>
      )}
    </div>
  );

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            Générateur d'Annonces Immobilières{" "}
            <span className="text-highlight">Gratuit</span>
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% gratuit</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% propulsé par l'IA</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Annonces en 10 secondes</span>
            </p>
          </div>
        </div>
        <h2>Entrez les détails de la propriété</h2>
        <form
          onSubmit={generateListing}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {renderField(
            "propertyType",
            "Type de Propriété",
            "text",
            1,
            "Appartement, Maison, Villa"
          )}
          {renderField(
            "condition",
            "État",
            "text",
            1,
            "Neuf, Rénové, À rénover"
          )}
          {renderField(
            "numberOfBedrooms",
            "Nombre de Chambres",
            "text",
            1,
            "2, 3, 4+"
          )}
          {renderField(
            "location",
            "Emplacement (optionnel)",
            "text",
            1,
            "Centre-ville, Quartier des affaires"
          )}
          {renderField(
            "locationVibe",
            "Ambiance du Quartier (optionnel)",
            "text",
            1,
            "Calme, Animé, Familial"
          )}
          {renderField("area", "Superficie", "text", 1, "80m², 100m², 150m²")}
          {renderField(
            "highlightedFeatures",
            "Points Forts",
            "textarea",
            3,
            "Grande terrasse, Cuisine équipée, Vue panoramique"
          )}
          {renderField(
            "otherWarnings",
            "Autres Avertissements (optionnel)",
            "textarea",
            3,
            "Nécessite des réparations mineures, Animaux non autorisés"
          )}

          <button
            type="submit"
            disabled={isLoading}
            style={{ cursor: "pointer" }}
            className="free-tool-button"
          >
            {isLoading ? "Génération en cours..." : "Générer l'Annonce"}
          </button>
        </form>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>
            <strong>Erreur :</strong> {error}
          </div>
        )}

        {generatedOutput && (
          <div className="step-container article">
            <article className="article-text free-tool-result-container">
              <div style={{ marginTop: "20px" }}>
                <h2 className="article-subtitleh2">
                  <span className="text-highlight">Annonce Générée :</span>
                </h2>
                <p>{generatedOutput}</p>
              </div>
            </article>
          </div>
        )}
        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              Qu'est-ce qu'un générateur d'annonces immobilières ?
            </h2>
            <p>
              Un générateur d'annonces immobilières est un outil qui aide les
              agents immobiliers et les propriétaires à créer des descriptions
              attrayantes et professionnelles pour leurs biens à vendre ou à
              louer. Cet outil utilise des algorithmes avancés et l'intelligence
              artificielle pour générer des descriptions persuasives et
              détaillées basées sur les informations fournies sur le bien.
            </p>

            <h2 className="article-subtitleh2">
              Comment créer des annonces immobilières
            </h2>
            <p>
              Si vous voulez obtenir les descriptions de biens les plus
              efficaces et attrayantes, utilisez Pedra. Notre générateur de
              descriptions utilise une méthode unique qui combine l'intelligence
              artificielle avancée avec la connaissance du marché immobilier
              local.
            </p>
            <p>
              La procédure pour créer une annonce immobilière gratuitement est
              la suivante :
            </p>

            <h2 className="article-subtitleh2">
              Complétez les détails de base du bien
            </h2>
            <p>
              Type de bien : Sélectionnez le type de bien que vous promouvez,
              comme un appartement, une maison ou une villa. Cela aide à
              catégoriser correctement l'annonce et à définir les attentes de
              l'acheteur dès le début.
            </p>
            <p>
              État du bien : Indiquez si le bien est neuf, rénové ou nécessite
              une rénovation. Cette information est cruciale car elle établit
              l'état actuel du bien et peut influencer les décisions des
              acheteurs.
            </p>

            <h2 className="article-subtitleh2">
              Spécifiez les caractéristiques principales
            </h2>
            <p>
              Nombre de chambres : Entrez le nombre de chambres que possède le
              bien. C'est l'un des détails les plus recherchés par les acheteurs
              et doit être clair et précis.
            </p>
            <p>
              Superficie : Indiquez la surface totale du bien en mètres carrés.
              Assurez-vous d'être précis, car cela aide les parties intéressées
              à comparer facilement différents biens.
            </p>

            <h2 className="article-subtitleh2">
              Ajoutez des informations facultatives pour enrichir l'annonce
            </h2>
            <p>
              Emplacement : Bien que facultatif, l'ajout de détails sur
              l'emplacement, comme "Centre-ville" ou "Quartier des affaires",
              peut rendre votre annonce plus pertinente pour ceux qui
              recherchent dans des zones spécifiques.
            </p>
            <p>
              Ambiance du quartier : Décrivez l'atmosphère du quartier, par
              exemple, "Calme", "Animé" ou "Familial". Cela aide les parties
              intéressées à imaginer le style de vie qu'elles pourraient avoir
              dans ce bien.
            </p>

            <h2 className="article-subtitleh2">
              Mettez en avant les points clés du bien
            </h2>
            <p>
              Points forts : Utilisez ce champ pour mettre en évidence des
              caractéristiques spéciales telles que "Grande terrasse", "Cuisine
              équipée" ou "Vue panoramique". Ces détails peuvent être décisifs
              pour capter l'attention et différencier votre annonce des autres
              similaires.
            </p>

            <h2 className="article-subtitleh2">
              Incluez des avertissements ou des restrictions
            </h2>
            <p>
              Autres avertissements (facultatif) : Ajoutez toute restriction
              importante, comme "Nécessite des réparations mineures" ou "Animaux
              non autorisés". Être transparent sur ces conditions évitera les
              malentendus et attirera des parties intéressées qui sont prêtes à
              accepter ces conditions.
            </p>

            <h2 className="article-subtitleh2">
              Avantages du générateur d'annonces immobilières
            </h2>
            <h3>Efficacité en termes de temps</h3>
            <p>
              Créer une annonce immobilière à partir de zéro peut être un
              processus long et détaillé, surtout si vous voulez vous assurer
              qu'elle soit persuasive et professionnelle. Avec le générateur
              d'annonces, vous n'avez qu'à saisir les informations clés sur le
              bien, et en quelques secondes, vous obtiendrez une description
              complète et bien structurée. Cela vous permet de gagner un temps
              précieux que vous pouvez consacrer à d'autres tâches importantes,
              comme le service client ou la gestion de plus de biens.
            </p>

            <h3>Qualité professionnelle</h3>
            <p>
              Une description mal rédigée ou peu attrayante peut décourager les
              acheteurs ou locataires potentiels dès le premier instant. Le
              générateur utilise un langage professionnel et convaincant,
              s'assurant que chaque annonce mette en valeur les meilleures
              caractéristiques du bien et capte l'attention du lecteur. De plus,
              la rédaction s'adapte aux meilleures pratiques du marché
              immobilier, ce qui augmente la probabilité d'attirer des parties
              intéressées.
            </p>

            <h3>Facilité d'utilisation</h3>
            <p>
              Le générateur d'annonces est conçu pour être intuitif et facile à
              utiliser, même pour ceux qui n'ont pas d'expérience préalable en
              rédaction de contenu ou en marketing immobilier. Vous n'avez qu'à
              remplir quelques champs avec les informations de base sur le bien,
              comme le type, l'emplacement et les caractéristiques mises en
              avant, et l'outil s'occupe du reste. Cette simplicité permet à
              quiconque de créer des annonces efficaces sans complications.
            </p>

            <h3>Gratuit</h3>
            <p>
              Contrairement à d'autres outils ou services de rédaction qui
              peuvent avoir un coût associé, ce générateur d'annonces est
              complètement gratuit. Cela permet aux utilisateurs, qu'ils soient
              agents indépendants, petites agences ou grandes sociétés
              immobilières, d'accéder à des descriptions de haute qualité sans
              encourir de coûts supplémentaires.
            </p>

            <h2 className="article-subtitleh2">
              Meilleurs cas d'utilisation du générateur de descriptions
            </h2>
            <h3>Pour les agents immobiliers indépendants</h3>
            <p>
              Les agents immobiliers indépendants gèrent souvent plusieurs biens
              et ont la responsabilité de gérer l'ensemble du processus de vente
              ou de location, de l'acquisition du bien à la création d'annonces
              attrayantes. Le générateur de descriptions leur permet
              d'économiser du temps et des efforts dans la rédaction d'annonces,
              en s'assurant que chaque description soit professionnelle et
              persuasive. Cela leur permet de se concentrer sur d'autres tâches
              critiques, comme la négociation avec les clients ou l'organisation
              de visites, augmentant ainsi leur productivité et leur efficacité
              dans la conclusion de transactions.
            </p>

            <h3>Pour les propriétaires qui vendent par eux-mêmes</h3>
            <p>
              Les propriétaires qui choisissent de vendre ou de louer leurs
              biens par eux-mêmes (sans intermédiaires) manquent souvent de
              l'expérience en marketing immobilier nécessaire pour rédiger des
              descriptions attrayantes et efficaces. Le générateur de
              descriptions leur fournit une solution simple et gratuite pour
              créer des annonces qui mettent en valeur les meilleures
              caractéristiques de leur bien. Cela les aide à capter l'attention
              des acheteurs ou locataires potentiels, augmentant les chances de
              réussite dans la vente ou la location, sans avoir besoin d'engager
              un agent ou un rédacteur professionnel.
            </p>

            <h3>Pour les agences immobilières</h3>
            <p>
              Les agences immobilières gèrent un grand volume de biens et
              doivent maintenir une cohérence dans le ton et la qualité des
              annonces qu'elles publient. Le générateur de descriptions leur
              permet de standardiser la création de contenu, en s'assurant que
              tous les biens sont présentés de manière cohérente et
              professionnelle. De plus, il réduit le temps et les coûts associés
              à la rédaction manuelle, permettant aux équipes de marketing de se
              concentrer sur des stratégies de promotion plus larges. En offrant
              des descriptions de haute qualité de manière efficace, les agences
              peuvent améliorer leur image de marque et attirer plus de clients.
            </p>

            <h2 className="article-subtitleh2">
              Pour qui est conçu le générateur d'annonces immobilières ?
            </h2>
            <p>
              Bien que n'importe qui puisse utiliser le générateur de
              descriptions de Pedra, voici les utilisateurs les plus courants :
            </p>
            <ul>
              <li>Agents immobiliers</li>
              <li>Propriétaires de biens</li>
              <li>Investisseurs immobiliers</li>
            </ul>
            <p>
              Découvrez comment notre outil peut transformer vos annonces
              immobilières dès aujourd'hui.
            </p>

            <h2 className="article-subtitleh2">
              Améliorez la présentation visuelle de votre annonce immobilière
            </h2>
            <p>
              En plus d'une description convaincante, la présentation visuelle
              de votre bien est cruciale pour attirer les acheteurs potentiels.
              Chez Pedra, nous comprenons l'importance de montrer le plein
              potentiel de chaque bien. C'est pourquoi nous proposons des
              services supplémentaires pour améliorer la présentation de vos
              annonces :
            </p>
            <ul>
              <li>
                Photographie immobilière professionnelle : Notre logiciel
                améliorera vos images en 1 clic, pour qu'elles apparaissent avec
                une meilleure résolution, luminosité et couleur.
              </li>
              <li>
                Home staging virtuel : Nous utilisons une technologie avancée
                pour montrer à quoi ressemblerait le bien avec différents styles
                de décoration, aidant les acheteurs à visualiser son potentiel.
              </li>
            </ul>
            <p>
              Ces services complémentaires, associés à nos descriptions générées
              par IA, vous aideront à créer des annonces immobilières qui se
              démarquent sur le marché concurrentiel actuel. Contactez-nous pour
              savoir comment nous pouvons vous aider à présenter votre bien de
              la meilleure façon possible.
            </p>

            <h2 className="article-subtitleh2">
              Créez votre annonce prête à être publiée sur les portails
              immobiliers
            </h2>
            <p>
              Avec notre générateur de descriptions gratuit et l'outil de{" "}
              <Link to="/fr/home-staging-virtuel" className="article-link">
                home staging virtuel
              </Link>{" "}
              pour optimiser vos photos immobilières, vous pourrez gérer toutes
              les informations de vos biens rapidement et facilement. Ces outils
              vous permettront d'automatiser des tâches complexes, réduisant
              considérablement les heures de travail et améliorant la qualité
              des résultats, sans avoir besoin de consacrer trop de temps et
              d'efforts.
            </p>
            <p>
              Une fois que vous aurez créé la description et optimisé vos photos
              avec l'aide de Pedra, elles seront prêtes à être publiées sur
              différents portails immobiliers pour attirer les futurs locataires
              ou acheteurs.
            </p>
          </article>
        </div>
        <br />
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
        <div className="section-first-button-container">
          <button className="button-top-of-page" onClick={redirectToPage}>
            Essayez Pedra <ArrowIcon />
          </button>
        </div>
      </section>
    </section>
  );
};

export default FreeDescriptionGeneratorFr;
