import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageBathroomFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeBathroom.png";
import Example4After from "../Images/curtain-effect-hero/AfterBathroom.png";
import Example1Before from "../Images/curtain-effect/BeforeBathroomStaging1.png";
import Example1After from "../Images/curtain-effect/AfterBathroomStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeBathroomStaging2.png";
import Example2After from "../Images/curtain-effect/AfterBathroomStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeBathroomStaging3.png";
import Example3After from "../Images/curtain-effect/AfterBathroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingBathroomDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie virtuelles Home Staging für Badezimmer
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung von virtuellem Home Staging für Badezimmer
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich virtuelles Home Staging für ein Badezimmer erstellen?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie virtuelles Home Staging für Badezimmer mit
              nur wenigen Klicks erstellen.
              <p>
                Öffnen Sie zunächst das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>. Wählen
                Sie "Badezimmer" als Raumtyp aus, und in etwa 2 bis 3 Minuten
                haben Sie Ihr virtuelles Home Staging-Bild des Badezimmers zum
                Download bereit.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="So erstellen Sie virtuelles Home Staging für ein Badezimmer – Beispiele"
            />
            <br />
            <h2 className="article-subtitleh2">
              Wie erstellt man virtuelles Home Staging für Badezimmer?
            </h2>
            <p className="article-text">
              Um Ihr{" "}
              <Link className="article-link" to="/home-staging-bathroom">
                virtuelles Home Staging für ein Badezimmer
              </Link>{" "}
              zu erstellen, folgen Sie diesen einfachen Schritten:
            </p>
            <ol>
              <li>
                Laden Sie das Bild des Badezimmers hoch, das Sie virtuell
                einrichten möchten
              </li>
              <li>
                Wählen Sie das Bild aus, um mit der Bearbeitung zu beginnen
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menü zur Erstellung von virtuellem Home Staging für ein Badezimmer"
            />
            <p className="article-text">
              Innerhalb von{" "}
              <span style={{ fontWeight: "500" }}>"Möblieren"</span> müssen Sie
              diese Schritte befolgen:
            </p>
            <ol>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Position der Einrichtungselemente
                </span>
                . Für Badezimmer empfehlen wir "Zeichnen", um spezifische
                Bereiche für Armaturen, Dekoration und Accessoires anzugeben.
              </li>
              <li>
                Wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Badezimmer"</span> als
                Raumtyp. Dies stellt sicher, dass Pedra passende
                Badezimmerartikel wie Toilettenartikel, Handtücher und
                Dekorationselemente platziert.
              </li>
              <li>
                Wählen Sie den <span style={{ fontWeight: "500" }}>Stil</span>.
                Für Badezimmer funktionieren Stile wie "Modern", "Spa" oder
                "Zeitgenössisch" normalerweise gut.
              </li>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Anzahl der zu generierenden Bilder
                </span>
                . Wir empfehlen 2 oder 3 Bilder, um verschiedene
                Badezimmerdesign-Optionen zu haben.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für besseres virtuelles Home Staging eines Badezimmers:
                <ul>
                  <li>
                    Stellen Sie sicher, dass die Fotografie das gesamte
                    Badezimmerlayout deutlich zeigt, für eine genauere
                    Möblierung
                  </li>
                  <li>
                    Erfassen Sie besondere Merkmale wie Waschtische,
                    Duschbereiche oder architektonische Details
                  </li>
                  <li>
                    Nutzen Sie die Option "Zeichnen", um spezifische Bereiche
                    für Armaturen, Waschtisch-Accessoires oder dekorative
                    Elemente anzugeben
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Beispiel für die Erstellung von virtuellem Home Staging für ein Badezimmer"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Warum virtuelles Home Staging für ein Badezimmer erstellen?
            </h2>
            <p className="article-text">
              Das Badezimmer ist einer der wichtigsten Räume in einem Haus und
              kann ein wichtiges Verkaufsargument sein. Virtuelles Home Staging
              für Badezimmer ermöglicht es Ihnen, das volle Potenzial des Raums
              zu zeigen und spa-ähnliche und funktionale Umgebungen zu schaffen,
              die den Käufern helfen, sich die Nutzung und den Genuss des Raums
              vorzustellen.
            </p>
            <p className="article-text">
              Mit gutem virtuellem Home Staging für ein Badezimmer können Sie:
            </p>
            <ul>
              <li>
                Einfache oder veraltete Badezimmer in luxuriöse Räume verwandeln
              </li>
              <li>Die Funktionalität und das Layout des Raums hervorheben</li>
              <li>
                Aufbewahrungslösungen und Ablageflächen-Potenzial präsentieren
              </li>
              <li>
                Käufern helfen, sich einen entspannenden und erfrischenden
                persönlichen Raum vorzustellen
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Beispiele für virtuelles Home Staging von Badezimmern
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für virtuelles Home Staging, das auf
              Badezimmer angewendet werden kann:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingBathroomDe;
