import React from "react";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import OscarMelendre from "./Images/Oscar Melendre image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import MaxVivesFierro from "./Images/Max Vives-Fierro image.png";
import JordiVives from "./Images/Jordi Vives image.png";
import ArrowIcon from "./Icons/ArrowIcon";
import { Link } from "react-router-dom";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function TestimonialsEs() {
  return (
    <>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <div className="header-stars">
              ★★★★★
              <div className="header-stars-text">
                {" "}
                "El descubrimiento del siglo"
              </div>
            </div>
            <h1 className="section-first-title">Testimonios sobre Pedra</h1>
            <div
              className="section-first-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 className="section-first-text-bold">
                Inspírate con los comentarios y reseñas de los clientes de
                Pedra.
              </h2>
              <h2 className="section-first-text-gray">
                Opiniones sobre las funcionalidades de{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
                l y mejoras de fotografía.
              </h2>
            </div>
          </div>
        </section>
      </section>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Director de Fotografía en aProperties"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Director de Fotografía en aProperties
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra está yendo estupendamente. Creo que lo mejor que tiene la
            herramienta es la rapidez con que genera las imágenes.
          </div>
          <div className="testimonial-author-container-date">3 July, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Agente inmobiliario en Huspy"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Agente inmobiliario en Huspy
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            🌈 🙌🏾 He vuelto{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              a hacer magia con Pedra
            </a>
            … Ahora toca espera!
          </div>
          <div className="testimonial-author-container-date">
            1 de Julio, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Asesor inmobiliario en iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Asesor inmobiliario en iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra es el descubrimiento del siglo
          </div>
          <div className="testimonial-author-container-date">3 July, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, CEO en Actívox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                CEO en Actívox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Pedra nos está siendo útil. Estamos muy contentos!!!!
            <div style={{ marginTop: "10px" }}>Debería de ser más caro.</div>
          </div>
          <div className="testimonial-author-container-date">28 May, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Agente Inmobiliario en iad"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Agente Inmobiliario en iad
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Impresionante.
            <div style={{ marginTop: "10px" }}>
              Además que el espacio en concreto que quería vaciar me parecía más
              complicado por ser profundo, pero lo ha hecho genial.
            </div>
          </div>
          <div className="testimonial-author-container-date">
            26 Junio, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager en Lucas Fox"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager en Lucas Fox
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Un par de casas se han vendido gracias a Pedra.
            <div style={{ marginTop: "10px" }}>
              Coloqué en foto de portada propuestas de como podría quedar la
              terraza amueblada y muchísima gente empezo a hacer click en el
              anuncio
            </div>
          </div>
          <div className="testimonial-author-container-date">7 Junio, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, CEO en Gilart Consultors"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                CEO en Gilart Consultors
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Os felicito, porque ha mejorado mucho¡¡¡
          </div>
          <div className="testimonial-author-container-date">2 Junio, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Agente inmobiliario
              </div>
              <div className="testimonial-author-container-second-title">
                Agente inmobiliaro
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Con Pedra conseguí{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              vender un inmueble de €500k
            </a>{" "}
            en menos de un día. El piso que tenía a la venta era un piso A
            REFORMAR y con Pedra conseguí unos renders que permitieron
            visualizar el potencial de las espacios después de reforma pero sin
            reforma!
          </div>
          <div className="testimonial-author-container-date">2 Junio, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MaxVivesFierro}
              alt="Max Vives-Fierro, Consultor inmobiliario en Fincas Lluria"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Max Vives-Fierro
              </div>
              <div className="testimonial-author-container-second-title">
                Consultor inmobiliario en Fincas Lluria
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Con Pedra conseguimos empezar a despertar el interés en un piso a
            reformar que no estaba recibiendo llamadas. Generamos unos renders
            de cómo podría quedar el piso reformado y empezamos a tener visitas.
          </div>
          <div className="testimonial-author-container-date">2 Marzo, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Dpt. de ventas en Inmobiliaria Rico"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Dpt. de ventas en Inmobiliaria Rico
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Estoy contenta con la aplicación de Pedra.
          </div>
          <div className="testimonial-author-container-date">
            24 Abril, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={JordiVives}
              alt="Jordi Vives, CEO de Vives Homes"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Jordi Vives
              </div>
              <div className="testimonial-author-container-second-title">
                CEO de Vives Homes
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Habéis pensado en todo! Pedra tiene todo lo que necesitamos!
          </div>
          <div className="testimonial-author-container-date">
            15 Abril, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Asesora inmobiliaria en Monapart"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Asesora inmobiliaria en Monapart
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            Estoy muy satisfecha con los resultados
            <div style={{ marginTop: "10px" }}>
              Además, han ido mejorando el producto según el feedback que les he
              compartido!
            </div>
          </div>
          <div className="testimonial-author-container-date">
            4 de Junio, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Inmobiliaria Encuentro, Boadilla del Monte"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Inmobiliaria Encuentro, Boadilla del Monte
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">Es una buena herramienta.</div>
          <div className="testimonial-author-container-date">
            6 de Mayo, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={OscarMelendre}
              alt="Oscar Melendre, Marketing at I-VEO"
            ></img>
            <div className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Oscar Melendre
              </div>
              <div className="testimonial-author-container-second-title">
                Marketing at I-VEO
              </div>
            </div>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <div className="testimonial-text">
            La IA funciona bastante bien si la comparo con otras herramientas de
            IA. Interpreta los espacios muy bien!
          </div>
          <div className="testimonial-author-container-date">
            11 de Marzo, 2024
          </div>
        </div>
      </div>

      <div
        className="section-first-button-container"
        style={{ marginTop: "100px" }}
      >
        <button className="button-top-of-page" onClick={redirectToPage}>
          Prueba Pedra <ArrowIcon />
        </button>
      </div>
    </>
  );
}

export default TestimonialsEs;
