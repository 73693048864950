import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeSalon from "./Images/curtain-effect-hero/BeforeSalon.png";
import AfterSalon from "./Images/curtain-effect-hero/AfterSalon.png";
import BeforeSalonStaging1 from "./Images/curtain-effect/BeforeSalonStaging1.png";
import AfterSalonStaging1 from "./Images/curtain-effect/AfterSalonStaging1.png";
import BeforeSalonStaging2 from "./Images/curtain-effect/BeforeSalonStaging2.png";
import AfterSalonStaging2 from "./Images/curtain-effect/AfterSalonStaging2.png";
import BeforeSalonStaging3 from "./Images/curtain-effect/BeforeSalonStaging3.png";
import AfterSalonStaging3 from "./Images/curtain-effect/AfterSalonStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingLivingRoomPt() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeSalon,
    afterImage: AfterSalon,
  });

  const handleExample = (example) => {
    if (example === "Modern") {
      setExample({
        name: "Modern",
        beforeImage: BeforeSalon,
        afterImage: AfterSalon,
      });
    } else if (example === "Traditional") {
      setExample({
        name: "Traditional",
        beforeImage: BeforeSalonStaging1,
        afterImage: AfterSalonStaging1,
      });
    } else if (example === "Rustic") {
      setExample({
        name: "Rustic",
        beforeImage: BeforeSalonStaging2,
        afterImage: AfterSalonStaging2,
      });
    } else {
      setExample({
        name: "Contemporary",
        beforeImage: BeforeSalonStaging3,
        afterImage: AfterSalonStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "O que é home staging de sala de estar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Home staging de sala de estar é uma técnica de marketing imobiliário que se concentra em preparar e apresentar a sala de estar de uma propriedade de forma atraente para potenciais compradores ou inquilinos. Isso pode incluir limpeza, organização, decoração estratégica e pequenas melhorias para destacar os pontos fortes da sala e minimizar suas imperfeições.",
        },
      },
      {
        "@type": "Question",
        name: "Quais são os benefícios do home staging virtual para salas de estar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "O home staging virtual para salas de estar oferece vários benefícios, incluindo: a capacidade de mostrar o potencial da sala sem mudanças físicas custosas, a capacidade de experimentar rapidamente diferentes estilos e configurações, melhor apresentação online da propriedade e a oportunidade de se destacar em um mercado imobiliário competitivo.",
        },
      },
      {
        "@type": "Question",
        name: "Como funciona o processo de home staging virtual para salas de estar?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "O processo de home staging virtual para salas de estar envolve o upload de fotos atuais da sala para nossa plataforma, seleção das opções de edição desejadas (como mudança de acabamentos, adição ou remoção de móveis, ou alteração do layout) e, em seguida, recebimento das imagens editadas mostrando a sala transformada.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeSalonStaging1,
    afterImage: AfterSalonStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeSalonStaging1,
      afterImage: AfterSalonStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeSalonStaging2,
      afterImage: AfterSalonStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeSalonStaging3,
      afterImage: AfterSalonStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging de Sala de Estar</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  O melhor home staging virtual para salas de estar ao seu
                  alcance
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rápido:</strong> Transforme sua sala em segundos
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Fácil:</strong> Design atraente de salas sem esforço
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualidade:</strong> Resultados hiper-realistas para
                    salas
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Ajuda:</strong> Assistência especializada em design
                    de salas
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Experimente a Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por 100 imagens
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Diretor de Fotografia na aProperties"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Corretor de Imóveis na Huspy"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO na Actívox"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO na Gilart Consultors"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Mais de 100 depoimentos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Antes do home staging virtual da sala de estar"}
                  altAfter={"Depois do home staging virtual da sala de estar"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Exemplo de Staging 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditional" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditional")}
                    >
                      Exemplo de Staging 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustic")}
                    >
                      Exemplo de Staging 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporary" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporary")}
                    >
                      Exemplo de Staging 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MAIS DE 5.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtual de salas de estar para{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            vender mais rápido
          </span>{" "}
          qualquer tipo de imóvel.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Renove sua sala virtualmente</h3>
                <div className="feature-text">
                  Transforme sua sala com um clique, sem reformas ou despesas
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Destaque características únicas
                </h3>
                <div className="feature-text">
                  Enfatize os pontos fortes da sua sala com texto descritivo
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Aprimore e corrija fotos da sala
                </h3>
                <div className="feature-text">
                  Otimize a qualidade e perspectiva das fotos da sua sala
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Adicione móveis e decoração modernos
                </h3>
                <div className="feature-text">
                  Atualize sua sala com os últimos estilos virtualmente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renove acabamentos</h3>
                <div className="feature-text">
                  Mude pisos, paredes e acabamentos com um único clique
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Limpe e organize virtualmente</h3>
                <div className="feature-text">
                  Remova desordem e objetos indesejados para uma sala impecável
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemplos do nosso home staging virtual para salas de estar.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Antes do home staging virtual da sala de estar"}
            altAfter={"Depois do home staging virtual da sala de estar"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Como funciona o home staging virtual para salas de estar?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 1
            </h5>
            <h2>Envie fotos da sua sala de estar</h2>
            <div className="value-prop-text">
              Faça upload de uma ou mais imagens da sua sala de estar na Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Como enviar fotos da sala de estar para criar home staging virtual na Pedra – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 2
            </h5>
            <h2>Escolha opções de transformação para sua sala</h2>
            <div className="value-prop-text">
              Selecione entre várias opções para renovar virtualmente sua sala.
              <p>
                Você pode mudar acabamentos, adicionar móveis, reorganizar o
                espaço e muito mais.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Como escolher opções para criar home staging virtual para uma sala de estar – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 3
            </h5>
            <h2>Receba e baixe sua sala transformada</h2>
            <div className="value-prop-text">
              Em questão de minutos, você receberá imagens realistas da sua sala
              renovada.
              <p>Baixe as imagens e use-as para promover seu imóvel.</p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Como baixar home staging virtual para uma sala de estar – Infográfico"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Home staging virtual de salas de estar por €29 por mês. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            E sem compromisso.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plano Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                por mês
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclui</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 gerações de imagens
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mês</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renove e redecore salas
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Mude acabamentos da sala
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Adicione móveis e decoração
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remova objetos indesejados
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumente a resolução da foto
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Mude o layout da sala
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Aprimore a fotografia da sala
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plantas de sala ilimitadas
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartilhamento ilimitado de projetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Adicione marcas d'água
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Suporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente a Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancele a qualquer momento.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compre mais imagens por €0,20 por imagem se precisar.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Perguntas e respostas sobre home staging de sala de estar
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                O que é home staging de sala de estar?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Home staging de sala de estar é uma técnica de marketing
                imobiliário que se concentra em preparar e apresentar a sala de
                estar de uma propriedade de forma atraente para potenciais
                compradores ou inquilinos. Isso pode incluir limpeza profunda,
                organização, decoração estratégica e pequenas melhorias para
                destacar os pontos fortes da sala e minimizar suas imperfeições.
                <br />
                <br />
                No caso do home staging virtual para salas de estar, usamos
                tecnologia avançada para transformar digitalmente o espaço,
                mostrando seu potencial sem a necessidade de mudanças físicas
                custosas.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais são os benefícios do home staging virtual para salas de
                estar?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                O home staging virtual para salas de estar oferece numerosos
                benefícios:
                <br />
                <br />
                1. Economia de custos: Sem necessidade de reformas físicas
                custosas.
                <br />
                2. Economia de tempo: Transformações são feitas em minutos, não
                em semanas.
                <br />
                3. Versatilidade: Você pode mostrar múltiplos estilos e
                configurações da mesma sala.
                <br />
                4. Apelo visual: Ajuda compradores a visualizar o potencial da
                sala.
                <br />
                5. Diferenciação no mercado: Destaque seu imóvel com imagens
                atraentes da sala.
                <br />
                6. Sem inconvenientes: Sem necessidade de perturbar a vida dos
                ocupantes atuais.
                <br />
                7. Melhor apresentação online: Ideal para anúncios de imóveis
                online.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                O que posso fazer com o home staging de sala da Pedra?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Com o home staging virtual para salas da Pedra, você pode
                realizar uma ampla variedade de transformações:
                <br />
                <br />
                1. Renovar salas: Você pode usar os estilos predefinidos da
                Pedra ou criar seus próprios estilos personalizados para
                transformar completamente o visual da sala.
                <br />
                2. Melhorar luz e cor: Otimize a iluminação e as cores das
                fotografias da sala para torná-las mais atraentes e
                profissionais.
                <br />
                3. Remover objetos: Apague elementos indesejados das fotos, como
                móveis antigos, desordem ou itens pessoais.
                <br />
                4. Adicionar elementos: Incorpore novos objetos na sala, como
                móveis modernos, acessórios decorativos ou elementos de design.
                <br />
                5. Mudar acabamentos: Modifique a aparência de pisos, paredes e
                outros acabamentos para mostrar diferentes opções de design.
                <br />
                6. Redistribuir o espaço: Visualize diferentes configurações de
                sala sem necessidade de mudanças físicas.
                <br />
                7. Melhorar iluminação: Ajuste a iluminação virtual para criar
                ambientes mais acolhedores e atraentes.
                <br />
                8. Destacar características arquitetônicas: Enfatize elementos
                únicos como lareiras, janelas grandes ou pé-direito alto.
                <br />
                <br />
                Todas essas transformações são feitas virtualmente, permitindo
                que você mostre o potencial da sala de forma rápida e econômica.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStagingLivingRoomPt;
