import React from "react";

function PrivacyDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div
          className="title-container"
          style={{ marginTop: "100px", maxWidth: "900px" }}
        >
          <h1 className="article-subtitleh2">Datenschutzerklärung</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container" style={{ maxWidth: "900px" }}>
          <div>
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Datenschutzerklärung für Pedra.so
              </h2>
              <div className="article-text">
                Diese Datenschutzerklärung erläutert, wie Pedra.so ("wir", "uns"
                oder "unser") Ihre personenbezogenen Daten erfasst, verwendet,
                weitergibt und schützt, wenn Sie unsere Anwendung nutzen. Durch
                die Nutzung von Pedra.so stimmen Sie den in dieser
                Datenschutzerklärung dargelegten Bedingungen zu.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Informationen, die wir sammeln:
              </h2>
              <div className="article-text">
                1. <strong>Persönliche Informationen:</strong> Wir können
                persönliche Informationen sammeln, die Sie direkt angeben, wie
                Ihren Namen, Ihre E-Mail-Adresse und Kontaktdaten, wenn Sie sich
                für ein Konto registrieren oder uns kontaktieren.
                <br />
                2. <strong>Nutzungsinformationen:</strong> Wir können
                Informationen über Ihre Interaktionen mit unserer Anwendung
                sammeln, einschließlich der besuchten Seiten, der genutzten
                Funktionen und der von Ihnen durchgeführten Aktionen.
                <br />
                3. <strong>Geräteinformationen:</strong> Wir können
                Informationen über das Gerät sammeln, mit dem Sie auf unsere
                Anwendung zugreifen, einschließlich Gerätetyp, Betriebssystem
                und eindeutige Gerätekennungen.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Wie wir Ihre Informationen verwenden:
              </h2>
              <div className="article-text">
                Wir können Ihre Informationen für folgende Zwecke verwenden:
                <br />
                1. Um unsere Dienste bereitzustellen und zu verbessern.
                <br />
                2. Um auf Ihre Anfragen und Wünsche zu reagieren.
                <br />
                3. Um Ihnen Updates, Newsletter und Werbematerialien zu senden
                (wenn Sie zugestimmt haben).
                <br />
                4. Um Ihr Erlebnis zu personalisieren und Inhalte und Funktionen
                bereitzustellen, die auf Ihre Interessen zugeschnitten sind.
              </div>
              <br />
              <h2 className="article-subtitleh2">Informationsweitergabe:</h2>
              <div className="article-text">
                Wir können Ihre Informationen unter folgenden Umständen
                weitergeben:
                <br />
                1. Mit Drittanbietern, die uns bei der Bereitstellung unserer
                Anwendung und Dienstleistungen unterstützen.
                <br />
                2. Um rechtlichen Verpflichtungen nachzukommen oder auf Anfragen
                von Strafverfolgungsbehörden zu reagieren.
                <br />
                3. Im Zusammenhang mit einer Geschäftstransaktion, wie einer
                Fusion, Übernahme oder einem Verkauf von Vermögenswerten.
                <br />
              </div>
              <br />
              <h2 className="article-subtitleh2">Datensicherheit:</h2>
              <div className="article-text">
                Wir ergreifen angemessene Maßnahmen, um Ihre persönlichen Daten
                vor unbefugtem Zugriff und Offenlegung zu schützen. Allerdings
                ist keine Methode der Datenübertragung oder -speicherung
                vollständig sicher, und wir können keine absolute Sicherheit
                garantieren.
              </div>
              <br />
              <h2 className="article-subtitleh2">Ihre Wahlmöglichkeiten:</h2>
              <div className="article-text">
                Sie haben das Recht, auf Ihre persönlichen Daten zuzugreifen,
                sie zu korrigieren oder zu löschen. Sie können sich auch
                jederzeit von Werbe-E-Mails abmelden.
              </div>
              <br />
              <h2 className="article-subtitleh2">
                Änderungen dieser Datenschutzerklärung:
              </h2>
              <div className="article-text">
                Wir können diese Datenschutzerklärung von Zeit zu Zeit
                aktualisieren. Alle Änderungen werden auf dieser Seite mit einem
                überarbeiteten Datum veröffentlicht.
              </div>
              <br />
              <h2 className="article-subtitleh2">Kontaktieren Sie uns:</h2>
              <div className="article-text">
                Bei Fragen oder Vorschlägen kontaktieren Sie uns unter folgender
                E-Mail-Adresse:
                <span style={{ color: "#2383E2" }}>felix@pedra.so</span>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyDe;
