import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseDec2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseDec2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseDec24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">DEZEMBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: Neue Funktionen für Neubauten und Gewerbeimmobilien
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Wir beenden das Jahr mit Verbesserungen, die Ihnen helfen, Pedra
              für mehr Arten von Immobilienverkäufen zu nutzen :)
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Visualisierungen aus Grundrissen erstellen
              </h2>
              Möchten Sie Ihre Grundrisse sofort in realistische Bilder
              umwandeln? Mit Pedra ist das jetzt möglich. Laden Sie ein
              Grundrissbild hoch und erstellen Sie mit einem Klick Bilder aller
              Räume. Perfekt für Neubauten.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Visualisierungen aus Grundrissen"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Gewerbeimmobilien renovieren und möblieren
              </h2>
              Jetzt haben Sie die Möglichkeit, beim Renovieren oder Möblieren
              Ihrer Räume die Art des Raums zu wählen: Restaurant, Bar,
              Geschäft, Supermarkt, Friseursalon... Was auch immer Sie möchten!
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Gewerbeimmobilien"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>
            <div>
              <li>Bessere Ergebnisse beim "Möblieren" von Räumen</li>
              <li>
                Option zur Beauftragung eines professionellen virtuellen
                Einrichtungsservice für 6€ pro Bild
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseDec24De;
