import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLivingRoomPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Criar Renders de Salas de Estar
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre como criar renders de salas de estar
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Por que usar as ferramentas de render da Pedra para salas de
              estar?
            </h2>
            <p className="article-text">
              As ferramentas de render da Pedra permitem visualizar uma
              transformação completa da sala de estar em segundos, ajudando
              potenciais compradores a ver o verdadeiro potencial do espaço.
              <p style={{ marginTop: "10px" }}>
                Renovações de sala de estar são frequentemente a parte mais
                impactante das melhorias residenciais, pois são o espaço central
                de convivência de qualquer casa. Poder mostrar o potencial de
                uma sala de estar datada pode fazer uma grande diferença na
                venda de imóveis usados.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com a Pedra, você não precisa mais de designers de interiores
                especializados ou especialistas em renderização 3D para criar
                propostas convincentes de renovação de sala de estar.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como criar um render de sala de estar?
            </h2>
            <p className="article-text">
              Comece fazendo upload de fotos claras da sala de estar existente
              que você deseja transformar.
              <p style={{ marginTop: "10px" }}>
                Selecione suas imagens da sala de estar e clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>,
                depois escolha a opção{" "}
                <span style={{ fontWeight: "500" }}>"Renovação"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Como usar a ferramenta de renovação para criar renders de sala de estar – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovação Rápida de Sala de Estar:{" "}
              </span>
              Gere um Render de Sala de Estar Rapidamente
            </h2>
            <p style={{ marginTop: "10px" }}>
              A ferramenta{" "}
              <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
              transformará sua sala de estar em cerca de 25 segundos.
              <p>
                Para uma atualização sutil, escolha o estilo "Minimalista" com
                criatividade "Baixa". Isso manterá o layout básico enquanto
                moderniza acabamentos e móveis.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Como usar a ferramenta de renovação para criar renders de sala de estar – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personalize Seu Render de Renovação de Sala de Estar
            </h2>
            <p className="article-text">
              A Pedra oferece extensas opções de personalização para renders de
              sala de estar.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Escolha entre vários estilos de sala de estar, controle quão
                  dramáticas devem ser as mudanças e decida se deseja preservar
                  elementos existentes como janelas ou características
                  arquitetônicas.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Como usar a ferramenta de renovação para criar renders de sala de estar – Captura de tela do software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Escolha seu estilo de sala de estar
            </h3>
            <p className="article-text">
              Selecione entre 9 estilos diferentes de sala de estar, incluindo
              minimalista, tradicional, mediterrâneo e mais.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você também pode fazer upload de imagens de referência de
                  estilos de sala de estar que você ama.
                </span>{" "}
                Isso permite criar renders que correspondam a uma inspiração
                específica de design ou à preferência estética do seu cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Defina a intensidade da renovação
            </h3>
            <p className="article-text">
              Escolha entre níveis de criatividade Baixo, Médio, Alto e Muito
              Alto para sua transformação de sala de estar.
              <p style={{ marginTop: "10px" }}>
                Configurações Baixa e Média atualizarão acabamentos e móveis
                mantendo o layout básico da sala intacto.
              </p>
              <p style={{ marginTop: "10px" }}>
                Configurações Alta e Muito Alta podem reimaginar completamente o
                espaço com novos layouts, configurações de iluminação e mudanças
                arquitetônicas.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Preserve elementos-chave da sala de estar
            </h3>
            <p className="article-text">
              Escolha preservar janelas, o que é particularmente importante para
              salas de estar com vistas ou requisitos específicos de iluminação.
              Isso garante que seu render mantenha elementos práticos enquanto
              atualiza a estética.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Gere seu render de sala de estar
            </h2>
            <p>
              Após selecionar suas preferências, gere o render da sala de estar.
              Renders padrão levam cerca de 15 segundos, enquanto preservar
              elementos específicos pode levar um pouco mais.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Dicas para melhores renders de sala de estar
                </h4>
                <p className="callout-p">
                  Para obter os renders de sala de estar mais realistas e úteis:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Gere múltiplas versões
                      </span>
                      . Cada render é único, e você pode encontrar alguns que
                      preservam melhor características importantes como detalhes
                      arquitetônicos ou iluminação natural.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use criatividade baixa para atualizações sutis em salas de
                      estar datadas.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Garanta boa iluminação ao tirar fotos e minimize
                        reflexos
                      </span>
                      . Isso ajuda a IA a entender melhor o espaço e criar
                      transformações mais precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Exemplos de render de sala de estar
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de renovações de sala de estar criadas
              com nossos renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Sala de estar antes do render"}
              altAfter={"Sala de estar depois do render"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Sala de estar antes do render"}
              altAfter={"Sala de estar depois do render"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Sala de estar antes do render"}
              altAfter={"Sala de estar depois do render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLivingRoomPt;
