import React, { useState } from "react";
import CheckIcon from "../Icons/CheckIcon";
import CustomerLogos from "../CustomerLogos";
import DownloadIcon from "../Icons/DownloadIcon";

const FreeImageFormatConverterPt = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [targetFormat, setTargetFormat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [convertedFiles, setConvertedFiles] = useState([]);

  const supportedFormats = ["JPG", "PNG", "JPEG"];

  const isValidFileType = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    return validTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => !isValidFileType(file));

    if (invalidFiles.length > 0) {
      setError(
        `Arquivos não suportados: ${invalidFiles
          .map((f) => f.name)
          .join(", ")}. Apenas JPG, JPEG e PNG são aceitos.`
      );
      return;
    }

    setSelectedFiles(files);
    setError("");
    setConvertedFiles([]);
  };

  const handleFormatChange = (e) => {
    setTargetFormat(e.target.value);
    setError("");
  };

  const convertImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 4096; // Evitar problemas de memória com imagens muito grandes

          let width = img.width;
          let height = img.height;

          // Redimensionar se a imagem for muito grande
          if (width > maxDimension || height > maxDimension) {
            const ratio = Math.min(maxDimension / width, maxDimension / height);
            width *= ratio;
            height *= ratio;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d", { willReadFrequently: true });

          // Definir fundo branco para transparência PNG
          if (targetFormat.toLowerCase() !== "png") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.92;
          if (
            targetFormat.toLowerCase() === "jpg" ||
            targetFormat.toLowerCase() === "jpeg"
          ) {
            quality = 0.92; // Alta qualidade para JPG/JPEG
          }

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error("Erro na conversão"));
                return;
              }
              resolve(blob);
            },
            `image/${targetFormat.toLowerCase()}`,
            quality
          );
        };

        img.onerror = () => reject(new Error("Erro ao carregar imagem"));
        img.src = reader.result;
      };

      reader.onerror = () => reject(new Error("Erro ao ler arquivo"));
      reader.readAsDataURL(file);
    });
  };

  const handleDownload = async (file) => {
    try {
      const originalFile = selectedFiles.find(
        (f) => f.name === file.originalName
      );
      if (!originalFile) {
        throw new Error("Arquivo original não encontrado");
      }

      setIsLoading(true);
      const convertedBlob = await convertImage(originalFile);

      // Verificar tamanho do arquivo convertido
      if (convertedBlob.size === 0) {
        throw new Error("O arquivo convertido está vazio");
      }

      const url = URL.createObjectURL(convertedBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Erro ao baixar ${file.name}: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadAll = async () => {
    setIsLoading(true);
    try {
      for (const file of convertedFiles) {
        await handleDownload(file);
      }
    } catch (err) {
      setError("Erro ao baixar todos os arquivos: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      setError("Por favor, selecione pelo menos um arquivo");
      return;
    }
    if (!targetFormat) {
      setError("Por favor, selecione um formato de destino");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const newConvertedFiles = selectedFiles.map((file) => ({
        name: `${file.name.split(".")[0]}.${targetFormat.toLowerCase()}`,
        size: file.size,
        originalName: file.name,
      }));

      setConvertedFiles(newConvertedFiles);
    } catch (err) {
      setError("Erro durante a conversão: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="section-first-wrapper">
      <section className="section-first">
        <div className="section-first-text-container">
          <h1 className="section-first-title">
            Conversor de Formato de Imagem{" "}
            <span className="text-highlight">Grátis</span>
          </h1>
          <div className="section-first-text free-tool-value-propcontainer">
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>100% grátis</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Conversão instantânea</span>
            </p>
            <p className="free-tool-value-prop">
              <CheckIcon /> <span>Múltiplos formatos suportados</span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="converter-form">
          <div>
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">1</div>
              </div>{" "}
              Selecionar Imagens
            </label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="free-tool-form">
              <div className="free-tool-form-number-container">
                <div className="free-tool-form-number">2</div>
              </div>{" "}
              Formato de Destino
            </label>
            <select
              value={targetFormat}
              onChange={handleFormatChange}
              className="form-select"
            >
              <option value="">Selecione o formato</option>
              {supportedFormats.map((format) => (
                <option key={format} value={format}>
                  {format}
                </option>
              ))}
            </select>
          </div>

          <br />

          <button
            type="submit"
            disabled={isLoading}
            className="free-tool-button"
          >
            {isLoading ? "Convertendo..." : "Converter imagens"}
          </button>
          <br />

          {convertedFiles.length > 0 && (
            <div className="form-group">
              <div className="converted-files-header">
                <h2 className="converted-files-title">Arquivos Convertidos:</h2>
                <button
                  onClick={handleDownloadAll}
                  className="free-tool-button"
                >
                  <DownloadIcon />
                  Baixar imagens
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="error-message">
              <strong>Erro:</strong> {error}
            </div>
          )}
        </form>

        <div className="step-container article" style={{ marginTop: "100px" }}>
          <article className="article-text">
            <h2 className="article-subtitleh2">
              O que é um conversor de formato de imagem?
            </h2>
            <p>
              Um conversor de formato de imagem é uma ferramenta que permite
              transformar imagens de um formato para outro mantendo a qualidade
              original. Esta ferramenta é especialmente útil quando você precisa
              de imagens em formatos específicos para diferentes plataformas ou
              usos.
            </p>
            <h2 className="article-subtitleh2">Formatos suportados</h2>
            <p>
              Nossa ferramenta suporta os formatos de imagem mais comuns e
              amplamente utilizados:
            </p>
            <ul>
              <li>
                JPG/JPEG - Ideal para fotografias e imagens com muitas cores
              </li>
              <li>PNG - Perfeito para imagens que requerem transparência</li>
            </ul>
            <h2 className="article-subtitleh2">Vantagens do nosso conversor</h2>
            <h3>Facilidade de uso</h3>
            <p>
              Nossa ferramenta é projetada para ser intuitiva e fácil de usar.
              Você só precisa fazer upload das suas imagens, selecionar o
              formato desejado e clicar em converter. Não é necessário
              conhecimento técnico.
            </p>
            <h3>Conversão em lote</h3>
            <p>
              Converta múltiplas imagens simultaneamente, economizando tempo e
              esforço quando você precisa processar vários arquivos.
            </p>
            <h3>Mantém a qualidade</h3>
            <p>
              Nosso conversor usa algoritmos avançados para manter a melhor
              qualidade possível durante a conversão, garantindo que suas
              imagens fiquem excelentes no novo formato.
            </p>
            <h2 className="article-subtitleh2">
              Dicas para escolher o formato certo
            </h2>
            <ul>
              <li>
                JPG/JPEG: Ideal para fotografias e imagens com muitas cores. É o
                formato mais comum para fotografias digitais e oferece boa
                compressão mantendo uma qualidade visual aceitável.
              </li>
              <li>
                PNG: Melhor escolha quando você precisa de transparência ou para
                imagens com texto, gráficos ou bordas definidas. Também é
                excelente para capturas de tela e gráficos com áreas de cores
                sólidas.
              </li>
            </ul>
          </article>
        </div>

        <div className="customer-logo-container">
          MAIS DE 5.000 USUÁRIOS JÁ CONFIAM NO NOSSO CONVERSOR
          <CustomerLogos />
        </div>
      </section>
    </section>
  );
};

export default FreeImageFormatConverterPt;
