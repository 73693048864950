import React from "react";
import ReleaseAug2024Frame1 from "../Images/ReleaseAug2024Frame1.png";
import ReleaseAug2024Frame2 from "../Images/ReleaseAug2024Frame2.png";
import ReleaseAug2024Frame3 from "../Images/ReleaseAug2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseAug24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">AGOSTO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Melhorias no mobiliamento, estilos personalizados e
            preservação de janelas
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Neste agosto, melhoramos tudo o que a Pedra já oferece, além de
              algumas novidades que vão te surpreender:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Resultados super-realistas na ferramenta de mobiliamento
              </h2>
              Você verá que os resultados ao mobiliar espaços estão melhores do
              que nunca. Atualizamos nossos modelos de IA para fazer suas fotos
              parecerem o mais realistas possível ✨
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame1}
                  alt="Exemplo de mobiliamento super-realista"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Use seus próprios estilos para renovar imóveis
              </h2>
              Agora você pode fazer upload de uma foto de qualquer estilo, e a
              Pedra a usará como referência para renovar a fotografia. Ideal se
              você quiser mostrar um imóvel no estilo mais atual ou no que seu
              cliente mais gosta. Você pode ver como funciona aqui.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame2}
                  alt="Exemplo de renovação com estilo personalizado"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Melhoria na preservação de janelas durante a renovação
              </h2>
              Preserve as vistas das janelas da maneira mais realista! Isso
              garante que suas propostas de renovação sejam fáceis de
              interpretar por seus clientes.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseAug2024Frame3}
                alt="Exemplo de preservação de janelas"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>

            <div>
              <li>
                Melhorias na detecção do céu ao aplicar o recurso{" "}
                <strong>"Céu azul"</strong>
              </li>
              <li>
                A funcionalidade <strong>"Adicionar objeto"</strong> está mais
                intuitiva de usar: feedback quando os objetos estão carregando e
                movimentação mais fácil dos móveis
              </li>
              <li>
                Agora você pode aumentar o número de imagens se acabar suas
                gerações mensais de imagens
              </li>
              <li>
                Várias correções: mudança de marca d'água, prevenção de falha de
                todas as imagens se uma imagem HD falhar e criação de novos
                projetos mais rápida do que nunca
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseAug24Pt;
