import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToFurnishFrame1 from "../Images/HowToFurnishFrame1.jpg";
import HowToFurnishFrame2 from "../Images/HowToFurnishFrame2.jpg";
import HowToFurnishFrame3 from "../Images/HowToFurnishFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";

function HowToFurnishDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So nutzen Sie das Möblierungs-Tool zur virtuellen Einrichtung
          </h1>

          <div className="title-secondary gray">
            Tutorial zur virtuellen Möblierung von Häusern und Räumen
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wofür wird Pedras "Möblieren"-Tool verwendet?
            </h2>
            <p className="article-text">
              Mit Pedras Möblierungs-Tools können Sie Möbel in die Räume Ihrer
              Immobilie einfügen.
              <p style={{ marginTop: "10px" }}>
                Die Darstellung möblierter Räume kann den Verkauf beschleunigen,
                da Käufer die Raumaufteilung besser verstehen, wenn sie die
                Fotos auf den großen Immobilienportalen sehen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Außerdem sehen die von Pedra hinzugefügten Möbel sauber, neu und
                ordentlich aus, was zu positiven Emotionen beim Betrachten der
                Fotos führt.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie möbliere ich einen Raum virtuell mit Pedra?
            </h2>
            <p className="article-text">
              Nachdem Sie die Bilder hochgeladen haben, die Sie möblieren
              möchten, wählen Sie eines davon aus.
              <p style={{ marginTop: "10px" }}>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                und Sie sehen die Option{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame1}
                alt="So nutzen Sie das Möblierungs-Tool zur virtuellen Einrichtung – Screenshot der Pedra-Software"
              />
            </p>
            <p className="article-text">
              Nachfolgend zeigen wir Ihnen die Schritte zur Möblierung eines
              Raums mit Pedra.
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Auswahl des virtuell zu möblierenden Bereichs
            </h3>
            <p className="article-text">
              Der erste Schritt bestimmt, wo die Möbel erscheinen sollen. Die
              Auswahl kann automatisch oder gezeichnet erfolgen. Bei der
              automatischen Auswahl entscheidet Pedra, wo die Möbel platziert
              werden.
              <p style={{ marginTop: "10px" }}>
                {" "}
                Wenn Sie mehr Präzision bei der Möbelplatzierung benötigen,
                wählen Sie <span style={{ fontWeight: "500" }}>"Zeichnen"</span>
                .
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit der <span style={{ fontWeight: "500" }}>"Zeichnen"</span>
                -Option können Sie den Bereich markieren, in dem die Möbel
                erscheinen sollen. Malen Sie einfach einen gleichmäßigen und
                großen Bereich aus, wo die Möbel erscheinen sollen.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToFurnishFrame2}
                alt="So nutzen Sie das Möblierungs-Tool zur virtuellen Einrichtung – Screenshot der Pedra-Software"
              />
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Art des zu möblierenden Raums
            </h3>
            <p className="article-text">
              In diesem zweiten Schritt müssen Sie die Art des Raums auswählen.
              Wenn Sie 'Schlafzimmer' wählen, wird ein Bett, ein Nachttisch,
              eine Lampe usw. platziert. Wenn Sie 'Wohnzimmer' wählen, werden
              Sofas, Couchtische, ein Fernseher usw. platziert.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Virtueller Einrichtungsstil
            </h3>
            <p className="article-text">
              Sie können auch die Art des 'Möblierungs'-Stils ändern. Sie können
              zwischen 'Minimalistisch', 'Mediterran', 'Traditionell' und
              weiteren Optionen wählen.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Anzahl der zu generierenden Bilder
            </h2>
            <p>
              Sie können auch die Anzahl der zu generierenden Vorschläge wählen.
              Jede Möblierung ist einzigartig, daher empfehlen wir, 2 oder 3
              Vorschläge generieren zu lassen. So können Sie den Vorschlag
              wählen, der am besten zu Ihnen passt.
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToFurnishFrame3}
              alt="So nutzen Sie das Möblierungs-Tool zur virtuellen Einrichtung – Screenshot der Pedra-Software"
            />
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  So erhalten Sie die besten virtuellen Möblierungen
                </h4>
                <p className="callout-p">
                  Befolgen Sie diese Tipps für beste Ergebnisse:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Wenn Sie mit der 'Auto'-Möbelpositionierung keine guten
                        Ergebnisse erzielen, versuchen Sie 'Zeichnen'.
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Um die Platzierung der Möbel zu kontrollieren, wählen
                        Sie die Option 'Zeichnen' in Schritt 1
                      </span>
                      . So können Sie bestimmen, wo die Möbel erscheinen und
                      welchen Raum sie einnehmen.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Wenn Sie 'Zeichnen' wählen, malen Sie keinen zu kleinen
                        Bereich aus, um zu kleine Möbeldimensionen zu vermeiden.
                      </span>{" "}
                      Malen Sie einen gleichmäßigen und großen Bereich aus.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Wenn Sie kontrollieren möchten, welche Möbel hinzugefügt
                        werden, deren Ausrichtung und Größe, empfehlen wir{" "}
                        <Link
                          className="article-link"
                          to="/help/how-to-add-3d-objects"
                        >
                          'Objekt hinzufügen'
                        </Link>
                      </span>
                      . Diese andere Pedra-Funktionalität ermöglicht es Ihnen,
                      die zu platzierenden Möbel, deren Ausrichtung und Größe zu
                      wählen. Sie können hier sehen, wie es funktioniert{" "}
                      <Link
                        className="article-link"
                        to="/help/how-to-add-3d-objects"
                      >
                        hier
                      </Link>
                      .
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToFurnishDe;
