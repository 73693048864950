import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example2Before from "../Images/curtain-effect/BeforeBathroomStaging2.png";
import Example2After from "../Images/curtain-effect/AfterBathroomStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeBathroomStaging3.png";
import Example3After from "../Images/curtain-effect/AfterBathroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderBathroomPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Como Criar Renders de Banheiros</h1>

          <div className="title-secondary gray">
            Tutorial sobre como criar renders de banheiros
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Por que usar as ferramentas de render da Pedra para banheiros?
            </h2>
            <p className="article-text">
              As ferramentas de render da Pedra permitem visualizar uma
              renovação completa do banheiro em segundos, ajudando potenciais
              compradores a ver o verdadeiro potencial do espaço.
              <p style={{ marginTop: "10px" }}>
                Renovações de banheiro são uma das melhorias residenciais mais
                valiosas. Poder mostrar o potencial de um banheiro desatualizado
                pode fazer uma grande diferença na venda de imóveis usados.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com a Pedra, você não precisa mais de designers especializados
                em banheiros ou especialistas em renderização 3D para criar
                propostas convincentes de renovação de banheiros.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como criar um render de banheiro?
            </h2>
            <p className="article-text">
              Comece fazendo upload de fotos claras do banheiro existente que
              você deseja transformar.
              <p style={{ marginTop: "10px" }}>
                Selecione suas imagens de banheiro e clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>,
                depois escolha a opção{" "}
                <span style={{ fontWeight: "500" }}>"Renovação"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Como usar a ferramenta de renovação para criar renders de banheiro – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovação Rápida de Banheiro:{" "}
              </span>
              Gere um Render de Banheiro Rapidamente
            </h2>
            <p style={{ marginTop: "10px" }}>
              A ferramenta{" "}
              <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
              transformará seu banheiro em cerca de 25 segundos.
              <p>
                Para uma atualização sutil, escolha o estilo "Minimalista" com
                criatividade "Baixa". Isso manterá o layout básico enquanto
                moderniza acabamentos e acessórios.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Como usar a ferramenta de renovação para criar renders de banheiro – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personalize Seu Render de Renovação de Banheiro
            </h2>
            <p className="article-text">
              A Pedra oferece extensas opções de personalização para renders de
              banheiro.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Escolha entre vários estilos de banheiro, controle quão
                  dramáticas devem ser as mudanças e decida se deseja preservar
                  elementos existentes como janelas ou acessórios.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Como usar a ferramenta de renovação para criar renders de banheiro – Captura de tela do software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Escolha seu estilo de banheiro
            </h3>
            <p className="article-text">
              Selecione entre 9 estilos diferentes de banheiro, incluindo
              minimalista, tradicional, mediterrâneo e mais.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você também pode fazer upload de imagens de referência de
                  estilos de banheiro que você ama.
                </span>{" "}
                Isso permite criar renders que correspondam a uma inspiração
                específica de design ou à preferência estética do seu cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Defina a intensidade da renovação
            </h3>
            <p className="article-text">
              Escolha entre níveis de criatividade Baixo, Médio, Alto e Muito
              Alto para sua transformação de banheiro.
              <p style={{ marginTop: "10px" }}>
                Configurações Baixa e Média atualizarão acabamentos e acessórios
                mantendo o layout básico do banheiro intacto.
              </p>
              <p style={{ marginTop: "10px" }}>
                Configurações Alta e Muito Alta podem reimaginar completamente o
                espaço com novos layouts, configurações de acessórios e mudanças
                arquitetônicas.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Preserve elementos-chave do banheiro
            </h3>
            <p className="article-text">
              Escolha preservar janelas e localizações hidráulicas, o que é
              particularmente importante para banheiros com requisitos
              específicos de ventilação ou restrições de encanamento. Isso
              garante que seu render mantenha elementos práticos enquanto
              atualiza a estética.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Gere seu render de banheiro
            </h2>
            <p>
              Após selecionar suas preferências, gere o render do banheiro.
              Renders padrão levam cerca de 15 segundos, enquanto preservar
              elementos específicos pode levar um pouco mais.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Dicas para melhores renders de banheiro
                </h4>
                <p className="callout-p">
                  Para obter os renders de banheiro mais realistas e úteis:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Gere múltiplas versões
                      </span>
                      . Cada render é único, e você pode encontrar alguns que
                      preservam melhor características importantes do banheiro,
                      como localizações hidráulicas.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use criatividade baixa para atualizações sutis em
                      banheiros datados.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Remova reflexos de espelhos ou vidros antes de
                        renderizar
                      </span>
                      . Isso ajuda a IA a entender melhor o espaço e criar
                      transformações mais precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Exemplos de render de banheiro
            </h2>
            <p className="article-text">
              Aqui estão vários exemplos de renovações de banheiro criadas com
              nossos renders:
            </p>
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Banheiro antes do render"}
              altAfter={"Banheiro depois do render"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Banheiro antes do render"}
              altAfter={"Banheiro depois do render"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderBathroomPt;
