import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import VirtualStagingExample1 from "../Images/HowToFloorPlanRender1En.png";
import VirtualStagingExample2 from "../Images/HowToFloorPlanRender2En.png";
import VirtualStagingExample3 from "../Images/HowToFloorPlanRender3En.png";

function HowToRenderFloorPlanWithAIPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Renderizar uma Planta Baixa com IA
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como gerar imagens realistas de ambientes a partir de
            plantas baixas usando inteligência artificial
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              O que é Renderização de Planta Baixa com IA?
            </h2>
            <p className="article-text">
              Com a Pedra, você pode transformar suas plantas baixas em imagens
              fotorrealistas de cada ambiente em apenas alguns minutos. Nossa
              tecnologia de IA analisa sua planta baixa e gera visualizações de
              alta qualidade mostrando como os espaços ficariam na vida real,
              permitindo que você escolha entre diferentes estilos de decoração.
            </p>

            <h3 className="article-subtitle">
              Passo 1 – Faça upload da sua planta baixa
            </h3>
            <div className="value-prop-text">
              Faça upload da sua planta baixa na plataforma Pedra.
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample1}
                alt="Como fazer upload de plantas baixas para renderização automática na Pedra – Infográfico"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Passo 2 – Escolha suas opções de renderização
            </h3>
            <div className="value-prop-text">
              Selecione seu estilo e opções de personalização.
              <p>Escolha o número de imagens que deseja e o estilo.</p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample2}
                alt="Como escolher opções para renderização de planta baixa – Infográfico"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Passo 3 – Receba seus renders de ambientes
            </h3>
            <div className="value-prop-text">
              Receba renders fotorrealistas de cada ambiente da sua planta
              baixa.
              <p>
                Baixe e use seus renders imediatamente ou continue editando-os.
              </p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample3}
                alt="Como baixar renders de planta baixa – Infográfico"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Dicas para melhores resultados:
                <ul>
                  <li>
                    Certifique-se de que a planta baixa esteja bem definida e as
                    medidas sejam legíveis
                  </li>
                  <li>
                    Considere o estilo que melhor se adequa ao seu projeto
                  </li>
                  <li>
                    Gere múltiplos renders para ter diferentes opções de
                    visualização
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">
              Por que usar Renderização de Planta Baixa com IA?
            </h2>
            <p className="article-text">
              A renderização de planta baixa com IA oferece múltiplas vantagens:
            </p>
            <ul>
              <li>
                Visualização imediata de como os espaços ficarão na realidade
              </li>
              <li>
                Economia significativa de tempo em comparação com renderização
                tradicional
              </li>
              <li>
                Flexibilidade para experimentar diferentes estilos de decoração
              </li>
              <li>
                Qualidade fotorrealista que ajuda na apresentação do projeto
              </li>
            </ul>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRenderFloorPlanWithAIPt;
