import React from "react";
import ReleaseMay2024Frame1 from "../Images/ReleaseMay2024Frame1.png";
import ReleaseMay2024Frame2 from "../Images/ReleaseMay2024Frame2.png";
import ReleaseMay2024Frame3 from "../Images/ReleaseMay2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseMay24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">MAI 2024</div>
          <h1 className="article-titleh1">
            Pedra: Räume leeren und Objekte entfernen, Renovierungsstile und
            neue Benutzeroberfläche
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Illustratives Titelbild über KI und die Immobilienbranche"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Entdecken Sie hier die neuesten Entwicklungen von Pedra im Mai:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Verbesserte Raumreinigung und Objektentfernung
              </h2>
              Wir haben unseren Algorithmus zum Leeren von Räumen und Entfernen
              von Objekten perfektioniert und erzielen damit noch realistischere
              Ergebnisse. Dies ist ideal, um Immobilien frei von unnötigen
              Gegenständen zu präsentieren, sodass Ihre Kunden die Ästhetik der
              Immobilie voll und ganz würdigen können. Hier ein Beispiel, wie
              sich ein Raum verwandeln lässt:
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame1}
                  alt="Aufgeräumte Räume mit KI"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Renovierungsstile: Neu und verbessert
              </h2>
              Jetzt können Sie neue{" "}
              <Link className="article-link" to="/render">
                Renovierungsstile
              </Link>{" "}
              wählen und mehr Veränderungen wahrnehmen. So können Sie Ihren
              Kunden in Sekundenschnelle mehr hochwertige Renovierungsvorschläge
              anbieten.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseMay2024Frame2}
                  alt="KI-Renovierungsstile"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Neue Benutzeroberfläche für eine bessere Übersicht
              </h2>
              Wir haben unsere Anwendung komplett umgestaltet und die
              Komplexität des vorherigen Designs hinter uns gelassen. Früher
              häuften sich die Bilder an, was es schwierig machte, den Überblick
              über das Projekt zu behalten und den Prozess verlangsamte. Jetzt
              präsentieren wir ein Design, bei dem jedes Bild sorgfältig
              geordnet und beschriftet ist, wodurch die Bildbearbeitung nicht
              nur einfach, sondern auch intuitiv wird.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseMay2024Frame3}
                alt="Neue Pedra-Benutzeroberfläche"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>

            <div>
              <li>
                Wir haben unsere Anwendung komplett umgestaltet und die
                Komplexität des vorherigen Designs hinter uns gelassen. Früher
                häuften sich die Bilder an, was es schwierig machte, den
                Überblick über das Projekt zu behalten und den Prozess
                verlangsamte. Jetzt präsentieren wir ein Design, bei dem jedes
                Bild sorgfältig geordnet und beschriftet ist, wodurch die
                Bildbearbeitung nicht nur einfach, sondern auch intuitiv wird.
              </li>
              <li>Passen Sie den Grad der Kreativität bei Renovierungen an</li>
              <li>
                Fügen Sie allen Ihren Bildern auf einmal ein Wasserzeichen hinzu
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseMay24De;
