import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import HowToCurtainEffectImage from "../Images/HowToCurtainEffectPtImage.jpg";

import CurtainEffect from "../CurtainEffect";

function HowToCurtainEffectPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Criar um Efeito Cortina Antes e Depois para Home Staging
            Virtual
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre como criar efeitos cortina antes/depois para staging
            virtual
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              O que é um efeito cortina e por que é útil?
            </h2>
            <p className="article-text">
              Um efeito cortina é uma forma interativa de mostrar imagens antes
              e depois do home staging virtual. Permite que os espectadores
              deslizem entre as versões original e decorada de um espaço,
              tornando fácil apreciar a transformação.
            </p>
            <p className="article-text">
              Este efeito é particularmente valioso para profissionais
              imobiliários porque:
            </p>
            <ul>
              <li>
                Demonstra o impacto do staging virtual de forma convincente
              </li>
              <li>
                Ajuda os clientes a entender o potencial dos espaços vazios
              </li>
              <li>Cria conteúdo envolvente para materiais de marketing</li>
              <li>
                Constrói confiança ao mostrar o espaço original junto com as
                melhorias
              </li>
            </ul>

            <h2 className="article-subtitleh2">
              Como criar um efeito cortina com Pedra
            </h2>
            <p className="article-text">
              Tradicionalmente, criar um efeito cortina exigia conhecimento
              técnico e habilidades de programação. No entanto, com Pedra, você
              pode criar um com apenas um clique.
            </p>
            <p className="article-text">
              Depois de criar sua imagem de staging virtual, simplesmente:
            </p>
            <ol>
              <li>Clique na sua imagem com staging virtual</li>
              <li>
                Clique em "Efeito cortina" - isso gerará automaticamente um link
                compartilhável mostrando sua comparação antes/depois com um
                controle deslizante interativo
              </li>
            </ol>

            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToCurtainEffectImage}
              alt="Como criar um efeito cortina - Captura de tela do software Pedra"
            />

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Dicas para efeitos cortina eficazes:
                <ul>
                  <li>
                    Use imagens de alta qualidade para a melhor apresentação
                  </li>
                  <li>
                    Compartilhe o link do efeito cortina em seu site ou redes
                    sociais para mostrar seu trabalho de staging virtual
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">Exemplos de efeitos cortina</h2>
            <p className="article-text">
              Aqui estão vários exemplos de efeitos cortina mostrando
              transformações de staging virtual:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Terraço vazio antes do staging virtual"}
              altAfter={"Terraço mobiliado depois do staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Terraço vazio antes do staging virtual"}
              altAfter={"Terraço mobiliado depois do staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Terraço vazio antes do staging virtual"}
              altAfter={"Terraço mobiliado depois do staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Terraço vazio antes do staging virtual"}
              altAfter={"Terraço mobiliado depois do staging virtual"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToCurtainEffectPt;
