import React from "react";
import ReleaseAug2024Frame1 from "../Images/ReleaseAug2024Frame1.png";
import ReleaseAug2024Frame2 from "../Images/ReleaseAug2024Frame2.png";
import ReleaseAug2024Frame3 from "../Images/ReleaseAug2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseAug24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">AUGUST 2024</div>
          <h1 className="article-titleh1">
            Pedra: Verbesserungen bei der Möblierung, individuelle Stile und
            Fenstererhaltung
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              In diesem August haben wir alles verbessert, was Pedra bereits
              bietet, plus einige neue Funktionen, die Sie überraschen werden:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Superrealistische Ergebnisse im Möblierungstool
              </h2>
              Sie werden sehen, dass die Ergebnisse bei der Möblierung von
              Räumen besser sind als je zuvor. Wir haben unsere KI-Modelle
              aktualisiert, damit Ihre Fotos so realistisch wie möglich aussehen
              ✨
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame1}
                  alt="Beispiel für superrealistische Möblierung"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Nutzen Sie Ihre eigenen Stile zur Renovierung von Immobilien
              </h2>
              Jetzt können Sie ein Foto von jedem beliebigen Stil hochladen, und
              Pedra wird es als Referenz für die Renovierung des Fotos
              verwenden. Ideal, wenn Sie eine Immobilie im angesagtesten Stil
              oder in dem Stil zeigen möchten, den Ihr Kunde am meisten mag. Sie
              können hier sehen, wie es funktioniert.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseAug2024Frame2}
                  alt="Beispiel für Renovierung mit individuellem Stil"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Verbesserung der Fenstererhaltung während der Renovierung
              </h2>
              Bewahren Sie Fensterausblicke auf realistischste Weise! Dies
              stellt sicher, dass Ihre Renovierungsvorschläge für Ihre Kunden
              leicht zu interpretieren sind.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseAug2024Frame3}
                alt="Beispiel für Fenstererhaltung"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>

            <div>
              <li>
                Verbesserungen bei der Himmelserkennung bei Anwendung der{" "}
                <strong>"Blauer Himmel"</strong>-Funktion
              </li>
              <li>
                Die <strong>"Objekt hinzufügen"</strong>-Funktion ist intuitiver
                zu bedienen: Feedback beim Laden von Objekten und einfachere
                Möbelbewegung
              </li>
              <li>
                Jetzt können Sie die Anzahl der Bilder erhöhen, wenn Ihnen die
                monatlichen Bildgenerierungen ausgehen
              </li>
              <li>
                Verschiedene Fehlerbehebungen: Wasserzeichenänderung,
                Verhinderung des Ausfalls aller Bilder, wenn ein HD-Bild
                fehlschlägt, und schnelleres Erstellen neuer Projekte als je
                zuvor
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseAug24De;
