import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderKitchenDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie Küchen-Renderings
          </h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Küchen-Renderings
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Warum sollten Sie Pedras Rendering-Tools für Küchen nutzen?
            </h2>
            <p className="article-text">
              Mit Pedras Rendering-Tools können Sie in wenigen Sekunden eine
              komplette Küchenrenovierung visualisieren und potenziellen Käufern
              das wahre Potenzial des Raums zeigen.
              <p style={{ marginTop: "10px" }}>
                Küchenrenovierungen sind oft der wirkungsvollste und teuerste
                Teil von Hausverbesserungen. Die Möglichkeit, das Potenzial
                einer veralteten Küche aufzuzeigen, kann beim Verkauf von
                gebrauchten Immobilien einen großen Unterschied machen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit Pedra benötigen Sie keine spezialisierten Küchendesigner
                oder 3D-Rendering-Experten mehr, um überzeugende
                Küchenrenovierungsvorschläge zu erstellen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich ein Küchen-Rendering?
            </h2>
            <p className="article-text">
              Laden Sie zunächst klare Fotos der bestehenden Küche hoch, die Sie
              transformieren möchten.
              <p style={{ marginTop: "10px" }}>
                Wählen Sie Ihre Küchenbilder aus und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                dann wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>"Renovieren"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Anleitung zur Nutzung des Renovierungstools für Küchen-Renderings – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Schnelle Küchenrenovierung:{" "}
              </span>
              Erstellen Sie ein schnelles Küchen-Rendering
            </h2>
            <p style={{ marginTop: "10px" }}>
              Das <span style={{ fontWeight: "500" }}>"Renovieren"</span> Tool
              wird Ihre Küche in etwa 25 Sekunden transformieren.
              <p>
                Für eine subtile Aktualisierung wählen Sie den
                "Minimalistischen" Stil mit "Niedriger" Kreativität. Dies behält
                das grundlegende Layout bei, während Oberflächen und Armaturen
                modernisiert werden.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Anleitung zur Nutzung des Renovierungstools für Küchen-Renderings – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Passen Sie Ihr Küchen-Renovierungs-Rendering an
            </h2>
            <p className="article-text">
              Pedra bietet umfangreiche Anpassungsmöglichkeiten für
              Küchen-Renderings.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Wählen Sie aus verschiedenen Küchenstilen, steuern Sie, wie
                  dramatisch die Änderungen sein sollen, und entscheiden Sie, ob
                  bestehende Elemente wie Fenster oder Gerätestandorte erhalten
                  bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Anleitung zur Nutzung des Renovierungstools für Küchen-Renderings – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Wählen Sie Ihren Küchenstil
            </h3>
            <p className="article-text">
              Wählen Sie aus 9 verschiedenen Küchenstilen, darunter
              minimalistisch, traditionell, mediterran und mehr.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Referenzbilder von Küchenstilen hochladen, die
                  Ihnen gefallen.
                </span>{" "}
                Dies ermöglicht es Ihnen, Renderings zu erstellen, die
                bestimmten Designinspirationen oder dem bevorzugten Stil Ihres
                Kunden entsprechen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Legen Sie die Renovierungsintensität fest
            </h3>
            <p className="article-text">
              Wählen Sie zwischen niedrigen, mittleren, hohen und sehr hohen
              Kreativitätsstufen für Ihre Küchentransformation.
              <p style={{ marginTop: "10px" }}>
                Niedrige und mittlere Einstellungen aktualisieren Oberflächen
                und Armaturen, während das grundlegende Küchenlayout erhalten
                bleibt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hohe und sehr hohe Einstellungen können den Raum komplett neu
                gestalten mit neuen Layouts, Schrankonfigurationen und
                architektonischen Änderungen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Bewahren Sie wichtige Küchenelemente
            </h3>
            <p className="article-text">
              Wählen Sie die Option, Fenster zu erhalten, was besonders wichtig
              ist für Küchen mit Gartenblick oder speziellen
              Beleuchtungsanforderungen. Dies stellt sicher, dass Ihr Rendering
              praktische Elemente beibehält, während die Ästhetik aktualisiert
              wird.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Generieren Sie Ihr Küchen-Rendering
            </h2>
            <p>
              Nach der Auswahl Ihrer Präferenzen generieren Sie das
              Küchen-Rendering. Standardrenderings dauern etwa 15 Sekunden,
              während das Erhalten bestimmter Elemente etwas länger dauern kann.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps für bessere Küchen-Renderings
                </h4>
                <p className="callout-p">
                  Um die realistischsten und nützlichsten Küchen-Renderings zu
                  erhalten:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generieren Sie mehrere Versionen
                      </span>
                      . Jedes Rendering ist einzigartig, und Sie könnten
                      feststellen, dass einige wichtige Küchenmerkmale wie
                      Gerätestandorte besser erhalten bleiben.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Verwenden Sie niedrige Kreativität für subtile
                      Aktualisierungen veralteter Küchen.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Entfernen Sie Reflexionen von Geräten oder Glasschränken
                        vor dem Rendering
                      </span>
                      . Dies hilft der KI, den Raum besser zu verstehen und
                      genauere Transformationen zu erstellen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">Küchen-Rendering Beispiele</h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für Küchenrenovierungen, die mit
              unseren Renderings erstellt wurden:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Küche vor der Renovierung"}
              altAfter={"Küche nach der Renovierung"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Küche vor der Renovierung"}
              altAfter={"Küche nach der Renovierung"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Küche vor der Renovierung"}
              altAfter={"Küche nach der Renovierung"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Küche vor der Renovierung"}
              altAfter={"Küche nach der Renovierung"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderKitchenDe;
