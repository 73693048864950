// HomeEs.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";

// Images

import REMAXLogo from "./Images/customer-logos/customer-logo-remax.png";
import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import ENGELLogo from "./Images/customer-logos/customer-logo-agenthia.png";
import HomeDecoration1 from "./Images/HomeDecoration1.png";
import HomeDecoration2 from "./Images/HomeDecoration2.png";
import TitleDecoration1 from "./Images/TitleDecoration1.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import PepeGomez from "./Images/Pepe Gomez image.png";
import CarmeHuges from "./Images/Carme Huges image.png";
import SoledadTerol from "./Images/Soledad Terol image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomePageBlueSkyBefore from "./Images/HomePageBlueSkyBefore.jpg";
import HomePageBlueSkyAfter from "./Images/HomePageBlueSkyAfter.jpg";
import HomePageEmptyBefore from "./Images/HomePageEmptyBefore.jpg";
import HomePageEmptyAfter from "./Images/HomePageEmptyAfter.jpg";
import HomePageEnhanceBefore from "./Images/HomePageEnhanceBefore.jpg";
import HomePageEnhanceAfter from "./Images/HomePageEnhanceAfter.jpg";
import HomePageEnlightBefore from "./Images/HomePageEnlightBefore.jpg";
import HomePageEnlightAfter from "./Images/HomePageEnlightAfter.jpg";
import HomePageFurnishBefore from "./Images/HomePageFurnishBefore.jpg";
import HomePageFurnishAfter from "./Images/HomePageFurnishAfter.jpg";
import HomePagePoolBefore from "./Images/HomePagePoolBefore.jpg";
import HomePagePoolAfter from "./Images/HomePagePoolAfter.jpg";
import HomePageRemoveWatermarkBefore from "./Images/HomePageRemoveWatermarkBefore.jpg";
import HomePageRemoveWatermarkAfter from "./Images/HomePageRemoveWatermarkAfter.jpg";
import HomePageRenovateBefore from "./Images/HomePageRenovateBefore.jpg";
import HomePageRenovateAfter from "./Images/HomePageRenovateAfter.jpg";
import HomePageRenovateExteriorBefore from "./Images/HomePageRenovateExteriorBefore.jpg";
import HomePageRenovateExteriorAfter from "./Images/HomePageRenovateExteriorAfter.jpg";
import HomeDrawFloorPlan from "./Images/HomeDrawFloorPlan.png";
import HomeRendersFromFloorPlan from "./Images/HomeRendersFromFloorPlanDe.png";
import HomeStep1 from "./Images/HomeStep1De.png";
import HomeStep2 from "./Images/HomeStep2De.png";
import HomeStep3 from "./Images/HomeStep3De.png";

// Icons
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeDe(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
    } else if (example === "Renovate") {
      setExample({
        name: "Renovate",
        beforeImage: BeforeRenovate,
        afterImage: AfterRenovate,
      });
    } else if (example === "Empty") {
      setExample({
        name: "Empty",
        beforeImage: BeforeEmpty,
        afterImage: AfterEmpty,
      });
    } else {
      setExample({
        name: "Enhance",
        beforeImage: BeforeEnhance,
        afterImage: AfterEnhance,
      });
    }
  };

  return (
    <div>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">
            Zeigen Sie das Potenzial Ihrer Immobilien{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
                borderBottom: "2px solid #ddd",
              }}
            >
              mit einem Klick
            </span>
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  Das virtuelle Home Staging Tool, nach dem Sie gesucht haben:
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Schnell:</strong> Ergebnisse in Sekunden
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Von Anfang an intuitiv
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualität:</strong> Hyperrealistische Ergebnisse
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Hilfe:</strong> Menschliche Unterstützung bei Bedarf
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Pedra testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ für 100 Bilder
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Fotograf bei aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, CEO bei Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, CEO bei Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Vor der virtuellen Leerung des Raums"}
                  altAfter={"Nach der virtuellen Leerung des Raums"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Möblieren
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Empty" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Empty")}
                    >
                      <PurpleEmptyIcon />
                      Leeren
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Renovate" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Renovate")}
                    >
                      <PurpleRenovateIcon />
                      Renovieren
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Enhance" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Enhance")}
                    >
                      <PurpleEnhanceIcon />
                      Verbessern
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="section-one-column-left">
        <div className="customer-logo-container">
          ÜBER 5.000 IMMOBILIENPROFIS VERTRAUEN BEREITS PEDRA
          <CustomerLogos />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration1}
          alt="Pedra Website Dekoration"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "1200px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                Erstellen Sie Bilder, die Ihnen helfen{" "}
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-10px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                  mehr zu verkaufen
                </span>
              </h2>
              <div className="title-secondary">
                Möblieren, renovieren, entrümpeln, verbessern usw. Das virtuelle
                Home Staging Tool, das Sie brauchen, um mit nur einem Klick
                Bilder zu erstellen, die mehr verkaufen.
              </div>
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageFurnishBefore}
                      afterImage={HomePageFurnishAfter}
                      altBefore={
                        "Vor der Möblierung des Raums mit Pedras virtuellem Home Staging Tool"
                      }
                      altAfter={
                        "Nach der Möblierung des Raums mit Pedras virtuellem Home Staging Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Einen Raum möblieren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI für virtuelles Home Staging.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEmptyBefore}
                      afterImage={HomePageEmptyAfter}
                      altBefore={"Vor der Leerung des Raums mit Pedras KI-Tool"}
                      altAfter={"Nach der Leerung des Raums mit Pedras KI-Tool"}
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Einen Raum leeren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI, um Räume mit einem Klick zu leeren.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateBefore}
                      afterImage={HomePageRenovateAfter}
                      altBefore={
                        "Vor der Renovierung des Raums mit Pedras KI-Tool"
                      }
                      altAfter={
                        "Nach der Renovierung des Raums mit Pedras KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Räume renovieren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI, um Räume automatisch zu renovieren.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateExteriorBefore}
                      afterImage={HomePageRenovateExteriorAfter}
                      altBefore={
                        "Vor der Renovierung der Außenansicht mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach der Renovierung der Außenansicht mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Außenbereiche renovieren
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um Fassaden und
                      Gärten zu transformieren.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePagePoolBefore}
                      afterImage={HomePagePoolAfter}
                      altBefore={
                        "Vor dem virtuellen Home Staging des Pools mit Pedras KI-Tool"
                      }
                      altAfter={
                        "Nach dem virtuellen Home Staging des Pools mit Pedras KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Pools reparieren</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um Pools zu
                      visualisieren und Ihren Garten zu transformieren.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageBlueSkyBefore}
                      afterImage={HomePageBlueSkyAfter}
                      altBefore={
                        "Vor dem virtuellen Home Staging des Himmels mit Pedras KI-Tool"
                      }
                      altAfter={
                        "Nach dem virtuellen Home Staging des Himmels mit Pedras KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Den Himmel blau machen
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um den Himmel
                      in Ihren Immobilienfotos zu verbessern.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRemoveWatermarkBefore}
                      afterImage={HomePageRemoveWatermarkAfter}
                      altBefore={
                        "Vor dem Entfernen des Wasserzeichens mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach dem Entfernen des Wasserzeichens mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Wasserzeichen entfernen
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras virtuelles Home Staging, um
                      Wasserzeichen aus Ihren Fotos zu entfernen.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnlightBefore}
                      afterImage={HomePageEnlightAfter}
                      altBefore={
                        "Vor der Aufhellung des Raums mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach der Aufhellung des Raums mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Räume aufhellen</h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedras KI, um die Beleuchtung in Ihren Fotos zu
                      verbessern.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnhanceBefore}
                      afterImage={HomePageEnhanceAfter}
                      altBefore={
                        "Vor der Verbesserung von Fotografie und Perspektive mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      altAfter={
                        "Nach der Verbesserung von Fotografie und Perspektive mit Pedras virtuellem Home Staging KI-Tool"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Fotos verbessern und korrigieren
                    </h2>
                    <div className="feature-text home">
                      Nutzen Sie Pedra, um Perspektiven zu korrigieren und Farbe
                      und Licht in Fotos zu verbessern
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Ausgezeichnete virtuelle Home Staging Software, sehr einfach zu
              bedienen mit großartigen Ergebnissen"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src={REMAXLogo}
                alt="REMAX Logo für Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={SoledadTerol}
                alt="Soledad Terol"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Soledad Terol</div>
                <div className="quote-author-company">Immobilienmaklerin</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration2}
          alt=""
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "800px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                <span style={{ position: "relative" }}>
                  Beschleunigen
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-8px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>{" "}
                Sie jeden Immobilienverkauf
              </h2>
              <div className="title-secondary">
                Ob Sie Immobilien vom Plan, Neubauten oder Bestandsimmobilien
                verkaufen, mit Pedra stellen Sie sicher, dass Sie in den Verkauf
                Ihrer Immobilie investieren können.
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container home">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Erstellen Sie Renderings aus einem Grundriss-Foto
                </h2>
                <div className="feature-text">
                  Laden Sie ein Grundriss-Bild hoch, um fotorealistische
                  Renderings zu erstellen. Keine architektonische Erfahrung
                  nötig, und Sie haben Ihre Ergebnisse in Sekunden.
                </div>
              </div>
              <img
                src={HomeRendersFromFloorPlan}
                alt="Virtuelle Home Staging Tools mit KI, Grundriss-Renderings - Software Screenshot"
                width="1000"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-small home">
                  <div className="feature-text-container ">
                    <OrangeVideoIcon />
                    <h2 className="feature-title ">
                      Virtuelle Videos aus Bildern
                    </h2>
                    <div className="feature-text ">
                      Erstellen Sie Berichte und Videos aus einem einzigen Bild,
                      die Ihnen helfen, Ihre Immobilie besser zu verkaufen. Und
                      das Beste: mit nur einem Klick.
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "30px",
                      marginBottom: "-8px",
                    }}
                  >
                    <video
                      width="100%"
                      height="auto"
                      playsInline
                      autoPlay
                      muted
                      loop
                      alt="Virtuelle Home Staging Software, virtuelle Videos - Video"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderLeft: "1px solid #EEEEEE",
                        borderRight: "1px solid #EEEEEE",
                        borderTop: "1px solid #EEEEEE",
                      }}
                    >
                      <source
                        src="https://pedraimages.s3.eu-west-3.amazonaws.com/HomeVirtualVideo.mp4"
                        type="video/mp4"
                      />
                      Ihr Browser unterstützt das Video-Tag nicht.
                    </video>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-small home">
                  <div className="feature-text-container">
                    <OrangePaletteIcon />
                    <h2 className="feature-title ">
                      Grundrisse in Sekunden erstellen
                    </h2>
                    <div className="feature-text ">
                      Zeichnen Sie einfach Grundrisse, um Ihre
                      Immobilienangebote besser zu positionieren.
                    </div>
                  </div>
                  <img
                    src={HomeDrawFloorPlan}
                    alt="Virtuelles Home Staging Tool, Grundrisse - Software Screenshot"
                    width="600"
                    height="400"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Ich musste eine Wohnung verkaufen, die renovierungsbedürftig war,
              und ich habe Pedra benutzt, um die Renderings in Sekunden zu
              erstellen. Die Ergebnisse waren unglaublich! Es hat mir geholfen,
              sie viel schneller zu verkaufen, jetzt empfehle ich es allen."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="EXP Logo für Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Immobilienmakler</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              Wie funktioniert es?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Schritt 1 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Bilder hochladen
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Laden Sie ein oder mehrere Immobilienbilder hoch.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Schritt 2 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transformieren
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Klicken Sie auf "Mit KI bearbeiten", um die Bilder zu
                    transformieren.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Schritt 3 von Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Warten und herunterladen
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Laden Sie Ihre KI-bearbeiteten Bilder herunter.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">"Pedra ist wunderbar"</h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={ENGELLogo}
                alt="Engel & Völkers Logo für Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={CarmeHuges}
                alt="Carme Hugues"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Carme Hugues</div>

                <div className="quote-author-company">Immobilienmaklerin</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Für 29€ pro Monat. <br />{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
              }}
            >
              Keine Vertragsbindung.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Pro Plan
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">29€</div>
                <div className="modal-product-plan-description-text-3">
                  pro Monat
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Enthält
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                  100 Bildgenerierungen
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>pro Monat</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Renovieren und umgestalten
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Räume leeren
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Möblieren
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Objekte entfernen
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Auflösung erhöhen
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Böden ändern
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Fotografie verbessern
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <VideoPricingIcon /> Virtuelle Videos generieren
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenzte Grundrisse
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenztes Projekt-Sharing
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Wasserzeichen hinzufügen
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Unbegrenzter Support
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Pedra testen
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Jederzeit kündbar.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Kaufen Sie bei Bedarf weitere Bilder für 0,20€ pro Bild.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Pedra testen <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Gründer von Pedra"
              />
              <div>
                Fragen? Senden Sie uns eine E-Mail an{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                oder vereinbaren Sie ein 15-minütiges Demo-Gespräch mit unserem
                Gründer Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  hier
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="section-one-column-left">
        <div className="title-container">Fragen und Antworten</div>
      </div>
      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Was ist KI-Rendering?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                KI-Rendering im Kontext von Architekturdesign und Visualisierung
                bezieht sich auf die Anwendung künstlicher Intelligenz (KI) zur
                Erzeugung hochwertiger gerendeter Bilder von architektonischen
                Räumen. KI wird eingesetzt, um den Rendering-Prozess zu
                verbessern und zu optimieren und ermöglicht realistischere und
                effizientere Ergebnisse.
                <br />
                <br />
                KI im Rendering ermöglicht schnellere Ergebnisse als mit
                herkömmlichen Methoden. Alte Methoden erfordern stunden- oder
                wochenlange Arbeit, da sie die Nachbildung von 3D-Modellen von
                Räumen und die Verarbeitung von Rendering-Systemen erfordern.
                Mit KI hat sich das geändert. Renderings können aus Bildern
                generiert werden und spektakuläre Ergebnisse aus dem
                Originalbild mit neuen angewandten Stilen erstellen.
                <br />
                <br />
                Künstliche Intelligenz beim Rendering von Immobilienbildern
                spart nicht nur Zeit, sondern bietet auch Flexibilität bei der
                Vorstellung von Räumen für Immobilienmakler, Eigentümer und
                Käufer. Die Technologie hilft dabei, das Potenzial von Räumen
                auf eine noch nie dagewesene Weise zu visualisieren.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Was ist virtuelles Home Staging?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Das{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  virtuelle Home Staging
                </Link>{" "}
                ist eine Technik, die im Immobilienmarkt verwendet wird, um eine
                Immobilie potenziellen Käufern oder Mietern attraktiver und
                ansprechender zu präsentieren. Im Gegensatz zum traditionellen
                Home Staging, das die physische Vorbereitung und Dekoration des
                Raums beinhaltet, erfolgt virtuelles Home Staging digital.
                <br />
                <br />
                Beim virtuellen Home Staging werden Tools wie
                3D-Design-Software, virtuelle Realität (VR) und digitale
                Fotografie verwendet, um digitale Darstellungen einer Immobilie
                zu erstellen. Diese Darstellungen können hochwertige gerenderte
                Bilder, virtuelle Rundgänge durch die Immobilie und interaktive
                Visualisierungen umfassen, die zeigen, wie der Raum mit
                verschiedenen Möbel- und Dekorationskonfigurationen aussehen
                würde.
                <br />
                <br />
                Das Ziel des virtuellen Home Stagings ist es, potenziellen
                Käufern oder Mietern eine bessere Vorstellung davon zu
                vermitteln, wie die Immobilie einmal möbliert und dekoriert
                aussehen würde, was das Interesse und die Verkaufs- oder
                Vermietungschancen erhöhen kann. Darüber hinaus kann virtuelles
                Home Staging besonders nützlich sein in Situationen, in denen
                die Immobilie nicht vollständig möbliert oder leer ist, da es
                den Kunden hilft, das Potenzial des Raums zu visualisieren.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Ist Pedra einfach zu bedienen?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Ja, Pedra ist darauf ausgelegt, einfach und schnell zu bedienen
                zu sein.
                <br />
                <br />
                Bei der Erstellung eines Projekts können Sie ein oder mehrere
                Bilder hochladen. Sie können dann Bilder auswählen, wodurch eine
                Optionsleiste angezeigt wird. Zu den Optionen gehören{" "}
                <span style={{ fontWeight: "600px" }}> Mit KI bearbeiten</span>,
                Hinzufügen eines Wasserzeichens, Herunterladen in verschiedenen
                Formaten und mehr. Die Erstellung eines Projekts gibt Ihnen auch
                die Möglichkeit, einen Grundriss zu zeichnen. Der Grundriss
                enthält mehrere Figuren, die Sie durch Auswählen, Verschieben
                oder über das Menü bearbeiten können.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Wie viel kostet Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra kostet 29€ plus Mehrwertsteuer pro Monat.
                <br />
                <br />
                Es gibt keine Vertragsbindung, Sie können also jederzeit
                kündigen.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDe;
