import React from "react";

const HDIconPricing = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5C18 4.44772 17.5523 4 17 4H3C2.44772 4 2 4.44772 2 5V15C2 15.5523 2.44772 16 3 16H17C17.5523 16 18 15.5523 18 15V5ZM4.78903 7.18182V13H6.01914V10.5966H8.5163V13H9.74357V7.18182H8.5163V9.58239H6.01914V7.18182H4.78903ZM10.7578 13H12.8203C13.4112 13 13.9188 12.8835 14.343 12.6506C14.7691 12.4176 15.0958 12.0833 15.3231 11.6477C15.5523 11.2121 15.6669 10.6913 15.6669 10.0852C15.6669 9.48106 15.5523 8.96212 15.3231 8.52841C15.0958 8.0947 14.771 7.76231 14.3487 7.53125C13.9263 7.2983 13.4226 7.18182 12.8373 7.18182H10.7578V13ZM12.7691 11.946H11.9879V8.2358H12.772C13.1356 8.2358 13.4415 8.30019 13.6896 8.42898C13.9377 8.55777 14.1243 8.75852 14.2493 9.03125C14.3761 9.30398 14.4396 9.6553 14.4396 10.0852C14.4396 10.5189 14.3761 10.8731 14.2493 11.1477C14.1243 11.4205 13.9368 11.6222 13.6868 11.7528C13.4386 11.8816 13.1328 11.946 12.7691 11.946Z"
        fill="#B15DE1"
      ></path>
    </svg>
  );
};

export default HDIconPricing;
