import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1Es from "../Images/HowToRenovateFrame1Es.jpg";
import HowToRenovateFrame2Es from "../Images/HowToRenovateFrame2Es.jpg";
import HowToRenovateFrame3Es from "../Images/HowToRenovateFrame3Es.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeSpaceExample1 from "../Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "../Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "../Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "../Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "../Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "../Images/curtain-effect/AfterSpaceStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLocalEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear renders de locales comerciales
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre cómo crear renders de locales comerciales
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Para qué sirven las herramientas de render de locales comerciales
              de Pedra?
            </h2>
            <p className="article-text">
              Las herramientas de Pedra te permiten visualizar transformaciones
              de espacios comerciales en segundos, ayudando a clientes
              potenciales o inversores a ver el verdadero potencial del espacio.
              <p style={{ marginTop: "10px" }}>
                Las renovaciones y el staging de espacios comerciales son
                cruciales para atraer inquilinos o compradores. Poder mostrar el
                potencial de un local comercial vacío o desactualizado puede
                marcar una diferencia significativa en el cierre más rápido de
                operaciones.
              </p>
              <p style={{ marginTop: "10px" }}>
                Con Pedra, ya no necesitas diseñadores de interiores
                especializados o expertos en renderizado 3D para crear
                visualizaciones comerciales convincentes.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              ¿Cómo creo un render de local comercial con Pedra?
            </h2>
            <p className="article-text">
              Comienza subiendo fotos claras del espacio comercial existente que
              deseas transformar.
              <p style={{ marginTop: "10px" }}>
                Selecciona tus imágenes y haz clic en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span>,
                luego elige la opción{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1Es}
                alt="Cómo usar la herramienta de renovación para crear renders de locales comerciales – Captura del software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Transformación rápida: </span>
              Genera un render de local comercial en segundos
            </h2>
            <p style={{ marginTop: "10px" }}>
              La herramienta de{" "}
              <span style={{ fontWeight: "500" }}>"Renovación"</span>{" "}
              transformará tu espacio comercial en aproximadamente 25 segundos.
              <p>
                Para una actualización sutil, elige el estilo "Minimalista" con
                creatividad "Baja". Esto mantendrá la distribución básica
                mientras moderniza acabados y elementos.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2Es}
                alt="Cómo usar la herramienta de renovación para crear renders de locales comerciales – Captura del software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personaliza tu render de local comercial
            </h2>
            <p className="article-text">
              Pedra ofrece amplias opciones de personalización para renders de
              espacios comerciales.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Elige entre varios estilos, especifica el tipo de espacio,
                  controla qué tan dramáticos serán los cambios y decide si
                  deseas preservar elementos existentes como ventanas o
                  características estructurales.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3Es}
                  alt="Cómo usar la herramienta de renovación para crear renders de locales comerciales – Captura del software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 1 – </span>
              Especifica el tipo de local comercial
            </h3>
            <p className="article-text">
              En el campo de tipo de espacio, introduce el tipo específico de
              local comercial con el que estás trabajando (por ejemplo,
              restaurante, bar, cafetería, tienda minorista, oficina, etc.).
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  También puedes subir imágenes de referencia de espacios
                  similares que te gusten.
                </span>{" "}
                Esto te permite crear renders que coincidan con una inspiración
                específica o con la estética preferida de tu cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 2 – </span>
              Intensidad de la renovación
            </h3>
            <p className="article-text">
              Elige entre niveles de creatividad Baja, Media, Alta y Muy Alta
              para tu transformación de espacio comercial.
              <p style={{ marginTop: "10px" }}>
                Los niveles Bajo y Medio actualizarán acabados y elementos
                manteniendo la distribución básica intacta.
              </p>
              <p style={{ marginTop: "10px" }}>
                Los niveles Alto y Muy Alto pueden reimaginar completamente el
                espacio con nuevas distribuciones, configuraciones de mobiliario
                y cambios arquitectónicos.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 3 – </span>
              Configurar ajustes de preservación
            </h3>
            <p className="article-text">
              Elige preservar ventanas y elementos estructurales, lo cual es
              particularmente importante para espacios comerciales con
              características arquitectónicas específicas o requisitos de
              iluminación. Esto asegura que tu render mantenga elementos
              prácticos mientras actualiza la estética.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 4 – </span>
              Generar tu render de espacio comercial
            </h2>
            <p>
              Después de seleccionar tus preferencias, genera el render del
              espacio comercial. Los renders estándar tardan unos 15 segundos,
              mientras que preservar elementos específicos puede llevar un poco
              más de tiempo.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Consejos para mejores renders de espacios comerciales
                </h4>
                <p className="callout-p">
                  Para obtener los renders de espacios comerciales más realistas
                  y útiles:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Asegúrate de que "Amueblar" esté activado
                      </span>{" "}
                      antes de hacer clic en generar imagen. Esto garantiza que
                      tu espacio estará correctamente amueblado según su tipo.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Sé específico con el tipo de espacio
                      </span>
                      . En lugar de solo "restaurante", especifica "restaurante
                      italiano moderno" o "café casual" para resultados más
                      precisos.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Genera múltiples versiones
                      </span>
                      . Cada render es único, y podrías encontrar algunos que
                      preserven mejor las características importantes o se
                      ajusten mejor a tu visión.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Elimina el desorden y elementos temporales antes de
                        renderizar
                      </span>
                      . Esto ayuda a la IA a comprender mejor el espacio y crear
                      transformaciones más precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Ejemplos de renders de espacios comerciales
            </h2>
            <p className="article-text">
              Aquí hay varios ejemplos de transformaciones de espacios
              comerciales creadas con nuestros renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeSpaceExample1}
              afterImage={AfterSpaceExample1}
              altBefore={"Local comercial antes del render"}
              altAfter={"Local comercial después del render"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample2}
              afterImage={AfterSpaceExample2}
              altBefore={"Local comercial antes del render"}
              altAfter={"Local comercial después del render"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample3}
              afterImage={AfterSpaceExample3}
              altBefore={"Local comercial antes del render"}
              altAfter={"Local comercial después del render"}
            />
            <br />
            <h2 className="article-subtitleh2">
              Video de cómo crear un render para locales comerciales
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/I2H_m43y9SY?si=BP4_sWPmXbH414iF"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLocalEs;
