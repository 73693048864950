import React from "react";

const PurpleFurnishIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.53262 9.11892C7.10561 9.11215 12.1374 9.11892 12.1374 9.11892L14 3H14.5L13.3815 10.6395L14.5 17H14L12.1374 11H6.53262L6 17H5.50229V10.3149C5.46196 9.82682 5.95962 9.12568 6.53262 9.11892Z"
        fill="#B15DE1"
        stroke="#B15DE1"
        stroke-width="1.4"
      />
    </svg>
  );
};

export default PurpleFurnishIcon;
