import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1En from "../Images/HowToHomeStageFrame1Es.jpg";
import HowToHomeStageFrame2En from "../Images/HowToHomeStageFrame2.jpg";
import HowToHomeStageFrame3En from "../Images/HowToHomeStageFrame3.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import Example1Before from "../Images/Example1BeforeHomeStaging.jpg";
import Example1After from "../Images/Example1AfterHomeStaging.jpg";
import Example2Before from "../Images/Example2BeforeHomeStaging.jpg";
import Example2After from "../Images/Example2AfterHomeStaging.jpg";
import Example3Before from "../Images/Example3BeforeHomeStaging.jpg";
import Example3After from "../Images/Example3AfterHomeStaging.jpg";
import Example4Before from "../Images/curtain-effect-hero/BeforeBedroomStaging.png";
import Example4After from "../Images/curtain-effect-hero/AfterBedroomStaging.png";
import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie virtuelles Home Staging
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung von virtuellem Home Staging
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich virtuelles Home Staging erstellen?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie virtuelles Home Staging mit nur wenigen
              Klicks erstellen.
              <p>
                Öffnen Sie zunächst das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>. Wählen
                Sie den Raumtyp aus, und in etwa 2 bis 3 Minuten haben Sie Ihr
                virtuelles Home Staging-Bild zum Download bereit.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1En}
              alt="So erstellen Sie virtuelles Home Staging – Beispiele"
            />
            <br />
            <h2 className="article-subtitleh2">
              Wie erstellt man virtuelles Home Staging für Neubauten?
            </h2>
            <p className="article-text">
              Wenn Sie eine unmöblierte Neubauwohnung haben, können Sie Pedras
              virtuelles Home Staging nutzen, um sie für potenzielle Käufer
              attraktiver zu machen und schneller zu verkaufen.
            </p>
            <p className="article-text">
              Um Ihr{" "}
              <Link className="article-link" to="/home-staging-virtual">
                {" "}
                virtuelles Home Staging
              </Link>{" "}
              zu erstellen, folgen Sie diesen einfachen Schritten:
            </p>
            <ol>
              <li>
                Laden Sie die Bilder hoch, für die Sie virtuelles Home Staging
                erstellen möchten
              </li>
              <li>
                Wählen Sie eines davon aus, um mit der Bearbeitung zu beginnen
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2En}
              alt="Menü zur Erstellung von virtuellem Home Staging"
            />
            <p className="article-text">
              Unter <span style={{ fontWeight: "500" }}>"Möblieren"</span>{" "}
              müssen Sie diese Schritte befolgen:
            </p>
            <ol>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>Möbelposition</span>. Wenn
                Sie <span style={{ fontWeight: "500" }}>"Auto"</span> wählen,
                entscheidet Pedra, wo die Möbel platziert werden und wie viel
                Platz sie einnehmen. Wenn Sie{" "}
                <span style={{ fontWeight: "500" }}>"Zeichnen"</span> wählen,
                können Sie auf dem Bild markieren, wo die Möbel erscheinen
                sollen.
              </li>
              <li>
                Wählen Sie den{" "}
                <span style={{ fontWeight: "500" }}>Raumtyp</span>. Wenn Sie
                Schlafzimmer wählen, platziert Pedra Schlafzimmermöbel: Bett,
                Nachttisch usw. Wenn Sie Wohnzimmer wählen, platziert Pedra
                Sofas, TV, Teppich usw.
              </li>
              <li>
                Wählen Sie den <span style={{ fontWeight: "500" }}>Stil</span>.
                Der Stil bestimmt die Art der Möbel, die dem Raum hinzugefügt
                werden.
              </li>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Anzahl der zu generierenden Bilder
                </span>
                . Wir empfehlen, 2 oder 3 Bilder zu generieren, damit Sie das
                beste auswählen können.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Häufige Probleme bei der Erstellung von virtuellem Home Staging:
                <ul>
                  <li>
                    Bei sehr schlechter Bildqualität können die Ergebnisse
                    unbefriedigend sein
                  </li>
                  <li>
                    Wenn die Decke nicht sichtbar ist, können die Möbel
                    unverhältnismäßig erscheinen
                  </li>
                  <li>
                    Die "Auto"-Option kann manchmal ungenau sein. Wenn "Auto"
                    nicht gut funktioniert, verwenden Sie "Zeichnen". Mit
                    "Zeichnen" können Sie angeben, welche Bereiche möbliert
                    werden sollen.
                    <strong>
                      {" "}
                      Beachten Sie, dass nur die gezeichneten Bereiche möbliert
                      werden.
                    </strong>
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Beispiel für die Erstellung von virtuellem Home Staging"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Wie erstellt man virtuelles Home Staging für Bestandsimmobilien?
            </h2>
            <p className="article-text">
              Bei einer Bestandsimmobilie, ob möbliert oder unmöbliert,
              empfehlen wir die Option{" "}
              <span style={{ fontWeight: "500" }}>"Renovieren"</span>.
            </p>
            <p className="article-text">
              Um Ihr virtuelles Home Staging für eine Bestandsimmobilie zu
              erstellen, folgen Sie diesen einfachen Schritten:
            </p>
            <ol>
              <li>
                Laden Sie die Bilder hoch, für die Sie virtuelles Home Staging
                erstellen möchten
              </li>
              <li>
                Wählen Sie eines davon aus, um mit der Bearbeitung zu beginnen
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Renovieren"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame3En}
              alt="Menü zur Erstellung von virtuellem Home Staging für Bestandsimmobilien"
            />
            <p className="article-text">
              Unter <span style={{ fontWeight: "500" }}>"Renovieren"</span>{" "}
              müssen Sie diese Schritte befolgen:
            </p>
            <ol>
              <li>
                Wählen Sie den Stil. Wir empfehlen "Skandinavisch" oder
                "Minimalistisch" für neutralere Ergebnisse. Optional können Sie
                auch ein Bild des gewünschten Stils hochladen und eine
                Renovierung basierend auf diesem Stil durchführen.
              </li>
              <li>
                Passen Sie die Details an:
                <ul>
                  <li>
                    <span style={{ fontWeight: "500" }}>
                      Fenster beibehalten:
                    </span>{" "}
                    Wählen Sie, ob die Aussicht durch die Fenster erhalten
                    bleiben soll
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Möblieren:</span> Wählen
                    Sie, ob der Raum möbliert werden soll. Da wir virtuelles
                    Home Staging erstellen, wählen wir ja. Dies fügt dem Raum
                    Möbel hinzu. Bei möblierten Immobilien wird die Einrichtung
                    verändert.
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Raumtyp:</span> Wählen
                    Sie aus. In diesem Fall belassen wir es bei "Auto". Bei
                    "Auto" interpretiert Pedra den Raumtyp und möbliert
                    entsprechend. Wenn wir möchten, dass ein Foto mit
                    Wohnzimmermöbeln ausgestattet wird, sollten wir Wohnzimmer
                    wählen
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Kreativität:</span> Die
                    Kreativität beeinflusst das Ausmaß der architektonischen
                    Veränderungen. Wir empfehlen, sie niedrig zu halten. So
                    vermeiden wir aggressive Veränderungen an der Architektur
                    des Raums.
                  </li>
                </ul>
              </li>
            </ol>
            <br />
            <h2 className="article-subtitleh2">
              Warum sollte ich virtuelles Home Staging erstellen?
            </h2>
            <p className="article-text">
              Der erste Kontakt eines potenziellen Käufers mit einer Immobilie
              findet im Internet statt. Deshalb ist es äußerst wichtig, dass der
              erste Eindruck positive Gefühle und Interesse weckt.
            </p>
            <p className="article-text">
              <Link className="article-link" to="/home-staging-virtual">
                Virtuelles Home Staging
              </Link>{" "}
              hilft dabei, positive Gefühle zu erzeugen. Mit gutem virtuellem
              Home Staging können Sie leere Räume zum Leben erwecken oder die
              beste Version einer renovierungsbedürftigen Immobilie zeigen. Es
              ist ein wichtiger Helfer beim schnellen Verkauf von Immobilien.
            </p>
            <br />
            <h2 className="article-subtitleh2">
              Beispiele für virtuelles Home Staging
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für virtuelles Home Staging, das mit
              Pedra erstellt wurde:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingDe;
