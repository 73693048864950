// 404Page.js
import React from "react";

const NotFoundDe = () => {
  return (
    <section className="section-first-wrapper">
      <div>
        <h1>404 - Seite nicht gefunden</h1>
        <p>Entschuldigung, die gesuchte Seite existiert nicht.</p>
      </div>
    </section>
  );
};

export default NotFoundDe;
