import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageKitchenFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingKitchenDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie virtuelles Home Staging für Küchen
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung von virtuellem Home Staging für Küchenräume
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich virtuelles Home Staging für eine Küche erstellen?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie virtuelles Home Staging für Küchen mit nur
              wenigen Klicks erstellen.
              <p>
                Öffnen Sie zunächst das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>. Wählen
                Sie "Küche" als Raumtyp aus, und in etwa 2 bis 3 Minuten haben
                Sie Ihr virtuelles Home Staging-Bild der Küche zum Download
                bereit.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="So erstellen Sie virtuelles Home Staging für eine Küche – Beispiele"
            />
            <br />
            <h2 className="article-subtitleh2">
              Wie erstellt man virtuelles Home Staging für Küchen?
            </h2>
            <p className="article-text">
              Um Ihr{" "}
              <Link className="article-link" to="/home-staging-kitchen">
                virtuelles Home Staging für eine Küche
              </Link>
              zu erstellen, folgen Sie diesen einfachen Schritten:
            </p>
            <ol>
              <li>
                Laden Sie das Bild der Küche hoch, die Sie virtuell einrichten
                möchten
              </li>
              <li>
                Wählen Sie das Bild aus, um mit der Bearbeitung zu beginnen
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menü zur Erstellung von virtuellem Home Staging für eine Küche"
            />
            <p className="article-text">
              Innerhalb von{" "}
              <span style={{ fontWeight: "500" }}>"Möblieren"</span> müssen Sie
              diese Schritte befolgen:
            </p>
            <ol>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Position der Einrichtungselemente
                </span>
                . Für Küchen empfehlen wir die Verwendung von "Zeichnen", um
                bestimmte Bereiche für Geräte, Dekoration und Zubehör zu
                markieren.
              </li>
              <li>
                Wählen Sie <span style={{ fontWeight: "500" }}>"Küche"</span>{" "}
                als Raumtyp aus. Dies stellt sicher, dass Pedra passende
                Küchenelemente wie Geräte, Kochutensilien und
                Dekorationselemente platziert.
              </li>
              <li>
                Wählen Sie den <span style={{ fontWeight: "500" }}>Stil</span>.
                Für Küchen funktionieren Stile wie "Modern", "Zeitgenössisch"
                oder "Traditionell" normalerweise gut.
              </li>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Anzahl der zu generierenden Bilder
                </span>
                . Wir empfehlen, 2 oder 3 Bilder zu wählen, um verschiedene
                Küchendesign-Optionen zu haben.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für besseres virtuelles Home Staging einer Küche:
                <ul>
                  <li>
                    Stellen Sie sicher, dass das Foto das gesamte Küchenlayout
                    deutlich zeigt, für eine genauere Einrichtung
                  </li>
                  <li>
                    Erfassen Sie alle besonderen Merkmale wie Inseln, Fenster
                    oder architektonische Details
                  </li>
                  <li>
                    Nutzen Sie die "Zeichnen"-Option, um spezifische Bereiche
                    für Geräte, Arbeitsplatten-Accessoires oder
                    Dekorationselemente zu markieren
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Beispiel für die Erstellung von virtuellem Home Staging für eine Küche"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Warum virtuelles Home Staging für eine Küche?
            </h2>
            <p className="article-text">
              Die Küche wird oft als das Herz des Hauses betrachtet und kann ein
              wichtiger Verkaufspunkt sein. Virtuelles Home Staging für Küchen
              ermöglicht es Ihnen, das volle Potenzial des Raums zu präsentieren
              und warme und funktionale Umgebungen zu schaffen, die Käufern
              helfen, sich selbst beim Kochen und Unterhalten darin
              vorzustellen.
            </p>
            <p className="article-text">
              Mit gutem virtuellem Home Staging für eine Küche können Sie:
            </p>
            <ul>
              <li>
                Leere oder veraltete Küchen in einladende Räume verwandeln
              </li>
              <li>
                Die Funktionalität und den Arbeitsablauf des Layouts hervorheben
              </li>
              <li>
                Aufbewahrungslösungen und Arbeitsflächenpotenzial präsentieren
              </li>
              <li>
                Käufern helfen, sich die Zubereitung von Mahlzeiten und
                Unterhaltung im Raum vorzustellen
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Beispiele für virtuelles Home Staging von Küchen
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für virtuelles Home Staging, das auf
              Küchen angewendet werden kann:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingKitchenDe;
