import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageSalonFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingLivingRoomDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie virtuelles Home Staging für Wohnzimmer
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung von virtuellem Home Staging für Wohnräume
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich virtuelles Home Staging für ein Wohnzimmer erstellen?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie virtuelles Home Staging für Wohnzimmer mit
              nur wenigen Klicks erstellen.
              <p>
                Öffnen Sie zunächst das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>. Wählen
                Sie "Wohnzimmer" als Raumtyp aus, und in etwa 2 bis 3 Minuten
                haben Sie Ihr virtuelles Home Staging-Bild des Wohnzimmers zum
                Download bereit.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="So erstellen Sie virtuelles Home Staging für ein Wohnzimmer – Beispiele"
            />
            <br />
            <h2 className="article-subtitleh2">
              Wie erstellt man virtuelles Home Staging für Wohnzimmer?
            </h2>
            <p className="article-text">
              Um Ihr{" "}
              <Link className="article-link" to="/home-staging-living-room">
                virtuelles Home Staging für ein Wohnzimmer
              </Link>
              zu erreichen, folgen Sie diesen einfachen Schritten:
            </p>
            <ol>
              <li>
                Laden Sie das Bild des Wohnzimmers hoch, das Sie virtuell
                einrichten möchten
              </li>
              <li>Wählen Sie das Bild aus, um es zu bearbeiten</li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menü zur Erstellung von virtuellem Home Staging für ein Wohnzimmer"
            />
            <p className="article-text">
              Innerhalb von{" "}
              <span style={{ fontWeight: "500" }}>"Möblieren"</span> müssen Sie
              diese Schritte befolgen:
            </p>
            <ol>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>Möbelposition</span>. Für
                Wohnzimmer empfehlen wir "Zeichnen", um bestimmte Bereiche für
                Sofa, Couchtisch, TV-Möbel usw. anzuzeigen.
              </li>
              <li>
                Wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Wohnzimmer"</span> als
                Raumtyp. Dies stellt sicher, dass Pedra passende Möbel wie
                Sofas, Sessel, Couchtische usw. platziert.
              </li>
              <li>
                Wählen Sie den <span style={{ fontWeight: "500" }}>Stil</span>.
                Für Wohnzimmer funktionieren Stile wie "Modern",
                "Zeitgenössisch" oder "Skandinavisch" normalerweise gut.
              </li>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Anzahl der zu generierenden Bilder
                </span>
                . Wir empfehlen 2 oder 3 Bilder, um verschiedene
                Wohnzimmerdesign-Optionen zu haben.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für besseres virtuelles Home Staging eines Wohnzimmers:
                <ul>
                  <li>
                    Stellen Sie sicher, dass die Fotografie die Abmessungen des
                    Wohnzimmers für eine genauere Möblierung klar zeigt
                  </li>
                  <li>
                    Wenn das Wohnzimmer besondere Merkmale wie Kamine oder große
                    Fenster hat, versuchen Sie diese im Foto einzufangen für ein
                    attraktiveres Ergebnis
                  </li>
                  <li>
                    Nutzen Sie die "Zeichnen"-Option, um spezifische Bereiche
                    für Sitzbereich, Essbereich und dekorative Elemente
                    anzuzeigen
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Beispiel für die Erstellung von virtuellem Home Staging für ein Wohnzimmer"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Warum virtuelles Home Staging für ein Wohnzimmer erstellen?
            </h2>
            <p className="article-text">
              Ein gut präsentiertes Wohnzimmer kann ein entscheidender Faktor
              für potenzielle Käufer sein. Virtuelles Home Staging für
              Wohnzimmer ermöglicht es Ihnen, das Potenzial dieser Wohnräume zu
              zeigen, indem Sie einladende und funktionale Umgebungen schaffen,
              die den Käufern helfen sich vorzustellen, wie sie dort
              Familienzeit verbringen und Freunde unterhalten können.
            </p>
            <p className="article-text">
              Mit gutem virtuellem Home Staging für ein Wohnzimmer können Sie:
            </p>
            <ul>
              <li>Das Potenzial leerer oder unattraktiver Räume zeigen</li>
              <li>Eine einladende und gesellige Atmosphäre schaffen</li>
              <li>
                Besonderheiten wie Helligkeit, Layout oder die Vielseitigkeit
                des Wohnzimmers hervorheben
              </li>
              <li>
                Käufern helfen, sich Momente des Zusammenseins und der
                Unterhaltung im Wohnzimmer vorzustellen
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Beispiele für virtuelles Home Staging für Wohnzimmer
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für virtuelles Home Staging, das auf
              Wohnzimmer angewendet werden kann:
            </p>
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingLivingRoomDe;
