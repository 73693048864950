import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import HowToCurtainEffectImage from "../Images/HowToCurtainEffectImage.jpg";

import CurtainEffect from "../CurtainEffect";

function HowToCurtainEffect() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create Before & After Curtain Effects for Virtual Home
            Staging
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to create before/after curtain effects for virtual
            staging
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              What is a curtain effect and why is it useful?
            </h2>
            <p className="article-text">
              A curtain effect is an interactive way to showcase before and
              after images of virtual home staging. It allows viewers to slide
              between the original and staged versions of a space, making it
              easy to appreciate the transformation.
            </p>
            <p className="article-text">
              This effect is particularly valuable for real estate professionals
              because it:
            </p>
            <ul>
              <li>
                Demonstrates the impact of virtual staging in a compelling way
              </li>
              <li>Helps clients understand the potential of empty spaces</li>
              <li>Creates engaging content for marketing materials</li>
              <li>
                Builds trust by showing the original space alongside
                improvements
              </li>
            </ul>

            <h2 className="article-subtitleh2">
              How to create a curtain effect with Pedra
            </h2>
            <p className="article-text">
              Traditionally, creating a curtain effect required technical
              knowledge and programming skills. However, with Pedra, you can
              create one in just a click.
            </p>
            <p className="article-text">
              Once you have created your virtual staging image, simply:
            </p>
            <ol>
              <li>Click on your virtually staged image</li>
              <li>
                Click on "Curtain effect" - this will automatically generate a
                shareable link showing your before/after comparison with an
                interactive slider
              </li>
            </ol>

            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToCurtainEffectImage}
              alt="How to create a curtain effect - Screenshot of Pedra software"
            />

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for effective curtain effects:
                <ul>
                  <li>Use high-quality images for the best presentation</li>
                  <li>
                    Share the curtain effect link on your website or social
                    media to showcase your virtual staging work
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">Examples of curtain effects</h2>
            <p className="article-text">
              Here are several examples of curtain effects showing virtual
              staging transformations:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Empty terrace before virtual staging"}
              altAfter={"Furnished terrace after virtual staging"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Empty terrace before virtual staging"}
              altAfter={"Furnished terrace after virtual staging"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Empty terrace before virtual staging"}
              altAfter={"Furnished terrace after virtual staging"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Empty terrace before virtual staging"}
              altAfter={"Furnished terrace after virtual staging"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToCurtainEffect;
