import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseNov2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseNov2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseNov24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">NOVEMBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: Team-Zusammenarbeit und Branchenanerkennung
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              In diesem November bringen wir Ihnen Updates zur Zusammenarbeit
              mit Ihrem Team und eine Auszeichnung, auf die wir stolz sind:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Laden Sie Mitglieder in Ihren Arbeitsbereich ein
              </h2>
              Jetzt können Sie andere Mitglieder in Ihren Arbeitsbereich
              einladen, damit verschiedene Personen aus Ihrer Agentur in Pedra
              arbeiten können. Schreiben Sie einfach ihre E-Mail-Adresse und
              laden Sie sie ein.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Arbeitsbereich-Einladungsfunktion"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                2024 Immobilien-KI-Innovationspreis
              </h2>
              Der Immobilienmaklerverband hat uns den KI-Innovationspreis für
              den Immobiliensektor verliehen. Wir erhielten 56% der Stimmen von
              600 Maklern.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Innovationspreis"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>
            <div>
              <li>
                Option zum Renovieren und Möblieren für "Küche und Esszimmer"
                und "Küche und Wohnzimmer"
              </li>
              <li>Schnelleres Leeren von Räumen</li>
              <li>
                Option zur Beauftragung eines professionellen virtuellen
                Raum-Leer-Services für 4€ pro Bild
              </li>
              <li>Optimierung der heruntergeladenen Bildgrößen</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseNov24De;
