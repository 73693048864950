import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

import BeforeKitchen from "./Images/curtain-effect/BeforeInteriorDesignAI2.png";
import AfterKitchen from "./Images/curtain-effect/AfterInteriorDesignAI2.png";

import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeInteriorDesignAI1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterInteriorDesignAI1.png";

import BeforeKitchenStaging2 from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterKitchenStaging2 from "./Images/curtain-effect-hero/AfterFurnish.png";

import BeforeKitchenStaging3 from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterKitchenStaging3 from "./Images/curtain-effect-hero/AfterTerraceStaging.png";

import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function InteriorDesignDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const handleExample = (example) => {
    switch (example) {
      case "Modern":
        setExample({
          name: "Modern",
          beforeImage: BeforeKitchenStaging1,
          afterImage: AfterKitchenStaging1,
        });
        break;
      case "Minimalist":
        setExample({
          name: "Minimalist",
          beforeImage: BeforeKitchenStaging2,
          afterImage: AfterKitchenStaging2,
        });
        break;
      case "Industrial":
        setExample({
          name: "Industrial",
          beforeImage: BeforeKitchenStaging3,
          afterImage: AfterKitchenStaging3,
        });
        break;
      default:
        setExample({
          name: "Classic",
          beforeImage: BeforeKitchen,
          afterImage: AfterKitchen,
        });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Was ist KI-Innenarchitektur?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "KI-Innenarchitektur verbindet traditionelle Designkreativität mit künstlicher Intelligenz, um Räume effizient zu optimieren und zu visualisieren. Die KI analysiert Ihren Raum und Ihre Präferenzen, um sofort personalisierte und professionelle Designs zu generieren.",
        },
      },
      {
        "@type": "Question",
        name: "Welche Arten von Räumen kann ich gestalten?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Sie können Wohnräume (Wohnzimmer, Schlafzimmer, Küchen), Geschäftsräume (Büros, Geschäfte, Restaurants), öffentliche Räume (Museen, Bibliotheken) und Spezialräume (Praxen, Fitnessstudios, Hotels) gestalten.",
        },
      },
      {
        "@type": "Question",
        name: "Was sind die Vorteile von KI-Design?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Die Vorteile umfassen sofortige Visualisierung von Änderungen, mehrere Designoptionen in Sekunden, intelligente Raumoptimierung, detaillierte Personalisierung, Zeit- und Kostenersparnis und professionelle Ergebnisse ohne Vorerfahrung.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">KI-Innenarchitektur</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Verwandeln Sie Ihre Räume mit intelligenter Innenarchitektur
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Sofort:</strong> Visualisieren Sie Änderungen in
                    Echtzeit
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Einfach:</strong> Keine technischen Kenntnisse
                    erforderlich
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Professionell:</strong> Hochwertige Ergebnisse
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Personalisiert:</strong> An Ihre Präferenzen
                    angepasst
                  </div>
                </div>

                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        KI-Design testen <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 pro Monat, keine Bindung
                      </div>
                    </div>
                  </div>

                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ Bewertungen
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Vor der Inneneinrichtung"
                  altAfter="Nach der Inneneinrichtung"
                />

                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Schlafzimmer
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Minimalist" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Minimalist")}
                    >
                      Wohnzimmer
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Industrial" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Industrial")}
                    >
                      Terrasse
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Classic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Classic")}
                    >
                      Küche
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          ÜBER 5.000 PROFIS VERTRAUEN BEREITS AUF PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Erstellen Sie professionelle Designs{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatisch
          </span>{" "}
          in Sekunden.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Mehrere Räume gestalten</h3>
                <div className="feature-text">
                  Generieren Sie gleichzeitig Designs für mehrere Räume
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Eigenen Stil hochladen</h3>
                <div className="feature-text">
                  Laden Sie Bilder Ihrer eigenen Stile hoch und die KI gestaltet
                  Ihre Fotos basierend auf diesen Stilen
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Raumoptimierung</h3>
                <div className="feature-text">Intelligente Möbelverteilung</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Automatische Bereinigung</h3>
                <div className="feature-text">
                  Entfernen Sie unerwünschte Objekte aus der Szene
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Vollständige Anpassung</h3>
                <div className="feature-text">
                  Passen Sie jedes Detail nach Ihrem Geschmack an
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Design in Sekunden</h3>
                <div className="feature-text">
                  Generieren Sie KI-Designvorschläge in Sekunden
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Wie funktioniert KI-Innenarchitektur?
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 1
            </h5>
            <h2>Laden Sie ein Bild Ihres Raums hoch</h2>
            <div className="value-prop-text">
              Beginnen Sie mit dem Hochladen eines Fotos des Raums, den Sie neu
              gestalten möchten. Unsere Software analysiert die Struktur,
              Beleuchtung und Schlüsselelemente.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Wie man Fotos für KI-Innenarchitektur hochlädt"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 2
            </h5>
            <h2>Wählen Sie Ihren bevorzugten Stil</h2>
            <div className="value-prop-text">
              Wählen Sie aus verschiedenen Dekorationsstilen und passen Sie
              Farben, Materialien und dekorative Elemente nach Ihren Vorlieben
              an.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Auswahl der Inneneinrichtungsstile"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              SCHRITT 3
            </h5>
            <h2>Erhalten Sie Ihr Design sofort</h2>
            <div className="value-prop-text">
              Erhalten Sie professionelle Visualisierungen Ihres umgestalteten
              Raums. Laden Sie Ihre Designs herunter und verwenden Sie sie
              sofort.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Endergebnis der Inneneinrichtung"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Professionelle Innenarchitektur für €29 pro Monat. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Keine Bindung erforderlich.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                pro Monat
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Enthält</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 Designs
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>pro Monat</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Sofortige Raumgestaltung
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Wählen Sie Ihre eigenen Designstile
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Intelligente Möbelanordnung
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Automatische Szenenbereinigung
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Hochauflösende Visualisierungen
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Materialien und Texturen ändern
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Professionelle Beleuchtung
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenzte Projekte
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unbegrenztes Teilen
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Prioritäts-Support
            </div>

            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                KI-Design testen
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Jederzeit kündbar.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Zusätzliche Designs für je €0,20 verfügbar.
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Häufig gestellte Fragen zur KI-Innenarchitektur
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Was ist KI-gestützte Innenarchitektur?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                KI-Innenarchitektur ist ein aufstrebender Trend, der
                traditionelle Designkreativität mit den analytischen und
                prädiktiven Fähigkeiten der KI verbindet. Die Technologie
                analysiert Räume und Präferenzen, um sofort personalisierte
                Designs zu generieren.
                <br />
                <br />
                Unser System kann:
                <br />
                • Designs automatisch generieren
                <br />
                • Raumverteilung optimieren
                <br />
                • Farb- und Materialkombinationen vorschlagen
                <br />• Fotorealistische Visualisierungen erstellen
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Welche Arten von Räumen kann ich gestalten?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Unser Tool kann verschiedene Räume gestalten:
                <br />
                <br />
                <strong>Wohnräume:</strong>
                <br />
                • Wohn- und Esszimmer
                <br />
                • Schlafzimmer
                <br />
                • Küchen und Badezimmer
                <br />
                • Außenbereiche und Gärten
                <br />
                <br />
                <strong>Geschäftsräume:</strong>
                <br />
                • Büros
                <br />
                • Geschäfte und Einzelhandelsflächen
                <br />
                • Restaurants und Cafés
                <br />
                <br />
                <strong>Öffentliche Räume:</strong>
                <br />
                • Museen und Galerien
                <br />
                • Bibliotheken und Bildungseinrichtungen
                <br />• Sportanlagen
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Was sind die Vorteile von KI in der Innenarchitektur?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Die wichtigsten Vorteile sind:
                <br />
                <br />
                <strong>Effizienz und Geschwindigkeit</strong>
                <br />
                • Sofortige Designgenerierung
                <br />
                • Automatisierung wiederkehrender Aufgaben
                <br />
                • Sofortige Visualisierung von Änderungen
                <br />
                <br />
                <strong>Präzision und Optimierung</strong>
                <br />
                • Detaillierte Raumanalyse
                <br />
                • Optimale Elementverteilung
                <br />
                • Reduzierung von Designfehlern
                <br />
                <br />
                <strong>Personalisierung</strong>
                <br />
                • Anpassung an individuelle Präferenzen
                <br />
                • Mehrere Stiloptionen
                <br />• Empfehlungen basierend auf der Raumnutzung
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesignDe;
