import React from "react";
import CheckIcon from "./Icons/CheckIcon";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple";
import EmptyIcon from "./Icons/EmptyIcon";
import HammerIconPricing from "./Icons/HammerIconPricing";
import BrushIconPricing from "./Icons/BrushIconPricing";
import HDIconPricing from "./Icons/HDIconPricing";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon";
import VideoPricingIcon from "./Icons/VideoPricingIcon";
import CustomerLogos from "./CustomerLogos";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function PricingPt() {
  return (
    <div
      className="section-one-column-left"
      style={{ display: "flex", flexDirection: "column", gap: "0px" }}
    >
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container" style={{ gap: "0px" }}>
            <div
              className="section-first-title"
              style={{ paddingBottom: "0px" }}
            >
              Experimente nosso plano Pro
            </div>
            <h1 className="section-first-text" style={{ fontSize: "20px" }}>
              Pague por mês,
              <span style={{ color: "#F8792A" }}> cancele quando quiser.</span>
            </h1>
            <div className="section-first-button-container"></div>
          </div>
        </section>
      </section>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plano Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                por mês
              </div>
            </div>
          </div>
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclui</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 gerações de imagens
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mês</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovar e redecorar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Esvaziar ambientes
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Mobiliar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remover objetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumentar a resolução
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Mudar paredes e pisos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Melhorar a fotografia
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Gerar vídeos virtuais
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plantas baixas ilimitadas
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartilhamentos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Adicionar marcas d'água
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Suporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente a Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancele quando quiser.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compre mais imagens por 0,20 € por imagem se precisar.
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="section-one-column-left" style={{ marginBottom: "0px" }}>
        <div className="customer-logo-container">
          MAIS DE 5.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Vocês pensaram em tudo! A Pedra tem tudo o que precisamos!"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Logo da Vives Homes para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingPt;
