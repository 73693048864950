import React from "react";
import { Helmet } from "react-helmet";
import ReleaseNov2024Frame1 from "../Images/ReleaseDec2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseDec2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseEsDec24() {
  const title =
    "Pedra: Nuevas funcionalidades para obra nueva y locales comerciales";
  const description =
    "Novedades de Pedra: crear renders a partir de planos y reforma y amueblamiento de locales comerciales";
  const imageUrl =
    "https://pedraimages.s3.eu-west-3.amazonaws.com/ReleaseAug2024.png";
  const canonicalUrl = "https://pedra.com/es/releases/obra-nueva-locales";

  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* OpenGraph / Facebook */}
        <title>{title}</title>
        <meta name="description" content={description} data-rh="true" />
        <link rel="canonical" href={canonicalUrl} data-rh="true" />

        <meta property="og:url" content={canonicalUrl} data-rh="true" />
        <meta property="og:type" content="article" data-rh="true" />
        <meta property="og:title" content={title} data-rh="true" />
        <meta property="og:description" content={description} data-rh="true" />
        <meta property="og:image" content={imageUrl} data-rh="true" />

        <meta
          name="twitter:card"
          content="summary_large_image"
          data-rh="true"
        />
        <meta name="twitter:title" content={title} data-rh="true" />
        <meta name="twitter:description" content={description} data-rh="true" />
        <meta name="twitter:image" content={imageUrl} data-rh="true" />

        {/* Twitter */}
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-rh="true"
        />
        <meta
          name="twitter:title"
          content="Pedra: Colaboración en equipo y reconocimiento del sector"
          data-rh="true"
        />
        <meta
          name="twitter:description"
          content="Novedades de Pedra: invitación a miembros del equipo y premio de innovación IA para el sector inmobiliario 2024"
          data-rh="true"
        />
        <meta
          name="twitter:image"
          content={
            "https://pedraimages.s3.eu-west-3.amazonaws.com/ReleaseAug2024.png"
          }
          data-rh="true"
        />
      </Helmet>
      <div style={{ justifyContent: "center" }}>
        <div className="section-one-column-left">
          <div
            className="title-container article"
            style={{ marginTop: "60px" }}
          >
            <Link className="go-back" to="/releases">
              &#8592; Releases
            </Link>
            <br />

            <div className="title-secondary gray small">DICIEMBRE 2024</div>
            <h1 className="article-titleh1">
              Pedra: Nuevas funcionalidades para obra nueva y locales
              comerciales
            </h1>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Felix Ingla"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador de Pedra
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="step-container article">
            <div>
              <div className="article-text">
                Acabamos el año trayendo mejoras para que Pedra os pueda ayudar
                en más tipos de ventas de inmuebles :)
              </div>
              <br />
              <div className="article-text">
                <h2 className="article-subtitleh2">
                  Crear renders a partir de plano
                </h2>
                ¿Te gustaría convertir tus planos en imágenes realistas de forma
                instantánea? Ahora con Pedra es posible. Sube la imagen de un
                plano y genera imágenes de todas las estancias en 1 click. Ideal
                para obra nueva.
                <div
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                    }}
                    src={ReleaseNov2024Frame1}
                    alt="Renders a partir de plano"
                  />
                </div>
                <br />
                <br />
                <h2 className="article-subtitleh2">
                  Reforma y amuebla locales comerciales
                </h2>
                Ahora tienes la opción de escoger el tipo de espacio que quieras
                en la reforma o amueblamiento de tus estancias: restaurante,
                bar, tienda, supermercado, peluquería... ¡Lo que quieras!
                <div
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                    }}
                    src={ReleaseNov2024Frame2}
                    alt="Locales comerciales"
                  />
                </div>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">Otras mejoras</h2>
              <div>
                <li>
                  Mejores resultados al usar "Amueblar" para amueblar estancias
                </li>
                <li>
                  Opción de contratar servicio profesional de amueblado virtual
                  de habitaciones por 6€ la imagen
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleaseEsDec24;
