import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

import BeforeKitchen from "./Images/curtain-effect/BeforeInteriorDesignAI2.png";
import AfterKitchen from "./Images/curtain-effect/AfterInteriorDesignAI2.png";

import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeInteriorDesignAI1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterInteriorDesignAI1.png";

import BeforeKitchenStaging2 from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterKitchenStaging2 from "./Images/curtain-effect-hero/AfterFurnish.png";

import BeforeKitchenStaging3 from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterKitchenStaging3 from "./Images/curtain-effect-hero/AfterTerraceStaging.png";

import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function InteriorDesign() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const handleExample = (example) => {
    switch (example) {
      case "Modern":
        setExample({
          name: "Modern",
          beforeImage: BeforeKitchenStaging1,
          afterImage: AfterKitchenStaging1,
        });
        break;
      case "Minimalist":
        setExample({
          name: "Minimalist",
          beforeImage: BeforeKitchenStaging2,
          afterImage: AfterKitchenStaging2,
        });
        break;
      case "Industrial":
        setExample({
          name: "Industrial",
          beforeImage: BeforeKitchenStaging3,
          afterImage: AfterKitchenStaging3,
        });
        break;
      default:
        setExample({
          name: "Classic",
          beforeImage: BeforeKitchen,
          afterImage: AfterKitchen,
        });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is AI interior design?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "AI interior design combines traditional design creativity with artificial intelligence to optimize and visualize spaces efficiently. The AI analyzes your space and preferences to generate personalized and professional designs instantly.",
        },
      },
      {
        "@type": "Question",
        name: "What types of spaces can I design?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "You can design residential spaces (living rooms, bedrooms, kitchens), commercial spaces (offices, stores, restaurants), public spaces (museums, libraries) and specialized areas (clinics, gyms, hotels).",
        },
      },
      {
        "@type": "Question",
        name: "What are the advantages of AI design?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The advantages include instant visualization of changes, multiple design options in seconds, intelligent space optimization, detailed personalization, time and cost savings, and professional results without prior experience.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Design de Interiores com IA</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Transforme seus espaços com design de interiores inteligente
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instantâneo:</strong> Visualize mudanças em tempo
                    real
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simples:</strong> Não requer conhecimento técnico
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Profissional:</strong> Resultados de alta qualidade
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Personalizado:</strong> Adaptado às suas
                    preferências
                  </div>
                </div>

                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Experimente o Design com IA <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 por mês, sem compromisso
                      </div>
                    </div>
                  </div>

                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Mais de 100 depoimentos
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Antes do design de interiores"
                  altAfter="Depois do design de interiores"
                />

                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Quarto
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Minimalist" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Minimalist")}
                    >
                      Sala de Estar
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Industrial" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Industrial")}
                    >
                      Terraço
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Classic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Classic")}
                    >
                      Cozinha
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MAIS DE 5.000 PROFISSIONAIS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Crie designs profissionais{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automaticamente
          </span>{" "}
          em segundos.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Design de múltiplos espaços</h3>
                <div className="feature-text">
                  Gere designs para vários ambientes simultaneamente
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Carregue seu próprio estilo</h3>
                <div className="feature-text">
                  Faça upload de imagens dos seus estilos e a IA redesenhará
                  suas fotos com base nesses estilos
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Otimização de espaço</h3>
                <div className="feature-text">
                  Distribua móveis de forma inteligente
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Limpeza automática</h3>
                <div className="feature-text">
                  Remova objetos indesejados da cena
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Personalização total</h3>
                <div className="feature-text">
                  Ajuste cada detalhe ao seu gosto
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Design em segundos</h3>
                <div className="feature-text">
                  Gere propostas de design com IA em segundos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Como funciona o design de interiores com IA?
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 1
            </h5>
            <h2>Faça upload de uma imagem do seu espaço</h2>
            <div className="value-prop-text">
              Comece fazendo upload de uma foto do ambiente que você deseja
              redesenhar. Nosso software analisa a estrutura, iluminação e
              elementos principais.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Como fazer upload de fotos para design de interiores com IA"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 2
            </h5>
            <h2>Escolha seu estilo preferido</h2>
            <div className="value-prop-text">
              Selecione entre diferentes estilos de decoração e personalize
              cores, materiais e elementos decorativos de acordo com suas
              preferências.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Seleção de estilos de design de interiores"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              PASSO 3
            </h5>
            <h2>Receba seu design instantaneamente</h2>
            <div className="value-prop-text">
              Receba visualizações profissionais do seu espaço transformado.
              Baixe e use seus designs imediatamente.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Resultado final do design de interiores"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Design de interiores profissional por €29 por mês. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Sem compromisso.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plano Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                por mês
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclui</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 designs
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mês</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Design instantâneo de espaços
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Escolha seus próprios estilos de design
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Layout inteligente de móveis
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Limpeza automática de cena
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Visualizações em alta resolução
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Altere materiais e texturas
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Iluminação profissional
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Projetos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartilhamento ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Suporte prioritário
            </div>

            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Experimente o Design com IA
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancele quando quiser.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Designs adicionais disponíveis por €0,20 cada.
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Perguntas Frequentes sobre Design de Interiores com IA
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                O que é design de interiores com inteligência artificial?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                O design de interiores com IA é uma tendência emergente que
                combina a criatividade do design tradicional com as capacidades
                analíticas e preditivas da IA. A tecnologia analisa espaços e
                preferências para gerar designs personalizados instantaneamente.
                <br />
                <br />
                Nosso sistema pode:
                <br />
                • Gerar designs automaticamente
                <br />
                • Otimizar a distribuição do espaço
                <br />
                • Sugerir combinações de cores e materiais
                <br />• Criar visualizações fotorrealistas
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais tipos de espaços posso projetar?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Nossa ferramenta pode projetar uma grande variedade de espaços:
                <br />
                <br />
                <strong>Espaços Residenciais:</strong>
                <br />
                • Salas de estar e jantar
                <br />
                • Quartos
                <br />
                • Cozinhas e banheiros
                <br />
                • Áreas externas e jardins
                <br />
                <br />
                <strong>Espaços Comerciais:</strong>
                <br />
                • Escritórios
                <br />
                • Lojas e espaços de varejo
                <br />
                • Restaurantes e cafés
                <br />
                <br />
                <strong>Espaços Públicos:</strong>
                <br />
                • Museus e galerias
                <br />
                • Bibliotecas e centros educacionais
                <br />• Instalações esportivas
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Quais são as vantagens da IA no design de interiores?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                As principais vantagens incluem:
                <br />
                <br />
                <strong>Eficiência e velocidade</strong>
                <br />
                • Geração instantânea de design
                <br />
                • Automatização de tarefas repetitivas
                <br />
                • Visualização imediata das mudanças
                <br />
                <br />
                <strong>Precisão e otimização</strong>
                <br />
                • Análise detalhada do espaço
                <br />
                • Distribuição ideal dos elementos
                <br />
                • Redução de erros de design
                <br />
                <br />
                <strong>Personalização</strong>
                <br />
                • Adaptação às preferências individuais
                <br />
                • Múltiplas opções de estilo
                <br />• Recomendações baseadas no uso do espaço
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesign;
