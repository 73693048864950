import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example2Before from "../Images/curtain-effect/BeforeBathroomStaging2.png";
import Example2After from "../Images/curtain-effect/AfterBathroomStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeBathroomStaging3.png";
import Example3After from "../Images/curtain-effect/AfterBathroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderBathroomDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie Badezimmer-Renderings
          </h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Badezimmer-Renderings
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Warum sollten Sie Pedras Rendering-Tools für Badezimmer nutzen?
            </h2>
            <p className="article-text">
              Mit Pedras Rendering-Tools können Sie in wenigen Sekunden eine
              komplette Badezimmerrenovierung visualisieren und potenziellen
              Käufern das wahre Potenzial des Raums zeigen.
              <p style={{ marginTop: "10px" }}>
                Badezimmerrenovierungen gehören zu den wertvollsten
                Hausverbesserungen. Die Möglichkeit, das Potenzial eines
                veralteten Badezimmers aufzuzeigen, kann beim Verkauf von
                gebrauchten Immobilien einen großen Unterschied machen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit Pedra benötigen Sie keine spezialisierten Baddesigner oder
                3D-Rendering-Experten mehr, um überzeugende
                Badezimmer-Renovierungsvorschläge zu erstellen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich ein Badezimmer-Rendering?
            </h2>
            <p className="article-text">
              Laden Sie zunächst klare Fotos des bestehenden Badezimmers hoch,
              das Sie transformieren möchten.
              <p style={{ marginTop: "10px" }}>
                Wählen Sie Ihre Badezimmerbilder aus und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                dann wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>"Renovierung"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Wie man das Renovierungs-Tool zur Erstellung von Badezimmer-Renderings verwendet – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Schnelle Badezimmerrenovierung:{" "}
              </span>
              Erstellen Sie ein schnelles Badezimmer-Rendering
            </h2>
            <p style={{ marginTop: "10px" }}>
              Das <span style={{ fontWeight: "500" }}>"Renovierungs"</span>-Tool
              transformiert Ihr Badezimmer in etwa 25 Sekunden.
              <p>
                Für eine dezente Aktualisierung wählen Sie den
                "Minimalistischen" Stil mit "Niedriger" Kreativität. Dies behält
                das grundlegende Layout bei und modernisiert Oberflächen und
                Armaturen.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Wie man das Renovierungs-Tool zur Erstellung von Badezimmer-Renderings verwendet – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Passen Sie Ihr Badezimmer-Renovierungs-Rendering an
            </h2>
            <p className="article-text">
              Pedra bietet umfangreiche Anpassungsoptionen für
              Badezimmer-Renderings.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Wählen Sie aus verschiedenen Badezimmerstilen, steuern Sie,
                  wie dramatisch die Änderungen sein sollen, und entscheiden
                  Sie, ob bestehende Elemente wie Fenster oder Armaturen
                  erhalten bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Wie man das Renovierungs-Tool zur Erstellung von Badezimmer-Renderings verwendet – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Wählen Sie Ihren Badezimmerstil
            </h3>
            <p className="article-text">
              Wählen Sie aus 9 verschiedenen Badezimmerstilen, darunter
              minimalistisch, traditionell, mediterran und mehr.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Referenzbilder von Badezimmerstilen hochladen,
                  die Ihnen gefallen.
                </span>{" "}
                Dies ermöglicht es Ihnen, Renderings zu erstellen, die
                spezifischen Designinspirationen oder dem bevorzugten Stil Ihres
                Kunden entsprechen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Legen Sie die Renovierungsintensität fest
            </h3>
            <p className="article-text">
              Wählen Sie zwischen niedriger, mittlerer, hoher und sehr hoher
              Kreativität für Ihre Badezimmertransformation.
              <p style={{ marginTop: "10px" }}>
                Niedrige und mittlere Einstellungen aktualisieren Oberflächen
                und Armaturen, während das grundlegende Badezimmerlayout
                erhalten bleibt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hohe und sehr hohe Einstellungen können den Raum komplett neu
                gestalten mit neuen Layouts, Armaturenkonfigurationen und
                architektonischen Änderungen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Bewahren Sie wichtige Badezimmerelemente
            </h3>
            <p className="article-text">
              Wählen Sie, ob Fenster und Sanitärinstallationen erhalten bleiben
              sollen, was besonders wichtig ist für Badezimmer mit spezifischen
              Lüftungsanforderungen oder Installationsbeschränkungen. Dies
              stellt sicher, dass Ihr Rendering praktische Elemente beibehält,
              während die Ästhetik aktualisiert wird.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Generieren Sie Ihr Badezimmer-Rendering
            </h2>
            <p>
              Nach der Auswahl Ihrer Präferenzen generieren Sie das
              Badezimmer-Rendering. Standardrenderings dauern etwa 15 Sekunden,
              während das Bewahren spezifischer Elemente etwas länger dauern
              kann.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps für bessere Badezimmer-Renderings
                </h4>
                <p className="callout-p">
                  Um die realistischsten und nützlichsten Badezimmer-Renderings
                  zu erhalten:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generieren Sie mehrere Versionen
                      </span>
                      . Jedes Rendering ist einzigartig, und Sie finden
                      möglicherweise einige, die wichtige Badezimmermerkmale wie
                      Sanitärinstallationen besser erhalten.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Verwenden Sie niedrige Kreativität für dezente
                      Aktualisierungen veralteter Badezimmer.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Entfernen Sie Spiegelungen von Spiegeln oder Glas vor
                        dem Rendering
                      </span>
                      . Dies hilft der KI, den Raum besser zu verstehen und
                      genauere Transformationen zu erstellen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Badezimmer-Rendering Beispiele
            </h2>
            <p className="article-text">
              Hier sind einige Beispiele von Badezimmerrenovierungen, die mit
              unseren Renderings erstellt wurden:
            </p>
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Badezimmer vor dem Rendering"}
              altAfter={"Badezimmer nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Badezimmer vor dem Rendering"}
              altAfter={"Badezimmer nach dem Rendering"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderBathroomDe;
