import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import VirtualStagingExample1 from "../Images/HowToFloorPlanRender1En.png";
import VirtualStagingExample2 from "../Images/HowToFloorPlanRender2En.png";
import VirtualStagingExample3 from "../Images/HowToFloorPlanRender3En.png";

function HowToRenderFloorPlanWithAIDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie einen Grundriss mit KI
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung realistischer Raumbilder aus Grundrissen mit
            künstlicher Intelligenz
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Was ist KI-Grundriss-Rendering?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie Ihre Grundrisse in wenigen Minuten in
              fotorealistische Bilder jedes Raums verwandeln. Unsere
              KI-Technologie analysiert Ihren Grundriss und erstellt hochwertige
              Visualisierungen, die zeigen, wie die Räume in Wirklichkeit
              aussehen würden, wobei Sie zwischen verschiedenen
              Einrichtungsstilen wählen können.
            </p>

            <h3 className="article-subtitle">
              Schritt 1 – Laden Sie Ihren Grundriss hoch
            </h3>
            <div className="value-prop-text">
              Laden Sie Ihren Grundriss auf die Pedra-Plattform hoch.
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample1}
                alt="Wie man Grundrisse für automatisches Rendering in Pedra hochlädt – Infografik"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Schritt 2 – Wählen Sie Ihre Rendering-Optionen
            </h3>
            <div className="value-prop-text">
              Wählen Sie Ihren Stil und Anpassungsoptionen.
              <p>Wählen Sie die Anzahl der gewünschten Bilder und den Stil.</p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample2}
                alt="Wie man Optionen für Grundriss-Rendering auswählt – Infografik"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Schritt 3 – Erhalten Sie Ihre Raum-Renderings
            </h3>
            <div className="value-prop-text">
              Erhalten Sie fotorealistische Renderings für jeden Raum Ihres
              Grundrisses.
              <p>
                Laden Sie Ihre Renderings sofort herunter oder bearbeiten Sie
                sie weiter.
              </p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample3}
                alt="Wie man Grundriss-Renderings herunterlädt – Infografik"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für bessere Ergebnisse:
                <ul>
                  <li>
                    Stellen Sie sicher, dass der Grundriss gut definiert und die
                    Maße lesbar sind
                  </li>
                  <li>
                    Wählen Sie den Stil, der am besten zu Ihrem Projekt passt
                  </li>
                  <li>
                    Erstellen Sie mehrere Renderings, um verschiedene
                    Visualisierungsoptionen zu haben
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">
              Warum KI-Grundriss-Rendering nutzen?
            </h2>
            <p className="article-text">
              KI-Grundriss-Rendering bietet mehrere Vorteile:
            </p>
            <ul>
              <li>
                Sofortige Visualisierung, wie Räume in der Realität aussehen
                werden
              </li>
              <li>
                Erhebliche Zeitersparnis im Vergleich zu traditionellem
                Rendering
              </li>
              <li>
                Flexibilität beim Ausprobieren verschiedener Einrichtungsstile
              </li>
              <li>
                Fotorealistische Qualität, die bei der Projektpräsentation hilft
              </li>
            </ul>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRenderFloorPlanWithAIDe;
