import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect-empty-room/Example3Before.jpeg";
import Example4After from "../Images/curtain-effect-empty-room/Example3After.jpeg";
import HowToCurtainEffectImage from "../Images/HowToCurtainEffectEsImage.jpg";

import CurtainEffect from "../CurtainEffect";

function HowToCurtainEffectEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear un efecto cortina antes/después para el Home Staging
            Virtual
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear efectos cortina de antes/después para el
            staging virtual
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Qué es un efecto cortina y por qué es útil?
            </h2>
            <p className="article-text">
              Un efecto cortina es una forma interactiva de mostrar imágenes de
              antes y después del home staging virtual. Permite a los
              espectadores deslizarse entre la versión original y la versión
              decorada de un espacio, facilitando la apreciación de la
              transformación.
            </p>
            <p className="article-text">
              Este efecto es particularmente valioso para los profesionales
              inmobiliarios porque:
            </p>
            <ul>
              <li>
                Demuestra el impacto del staging virtual de manera convincente
              </li>
              <li>
                Ayuda a los clientes a entender el potencial de los espacios
                vacíos
              </li>
              <li>Crea contenido atractivo para materiales de marketing</li>
              <li>
                Genera confianza al mostrar el espacio original junto con las
                mejoras
              </li>
            </ul>

            <h2 className="article-subtitleh2">
              Cómo crear un efecto cortina con Pedra
            </h2>
            <p className="article-text">
              Tradicionalmente, crear un efecto cortina requería conocimientos
              técnicos y habilidades de programación. Sin embargo, con Pedra,
              puedes crear uno con solo un clic.
            </p>
            <p className="article-text">
              Una vez que hayas creado tu imagen de staging virtual,
              simplemente:
            </p>
            <ol>
              <li>Haz clic en tu imagen con staging virtual</li>
              <li>
                Haz clic en "Efecto cortina" - esto generará automáticamente un
                enlace compartible que muestra tu comparación de antes/después
                con un control deslizante interactivo
              </li>
            </ol>

            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToCurtainEffectImage}
              alt="Cómo crear un efecto cortina - Captura de pantalla del software Pedra"
            />

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para efectos cortina efectivos:
                <ul>
                  <li>
                    Utiliza imágenes de alta calidad para la mejor presentación
                  </li>
                  <li>
                    Comparte el enlace del efecto cortina en tu sitio web o
                    redes sociales para mostrar tu trabajo de staging virtual
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">Ejemplos de efectos cortina</h2>
            <p className="article-text">
              Aquí hay varios ejemplos de efectos cortina que muestran
              transformaciones de staging virtual:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Terraza vacía antes del staging virtual"}
              altAfter={"Terraza amueblada después del staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Terraza vacía antes del staging virtual"}
              altAfter={"Terraza amueblada después del staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Terraza vacía antes del staging virtual"}
              altAfter={"Terraza amueblada después del staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Terraza vacía antes del staging virtual"}
              altAfter={"Terraza amueblada después del staging virtual"}
            />
            <br />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToCurtainEffectEs;
