import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeSpaceExample1 from "../Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "../Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "../Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "../Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "../Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "../Images/curtain-effect/AfterSpaceStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLocal() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create Commercial Space Renders
          </h1>

          <div className="title-secondary gray">
            Tutorial on How to Create Commercial Space Renders
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Why use Pedra's render tools for commercial spaces?
            </h2>
            <p className="article-text">
              Pedra's render tools allow you to visualize commercial space
              transformations in just seconds, helping potential clients or
              investors see the true potential of the space.
              <p style={{ marginTop: "10px" }}>
                Commercial space renovations and staging are crucial for
                attracting tenants or buyers. Being able to show the potential
                of an empty or outdated commercial space can make a significant
                difference in closing deals faster.
              </p>
              <p style={{ marginTop: "10px" }}>
                With Pedra, you no longer need specialized interior designers or
                3D rendering experts to create compelling commercial space
                visualizations.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              How do I create a commercial space render?
            </h2>
            <p className="article-text">
              Start by uploading clear photos of the existing commercial space
              you want to transform.
              <p style={{ marginTop: "10px" }}>
                Select your commercial space images and click{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, then
                choose the{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="How to Use the Renovation Tool to Create Commercial Space Renders – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Quick Commercial Space Transformation:{" "}
              </span>
              Generate a Fast Commercial Space Render
            </h2>
            <p style={{ marginTop: "10px" }}>
              The <span style={{ fontWeight: "500" }}>"Renovation"</span> tool
              will transform your commercial space in about 25 seconds.
              <p>
                For a subtle update, choose the "Minimalist" style with "Low"
                creativity. This will maintain the basic layout while
                modernizing finishes and fixtures.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="How to Use the Renovation Tool to Create Commercial Space Renders – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Customize Your Commercial Space Render
            </h2>
            <p className="article-text">
              Pedra offers extensive customization options for commercial space
              renders.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Choose from various styles, specify your space type, control
                  how dramatic the changes should be, and decide whether to
                  preserve existing elements like windows or structural
                  features.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="How to Use the Renovation Tool to Create Commercial Space Renders – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 – </span>
              Specify your commercial space type
            </h3>
            <p className="article-text">
              In the space type field, enter the specific type of commercial
              space you're working with (e.g., restaurant, bar, cafeteria,
              retail store, office space, etc.).
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  You can also upload reference images of similar spaces you
                  love.
                </span>{" "}
                This allows you to create renders that match specific design
                inspiration or your client's preferred aesthetic.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 – </span>
              Set the renovation intensity
            </h3>
            <p className="article-text">
              Choose between Low, Medium, High, and Very High creativity levels
              for your commercial space transformation.
              <p style={{ marginTop: "10px" }}>
                Low and Medium settings will update finishes and fixtures while
                keeping the basic layout intact.
              </p>
              <p style={{ marginTop: "10px" }}>
                High and Very High settings can completely reimagine the space
                with new layouts, furniture configurations, and architectural
                changes.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 – </span>
              Configure preservation settings
            </h3>
            <p className="article-text">
              Choose to preserve windows and structural elements, which is
              particularly important for commercial spaces with specific
              architectural features or lighting requirements. This ensures your
              render maintains practical elements while updating the aesthetic.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 4 – </span>
              Generate your commercial space render
            </h2>
            <p>
              After selecting your preferences, generate the commercial space
              render. Standard renders take about 15 seconds, while preserving
              specific elements may take slightly longer.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tips for better commercial space renders
                </h4>
                <p className="callout-p">
                  To get the most realistic and useful commercial space renders:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Make sure "Furnish" is turned on
                      </span>{" "}
                      before clicking generate image. This ensures your space
                      will be properly furnished according to its type.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Be specific with your space type
                      </span>
                      . Instead of just "restaurant", specify "modern Italian
                      restaurant" or "casual cafe" for more targeted results.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generate multiple versions
                      </span>
                      . Each render is unique, and you might find some better
                      preserve important features or match your vision more
                      closely.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Remove clutter and temporary items before rendering
                      </span>
                      . This helps the AI better understand the space and create
                      more accurate transformations.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Commercial space render examples
            </h2>
            <p className="article-text">
              Here are several examples of commercial space transformations
              created with our renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeSpaceExample1}
              afterImage={AfterSpaceExample1}
              altBefore={"Commercial space before render"}
              altAfter={"Commercial space after render"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample2}
              afterImage={AfterSpaceExample2}
              altBefore={"Commercial space before render"}
              altAfter={"Commercial space after render"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample3}
              afterImage={AfterSpaceExample3}
              altBefore={"Commercial space before render"}
              altAfter={"Commercial space after render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLocal;
