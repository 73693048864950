import React from "react";

const SocialMediaIcon3 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3190_1255)">
        <path
          d="M19.5817 5.15591C19.4687 4.73031 19.2459 4.34186 18.9355 4.02946C18.6252 3.71707 18.2382 3.4917 17.8133 3.37591C16.2542 2.95508 10 2.95508 10 2.95508C10 2.95508 3.74583 2.95508 2.18583 3.37591C1.76117 3.4919 1.37441 3.71736 1.06423 4.02973C0.754051 4.3421 0.531326 4.73044 0.418000 5.15591C0 6.72591 0 10.0009 0 10.0009C0 10.0009 0 13.2759 0.418000 14.8459C0.531322 15.2715 0.754146 15.66 1.06449 15.9724C1.37484 16.2848 1.76181 16.5101 2.18667 16.6259C3.74583 17.0467 10 17.0467 10 17.0467C10 17.0467 16.2542 17.0467 17.8142 16.6259C18.2391 16.5102 18.6261 16.2849 18.9364 15.9724C19.2468 15.66 19.4696 15.2716 19.5825 14.8459C20 13.2759 20 10.0009 20 10.0009C20 10.0009 20 6.72591 19.5817 5.15591ZM7.95417 12.9742V7.02758L13.1817 10.0009L7.95417 12.9742Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0_3190_1255">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SocialMediaIcon3;
