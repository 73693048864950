import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLivingRoomDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie Wohnzimmer-Renderings
          </h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Wohnzimmer-Renderings
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Warum sollten Sie Pedras Rendering-Tools für Wohnzimmer nutzen?
            </h2>
            <p className="article-text">
              Mit Pedras Rendering-Tools können Sie in wenigen Sekunden eine
              komplette Wohnzimmer-Transformation visualisieren und potenziellen
              Käufern das wahre Potenzial des Raums zeigen.
              <p style={{ marginTop: "10px" }}>
                Wohnzimmerrenovierungen sind oft der wirkungsvollste Teil von
                Hausverbesserungen, da sie der zentrale Treffpunkt jedes
                Zuhauses sind. Die Möglichkeit, das Potenzial eines veralteten
                Wohnzimmers aufzuzeigen, kann beim Verkauf von gebrauchten
                Immobilien einen großen Unterschied machen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit Pedra benötigen Sie keine spezialisierten Innenarchitekten
                oder 3D-Rendering-Experten mehr, um überzeugende
                Wohnzimmer-Renovierungsvorschläge zu erstellen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich ein Wohnzimmer-Rendering?
            </h2>
            <p className="article-text">
              Laden Sie zunächst klare Fotos des bestehenden Wohnzimmers hoch,
              das Sie transformieren möchten.
              <p style={{ marginTop: "10px" }}>
                Wählen Sie Ihre Wohnzimmerbilder aus und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                dann wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>"Renovieren"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="So nutzen Sie das Renovierungstool zur Erstellung von Wohnzimmer-Renderings – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Schnelle Wohnzimmerrenovierung:{" "}
              </span>
              Erstellen Sie ein schnelles Wohnzimmer-Rendering
            </h2>
            <p style={{ marginTop: "10px" }}>
              Das <span style={{ fontWeight: "500" }}>"Renovieren"</span> Tool
              transformiert Ihr Wohnzimmer in etwa 25 Sekunden.
              <p>
                Für eine subtile Aktualisierung wählen Sie den
                "Minimalistischen" Stil mit "Niedriger" Kreativität. Dies behält
                das grundlegende Layout bei, während Oberflächen und Möbel
                modernisiert werden.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="So nutzen Sie das Renovierungstool zur Erstellung von Wohnzimmer-Renderings – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Passen Sie Ihr Wohnzimmer-Renovierungs-Rendering an
            </h2>
            <p className="article-text">
              Pedra bietet umfangreiche Anpassungsoptionen für
              Wohnzimmer-Renderings.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Wählen Sie aus verschiedenen Wohnzimmerstilen, steuern Sie,
                  wie dramatisch die Änderungen sein sollen, und entscheiden
                  Sie, ob bestehende Elemente wie Fenster oder architektonische
                  Merkmale erhalten bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="So nutzen Sie das Renovierungstool zur Erstellung von Wohnzimmer-Renderings – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Wählen Sie Ihren Wohnzimmerstil
            </h3>
            <p className="article-text">
              Wählen Sie aus 9 verschiedenen Wohnzimmerstilen, darunter
              minimalistisch, traditionell, mediterran und mehr.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Referenzbilder von Wohnzimmerstilen hochladen,
                  die Ihnen gefallen.
                </span>{" "}
                Dies ermöglicht es Ihnen, Renderings zu erstellen, die
                bestimmten Design-Inspirationen oder dem bevorzugten Stil Ihres
                Kunden entsprechen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Legen Sie die Renovierungsintensität fest
            </h3>
            <p className="article-text">
              Wählen Sie zwischen niedriger, mittlerer, hoher und sehr hoher
              Kreativität für Ihre Wohnzimmer-Transformation.
              <p style={{ marginTop: "10px" }}>
                Niedrige und mittlere Einstellungen aktualisieren Oberflächen
                und Möbel, während das grundlegende Raumlayout erhalten bleibt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hohe und sehr hohe Einstellungen können den Raum komplett neu
                gestalten mit neuen Layouts, Beleuchtungskonfigurationen und
                architektonischen Änderungen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Bewahren Sie wichtige Wohnzimmerelemente
            </h3>
            <p className="article-text">
              Wählen Sie die Erhaltung von Fenstern, was besonders wichtig ist
              für Wohnzimmer mit Ausblick oder spezifischen
              Beleuchtungsanforderungen. Dies stellt sicher, dass Ihr Rendering
              praktische Elemente beibehält, während die Ästhetik aktualisiert
              wird.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Generieren Sie Ihr Wohnzimmer-Rendering
            </h2>
            <p>
              Nach der Auswahl Ihrer Präferenzen generieren Sie das
              Wohnzimmer-Rendering. Standardrenderings dauern etwa 15 Sekunden,
              während die Erhaltung spezifischer Elemente etwas länger dauern
              kann.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps für bessere Wohnzimmer-Renderings
                </h4>
                <p className="callout-p">
                  Um die realistischsten und nützlichsten Wohnzimmer-Renderings
                  zu erhalten:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generieren Sie mehrere Versionen
                      </span>
                      . Jedes Rendering ist einzigartig, und Sie könnten
                      feststellen, dass einige wichtige Merkmale wie
                      architektonische Details oder natürliches Licht besser
                      erhalten.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Verwenden Sie niedrige Kreativität für subtile
                      Aktualisierungen veralteter Wohnzimmer.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Sorgen Sie für gute Beleuchtung beim Fotografieren und
                        minimieren Sie Reflexionen
                      </span>
                      . Dies hilft der KI, den Raum besser zu verstehen und
                      genauere Transformationen zu erstellen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Wohnzimmer-Rendering Beispiele
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für Wohnzimmerrenovierungen, die mit
              unseren Renderings erstellt wurden:
            </p>
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Wohnzimmer vor dem Rendering"}
              altAfter={"Wohnzimmer nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Wohnzimmer vor dem Rendering"}
              altAfter={"Wohnzimmer nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Wohnzimmer vor dem Rendering"}
              altAfter={"Wohnzimmer nach dem Rendering"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLivingRoomDe;
