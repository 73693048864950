import React from "react";
import ReleaseJul2024Frame1 from "../Images/ReleaseJul2024Frame1.png";
import ReleaseJul2024Frame2 from "../Images/ReleaseJul2024Frame2.png";
import ReleaseJul2024Frame3 from "../Images/ReleaseJul2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJul24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">JULI 2024</div>
          <h1 className="article-titleh1">
            Pedra: 3D-Objekte hinzufügen, Himmel mit einem Klick blau färben und
            Bilder automatisch verbessern oder aufhellen
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Illustratives Titelbild über KI und die Immobilienbranche"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Hier sind die Neuigkeiten für diesen Juli – ich hoffe, Sie sind
              gespannt darauf, sie in Pedra auszuprobieren!
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                3D-Objekte zu Ihren Fotos hinzufügen
              </h2>
              Jetzt können Sie die Möbel auswählen, die Sie zu Ihren Fotos
              hinzufügen möchten. Mit der Option "Objekt hinzufügen" können Sie
              dessen Größe, Ausrichtung und Anordnung wählen. Sie können{" "}
              <Link to="/help/how-to-add-3d-objects" className="article-link">
                hier sehen, wie Sie 3D-Objekte hinzufügen
              </Link>
              .
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame1}
                  alt="Räume mit KI vom Mobilgerät aus erneuern"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Klären Sie den Himmel in Ihren Fotos mit einem Klick
              </h2>
              Wir alle möchten, dass unsere Fotos einen klaren und schönen
              Himmel zeigen. Jetzt können Sie mit nur einem Klick und ~30
              Sekunden einen blauen Himmel in Ihre Fotos einfügen.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame2}
                  alt="Wasserzeichen-Transparenz verschieben und bearbeiten"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Wir haben die Optionen Verschönern und Aufhellen kombiniert
              </h2>
              Durch Klicken auf Verschönern erkennt Pedra, ob die Fotos eine
              geringe Helligkeit aufweisen und verbessert automatisch Licht,
              Farbe und Kontrast.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJul2024Frame3}
                alt="Bildunterschrift einer KI-Kreation ändern"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>

            <div>
              <li>
                Bessere Qualität beim Erhalten von Fenstern in "Erweiterte
                Renovierung"
              </li>
              <li>
                Weniger Fehler in "Erweiterte Renovierung" beim Erhalten von
                Fenstern
              </li>
              <li>Intuitivere Erfahrung beim Übermalen von Bildern</li>
              <li>
                Option zum Herunterladen von Rechnungen aus den Einstellungen
              </li>
              <li>
                Verbesserungen der Benutzerfreundlichkeit und verschiedene
                Fehlerbehebungen
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJul24De;
