// RealEstateAgents.js
import React from "react";
import Frame1RealEstateAgents from "./Images/Frame 1 RealEstateAgents.png";
import { ReactComponent as ChairIcon } from "./Images/Chair icon.svg";
import { ReactComponent as CleanIcon } from "./Images/Clean icon.svg";
import { ReactComponent as BrandIcon } from "./Images/Brand icon.svg";
import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import Frame2RealEstateAgents from "./Images/Frame 2 RealEstateAgents.jpeg";
import Frame3RealEstateAgents from "./Images/Frame 3 RealEstateAgents.png";
import Frame4RealEstateAgents from "./Images/Frame 4 RealEstateAgents.png";
import ArrowIcon from "./Icons/ArrowIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RealEstateAgentsDe() {
  return (
    <div className="body-real-estate">
      <div>
        <section className="section-first-wrapper">
          <section className="section-first">
            <div className="section-first-text-container">
              <h1 className="section-first-title">
                Beschleunigen Sie Ihre Immobilienverkäufe
              </h1>
              <div className="section-first-text">
                Gewinnen Sie Käufer, vereinbaren Sie mehr Besichtigungen und
                verkaufen Sie schneller. Pedra hilft Ihnen, das Potenzial jeder
                Immobilie mit einem Klick zu präsentieren.
              </div>
              <div className="section-first-button-container">
                <button className="button-small" onClick={redirectToPage}>
                  Pedra testen <ArrowIcon />
                </button>
              </div>
            </div>
            <div className="header-picture">
              <img
                src={Frame1RealEstateAgents}
                alt="Tool für Immobilienmakler – Software Screenshot"
              ></img>
            </div>
          </section>
        </section>

        <div style={{ borderTop: "1px solid #EEEEEE", height: "20px" }}></div>

        <div className="section-one-column-left">
          <div
            className="title-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignText: "left",
            }}
          >
            <div className="">
              Ein Immobilienmarketing-Tool wie kein anderes.
            </div>
            <div className="title-secondary">
              Pedra macht es für jedes Immobilienunternehmen einfach, ihre
              Immobilienangebote zu verbessern.
            </div>
            <div className="title-secondary">
              Keine besonderen Fähigkeiten erforderlich — laden Sie einfach Ihre
              Bilder hoch und legen Sie los!
            </div>
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <RenovateIcon />
              <div className="feature-title">Renovieren mit KI</div>
              <div className="feature-text">
                Generieren Sie KI-Renderings mit einem Klick. Käufer möchten
                Renovierungsvorschläge sehen, bevor sie einen Kauf tätigen.
                Pedra bietet günstige, schnelle und hochwertige Renderings, um
                Ihren Kunden Renovierungsideen zu zeigen.
              </div>
            </div>
            <img
              src={FrameRenovateRealEstateAgents}
              alt="Pedra Render Software Screenshot – Immobilien-Funktionen"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="title-container">Einfach aber leistungsstark</div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container">
            <div className="feature-text-container">
              <ChairIcon />
              <div className="feature-title">Home Staging</div>
              <div className="feature-text">
                Verkaufen Sie einen Traum. Fügen Sie mit einem Klick Möbel in
                leere Räume ein. Kunden haben oft Schwierigkeiten, die
                Raumverhältnisse zu verstehen.
              </div>
            </div>
            <img
              src={Frame2RealEstateAgents}
              alt="Pedra Render Software Screenshot vom virtuellen Home Staging"
            />
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-small">
                <div className="feature-text-container">
                  <CleanIcon />
                  <div className="feature-title">Halten Sie es sauber</div>
                  <div className="feature-text">
                    Nutzen Sie unsere KI-Tools, um Elemente aus Bildern zu
                    entfernen.
                  </div>
                </div>
                <img
                  src={Frame4RealEstateAgents}
                  alt="Pedra Render Software Screenshot der Objektentfernung"
                />
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-small">
                <div className="feature-text-container">
                  <BrandIcon />
                  <div className="feature-title">Schützen Sie Ihre Marke</div>
                  <div className="feature-text">
                    Nutzen Sie unsere Leinwand, um Wasserzeichen zu Bildern
                    hinzuzufügen.
                  </div>
                </div>
                <img
                  src={Frame3RealEstateAgents}
                  alt="Pedra Render Software Screenshot zum Hinzufügen von Immobilien-Wasserzeichen"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Die Verwendung von Pedra zur Bildbearbeitung hilft uns, mehr
              Besichtigungen zu bekommen!"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src="/fincas-lluria.png"
                alt="Logo von Fincas Lluria für Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Max Vives-Fierro</div>
                <div className="quote-author-company">
                  Fincas Llúria Immobilien
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mid-break"> </div>

        <div className="centered-heading">
          <button className="button" onClick={redirectToPage}>
            Pedra testen
          </button>
        </div>
      </div>
    </div>
  );
}

export default RealEstateAgentsDe;
