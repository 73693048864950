import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icônes
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";

// Images
import BeforeKitchen from "./Images/curtain-effect-hero/BeforeKitchen.png";
import AfterKitchen from "./Images/curtain-effect-hero/AfterKitchen.png";
import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeKitchenStaging1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterKitchenStaging1.png";
import BeforeKitchenStaging2 from "./Images/curtain-effect/BeforeKitchenStaging2.png";
import AfterKitchenStaging2 from "./Images/curtain-effect/AfterKitchenStaging2.png";
import BeforeKitchenStaging3 from "./Images/curtain-effect/BeforeKitchenStaging3.png";
import AfterKitchenStaging3 from "./Images/curtain-effect/AfterKitchenStaging3.png";
import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeStagingKitchenFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Moderne",
    beforeImage: BeforeKitchen,
    afterImage: AfterKitchen,
  });

  const handleExample = (example) => {
    if (example === "Moderne") {
      setExample({
        name: "Moderne",
        beforeImage: BeforeKitchen,
        afterImage: AfterKitchen,
      });
    } else if (example === "Traditionnel") {
      setExample({
        name: "Traditionnel",
        beforeImage: BeforeKitchenStaging1,
        afterImage: AfterKitchenStaging1,
      });
    } else if (example === "Rustique") {
      setExample({
        name: "Rustique",
        beforeImage: BeforeKitchenStaging2,
        afterImage: AfterKitchenStaging2,
      });
    } else {
      setExample({
        name: "Contemporain",
        beforeImage: BeforeKitchenStaging3,
        afterImage: AfterKitchenStaging3,
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu'est-ce que le home staging de cuisine ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging de cuisine est une technique de marketing immobilier qui se concentre sur la préparation et la présentation de la cuisine d'une propriété de manière attrayante pour les acheteurs ou locataires potentiels. Cela peut inclure un nettoyage en profondeur, l'organisation, la décoration stratégique et des améliorations mineures pour mettre en valeur les points forts de la cuisine et minimiser ses défauts.",
        },
      },
      {
        "@type": "Question",
        name: "Quels sont les avantages du home staging virtuel pour les cuisines ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le home staging virtuel pour les cuisines offre plusieurs avantages, notamment : la possibilité de montrer le potentiel de la cuisine sans changements physiques coûteux, la capacité d'expérimenter rapidement différents styles et configurations, l'amélioration de la présentation en ligne de la propriété, et l'opportunité de se démarquer sur un marché immobilier concurrentiel.",
        },
      },
      {
        "@type": "Question",
        name: "Comment fonctionne le processus de home staging virtuel pour les cuisines ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le processus de home staging virtuel pour les cuisines implique le téléchargement de photos de la cuisine actuelle sur notre plateforme, la sélection des options d'édition souhaitées (comme le changement de finitions, l'ajout ou la suppression d'éléments, ou la modification de l'agencement), puis la réception d'images éditées montrant la cuisine transformée.",
        },
      },
      {
        "@type": "Question",
        name: "Combien coûte le home staging virtuel de cuisine ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Le prix de notre service de home staging virtuel, qui inclut les cuisines et d'autres zones de la maison, est de 29 € plus TVA par mois. Ce forfait vous permet de générer jusqu'à 100 images par mois, sans engagement à long terme.",
        },
      },
    ],
  };

  const [stagingExample, setStagingExample] = useState({
    name: "stagingExample1",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const stagingExamples = [
    {
      name: "stagingExample1",
      beforeImage: BeforeKitchenStaging1,
      afterImage: AfterKitchenStaging1,
    },
    {
      name: "stagingExample2",
      beforeImage: BeforeKitchenStaging2,
      afterImage: AfterKitchenStaging2,
    },
    {
      name: "stagingExample3",
      beforeImage: BeforeKitchenStaging3,
      afterImage: AfterKitchenStaging3,
    },
  ];

  const handleStagingExample = (exampleName) => {
    const selectedExample = stagingExamples.find(
      (example) => example.name === exampleName
    );
    if (selectedExample) {
      setStagingExample(selectedExample);
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">Home Staging de Cuisine</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Le meilleur home staging virtuel de cuisine à portée de main
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Transformez votre cuisine en
                    quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Concevez des cuisines attrayantes
                    sans effort
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyper-réalistes pour
                    les cuisines
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance experte en design de
                    cuisine
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29 € pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    {/* Ajoutez ici les images et textes de témoignages */}
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant le home staging virtuel de cuisine"}
                  altAfter={"Après le home staging virtuel de cuisine"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Moderne" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Moderne")}
                    >
                      Exemple de cuisine 1
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Traditionnel" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Traditionnel")}
                    >
                      Exemple de cuisine 2
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Rustique" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Rustique")}
                    >
                      Exemple de cuisine 3
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Contemporain" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Contemporain")}
                    >
                      Exemple de cuisine 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Home staging virtuel de cuisine pour{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            vendre plus rapidement
          </span>{" "}
          tout type de propriété.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">
                  Renouvelez votre cuisine virtuellement
                </h3>
                <div className="feature-text">
                  Transformez votre cuisine en un clic, sans travaux ni dépenses
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">
                  Mettez en valeur les caractéristiques uniques
                </h3>
                <div className="feature-text">
                  Soulignez les points forts de votre cuisine avec du texte
                  descriptif
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">
                  Améliorez et corrigez les photos de cuisine
                </h3>
                <div className="feature-text">
                  Optimisez la qualité et la perspective de vos photos de
                  cuisine
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">
                  Ajoutez des appareils modernes
                </h3>
                <div className="feature-text">
                  Mettez à jour votre cuisine avec les derniers appareils
                  virtuellement
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Renouvelez les finitions</h3>
                <div className="feature-text">
                  Changez les plans de travail, les carrelages et les finitions
                  en un seul clic
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">
                  Nettoyez et organisez virtuellement
                </h3>
                <div className="feature-text">
                  Supprimez le désordre et les objets indésirables pour une
                  cuisine impeccable
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Exemples de notre home staging virtuel de cuisine.
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="hero-example-container">
          <CurtainEffect
            beforeImage={stagingExample.beforeImage}
            afterImage={stagingExample.afterImage}
            altBefore={"Avant le home staging virtuel de cuisine"}
            altAfter={"Après le home staging virtuel de cuisine"}
          />

          <div style={{ display: "flex", flex: "1 0 0" }}></div>
          <div className="hero-example-buttons-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "6px",
                width: "100%",
              }}
            >
              {stagingExamples.map((item) => (
                <div
                  key={item.name}
                  className={`hero-button ${
                    stagingExample.name === item.name ? "selected" : ""
                  }`}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "50px",
                    padding: "0px",
                  }}
                  onClick={() => handleStagingExample(item.name)}
                >
                  <img
                    src={item.beforeImage}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container" style={{ display: "inline" }}>
          Comment fonctionne le home staging virtuel de cuisine ?
        </h3>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 1
            </h5>
            <h2>Téléchargez des photos de votre cuisine</h2>
            <div className="value-prop-text">
              Téléchargez une ou plusieurs images de votre cuisine sur Pedra.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="Comment télécharger des photos de cuisine pour créer un home staging virtuel dans Pedra – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 2
            </h5>
            <h2>Choisissez les options de transformation pour votre cuisine</h2>
            <div className="value-prop-text">
              Sélectionnez parmi diverses options pour rénover virtuellement
              votre cuisine.
              <p>
                Vous pouvez changer les finitions, ajouter des appareils,
                réorganiser l'espace et bien plus encore.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Comment choisir les options pour créer un home staging virtuel de cuisine – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              ÉTAPE 3
            </h5>
            <h2>Recevez et téléchargez votre cuisine transformée</h2>
            <div className="value-prop-text">
              En quelques minutes, vous obtiendrez des images réalistes de votre
              cuisine rénovée.
              <p>
                Téléchargez les images et utilisez-les pour promouvoir votre
                propriété.
              </p>
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Comment télécharger un home staging virtuel de cuisine – Infographie"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Home staging virtuel de cuisine pour 29 € par mois. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            Et sans engagement.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Forfait Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29 €</div>
              <div className="modal-product-plan-description-text-3">
                par mois
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Inclus</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 générations d'images
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>par mois</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Rénovation et redécoration de cuisines
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Changement des finitions de cuisine
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Ajout d'appareils électroménagers
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Suppression d'objets indésirables
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Augmentation de la résolution des photos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Modification de l'agencement de la
              cuisine
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Amélioration de la photographie de
              cuisine
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Plans de cuisine illimités
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Partage de projets illimité
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Ajout de filigranes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Support illimité
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Annulez quand vous voulez.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Achetez plus d'images à 0,20 € par image si vous en avez besoin.
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Questions et réponses sur le home staging de cuisine
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                Qu'est-ce que le home staging de cuisine ?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Le home staging de cuisine est une technique de marketing
                immobilier qui se concentre sur la préparation et la
                présentation de la cuisine d'une propriété de manière attrayante
                pour les acheteurs ou locataires potentiels. Cela peut inclure
                un nettoyage en profondeur, l'organisation, la décoration
                stratégique et des améliorations mineures pour mettre en valeur
                les points forts de la cuisine et minimiser ses défauts.
                <br />
                <br />
                Dans le cas du home staging virtuel de cuisine, nous utilisons
                une technologie avancée pour transformer numériquement l'espace,
                montrant son potentiel sans avoir besoin de changements
                physiques coûteux.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quels sont les avantages du home staging virtuel pour les
                cuisines ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Le home staging virtuel pour les cuisines offre de nombreux
                avantages :
                <br />
                <br />
                1. Économies de coûts : Pas besoin de rénovations physiques
                coûteuses.
                <br />
                2. Gain de temps : Les transformations sont réalisées en
                minutes, pas en semaines.
                <br />
                3. Polyvalence : Vous pouvez présenter plusieurs styles et
                configurations de la même cuisine.
                <br />
                4. Attrait visuel : Aide les acheteurs à visualiser le potentiel
                de la cuisine.
                <br />
                5. Différenciation sur le marché : Démarquez votre propriété
                avec des images de cuisine attrayantes.
                <br />
                6. Pas de dérangement : Pas besoin de perturber la vie des
                occupants actuels.
                <br />
                7. Amélioration de la présentation en ligne : Idéal pour les
                annonces immobilières sur internet.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Que puis-je faire avec le home staging de cuisine de Pedra ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Avec le home staging virtuel de cuisine de Pedra, vous pouvez
                effectuer une grande variété de transformations :
                <br />
                <br />
                1. Rénover les cuisines : Vous pouvez utiliser les styles
                prédéfinis de Pedra ou créer vos propres styles personnalisés
                pour transformer complètement l'aspect de la cuisine.
                <br />
                2. Améliorer la lumière et la couleur : Optimisez l'éclairage et
                les couleurs des photographies de cuisine pour les rendre plus
                attrayantes et professionnelles.
                <br />
                3. Supprimer des objets : Effacez les éléments indésirables des
                photos, tels que les vieux appareils électroménagers, le
                désordre ou les objets personnels.
                <br />
                4. Ajouter des éléments : Incorporez de nouveaux objets dans la
                cuisine, comme des appareils modernes, des accessoires
                décoratifs ou des meubles supplémentaires.
                <br />
                5. Changer les finitions : Modifiez l'apparence des plans de
                travail, des carrelages, des armoires et d'autres finitions pour
                montrer différentes options de design.
                <br />
                6. Redistribuer l'espace : Visualisez différentes configurations
                de cuisine sans avoir besoin de changements physiques.
                <br />
                <br />
                Toutes ces transformations sont réalisées de manière virtuelle,
                vous permettant de montrer le potentiel de la cuisine rapidement
                et économiquement.
              </div>
            )}
          </div>

          {/* Ajoutez plus de FAQ ici */}
        </div>
      </div>
    </div>
  );
}

export default HomeStagingKitchenFr;
