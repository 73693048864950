import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import HowToRemoveObjectFrame1 from "../Images/HowToRemoveObjectFrame1.jpg";
import HowToRemoveObjectFrame2 from "../Images/HowToRemoveObjectFrame2Es.jpg";

function HowToRemoveObjectPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Como Remover Objetos de Fotos</h1>
          <div className="title-secondary gray">
            Tutorial sobre Como Remover Objetos de Fotos
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Por que usar a ferramenta de remoção de objetos da Pedra?
            </h2>
            <p className="article-text">
              A ferramenta de remoção de objetos da Pedra permite limpar suas
              fotos de imóveis removendo elementos indesejados em segundos. Isso
              é particularmente útil para:
              <ul style={{ marginTop: "10px" }}>
                <li>Remover móveis antigos ou indesejados</li>
                <li>Limpar a desordem e objetos pessoais</li>
                <li>Eliminar elementos temporários como placas ou cartazes</li>
                <li>Remover carros ou objetos do exterior</li>
              </ul>
              <p style={{ marginTop: "10px" }}>
                Com a Pedra, você não precisa mais de habilidades avançadas em
                edição de fotos para criar imagens limpas e profissionais dos
                seus imóveis.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como remover objetos de suas fotos?
            </h2>
            <p className="article-text">
              Comece fazendo upload da foto da qual você deseja remover objetos.
              <p style={{ marginTop: "10px" }}>
                Selecione sua imagem e clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>,
                depois escolha a opção{" "}
                <span style={{ fontWeight: "500" }}>"Remover Objetos"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRemoveObjectFrame1}
                alt="Como Usar a Ferramenta de Remoção de Objetos – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Remoção Rápida de Objetos:{" "}
              </span>
              Remover um Objeto Rapidamente
            </h2>
            <p style={{ marginTop: "10px" }}>
              A ferramenta{" "}
              <span style={{ fontWeight: "500" }}>"Remover Objetos"</span>{" "}
              eliminará o objeto selecionado em cerca de 15 segundos.
              <p>
                Desenhe sobre o objeto que você deseja remover. A ferramenta
                preencherá a área automaticamente com base no ambiente ao redor.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRemoveObjectFrame2}
                alt="Como Usar a Ferramenta de Remoção de Objetos – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Dicas para Remoção de Objetos Eficaz
            </h2>
            <p className="article-text">
              Para obter os melhores resultados ao remover objetos:
              <p style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <span style={{ fontWeight: "500" }}>
                      Seja preciso ao desenhar
                    </span>
                    . Cubra todo o objeto que deseja remover, mas evite incluir
                    áreas extras desnecessárias.
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>
                      Remova um objeto por vez
                    </span>
                    . Para múltiplos objetos, faça remoções separadas para cada
                    um.
                  </li>
                  <li>
                    <span style={{ fontWeight: "500" }}>Considere o fundo</span>
                    . A remoção funciona melhor quando o fundo ao redor do
                    objeto é relativamente uniforme ou tem um padrão
                    consistente.
                  </li>
                </ul>
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Selecione o Objeto a Remover
            </h3>
            <p className="article-text">
              Use a ferramenta de desenho para marcar o objeto que você deseja
              remover. Seja preciso mas certifique-se de cobrir todo o objeto.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você pode ajustar o tamanho do pincel
                </span>{" "}
                para maior precisão ao selecionar objetos pequenos ou
                detalhados.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Gere a Imagem sem o Objeto
            </h3>
            <p className="article-text">
              Clique em "Gerar" para criar uma nova versão da imagem sem o
              objeto selecionado.
              <p style={{ marginTop: "10px" }}>
                A IA analisará o ambiente ao redor e preencherá a área de forma
                natural e realista.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Refine se Necessário
            </h3>
            <p className="article-text">
              Se o resultado não for perfeito na primeira tentativa:
              <ul style={{ marginTop: "10px" }}>
                <li>Tente gerar novamente para um resultado diferente</li>
                <li>Ajuste a área selecionada e tente novamente</li>
                <li>Remova objetos menores separadamente</li>
              </ul>
              <br />
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Dicas para melhores resultados</h4>
                <p className="callout-p">
                  Para obter as remoções de objetos mais realistas:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Gere múltiplas versões
                      </span>
                      . Cada geração é única, e você pode encontrar algumas que
                      preenchem o espaço de forma mais natural.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Comece com objetos menores
                      </span>
                      . Remova primeiro os objetos menores antes de tentar
                      remover elementos maiores.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Considere o ângulo da foto
                      </span>
                      . Fotos frontais geralmente produzem melhores resultados
                      para remoção de objetos.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRemoveObjectPt;
