import React from "react";
import ReleaseOct2024Frame1 from "../Images/ReleaseOct2024Frame1.png";
import ReleaseOct2024Frame2 from "../Images/ReleaseOct2024Frame2.png";
import ReleaseOct2024Frame3 from "../Images/ReleaseOct2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseOct24De() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">OKTOBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: Pool-Verbesserungen, Raumerhaltung und selektive Renovierung
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              In diesem Oktober bringen wir Ihnen Verbesserungen, um noch mehr
              aus Pedra herauszuholen:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Wasser in Pools hinzufügen</h2>
              Zeigen Sie keine leeren Pools – das hilft nicht beim Verkauf.
              Jetzt können Sie mit Pedra Pools mit Wasser füllen, um Ihre
              Immobilie in ihrem besten Zustand zu präsentieren.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame1}
                  alt="Beispiel eines Pools mit Wasser"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Bessere Erhaltung von Böden und Wänden beim Leeren von Räumen
              </h2>
              Früher hat Pedra manchmal die Materialien von Böden und Wänden
              verändert. Wir haben einige Änderungen vorgenommen, damit dies
              nicht mehr passiert.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame2}
                  alt="Beispiel für die Erhaltung von Boden und Wänden"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Bestimmte Bildteile bei der Renovierung erhalten
              </h2>
              Jetzt können Sie Pedra genau mitteilen, welche Teile Sie erhalten
              möchten. Ideal für die Beibehaltung des Zustands von Gebäuden, die
              an Ihre Immobilie angrenzen.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame3}
                  alt="Beispiel für selektive Erhaltung"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Weitere Verbesserungen</h2>
            <div>
              <li>Bessere Ergebnisse beim Entfernen kleiner Objekte</li>
              <li>
                Bessere Erhaltung der Raumarchitektur bei Renovierungen mit
                niedriger Kreativität
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseOct24De;
