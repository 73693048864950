import React from "react";
import ReleaseJul2024Frame1 from "../Images/ReleaseJul2024Frame1.png";
import ReleaseJul2024Frame2 from "../Images/ReleaseJul2024Frame2.png";
import ReleaseJul2024Frame3 from "../Images/ReleaseJul2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseJul24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">JULHO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Adicione objetos 3D, deixe o céu azul em 1 clique e aprimore
            ou ilumine imagens automaticamente
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Aqui estão os lançamentos deste julho – espero que você fique
              animado para testá-los na Pedra!
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Adicione objetos 3D às suas fotos
              </h2>
              Agora você pode escolher os móveis para adicionar às suas fotos.
              Com a opção Adicionar Objeto, você pode selecionar seu tamanho,
              orientação e disposição. Você pode ver como{" "}
              <Link to="/help/how-to-add-3d-objects" className="article-link">
                usar adicionar objetos 3D aqui
              </Link>
              .
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame1}
                  alt="Renovar espaços com IA pelo celular"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Deixe o céu limpo em suas fotos em 1 clique
              </h2>
              Todos queremos que nossas fotos mostrem um céu limpo e bonito.
              Agora você pode colocar um céu azul em suas fotos com apenas 1
              clique e ~30 segundos.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseJul2024Frame2}
                  alt="Mover e editar a transparência da marca d'água"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Combinamos as opções Embelezar e Iluminar
              </h2>
              Ao clicar em Embelezar, a Pedra detectará se as fotos têm baixa
              luminosidade e automaticamente melhorará a luz, cor e contraste.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseJul2024Frame3}
                alt="Alterar a legenda de uma criação com IA"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>

            <div>
              <li>
                Melhor qualidade ao preservar janelas na "Renovação Avançada"
              </li>
              <li>
                Redução de erros na "Renovação Avançada" com preservação de
                janelas
              </li>
              <li>Experiência mais intuitiva ao pintar sobre imagens</li>
              <li>Opção para baixar faturas nas configurações</li>
              <li>Melhorias de usabilidade e várias correções</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseJul24Pt;
