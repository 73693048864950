import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageBedroomFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeBedroom from "../Images/curtain-effect-hero/BeforeBedroom.png";
import AfterBedroom from "../Images/curtain-effect-hero/AfterBedroom.png";
import BeforeBedroomStaging1 from "../Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "../Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "../Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "../Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "../Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "../Images/curtain-effect/AfterBedroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingBedroomDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie virtuelles Home Staging für Schlafzimmer
          </h1>
          <h2 className="title-secondary gray">
            Tutorial zur Erstellung von virtuellem Home Staging für Schlafräume
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wie kann ich virtuelles Home Staging für ein Schlafzimmer
              erstellen?
            </h2>
            <p className="article-text">
              Mit Pedra können Sie virtuelles Home Staging für Schlafzimmer mit
              nur wenigen Klicks erstellen.
              <p>
                Öffnen Sie zunächst das{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                Menü und wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>. Wählen
                Sie "Schlafzimmer" als Raumtyp aus, und in etwa 2 bis 3 Minuten
                haben Sie Ihr virtuelles Home Staging-Bild des Schlafzimmers zum
                Download bereit.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="So erstellen Sie virtuelles Home Staging für ein Schlafzimmer – Beispiele"
            />
            <br />
            <h2 className="article-subtitleh2">
              Wie erstellt man virtuelles Home Staging für Schlafzimmer?
            </h2>
            <p className="article-text">
              Um Ihr{" "}
              <Link className="article-link" to="/home-staging-bedroom">
                virtuelles Home Staging für ein Schlafzimmer
              </Link>{" "}
              zu erstellen, folgen Sie diesen einfachen Schritten:
            </p>
            <ol>
              <li>
                Laden Sie das Bild des Schlafzimmers hoch, das Sie virtuell
                möblieren möchten
              </li>
              <li>
                Wählen Sie das Bild aus, um mit der Bearbeitung zu beginnen
              </li>
              <li>
                Klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>{" "}
                und dann auf{" "}
                <span style={{ fontWeight: "500" }}>"Möblieren"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menü zur Erstellung von virtuellem Home Staging für ein Schlafzimmer"
            />
            <p className="article-text">
              Unter <span style={{ fontWeight: "500" }}>"Möblieren"</span>{" "}
              müssen Sie diese Schritte befolgen:
            </p>
            <ol>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>Möbelposition</span>. Für
                Schlafzimmer empfehlen wir "Zeichnen", um bestimmte Bereiche für
                Bett, Schränke, Nachttische usw. anzugeben.
              </li>
              <li>
                Wählen Sie{" "}
                <span style={{ fontWeight: "500" }}>"Schlafzimmer"</span> als
                Raumtyp. Dies stellt sicher, dass Pedra passende Möbel wie
                Betten, Schränke, Kommoden usw. platziert.
              </li>
              <li>
                Wählen Sie den <span style={{ fontWeight: "500" }}>Stil</span>.
                Für Schlafzimmer funktionieren Stile wie "Modern", "Klassisch"
                oder "Minimalistisch" normalerweise gut.
              </li>
              <li>
                Wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>
                  Anzahl der zu generierenden Bilder
                </span>
                . Wir empfehlen 2 oder 3 Bilder, um verschiedene
                Schlafzimmerdesign-Optionen zu haben.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tipps für besseres virtuelles Home Staging eines Schlafzimmers:
                <ul>
                  <li>
                    Stellen Sie sicher, dass die Fotografie die Abmessungen des
                    Schlafzimmers deutlich zeigt, für eine genauere Möblierung
                  </li>
                  <li>
                    Wenn das Schlafzimmer besondere Merkmale wie große Fenster
                    oder hohe Decken hat, versuchen Sie diese im Foto
                    einzufangen, für ein attraktiveres Ergebnis
                  </li>
                  <li>
                    Nutzen Sie die Option "Zeichnen", um spezifische Bereiche
                    für das Bett, Schränke, Leseecke oder dekorative Elemente
                    anzugeben
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Beispiel für die Erstellung von virtuellem Home Staging für ein Schlafzimmer"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              Warum virtuelles Home Staging für ein Schlafzimmer erstellen?
            </h2>
            <p className="article-text">
              Ein gut präsentiertes Schlafzimmer kann ein großer Vorteil für
              potenzielle Käufer sein. Virtuelles Home Staging für Schlafzimmer
              ermöglicht es Ihnen, das Potenzial dieser Ruheräume zu zeigen und
              gemütliche und funktionale Umgebungen zu schaffen, die den Käufern
              helfen, sich erholsamen Schlaf und Entspannung vorzustellen.
            </p>
            <p className="article-text">
              Mit gutem virtuellem Home Staging für ein Schlafzimmer können Sie:
            </p>
            <ul>
              <li>Das Potenzial leerer oder unattraktiver Räume zeigen</li>
              <li>Eine Atmosphäre von Komfort und Ruhe schaffen</li>
              <li>
                Merkmale wie Helligkeit, Stauraum oder die Vielseitigkeit des
                Schlafzimmers hervorheben
              </li>
              <li>
                Käufern helfen, sich Momente der Entspannung und Ruhe im
                Schlafzimmer vorzustellen
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Beispiele für virtuelles Home Staging von Schlafzimmern
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele für virtuelles Home Staging, das auf
              Schlafzimmer angewendet werden kann:
            </p>
            <CurtainEffect
              beforeImage={BeforeBedroom}
              afterImage={AfterBedroom}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging1}
              afterImage={AfterBedroomStaging1}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging2}
              afterImage={AfterBedroomStaging2}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging3}
              afterImage={AfterBedroomStaging3}
              altBefore={"Vor der Erstellung des virtuellen Home Stagings"}
              altAfter={"Nach der Erstellung des virtuellen Home Stagings"}
            />
            <br />
            {/*
            <h2 className="article-subtitleh2">
              Video zur Erstellung von virtuellem Home Staging für Schlafzimmer
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/YOUTUBE_VIDEO_ID"
                  title="Tutorial zum virtuellen Home Staging für Schlafzimmer"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
            */}
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingBedroomDe;
