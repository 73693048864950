import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderTerraceDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie Terrassen-Renderings
          </h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Terrassen-Renderings
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Warum sollten Sie Pedras Rendering-Tools für Terrassen nutzen?
            </h2>
            <p className="article-text">
              Mit Pedras Rendering-Tools können Sie in wenigen Sekunden eine
              komplette Terrassenumgestaltung visualisieren und potenziellen
              Käufern das wahre Potenzial des Außenbereichs zeigen.
              <p style={{ marginTop: "10px" }}>
                Terrassenrenovierungen können den Wert einer Immobilie deutlich
                steigern, da Außenwohnbereiche für Käufer immer wichtiger
                geworden sind. Die Möglichkeit, das Potenzial einer ungenutzten
                Terrasse aufzuzeigen, kann beim Immobilienverkauf einen großen
                Unterschied machen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit Pedra benötigen Sie keine spezialisierten
                Landschaftsarchitekten oder 3D-Rendering-Experten mehr, um
                überzeugende Terrassenrenovierungsvorschläge zu erstellen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich ein Terrassen-Rendering?
            </h2>
            <p className="article-text">
              Laden Sie zunächst klare Fotos der bestehenden Terrasse hoch, die
              Sie umgestalten möchten.
              <p style={{ marginTop: "10px" }}>
                Wählen Sie Ihre Terrassenbilder aus und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                dann wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>"Renovieren"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="So nutzen Sie das Renovierungstool zur Erstellung von Terrassen-Renderings – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Schnelle Terrassenrenovierung:{" "}
              </span>
              Erstellen Sie ein schnelles Terrassen-Rendering
            </h2>
            <p style={{ marginTop: "10px" }}>
              Das <span style={{ fontWeight: "500" }}>"Renovieren"</span> Tool
              verwandelt Ihre Terrasse in etwa 25 Sekunden.
              <p>
                Für eine dezente Aktualisierung wählen Sie den
                "Minimalistischen" Stil mit "Niedriger" Kreativität. Dies behält
                das grundlegende Layout bei, während Bodenbeläge, Möbel und
                Landschaftselemente modernisiert werden.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="So nutzen Sie das Renovierungstool zur Erstellung von Terrassen-Renderings – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Passen Sie Ihr Terrassen-Renovierungs-Rendering an
            </h2>
            <p className="article-text">
              Pedra bietet umfangreiche Anpassungsmöglichkeiten für
              Terrassen-Renderings.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Wählen Sie aus verschiedenen Terrassenstilen, steuern Sie, wie
                  dramatisch die Änderungen sein sollen, und entscheiden Sie, ob
                  bestehende Elemente wie Geländer oder strukturelle Merkmale
                  erhalten bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="So nutzen Sie das Renovierungstool zur Erstellung von Terrassen-Renderings – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Wählen Sie Ihren Terrassenstil
            </h3>
            <p className="article-text">
              Wählen Sie aus 9 verschiedenen Terrassenstilen, darunter modern,
              tropisch, mediterran und mehr.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Referenzbilder von Terrassenstilen hochladen,
                  die Ihnen gefallen.
                </span>{" "}
                So können Sie Renderings erstellen, die bestimmten
                Designinspirationen oder dem bevorzugten Außendesign Ihres
                Kunden entsprechen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Legen Sie die Renovierungsintensität fest
            </h3>
            <p className="article-text">
              Wählen Sie zwischen niedriger, mittlerer, hoher und sehr hoher
              Kreativität für Ihre Terrassenumgestaltung.
              <p style={{ marginTop: "10px" }}>
                Niedrige und mittlere Einstellungen aktualisieren Bodenbeläge,
                Möbel und Pflanzen, während das grundlegende Terrassen-Layout
                erhalten bleibt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hohe und sehr hohe Einstellungen können den Raum mit neuen
                Layouts, Pergolen und architektonischen Elementen komplett neu
                gestalten.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Bewahren Sie wichtige Terrassenelemente
            </h3>
            <p className="article-text">
              Wählen Sie, ob strukturelle Elemente wie Geländer oder bestehende
              Merkmale erhalten bleiben sollen. Dies stellt sicher, dass Ihr
              Rendering praktische Elemente beibehält, während das Gesamtdesign
              aktualisiert wird.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Generieren Sie Ihr Terrassen-Rendering
            </h2>
            <p>
              Nach der Auswahl Ihrer Präferenzen generieren Sie das
              Terrassen-Rendering. Standard-Renderings dauern etwa 15 Sekunden,
              während das Bewahren bestimmter Elemente etwas länger dauern kann.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps für bessere Terrassen-Renderings
                </h4>
                <p className="callout-p">
                  Um die realistischsten und nützlichsten Terrassen-Renderings
                  zu erhalten:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Erstellen Sie mehrere Versionen
                      </span>
                      . Jedes Rendering ist einzigartig, und Sie finden
                      vielleicht einige, die Außenelemente besser integrieren
                      oder den verfügbaren Platz besser nutzen.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Verwenden Sie niedrige Kreativität für dezente
                      Aktualisierungen einfacher Terrassen.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Machen Sie Fotos bei Tageslicht und erfassen Sie den
                        gesamten Raum
                      </span>
                      . Dies hilft der KI, das Terrassen-Layout besser zu
                      verstehen und genauere Transformationen zu erstellen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Beispiele für Terrassen-Renderings
            </h2>
            <p className="article-text">
              Hier sind einige Beispiele für Terrassenrenovierungen, die mit
              unseren Renderings erstellt wurden:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Terrasse vor dem Rendering"}
              altAfter={"Terrasse nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Terrasse vor dem Rendering"}
              altAfter={"Terrasse nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Terrasse vor dem Rendering"}
              altAfter={"Terrasse nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Terrasse vor dem Rendering"}
              altAfter={"Terrasse nach dem Rendering"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderTerraceDe;
