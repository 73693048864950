import React from "react";

const ToggleIconUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M7.49998 4.99997C7.3271 4.99997 7.17965 5.0661 7.04744 5.19828L3.16779 9.16436C3.05593 9.27623 3 9.41351 3 9.57623C3 9.90674 3.25424 10.1711 3.58474 10.1711C3.74745 10.1711 3.89491 10.105 4.01186 9.99318L7.49998 6.41353L10.9881 9.99318C11.1 10.1 11.2474 10.1711 11.4101 10.1711C11.7458 10.1711 12 9.90674 12 9.57623C12 9.41351 11.9441 9.27623 11.8322 9.16436L7.95252 5.19828C7.82032 5.06097 7.67287 4.99997 7.49998 4.99997Z"
        fill="#878787"
      />
    </svg>
  );
};

export default ToggleIconUp;
