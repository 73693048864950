import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseDec2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseDec2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseDec24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">DEZEMBRO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Novos recursos para construções novas e espaços comerciais
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Estamos terminando o ano trazendo melhorias para ajudar a Pedra a
              auxiliar você com mais tipos de vendas de imóveis :)
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Criar renders a partir de plantas baixas
              </h2>
              Gostaria de converter suas plantas baixas em imagens realistas
              instantaneamente? Agora com a Pedra é possível. Faça upload de uma
              imagem da planta baixa e gere imagens de todos os ambientes em 1
              clique. Perfeito para construções novas.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Renders a partir de plantas baixas"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Renovar e mobiliar espaços comerciais
              </h2>
              Agora você tem a opção de escolher o tipo de espaço que deseja ao
              renovar ou mobiliar seus ambientes: restaurante, bar, loja,
              supermercado, salão de beleza... O que você quiser!
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Espaços comerciais"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>
            <div>
              <li>
                Melhores resultados ao usar "Mobiliar" para mobiliar ambientes
              </li>
              <li>
                Opção de contratar serviço profissional de mobiliamento virtual
                de ambientes por €6 por imagem
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseDec24Pt;
