import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeBedroomStaging1 from "../Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "../Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "../Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "../Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "../Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "../Images/curtain-effect/AfterBedroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderBedroomDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So erstellen Sie Schlafzimmer-Renderings
          </h1>

          <div className="title-secondary gray">
            Tutorial zur Erstellung von Schlafzimmer-Renderings
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Warum sollten Sie Pedras Rendering-Tools für Schlafzimmer nutzen?
            </h2>
            <p className="article-text">
              Mit Pedras Rendering-Tools können Sie in wenigen Sekunden eine
              komplette Schlafzimmer-Transformation visualisieren und
              potenziellen Käufern das wahre Potenzial des Raums zeigen.
              <p style={{ marginTop: "10px" }}>
                Schlafzimmerrenovierungen sind essentiell, da sie zu den
                persönlichsten und wichtigsten Räumen jedes Zuhauses gehören.
                Die Möglichkeit, das Potenzial eines veralteten Schlafzimmers
                aufzuzeigen, kann beim Verkauf von gebrauchten Immobilien einen
                großen Unterschied machen.
              </p>
              <p style={{ marginTop: "10px" }}>
                Mit Pedra benötigen Sie keine spezialisierten Innenarchitekten
                oder 3D-Rendering-Experten mehr, um überzeugende
                Schlafzimmer-Renovierungsvorschläge zu erstellen.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Wie erstelle ich ein Schlafzimmer-Rendering?
            </h2>
            <p className="article-text">
              Laden Sie zunächst klare Fotos des bestehenden Schlafzimmers hoch,
              das Sie transformieren möchten.
              <p style={{ marginTop: "10px" }}>
                Wählen Sie Ihre Schlafzimmerbilder aus und klicken Sie auf{" "}
                <span style={{ fontWeight: "500" }}>"Mit KI bearbeiten"</span>,
                dann wählen Sie die{" "}
                <span style={{ fontWeight: "500" }}>"Renovierung"</span> Option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Wie man das Renovierungs-Tool zur Erstellung von Schlafzimmer-Renderings verwendet – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Schnelle Schlafzimmerrenovierung:{" "}
              </span>
              Erstellen Sie ein schnelles Schlafzimmer-Rendering
            </h2>
            <p style={{ marginTop: "10px" }}>
              Das <span style={{ fontWeight: "500" }}>"Renovierungs"</span>-Tool
              transformiert Ihr Schlafzimmer in etwa 25 Sekunden.
              <p>
                Für eine subtile Aktualisierung wählen Sie den
                "Minimalistischen" Stil mit "Niedriger" Kreativität. Dies behält
                das grundlegende Layout bei, während Oberflächen und Möbel
                modernisiert werden.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Wie man das Renovierungs-Tool zur Erstellung von Schlafzimmer-Renderings verwendet – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Passen Sie Ihr Schlafzimmer-Renovierungs-Rendering an
            </h2>
            <p className="article-text">
              Pedra bietet umfangreiche Anpassungsoptionen für
              Schlafzimmer-Renderings.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Wählen Sie aus verschiedenen Schlafzimmerstilen, steuern Sie,
                  wie dramatisch die Änderungen sein sollen, und entscheiden
                  Sie, ob bestehende Elemente wie Fenster oder architektonische
                  Merkmale erhalten bleiben sollen.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Wie man das Renovierungs-Tool zur Erstellung von Schlafzimmer-Renderings verwendet – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Wählen Sie Ihren Schlafzimmerstil
            </h3>
            <p className="article-text">
              Wählen Sie aus 9 verschiedenen Schlafzimmerstilen, darunter
              minimalistisch, traditionell, mediterran und mehr.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Sie können auch Referenzbilder von Schlafzimmerstilen
                  hochladen, die Ihnen gefallen.
                </span>{" "}
                Dies ermöglicht es Ihnen, Renderings zu erstellen, die
                spezifischer Design-Inspiration oder dem bevorzugten Stil Ihres
                Kunden entsprechen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 – </span>
              Legen Sie die Renovierungsintensität fest
            </h3>
            <p className="article-text">
              Wählen Sie zwischen niedriger, mittlerer, hoher und sehr hoher
              Kreativität für Ihre Schlafzimmer-Transformation.
              <p style={{ marginTop: "10px" }}>
                Niedrige und mittlere Einstellungen aktualisieren Oberflächen
                und Möbel, während das grundlegende Raumlayout erhalten bleibt.
              </p>
              <p style={{ marginTop: "10px" }}>
                Hohe und sehr hohe Einstellungen können den Raum komplett neu
                gestalten mit neuen Layouts, Beleuchtungskonfigurationen und
                architektonischen Änderungen.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Bewahren Sie wichtige Schlafzimmerelemente
            </h3>
            <p className="article-text">
              Wählen Sie die Option, Fenster zu erhalten, was besonders wichtig
              ist für Schlafzimmer mit Ausblick oder speziellen
              Beleuchtungsanforderungen. Dies stellt sicher, dass Ihr Rendering
              praktische Elemente beibehält, während die Ästhetik aktualisiert
              wird.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 4 – </span>
              Generieren Sie Ihr Schlafzimmer-Rendering
            </h2>
            <p>
              Nach der Auswahl Ihrer Präferenzen generieren Sie das
              Schlafzimmer-Rendering. Standard-Renderings dauern etwa 15
              Sekunden, während das Erhalten spezifischer Elemente etwas länger
              dauern kann.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tipps für bessere Schlafzimmer-Renderings
                </h4>
                <p className="callout-p">
                  Um die realistischsten und nützlichsten
                  Schlafzimmer-Renderings zu erhalten:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generieren Sie mehrere Versionen
                      </span>
                      . Jedes Rendering ist einzigartig, und Sie finden
                      möglicherweise einige, die wichtige Merkmale wie
                      architektonische Details oder natürliches Licht besser
                      erhalten.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Verwenden Sie niedrige Kreativität für subtile
                      Aktualisierungen veralteter Schlafzimmer.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Sorgen Sie für gute Beleuchtung beim Fotografieren und
                        minimieren Sie Reflexionen
                      </span>
                      . Dies hilft der KI, den Raum besser zu verstehen und
                      genauere Transformationen zu erstellen.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Schlafzimmer-Rendering Beispiele
            </h2>
            <p className="article-text">
              Hier sind mehrere Beispiele von Schlafzimmerrenovierungen, die mit
              unseren Renderings erstellt wurden:
            </p>
            <CurtainEffect
              beforeImage={BeforeBedroomStaging1}
              afterImage={AfterBedroomStaging1}
              altBefore={"Schlafzimmer vor dem Rendering"}
              altAfter={"Schlafzimmer nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging2}
              afterImage={AfterBedroomStaging2}
              altBefore={"Schlafzimmer vor dem Rendering"}
              altAfter={"Schlafzimmer nach dem Rendering"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging3}
              afterImage={AfterBedroomStaging3}
              altBefore={"Schlafzimmer vor dem Rendering"}
              altAfter={"Schlafzimmer nach dem Rendering"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderBedroomDe;
