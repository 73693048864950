import React from "react";
import ReleaseAug2024 from "./Images/ReleaseAug2024.png";
import ReleaseJul2024 from "./Images/ReleaseJul2024.png";
import ReleaseJun2024 from "./Images/ReleaseJun2024.png";
import ReleaseMay2024 from "./Images/ReleaseMay2024.png";
import ReleaseSep24 from "./Images/ReleaseSep2024.png";
import ReleaseOct24 from "./Images/ReleaseOct2024.png";
import { Link } from "react-router-dom";
import ReleaseNov24 from "./Images/ReleaseNov2024.png";
import ReleaseDec24 from "./Images/ReleaseDec2024.png";

function ReleasesPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Lançamentos</h1>
          <h2 className="title-secondary">
            Confira os últimos lançamentos da Pedra aqui
            <br />
          </h2>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>DEZEMBRO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/floorplans-and-commercial-spaces"
              >
                Pedra: Renders de plantas baixas e espaços comerciais
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/floorplans-and-commercial-spaces"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseDec24}
                alt="Lançamentos da Pedra do mês de dezembro 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>NOVEMBRO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link className="article-title-on-blog" to="/releases/workspaces">
                Pedra: Espaços de trabalho para colaboração em staging virtual
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link to="/releases/workspaces" style={{ padding: "0px" }}>
              <img
                src={ReleaseNov24}
                alt="Lançamentos da Pedra do mês de novembro 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>OUTUBRO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/pedra-improvements-pools-and-preservation"
              >
                Pedra: Melhorias em piscinas, preservação de espaços e recursos
                de renovação seletiva
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/pedra-improvements-pools-and-preservation"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseOct24}
                alt="Lançamentos da Pedra do mês de outubro 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>SETEMBRO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/renovate-and-furnish-in-one-click"
              >
                Pedra: Renove e mobilie espaços com apenas um clique, resultados
                melhorados de mobiliário em 'Auto' e melhor preservação de
                espaços.
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/renovate-and-furnish-in-one-click"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseSep24}
                alt="Lançamentos da Pedra do mês de setembro 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>AGOSTO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/realistic-furnishing"
              >
                Pedra: Staging virtual realista, renove com seu próprio estilo e
                muito mais
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/realistic-furnishing"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseAug2024}
                alt="Lançamentos da Pedra do mês de agosto 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JULHO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/add-3d-objects-and-make-sky-blue"
              >
                Pedra: Adicione objetos 3D, deixe o céu azul com 1 clique, e
                melhore ou ilumine imagens automaticamente
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/add-3d-objects-and-make-sky-blue"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJul2024}
                alt="Lançamentos da Pedra do mês de julho 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUNHO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/renovate-interiors-with-ai-from-phone"
              >
                Pedra: Renove interiores pelo celular com IA, edite notas de
                rodapé de imagens e edite marcas d'água
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/renovate-interiors-with-ai-from-phone"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJun2024}
                alt="Lançamentos da Pedra do mês de junho 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>MAIO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/empty-rooms-and-remove-objects-with-ai"
              >
                Pedra: Limpe Ambientes e Remova Itens, Estilos de Renovação e
                Nova Interface
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/empty-rooms-and-remove-objects-with-ai"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseMay2024}
                alt="Lançamentos da Pedra do mês de maio 24 – Banner decorativo"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleasesPt;
