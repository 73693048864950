// Pricing.js
import React from "react";
import CheckIcon from "./Icons/CheckIcon";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple";
import EmptyIcon from "./Icons/EmptyIcon";
import HammerIconPricing from "./Icons/HammerIconPricing";
import BrushIconPricing from "./Icons/BrushIconPricing";
import HDIconPricing from "./Icons/HDIconPricing";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon";
import VideoPricingIcon from "./Icons/VideoPricingIcon";
import CustomerLogos from "./CustomerLogos.js";
import ArrowIcon from "./Icons/ArrowIcon";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function PricingEs() {
  return (
    <div
      className="section-one-column-left"
      style={{ display: "flex", flexDirection: "column", gap: "0px" }}
    >
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container" style={{ gap: "0px" }}>
            <div
              className="section-first-title"
              style={{ paddingBottom: "0px" }}
            >
              Prueba nuestro Plan Pro
            </div>
            <h1 className="section-first-text" style={{ fontSize: "20px" }}>
              Paga por mes,
              <span style={{ color: "#F8792A" }}>
                {" "}
                cancela en cualquier momento.
              </span>
            </h1>
            <div className="section-first-button-container"></div>
          </div>
        </section>
      </section>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Plan Pro
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">29€</div>
              <div className="modal-product-plan-description-text-3">
                por mes
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">Incluye</div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 generaciones de imagen
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>por mes</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovar y redecorar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Vaciar habitaciones
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Amueblar
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Quitar objetos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Aumentar resolución
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Cambiar suelos
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Mejorar fotografía
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Generar videos virtuales
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Planos ilimitados
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Compartir proyectos ilimitado
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Añadir marcas de agua
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Soporte ilimitado
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancela cuando quieras.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Compra más imágenes a 0.20€ por imagen si las necesitas.
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="section-one-column-left" style={{ marginBottom: "0px" }}>
        <div className="customer-logo-container">
          MORE THAN 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Habéis pensado en todo! Pedra tiene todo lo que necesitamos!"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Logotipo de Vives Homes para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingEs;
