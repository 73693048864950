import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

function HowToRenovatePt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Usar a Ferramenta de Renovação para Renovar Imóveis
            Virtualmente
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre Como Renovar Imóveis Virtualmente
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Para que servem as ferramentas de renovação virtual da Pedra?
            </h2>
            <p className="article-text">
              Com as ferramentas de renovação da Pedra, você pode mostrar uma
              potencial renovação de um imóvel em apenas alguns segundos.
              <p style={{ marginTop: "10px" }}>
                Mostrar o potencial de uma renovação é ideal para ajudar a
                vender imóveis usados, que provavelmente precisarão de um bom
                investimento do comprador para melhorar sua condição.
              </p>
              <p style={{ marginTop: "10px" }}>
                Graças à Pedra, não é necessário contratar renderizadores ou
                arquitetos para obter uma proposta de renovação para um imóvel.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como criar uma proposta de renovação virtual?
            </h2>
            <p className="article-text">
              Depois de fazer upload das imagens que você deseja renovar,
              selecione-as.
              <p style={{ marginTop: "10px" }}>
                Clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>, e
                você verá a opção{" "}
                <span style={{ fontWeight: "500" }}>"Renovação"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Como Usar a Ferramenta de Renovação para Renovar Imóveis Virtualmente – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Renovação: </span>
              Gerar uma Renovação Virtual Rápida
            </h2>
            <p style={{ marginTop: "10px" }}>
              Ao clicar em{" "}
              <span style={{ fontWeight: "500" }}>"Renovação"</span>, você
              poderá gerar uma proposta de renovação para as imagens
              selecionadas. Você terá o resultado em cerca de 25 segundos.
              <p>
                Se você escolher o estilo "Minimalista" e criatividade "Baixa",
                a opção <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
                gera uma renovação minimalista com um baixo nível de mudança.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Como Usar a Ferramenta de Renovação para Renovar Imóveis Virtualmente – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Gerar uma Renovação Virtual ao Seu Gosto
            </h2>
            <p className="article-text">
              A Pedra oferece uma maneira de obter a renovação que você precisa
              com um alto nível de personalização.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Você pode escolher ou fazer upload dos seus próprios estilos,
                  selecionar o nível de criatividade das mudanças e até mesmo
                  decidir se quer preservar certas partes da imagem original,
                  como as janelas.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Como Usar a Ferramenta de Renovação para Renovar Imóveis Virtualmente – Captura de tela do software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Estilo de renovação
            </h3>
            <p className="article-text">
              A Pedra oferece 9 estilos básicos de renovação avançada:
              minimalista, tradicional, mediterrâneo, etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você também pode fazer upload do seu próprio estilo de
                  renovação.
                </span>{" "}
                Ao fazer upload do seu próprio estilo de renovação, você poderá
                criar propostas de renovação baseadas no estilo que você enviou.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Criatividade da renovação
            </h3>
            <p className="article-text">
              Você também pode escolher o nível de criatividade da renovação
              avançada entre Baixo, Médio, Alto e Muito Alto.
              <p style={{ marginTop: "10px" }}>
                Baixo e Médio preservarão bem a arquitetura da fotografia
                original.
              </p>
              <p style={{ marginTop: "10px" }}>
                Alto e Muito Alto são destinados a mudar agressivamente a
                arquitetura do espaço, então a fotografia resultante não se
                parecerá com a original.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Preservar janelas na renovação
            </h3>
            <p className="article-text">
              Você também tem a opção de preservar as janelas ao realizar uma
              renovação avançada. Isso é importante se você quiser manter as
              vistas das janelas ou se quiser que elas fiquem exatamente iguais
              à fotografia original.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Gerar imagem de renovação
            </h2>
            <p>
              Depois de selecionar o estilo, nível de criatividade e se deseja
              ou não preservar as janelas, você pode gerar as imagens. Levará
              cerca de 15 segundos por imagem, a menos que você preserve as
              janelas, o que levará um pouco mais de tempo.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Como evitar mudanças indesejadas em janelas e portas
                </h4>
                <p className="callout-p">
                  Se aparecerem portas onde havia janelas, ou se houver mais
                  janelas do que o esperado nas imagens geradas, sugerimos as
                  seguintes ações:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Gere 2 ou 3 renovações adicionais
                      </span>
                      . Cada renovação é única, então haverá algumas que
                      preservam melhor as janelas e portas do que outras.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      {" "}
                      Mantenha o nível de criatividade baixo.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Se a fotografia original tiver reflexos ou espelhos que
                        se parecem com janelas, use a ferramenta para removê-los
                        da imagem
                      </span>
                      . Depois, gere a renovação na imagem sem reflexos ou
                      espelhos.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenovatePt;
