// HomeEs.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomerLogos from "./CustomerLogos.js";
import CurtainEffect from "./CurtainEffect.js";

// Images

import REMAXLogo from "./Images/customer-logos/customer-logo-remax.png";
import EXPLogo from "./Images/customer-logos/customer-logo-exp.png";
import ENGELLogo from "./Images/customer-logos/customer-logo-agenthia.png";
import HomeDecoration1 from "./Images/HomeDecoration1.png";
import HomeDecoration2 from "./Images/HomeDecoration2.png";
import TitleDecoration1 from "./Images/TitleDecoration1.png";
import FelixIngla from "./Images/Felix Ingla picture.jpeg";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import PepeGomez from "./Images/Pepe Gomez image.png";
import CarmeHuges from "./Images/Carme Huges image.png";
import SoledadTerol from "./Images/Soledad Terol image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import BeforeFurnish from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterFurnish from "./Images/curtain-effect-hero/AfterFurnish.png";
import BeforeEmpty from "./Images/curtain-effect-hero/BeforeEmpty.png";
import AfterEmpty from "./Images/curtain-effect-hero/AfterEmpty.png";
import BeforeRenovate from "./Images/curtain-effect-hero/BeforeRenovate.png";
import AfterRenovate from "./Images/curtain-effect-hero/AfterRenovate.png";
import BeforeEnhance from "./Images/curtain-effect-hero/BeforeEnhance.png";
import AfterEnhance from "./Images/curtain-effect-hero/AfterEnhance.png";
import HomePageBlueSkyBefore from "./Images/HomePageBlueSkyBefore.jpg";
import HomePageBlueSkyAfter from "./Images/HomePageBlueSkyAfter.jpg";
import HomePageEmptyBefore from "./Images/HomePageEmptyBefore.jpg";
import HomePageEmptyAfter from "./Images/HomePageEmptyAfter.jpg";
import HomePageEnhanceBefore from "./Images/HomePageEnhanceBefore.jpg";
import HomePageEnhanceAfter from "./Images/HomePageEnhanceAfter.jpg";
import HomePageEnlightBefore from "./Images/HomePageEnlightBefore.jpg";
import HomePageEnlightAfter from "./Images/HomePageEnlightAfter.jpg";
import HomePageFurnishBefore from "./Images/HomePageFurnishBefore.jpg";
import HomePageFurnishAfter from "./Images/HomePageFurnishAfter.jpg";
import HomePagePoolBefore from "./Images/HomePagePoolBefore.jpg";
import HomePagePoolAfter from "./Images/HomePagePoolAfter.jpg";
import HomePageRemoveWatermarkBefore from "./Images/HomePageRemoveWatermarkBefore.jpg";
import HomePageRemoveWatermarkAfter from "./Images/HomePageRemoveWatermarkAfter.jpg";
import HomePageRenovateBefore from "./Images/HomePageRenovateBefore.jpg";
import HomePageRenovateAfter from "./Images/HomePageRenovateAfter.jpg";
import HomePageRenovateExteriorBefore from "./Images/HomePageRenovateExteriorBefore.jpg";
import HomePageRenovateExteriorAfter from "./Images/HomePageRenovateExteriorAfter.jpg";
import HomeDrawFloorPlan from "./Images/HomeDrawFloorPlan.png";
import HomeRendersFromFloorPlan from "./Images/HomeRendersFromFloorPlanFr.png";
import HomeStep1 from "./Images/HomeStep1Fr.png";
import HomeStep2 from "./Images/HomeStep2Fr.png";
import HomeStep3 from "./Images/HomeStep3Fr.png";

// Icons
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import PurpleEmptyIcon from "./Icons/hero-icons/PurpleEmptyIcon.jsx";
import PurpleFurnishIcon from "./Icons/hero-icons/PurpleFurnishIcon.jsx";
import PurpleEnhanceIcon from "./Icons/hero-icons/PurpleEnhanceIcon.jsx";
import PurpleRenovateIcon from "./Icons/hero-icons/PurpleRenovateIcon.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function HomeFr(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  const [example, setExample] = useState({
    name: "Furnish",
    beforeImage: BeforeFurnish,
    afterImage: AfterFurnish,
  });

  const handleExample = (example) => {
    if (example === "Furnish") {
      setExample({
        name: "Furnish",
        beforeImage: BeforeFurnish,
        afterImage: AfterFurnish,
      });
    } else if (example === "Renovate") {
      setExample({
        name: "Renovate",
        beforeImage: BeforeRenovate,
        afterImage: AfterRenovate,
      });
    } else if (example === "Empty") {
      setExample({
        name: "Empty",
        beforeImage: BeforeEmpty,
        afterImage: AfterEmpty,
      });
    } else {
      setExample({
        name: "Enhance",
        beforeImage: BeforeEnhance,
        afterImage: AfterEnhance,
      });
    }
  };

  return (
    <div>
      <br />
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">
            Montrez le potentiel de vos biens{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
                borderBottom: "2px solid #ddd",
              }}
            >
              en 1 clic
            </span>
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2
                  style={{
                    fontSize: "20px",
                    marginTop: "12px",
                  }}
                >
                  L'outil de home staging virtuel que vous recherchez :
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Rapide :</strong> Résultats en quelques secondes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Facile :</strong> Intuitif dès le début
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Qualité :</strong> Résultats hyperréalistes
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Aide :</strong> Assistance humaine si nécessaire
                  </div>
                </div>
                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Essayez Pedra <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        29€ pour 100 images
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flex: "1 0 0" }}></div>
                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa, Directeur de la Photographie chez aProperties"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera, Agent Immobilier chez Huspy"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata, PDG d'Actívox"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart, PDG de Gilart Consultors"
                      ></img>
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      ></img>
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        Plus de 100 témoignages
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore={"Avant de vider virtuellement la pièce"}
                  altAfter={"Après avoir vidé virtuellement la pièce"}
                />

                <div style={{ display: "flex", flex: "1 0 0" }}></div>
                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Furnish" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Furnish")}
                    >
                      <PurpleFurnishIcon />
                      Meubler
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Empty" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Empty")}
                    >
                      <PurpleEmptyIcon />
                      Vider
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Renovate" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Renovate")}
                    >
                      <PurpleRenovateIcon />
                      Rénover
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Enhance" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Enhance")}
                    >
                      <PurpleEnhanceIcon />
                      Embellir
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration1}
          alt="Décoration du site web Pedra"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "1200px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                Obtenez des images qui vous aident à{" "}
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  vendre plus
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-10px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>
              </h2>
              <div className="title-secondary">
                Meublez, rénovez, videz, améliorez, etc. L'outil de home staging
                virtuel dont vous avez besoin pour{" "}
                <strong>obtenir des images qui vendent plus</strong> en 1 clic.
              </div>
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageFurnishBefore}
                      afterImage={HomePageFurnishAfter}
                      altBefore={
                        "Avant de meubler la pièce avec l'outil de home staging virtuel de Pedra"
                      }
                      altAfter={
                        "Après avoir meublé la pièce avec l'outil de home staging virtuel de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Meublez une pièce</h2>
                    <div className="feature-text home">
                      Utilisez l'IA de Pedra pour faire du home staging virtuel.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEmptyBefore}
                      afterImage={HomePageEmptyAfter}
                      altBefore={
                        "Avant de vider la pièce avec l'outil d'IA de Pedra"
                      }
                      altAfter={
                        "Après avoir vidé la pièce avec l'outil d'IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Videz une pièce</h2>
                    <div className="feature-text home">
                      Utilisez l'IA de Pedra pour vider des pièces en 1 clic.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateBefore}
                      afterImage={HomePageRenovateAfter}
                      altBefore={
                        "Avant de rénover la pièce avec l'outil d'IA de Pedra"
                      }
                      altAfter={
                        "Après avoir rénové la pièce avec l'outil d'IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Rénovez les espaces</h2>
                    <div className="feature-text home">
                      Utilisez l'IA de Pedra pour rénover automatiquement les
                      espaces.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRenovateExteriorBefore}
                      afterImage={HomePageRenovateExteriorAfter}
                      altBefore={
                        "Avant de rénover l'extérieur avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      altAfter={
                        "Après avoir rénové l'extérieur avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Rénovez les extérieurs
                    </h2>
                    <div className="feature-text home">
                      Utilisez le home staging virtuel de Pedra pour transformer
                      les façades et les jardins.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePagePoolBefore}
                      afterImage={HomePagePoolAfter}
                      altBefore={
                        "Avant le home staging virtuel de la piscine avec l'outil d'IA de Pedra"
                      }
                      altAfter={
                        "Après le home staging virtuel de la piscine avec l'outil d'IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Réparez les piscines</h2>
                    <div className="feature-text home">
                      Utilisez le home staging virtuel de Pedra pour visualiser
                      les piscines et transformer votre jardin.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageBlueSkyBefore}
                      afterImage={HomePageBlueSkyAfter}
                      altBefore={
                        "Avant le home staging virtuel du ciel avec l'outil d'IA de Pedra"
                      }
                      altAfter={
                        "Après le home staging virtuel du ciel avec l'outil d'IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Rendez le ciel bleu</h2>
                    <div className="feature-text home">
                      Utilisez le home staging virtuel de Pedra pour améliorer
                      le ciel dans vos photos immobilières.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageRemoveWatermarkBefore}
                      afterImage={HomePageRemoveWatermarkAfter}
                      altBefore={
                        "Avant de supprimer le filigrane avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      altAfter={
                        "Après avoir supprimé le filigrane avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Supprimez les filigranes
                    </h2>
                    <div className="feature-text home">
                      Utilisez le home staging virtuel de Pedra pour supprimer
                      les filigranes de vos photos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnlightBefore}
                      afterImage={HomePageEnlightAfter}
                      altBefore={
                        "Avant d'éclairer la pièce avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      altAfter={
                        "Après avoir éclairé la pièce avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">Éclairez les espaces</h2>
                    <div className="feature-text home">
                      Utilisez l'IA de Pedra pour améliorer l'éclairage de vos
                      photos.
                    </div>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-extra-small">
                  <div className="feature-text-container home">
                    <CurtainEffect
                      beforeImage={HomePageEnhanceBefore}
                      afterImage={HomePageEnhanceAfter}
                      altBefore={
                        "Avant d'améliorer la photographie et la perspective avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      altAfter={
                        "Après avoir amélioré la photographie et la perspective avec l'outil de home staging virtuel avec IA de Pedra"
                      }
                      curtainStyle={"home"}
                    />
                    <h2 className="feature-title home">
                      Améliorez et corrigez les photos
                    </h2>
                    <div className="feature-text home">
                      Utilisez Pedra pour corriger les perspectives et améliorer
                      la couleur et la lumière des photos.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Excellent logiciel de home staging virtuel, très simple à
              utiliser avec d'excellents résultats"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src={REMAXLogo}
                alt="Logo REMAX pour Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={SoledadTerol}
                alt="Soledad Terol"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Soledad Terol</div>
                <div className="quote-author-company">Agent Immobilier</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={HomeDecoration2}
          alt=""
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxWidth: "800px",
            opacity: 1,
            pointerEvents: "none",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="section-one-column-left">
            <div className="title-container">
              <h2
                className="title-container"
                style={{ position: "relative", display: "inline" }}
              >
                <span style={{ position: "relative" }}>
                  Accélérez
                  <img
                    src={TitleDecoration1}
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "-4px",
                      left: 0,
                      width: "100%",
                      pointerEvents: "none",
                      zIndex: -1,
                    }}
                  />
                </span>{" "}
                tous types de ventes
              </h2>
              <div className="title-secondary">
                Que vous vendiez sur plan, des biens neufs ou d'occasion, Pedra
                vous garantit un retour sur investissement dans la vente de
                votre bien immobilier.
              </div>
            </div>
          </div>
          <div className="section-one-column-left">
            <div className="feature-container home">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Créer des rendus à partir d'une photo de plan
                </h2>
                <div className="feature-text">
                  Téléchargez l'image d'un plan pour créer des rendus
                  photoréalistes. Aucune expérience architecturale n'est
                  nécessaire, et vous aurez vos résultats en quelques secondes.
                </div>
              </div>
              <img
                src={HomeRendersFromFloorPlan}
                alt="Outils de home staging virtuel avec IA, rendus de plans - Capture d'écran du logiciel"
                width="1000"
                height="400"
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "2.5/1",
                }}
              />
            </div>
          </div>

          <div className="section-one-column-left">
            <div className="feature-container-two-features">
              <div className="feature-container-2-column">
                <div className="feature-container-small home">
                  <div className="feature-text-container ">
                    <OrangeVideoIcon />
                    <h2 className="feature-title ">
                      Vidéos virtuelles à partir d'images
                    </h2>
                    <div className="feature-text ">
                      Créez des reportages et des vidéos à partir d'une image
                      qui vous aideront à mieux vendre votre bien. Et le
                      meilleur de tout, en 1 seul clic.
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "30px",
                      paddingTop: "30px",
                      marginBottom: "-8px",
                    }}
                  >
                    <video
                      width="100%"
                      height="auto"
                      playsInline
                      autoPlay
                      muted
                      loop
                      alt="Logiciel de home staging virtuel, vidéos virtuelles - Vidéo"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderLeft: "1px solid #EEEEEE",
                        borderRight: "1px solid #EEEEEE",
                        borderTop: "1px solid #EEEEEE",
                      }}
                    >
                      <source
                        src="https://pedraimages.s3.eu-west-3.amazonaws.com/HomeVirtualVideo.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div className="feature-container-expander"></div>

                <div className="feature-container-small home">
                  <div className="feature-text-container">
                    <OrangePaletteIcon />
                    <h2 className="feature-title ">
                      Obtenez des plans en quelques secondes
                    </h2>
                    <div className="feature-text ">
                      Dessinez des plans facilement pour mieux positionner vos
                      annonces immobilières.
                    </div>
                  </div>
                  <img
                    src={HomeDrawFloorPlan}
                    alt="Outil de home staging virtuel, plans d'étage - Capture d'écran du logiciel"
                    width="600"
                    height="400"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Je devais vendre un appartement à rénover, et j'ai utilisé Pedra
              pour créer les rendus en quelques secondes. Les résultats étaient
              incroyables ! Cela m'a aidé à le vendre beaucoup plus rapidement,
              maintenant je le recommande à tout le monde."
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "100px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={EXPLogo}
                alt="Logo EXP pour Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={PepeGomez}
                alt="José Antonio Gómez"
              />
              <div className="quote-author-block-name-title">
                <div className="quote-author-name">José Antonio Gómez</div>
                <div className="quote-author-company">Agent Immobilier</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <div className="title-container">
            <h2 className="title-container" style={{ position: "relative" }}>
              Comment ça marche ?
            </h2>
          </div>
        </div>
        <div className="section-one-column-left">
          <div className="feature-container-two-features">
            <div className="feature-container-2-column">
              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep1}
                    alt="Étape 1 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      1
                    </div>
                    Téléchargez les images
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Téléchargez une ou plusieurs images du bien.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep2}
                    alt="Étape 2 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      2
                    </div>
                    Transformez
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Cliquez sur "Éditer avec l'IA" pour transformer les images.
                  </div>
                </div>
              </div>

              <div className="feature-container-expander"></div>

              <div className="feature-container-extra-small">
                <div
                  className="feature-text-container home"
                  style={{ backgroundColor: "#f7f7f7", boxShadow: "none" }}
                >
                  <img
                    src={HomeStep3}
                    alt="Étape 3 de Pedra"
                    className="feature-text-container home"
                    style={{
                      backgroundColor: "#f7f7f7",
                      boxShadow: "none",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <h2 className="feature-title home" style={{ gap: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#FF8A33",
                        borderRadius: "3px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      3
                    </div>
                    Attendez et téléchargez
                  </h2>
                  <div
                    className="feature-text home"
                    style={{ marginTop: "10px" }}
                  >
                    Téléchargez vos images éditées avec l'IA.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">"Pedra est merveilleux"</h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={ENGELLogo}
                alt="Logo Engel & Völkers pour Pedra"
              />
              <img
                className="testimonials-on-header-image"
                src={CarmeHuges}
                alt="Carme Hugues"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Carme Hugues</div>

                <div className="quote-author-company">Agent Immobilier</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="section-one-column-left">
          <h2
            className="title-container"
            style={{ maxWidth: "400px", display: "inline" }}
          >
            Pour 29€ par mois. <br />{" "}
            <span
              style={{
                color: "rgb(248, 121, 42)",
                fontWeight: "bold",
              }}
            >
              Et sans engagement.
            </span>{" "}
          </h2>
        </div>

        <div className="modal-product-tiers-container">
          <div className="modal-product-tier-current">
            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-1">
                Plan Pro
              </div>
              <div className="modal-product-plan-description-text-2-3-container">
                <div className="modal-product-plan-description-text-2">29€</div>
                <div className="modal-product-plan-description-text-3">
                  par mois
                </div>
              </div>
            </div>

            <div className="modal-product-plan-description">
              <div className="modal-product-plan-description-text-4">
                Inclut
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />{" "}
                <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                  100 générations d'images
                </span>{" "}
                <MagicWandIconPurple />
                <div style={{ marginLeft: "2px" }}>par mois</div>
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HammerIconPricing /> Rénover et redécorer
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <BrushIconPricing /> Vider les pièces
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <FurnishPricingIcon /> Meubler
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <RemoveObjectPricingIcon /> Supprimer des objets
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <HDIconPricing /> Augmenter la résolution
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <WallChangePricingIcon /> Changer les sols
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <PhotographyPricingIcon /> Améliorer la photographie
              </div>
              <div className="modal-product-plan-description-text-4">
                <EmptyIcon />
                <VideoPricingIcon /> Générer des vidéos virtuelles
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Plans illimités
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Partage de projets illimité
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Ajouter des filigranes
              </div>
              <div className="modal-product-plan-description-text-4">
                <CheckIcon />
                Support illimité
              </div>
              <div className="pricing-modal-button-container">
                <button className="button-top-of-page" onClick={redirectToPage}>
                  Essayez Pedra
                  <ArrowIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div>Annulez quand vous voulez.</div>
              <div style={{ fontSize: "12px", lineHeight: "100%" }}>
                Achetez plus d'images à 0,20€ par image si vous en avez besoin.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F8792A",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="section-first-button-container">
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <button
              className="button-top-of-page"
              onClick={redirectToPage}
              style={{ backgroundColor: "#2383E2", borderColor: "#0077D4" }}
            >
              Essayez Pedra <ArrowIcon />
            </button>

            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                className="testimonials-on-header-image"
                src={FelixIngla}
                style={{ marginLeft: "0px" }}
                alt="Fondateur de Pedra"
              />
              <div>
                Des questions ? Envoyez-nous un email à{" "}
                <a
                  href="mailto:felix@pedra.so"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  felix@pedra.so
                </a>{" "}
                ou planifiez une démo de 15min avec notre fondateur Felix{" "}
                <a
                  href="https://calendar.app.google/W35goQDoST5hwDVL6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    paddingLeft: "0px",
                  }}
                >
                  ici
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="section-one-column-left">
        <div className="title-container">Questions et réponses</div>
      </div>
      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Qu'est-ce qu'un rendu IA ?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Un rendu IA, dans le contexte du design et de la visualisation
                architecturale, fait référence à l'application de l'intelligence
                artificielle (IA) pour générer des images rendues de haute
                qualité d'espaces architecturaux. L'intelligence artificielle
                est utilisée pour améliorer et optimiser le processus de rendu,
                permettant des résultats plus réalistes et efficaces.
                <br />
                <br />
                L'IA dans le rendu permet d'obtenir des résultats plus
                rapidement qu'avec la méthode traditionnelle. Les anciennes
                méthodes impliquent des heures ou des semaines de travail car il
                faut recréer des modèles 3D des pièces et traiter les systèmes
                de rendu. Avec l'IA, cela a changé. Les rendus peuvent être
                générés à partir d'images et créer des résultats spectaculaires
                à partir de l'image originale avec de nouveaux styles appliqués.
                <br />
                <br />
                L'intelligence artificielle dans le rendu d'images immobilières
                ne fait pas que gagner du temps, elle offre aussi de la
                flexibilité pour imaginer des espaces pour les agents
                immobiliers, les propriétaires et les acheteurs. La technologie
                aide à visualiser le potentiel des espaces d'une manière jamais
                vue auparavant.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Qu'est-ce que le home staging virtuel ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Le{" "}
                <Link className="article-link" to="/fr/home-staging-virtuel">
                  home staging virtuel
                </Link>{" "}
                est une technique utilisée dans le marché immobilier pour
                présenter un bien de manière plus attrayante et agréable aux
                acheteurs ou locataires potentiels, mais contrairement au home
                staging traditionnel, qui implique la préparation physique et la
                décoration de l'espace, le home staging virtuel se fait de
                manière digitale.
                <br />
                <br />
                Dans le home staging virtuel, des outils tels que des logiciels
                de conception 3D, la réalité virtuelle (VR) et la photographie
                numérique sont utilisés pour créer des représentations
                numériques réalistes d'un bien. Ces représentations peuvent
                inclure des images rendues de haute qualité, des visites
                virtuelles du bien et des visualisations interactives qui
                montrent à quoi ressemblerait l'espace avec différentes
                configurations de meubles et de décoration.
                <br />
                <br />
                L'objectif du home staging virtuel est de permettre aux
                acheteurs ou locataires potentiels d'avoir une meilleure idée de
                l'apparence du bien une fois meublé et décoré, ce qui peut aider
                à augmenter l'intérêt et les chances de vente ou de location. De
                plus, le home staging virtuel peut être particulièrement utile
                dans les situations où le bien n'est pas complètement meublé ou
                est vide, car il peut aider les clients à visualiser le
                potentiel de l'espace.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Pedra est-il facile à utiliser ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Oui, Pedra est conçu pour être simple et rapide à utiliser.
                <br />
                <br />
                Lors de la création d'un projet, vous pouvez télécharger une ou
                plusieurs images. Vous pouvez cliquer pour sélectionner des
                images, ce qui affichera une barre d'options. Les options
                incluent{" "}
                <span style={{ fontWeight: "600px" }}> Éditer avec l'IA</span>,
                ajouter un filigrane, télécharger dans différents formats et
                plus encore. En créant un projet, vous aurez également l'option
                de dessiner un plan. Le plan contient plusieurs figures que vous
                pouvez éditer en les sélectionnant, en les faisant glisser ou
                via le menu.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Combien coûte Pedra ?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra coûte 29€ plus TVA par mois.
                <br />
                <br />
                Il n'y a pas d'engagement, vous pouvez donc vous désabonner
                quand vous n'en avez plus besoin.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeFr;
