import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeBedroomStaging1 from "../Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "../Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "../Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "../Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "../Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "../Images/curtain-effect/AfterBedroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderBedroomPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Como Renderizar um Quarto</h1>

          <div className="title-secondary gray">
            Tutorial sobre Como Criar Renders de Quartos
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Por que usar as ferramentas de render da Pedra para quartos?
            </h2>
            <p className="article-text">
              As ferramentas de render da Pedra permitem visualizar
              transformações completas de quartos em apenas segundos, ajudando
              potenciais compradores a ver o verdadeiro potencial do espaço.
              <p style={{ marginTop: "10px" }}>
                Renovações de quartos são frequentemente a parte mais impactante
                das melhorias em uma casa, pois são espaços íntimos e pessoais.
                Poder mostrar o potencial de um quarto desatualizado pode fazer
                uma grande diferença na venda de imóveis.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com a Pedra, você não precisa mais de arquitetos especializados
                ou especialistas em renderização para criar propostas
                convincentes de renovação de quartos.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como criar um render de quarto?
            </h2>
            <p className="article-text">
              Comece fazendo upload de fotos nítidas do quarto existente que
              você deseja transformar.
              <p style={{ marginTop: "10px" }}>
                Selecione suas imagens e clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>,
                depois escolha a opção{" "}
                <span style={{ fontWeight: "500" }}>"Renovação"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Como Usar a Ferramenta de Renovação para Criar Renders de Quartos – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovação Rápida de Quarto:{" "}
              </span>
              Gerar um Render Rápido de Quarto
            </h2>
            <p style={{ marginTop: "10px" }}>
              A ferramenta{" "}
              <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
              transformará seu quarto em cerca de 25 segundos.
              <p>
                Para uma atualização sutil, escolha o estilo "Minimalista" com
                criatividade "Baixa". Isso manterá a estrutura básica enquanto
                moderniza acabamentos e elementos do quarto.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Como Usar a Ferramenta de Renovação para Criar Renders de Quartos – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personalize seu Render de Quarto
            </h2>
            <p className="article-text">
              A Pedra oferece extensas opções de personalização para renders de
              quartos.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Escolha entre vários estilos de decoração, controle quão
                  dramáticas devem ser as mudanças e decida se quer preservar
                  elementos existentes como janelas ou características
                  distintivas.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Como Usar a Ferramenta de Renovação para Criar Renders de Quartos – Captura de tela do software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Escolha seu estilo de decoração
            </h3>
            <p className="article-text">
              Selecione entre 9 diferentes estilos de decoração, incluindo
              moderno, tradicional, escandinavo e mais.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você também pode fazer upload de imagens de referência de
                  quartos que você gosta.
                </span>{" "}
                Isso permite criar renders que correspondam a uma inspiração
                específica de design ou à estética preferida do seu cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Defina a intensidade da renovação
            </h3>
            <p className="article-text">
              Escolha entre os níveis de criatividade Baixo, Médio, Alto e Muito
              Alto para sua transformação.
              <p style={{ marginTop: "10px" }}>
                As configurações Baixa e Média atualizarão acabamentos e
                elementos do quarto mantendo a estrutura básica intacta.
              </p>
              <p style={{ marginTop: "10px" }}>
                As configurações Alta e Muito Alta podem reimaginar
                completamente o espaço com novos elementos decorativos, layouts
                e mudanças estruturais.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Preserve elementos-chave do quarto
            </h3>
            <p className="article-text">
              Escolha preservar janelas, o que é particularmente importante para
              quartos com características distintivas de janelas ou requisitos
              específicos de iluminação. Isso garante que seu render mantenha
              elementos práticos enquanto atualiza a estética.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Gere seu render de quarto
            </h2>
            <p>
              Após selecionar suas preferências, gere o render do quarto.
              Renders padrão levam cerca de 15 segundos, enquanto preservar
              elementos específicos pode levar um pouco mais de tempo.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 5 – </span>
              Preserve elementos-chave do quarto
            </h3>
            <p className="article-text">
              Escolha preservar partes específicas da imagem que você não quer
              modificar.
              <p style={{ marginTop: "10px" }}>
                Após selecionar a opção "preservar partes", você pode desenhar
                diretamente na imagem para indicar áreas que devem permanecer
                inalteradas. Isso é particularmente útil para:
                <ul>
                  <li>Elementos arquitetônicos únicos</li>
                  <li>Janelas ou vistas específicas</li>
                  <li>Móveis ou peças que deseja manter</li>
                  <li>Detalhes decorativos especiais</li>
                </ul>
              </p>
              <p style={{ marginTop: "10px" }}>
                Simplesmente desenhe sobre as áreas que você quer preservar
                antes de clicar em gerar. Isso garante que seu render se
                concentre apenas em renovar as partes pretendidas do quarto
                enquanto mantém elementos importantes.
              </p>
              <br />
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Dicas para melhores renders de quartos
                </h4>
                <p className="callout-p">
                  Para obter os renders de quarto mais realistas e úteis:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Gere múltiplas versões
                      </span>
                      . Cada render é único, e você pode encontrar alguns que
                      preservam melhor características importantes como detalhes
                      arquitetônicos ou elementos distintivos.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use criatividade baixa para atualizações sutis em quartos
                      desatualizados.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Tire fotos em um dia nublado para minimizar sombras
                        fortes, e capture todo o quarto
                      </span>
                      . Isso ajuda a IA a entender melhor o espaço e criar
                      transformações mais precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">Bedroom render examples</h2>
            <p className="article-text">
              Here are several examples of bedroom renovations created with our
              renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeBedroomStaging1}
              afterImage={AfterBedroomStaging1}
              altBefore={"Bedroom before render"}
              altAfter={"Bedroom after render"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging2}
              afterImage={AfterBedroomStaging2}
              altBefore={"Bedroom before render"}
              altAfter={"Bedroom after render"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging3}
              afterImage={AfterBedroomStaging3}
              altBefore={"Bedroom before render"}
              altAfter={"Bedroom after render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderBedroomPt;
