import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseDec2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseDec2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseFrDec24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">DÉCEMBRE 2024</div>
          <h1 className="article-titleh1">
            Pedra : Nouvelles fonctionnalités pour les constructions neuves et
            les espaces commerciaux
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Nous terminons l'année en apportant des améliorations pour que
              Pedra puisse vous aider avec plus de types de ventes immobilières
              :)
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Créer des rendus à partir de plans
              </h2>
              Vous aimeriez convertir vos plans en images réalistes
              instantanément ? C'est maintenant possible avec Pedra. Téléchargez
              l'image d'un plan et générez des images de toutes les pièces en 1
              clic. Idéal pour les constructions neuves.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Rendus à partir de plans"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Rénover et meubler des espaces commerciaux
              </h2>
              Vous avez maintenant la possibilité de choisir le type d'espace
              que vous souhaitez lors de la rénovation ou de l'ameublement de
              vos pièces : restaurant, bar, boutique, supermarché, salon de
              coiffure... Ce que vous voulez !
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Espaces commerciaux"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>
            <div>
              <li>
                De meilleurs résultats lors de l'utilisation de "Meubler" pour
                l'ameublement des pièces
              </li>
              <li>
                Option de service professionnel d'ameublement virtuel de pièces
                à 6€ par image
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseFrDec24;
