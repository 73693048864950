import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenderKitchenFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenderKitchenFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenderKitchenFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderKitchenPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Como Renderizar uma Cozinha</h1>

          <div className="title-secondary gray">
            Tutorial sobre Como Criar Renders de Cozinhas
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Por que usar as ferramentas de render da Pedra para cozinhas?
            </h2>
            <p className="article-text">
              As ferramentas de render da Pedra permitem visualizar
              transformações completas de cozinhas em apenas segundos, ajudando
              potenciais compradores a ver o verdadeiro potencial do espaço.
              <p style={{ marginTop: "10px" }}>
                Renovações de cozinha são frequentemente a parte mais impactante
                das melhorias em uma casa, pois são espaços centrais de
                convivência. Poder mostrar o potencial de uma cozinha
                desatualizada pode fazer uma grande diferença na venda de
                imóveis.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com a Pedra, você não precisa mais de arquitetos especializados
                ou especialistas em renderização para criar propostas
                convincentes de renovação de cozinhas.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como criar um render de cozinha?
            </h2>
            <p className="article-text">
              Comece fazendo upload de fotos nítidas da cozinha existente que
              você deseja transformar.
              <p style={{ marginTop: "10px" }}>
                Selecione suas imagens e clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>,
                depois escolha a opção{" "}
                <span style={{ fontWeight: "500" }}>"Renovação"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenderKitchenFrame1}
                alt="Como Usar a Ferramenta de Renovação para Criar Renders de Cozinhas – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovação Rápida de Cozinha:{" "}
              </span>
              Gerar um Render Rápido de Cozinha
            </h2>
            <p style={{ marginTop: "10px" }}>
              A ferramenta{" "}
              <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
              transformará sua cozinha em cerca de 25 segundos.
              <p>
                Para uma atualização sutil, escolha o estilo "Minimalista" com
                criatividade "Baixa". Isso manterá a estrutura básica enquanto
                moderniza acabamentos e elementos da cozinha.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenderKitchenFrame2}
                alt="Como Usar a Ferramenta de Renovação para Criar Renders de Cozinhas – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personalize seu Render de Cozinha
            </h2>
            <p className="article-text">
              A Pedra oferece extensas opções de personalização para renders de
              cozinhas.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Escolha entre vários estilos de decoração, controle quão
                  dramáticas devem ser as mudanças e decida se quer preservar
                  elementos existentes como janelas ou características
                  distintivas.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenderKitchenFrame3}
                  alt="Como Usar a Ferramenta de Renovação para Criar Renders de Cozinhas – Captura de tela do software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Escolha seu estilo de cozinha
            </h3>
            <p className="article-text">
              Selecione entre 9 diferentes estilos de cozinha, incluindo
              moderno, tradicional, industrial e mais.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você também pode fazer upload de imagens de referência de
                  cozinhas que você gosta.
                </span>{" "}
                Isso permite criar renders que correspondam a uma inspiração
                específica de design ou à estética preferida do seu cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Defina a intensidade da renovação
            </h3>
            <p className="article-text">
              Escolha entre os níveis de criatividade Baixo, Médio, Alto e Muito
              Alto para sua transformação.
              <p style={{ marginTop: "10px" }}>
                As configurações Baixa e Média atualizarão acabamentos e
                elementos da cozinha mantendo a estrutura básica intacta.
              </p>
              <p style={{ marginTop: "10px" }}>
                As configurações Alta e Muito Alta podem reimaginar
                completamente o espaço com novos layouts, armários e mudanças
                estruturais.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Preserve elementos-chave da cozinha
            </h3>
            <p className="article-text">
              Escolha preservar janelas, o que é particularmente importante para
              cozinhas com características distintivas de janelas ou requisitos
              específicos de iluminação. Isso garante que seu render mantenha
              elementos práticos enquanto atualiza a estética.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Gere seu render de cozinha
            </h2>
            <p>
              Após selecionar suas preferências, gere o render da cozinha.
              Renders padrão levam cerca de 15 segundos, enquanto preservar
              elementos específicos pode levar um pouco mais de tempo.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 5 – </span>
              Preserve elementos-chave da cozinha
            </h3>
            <p className="article-text">
              Escolha preservar partes específicas da imagem que você não quer
              modificar.
              <p style={{ marginTop: "10px" }}>
                Após selecionar a opção "preservar partes", você pode desenhar
                diretamente na imagem para indicar áreas que devem permanecer
                inalteradas. Isso é particularmente útil para:
                <ul>
                  <li>Eletrodomésticos que deseja manter</li>
                  <li>Janelas ou vistas específicas</li>
                  <li>Elementos estruturais importantes</li>
                  <li>Detalhes arquitetônicos especiais</li>
                </ul>
              </p>
              <p style={{ marginTop: "10px" }}>
                Simplesmente desenhe sobre as áreas que você quer preservar
                antes de clicar em gerar. Isso garante que seu render se
                concentre apenas em renovar as partes pretendidas da cozinha
                enquanto mantém elementos importantes.
              </p>
              <br />
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Dicas para melhores renders de cozinha
                </h4>
                <p className="callout-p">
                  Para obter os renders de cozinha mais realistas e úteis:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Gere múltiplas versões
                      </span>
                      . Cada render é único, e você pode encontrar alguns que
                      preservam melhor características importantes como
                      eletrodomésticos ou elementos distintivos.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use criatividade baixa para atualizações sutis em cozinhas
                      desatualizadas.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Tire fotos em um dia nublado para minimizar sombras
                        fortes, e capture toda a cozinha
                      </span>
                      . Isso ajuda a IA a entender melhor o espaço e criar
                      transformações mais precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">Kitchen render examples</h2>
            <p className="article-text">
              Here are several examples of kitchen renovations created with our
              renders:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Kitchen before render"}
              altAfter={"Kitchen after render"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Kitchen before render"}
              altAfter={"Kitchen after render"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Kitchen before render"}
              altAfter={"Kitchen after render"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Kitchen before render"}
              altAfter={"Kitchen after render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderKitchenPt;
