import React from "react";

const WorldIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 1C3.6875 1 1 3.6875 1 7C1 10.3125 3.6875 13 7 13C10.3125 13 13 10.3125 13 7C13 3.6875 10.3125 1 7 1ZM10.74 4.5H9.495C9.3775 3.9475 9.2175 3.4325 9.0175 2.9825C9.7125 3.3325 10.3075 3.855 10.7375 4.5H10.74ZM8.025 4.5H5.9725C6.4475 2.285 7.55 2.285 8.025 4.5ZM2.615 8C2.5425 7.6775 2.5 7.345 2.5 7C2.5 6.655 2.5425 6.3225 2.615 6H4.29C4.265 6.33 4.25 6.665 4.25 7C4.25 7.335 4.2625 7.67 4.29 8H2.615ZM3.2575 9.5H4.5025C4.62 10.0525 4.78 10.5675 4.98 11.0175C4.285 10.6675 3.69 10.145 3.26 9.5H3.2575ZM4.5025 4.5H3.2575C3.6875 3.8575 4.2825 3.335 4.9775 2.9825C4.7775 3.4325 4.62 3.9475 4.5 4.5H4.5025ZM5.9725 9.5H8.025C7.55 11.715 6.4475 11.715 5.9725 9.5ZM8.22 8H5.78C5.76 7.69 5.75 7.3575 5.75 7C5.75 6.6425 5.76 6.31 5.78 6H8.22C8.24 6.31 8.25 6.6425 8.25 7C8.25 7.3575 8.24 7.69 8.22 8ZM9.02 11.0175C9.22 10.5675 9.3775 10.0525 9.4975 9.5H10.7425C10.3125 10.1425 9.7175 10.665 9.0225 11.0175H9.02ZM9.71 8C9.735 7.67 9.75 7.335 9.75 7C9.75 6.665 9.7375 6.33 9.71 6H11.385C11.4575 6.3225 11.5 6.655 11.5 7C11.5 7.345 11.4575 7.6775 11.385 8H9.71Z"
        fill="#878787"
      />
    </svg>
  );
};

export default WorldIcon;
