import React from "react";
import ReleaseOct2024Frame1 from "../Images/ReleaseOct2024Frame1.png";
import ReleaseOct2024Frame2 from "../Images/ReleaseOct2024Frame2.png";
import ReleaseOct2024Frame3 from "../Images/ReleaseOct2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseOct24Pt() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">OUTUBRO 2024</div>
          <h1 className="article-titleh1">
            Pedra: Melhorias em piscinas, preservação de espaços e renovação
            seletiva
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Neste outubro, trazemos melhorias para você obter ainda mais valor
              da Pedra:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">Adicione água às piscinas</h2>
              Não mostre piscinas vazias – isso não ajuda a vender. Agora com a
              Pedra, você pode encher piscinas para mostrar seu imóvel em seu
              melhor estado.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame1}
                  alt="Exemplo de piscina com água"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Melhor preservação de pisos e paredes ao esvaziar espaços
              </h2>
              Anteriormente, a Pedra às vezes modificava os materiais de pisos e
              paredes. Fizemos algumas mudanças para que isso não aconteça mais.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame2}
                  alt="Exemplo de preservação de piso e parede"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Preserve partes específicas de uma imagem ao renovar
              </h2>
              Agora você pode dizer à Pedra exatamente quais partes você quer
              preservar. Ideal para manter o estado dos edifícios adjacentes ao
              seu imóvel.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseOct2024Frame3}
                  alt="Exemplo de preservação seletiva"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Outras melhorias</h2>
            <div>
              <li>Melhores resultados ao remover objetos pequenos</li>
              <li>
                Melhor preservação da arquitetura do espaço ao renovar com baixa
                criatividade
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseOct24Pt;
