// RenderEs.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import RealEstatePhotographyEsPage1 from "./Images/RealEstatePhotographyEsPage1En.png";
import RealEstatePhotographyEsPage2 from "./Images/RealEstatePhotographyEsPage2En.png";
import RealEstatePhotographyEsPage3 from "./Images/RealEstatePhotographyEsPage3En.png";
import RealEstatePhotographyEsPage4 from "./Images/RealEstatePhotographyEsPage4En.png";
import RealEstatePhotographyEsPage5 from "./Images/RealEstatePhotographyEsPage5En.jpeg";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6En.jpeg";
import iadLogo from "./Images/iadLogo.png";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import posterImageVideo from "./Images/poster-images/poster-image-video.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function RealEstatePhotographyDe() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Verbessern Sie Ihre Immobilienfotos mit KI in 1 Klick
            </h1>
            <div className="section-first-text">
              <div
                className="section-first-text-bold"
                style={{ width: "100%" }}
              >
                Nutzen Sie Pedras KI, um Ihre Immobilienfotos einfach zu
                verbessern
              </div>
              <div className="section-first-text-gray">
                Korrigieren Sie Perspektiven, verbessern Sie das Licht und
                entfernen Sie Überflüssiges
              </div>
            </div>
            <div className="section-first-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Pedra testen <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Fotografie-Direktor bei aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Immobilienmakler bei Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO bei Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO bei Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  Über 5.000 Immobilienprofis nutzen bereits Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+demo+video+09+Jul+24+English.mp4"
                    type="video/mp4"
                  />
                  Ihr Browser unterstützt das Video-Tag nicht.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MEHR ALS 5.000 IMMOBILIENPROFIS VERTRAUEN BEREITS AUF PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Verbessern Sie Ihre Immobilienfotografie mit einem Klick
        </h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">
                  Verbessern Sie Ihre Immobilienfotos ganz einfach
                </h2>
                <div className="feature-text">
                  Laden Sie Ihre Bilder hoch und klicken Sie auf "Verschönern
                  und korrigieren". Pedra kümmert sich um die Verbesserung von
                  Licht und Farbe sowie die Korrektur der Perspektiven.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage1}
                alt="Perspektive, Farbe und Licht mit KI korrigieren"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Entfernen Sie überflüssige Objekte aus Ihren Immobilienfotos
                </h2>
                <div className="feature-text">
                  Pedras KI entfernt Objekte aus Räumen, wie Familienfotos,
                  Flaggen, Gemälde und mehr.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage2}
                alt="Objekte aus Immobilienfotografie entfernen"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Automatisch sehr dunkle Räume aufhellen
                </h2>
                <div className="feature-text">
                  Nutzen Sie Pedras KI, um die Beleuchtung in zu dunklen Räumen
                  zu korrigieren.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage3}
                alt="Sehr dunkle Räume aufhellen"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Erhöhen Sie die Auflösung Ihrer Hausfotos auf HD
                </h2>
                <div className="feature-text">
                  Machen Sie sich keine Sorgen um niedrig aufgelöste Fotos. Mit
                  Pedra können Sie die Auflösung Ihrer Bilder mit einem Klick
                  auf HD erhöhen.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage4}
                alt="HD-Auflösungsverbesserungswerkzeug für Wände und Böden durch KI-Renderings"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Ändern Sie Materialien von Wänden und Böden von Immobilien mit
                  KI
                </h2>
                <div className="feature-text">
                  Nutzen Sie KI, um das Material von Böden und Wänden zu ändern.
                  Wenn Sie eine Immobilie mit schlecht gepflegtem Rasen haben,
                  können Sie mit Pedra die beste Version zeigen.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage5}
                alt="Werkzeug zum Ändern von Wänden und Böden über KI-Renderings"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">
                  Setzen Sie den blauen Himmel mit KI ein
                </h2>
                <div className="feature-text">
                  Nutzen Sie Pedras KI, um einen schlechten Tag zu korrigieren.
                  Lassen Sie das Wetter Ihr Immobilien-Fotoshooting nicht
                  ruinieren.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Blauen Himmel mit KI einsetzen"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h3 className="quote">"Es ist die Entdeckung des Jahrhunderts"</h3>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "45px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={iadLogo}
                alt="iad Logo für Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Bouchra</div>
                <div className="quote-author-company">iad Spanien</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Pedra testen <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Was ist Pedra?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Pedra ist eine KI-Anwendung, die für Immobilienmakler und
                Unternehmen entwickelt wurde.
                <br />
                <br />
                Das Ziel von Pedra ist es, Ihnen zu helfen, mehr zu verkaufen,
                indem die Kosten reduziert werden.
                <br />
                <br />
                Mit einem einzigen Klick können Sie die Fotos Ihrer Immobilien
                verbessern, Licht, Farbe und Perspektiven oder Definition
                optimieren. Dies spart Ihnen Stunden der Bearbeitung in
                Photoshop, Lightroom oder mit professionellen Fotografen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Welche Funktionen enthält Pedras Fotoverbesserungs-Kit?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Pedras Immobilienfotografie-Verbesserungskit enthält folgende
                  Funktionen:
                </div>
                <h4>Automatische Perspektivkorrektur:</h4>Mit Pedra können Sie
                die Perspektiven Ihrer Fotos automatisch mit einem einzigen
                Klick begradigen und korrigieren. Dies spart Zeit und Mühe. Die
                Funktion dauert normalerweise 5 Sekunden.
                <br />
                <br />
                <h4>Automatische Fotoverschönerung:</h4>Verbessern Sie das Licht
                und die Farbe Ihrer Immobilienfotos mit einem einzigen
                Knopfdruck. Klicken Sie und Pedra wird die Farben und Lichter
                verbessern, sodass das Foto ansprechender aussieht. Die Funktion
                dauert ebenfalls 5 Sekunden.
                <br />
                <br />
                <h4>Korrektur und Verschönerung von Fotos:</h4>Sie können auch
                Licht, Farbe und Perspektive Ihrer Fotos gleichzeitig
                verbessern. Diese Funktion dauert ebenfalls 5 Sekunden.
                <br />
                <br />
                <h4>Automatische Beleuchtung von Fotos:</h4>Wenn Sie sehr dunkle
                Fotos haben, können Sie diese mit Pedra aufhellen, damit sie
                klar aussehen. So werden Ihre Immobilien attraktiver
                präsentiert, was die Beziehung zum Eigentümer verbessert und die
                Verkaufschancen erhöht.
                <br />
                <br />
                <h4>Auflösungserhöhung von Fotos auf HD:</h4>Sie können auch die
                Auflösung Ihrer Fotos mit einem einzigen Klick auf HD erhöhen.
                Wählen Sie einfach die Option "In HD umwandeln". Hochwertigere
                Fotos verbessern die Präsenz Ihrer Anzeige auf den wichtigsten
                Immobilienportalen.
                <br />
                <br />
                <h4>Objektentfernung:</h4>Mit Pedra können Sie auch jede Art von
                Objekt aus Immobilienfotos entfernen, wie Familienfotos, Müll,
                Gemälde, Kreuze, Flaggen usw. Verwenden Sie einfach den
                Pedra-Pinsel auf den Objekten, die Sie entfernen möchten, und
                wählen Sie "Bild generieren".
                <br />
                <br />
                <h4>Wasserzeichenentfernung:</h4>Sie können Wasserzeichen
                anderer Immobilienagenturen mit der gleichen "Objekt
                entfernen"-Funktion entfernen.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Warum sollte ich Pedra zur Verbesserung meiner Immobilienfotos
                nutzen?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Die Verbesserung Ihrer Immobilienfotos vor der Veröffentlichung
                kann einen erheblichen Einfluss darauf haben, wie potenzielle
                Käufer oder Mieter Ihre Immobilien wahrnehmen. Hier sind einige
                wichtige Gründe:
                <br />
                <h4>Erster Eindruck:</h4> Die Fotografie ist oft der erste
                Kontakt, den ein Interessent mit Ihrer Immobilie hat.
                Hochwertige Bilder schaffen einen positiven ersten Eindruck, der
                die Entscheidung, die Immobilie weiter zu erkunden, maßgeblich
                beeinflussen kann.
                <br />
                <h4>Merkmale hervorheben:</h4> Gute fotografische
                Nachbearbeitung kann die besten Merkmale Ihrer Immobilie
                hervorheben, wie die Geräumigkeit der Räume, natürliche
                Beleuchtung und hochwertige Ausstattung. Dies kann potenziellen
                Käufern helfen, den Wert der Immobilie zu erkennen.
                <br />
                <h4>Marktdifferenzierung:</h4> Der Immobilienmarkt kann sehr
                wettbewerbsintensiv sein. Gut produzierte Fotos können Ihre
                Immobilie von anderen auf dem Markt abheben und mehr Interesse
                wecken und möglicherweise den Verkauf oder die Vermietung
                beschleunigen. Attraktive und qualitativ hochwertige Bilder
                generieren auch mehr Interaktion auf Online-Listing-Plattformen
                und sozialen Netzwerken. Dies kann die Sichtbarkeit Ihrer
                Anzeige erhöhen und mehr Interessenten anziehen.
                <br />
                <h4>Reduzierung der Marktzeit:</h4> Immobilien mit attraktiven
                Bildern verkaufen oder vermieten sich tendenziell schneller.
                Käufer sind eher geneigt, schnelle Entscheidungen zu treffen,
                wenn Bilder ihnen eine gute Darstellung der Immobilie bieten.
                <br />
                <h4>Effektive Werbung:</h4> Hochwertige Bilder sind essentiell
                für die effektive Bewerbung jeder Immobilie. Sie sind nicht nur
                nützlich für Online-Anzeigen, sondern auch für Broschüren,
                Präsentationen und andere Marketingmaterialien. Zusammenfassend
                lässt sich sagen, dass die Investition von Zeit und Ressourcen
                in die Verbesserung Ihrer Immobilienfotos zu einer besseren
                Wahrnehmung Ihrer Immobilien, Differenzierung am Markt und einem
                schnelleren Verkauf oder Vermietung führen kann.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Wie viel kostet Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra kostet 29 € pro Monat und ist ein monatliches Abonnement
                ohne Bindung. Wenn Sie es nicht mehr nutzen möchten, können Sie
                jederzeit kündigen.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealEstatePhotographyDe;
