import React from "react";

const BedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.125 11.875V16.25H16.25V13.75H3.75V16.25H1.875V4.375H3.75V6.875H6.875C8.41875 6.875 9.375 7.83125 9.375 9.375H15.625C17.1688 9.375 18.125 10.3312 18.125 11.875Z"
        fill="#B15DE1"
      />
    </svg>
  );
};

export default BedIcon;
