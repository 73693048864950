import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeSpaceExample1 from "../Images/curtain-effect/BeforeSpaceStaging1.png";
import AfterSpaceExample1 from "../Images/curtain-effect/AfterSpaceStaging1.png";
import BeforeSpaceExample2 from "../Images/curtain-effect/BeforeSpaceStaging2.png";
import AfterSpaceExample2 from "../Images/curtain-effect/AfterSpaceStaging2.png";
import BeforeSpaceExample3 from "../Images/curtain-effect/BeforeSpaceStaging3.png";
import AfterSpaceExample3 from "../Images/curtain-effect/AfterSpaceStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLocalPt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Como Renderizar um Estabelecimento Comercial
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre Como Renderizar Estabelecimentos Comerciais
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador da Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Por que usar as ferramentas de render da Pedra para
              estabelecimentos comerciais?
            </h2>
            <p className="article-text">
              As ferramentas de render da Pedra permitem visualizar uma
              transformação completa de estabelecimentos comerciais em apenas
              segundos, ajudando potenciais inquilinos ou compradores a ver o
              verdadeiro potencial do espaço.
              <p style={{ marginTop: "10px" }}>
                Renovações comerciais são frequentemente a parte mais impactante
                das melhorias em um estabelecimento, pois definem a primeira
                impressão dos clientes. Poder mostrar o potencial de um espaço
                comercial desatualizado pode fazer uma grande diferença na
                locação ou venda de imóveis comerciais.
              </p>
              <p style={{ marginTop: "10px" }}>
                Com a Pedra, você não precisa mais de arquitetos especializados
                ou especialistas em renderização para criar propostas
                convincentes de renovação comercial.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Como criar um render de estabelecimento comercial?
            </h2>
            <p className="article-text">
              Comece fazendo upload de fotos nítidas do espaço comercial
              existente que você deseja transformar.
              <p style={{ marginTop: "10px" }}>
                Selecione suas imagens e clique em{" "}
                <span style={{ fontWeight: "500" }}>"Editar com IA"</span>,
                depois escolha a opção{" "}
                <span style={{ fontWeight: "500" }}>"Renovação"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Como Usar a Ferramenta de Renovação para Criar Renders Comerciais – Captura de tela do software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovação Rápida Comercial:{" "}
              </span>
              Gerar um Render Rápido de Estabelecimento
            </h2>
            <p style={{ marginTop: "10px" }}>
              A ferramenta{" "}
              <span style={{ fontWeight: "500" }}>"Renovação"</span>{" "}
              transformará seu espaço comercial em cerca de 25 segundos.
              <p>
                Para uma atualização sutil, escolha o estilo "Minimalista" com
                criatividade "Baixa". Isso manterá a estrutura básica enquanto
                moderniza acabamentos e elementos do espaço.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Como Usar a Ferramenta de Renovação para Criar Renders Comerciais – Captura de tela do software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personalize seu Render de Renovação Comercial
            </h2>
            <p className="article-text">
              A Pedra oferece extensas opções de personalização para renders
              comerciais.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Escolha entre vários estilos arquitetônicos, controle quão
                  dramáticas devem ser as mudanças e decida se quer preservar
                  elementos existentes como janelas ou características
                  distintivas.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Como Usar a Ferramenta de Renovação para Criar Renders Comerciais – Captura de tela do software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 1 – </span>
              Escolha seu estilo arquitetônico
            </h3>
            <p className="article-text">
              Selecione entre 9 diferentes estilos arquitetônicos, incluindo
              moderno, tradicional, industrial e mais.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Você também pode fazer upload de imagens de referência de
                  estilos comerciais que você gosta.
                </span>{" "}
                Isso permite criar renders que correspondam a uma inspiração
                específica de design ou à estética preferida do seu cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 2 – </span>
              Defina a intensidade da renovação
            </h3>
            <p className="article-text">
              Escolha entre os níveis de criatividade Baixo, Médio, Alto e Muito
              Alto para sua transformação comercial.
              <p style={{ marginTop: "10px" }}>
                As configurações Baixa e Média atualizarão acabamentos e
                elementos do espaço mantendo a estrutura básica intacta.
              </p>
              <p style={{ marginTop: "10px" }}>
                As configurações Alta e Muito Alta podem reimaginar
                completamente o espaço com novos elementos arquitetônicos,
                layouts e mudanças estruturais.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 3 – </span>
              Preserve elementos-chave do espaço
            </h3>
            <p className="article-text">
              Escolha preservar janelas, o que é particularmente importante para
              estabelecimentos com características distintivas de janelas ou
              requisitos específicos de iluminação. Isso garante que seu render
              mantenha elementos práticos enquanto atualiza a estética.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 4 – </span>
              Gere seu render comercial
            </h2>
            <p>
              Após selecionar suas preferências, gere o render do
              estabelecimento. Renders padrão levam cerca de 15 segundos,
              enquanto preservar elementos específicos pode levar um pouco mais
              de tempo.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Passo 5 – </span>
              Preserve elementos-chave do espaço
            </h3>
            <p className="article-text">
              Escolha preservar partes específicas da imagem que você não quer
              modificar.
              <p style={{ marginTop: "10px" }}>
                Após selecionar a opção "preservar partes", você pode desenhar
                diretamente na imagem para indicar áreas que devem permanecer
                inalteradas. Isso é particularmente útil para:
                <ul>
                  <li>
                    Estabelecimentos vizinhos que você quer manter intactos
                  </li>
                  <li>A rua ou paisagem ao redor</li>
                  <li>Características arquitetônicas distintivas</li>
                  <li>Janelas ou outros elementos que você quer manter</li>
                </ul>
              </p>
              <p style={{ marginTop: "10px" }}>
                Simplesmente desenhe sobre as áreas que você quer preservar
                antes de clicar em gerar. Isso garante que seu render se
                concentre apenas em renovar as partes pretendidas do espaço
                enquanto mantém o contexto e o entorno.
              </p>
              <br />
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Dicas para melhores renders comerciais
                </h4>
                <p className="callout-p">
                  Para obter os renders comerciais mais realistas e úteis:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Gere múltiplas versões
                      </span>
                      . Cada render é único, e você pode encontrar alguns que
                      preservam melhor características importantes como detalhes
                      arquitetônicos ou elementos distintivos.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use criatividade baixa para atualizações sutis em espaços
                      desatualizados.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Tire fotos em um dia nublado para minimizar sombras
                        fortes, e capture todo o espaço
                      </span>
                      . Isso ajuda a IA a entender melhor a estrutura e criar
                      transformações mais precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Estabelecimento comercial render examples
            </h2>
            <p className="article-text">
              Here are several examples of commercial space transformations
              created with our renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeSpaceExample1}
              afterImage={AfterSpaceExample1}
              altBefore={"Commercial space before render"}
              altAfter={"Commercial space after render"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample2}
              afterImage={AfterSpaceExample2}
              altBefore={"Commercial space before render"}
              altAfter={"Commercial space after render"}
            />
            <CurtainEffect
              beforeImage={BeforeSpaceExample3}
              afterImage={AfterSpaceExample3}
              altBefore={"Commercial space before render"}
              altAfter={"Commercial space after render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLocalPt;
