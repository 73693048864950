// Home.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import BlueChairIcon from "./Icons/BlueChairIcon";
import BlueEyeIcon from "./Icons/BlueEyeIcon";
import BlueRulerIcon from "./Icons/BlueRulerIcon";
import BlueSmileIcon from "./Icons/BlueSmileIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import FloorPlanHeader from "./Images/FloorPlanHeader.png";
import FloorPlanFeature1 from "./Images/FloorPlanFeature1.png";
import FloorPlanFeature2 from "./Images/FloorPlanFeature2.png";
import FloorPlanFeature3 from "./Images/FloorPlanFeature3.png";
import FloorPlanFeature4 from "./Images/FloorPlanFeature4.png";
import FloorPlanStep1 from "./Images/FloorPlanStep1.png";
import FloorPlanStep2 from "./Images/FloorPlanStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function FloorPlanPt() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              A ferramenta de planta baixa mais simples
            </h1>
            <div className="section-first-text">
              Simples. Poderosa. Desenhe plantas baixas em segundos para
              posicionar melhor seus imóveis.
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#57A4EB",
                  border: "1px solid #57A4EB",
                }}
              >
                Experimente a Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Diretor de Fotografia na aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Corretor de Imóveis na Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO da Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO da Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  Mais de 5.000 profissionais imobiliários já usam a Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={FloorPlanHeader}
              alt="Ferramenta para Criar Plantas Baixas para Imóveis – Captura de Tela do Software"
            ></img>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MAIS DE 5.000 PROFISSIONAIS IMOBILIÁRIOS JÁ CONFIAM NA PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Desenhe uma planta baixa com a Pedra
        </h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueChairIcon />
                <h2 className="feature-title">
                  Adicione móveis à planta baixa
                </h2>
                <div className="feature-text">
                  Use camadas de móveis para mostrar o que cabe no espaço e
                  facilitar a compreensão das plantas baixas para seu público
                </div>
              </div>
              <img
                src={FloorPlanFeature1}
                alt="Adicione Móveis à Planta Baixa – Captura de Tela do Software"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueEyeIcon />
                <div className="feature-title">
                  Mostre medidas da planta baixa com 1 clique
                </div>
                <div className="feature-text">
                  Decida se quer mostrar ou ocultar as medidas nas suas plantas
                  baixas
                </div>
              </div>
              <img
                src={FloorPlanFeature2}
                alt="Mostre Medidas da Planta Baixa – Captura de Tela do Software"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueSmileIcon />
                <h2 className="feature-title">
                  Ferramenta de planta baixa fácil
                </h2>
                <div className="feature-text">
                  A ferramenta de planta baixa da Pedra é a mais fácil — basta
                  arrastar e soltar
                </div>
              </div>
              <img
                src={FloorPlanFeature3}
                alt="Ferramenta de Planta Baixa Fácil – Captura de Tela do Software"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <BlueRulerIcon />
                <h2 className="feature-title">
                  Planta baixa flexível mas simples
                </h2>
                <div className="feature-text">
                  A planta baixa da Pedra é simples mas flexível. Aproveite
                  vários tipos diferentes de camadas: portas, janelas, móveis e
                  edite seus parâmetros
                </div>
              </div>
              <img
                src={FloorPlanFeature4}
                alt="Ferramenta de Planta Baixa Flexível mas Simples – Captura de Tela do Software"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="title-container">Como desenhar uma planta baixa?</div>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep1}
            alt="Como Desenhar uma Planta Baixa – Diagrama"
          />
        </div>

        <div className="description">
          <h5>PASSO 1</h5>
          <h2>Crie um novo projeto e selecione "Desenhar planta baixa"</h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={FloorPlanStep2}
            alt="Como Editar uma Planta Baixa – Diagrama"
          />
        </div>

        <div className="description">
          <h5>PASSO 2</h5>
          <h2>Clique nas camadas para adicioná-las à planta baixa</h2>
          <div className="value-prop-text">
            Para editar o tamanho, cor ou propriedades da camada, você pode
            selecioná-las
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button
          className="button"
          onClick={redirectToPage}
          style={{ backgroundColor: "#57A4EB", border: "1px solid #57A4EB" }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0077D4";
            e.target.style.borderColor = "#0077D4";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#57A4EB";
            e.target.style.borderColor = "#57A4EB";
          }}
        >
          Experimente a Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <div className="section-one-column-left">
        <div className="title-container">Perguntas e respostas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">O que são plantas baixas?</h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Plantas baixas são representações gráficas que mostram uma vista
                de cima de um edifício, casa ou estrutura, como se você
                estivesse olhando de cima para baixo. Esses planos são
                principalmente usados em arquitetura, design de interiores,
                engenharia civil e outros campos relacionados para visualizar o
                layout e a disposição dos espaços dentro de uma estrutura.
                <br />
                <br />
                As plantas baixas geralmente incluem detalhes como dimensões dos
                cômodos, localização de paredes, portas, janelas, móveis e
                outros elementos importantes para o planejamento e construção de
                um edifício. Elas são essenciais para a comunicação entre
                designers, arquitetos, engenheiros e construtores envolvidos em
                um projeto de construção.
                <br />
                <br />
                Imagine que você está olhando do céu para um edifício. As
                plantas baixas capturam essa perspectiva, mostrando a
                distribuição dos espaços internos, cômodos, paredes, portas,
                janelas e outros elementos estruturais. Essencialmente, esses
                planos oferecem uma vista superior de um edifício, permitindo
                uma compreensão clara de como os espaços serão organizados e
                utilizados.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quais são os tipos de plantas baixas?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Existem vários tipos de plantas baixas, cada uma com seu
                  próprio propósito e foco:
                </div>
                <h4>Plantas do Térreo:</h4>Mostram o layout dos espaços no nível
                térreo. Isso inclui áreas como entradas, salas de estar,
                cozinhas, quartos e garagens.
                <br />
                <br />
                <h4>Plantas dos Andares Superiores:</h4> Representam os níveis
                superiores de um edifício, como o segundo andar, sótão ou
                quaisquer níveis adicionais. Esses planos são cruciais para
                entender o layout vertical de um edifício.
                <br />
                <br />
                <h4>Plantas com Mobília:</h4>Incorporam a disposição dos móveis
                dentro dos espaços. Esses planos ajudam designers de interiores
                e proprietários a visualizar como o espaço ficará depois de
                mobiliado.
                <br />
                <br />
                <h4>Plantas de Instalações Elétricas e Hidráulicas:</h4>Detalham
                a localização dos pontos de acesso elétrico e hidráulico no
                edifício. São essenciais para garantir a instalação adequada dos
                sistemas elétricos e hidráulicos.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Qual é o processo de criação de plantas baixas?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Crie um novo projeto:</h4>Comece criando uma planta baixa na
                seção 'Desenhar planta baixa'.
                <br />
                <br />
                <h4>Adicione camadas:</h4>Adicione todas as camadas necessárias
                e edite as dimensões, cor ou propriedades da camada.
                <br />
                <br />
                <h4>Mobilie:</h4>Adicione móveis à planta baixa para mostrar
                visualmente o espaço disponível e como ele poderia ser
                mobiliado.
                <br />
                <br />
                <h4>Baixe sua planta baixa online:</h4>Finalize os últimos
                detalhes como portas, janelas, etc., e baixe sua planta baixa.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Quanto custa a Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                A Pedra custa 29€ mais impostos por mês.
                <br />
                <br />É uma assinatura mensal, portanto você pode cancelar sua
                assinatura se não precisar mais.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloorPlanPt;
