import React from "react";
import { Link } from "react-router-dom";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import Article18Frame1 from "../Images/Article18Frame1.jpg";
import Article18Frame2 from "../Images/Article18Frame2.jpg";
import Article18Frame3 from "../Images/Article18Frame3.jpg";
import Article18Frame4 from "../Images/Article18Frame4.jpg";

function BlogArticle18Pt() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">
              Como Revitalizar um Imóvel que Não Está Vendendo
            </h1>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Fundador da Pedra"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador da Pedra
                </div>
              </div>
            </div>
            <article className="article-text">
              <p>
                Você já teve um imóvel que simplesmente não vendia? Um que
                estava anunciado há meses e simplesmente não recebia nenhuma
                ligação? Com certeza foi uma situação desagradável. A pressão do
                proprietário não ajuda. Baixar o preço? Mas você já reduziu na
                semana passada...{" "}
              </p>
              <p>
                Existem várias opções para desencalhar uma casa que não está
                vendendo — e o que vamos explicar pode ser eficaz em casos onde
                você nem mesmo está recebendo ligações dos portais imobiliários.
              </p>
              <h2 className="article-subtitleh2">
                Mude a Capa do Imóvel que Não Está Vendendo
              </h2>
              <p>
                Uma das opções mais simples para receber mais visitas em um
                anúncio é fazê-lo parecer novo. Anúncios novos recebem mais
                visitas porque os compradores geralmente procuram imóveis por
                área, tentando ver se os novos se encaixam em suas necessidades.{" "}
              </p>
              <p>
                Mas como fazer um anúncio parecer novo? Bem, mudando a foto de
                capa.
              </p>
              <p>Aqui estão três exemplos de como fazer isso.</p>
              <h3 className="article-subtitle">
                1. Imóvel Usado que Não Está Vendendo
              </h3>
              <p>
                Se você tem um imóvel usado que não está vendendo, você pode
                criar um{" "}
                <Link className="article-link" to="/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
                de como ele ficaria renovado e mobiliado.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame3}
                  alt="Renovando um imóvel que não está vendendo"
                />
              </div>
              Ou simplesmente remobiliado, deixando para trás os móveis
              desatualizados.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame1}
                  alt="Home staging virtual em um imóvel novo já mobiliado"
                />
              </div>
              <h3 className="article-subtitle">
                2. Imóvel Novo que Não Está Vendendo
              </h3>
              <p>
                No caso de um imóvel novo, dê a ele um visual mais atraente e
                habitável com home staging virtual. Você pode mobiliar
                virtualmente o espaço para fazê-lo parecer um novo anúncio.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame2}
                  alt="Home staging virtual em um imóvel novo"
                />
              </div>
              <h3 className="article-subtitle">
                3. Imóvel que Não Está Vendendo, Cheio de Objetos
              </h3>
              <p>
                Dê uma renovada no seu imóvel removendo todos os objetos e
                móveis que não ajudam na venda. Isso o fará parecer melhor e,
                além disso, parecerá um novo anúncio.
              </p>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={Article18Frame4}
                  alt="Limpando espaços em um imóvel que não está vendendo"
                />
              </div>
              <h3 className="article-subtitle">Conclusão</h3>
              <p>
                Ninguém gosta de ter um imóvel que não está vendendo, mas às
                vezes essas situações acontecem. É necessário estar preparado
                para aproveitar esses momentos.
              </p>
            </article>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container article">
          <footer className="article-footer">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
              src={FelixIngla}
              alt="Fundador da Pedra"
            />
            <h3 className="article-footer-author-name">Felix Ingla</h3>
            <p>
              Olá! Sou Felix Ingla, o fundador da{" "}
              <Link className="article-link" to="/en">
                Pedra
              </Link>
              , uma aplicação web inovadora para home staging virtual e
              fotografia imobiliária que está transformando a forma como os
              imóveis são apresentados no mundo digital.
            </p>
            <p>
              Se você quiser se conectar e está interessado em discutir
              tecnologia imobiliária, entre em contato comigo através do meu{" "}
              <a
                className="article-link"
                href="https://www.linkedin.com/in/felixingla/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LinkedIn
              </a>
              . Você também pode ler mais{" "}
              <Link className="article-link" to="/about">
                sobre a Pedra aqui
              </Link>
              .
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default BlogArticle18Pt;
