import React from "react";

function PrivacyPt() {
  return (
    <div>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h1 className="title-container">Política de Privacidade</h1>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="privacy-container">
          <div className="privacy-text">
            <h2>Introdução</h2>
            <p>
              Bem-vindo à Política de Privacidade da Pedra. Esta política
              descreve como coletamos, usamos e protegemos suas informações
              pessoais quando você usa nosso site e serviços.
            </p>

            <h2>Informações que Coletamos</h2>
            <p>
              Coletamos informações que você nos fornece diretamente, incluindo:
            </p>
            <ul>
              <li>Nome e endereço de e-mail ao criar uma conta</li>
              <li>Informações de pagamento ao assinar nossos serviços</li>
              <li>Imagens que você faz upload em nossa plataforma</li>
              <li>Comunicações que você tem conosco</li>
            </ul>

            <h2>Como Usamos suas Informações</h2>
            <p>Usamos suas informações para:</p>
            <ul>
              <li>Fornecer e melhorar nossos serviços</li>
              <li>Processar seus pagamentos</li>
              <li>Enviar atualizações sobre nossos serviços</li>
              <li>Responder às suas perguntas e solicitações</li>
              <li>Detectar e prevenir fraudes</li>
            </ul>

            <h2>Compartilhamento de Informações</h2>
            <p>
              Não vendemos suas informações pessoais. Compartilhamos suas
              informações apenas com:
            </p>
            <ul>
              <li>
                Provedores de serviços que nos ajudam a operar nosso negócio
              </li>
              <li>Autoridades legais quando exigido por lei</li>
            </ul>

            <h2>Segurança</h2>
            <p>
              Implementamos medidas de segurança apropriadas para proteger suas
              informações contra acesso não autorizado, alteração, divulgação ou
              destruição.
            </p>

            <h2>Seus Direitos</h2>
            <p>Você tem o direito de:</p>
            <ul>
              <li>Acessar suas informações pessoais</li>
              <li>Corrigir informações imprecisas</li>
              <li>Solicitar a exclusão de suas informações</li>
              <li>Optar por não receber comunicações de marketing</li>
            </ul>

            <h2>Cookies</h2>
            <p>
              Usamos cookies para melhorar sua experiência em nosso site. Você
              pode controlar os cookies através das configurações do seu
              navegador.
            </p>

            <h2>Alterações nesta Política</h2>
            <p>
              Podemos atualizar esta política periodicamente. Notificaremos você
              sobre mudanças significativas publicando a nova política em nosso
              site.
            </p>

            <h2>Contato</h2>
            <p>
              Se você tiver dúvidas sobre esta política de privacidade, entre em
              contato conosco em:
            </p>
            <p>
              E-mail: privacy@pedra.so
              <br />
              Endereço: Carrer de Pau Claris 95, 08009 Barcelona, Espanha
            </p>

            <h2>Última Atualização</h2>
            <p>
              Esta política foi atualizada pela última vez em 1 de janeiro de
              2024.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPt;
