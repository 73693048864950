import React from "react";

const OrangeFlowerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M22.1163 18.5769C19.5462 18.5769 17.4562 20.0481 16.5865 22.2332V17.2659C17.0495 17.5298 17.5687 17.6639 18.0923 17.6639C20.2947 17.6639 21.1168 15.7038 21.1168 14.6351C21.1168 14.4014 21.1082 13.2332 20.0481 12.0002C21.8178 12.1947 22.8433 10.7063 22.8433 9.31731C22.8433 9.00577 22.7957 8.6899 22.6962 8.38269C22.2808 7.10192 21.0909 6.28846 19.8144 6.28846C19.0572 6.28846 18.5163 6.56538 18.2308 6.73846C18.287 6.50913 18.3173 6.27115 18.3173 6.02885C18.3173 4.35433 16.963 3 15.2885 3C13.6139 3 12.2596 4.35433 12.2596 6.02885C12.2596 6.27548 12.2899 6.51346 12.3462 6.73846C12.0606 6.56106 11.5154 6.28846 10.7625 6.28846C9.48606 6.28846 8.30048 7.10192 7.88077 8.38269C7.78125 8.69423 7.73365 9.0101 7.73365 9.31731C7.73365 10.7106 8.77212 12.1947 10.5288 12.0002C9.47308 13.2332 9.4601 14.4014 9.4601 14.6351C9.4601 15.6865 10.2779 17.6639 12.4846 17.6639C13.0082 17.6639 13.5317 17.5298 13.9904 17.2659V22.2332C13.125 20.0481 11.0308 18.5769 8.46058 18.5769L7.5 19.5375L13.4625 25.5H17.1187L23.0812 19.5375L22.1207 18.5769H22.1163ZM15.2885 12.5192C14.3322 12.5192 13.5577 11.7447 13.5577 10.7885C13.5577 9.83221 14.3322 9.05769 15.2885 9.05769C16.2447 9.05769 17.0192 9.83221 17.0192 10.7885C17.0192 11.7447 16.2447 12.5192 15.2885 12.5192Z"
        fill="#F8792A"
      />
    </svg>
  );
};

export default OrangeFlowerIcon;
