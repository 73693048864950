import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

// Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import CurtainEffect from "./CurtainEffect.js";
import SmileIcon from "./Icons/hero-icons/SmileIcon.jsx";
import DiamondIcon from "./Icons/hero-icons/DiamondIcon.jsx";
import PersonIcon from "./Icons/hero-icons/PersonIcon.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import TimeIcon from "./Icons/hero-icons/TimeIcon.jsx";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

// Images
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

import BeforeKitchen from "./Images/curtain-effect/BeforeInteriorDesignAI2.png";
import AfterKitchen from "./Images/curtain-effect/AfterInteriorDesignAI2.png";

import BeforeKitchenStaging1 from "./Images/curtain-effect/BeforeInteriorDesignAI1.png";
import AfterKitchenStaging1 from "./Images/curtain-effect/AfterInteriorDesignAI1.png";

import BeforeKitchenStaging2 from "./Images/curtain-effect-hero/BeforeFurnish.png";
import AfterKitchenStaging2 from "./Images/curtain-effect-hero/AfterFurnish.png";

import BeforeKitchenStaging3 from "./Images/curtain-effect-hero/BeforeTerraceStaging.png";
import AfterKitchenStaging3 from "./Images/curtain-effect-hero/AfterTerraceStaging.png";

import VirtualStagingExample1 from "./Images/VirtualStagingExample1.png";
import VirtualStagingExample2 from "./Images/VirtualStagingExample2.png";
import VirtualStagingExample3 from "./Images/VirtualStagingExample3.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es/register", "_blank");
  } else if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr/register", "_blank");
  } else if (window.location.href.includes("/pt")) {
    window.open("https://app.pedra.so/pt/register", "_blank");
  } else if (window.location.href.includes("/de")) {
    window.open("https://app.pedra.so/de/register", "_blank");
  } else {
    window.open("https://app.pedra.so/register", "_blank");
  }
}

function InteriorDesign() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);

  const [example, setExample] = useState({
    name: "Modern",
    beforeImage: BeforeKitchenStaging1,
    afterImage: AfterKitchenStaging1,
  });

  const handleExample = (example) => {
    switch (example) {
      case "Modern":
        setExample({
          name: "Modern",
          beforeImage: BeforeKitchenStaging1,
          afterImage: AfterKitchenStaging1,
        });
        break;
      case "Minimalist":
        setExample({
          name: "Minimalist",
          beforeImage: BeforeKitchenStaging2,
          afterImage: AfterKitchenStaging2,
        });
        break;
      case "Industrial":
        setExample({
          name: "Industrial",
          beforeImage: BeforeKitchenStaging3,
          afterImage: AfterKitchenStaging3,
        });
        break;
      default:
        setExample({
          name: "Classic",
          beforeImage: BeforeKitchen,
          afterImage: AfterKitchen,
        });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is AI interior design?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "AI interior design combines traditional design creativity with artificial intelligence to optimize and visualize spaces efficiently. The AI analyzes your space and preferences to generate personalized and professional designs instantly.",
        },
      },
      {
        "@type": "Question",
        name: "What types of spaces can I design?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "You can design residential spaces (living rooms, bedrooms, kitchens), commercial spaces (offices, stores, restaurants), public spaces (museums, libraries) and specialized areas (clinics, gyms, hotels).",
        },
      },
      {
        "@type": "Question",
        name: "What are the advantages of AI design?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The advantages include instant visualization of changes, multiple design options in seconds, intelligent space optimization, detailed personalization, time and cost savings, and professional results without prior experience.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="section-one-column-left">
        <section style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="hero-header">AI Interior Design</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="hero-description-and-example-container">
              <div className="hero-description-container">
                <h2 style={{ fontSize: "20px", marginTop: "12px" }}>
                  Transform your spaces with intelligent interior design
                </h2>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: "12px",
                  }}
                >
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <TimeIcon />
                    </div>
                    <strong>Instant:</strong> Visualize changes in real time
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <SmileIcon />
                    </div>
                    <strong>Simple:</strong> No technical knowledge required
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <DiamondIcon />
                    </div>
                    <strong>Professional:</strong> High-quality results
                  </div>
                  <div className="hero-description-benefit-container">
                    <div className="hero-description-icon-container">
                      <PersonIcon />
                    </div>
                    <strong>Personalized:</strong> Adapted to your preferences
                  </div>
                </div>

                <div className="hero-cta-and-reviews-container">
                  <div style={{ marginTop: "40px", alignItems: "left" }}>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className="button-top-of-page"
                        onClick={redirectToPage}
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        Try AI Design <ArrowIcon />
                      </button>
                      <div
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#2383E2",
                          textAlign: "center",
                        }}
                      >
                        €29 per month, no commitment
                      </div>
                    </div>
                  </div>

                  <div className="testimonials-on-header-container">
                    <div className="testimonials-on-header-image-container">
                      <img
                        className="testimonials-on-header-image"
                        src={VictorLaviosa}
                        alt="Victor Laviosa"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={Soma}
                        alt="Francisco Somacarrera"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={DanieldelaMata}
                        alt="Daniel de la Mata"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={MariaJoseGilart}
                        alt="Maria José Gilart"
                      />
                      <img
                        className="testimonials-on-header-image"
                        src={EmanuelaCosma}
                        alt="Emanuela Cosma"
                      />
                    </div>
                    <div>
                      <div className="testimonials-on-header-stars">★★★★★</div>
                      <p className="testimonials-on-header-text">
                        100+ testimonials
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero-example-container">
                <CurtainEffect
                  beforeImage={example.beforeImage}
                  afterImage={example.afterImage}
                  altBefore="Before interior design"
                  altAfter="After interior design"
                />

                <div className="hero-example-buttons-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Modern" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Modern")}
                    >
                      Bedroom
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Minimalist" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Minimalist")}
                    >
                      Living Room
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`hero-button ${
                        example.name === "Industrial" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Industrial")}
                    >
                      Terrace
                    </div>
                    <div
                      className={`hero-button ${
                        example.name === "Classic" ? "selected" : ""
                      }`}
                      onClick={() => handleExample("Classic")}
                    >
                      Kitchen
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          OVER 5,000 PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Create professional designs{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            automatically
          </span>{" "}
          in seconds.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h3 className="feature-title">Design multiple spaces</h3>
                <div className="feature-text">
                  Generate designs for multiple rooms simultaneously
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h3 className="feature-title">Upload your own style</h3>
                <div className="feature-text">
                  Upload images of your own styles and the AI will redesign your
                  photos based on those styles
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h3 className="feature-title">Space optimization</h3>
                <div className="feature-text">
                  Distribute furniture intelligently
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h3 className="feature-title">Automatic cleanup</h3>
                <div className="feature-text">
                  Remove unwanted objects from the scene
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h3 className="feature-title">Total customization</h3>
                <div className="feature-text">
                  Adjust every detail to your taste
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h3 className="feature-title">Design in seconds</h3>
                <div className="feature-text">
                  Generate AI design proposals in seconds
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          How does AI interior design work?
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 1
            </h5>
            <h2>Upload an image of your space</h2>
            <div className="value-prop-text">
              Start by uploading a photo of the room you want to redesign. Our
              software analyzes the structure, lighting, and key elements.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample1}
              alt="How to upload photos for AI interior design"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 2
            </h5>
            <h2>Choose your preferred style</h2>
            <div className="value-prop-text">
              Select from different decoration styles and customize colors,
              materials, and decorative elements according to your preferences.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample2}
              alt="Selection of interior design styles"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5 style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
              STEP 3
            </h5>
            <h2>Get your design instantly</h2>
            <div className="value-prop-text">
              Receive professional visualizations of your transformed space.
              Download and use your designs immediately.
            </div>
          </div>

          <div className="picture">
            <img
              src={VirtualStagingExample3}
              alt="Final result of interior design"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto", maxWidth: "700px" }}
            />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Professional interior design for €29 per month. <br />{" "}
          <span style={{ color: "rgb(248, 121, 42)", fontWeight: "bold" }}>
            No commitment required.
          </span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                per month
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">
              Includes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#B15DE1" }}>
                100 designs
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>per month</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Instant space design
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Choose your own design styles
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Intelligent furniture layout
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Automatic scene cleanup
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> High-resolution visualizations
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Change materials and textures
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Professional lighting
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited projects
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Priority support
            </div>

            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try AI Design
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <div>Cancel anytime.</div>
            <div style={{ fontSize: "12px", lineHeight: "100%" }}>
              Additional designs available for €0.20 each.
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="title-container">
          Frequently Asked Questions about AI Interior Design
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">
                What is artificial intelligence interior design?
              </h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                AI interior design is an emerging trend that combines
                traditional design creativity with AI's analytical and
                predictive capabilities. The technology analyzes spaces and
                preferences to generate personalized designs instantly.
                <br />
                <br />
                Our system can:
                <br />
                • Generate designs automatically
                <br />
                • Optimize space distribution
                <br />
                • Suggest color and material combinations
                <br />• Create photorealistic visualizations
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What types of spaces can I design?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                Our tool can design a wide variety of spaces:
                <br />
                <br />
                <strong>Residential Spaces:</strong>
                <br />
                • Living rooms and dining rooms
                <br />
                • Bedrooms
                <br />
                • Kitchens and bathrooms
                <br />
                • Outdoor areas and gardens
                <br />
                <br />
                <strong>Commercial Spaces:</strong>
                <br />
                • Offices
                <br />
                • Shops and retail spaces
                <br />
                • Restaurants and cafes
                <br />
                <br />
                <strong>Public Spaces:</strong>
                <br />
                • Museums and galleries
                <br />
                • Libraries and educational centers
                <br />• Sports facilities
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                What are the advantages of AI in interior design?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                The main advantages include:
                <br />
                <br />
                <strong>Efficiency and speed</strong>
                <br />
                • Instant design generation
                <br />
                • Automation of repetitive tasks
                <br />
                • Immediate visualization of changes
                <br />
                <br />
                <strong>Precision and optimization</strong>
                <br />
                • Detailed space analysis
                <br />
                • Optimal element distribution
                <br />
                • Reduction of design errors
                <br />
                <br />
                <strong>Personalization</strong>
                <br />
                • Adaptation to individual preferences
                <br />
                • Multiple style options
                <br />• Recommendations based on space usage
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesign;
