import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import ChangeSize from "../Images/HowTo3DChangeSize.jpeg";
import Move from "../Images/HowTo3DMove.jpeg";
import Rotate from "../Images/HowTo3DRotate.jpeg";

function HowTo3DDe() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            So nutzen Sie das "Objekt hinzufügen"-Tool für virtuelles Home
            Staging
          </h1>

          <div className="title-secondary gray">
            Tutorial zum Hinzufügen von 3D-Objekten und Erstellen von virtuellem
            Home Staging
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Gründer von Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Gründer von Pedra
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            {typeof window !== "undefined" && (
              <iframe
                src="https://www.loom.com/embed/9c29edc36a704433a10cab23ca729c64?sid=0880e9f3-e662-47a7-9d9a-62d71b8ca968"
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  maxWidth: "900px",
                  height: "500px",
                }}
                frameBorder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                title="KI für den Immobilienverkauf"
              ></iframe>
            )}
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Wofür wird das "Objekt hinzufügen"-Tool verwendet?
            </h2>
            <div className="article-text">
              Mit dem "Objekt hinzufügen"-Tool können Sie Möbel, Pflanzen,
              Objekte und mehr in Ihr Bild einfügen.
              <br />
              <br />
              Das Besondere an diesem Tool ist, dass Sie die Position, Größe und
              Ausrichtung der Objekte selbst bestimmen können.
              <br />
              <br />
              Sobald Sie die Objekte platziert haben, können Sie auf die
              Generieren-Option klicken. Dies erstellt ein hochrealistisches
              Bild Ihrer Einrichtung.
              <br />
              <br />
            </div>
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 1 – </span>
              Objekte hinzufügen
            </h2>
            <div className="article-text">
              Klicken Sie auf das Objekt, das Sie hinzufügen möchten. Beim
              Klicken wird das Objekt automatisch zum Bild hinzugefügt.
              <div style={{ marginTop: "10px" }}>
                Wenn Sie hinzugefügte Objekte entfernen möchten, nutzen Sie die
                "Objekte löschen"-Schaltfläche.
              </div>
            </div>
            <br />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 2 –</span>{" "}
              Position, Ausrichtung und Größe der Objekte bearbeiten
            </h2>
            <div className="article-text">
              Nachdem Sie die Objekte zum Bild hinzugefügt haben, müssen Sie sie
              in die richtige Position bringen und Ausrichtung sowie Größe
              anpassen.
              <div style={{ marginTop: "10px", fontWeight: "500" }}>
                {" "}
                Wir empfehlen, ein Objekt vollständig zu positionieren, bevor
                Sie ein weiteres hinzufügen.
              </div>
            </div>
            <br />
            <h3 className="article-subtitle">Position der Objekte ändern</h3>
            <div className="article-text">
              Verwenden Sie die grünen, gelben und roten Pfeile, um das Objekt
              zu bewegen. Denken Sie daran, dass Sie in 3 Dimensionen arbeiten.
              <div style={{ marginTop: "10px" }}>
                Sie können auch auf das gelbe Rechteck in der Mitte des Objekts
                klicken, um es im Raum zu verschieben.
              </div>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={Move}
                alt="Position von 3D-Objekten für virtuelles Home Staging ändern – Screenshot der Pedra-Software"
              />
              <br />
            </div>
            <h3 className="article-subtitle">Objekte drehen</h3>
            <div className="article-text">
              Verwenden Sie die grünen, gelben und roten Kugeln, um das Objekt
              zu drehen.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={Rotate}
              alt="3D-Objekt für virtuelles Home Staging drehen – Screenshot der Pedra-Software"
            />
            <h3 className="article-subtitle">Größe der Objekte ändern</h3>

            <div className="article-text">
              Verwenden Sie die grünen, gelben und roten Linien, um die Größe
              des Objekts zu ändern.
            </div>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "20px 0px",
              }}
              src={ChangeSize}
              alt="Größe von 3D-Objekten für virtuelles Home Staging ändern – Screenshot der Pedra-Software"
            />
            <h2 className="article-subtitleh2">
              <span style={{ color: "rgb(248, 121, 42)" }}>Schritt 3 – </span>
              Bild generieren
            </h2>
            <div className="article-text">
              Sobald Sie die Objekte platziert haben, klicken Sie auf Bild
              generieren. Es wird eine hyperrealistische Darstellung des Raums
              erstellt.
            </div>
            <div style={{ marginTop: "10px" }}>
              Bei Fragen kontaktieren Sie mich per E-Mail unter felix@pedra.so
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowTo3DDe;
